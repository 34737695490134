
import { Fragment } from 'react';

export default function TonerContainer({ bgCol, data, levelName, stockName, maxName }) {

  const backgroundCol = (bgCol) ? `colour background ${bgCol}` : '';

  return <Fragment>
    <div className={`flex-container column around height-100 ${backgroundCol}`}>
      <div className={'flex-container row nowrap pad-1'}>
        <div className={'flex-item start pad-l5 pad-r5'}>Level:</div>
        <div className={'flex-item flex-grow-1 end mar-r5'}>{(data[levelName]) ? data[levelName] : 0}</div>
      </div>
      <div className={'flex-container row nowrap pad-1'}>
        <div className={'flex-item start pad-l5 pad-r5'}>Cur:</div>
        <div className={'flex-item flex-grow-1 end mar-r5'}>{(data[stockName]) ? data[stockName] : 0}</div>
      </div>
      <div className={'flex-container row nowrap pad-1'}>
        <div className={'flex-item start pad-l5 pad-r5'}>Max:</div>
        <div className={'flex-item flex-grow-1 end mar-r5'}>{(data[maxName]) ? data[maxName] : 0}</div>
      </div>
    </div>
  </Fragment>
}
