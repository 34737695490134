import { useState, Fragment } from 'react';
import CurrentRequestsTable from './currentRequestsTable';
import ConsumablesRequestSendVO from './consumablesRequestSendVO';


export default function CurrentRequests({ context, entityLookup, setEntityLookup }) 
{  

  const [sendToVo, setSendToVo] = useState({ sending: false, tonerIds: null, contact: -1, courier: -1, equipmentId: null, sendVo: true, context: null });

  return <Fragment>
    {!sendToVo.sending && <CurrentRequestsTable context={context} entityLookup={entityLookup} setEntityLookup={setEntityLookup} sendToVo={sendToVo} setSendToVo={setSendToVo} />}
    {sendToVo.sending && <ConsumablesRequestSendVO entityLookup={entityLookup} sendToVo={sendToVo} setSendToVo={setSendToVo} />}
  </Fragment>
}
