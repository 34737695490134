
import { Fragment } from 'react'
import { handleModalStyle, getUploadType } from '../../../shared/functions' 
import UILIB from 'components';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import BlockImportedDriverTable from '../tables/blockImportedDriverTable'
import BlockImportedVehicleTable from '../tables/blockImportedVehicleTable'
import BlockImportedDriverVehicleTable from '../tables/blockImportedDriverVehicleTable'


const operationClassData = [
  { label: 'View All Rows', id: 0 },
  { label: 'Create', id: 1 },
  { label: 'Update', id: 2 },
  { label: 'Failed', id: 3 }
];


export default function ViewImported(props) 
{

    const handleListItemClick = (event, newValue) => {
      const driverTableSortHandlerNew = structuredClone(props.tableSortHandler);
      driverTableSortHandlerNew.filter = newValue.label;
      props.setTableSortHandler(driverTableSortHandlerNew);
    }

    const handleCloseModal = () => {
      const driverTableSortHandlerNew = structuredClone(props.tableSortHandler);
      driverTableSortHandlerNew.filter = 'View All Rows';
      props.setTableSortHandler(driverTableSortHandlerNew);
      props.setShowUloadedRowsModal(false);
    }

    return <Fragment>
      <div className="genericModalContainer">
        <div className="genericModalContent" style={handleModalStyle(props, 'upload')}>
          <UILIB.Paper className="flex-container row genericaModalChild" >
            <div className="flex-item start width-100 mar-b10"><h4 style={{ padding: 0, margin: 0 }}>
              {getUploadType(props.uploadedRowsModal)} #{props.uploadedRowsModal.id}</h4>
            </div>
            <div className="flex-item start flex-grow-1 mar-b10">
              <FormControl sx={{ m: 1, minWidth: '200px' }} variant="standard">
                <Autocomplete
                  disablePortal
                  size="small"
                  onChange={(event, newValue) => handleListItemClick(event, newValue)}
                  options={operationClassData}
                  sx={{ width: 300 }}
                  defaultValue={{ label: 'Vew All Rows', id: 0 }}
                  renderInput={(params) => <TextField {...params} label="Select a result" />}
                />
              </FormControl>
            </div>
            <div className="flex-item end flex-grow-1 mar-b10">
              <UILIB.Button
                className="green"
                value="Close"
                onClick={() => handleCloseModal()}
              />
            </div>
          </UILIB.Paper>  
          <UILIB.Paper className="flex-container genericaModalChild" >
            {getUploadType(props.uploadedRowsModal) === 'Driver Upload' && <BlockImportedDriverTable {...props} />}
            {getUploadType(props.uploadedRowsModal) === 'Vehicle Upload' && <BlockImportedVehicleTable {...props} />}
            {getUploadType(props.uploadedRowsModal) === 'Ownership Upload' && <BlockImportedDriverVehicleTable {...props} />}
          </UILIB.Paper>  
        </div> 
      </div> 
    </Fragment>
}

