import { useState, useRef, useEffect, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { pleaseSelect } from 'classes/controls/genericControls';
import { createSelectInput, createTextArea, createButton } from 'classes/controls/formControls';
import { getEmptySendLogisticsObject } from '../functions';
import { getFormErrors, getEmptyError } from 'classes/formErrors/formErrors';
import { getSendLogisticsErrors } from 'classes/formErrors/repository';
import { formatAddress } from 'classes/dataValidation';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions';


export default function SendToLogistics({ deal, isDisabled, onFinished, sync }) {

    const headerData = [
      { label: 'Device Ref', value: 'deviceRef', type: 'number' },
      { label: 'Model Name', value: 'modelName', type: 'string' },
      { label: 'Part #', value: 'partNumber', type: 'string' },
      { label: 'New/Used', value: 'newOrUsed', type: 'string' },
      { label: 'Warehouse', value: 'warehouse', type: 'string' } ];


    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const formData = useRef(getEmptySendLogisticsObject());
    const formErrors = useRef({});
    const [clickedClose, setClickedClose] = useState(false);
    const [bannerError, setBannerError] = useState({ error: false, message: '' });
    const [hasSent, setHasSent] = useState(false);
    const [localRender, setLocalRender] = useState(false);
    const [warehouses, setWarehouses] = useState();
    const exceptions = [400,404];

    const invalidOwner = 'There is currently no SOP Owner selected for this deal';
    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';


    useEffect(() => {
      const actionAsync = async () => {

        setLoading(true);

        formData.current = getEmptySendLogisticsObject();

        const productResult = await Axios.get(`/entities/products?$filter=dealID eq ${deal.dealID}&$expand=attachments and singletons`).then(api => api.data.result);
        const addressResult = await Axios.get(`/entities/addresses?$filter=dealId eq ${deal.dealID}`).then(api => api.data.result);

        formData.current.productCount = productResult.length;
        formData.current.warehouses = deal.warehouses;
        formData.current.deal = deal;
        formData.current.deal.products = productResult;

        setWarehouses([
          { label: pleaseSelect, value: "-1" },
          ...formData.current.warehouses.filter(x => x.active).map(x => { return { value: x.id, label: x.warehouse } })]);

        addressResult.forEach(address => {

          if(!productResult.some(x => String(x.siteAddress) === String(address.id))) return;

          formData.current.addresses.push({ address: formatAddress(address), addressId: address.id });

          productResult.filter(x => String(x.siteAddress) === String(address.id)).forEach(product => {

            const warehouse = (product.warehouse) ? product.warehouse : -1;
            const errObj = getEmptyError(formErrors, warehouse, product.prodID, null, 0);

            formData.current.devices.push({
              productId: product.prodID,
              deviceRef: product.deviceNo,
              modelName: product.description,
              partNumber: product.partNo,
              newOrUsed: product.newUsed,
              addressId: address.id,
              warehouse: warehouse,
              errored: errObj });
          });
        });

        setLoading(false);
      }
      actionAsync();
    }, [])

    const actionUpdatePrimary = (event) => { 
      formData.current.warehouse = Number(event.target.value); 
      setLocalRender(!localRender);
    }

    const actionUpdateProduct = (event, device) => { 
      const found = formData.current.devices.find(x => Number(x.productId) === Number(device.productId));
      found.warehouse = Number(event.target.value);
      setLocalRender(!localRender);
    }

    const actionUpdateNotes = (event) => { 
      formData.current.deal.warehouseNotes = event.target.value;
      setLocalRender(!localRender);
    }

    const actionFill = () => {
      formData.current.devices = formData.current.devices.map(x => { return { ...x, warehouse: Number(formData.current.warehouse) } });
      setLocalRender(!localRender);
    }

    const rebuildDealData = (dealData, productData, dealLocal) => {
      const productIds = Array.from(new Set(productData.map(x => Number(x.prodID))));
      const existingProducts = dealLocal.products.filter(x => !productIds.includes(Number(x.prodID)));
      existingProducts.push(...productData);
      dealLocal = { ...dealLocal, ...dealData };
      dealLocal.products = existingProducts;
      return dealLocal;
    }

    const buildTable = (tableData) => {
      return <Fragment>
        <UILIB.Table
          height='100%'
          overflowX='auto'
          overflowY='hidden'
          loading={loading}
          header={headerData}
          data={tableData}
          sortable={false}
          filter={false}
          resetFilters={false}
          goExport={false}
          maxRows={40}
          autoResize={false}
          exportEnable={false} />
      </Fragment>
    }

    const buildSelect = (device) => {
      return <UILIB.Select 
        name={device.productId}
        value={device.warehouse}
        onChange={(event) => actionUpdateProduct(event, device)}
        errored={device.errored}
        data={warehouses} />
    }

    const buildGrouping = () => {
      return formData.current.addresses.map(address => {
        const devices = formData.current.devices.filter(x => Number(x.addressId) === Number(address.addressId));
        const mapped = devices.map(x => { return { 
            deviceRef: x.deviceRef,
            modelName: x.modelName,
            partNumber: x.partNumber,
            newOrUsed: x.newOrUsed,
            warehouse: buildSelect(x) } });
        return <div className='flex-container row offShade mar-t10'>
          <div className='flex-item flex-grow-1 text-11 max-height-20-px mar-5 font-weight-bold'>{address.address}</div>
          {buildTable(mapped)}
        </div> 
      })
    }

    const onConfirm = async () => {
      setSaving(true);
      const formResult = await getFormErrors(getSendLogisticsErrors(formData), null, setLocalRender(!localRender));
      if(formResult.errorCount === 0) {
        try { 
          const result = await Axios.put(`/entities/workflow/deals/actionSelectedWarehouse/${deal.dealID}`, formData.current);
          if(exceptions.includes(result.status)) {
            setSaving(false);
            if(Number(result.status) === 400 && result.data.result === 'NO SOP OWNER') return setBannerError({ error: true, message: invalidOwner });
            if(Number(result.status) === 400) return setBannerError({ error: true, message: invalidConfig });
            if(Number(result.status) === 404) return setBannerError({ error: true, message: notFoundError });
          } else {
            sync(rebuildDealData(result.data.result.deal[0], result.data.result.products, formData.current.deal));
            setHasSent(true);
            onFinished();
          }
        } catch (error) {
          console.log(error);
          setBannerError({ error: true, message: exceptionError });
        }
      }
      setSaving(false);
    }


    return <Fragment>
      <div className='flex-container row'>
          {clickedClose && hasSent && dispatch(setDrawer({ open: false, content: "" }))}
          {clickedClose && !hasSent &&
            <UILIB.Confirm 
            onConfirm={() => dispatch(setDrawer({ open: false, content: "" }))} 
            onCancel={() => setClickedClose(false)} 
            header="Close Drawer" 
            text={'Are you sure you want to close without sending?'} />}
          <h2 className='flex-item row center width-100 mar-b10'>Send to Logistics - {deal.finalDealNo}</h2>
          {loading && <UILIB.Loading type={3} />}
          {!loading && formData.current.addresses.length === 0 && <div className='width100 center'>
            <p>No applicable data to send.</p>
            <UILIB.Button className='mar-t15 secondary' value='Close' onClick={() => { setHasSent(true); setClickedClose(true); }} disabled={isDisabled} />
          </div>}
          {!loading && formData.current.addresses.length > 0 && <div className='width100'>
            <div className='flex-container row start wrap evenly width-100 mar-t2'>
              {createSelectInput('Fill Warehouse selection', 'warehouse', isDisabled, formData.current.warehouse, warehouses, (ev) => actionUpdatePrimary(ev), null, 'width-70', null, null, 'fullBorder border-colour-grey-4')}
              {createButton('', 'actionFill', 'Action Fill', isDisabled, false, () => actionFill(), null, 'flex-item flex-grow-1')}
            </div>
            <div className='mar-b10'>{buildGrouping()}</div>
            {createTextArea('Notes to all warehouses', 'warehouseNotes', isDisabled, formData.current.deal.warehouseNotes, (ev) => actionUpdateNotes(ev), null, 'flex-grow-1', null, null, 'height-100-px')}
            <div className='flex-container row end width-100 mar-t15 mar-r5'>
              <UILIB.Button className='red' disabled={saving} value='Cancel' onClick={() => setClickedClose(true)} />
              <UILIB.Button className='green' disabled={saving || formData.current.productCount === 0} loading={saving} value='Send' onClick={async () => await onConfirm()} />
            </div>
            {bannerError.error && <div className="flex-container end width-100">
              <div className="errored message">{bannerError.message}</div>
            </div>}
          </div>}
      </div>
    </Fragment>
}