import { useState, useRef, useEffect, memo } from 'react';
import { TableCell } from '@mui/material';
import { getYesNo, setCacheData } from '../functions';
import UILIB from 'components'

const ColumnInput = memo(({ header, settings, render, setRender }) => 
{
  const firstRender = useRef(true);
  const [renderLocal, setRenderLocal] = useState({ render: false, filter: null });
  const validTypes = ['number', 'string', 'date', 'datetime'];
  const boolDropdown = [9,10];

  const changeOption = async (event, columnName) => {
    const found = settings.current.filters.find(x => x.name === columnName);
    found.value = (Number(event.target.value) === -1) ? '' : event.target.value;
    found.value = (found?.type === 'datetime') ? found?.value?.replace('T',' ') : found?.value;
    settings.current = setCacheData({ ...settings.current, paging: { ...settings.current.paging, page: 0, offset: 0 }});
    setRenderLocal({ render: !renderLocal, filter: found?.filter });
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      if(firstRender.current) { firstRender.current = false; return; }
      if(renderLocal.filter !== 1) setRender(!render);
    }, 1000);
    return () => clearTimeout(timer);
  }, [renderLocal])


  return header.map((col, index) => {
    const style = (col?.style) ? col.style : {};
    const special = settings.current.filters.find(x => x.name === col.value)?.filter;
    const found = settings.current.filters.find(x => x.name === col.value);
    if(!validTypes.includes(col.type) || !col.type) return <TableCell style={{...style}} key={"input_" + index}><div>{<UILIB.TextInput disabled={true} className="textInput fullBorder tableFilter tableInput mar-b0" outerClassName="tableInput mar-b0"/>}</div></TableCell>;
    if(col.filterArray && col.filterArray.length) return <TableCell style={{...style}} key={"input_" + index}><div>{<UILIB.Select disabled={!col.filtering} className="textInput fullBorder tableFilter tableInput mar-b0" outerClassName="tableFilter mar-b0" data={col.filterArray} value={found?.value} onChange={(ev) => changeOption(ev, col.value)}/>}</div></TableCell>;
    if(boolDropdown.includes(special)) return <TableCell style={{...style}} key={"input_" + index}><div>{<UILIB.Select disabled={!col.filtering} className="textInput fullBorder tableFilter tableInput mar-b0" outerClassName="tableFilter mar-b0" data={getYesNo(special)} value={found?.value} onChange={(ev) => changeOption(ev, col.value)}/>}</div></TableCell>;
    if(col.type === 'number') return <TableCell style={{...style}} key={"input_" + index}><div>{<UILIB.TextInput disabled={!col.filtering} placeholder="Enter a value" type="number" className="textInput fullBorder tableFilter tableInput mar-b0" outerClassName="tableInput mar-b0" value={found?.value} onChange={(ev) => changeOption(ev, col.value)}/>}</div></TableCell>;
    if(col.type === 'string') return <TableCell style={{...style}} key={"input_" + index}><div>{<UILIB.TextInput disabled={!col.filtering} placeholder="Enter a value" type="string" className="textInput fullBorder tableFilter tableInput mar-b0" outerClassName="tableInput mar-b0" value={found?.value} onChange={(ev) => changeOption(ev, col.value)}/>}</div></TableCell>;
    if(col.type === 'date') return <TableCell style={{...style}} key={"input_" + index}><div>{<UILIB.TextInput disabled={!col.filtering} placeholder="Enter a value" type="date" className="textInput fullBorder tableFilter tableInput mar-b0" outerClassName="tableInput mar-b0" value={found?.value} onChange={(ev) => changeOption(ev, col.value)}/>}</div></TableCell>;
    if(col.type === 'datetime') return (<TableCell style={{...style}} key={'input_' + index}><div>{<UILIB.TextInput disabled={!col.filtering} placeholder="Enter a value" type="datetime-local" className="textInput fullBorder tableFilter tableInput mar-b0" outerClassName="tableInput mar-b0" value={found?.value?.replace(' ', 'T')?.replaceAll("'", '')} onChange={(ev) => changeOption(ev, col.value)} />}</div></TableCell>);
    return <TableCell style={{...style}} key={"input_" + index}><div>{<UILIB.TextInput disabled={true} className="textInput fullBorder tableFilter tableInput mar-b0" outerClassName="tableInput mar-b0"/>}</div></TableCell>;
  })
})
export default ColumnInput