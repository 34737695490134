import * as React from 'react';
import * as functions from '../shared/functions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { formatDateTime } from 'classes/format';



function Row(data) {

  if(!data.row) return <React.Fragment></React.Fragment>

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderColor: 'grey.500' } }}>
        <TableCell><Typography sx={{ fontSize: 13 }} variant="body2">{data.row.displayName}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: 13 }} variant="body2">{data.row.managerName}</Typography></TableCell>
        <TableCell>
          <Typography sx={{ fontSize: 13 }} variant="body2">
            <a href={`mailto:${data.row.emailAddress}`} target="_top"><div>{data.row.emailAddress}</div></a>
          </Typography>
        </TableCell>
        <TableCell>
          {data.row.groups.map(group => {
            return <Chip sx={{ m: '2px', fontSize: 11 }} size="small" variant="outlined" color="info" label={group.displayName} />
          })}
        </TableCell> 
        <TableCell>{functions.addSortedEnabledButton(data.row, { backgroundColor: '#ffa500', width: '100%', minWidth: '140px', fontSize: 12, padding: 0  }, data.row.innerProps)}</TableCell>
        <TableCell>{functions.addSortedLogoutButton(data.row, { backgroundColor: '#ffa500', width: '100%', minWidth: '110px', fontSize: 12, padding: 0  }, data.row.innerProps)}</TableCell>
        <TableCell><Typography sx={{ fontSize: 13 }} variant="body2">{data.row.loginCount === 0 ? 'Never' : formatDateTime(data.row.lastOnline, "DD/MM/YYYY HH:mm:ss")}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: 13 }} variant="body2">{formatDateTime(data.row.updatedAt, "DD/MM/YYYY HH:mm:ss")}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: 13 }} variant="body2">{formatDateTime(data.row.createdAt, "DD/MM/YYYY HH:mm:ss")}</Typography></TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function CollapsibleTable(props) {

  const handleChangePage = (event, newPage) => {
    const userTableSortHandler = structuredClone(props.userTableSortHandler);  
    userTableSortHandler.page = newPage;
    props.setUserTableSortHandler(userTableSortHandler);
  };

  const handleChangeRowsPerPage = (event) => {
    const userTableSortHandler = structuredClone(props.userTableSortHandler);
    userTableSortHandler.rowsPerPage = parseInt(event, 10);
    userTableSortHandler.page = 0;
    props.setUserTableSortHandler(userTableSortHandler);
  };

  const handleUserClick = (event, newValue) => {
    handleChangeRowsPerPage(12);
    props.setSelectedUserIndex({ 
      user: newValue.id, 
      group: props.selectedUserIndex.group
    });
  }

  const handleFilterChange = (event) => {
    const userTableSortHandler = structuredClone(props.userTableSortHandler);  
    userTableSortHandler.filter = event.target.value;
    props.setUserTableSortHandler(userTableSortHandler);
  };

  const userSelectData = props.users.sort(function(a, b){
    if(a.displayName < b.displayName) { return -1; }
    if(a.displayName > b.displayName) { return 1; }
    return 0;
  }).map(user => { return  { label: user.displayName, id: user.id } });
  userSelectData.unshift({ label: "All Users", id: 0 });

  const foundUserData = functions.findUserData(props, Row);


  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        <TextField 
          size="small" 
          id="outlined-basic" 
          label="Search" 
          variant="outlined" 
          sx={{ width: 600, pr: 2 }}
          onChange={(ev) => { handleFilterChange(ev) }}
        />
        <Autocomplete
          disablePortal
          size="small"
          onChange={(event, newValue) => handleUserClick(event, newValue)}
          options={userSelectData}
          sx={{ width: 400, pb: 2, pr: 2 }}
          defaultValue={{ label: "All Users", id: 0 }}
          renderInput={(params) => <TextField {...params} label="Select User" />}
        />
        {functions.addTableRefreshButton({ mb: 1, height: '38px', backgroundColor: '#00B200', fontSize: 12 }, props, props.userRender, props.setUserRender)}
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
            <TableRow>
              {functions.addSortedHeader('User Name', "14%", 'displayName', 'lightgray', props.userTableSortHandler, props.setUserTableSortHandler)}
              {functions.addSortedHeader('Line Manager', "14%", 'managerName', 'lightgray', props.userTableSortHandler, props.setUserTableSortHandler)}
              {functions.addSortedHeader('Email Address', "10%", 'emailAddress', 'lightgray', props.userTableSortHandler, props.setUserTableSortHandler)}
              {functions.addSortedHeader('Groups', "18%", 'groups', 'lightgray', props.userTableSortHandler, props.setUserTableSortHandler)}
              {functions.addSortedHeader('Enabled', "5%", 'isActive', 'lightgray', props.userTableSortHandler, props.setUserTableSortHandler)}
              {functions.addSortedHeader('Logout', "5%", 'logout', 'lightgray', props.userTableSortHandler, props.setUserTableSortHandler)}
              {functions.addSortedHeader('Last Login', "10%", 'lastOnline', 'lightgray', props.userTableSortHandler, props.setUserTableSortHandler)}
              {functions.addSortedHeader('UpdatedAt', "9%", 'updatedAt', 'lightgray', props.userTableSortHandler, props.setUserTableSortHandler)}
              {functions.addSortedHeader('CreatedAt', "9%", 'createdAt', 'lightgray', props.userTableSortHandler, props.setUserTableSortHandler)}
            </TableRow>
          <TableBody>
            {foundUserData.users}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
          size="small"
          component="div"
          showFirstButton
          showLastButton
          rowsPerPageOptions={[25, 50, 100, 200]}
          count={foundUserData.length}
          rowsPerPage={props.userTableSortHandler.rowsPerPage}
          page={props.userTableSortHandler.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(ev) => handleChangeRowsPerPage(ev.target.value)}
        />
    </div>
  );
}
