
import { Fragment } from 'react'
import UploadFileDialogue from './uploadFile';
import { 
  Dialog, 
  DialogContent
} from '@mui/material';



export default function UploadFileContainer(props) 
{
    return <Fragment>
        <Dialog open={props.uploadFileHandler.open} >
          <DialogContent style={{ minWidth: "480px", minHeight: "150px" }}>
            <UploadFileDialogue
              selectedFile={props.selectedFile} 
              setSelectedFile={props.setSelectedFile}
              fileUploadJobActive={props.fileUploadJobActive}
              setFileUploadJobActive={props.setFileUploadJobActive}
              setUploadFileHandler={props.setUploadFileHandler}
              uploadFileHandler={props.uploadFileHandler}
              render={props.fleetRender}
              setRender={props.setFleetRender}
            />
          </DialogContent>
        </Dialog>
    </Fragment>
}