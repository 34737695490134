import { useState, useRef, Fragment } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';


export default function OrderDeleteDrawer({ ourDeal, history, route, isActive, onCancel }) {

  const baseRoute = useRef(route.split('order/')[0]);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';

  
  const actionDeleteOrder = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const result = await Axios.delete(`/entities/workflow/deals/actionDeleteOrder/${ourDeal.dynamicsOpportunityNumber}/${ourDeal.dealID}`, { data: ourDeal }); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        history.push(`${baseRoute.current}quote/${ourDeal.finalDealNo}`);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  }


  return <Fragment>
      <div className="flex-container row wrap start align-center mar-b10 font-weight-bold text-16">Are you sure you want to DELETE Order #{ourDeal.dealID}?</div>
      <p className="mar-b10">This action will delete the current order and set any closed quotes back to active.</p>
      <p className="mar-b10">Are you sure you want to continue?</p>
      <div className="flex-container row start width-100">
        <UILIB.Button loading={saving} disabled={!isActive || saving} value="Confirm" onClick={async () => await actionDeleteOrder()} />
        <UILIB.Button disabled={!isActive || saving} value="Cancel" className="red" onClick={() => onCancel()} />
        {bannerError.error && <div className="flex-container start wrap width-100">
          <div className="errored message">{bannerError.message}</div>
      </div>}
      </div>
  </Fragment >
}