import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';


const tableHeader = [
  { label: "Recipient", value: "recipient", align: "left", type: 'text' }, 
  { label: "Email", value: "emailAddress", align: "left", type: 'text' },
  { label: "Type", value: "type", align: "left", type: 'text' },
  { label: " ", value: "options", align: "left", type: 'text',  width: "10px" }
]

export default function AddEditRecipients({ workflow = {}, onClose = () => { } }) {

    const [workflowData, setWorkflowData] = useState(workflow);
    const [groupData, setGroupData] = useState({});
    const [userData, setUserData] = useState({});
    const [addingRecipient, setAddingRecipient] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [pageLoading, setPageLoading] = useState(true);


    const saveChanges = async (newWorkFlow) => {
        await Axios.put(`/admin/workflows/${workflow.id}`, newWorkFlow);
        onClose();
    }

    const getAdGroups = async () => {
      const groupsResult = await Axios.get("/azure/groups");
        setGroupData(groupsResult.data);
        setPageLoading(false);
    }

    const getAdUsers = async () => {
      const usersResult = await Axios.get("/azure/users");
      setUserData(usersResult.data);
      setPageLoading(false);
    }

    const selectUserOrGroup = async (type, data) => {
        const newWorkFlow = structuredClone(workflowData);
        if (!newWorkFlow.recipients || !Array.isArray(newWorkFlow.recipients)) newWorkFlow.recipients = [];
        if (type === "group") {
            const found = newWorkFlow.recipients.find(f => f.type === "group" && f.id === data.objectId);
            if (!found) {
                newWorkFlow.recipients.push({
                    type: "group",
                    displayName: data.displayName,
                    id: data.objectId,
                    emailAddress: data.emailAddress
                })
            }
        }
        if (type === "user") {
            const found = newWorkFlow.recipients.find(f => f.type === "user" && f.id === data.id);
            if (!found) {
                newWorkFlow.recipients.push({
                    type: "user",
                    displayName: data.displayName,
                    id: data.id,
                    emailAddress: data.emailAddress
                })
            }
        }
        setWorkflowData(newWorkFlow);
        setAddingRecipient(false);
        saveChanges(newWorkFlow);
    }

    const deleteUser = async (userId) => {
        const newWorkFlow = structuredClone(workflowData);
        const index = newWorkFlow.recipients.findIndex(f => f.id === userId);
        newWorkFlow.recipients.splice(index, 1);
        setWorkflowData(newWorkFlow);
        saveChanges(newWorkFlow);
    }

    useEffect(() => {
        getAdGroups();
        getAdUsers();
    }, [])


    if (pageLoading) {
        return <div><UILIB.Loading type={3} /></div>
    }
    if (addingRecipient) {
        return <Fragment>
            <h2>Add a Recipient</h2>
            <p>Use the options below to find recipients and groups to add</p>
            <UILIB.TextInput className="textInput input" onChange={(event) => { setSearchText(event.target.value) }} value={searchText} />
            <div>
                {searchText && searchText.length > 2 &&
                    <div style={{ display: "flex" , width: "100%", flexFlow: "wrap" }}>
                        {groupData.filter(g => g.displayName.toLowerCase().indexOf(searchText.toLowerCase()) > -1).map(g => {
                            return <div style={{
                                border: "1px solid #CCC", borderRadius: "10px", padding: "3px 5px", display: "flex" , marginRight: "5px", marginBottom: "5px", cursor: "pointer"
                            }}
                                onClick={() => { selectUserOrGroup("group", g) }}>
                                <div style={{ border: "1px solid #FF00FF", borderRadius: "10px", padding: "3px", marginRight: "5px", fontSize: "10px" }}>Group</div>
                                {g.displayName}
                            </div>
                        })}

                        {userData.filter(u => u.displayName.toLowerCase().indexOf(searchText.toLowerCase()) > -1).map(u => {
                            return <div style={{
                                border: "1px solid #CCC", borderRadius: "10px", padding: "3px 5px", display: "flex" , marginRight: "5px", marginBottom: "5px", cursor: "pointer"
                            }} onClick={() => { selectUserOrGroup("user", u) }}>
                                <div style={{ border: "1px solid #0000FF", borderRadius: "10px", padding: "3px", marginRight: "5px", fontSize: "10px" }}>User</div>
                                {u.displayName}
                            </div>
                        })}
                    </div>
                }
            </div >
        </Fragment>
    }


    return <Fragment>

        <h2>Add / Edit a Workflow Recipients</h2>
        <div className="text-right mar-b15">
            <UILIB.Button value="Add a Recipient" onClick={() => { setAddingRecipient(true) }} />
        </div>

        <UILIB.Table header={tableHeader} data={workflowData.recipients && Array.isArray(workflowData.recipients) ? workflowData.recipients.map(row => {
            return {
                "recipient": { value: row.displayName, raw: row.displayName },
                "emailAddress": { value: row.emailAddress, raw: row.emailAddress },
                "type": { value: row.type, raw: row.type },
                "options": { value: <div className="icon icon-trash2" title="Delete" alt="Delete" style={{ cursor: "pointer" }} onClick={() => { deleteUser(row.id) }}></div>, raw: "" },
            }
        }) : []
        } />

        <p style={{ fontSize: "14px", textAlign: "center" }} className="mar-t15">
            For changes other than to the mailing list, please send the requirements as an <a href="https://360.agilico.co.uk/serviceadmin/customerCare/appsSupportRequest/Default.asp?" target="_blank" rel="noreferrer">app support request</a>.
        </p>


    </Fragment>
}