import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { useParams, useHistory } from 'react-router-dom';
import { checkFormErrors } from '../../../../classes/formErrors/formErrors';
import { getDeviceCollectFormErrors } from '../../../../classes/formErrors/repository';
import BlockDetailPage from './tabs/blockDetailPage';
import BlockAddressPage from './tabs/blockAddressPage';
import BlockInstallPage from './tabs/blockInstallPage';
import BlockCollectPage from './tabs/blockCollectionPage';


export default function DeviceCollect() {

  const history = useHistory();
  const { formid } = useParams();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [existingform, setExistingform] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [formData, setFormData] = useState({});
  const [tabErrors, setTabErrors] = useState([]);
  const [formErrors, setFormErrors] = useState(getDeviceCollectFormErrors());
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultBanner);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';

  
  const submitForm = async (formData, sendEmail)  => {

    try {

      const hasInstalls = (formData.installs.length > 0) ? 1 : 0;
      const hasRemovals = (formData.removals.length > 0) ? 1 : 0;

      let localData = { 
        tabErrorsLocal: [],
        foundAddressErrors: false,
        addressRequiredCnt: 1 + (hasInstalls + hasRemovals),
        foundRemoveErrors: false,
        removeCnt: 0 };

      if(formData.selected.length < localData.addressRequiredCnt) {
        localData.foundAddressErrors = true;
        localData.tabErrorsLocal[1] = 1;
      }

      formData.removals.forEach(i => { if(i.removalSelected) localData.removeCnt++ });
      if(Number(formData.removals.length) > 0 && Number(localData.removeCnt) === 0) {
        localData.foundRemoveErrors = true;
        localData.tabErrorsLocal[3] = 3;
      }

      const formResult = await checkFormErrors(formErrors, setFormErrors, localData.tabErrorsLocal, setTabErrors, formData, null);

      if (formResult.errorCount === 0 && !localData.foundAddressErrors && !localData.foundRemoveErrors) {

        setBannerError(defaultBanner);
        setSubmitted(true); 
        setSaving(true);

        const hostData = {
          hostname: window.location.host,
          protocol: window.location.protocol }

        formData.hostName = hostData.hostname;
        formData.sendEmail = sendEmail;

        const result = await Axios.put(`/entities/workflow/installs/actionSaveFormData/${formData.dealId}`, formData).then(api => api.data);
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
          setSaving(false);
        } else {
          if(!sendEmail) { 
            history.push({ pathname: '/logisticsHub', state: { tab: history.location.state.tab } });
          } else {
            await actionGetData();
            setSaving(false);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setSaving(false);
      setBannerError({ error: true, message: exceptionError });
    }
  }

  const handleAppendBaseData = (formData)  => {

    const dataObject = {
      emailAttachment: null,
      fnInstall: null,
      fnCollection: null };

    if (formData.installs.length > 0 && formData.removals.length > 0) dataObject.emailAttachment = 0;
    if (formData.installs.length < 1) dataObject.emailAttachment = 2;
    if (formData.removals.length < 1) dataObject.emailAttachment = 1;

    dataObject.fnInstall = (formData.installs && formData.installs.length > 0)
      ? 'InstallForm_' + String(formData.finalDealNo).trim() + '_' + String(formData.accountNo).trim() + '_' + String(formData.companyName).trim()
      : null;

    dataObject.fnCollection = (formData.removals && formData.removals.length > 0)
      ? 'CollectionForm_' + String(formData.finalDealNo).trim() + '_' + String(formData.accountNo).trim() + '_' + String(formData.companyName).trim()
      : null;

    return dataObject;
  }


  const actionGetData = async ()  => {
    try {
      const existingLocal = history.location.state && history.location.state.existingForm ? true : false;
      const existingRemote = formid ? true : false
      if(existingLocal) {
        const installData = await Axios.post(`/entities/workflow/installs/actionGetFormData/${history.location.state.dealId}/${history.location.state.uuid}`);
        if(!installData.data.result) history.push('/viewLinkError');
        setFormData(installData.data.result);
        setExistingform(history.location.state.existingForm);
      } else if(existingRemote) {
        const installData = await Axios.post(`/entities/workflow/installs/actionGetFormData/${history.location.state.dealId}/${history.location.state.uuid}`);
        if(!installData.data.result) history.push('/viewLinkError');
        setFormData(installData.data.result);
        setExistingform(true);
        history.replace({ state: { tab: 1 } });
      } else {
        const installData = await Axios.post(`/entities/workflow/installs/actionGetFormData/${history.location.state.dealData.dealId}`, history.location.state);
        if(!installData.data.result) history.push('/viewLinkError');
        const found = history.location.state.dealData.dealNote.find(x => x.noteSource = 'External Notes');
        const baseData = {
          ...installData.data.result,
          ...handleAppendBaseData(installData.data.result),
          addressId: history.location.state.dealData.addressId,
          selected: [],
          moveDate: null,
          emailToAddress: null,
          emailToName: null,
          parkingOptions: "Not Required",
          deliveryMethod: "Agilico",
          deviceOwnership: "Agilico",
          customerNotes: (found) ? found.theNotes : null }

        baseData.selected.push({ addressId: history.location.state.dealData.addressId, addressType: 2, installId: null });

        setFormData(baseData);
        setExistingform(history.location.state.existingForm);
      }
    } catch (error) {
      console.log("error loading form");
      history.push('/viewLinkError');
    }
  }

  useEffect(() => {  
    const actionAsync = async () => {
      setLoading(true);
      await actionGetData();
      setLoading(false);
    }
    actionAsync();
  }, [])


  const tabs = [
    { label: "Details", value: 0 }, 
    { label: "Addresses", value: 1 }, 
    { label: "Installs", value: 2, disabled: formData.installs && formData.installs.length > 0 ? false : true }, 
    { label: "Collections", value: 3, disabled: formData.removals && formData.removals.length > 0 ? false : true }];

  if (loading) return <UILIB.Loading type={3} />

  return <div className="flex-container row center wrap width-100 pad-l5 pad-r5">
      <div className="flex-item start flex-grow-1 mar-t15">
        <UILIB.Button 
          className="white outline" 
          value="Back" 
          iconBefore="icon-arrow-left" 
          onClick={() => history.push({ pathname: '/logisticsHub', state: { tab: history.location.state.tab  } })} />
      </div>
      <div className="flex-item end mar-t15">
        <UILIB.Button 
          disabled={(formData.status === 2 || formData.status === 3) || saving}
          loading={saving}
          value={"Save and Exit"}
          onClick={async () => await submitForm(formData, false)} />
        <UILIB.Button
          disabled={(formData.status === 2 || formData.status === 3) || saving || (!formData.emailToName || !formData.emailToAddress)}
          loading={saving}
          value={!submitted && !existingform ? "Save and send Email" : "Update and send Email"}
          onClick={async () => await submitForm(formData, true)} />  
      </div>
      {bannerError.error && <div className="flex-container end width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
      <div className="col-xs-12">
        <UILIB.SubNav tabs={tabs} 
          currentTab={currentTab} 
          outerClasses="mar-t15 mar-b15" 
          onClick={(newId) => setCurrentTab(newId)} 
          errored={{ option: 1, tabs: tabErrors }} />
      </div>
      {currentTab === 0 &&
      <div className="col-xs-12 col-sm-12 col-md-12">
        <BlockDetailPage
          formData={formData}
          setFormData={setFormData}
          loading={loading}
          formErrors={formErrors} />
      </div>}
      {currentTab === 1 &&
      <div className="col-xs-12 col-sm-12 col-md-12">
        <BlockAddressPage
          formData={formData}
          setFormData={setFormData}
          loading={loading}
          formErrors={formErrors} />
      </div>}
      {currentTab === 2 &&
      <div className="col-xs-12 col-sm-12 col-md-12">
        <BlockInstallPage
          formData={formData}
          setFormData={setFormData}
          loading={loading}
          formErrors={formErrors} />
      </div>}
      {currentTab === 3 &&
      <div className="col-xs-12 col-sm-12 col-md-12">
        <BlockCollectPage
          formData={formData}
          setFormData={setFormData}
          loading={loading}
          formErrors={formErrors} />
      </div>}
  </div>
}


