import { useState, useEffect, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import UILIB from 'components';
import Axios from 'classes/axios';
import ConsumablesLookUpCustomerDetails from './consumablesLookUpCustomerDetails';
import ConsumablesLookUpSerialNumberDetails from './ConsumablesLookupSerialNumberDetails';
import ConsumablesLookUpCustomer from './consumablesLookUpCustomer';
import ConsumablesLookUpSerialNumber from './consumablesLookUpSerialNumber';
import SerialLookupData from './consumablesLookUpSerialNumberData';
import CurrentRequests from './currentRequests';
import CurrentAlerts from './currentAlerts';


export default function ConsumablesLookUp() {

  const history = useHistory();
  const { context, id } = useParams();
  const [entityLookup, setEntityLookup] = useState({ showSearch: false });
  const [render, setRender] = useState(false);

  useEffect(() => { 
    const actionAsync = async () => {
      switch(context) {
        case 'customer':
          const customerResponse = await Axios.get(`/entities/workflow/actionGetLookupData/${context}/${id}`);
          setEntityLookup({ ...entityLookup, context, ...customerResponse?.data?.result, showSearch: true });
          break;
        case 'equipment':
          const equipmentResponse = await Axios.get(`/entities/workflow/actionGetLookupData/${context}/${id}`);
          setEntityLookup({ ...entityLookup, context, ...equipmentResponse?.data?.result, showSearch: true });
          break;
        default:
          console.info("no incomming params detected");
          setEntityLookup({ ...entityLookup, showSearch: true });
      }
    }
    actionAsync(); 
  }, [render, context, id])


  return <Fragment>    
    <div className="flex-container row mar-t10 mar-r5 mar-l5">
      <UILIB.Paper className='flex-item row width-100 mar-b5'>
        <div className='flex-item start width-100 mar-b20'><h3>{`Toner & Meter History ${(entityLookup?.altName) ? ` - ${entityLookup.altName}` : ''}`}</h3></div>  
        <div className='flex-item start flex-grow-1'>
          <UILIB.Button value="Back" className="grey" onClick={() => history.push('/consumablesHub')} />
        </div>
      </UILIB.Paper>
      {entityLookup.showSearch && <Fragment>    
        <div className='width-100 mar-b5'>
          <ConsumablesLookUpCustomer render={render} setRender={setRender} />
        </div>
        <div className='width-100 mar-b5'>
          <ConsumablesLookUpSerialNumber render={render} setRender={setRender} />
        </div>
      </Fragment>}
      {(entityLookup.hasOwnProperty('serialNumber') && entityLookup.context === 'equipment') && <div className='width-100 pad-t6 pad-b3'>
        <ConsumablesLookUpSerialNumberDetails entityLookup={entityLookup} />
      </div>}
      {(entityLookup.hasOwnProperty('serialNumber') && entityLookup.context === 'equipment') && <div className='width-100 pad-t3 pad-b3'>
        <CurrentAlerts entityLookup={entityLookup} />
      </div>}
      {(entityLookup.hasOwnProperty('serialNumber') && entityLookup.context === 'equipment') && <div className='width-100 pad-t3 pad-b3'>
        <CurrentRequests context={entityLookup.context} entityLookup={entityLookup} setEntityLookup={setEntityLookup} />
      </div>}
      {(entityLookup.hasOwnProperty('serialNumber') && entityLookup.context === 'equipment') && <div className='width-100 pad-t3 pad-b3'>
        {<SerialLookupData entityLookup={entityLookup} />}
      </div>}
      {entityLookup.hasOwnProperty('customerId') && entityLookup.context === 'customer' && <div className='width-100 mar-t5'>
        <CurrentAlerts entityLookup={entityLookup} />
      </div>}
      {entityLookup.hasOwnProperty('customerId') && entityLookup.context === 'customer' && <div className='width-100 mar-t5'>
        <ConsumablesLookUpCustomerDetails customerId={entityLookup.customerId} setEntityLookup={setEntityLookup} />
      </div>}
      {entityLookup.hasOwnProperty('customerId') && entityLookup.context === 'customer' && <div className='width-100 mar-t5'>
        <CurrentRequests context={entityLookup.context} entityLookup={entityLookup} setEntityLookup={setEntityLookup} />
      </div>}
    </div>
  </Fragment>
}