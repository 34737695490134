import { Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import * as functions from '../../../shared/functions' 
import UILIB from 'components';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ViewImported from '../modal/viewImported';
import UploadControl from '../controls/uploadControl';



const headerData = [
  { label: '#', value: 'id', type: 'number', width: '50px' },
  { label: 'Upload Type', value: 'jobName', type: 'string', width: '250px' },
  { label: 'Uploaded By', value: 'accountUserName', type: 'string', width: '250px' },
  { label: 'Upload Date', value: 'createdAt', type: 'string', width: '250px' },
  { label: 'Total Lines', value: 'totalLines', type: 'number' },
  { label: 'Failed Lines', value: 'failed', type: 'number' },
  { label: 'Status', value: 'status', type: 'string', width: '200px' },
  { label: 'Download', value: 'downloadUploadedCSV', type: 'string', width: '150px' },
  { label: 'View', value: 'view', type: 'number', width: '70px' }
]

export default function UploadTable(props) {

  const openImportRowsModal = async (props) => { 
    props.setUploadedRowsModal(props.row);
    props.setShowUloadedRowsModal(true);
  }

  const handleUploadListItemClick = async (event, newValue) => { 
    console.log(event)
    props.uploadTableSortHandler.current.filter[0] = handleUploadFiltering(props.uploadTableSortHandler, newValue);
    props.setFleetRender(props.fleetRender === 0 ? 1 : 0);
  }

  const handleStatusListItemClick = async (event, newValue) => { 
    props.uploadTableSortHandler.current.filter[1] = handleStatusFiltering(props.uploadTableSortHandler, newValue);
    props.setFleetRender(props.fleetRender === 0 ? 1 : 0);
  }

  const handleUploadFiltering = (handler, dataObj) => { 
    if(handler.current.filter[0] === 'View All Uploads') return { label: 'View All Uploads', value: null };
    return { label: dataObj.label, value: dataObj.id }
  }

  const handleStatusFiltering = (handler, dataObj) => { 
    if(handler.current.filter[0] === 'View All Statuses') return { label: 'View All Statuses', value: null };
    return { label: dataObj.label, value: dataObj.id }
  }

  const tableDataResult = props.tableData.map(row => {
    let statusType = { data: <UILIB.Chip className="table grey text-small width-100" value={row.status} />, value: 0 };
    if (row.status === 'Upload Complete') statusType = { data: <UILIB.Chip className="table green text-small width-100" value={row.status} />, value: row.status };
    if (row.status === 'Rollback Data Transaction') statusType = { data: <UILIB.Chip className="table red text-small width-100" value={row.status} />, value: row.status };

    const downloadUrl = `/fleet/uploadManagement/${row.id}/downloadFile`
    const downloadHandler = functions.downloadFileHandler.bind(null, 'post', downloadUrl, row.id, row.origFileName, 'csv', props.downloadingFile, props.setDownloadingFile, { data: row });
    const addDownloadButton = functions.addDownloadButtonUILIB.bind(null, false, row.id, "Download", props.downloadingFile, props.setDownloadingFile, downloadHandler);

    return {
      id: { value: row.id, raw: row.id },
      jobName: { value: functions.getUploadType(row), raw: functions.getUploadType(row) },
      status: { value: statusType.data, raw: statusType.value},
      createdAt: { value: formatDateTime(row.createdAt, 'YYYY-MM-DD HH:mm'), raw: row.createdAt },
      totalLines: { value: row.totalLines, raw: row.totalLines },
      failed: { value: row.failed, raw: row.failed },
      origFileName: { value: row.origFileName, raw: row.origFileName },
      downloadUploadedCSV: { value: addDownloadButton(), raw: row.origFileName },
      accountUserName: { value: row.AccountUserName, raw: row.AccountUserName },
      view: { 
        value: (
          <UILIB.Button 
            className="secondary table text-small width-100"
            disabled={((Number(row.failed) === 0 && Number(row.totalLines) === 0) || Number(row.complete === 2)) ? true : false}
            onClick={async () => { await openImportRowsModal({ ...props, row: row })}} 
            value="view"      
          />
        ),
        raw: row.failed
      }
    }
  })
  
  
  return (
    <Fragment>
      <UILIB.Paper className='mar-l10 mar-r10'>
        <Box display="flex" justifyContent="flex-end">
          <FormControl sx={{ mt: 1, minWidth: '200px' }} variant="standard">
            <UploadControl 
              uploadFileHandler={props.uploadFileHandler}
              setUploadFileHandler={props.setUploadFileHandler}
              fileUploadJobActive={props.fileUploadJobActive}
              setFileUploadJobActive={props.setFileUploadJobActive}
              selectedFile={props.selectedFile}
              setSelectedFile={props.setSelectedFile}
            />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: '200px' }} variant="standard">
            <Autocomplete
              disablePortal
              size="small"
              onChange={(event, newValue) => handleStatusListItemClick(event, newValue)}
              options={functions.statusClassData()}
              sx={{ width: 300 }}
              defaultValue={{ label: 'View All Statuses', id: 0 }}
              renderInput={(params) => <TextField {...params} label="Select a status type" />}
            />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: '200px' }} variant="standard">
            <Autocomplete
              disablePortal
              size="small"
              onChange={(event, newValue) => handleUploadListItemClick(event, newValue)}
              options={functions.uploadClassData()}
              sx={{ width: 300 }}
              defaultValue={{ label: 'View All Uploads', id: 0 }}
              renderInput={(params) => <TextField {...params} label="Select an upload type" />}
            />
          </FormControl>
        </Box>
        <UILIB.Table
            height='100%'
            className='small' 
            overflowX='auto'
            overflowY='hidden'
            loading={props.loading}
            header={headerData}
            data={tableDataResult}
            sortable={true}
            filter={true}
            resetFilters={false}
            goExport={true}
            autoResize={false}
            defaultOrderBy='createdAt'
            defaultOrderDir='DESC'
            exportEnable={true} 
            tableFilterHandler={props.uploadTableSortHandler}
            setTableFilterHandler={props.setUploadTableSortHandler}
            tableRender={props.fleetRender}
            setTableRender={props.setFleetRender}
          />
      </UILIB.Paper>
      {props.showUloadedRowsModal && (
        <ViewImported 
          downloadingFile={props.downloadingFile}
          setDownloadingFile={props.setDownloadingFile}
          showUloadedRowsModal={props.showUloadedRowsModal}
          setShowUloadedRowsModal={props.setShowUloadedRowsModal}
          uploadedRowsModal={props.uploadedRowsModal}
          setUploadedRowsModal={props.setUploadedRowsModal}
          tableSortHandler={functions.handleModalState(props).get}
          setTableSortHandler={functions.handleModalState(props).set}
        />
      )}
    </Fragment> 
  );
}