
export default function Permission({ departments, account, children }) {
  let canEnter = false;
  if (departments && departments.length && account && account.groups) {
      for (const department of departments) {
          if (department && account.groups.some(g => g.toLowerCase() === department.toLowerCase())) {
              canEnter = true
          }
      }
  } 
  if (canEnter) return children
  return null
}