import { useState, useEffect, memo, Fragment } from 'react';
import UILIB from 'components';
import { getRegions } from "classes/helpers";
import { useHistory } from 'react-router-dom';
import ProcessedDevices from './processedDevices';
import ProcessedOrders from './processedOrders';


const sendToVantageOnline = memo(() => {

  const tabs = [
    { label: "Processed Devices", value: 0, title: 'Processed Devices' },
    { label: "Processed Orders", value: 1, title: 'Processed Orders' }];

  const history = useHistory();
  const [regions, setRegions] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [serialNumber, setSerialNumber] = useState(null);

  const actionSetCurrentTab = (newId) => {
    if(newId === 0) setSerialNumber(null);
    setCurrentTab(newId);
  }

  useEffect(() => {
    const actionAsync = async () => {
      const regionsResult = await getRegions();
      const regionsMapped = regionsResult.map(region => { return { label: region.name, value: region.id } })
      setRegions(regionsMapped);
    }
    actionAsync();
  }, [])

  return <Fragment>
      <div className="flex-container row mar-b15 mar-l5 mar-r5 mar-t10">    
        <UILIB.Paper className='width-100 mar-b15'>
          <div className='flex-item width-100 mar-b10'>
            <div className='col-xs-6 '><h3>{tabs[currentTab].title}</h3></div>
          </div>
          <div className='flex-container row'>
            <div className='flex-item start flex-grow-1 align-center'> 
              <UILIB.Button value="Back" className="grey" onClick={() => history.push('/consumablesHub')} />
            </div>       
          </div>
        </UILIB.Paper>
        <div className="flex-item center width-100 mar-t10 mar-b10">
          <UILIB.SubNav tabs={tabs} currentTab={currentTab} onClick={(newId) => actionSetCurrentTab(newId)} />
        </div>
        {currentTab === 0 && <ProcessedDevices setCurrentTab={setCurrentTab} setSerialNumber={setSerialNumber} />}
        {currentTab === 1 && <ProcessedOrders serialNumber={serialNumber} regions={regions} />}
      </div>
    </Fragment>
})
export default sendToVantageOnline