import { useState, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import Axios from 'classes/axios';
import UILIB from 'components';
import { actionOpenDrawer, getDefaultFiltersStockRequests } from '../../functions'
import ActiveStocksRequestsTable from './activeStocksRequestsTable';
import ArchivedStocksRequestsTable from './archivedStocksRequestsTable';

export default function SiteStocksRequestTable() {
  const dispatch = useDispatch();

  const entityData = useRef([]);
  const requestCheckbox = useRef({});
  const [filter, setFilter] = useState({ button: 'Active' });
  const [filters, setFilters] = useState(getDefaultFiltersStockRequests());
  const defaultDialogue = { count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [showDeleteDialogue, setShowDeleteDialoge] = useState(defaultDialogue);
  const [showApproveDialogue, setShowApproveDialoge] = useState(defaultDialogue);
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [storeBannerError, setStoreBannerError] = useState(defaultBanner);
  const [remoteRender, setRemoteRender] = useState(false);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];
  
  const handleButtonsChanged = (buttonName, set) => {
    const newTableFilter = { ...filter };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = '';
    } else {
        newTableFilter.button = buttonName;
    }
    set(newTableFilter);
  };

  const tableDeleteRequest = () => {
    setBannerError(defaultBanner);
    setShowDeleteDialoge({ count: Object.values(requestCheckbox.current).filter(x => x).length, show: true });
  };

  const tableApproveRequst = () => {
    setBannerError(defaultBanner);
    setShowApproveDialoge({ count: Object.values(requestCheckbox.current).filter(x => x).length, show: true });
  };

  const actionDeleteRequest = async (event) => {
    try {
      setLoading(true);
      const requestIdArray = Array.from(new Set(Object.keys(event).filter(x => event[x]))).map(x => Number(x));
      const filtered = entityData.current.result.filter(x => requestIdArray.includes(x.requestID));
      const postData = filtered.map(x => { return { ...x, status: 1 }});
      const result = await Axios.put('/entities/serviceAdmin_siteStoreRequests', { entity: postData });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setShowDeleteDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setStoreBannerError(defaultBanner);
        setRemoteRender(!remoteRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setLoading(false);
  };

  const actionApproveRequest = async (event) => {
    try {
      setLoading(true);
      const requestIdArray = Array.from(new Set(Object.keys(event).filter(x => event[x]))).map(x => Number(x));
      const filtered = entityData.current.result.filter(x => requestIdArray.includes(x.requestID));
      const result = await Axios.post('/entities/workflow/actionApproveStockRequest', { requestIdArray: requestIdArray, entityData: filtered });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setShowApproveDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setStoreBannerError(defaultBanner);
        setRemoteRender(!remoteRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setLoading(false);
  };

  return <Fragment>
    {showDeleteDialogue.show && <UILIB.MessageBox
      header={'Delete Requests'}
      text={`Click OK to DELETE the selected Request`}
      loading={loading}
      errored={bannerError}
      onConfirm={async () => await actionDeleteRequest(requestCheckbox.current)}
      onCancel={() => setShowDeleteDialoge({ ...showDeleteDialogue, show: false })} />}
     {showApproveDialogue.show && <UILIB.MessageBox
      header={'Approve Requests'}
      text={`Click OK to APPROVE the selected Request`}
      loading={loading}
      errored={bannerError}
      onConfirm={async () => await actionApproveRequest(requestCheckbox.current)}
      onCancel={() => setShowApproveDialoge({ ...showApproveDialogue, show: false })} />}
    <UILIB.Paper className="width-100">
      <div className='flex-container row mar-b10'>
        <div className='flex-item flex-grow-1 start wrap'>
          <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Active' ? 'primary' : 'secondary')} name='Active' value='Active' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Archived' ? 'primary' : 'secondary')} name='Archived' value='Archived' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
        </div>
        <div className='flex-item end flex-grow-1'>
          <UILIB.Button 
            value="Delete" 
            className="button red" 
            disabled={(!Object.values(requestCheckbox.current).some(x => x) || filter.button !== 'Active')} 
            onClick={() => tableDeleteRequest()} />
          <UILIB.Button 
            value="Approve" 
            className="button secondary" 
            disabled={(!Object.values(requestCheckbox.current).some(x => x)) || filter.button !== 'Active' || storeBannerError.error} 
            onClick={() => tableApproveRequst()} />
          <UILIB.Button value="Filters" onClick={() => { actionOpenDrawer('ViewRequestFiltersDrawer', { filters: filters, setFilters: setFilters }, dispatch, true, { get: render, set: setRender }, '300px') }} />
        </div>
        {storeBannerError.error && <div className='flex-item end flex-grow-1 width-100'>
          <div className="errored message">{storeBannerError.message}</div>
        </div>}
      </div>
      <div className="width-100">
      {filter.button === 'Active' ? (
        <ActiveStocksRequestsTable
          setStoreBannerError={setStoreBannerError}
          remoteRender={remoteRender}
          filter={filter}
          filters={filters}
          requestCheckbox={requestCheckbox}
          entityData={entityData}
          updateTrigger={updateTrigger}
          setUpdateTrigger={setUpdateTrigger}
        />
      ) : (
        <ArchivedStocksRequestsTable
          remoteRender={remoteRender}
          filter={filter}
          filters={filters}
          entityData={entityData}
        />
      )}
      </div>
    </UILIB.Paper>
  </Fragment>
}