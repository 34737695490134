import BatchedOrders from './modules/batchedOrders';
import SiteSearch from './modules/siteSearch';
import SiteDevices from './modules/siteDevices';
import SiteStocks from './modules/siteStocks';
import NotesDrawer from './modules/notesDrawer';
import ViewNotes from './modules/notesView';
import AddNotes from './modules/notesAdd';
import GroupContainer from './modules/groupContainer';
import TonerContainer from './modules/tonerContainer';
import GenerateStocksReport from './modules/generateStocksReport';

const exported = {
  BatchedOrders,
  SiteSearch,
  SiteDevices,
  SiteStocks,
  NotesDrawer,
  ViewNotes,
  AddNotes,
  GroupContainer,
  TonerContainer,
  GenerateStocksReport
}
export default exported;