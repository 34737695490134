
export default function SubNav({ tabs, outerClasses, currentTab, onClick, errored = { messageText: "", option: 0 } }) {
    let outerC = "subNavHolder";
    if (outerClasses) outerC += " " + outerClasses;

    let errorType;
    if (typeof errored === "object") {
        errorType = errored.option;
    }

    return <div className={outerC}>
        {tabs.map((tab, index) => {
            let tabClasses = "subNav";
            if (tab.value === currentTab) {
                tabClasses += " selected";
            }
            if (tab.disabled) {
                tabClasses += " disabled"
            }

            let showError = (errorType === 1 && !tab.disabled && errored.tabs && errored.tabs.includes(index) ? " errored div " : "")
            
            return <div key={"tab_" + index} className={tabClasses + showError} onClick={() => tab.disabled ? {} : onClick(tab.value)}>{tab.label}</div>
        })}
    </div>
}