import { useState, useEffect, Fragment } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';
import { leaseCompanies, settlementFieldValues, settlementAgreemtntValues } from 'classes/quotesHelpers';
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getSettlementAdditionalDrawerFormErrors } from 'classes/formErrors/repository';
import { createSelectInput, createTextArea } from 'classes/controls/formControls';


export default function SettlementAdditionalDrawer({ onFinished, ourDeal, setOurDeal, settlement = { id: null } }) {

  const defaultError = { error: false, message: '' };
  const [leaseData, setLeaseData] = useState([]);
  const [formData, setFormData] = useState({ agreementNumber: '', agreementType: '', agreementProvider: '', settlementHow: '', notes: '', ...settlement });
  const defaultFormErrors = { agreementNumber: defaultError, agreementType: defaultError, agreementProvider: defaultError, settlementHow: defaultError, notes: defaultError };
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [bannerError, setBannerError] = useState(defaultError);
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


  const updateFieldEvent = (ev) => {
    setFormData({ ...formData, [ev.target.name]: ev.target.value });
  }

  const changeSettlementEvent = (ev) => {
    const found = leaseData.find(x => x.dmc_agreementnumber === ev.target.value);
    setFormData({
      ...formData, 
      agreementNumber: ev.target.value,
      notes: (found) ? found.dmc_notes : ' ',
      agreementProvider: (found) ? found.dmc_leasecompany : -1
    });
  }

  const actionsubmitForm = async () => {
    setSaving(true);
    try {
      const formResult = await checkFormErrors(getSettlementAdditionalDrawerFormErrors(formData), setFormErrors, null, null, formData, null);
      if(formResult.errorCount === 0) {
        const result = await Axios.post(`/entities/workflow/deals/actionCreateUpdateSettlement/${ourDeal.dealID}`, formData);
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
          setSaving(false);
        } else {
          if(ourDeal.settlement.some(x => Number(x.id) === Number(settlement.id))) {
            const index = ourDeal.settlement.findIndex(x => Number(x.id) === Number(settlement.id));
            ourDeal.settlement[index] = { ...formData, ...result.data.result.settlement };
          } else {
            ourDeal.settlement.push(result.data.result.settlement);
          }
          if(result.data.result.product.length) {
            const productFiltered = ourDeal.product.filter(x => Number(x.settlementId) !== Number(result.data.result.settlement.id));
            productFiltered.push(...result.data.result.product);
            setOurDeal({ ...ourDeal, product: productFiltered });
            onFinished();
            setSaving(false);
            return;
          }
          setOurDeal(ourDeal);
          onFinished();
          setSaving(false);
        }
      } else {
        setSaving(false);
      }
    } catch (error) {
      console.log(error);
      setBannerError({ error: true, message: exceptionError });
      setSaving(false);
    }
  };

  useEffect(() => {
    const actionAsync = async () => {
      const response = await Axios.post(`/dynamics/leases/${ourDeal.crm.primaryAccount.accountid}`);
      if(!response?.data.length) return;

      const agreementnumbersMapped = response.data.map(x => { return { label: x.dmc_agreementnumber, value: x.dmc_agreementnumber, ...x } });
      agreementnumbersMapped.unshift({ label: "Please Select", value: -1 });
      setLeaseData(agreementnumbersMapped);
    };
    actionAsync();
  }, []);


  return <Fragment>
    <div className='flex-container row'>
      <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Add from Existing Agreement</div>
      <div className='flex-container row start wrap evenly width-100 mar-t2'>
        {createSelectInput('Agreement Number *', 'agreementNumber', (!ourDeal.isActive || saving || !leaseData.length), formData.agreementNumber, leaseData, (ev) => changeSettlementEvent(ev), formErrors, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {createSelectInput('Agreement Provider *', 'agreementProvider', (!ourDeal.isActive || saving), formData.agreementProvider, leaseCompanies, (ev) => updateFieldEvent(ev), formErrors, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {createSelectInput('Agreement Type *', 'agreementType', (!ourDeal.isActive || saving), formData.agreementType, settlementAgreemtntValues, (ev) => updateFieldEvent(ev), formErrors, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {createSelectInput('Settlement Method *', 'settlementHow', (!ourDeal.isActive || saving), formData.settlementHow, settlementFieldValues, (ev) => updateFieldEvent(ev), formErrors, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {createTextArea('Notes', 'notes', (!ourDeal.isActive || saving), formData.notes, (ev) => updateFieldEvent(ev), null, 'width-100', null, null, 'height-100-px')}
      </div>
      <div className='flex-container row start width-100 mar-t20 mar-r5'>
        <UILIB.Button className='green' disabled={(!ourDeal.isActive || saving || !formData.agreementNumber)} loading={saving} value='Submit' onClick={async () => await actionsubmitForm()} />
        <UILIB.Button className='grey' disabled={saving || !formData.agreementNumber} value='Cancel' onClick={() => onFinished()} />
        {bannerError.error && <div className="flex-item start wrap width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
    </div>
  </Fragment>
}