import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { pleaseSelect, createMultilineTextInput, createTextInput, createSelectInput, checkAndSet, newError, alphaNumericSpaceSpecialPattern } from '../generic';

export default function DriverCheckCRUD(props) {
    //Specific to entity
    const emptyEntity = {
        neurologicalDisorders: '',
        cardiovascularDisorders: '',
        psychiatricDisorders: '',
        drugOrAlcoholMisuse: '',
        visualDisorders: '',
        renalAndRespiratory: '',
        miscellaneousConditions: '',
        healthQuestionsDetails: '',
        healthMedicationDetails: '',
        signDisclaimerConformation: '' };
    const entityUrl = `/fleet/driverCheck`;
    const entityUrlWithId = `/fleet/driverCheck/${props.data.entity.id}`;
    const addFromEntityWithKey = {...emptyEntity, workEmail: props.data.entity.workEmail };
    const headerText = ' Driver Check - ';
    const yesNo = [pleaseSelect,'Yes','No'];
    const yes = 'Yes';
    const [workEmails, setWorkEmails] = useState();

    const loadDriverEmails = async () => {
        const driverEmailsData = await Axios.get(`/fleet/driverEmails`);
        const driverEmailsDataFormatted = driverEmailsData.data.result.map(function(item){ return item.workEmail });
        setWorkEmails([pleaseSelect, ...driverEmailsDataFormatted]);
    }

    const loadSpecificData = async () => {
        loadDriverEmails();
    }

    const evaluatedRulesPassed = (formErrorsTemp) => {
        let e = 0;
        e = e + checkAndSet(!entity.workEmail || entity.workEmail.length < 1 || entity.workEmail === pleaseSelect, formErrorsTemp, 'workEmail', newError('workEmail', 'Please select Driver Email'));
        e = e + checkAndSet(!entity.neurologicalDisorders || entity.neurologicalDisorders.length < 1 || entity.neurologicalDisorders === pleaseSelect, formErrorsTemp, 'neurologicalDisorders', newError('neurologicalDisorders', 'Please select Neurological Disorders'));
        e = e + checkAndSet(!entity.cardiovascularDisorders || entity.cardiovascularDisorders.length < 1 || entity.cardiovascularDisorders === pleaseSelect, formErrorsTemp, 'cardiovascularDisorders', newError('cardiovascularDisorders', 'Please select Cardiovascular Disorders'));
        e = e + checkAndSet(!entity.psychiatricDisorders || entity.psychiatricDisorders.length < 1 || entity.psychiatricDisorders === pleaseSelect, formErrorsTemp, 'psychiatricDisorders', newError('psychiatricDisorders', 'Please select Psychiatric Disorders'));
        e = e + checkAndSet(!entity.drugOrAlcoholMisuse || entity.drugOrAlcoholMisuse.length < 1 || entity.drugOrAlcoholMisuse === pleaseSelect, formErrorsTemp, 'drugOrAlcoholMisuse', newError('drugOrAlcoholMisuse', 'Please select Drug Or Alcohol Misuse'));
        e = e + checkAndSet(!entity.visualDisorders || entity.visualDisorders.length < 1 || entity.visualDisorders === pleaseSelect, formErrorsTemp, 'visualDisorders', newError('visualDisorders', 'Please select Visual Disorders'));
        e = e + checkAndSet(!entity.renalAndRespiratory || entity.renalAndRespiratory.length < 1 || entity.renalAndRespiratory === pleaseSelect, formErrorsTemp, 'renalAndRespiratory', newError('renalAndRespiratory', 'Please select Renal And Respiratory'));
        e = e + checkAndSet(!entity.miscellaneousConditions || entity.miscellaneousConditions.length < 1 || entity.miscellaneousConditions === pleaseSelect, formErrorsTemp, 'miscellaneousConditions', newError('miscellaneousConditions', 'Please select Miscellaneous Conditions'));

        if (entity.neurologicalDisorders === yes
            || entity.cardiovascularDisorders === yes
            || entity.psychiatricDisorders === yes
            || entity.drugOrAlcoholMisuse === yes
            || entity.visualDisorders === yes
            || entity.renalAndRespiratory === yes
            || entity.miscellaneousConditions === yes) {

            e = e + checkAndSet(!entity.healthQuestionsDetails || entity.healthQuestionsDetails.length < 1, formErrorsTemp, 'healthQuestionsDetails', newError('healthQuestionsDetails', 'Please enter detail'));
            e = e + checkAndSet(!entity.healthQuestionsDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'healthQuestionsDetails', newError('healthQuestionsDetails', 'Please enter Health Questions Details without special characters'));

            e = e + checkAndSet(!entity.healthMedicationDetails || entity.healthMedicationDetails.length < 1, formErrorsTemp, 'healthMedicationDetails', newError('healthMedicationDetails', 'Please enter detail'));
            e = e + checkAndSet(!entity.healthMedicationDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'healthMedicationDetails', newError('healthMedicationDetails', 'Please enter Health Medication Details without special characters'));
        }

        e = e + checkAndSet(!entity.signDisclaimerConformation || entity.signDisclaimerConformation.length < 1, formErrorsTemp, 'signDisclaimerConformation', newError('signDisclaimerConformation', 'Please enter Sign Disclaimer Conformation'));
        e = e + checkAndSet(!entity.signDisclaimerConformation.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'signDisclaimerConformation', newError('signDisclaimerConformation', 'Please enter Sign Disclaimer Conformation without special characters'));

        return e > 0;
	}

    const htmlButtons = (loading) => {
        if (loading) {
            return <div />
        } else {
            return <div>
                <UILIB.Button loading={saving} className='mar-t10' value='Action' onClick={submitForm} />
            </div>
        }
    }
    
    const htmlRows = (loading) => { 
        if (loading) {
            return <div />
        } else {
            return <div className='mar-t15'>
                {createTextInput('First Name', 'firstName', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Last Name', 'lastName', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Job Role', 'jobRole', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Region', 'region', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Office', 'office', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Driver Type', 'driverType', false, 40, entity, entityErrors, changeInput)}
                {createSelectInput('Driver Email', 'workEmail', workEmails, isActiveOnEdit, entity, entityErrors, changeInput)}
                {createTextInput('Managers Email', 'managersEmail', false, 40, entity, entityErrors, changeInput)}
                {createSelectInput('Neurological Disorders', 'neurologicalDisorders', yesNo, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Cardiovascular Disorders', 'cardiovascularDisorders', yesNo, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Psychiatric Disorders', 'psychiatricDisorders', yesNo, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Drug Or Alcohol Misuse', 'drugOrAlcoholMisuse', yesNo, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Visual Disorders', 'visualDisorders', yesNo, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Renal And Respiratory', 'renalAndRespiratory', yesNo, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Miscellaneous Conditions', 'miscellaneousConditions', yesNo, isActive, entity, entityErrors, changeInput)}
                {createMultilineTextInput(
                    'If you have answered yes to any of the questions above then please provide as much detail on the health/medical matter, treatment and/or treatment plan here',
                    'healthQuestionsDetails', isActive, 9999, entity, entityErrors, changeInput)}
                {createMultilineTextInput(
                    'If you are currently taking any medication which you have been advised may affect your ability to drive, please provide details here and include the dosage',
                    'healthMedicationDetails', isActive, 9999, entity, entityErrors, changeInput)}
                {createTextInput(
                    'By signing this document, I confirm that I have completed all required fields to the best of my knowledge, and should my circumstances change, I will inform my Line Manager and the HR Team immediately. Failure to do so may result in disciplinary proceedings',
                    'signDisclaimerConformation', isActive, 50, entity, entityErrors, changeInput)}
                {createTextInput('Created At', 'createdAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Created By', 'createdBy', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated At', 'updatedAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated By', 'updatedBy', false, 20, entity, entityErrors, changeInput)}
            </div>
        }
    }

    //Generic
    const addType = 'add';
    const addFromType = 'addFrom';
    const editType = 'edit';
    const deleteType = 'delete';
    const defaultPageMessage = 'Please complete the below and then click Action.';
    const deleteConfirmationMessage = 'Please confirm deletion of the below details by clicking Action.';
    const failValidationMessage = 'Please correct any errors and click Action again.';
    const rowVersionMessage = 'A newer version of this record has already been saved. Please reload the data.';
    const missingRecordMessage = 'Unable to find record in db. Please reload the data.';
    const invalidSubmitMessage = 'Invalid submit request, please advise support.';
    const saveErrorMessage = 'Unable to save, please advise support.';
    const [loadingData, setLoadingData] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [isActiveOnEdit, setIsActiveOnEdit] = useState(true);
    const [entity, setEntity] = useState(emptyEntity);
    const [entityErrors, setEntityErrors] = useState(emptyEntity);
    const [errored, setErrored] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState(defaultPageMessage);

    useEffect(() => {
        const loadEntity = async () => {
            if (props.data.type === addType) {
                setEntity(emptyEntity);
            } else if (props.data.type === addFromType) {
                const entity = addFromEntityWithKey;
                setEntity(entity);
            } else {        
                const entityData = await Axios.get(entityUrlWithId);
                setEntity(entityData.data.result);
            }

            setLoadingData(false);
        }

        if (props.data.type === deleteType) {
            setIsActive(false);
            setIsActiveOnEdit(false);
            setMessage(deleteConfirmationMessage)
        }

        if (props.data.type === editType || props.data.type === addFromType) {
            setIsActiveOnEdit(false);
        }

        loadSpecificData();
        loadEntity();
    }, [])

    const changeInput = (event) => {
        let newFormData = JSON.parse(JSON.stringify(entity));
        newFormData[event.target.name] = event.target.value;
        setEntity(newFormData);

        if (event.target.name in entityErrors) {
			let newFormErrors = { ...entityErrors };
			newFormErrors[event.target.name] = '';
			setEntityErrors(newFormErrors);
		}
    }

    const validSubmit = () => {
        let formErrorsTemp = emptyEntity;        
        if (!evaluatedRulesPassed(formErrorsTemp)) {
            return true;
        }

        setEntityErrors(formErrorsTemp);
        setMessage(failValidationMessage);
        return false;
    }

    const processDelete = async () => {
        if (props.data.type === deleteType) {
            setSaving(true);
            await Axios.delete(entityUrlWithId, entity);
            setDeleted(true);
            props.sync({ deleted: true, id: props.data.entity.id });
            setSaving(false);

            return true;
        }

        return false;
    }

    const processAdd = async () => {
        if (props.data.type === addType || props.data.type === addFromType) {
            setSaving(true);
            const entityResult = await Axios.post(entityUrl, entity);
            props.data.type = editType;
            props.data.entity.id = entityResult.data.result.id;
            setEntity(entityResult.data.result);
            props.sync(entityResult.data.result);
            setIsActiveOnEdit(false);
            setSaving(false);

            return true;
        }
        
        return false;
    }

    const processEdit = async () => {
        if (props.data.type === editType) {
            setSaving(true);
            const entityResult = await Axios.put(entityUrlWithId, entity);

            if (entityResult.status === 200) {
                setEntity(entityResult.data.result);
                props.sync(entityResult.data.result);
            }

            if (entityResult.status === 409) {
                setMessage(rowVersionMessage);
                setErrored(true);
            }

            if (entityResult.status === 400) {
                setMessage(missingRecordMessage);
                setErrored(true);
            }
            
            setSaving(false);
            return true;		
        }

        return false;
    }

    const submitForm = async () => {
        try {
            if (await processDelete()) { return; }
            if (validSubmit()) {
                if (await processAdd()) { return; }
                if (await processEdit()) { return; }

                setMessage(invalidSubmitMessage);
                setErrored(true);
            }
        }
        catch (err) {
            console.log(err);
            setMessage(saveErrorMessage);
            setErrored(true);
        }
    }

    if (deleted) return <UILIB.Paper className='width-100'><p className="center">Deleted</p></UILIB.Paper>

    if (errored) return <UILIB.Paper className='width-100'><p className="center">{message}</p></UILIB.Paper>

    if (loadingData) return <UILIB.Loading type={3} />

    return (
        <div className="mar-l15 mar-r15">
            <h2 className='mar-b10'>{(props.data.type === addType || props.data.type === addFromType) ? 'Add' : 'Edit'}{headerText}{entity.id}</h2>
            <p>{message}</p>
            {htmlButtons(loadingData)}
            {htmlRows(loadingData)}
        </div> 
    )
}