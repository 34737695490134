import { Fragment } from 'react';
import BlockGroupTable from '../tables/blockGroupTable';


export default function Hubs(props) {
  return <Fragment> 
    <div className="flex-container row">
      <div className="col-xs-12 col-sm-12 col-md-12" style={{ padding: 10 }}> 
        <BlockGroupTable
          setHubTableData={props.setHubTableData}
          hubTableData={props.hubTableData}
          hubTableDataOpen={props.hubTableDataOpen}
          setHubTableDataOpen={props.setHubTableDataOpen}
          permissions={props.permissions}
          selectedGroupIndex={props.selectedGroupIndex}
          setSelectedGroupIndex={props.setSelectedGroupIndex}
          selectedHubIndex={props.selectedHubIndex}
          setselectedHubIndex={props.setselectedHubIndex}
          showPermissionsModal={props.showPermissionsModal}
          setShowPermissionsModal={props.setShowPermissionsModal}
          groupTableSortHandler={props.groupTableSortHandler}
          setGroupTableSortHandler={props.setGroupTableSortHandler}
          groupChildTableSortHandler={props.groupChildTableSortHandler}
          setGroupChildTableSortHandler={props.setGroupChildTableSortHandler}
          entityTableSearch={props.entityTableSearch}
          setEntityTableSearch={props.setEntityTableSearch}
          groupRender={props.groupRender}
          setGroupRender={props.setGroupRender}
          groupExpandRow={props.groupExpandRow}
          setGroupExpandRow={props.setGroupExpandRow}
          renderLoading={props.renderLoading}
          setRenderLoading={props.setRenderLoading}
        />
      </div>
    </div>
  </Fragment> 
}
