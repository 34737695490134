import { Fragment } from 'react';
import Button from '../Button/Button'
import AutoComplete from 'components/AutoComplete';

export default function MessageSelectBox({ header, text, loading = false, showConfirm = true, showCancel = true, confirmText = 'Ok', cancelText = 'Cancel', onConfirm, onCancel = () => { }, errored = null, selectQuery }) {

  const showError = (errored && typeof(errored) === 'object' && errored.error)

  return <Fragment>
    <div className="modalBackground">
      <div className="modalContent" style={{ minWidth: "360px" }}>
        {Boolean(header) && <div className="text-center text-xlarge mar-b5">{header}</div>}
        {Boolean(text) && <div className="text-center text-14">{text}</div>}
        <div className="flex-container row center width-100 mar-t10">
            <AutoComplete
                className="min-height-50"
                name='customerId'
                blank='No Customers Found'
                query={selectQuery}
                enableButton={true}
                onClick={(data) => onConfirm(data, 'customerId')} 
                buttonText='Reserve' />
        </div>
        <div className="flex-container row center width-100 mar-t10">
          {showCancel && <Button disabled={loading} className="secondary mar-5" value={cancelText} onClick={onCancel} />}
        </div>
        {showError && <div className="flex-container row end flex-grow-1 width-100">
          <div className="errored message">{errored.message}</div>
        </div>}
      </div>
    </div>
  </Fragment>
}