
import { Fragment } from 'react'
import * as functions from '../shared/functions';
import { 
  DialogContent, 
  DialogTitle, 
  Box, 
  Button 
} from '@mui/material';



export default function UploadFileRequiredFields(props) 
{
    const handleFieldChange = (event) => {
      const uploadFileRequiredNew = structuredClone(props.uploadFileHandler);
      uploadFileRequiredNew[event.target.name] = event.target.value;
      props.setUploadFileHandler(uploadFileRequiredNew);
    };

    const handleNext = () => {
      const uploadFileRequiredNew = structuredClone(props.uploadFileHandler);
      uploadFileRequiredNew.stage = 2;
      props.setUploadFileHandler(uploadFileRequiredNew);
    };

    const versionTruith = (props.uploadFileHandler.versionNumber ? props.uploadFileHandler.versionNumber.length < 3 : true);
    const incrementTruith = (props.uploadFileHandler.incrementNumber ? props.uploadFileHandler.incrementNumber.length < 3 : true);

    return <Fragment>
      <DialogTitle>Please enter required fields</DialogTitle>
      <DialogContent>
        <p className={"mar-b10"}>Please enter the version number and increment name for the release you are uploading.</p>
        <Box
          display="flex" 
          alignItems="center"
          sx={{ pt: 1, display: 'flex', justifyContent: 'space-between', bgcolor: 'background.paper', borderRadius: 1 }}
        >
          {functions.addTextFieldMUI("small", "Version Number", 'versionNumber', "outlined", !versionTruith ? props.uploadFileHandler.versionNumber : '', (ev) => handleFieldChange(ev))}
          {functions.addTextFieldMUI("small", "Increment Name", 'incrementNumber', "outlined", !incrementTruith ? props.uploadFileHandler.incrementNumber : '', (ev) => handleFieldChange(ev))}
        </Box>
        <Box 
          display="flex" 
          alignItems="center"
          sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', bgcolor: 'background.paper', borderRadius: 1 }}
        >
          <Box>
              <Button
                size="small"
                className="btn-upload"
                color="primary"
                variant="contained"
                component="span" 
                disabled={versionTruith || incrementTruith}
                onClick={handleNext}
                sx={{ ml: 1, backgroundColor: '#00B200' }}
              > Next
              </Button>
              <Button
                size="small"
                className="btn-upload"
                color="primary"
                variant="contained"
                component="span"
                onClick={() => functions.handCloseUploadModal(props)}
                sx={{ minWidth: 70, ml: 1, backgroundColor: props.selectedFile && props.fileUploadJobActive.complete ? '#00B200' : '#e74c3c' }}
              > Cancel
              </Button>
            </Box>
          </Box>
      </DialogContent>
    </Fragment>
}