import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { timerPromise } from "classes/timers";

export default function OrderNotes({ dataLoaded, ourDeal, context, isActive }) {
  
  const [timeout, setTimout] = useState(null);
  const entity = (ourDeal.note && ourDeal.note.length) ? ourDeal.note.find(x => x.noteSource === context) : {};
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultBanner);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const actionUpdate = async () => {
    return await Axios.post(`/entities/workflow/deals/actionAddDealNote/${ourDeal.dealID}/${entity.notesID}/?&$filter=noteSource eq ${context}`, { entity: entity });
  };

  const updateNotes = async (ev) => {
    try { 
      const baseData = { ...entity, theNotes: ev.target.value, supression: true };
      Object.keys(baseData).forEach(x => entity[x] = baseData[x]);
      const response = await timerPromise(function() { return actionUpdate(); }, 500, timeout, setTimout);
      if(exceptions.includes(response.result.status)) {
        if(response.result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(response.result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setBannerError(defaultBanner);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
  } 

  return <Fragment>
    <div className="text-right">
      <UILIB.TextArea
        disabled={!isActive || !dataLoaded?.note}
        style={{ height: "140px", width: "100%", padding: "0", "resize": "none" }} 
        type="text"
        className="textInput input"
        name="warnings"
        value={entity.theNotes}
        onChange={async (event) => await updateNotes(event)} />
      {bannerError.error && <div className="flex-container end width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>
}