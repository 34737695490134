import Axios from './axios';
import { store } from 'store/store';
import { setRefreshToken, setAccessToken, setMomentSession, setSessionExpire, logout } from 'store/actions';
import moment from 'moment-timezone';


export function checkAccount() {
  var loggedIn = false;
  var accessToken = localStorage.getItem("accessToken");
  if (accessToken && accessToken.length) loggedIn = true;
  return loggedIn;
}

export async function getAccount() {
  var user = await Axios.get("/users/me");
  if (!user) return {};
  return { user: user.data };
}

export async function getSessionTimer(timeout) {
  return await new Promise(resolve => setTimeout(resolve, timeout));
}

export async function sessionAlive(timeout) {
  const accessToken = { token: 'preFetch' };
  do {
    try {
      const state = await store.getState();
      accessToken.token = state.accessToken;
      await Axios.get(`/session/ping`);
      await getSessionTimer(timeout);
    } catch (err) {
      console.log(err);
      await getSessionTimer(timeout);
    }
  } while (accessToken.token);
}

export function setEventListener(time) {
  const { dispatch } = store; 
  document.addEventListener("click", function () {
    try {
      dispatch(setMomentSession(moment().add(time.length, time.segment)));
      return () => { document.removeEventListener('click', () => {}) }
    }
    catch (err) { console.log(err); } 
  })
}

export async function sessionTimer(timeout, sessionTimeout) {

  const { dispatch } = store; 
  const accessToken = { token: 'preFetch' };
  const time = { segment: 'minutes', length: 120 };

  dispatch(setMomentSession(moment().add(time.length, time.segment)));
  dispatch(setSessionExpire(false));
  setEventListener(time);

  do {
    try {
      const state = await store.getState();
      accessToken.token = state.accessToken;
      await getSessionTimer(timeout);
      if(moment().diff(state.momentSession, time.segment) >= time.length) dispatch(setSessionExpire(true));  
      if(sessionTimeout === 0) onLogout(0);
    } catch (err) {
      await getSessionTimer(timeout);
    }
  } while (accessToken.token);
}

export function onLogout(actionType) { 

  const { dispatch } = store; 

  if(Number(actionType) === 0) { 
    dispatch(logout());
  };
  if(Number(actionType) === 1) {
    dispatch(setSessionExpire(false));
    dispatch(logout());
  }
}

export async function onLoggedIn(setSessionTimeout) {
  const { dispatch } = store; 
  const state = await store.getState();
  const result = await Axios.post('/getAccessToken', { refreshToken: state.refreshToken });
  dispatch(setRefreshToken(result.data.refreshToken));
  dispatch(setAccessToken(result.data.accessToken));
  dispatch(setSessionExpire(false));
  setSessionTimeout(60);
}



