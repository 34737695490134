import UILIB from 'components';
import { setDrawer } from 'store/actions';
import NotesDrawer from '../../../shared/drawers/notesDrawer';


export function tableContainer(onClick, icon, toolTip, colour, hover) {
  return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function actionOpenDrawer(type, entity, dispatch, enabled, sync, width) {
  if(!enabled) return false;
  const selector = (type, data) => {
    if(type === 'ViewNotesDrawer') return <NotesDrawer entity={entity} allowAdd={'/salesHub/accountmanagerview/deals/active'} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
  };
  dispatch(setDrawer({ 
    open: true, 
    width: width,
    content: selector(type, entity)
  }))
}

export function getNotesColour(row) {
  if (!row.dealNote.length) {
    return 'colour lightGrey'
  }
  if(row.dealNote.some(x => (x.noteSource.trim() === 'Internal Notes' || x.noteSource.trim() === 'External Notes')) && !row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 orange'
  }
  if(row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 red'
  }
}







