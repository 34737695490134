
import QuoteFiltersDrawer from './modules/drawer/quoteFiltersDrawer';
import ReportsTable from './modules/reportsTable';
import DraftTable from './modules/draftTable';
import QuotesTable from './modules/quotesTable';
import AwaitingApprovalTable from '../sophub/tables/awaitingApprovalTable';
import ApprovedTable from '../sophub/tables/approvedTable';
import InstallChecklistTable from '../logisticsHub/tables/installChecklistTable';
import ExportReport from './modules/reports/exportReport';
import MachineReport from './modules/reports/machineReport';
import OrderSheetReport from './modules/reports/orderSheetReport';
import PSSalesReport from './modules/reports/pSSalesReport';
import SalesReport from './modules/reports/salesReport';
import TotalSalesReport from './modules/reports/totalSalesReport';
import MachineReportDrawer from './modules/drawer/machineReportDrawer';
import PSSalesReportDrawer from './modules/drawer/pSSalesReportDrawer';
import SalesReportDrawer from './modules/drawer/salesReportDrawer';
import TotalSalesReportDrawer from './modules/drawer/totalSalesReportDrawer';
import AdminTable from './modules/adminTable';


const exported = {
  QuoteFiltersDrawer,
  DraftTable,
  QuotesTable,
  AwaitingApprovalTable,
  ApprovedTable,
  InstallChecklistTable,
  ReportsTable,
  ExportReport,
  MachineReport,
  OrderSheetReport,
  PSSalesReport,
  SalesReport,
  TotalSalesReport,
  MachineReportDrawer,
  PSSalesReportDrawer,
  SalesReportDrawer,
  TotalSalesReportDrawer,
  AdminTable
}

export default exported;