import UILIB from 'components';
import * as functions from '../../shared/functions';

export default function DocumentTypes(props) {
    const docTypes = functions
        .getDoctype(props.ourDeal.leaseorcash, Number(props.ourDeal.serviceContract))
        .sort(function(a, b){
            if(a < b) { return -1; }
            if(a > b) { return 1; }
            return 0;
        });

    const buttonColor = (type) => {
        let color = "white outline"
        if (type.endsWith("*")) {
          color = "red"
        }

        if (props.ourDeal.document && Array.isArray(props.ourDeal.document)) {
          if (props.ourDeal.document.some(x => x.Class.toLowerCase().replace(/\s/g, '') === type.toLowerCase().replace(/\s/g, '').replace("*", ""))) {
              color = "green"
          }
        }

        return color;
    }

    return <UILIB.Paper style={{ height: "100%" }}>
        <div className="container">
            <div className="row">
                {docTypes.map((type, index) => <div key={type + index} className="text-center col-xs-12 col-sm-4 mar-b10 mar-t10">
                    <UILIB.Button
                        key={type + index + "Button"}
                        className={buttonColor(type) + " width-100"}
                        value={type.replace("*", "")}
                    />
                </div>)}
            </div>
            <div className={"text-right"}>
                <div className="row">
                    {Number(props.ourDeal.serviceContract) === -1 &&
                    <div className="text-left col-sm-8 mar-t10">
                        <p>Please ensure you select an option from the dropdown labeled 'Service Agreement' to see the Document list.</p>
                    </div>}
                    <div className="text-left col-sm-8 mar-t10">
                        <p>Please ensure you add all relevant documentation. If using any special prices, CPC's or lease rates, please add the approval for these as 'Other Document'. Orders submitted without these will be declined.</p>
                    </div>
                    <div className="text-left col-sm-8 mar-b10 mar-t10">
                        <p>For any documents related to the deal that have been electronically signed, please add the DocuSign summary PDF as "e-Signature Summary".</p>
                    </div>
                    <div className="text-center col-sm-1 mar-b10 mar-t10">
                    </div>
                    <div className="text-center col-sm-1 mar-b10 mar-t10 pad-l5 pad-r5">
                        <UILIB.Button className={"red width-10 height-30"} /> <p>Required</p>
                    </div>
                    <div className="text-center col-sm-1 mar-b10 mar-t10 pad-l5 pad-r5">
                        <UILIB.Button className={"green width-10 height-30"} /> <p>Added</p>
                    </div>
                    <div className="text-center col-sm-1 mar-b10 mar-t10 pad-l5 pad-r5">
                        <UILIB.Button className={"white outline width-10 height-30"} /> <p>Not Added</p>
                    </div>
                </div>
            </div>
        </div>
    </UILIB.Paper>
}