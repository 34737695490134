import Modules from '../Modules';
import { spawnContainerType } from '../functions';
import { TableCell } from '@mui/material';
import { memo } from 'react';

const DataRow = memo(({ header, row, rowIndex, expanding, checked, setChecked, selectQuery }) => 
{ 

  return <tr key={"th_" + rowIndex}>
          {header.map((headerColumn, columnIndex) => {
            const defaults = { minWidth: 50, maxWidth: 300, padding: 0, align: 'left' };
            const columnData = row[headerColumn.value] || '';
            const { label, type, value, filtering, ...style } = headerColumn;
            const headerStyle = (columnData.tdStyle) ? columnData.tdStyle : '';
            const columnClass = (columnData.class) ? columnData.class : '';
            const columnDataFinal = (columnData.hasOwnProperty('value') ? spawnContainerType(headerColumn?.type, headerColumn?.behaviorType, columnData, checked, setChecked, selectQuery) : columnData);
            return <TableCell key={"td_" + rowIndex + "_" + columnIndex} style={{ ...defaults, ...style, ...headerStyle }}>
              {<Modules.DataColumn expanding={expanding} className={columnClass} style={style}>{columnDataFinal}</Modules.DataColumn>}
            </TableCell>
          })}
        </tr>
})
export default DataRow