import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import UILIB from 'components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { formatCurrency } from 'classes/format';
import * as functions from '../functions';



const Collapse = functions.styleCollapse();


function Row(data) {

  if(!data.row) return <React.Fragment></React.Fragment>

  const open = data.row.props.productExpandRow[data.row.id] || false;
  const partNumberLink = <UILIB.Link onClick={() => data.row.props.history.push("/saleshub/partsManagement/addProduct/" + data.row.id)}>{data.row.partNumber}</UILIB.Link>;
  const typographySx = { fontSize:12 }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderColor: 'grey.500' } }}>
        <TableCell>
          <IconButton
            sx={{ pb: 0, pt: 0, width: 0 }}
            aria-label="expand row"
            size="small"
            onClick={() => functions.expandRowHander(data.row, data.row.id, data.row.props.setProductExpandRow, open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{partNumberLink}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{data.row.manufacturerPartNumber}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{data.row.name}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{data.row.salesPartType}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{data.row.manufacturer}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{data.row.supplier}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{formatCurrency(data.row.listPrice)}</Typography></TableCell>  
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{formatCurrency(data.row.partnerPrice)}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{formatCurrency(data.row.basePrice)}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{data.row.serviceMeters}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={typographySx} variant="body2">{data.row.category}</Typography></TableCell>
        <TableCell>{functions.deleteProductCell(data.row, data.row.props, data.row.props.setProductRender, data.row.props.productRender)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={13} sx={{ p: 0, m: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="tasks">
              <TableHead>  
                <TableRow sx={{ pb: 0, pt: 0 }}>
                  {functions.addSortedHeader('Compatibles', "40%", 'compatibles', 'name', 'string', '#EEEEEE', 'SalesPart', data.row.props.partsTableSortHandler, data.row.props.setPartsableSortHandler, data.row.props.columnFilterHander, data.row.props.setColumnFilterHander, null, null)}
                  {functions.addSortedHeader('Conflicting', "40%", 'incompatibles', 'name', 'string', '#EEEEEE', 'SalesPart', data.row.props.partsTableSortHandler, data.row.props.setPartsableSortHandler, data.row.props.columnFilterHander, data.row.props.setColumnFilterHander, null, null)}
                  {functions.addSortedHeader('Ranges', "20%", 'range', 'name', 'string', '#EEEEEE', 'SalesPartRange', data.row.props.partsTableSortHandler, data.row.props.setPartsableSortHandler, data.row.props.columnFilterHander, data.row.props.setColumnFilterHander, null, null)}
                </TableRow>
              </TableHead>
                <TableRow sx={{ '& > *': { borderColor: 'grey.500' } }}>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    {data.row.compatiblesRaw.map(compat => {
                      return <Chip sx={{ m: '2px', fontSize: 11 }} size="small" variant="outlined" color="info" label={compat.name} onClick={() => data.row.props.history.push("/saleshub/partsManagement/addProduct/" + compat.id)}/>
                    })}
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    {data.row.incompatiblesRaw.map(conflict => {
                      return <Chip sx={{ m: '2px', fontSize: 11 }} size="small" variant="outlined" color="info" label={conflict.name} onClick={() => data.row.props.history.push("/saleshub/partsManagement/addProduct/" + conflict.id)}/>
                    })}
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    {data.row.rangesRaw.map(range => {
                      return <Chip sx={{ m: '2px', fontSize: 11 }} size="small" variant="outlined" color="info" label={range.name}/>
                    })}
                  </TableCell>
                </TableRow>
            </Table>    
          </Collapse>    
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function CollapsibleTable(props) {


  const handleChangePage = (event, newPage) => {  
    const partsTableSortHandler = structuredClone(props.partsTableSortHandler);
    if(Number(newPage) > partsTableSortHandler.page)partsTableSortHandler.offset = (Number(partsTableSortHandler.offset) + Number(partsTableSortHandler.limit));
    if(Number(newPage) < partsTableSortHandler.page)partsTableSortHandler.offset = (Number(partsTableSortHandler.offset) - Number(partsTableSortHandler.limit));
    partsTableSortHandler.page = (Math.sign(newPage) === -1) ? 0 : Number(newPage);
    props.setPartsableSortHandler(partsTableSortHandler);
    props.setProductRender(props.productRender === 0 ? 1 : 0);
  };

  const handleChangeRowsPerPage = (event) => {
    const partsTableSortHandler = structuredClone(props.partsTableSortHandler);
    partsTableSortHandler.limit = parseInt(event, 10);
    props.setPartsableSortHandler(partsTableSortHandler);
    props.setProductRender(props.productRender === 0 ? 1 : 0);
  };

  const handleFilterChange = (event) => {
    props.setGlobalFilter(event.target.value);
  };

  const handleFilterRemove = (chip) => {
    const columnFilterHanderNew = structuredClone(props.columnFilterHander)
    const filtered = columnFilterHanderNew.columnFilters.filter(f => f.id !== chip.headName)
    columnFilterHanderNew.columnFilters = filtered.length > 0 ? filtered : [];
    props.setColumnFilterHander(columnFilterHanderNew);
    props.setProductRender(props.productRender === 0 ? 1 : 0);
  };

  const foundReleaseData = functions.searchTextTrigger(props, props.globalFilter, Row);

  return (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end">
        <TextField 
          size="small" 
          id="outlined-basic" 
          label="Search" 
          variant="outlined" 
          disabled={false}
          sx={{ width: 600 }}
          onChange={(ev) => { handleFilterChange(ev) }}
        />
        {functions.addTableExportButton({ ml: 1, mb: 1, height: '38px', backgroundColor: 'gray', fontSize: 12 }, props)}
        <Button 
          size="small"
          variant="contained"
          disabled={!props.permissionsObject('addProductOption', props.localPermissions.permissions, props.permissions, props.location.pathname).entityEnable}
          sx={{ ml: 1, mb: 1, height: '38px', backgroundColor: '#00B200', fontSize: 12, minWidth: '110px' }}
          onClick={() => props.history.push('/saleshub/partsManagement/addProduct')}
        >
        Add Product
        </Button>
        <Button 
          size="small"
          variant="contained"
          disabled={!props.permissionsObject('importProductsOption', props.localPermissions.permissions, props.permissions, props.location.pathname).entityEnable}
          sx={{ ml: 1, mb: 1, height: '38px', backgroundColor: '#00B200', fontSize: 12, minWidth: '130px' }}
          onClick={() => props.history.push('/saleshub/partsManagement/import')}
        >
        Import Product(s)
        </Button>
        <Button  
          size="small"
          variant="contained"
          disabled={!props.permissionsObject('partsAdminOption', props.localPermissions.permissions, props.permissions, props.location.pathname).entityEnable}
          sx={{ ml: 1, mb: 1, height: '38px', backgroundColor: 'orange', fontSize: 12, minWidth: '110px' }}
          onClick={() => props.history.push('/saleshub/partsManagement/admin')}
        >
        Parts Admin
        </Button>
        {functions.addTableRefreshButton({ ml: 1, mb: 1, height: '38px', backgroundColor: '#00B200', fontSize: 12 }, props, props.productRender, props.setProductRender)}
      </Box>
      {props.columnFilterHander.columnFilters && props.columnFilterHander.columnFilters.map(chip => { 
        return <Chip sx={{ m: '2px', fontSize: 12 }} size="small" variant="outlined" color="info" label={`${chip.headName} : ${chip.filterText}`} onDelete={() => handleFilterRemove(chip)}/>
      })}
      {props.columnFilterHander.active && functions.addColumnFilterHolder(props.columnFilterHander, props.setColumnFilterHander, props.productFilterInput, props.productRender, props.setProductRender)}
      <TableContainer component={Paper}>
        <Table 
          sx={{ tableLayout: "auto" }}
          size="small" 
          aria-label="collapsible table">
            <TableRow>
              <TableCell sx={{ backgroundColor: "lightgray", width: '1%' }}/>
              {functions.addSortedHeader('Part #', "8%", 'partNumber', 'partNumber', 'string', 'lightgray', 'SalesPart', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('Manufacturer #', "8%", 'manufacturerPartNumber', 'manufacturerPartNumber', 'string', 'lightgray', 'SalesPart', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('Product Name', "8%", 'name', 'name', 'string', 'lightgray', 'SalesPart', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('Part Type', "8%", 'salesPartType', 'name', 'string', 'lightgray', 'salesPartType', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('Manufacturer', "8%", 'manufacturer', 'name', 'string', 'lightgray', 'SalesPartManufacturer', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('Default Supplier', "8%", 'supplier', 'name', 'string', 'lightgray', 'SalesPartSupplier', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('List Price (RRP)', "8%", 'listPrice', 'listPrice', 'number', 'lightgray', 'SalesPart', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('Partner Price', "8%", 'partnerPrice', 'partnerPrice', 'number', 'lightgray', 'SalesPart', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('Sales Baseprice', "8%", 'basePrice', 'basePrice', 'number', 'lightgray', 'SalesPart', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('Service Meters', "8%", 'serviceMeters', 'serviceMeters', 'string', 'lightgray', 'SalesPart', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('VO Category', "8%", 'category', 'name', 'string', 'lightgray', 'SalesPartCategory', props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
              {functions.addSortedHeader('Delete', "1%", 'delete', null, 'number', 'lightgray', null, props.partsTableSortHandler, props.setPartsableSortHandler, props.columnFilterHander, props.setColumnFilterHander, props.productRender, props.setProductRender)}
            </TableRow>
          <TableBody>
            {foundReleaseData.products}
          </TableBody>
        </Table>
          {props.loading ? <UILIB.Loading className="mar-t10" type={3}/> : ''}
      </TableContainer>
      <TablePagination
          size="small"
          component="div"
          showFirstButton
          showLastButton
          rowsPerPageOptions={[25, 50, 100, 200]}
          count={props.partsTableSortHandler.totalRows}
          rowsPerPage={props.partsTableSortHandler.limit}
          page={props.partsTableSortHandler.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(ev) => handleChangeRowsPerPage(ev.target.value)}
        />
    </React.Fragment>
  );
}
