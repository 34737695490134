import UILIB from 'components';
import Axios from 'classes/axios';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LogoutIcon from '@mui/icons-material/Logout';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextField from '@mui/material/TextField';
import MuiCollapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import * as colors from '@mui/material/colors';
import { styled } from '@mui/material/styles';



export function styleCollapse() {
  const Collapse = styled((props) => (
    <MuiCollapse 
      disableGutters 
      sx={{ backgroundColor: '#EFEFEF' }}
      {...props} 
    />
  ))(({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
  }));
  return Collapse
}

export function selectDropdown(props) {
  return (<UILIB.Select 
    name={props.name || ''}
    disabled={props.disabled || false} 
    style={props.style || { height: "25px", padding: "0" }} 
    data={props.data} 
    value={String(props.value)} 
    onChange={(event) => { props.onChange(event); }} 
  />)
}

export function deletePermissionCell(props, innerProps, setRender, render) {
  return (<div 
    className="icon icon-trash2" 
    title="Delete" 
    alt="Delete" 
    onClick={async (event) => { 
      await deletePermission(event, props, innerProps); 
      setRender(!render)
     }} 
    style={{ cursor: "pointer" }} 
  />)
}


export function addSortedHeader(name, width, propertyName, colour, tableHandler, setTableHandler) {
  return (
    <TableCell 
        key={name} 
        sortDirection={tableHandler.direction}  
        sx={{ backgroundColor: colour, width: width }}
      > 
      <TableSortLabel 
        name={name} 
        active={tableHandler.headName === name ? true : false} 
        direction={tableHandler.direction} 
        onClick={() => createSortHandler(propertyName, null, null, tableHandler, setTableHandler)}>
        {name}
      </TableSortLabel>
    </TableCell>
  )
}

export function addCheckbox(labelName, size, style) {
  const label = { inputProps: { 'aria-label': labelName } };
  return (
    <div>
      <Checkbox
        {...label}
        size={size}
        defaultChecked
        sx={{
          ...style,
          p: 0,
          color: colors.green[800],
          '&.Mui-checked': {
            color: colors.green[600],
          },
        }}
      />
    </div>
  );
}


export function addEntityCell(props) {
  return (<div 
    className="icon icon-plus" 
    title="Add" 
    alt="Add" 
    onClick={() => { props.setShowEntityModal(true) }} 
    style={{ cursor: "pointer" }} 
  />)
}

export async function onChangeEntity(event, args, setRender, render) {
  await Axios.post(`/admin/groups/updateAdminEntityGroupPermission`, { 
    fieldName: event.target.name, 
    fieldValue: event.target.value, 
    rowData: args
  });
  setRender(!render);
}

export async function deletePermission(event, args, innerProps) {
  if(window.confirm("Are you sure you wish to DELETE this permission rule?")) {
    await Axios.post(`/admin/groups/deleteAdminEntityGroupPermission`, { 
      rowData: args.permission
    });
    innerProps.setGroupExpandRow([]);
  }
}

export async function addPermission(props, entity) {
  await Axios.post(`/admin/groups/addAdminEntityGroupPermission`, { 
    AdminEntityId: entity.id, 
    AdminGroupId: props.selectedGroupIndex.group
  });
  props.setShowPermissionsModal(false);
  props.setGroupExpandRow([]);
  props.setGroupRender(props.groupRender === 0 ? 1 : 0);
}

export function createSortHandler(event, page, rowsPerPage, tableHandler, setTableHandler) {
  const activeLocal = event ? (tableHandler.headName === event ? tableHandler.active : true) : tableHandler.active;
  const directionLocal = event ? (tableHandler.headName === event ? (tableHandler.direction === 'asc' ? 'desc' : 'asc') : 'asc') : tableHandler.direction;
  setTableHandler({
    filter: tableHandler.filter,
    page: page ? page : tableHandler.page,
    rowsPerPage: rowsPerPage ? rowsPerPage : tableHandler.rowsPerPage,
    active: activeLocal,
    direction: directionLocal,
    headName: event ? event : tableHandler.headName
  })
}

export function stableSort(array, comparator) {
  
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy)  {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function search(array, textArray, filterColumns) {
  textArray = textArray.map(t => t.toLowerCase());
  return array.filter((a) => {
    return textArray.every((t) => {
      return filterColumns.some((f) => {    
        return a[f] ? a[f].toString().toLowerCase().indexOf(t) !== -1 : null;
      });
    });
  });
}

export function findGroupData(innerProps, groupId, hubId, Row) {

  const tableDataRaw = [];
  const adminRoutes = [];
  const groups = [];

  innerProps.permissions.AdminRoute.filter(f => hubId !== 0 ? f.AdminHubId === innerProps.selectedHubIndex.hub : f.id).forEach(route => {
    const foundHub = innerProps.permissions.AdminHub.find(h => h.id === route.AdminHubId);
    adminRoutes.push({
      route: route,
      hub: foundHub && foundHub,
      routeName: route.routeName,
      hubName: foundHub && foundHub.hubName,
      parentId: route.parentId,
      inheriting: route.inheriting,
      createdBy: route.createdAt,
      updatedBy: route.updatedAt,
      createdAt: route.createdAt,
      updatedAt: route.updatedAt
    })
  })

  innerProps.permissions.AdminGroup.filter(f => groupId !== 0 ? f.id === innerProps.selectedGroupIndex.group : f.id).forEach(group => {
    innerProps.permissions.AdminEntityGroupPermission.filter(p => p.AdminGroupId === group.id).forEach(permission => {
      const foundEntity = innerProps.permissions.AdminEntity.find(e => e.id === permission.AdminEntityId);
      const foundRoute = innerProps.permissions.AdminRoute.find(r => r.id === foundEntity.AdminRouteId);
      const foundHub = innerProps.permissions.AdminHub.find(h => h.id === foundEntity.AdminHubId);
      tableDataRaw.push({
        permission: permission,
        group: group,
        entity: foundEntity,
        AdminGroupId: group.id,
        permissionOwner: group.groupName,
        hubName: foundHub && foundHub.hubName,
        hubId: foundHub && foundHub.id,
        routeId: foundRoute && foundRoute.id,
        entityId: foundEntity && foundEntity.id,
        routeName: foundRoute && foundRoute.routeName,
        entityName: foundEntity && foundEntity.entityName,
        entityType: foundEntity && foundEntity.AdminEntityType.entityType,
        entityEnable: permission.entityEnable,
        entityWrite: permission.entityWrite,
        entityDelete: permission.entityDelete,
        createdAt: permission.createdAt,
        updatedAt: permission.updatedAt
      })
    })
  })

  const rowsPerPage = innerProps.groupTableSortHandler.rowsPerPage;
  const page = innerProps.groupTableSortHandler.page;

  const filterOptionsParent = [
    'route',
    'hub',
    'routeName',
    'hubName',
    'parentId',
    'inheriting',
    'createdBy',
    'updatedBy',
    'createdAt',
    'updatedAt'
  ]

  const filterOptionsChild = [
    'permissionOwner',
    'routeName',
    'entityName',
    'entityType',
    'entityEnable',
    'entityWrite',
    'entityDelete',
    'createdAt',
    'updatedAt'
  ]


  const adminRoutesFiltered = innerProps.groupTableSortHandler.filter ? search(adminRoutes, [innerProps.groupTableSortHandler.filter], filterOptionsParent) : adminRoutes;
  const sortedRoutes = stableSort(adminRoutesFiltered, getComparator(innerProps.groupTableSortHandler.direction, innerProps.groupTableSortHandler.headName));

  const groupsFiltered = innerProps.groupChildTableSortHandler.filter ? search(tableDataRaw, [innerProps.groupChildTableSortHandler.filter], filterOptionsChild) : tableDataRaw;
  const sortedPermissions = stableSort(groupsFiltered, getComparator(innerProps.groupChildTableSortHandler.direction, innerProps.groupChildTableSortHandler.headName));

  sortedRoutes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => groups.push(<Row key={index} row={{...row, innerProps, sortedPermissions}} />));

  return { groups: groups, length: adminRoutesFiltered.length };
}


export function findReleaseData(innerProps, Row) {
  
  const tableDataRaw = [];
  const releases = [];

  innerProps.releases.forEach(release => {
    tableDataRaw.push({
      id: release.id,
      documentName: release.documentName,
      documentPath: release.documentPath,
      published: release.published,
      versionNumber: release.versionNumber,
      incrementNumber: release.incrementNumber,
      createdBy: release.createdBy,
      createdAt: release.createdAt,
      updatedBy: release.updatedBy,
      updatedAt: release.updatedAt
    })
  })


  const rowsPerPage = innerProps.releaseTableSortHandler.rowsPerPage;
  const page = innerProps.releaseTableSortHandler.page;

  const filterOptions = [
    'versionNumber',
    'incrementNumber',
    'documentName',
    'documentPath',
    'published',
    'createdBy',
    'updatedBy',
    'createdAt',
    'updatedAt'
  ]

  const tableDataRawFiltered = innerProps.releaseTableSortHandler.filter ? search(tableDataRaw, [innerProps.releaseTableSortHandler.filter], filterOptions) : tableDataRaw;
  const sorted = stableSort(tableDataRawFiltered, getComparator(innerProps.releaseTableSortHandler.direction, innerProps.releaseTableSortHandler.headName));
  sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => releases.push(<Row key={index} row={{...row, innerProps}} />));


  return { releases: releases, length: tableDataRawFiltered.length, tableDataRaw: tableDataRaw };
}


export function findUserData(innerProps, Row) {
  
  const tableDataRaw = [];
  const users = [];

  innerProps.users.filter(f => innerProps.selectedUserIndex.user ? Number(f.id) === Number(innerProps.selectedUserIndex.user) : f.id).forEach(user => {
    const foundManager = innerProps.users.find(f => f.azureId === user.managerId);
    tableDataRaw.push({
      groups: user.AccountUserGroup,
      isActive: user.AccountUserAuth.length > 0 ? user.AccountUserAuth[0].isActive : null,
      accountUserAuthId: user.AccountUserAuth.length > 0 ? user.AccountUserAuth[0].id : null,
      azureId: user.azureId,
      managerName: foundManager ? foundManager.displayName : 'n/a',
      createdAt: user.createdAt,
      createdBy: user.createdBy,
      displayName: user.displayName,
      emailAddress: user.emailAddress,
      firstName: user.firstName,
      id: user.id,
      jobTitle: user.jobTitle,
      lastName: user.lastName,
      lastOnline: user.lastOnline,
      loginCount: user.loginCount,
      phoneNumber: user.phoneNumber,
      serviceRegionId: user.serviceRegionId,
      systemuserid: user.systemuserid,
      updatedAt: user.updatedAt,
      updatedBy: user.updatedBy,
      azureId: user.azureId,
      userPrincipalName: user.userPrincipalName
    })
  })

  const rowsPerPage = innerProps.userTableSortHandler.rowsPerPage;
  const page = innerProps.userTableSortHandler.page;

  const filterOptions = [
    'groups',
    'isActive',
    'managerName',
    'displayName',
    'emailAddress',
    'jobTitle',
    'lastOnline',
    'loginCount',
    'createdAt',
    'updatedAt'
  ]


  const tableDataRawFiltered = innerProps.userTableSortHandler.filter ? search(tableDataRaw, [innerProps.userTableSortHandler.filter], filterOptions) : tableDataRaw;
  const sorted = stableSort(tableDataRawFiltered, getComparator(innerProps.userTableSortHandler.direction, innerProps.userTableSortHandler.headName));
  sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => users.push(<Row key={index} row={{...row, innerProps}} />));

  return { users: users, length: tableDataRawFiltered.length, tableDataRaw: tableDataRaw };
}

export async function fileDownloadHandler(row, props) {
  props.setDownloadingRelease(d => ({ ...d, [row.id]: { status: true, error: false, message: 'Downloading CSV' }}));
  const result = await Axios.get(`/admin/releaseNotes/getCsvFile/${row.documentName}`).catch(() => {
    props.setDownloadingRelease(d => ({ ...d, [row.id]: { status: false, error: true, message: 'Download Error' }}));
  })
  var link = document.createElement('a');
  link.download = result.data.fileName;
  link.href = 'data:text/csv;charset=utf-8;base64,' + result.data.result;
  document.body.appendChild(link);
  link.click();
  props.setDownloadingRelease(d => ({ ...d, [row.id]: { status: false, error: false, message: 'Download CSV' }}));
}


export async function fileDeleteHandler(row, props) {
  props.setUploadingRelease(d => ({ ...d, [row.id]: { status: true, error: false }}));
  if(window.confirm("Are you sure you wish to DELETE this release?")) {
    await Axios.delete(`/admin/releaseNotes/deleteRelease/${row.id}`);
    props.setReleaseRender(props.releaseRender === 0 ? 1 : 0);
  }
  props.setUploadingRelease(d => ({ ...d, [row.id]: { status: false, error: false }}));
}

export async function filePublishHandler(row, props) {
  props.setPublishRelease(d => ({ ...d, [row.id]: { status: true, error: false, message: 'Working' }}));
  await Axios.put(`/admin/releaseNotes/setPublished/`, row).catch(() => {
    props.setPublishRelease(d => ({ ...d, [row.id]: { status: false, error: true, message: 'Action Failed' }}));
  })
  props.setReleaseRender(props.releaseRender === 0 ? 1 : 0);
  props.setPublishRelease(d => ({ ...d, [row.id]: { status: false, error: false, message: null }}));
}

export async function expandRowHander(row, id, handler, open) {
  handler(d => ({ ...d, [id]: open ? false : true }))
}

export async function userEnableHandler(row, props) {
  props.setEnableUser(d => ({ ...d, [row.id]: { status: true, error: false, message: 'Working' }}));
  if(window.confirm(`Are you sure you wish to ${row.isActive ? 'DISABLE' : 'ENABLE'} this user?`)) {
    await Axios.put(`/admin/users/enableUser/${row.azureId}`).catch(() => {
      props.setEnableUser(d => ({ ...d, [row.id]: { status: false, error: true, message: 'Action Failed' }}));
    });
    props.setUserRender(props.userRender === 0 ? 1 : 0);
  }
  props.setEnableUser(d => ({ ...d, [row.id]: { status: false, error: false, message: null }}));
}

export async function userLogoutHandler(row, props) {
  props.setLogoutUser(d => ({ ...d, [row.id]: { status: true, error: false, message: 'Working' }}));
  if(window.confirm("Are you sure you wish to LOGOUT this user?")) {
    await Axios.delete(`/admin/users/logoutUser/${row.azureId}`, { data: row }).catch(() => {
      props.setLogoutUser(d => ({ ...d, [row.id]: { status: false, error: true, message: 'Action Failed' }}));
    });
    props.setUserRender(props.userRender === 0 ? 1 : 0);
  }
  props.setLogoutUser(d => ({ ...d, [row.id]: { status: false, error: false, message: 'Logout' }}));
}


export function addSortedReleaseDownloadButton(row, sx, props) {
  if(props.downloadingRelease[row.id] && props.downloadingRelease[row.id].error) sx.backgroundColor = '#E93D24';
  return ( 
    <LoadingButton
      size="small"
      sx={sx}
      onClick={async () => await fileDownloadHandler(row, props)}
      loading={props.downloadingRelease[row.id] && props.downloadingRelease[row.id].status}
      loadingPosition="start"
      startIcon={<SaveIcon />}
      variant="contained"     
    >
      {props.downloadingRelease[row.id] ? props.downloadingRelease[row.id].message : 'Download CSV'} 
    </LoadingButton>
  )
}

export function addSortedReleaseDeleteButton(row, sx, props) {
  return ( 
    <LoadingButton
      size="small"
      sx={sx}
      onClick={async () => await fileDeleteHandler(row, props)}
      loading={props.uploadingRelease[row.id] && props.uploadingRelease[row.id].status}
      loadingPosition="start"
      startIcon={<DeleteForeverIcon />}
      variant="contained"     
    >
      Delete Row
    </LoadingButton>
  )
}

export function addSortedReleasePublishedButton(row, sx, props) {
  if(row.published) sx.backgroundColor = '#00B200';
  if(!row.published) sx.backgroundColor = '#E93D24';
  if(props.downloadingRelease[row.id] && props.downloadingRelease[row.id].error) sx.backgroundColor = '#E93D24';
  const messageText = row.published ? 'Published' : 'Not Published';
  return ( 
    <LoadingButton
      size="small"
      sx={sx}
      onClick={async () => await filePublishHandler(row, props)}
      loading={props.publishRelease[row.id] && props.publishRelease[row.id].status}
      loadingPosition="start"
      startIcon={row.published ? <CheckCircleIcon /> : <CancelIcon />}
      variant="contained"     
    >
      {(props.publishRelease[row.id] && props.publishRelease[row.id].message) ? props.publishRelease[row.id].message : messageText} 
    </LoadingButton>
  )
}

export function addSortedLogoutButton(row, sx, props) {
  return ( 
    <LoadingButton
      size="small"
      disabled={row.isActive === null}
      sx={sx}
      onClick={async () => await userLogoutHandler(row, props)}
      loading={props.logoutUser[row.id] && props.logoutUser[row.id].status}
      loadingPosition="start"
      startIcon={<LogoutIcon />}
      variant="contained"     
    >
      {props.logoutUser[row.id] ? props.logoutUser[row.id].message : 'Logout'} 
    </LoadingButton>
  )
}

export function addSortedEnabledButton(row, sx, props) {
  if(row.isActive) sx.backgroundColor = '#00B200';
  if(!row.isActive) sx.backgroundColor = '#E93D24';
  const messageText = row.isActive ? 'Enabled' : row.isActive === null ? 'Unknown' : 'Disabled';
  return ( 
    <LoadingButton
      size="small"
      sx={sx}
      disabled={row.isActive === null}
      onClick={async () => await userEnableHandler(row, props)}
      loading={props.enableUser[row.id] && props.enableUser[row.id].status}
      loadingPosition="start"
      startIcon={row.isActive ? <CheckCircleIcon /> : <CancelIcon />}
      variant="contained"     
    >

      {(props.enableUser[row.id] && props.enableUser[row.id].message) ? props.enableUser[row.id].message : messageText} 
    </LoadingButton>
  )
}

export function addTableRefreshButton(sx, props, renderHandle, setRenderHandle) {
  return ( 
    <LoadingButton
      size="small"
      sx={sx}
      onClick={() => setRenderHandle(renderHandle === 0 ? 1 : 0)}
      loading={props.renderLoading}
      loadingPosition="start"
      startIcon={<RefreshIcon/>}
      variant="contained"     
    >
      {'Refresh'}
    </LoadingButton>
  )
}

export function addTextFieldUILIB(name, value, onChange) {
  return <UILIB.TextInput
    className="textInput input"
    name={name}
    value={value}
    onChange={(ev) => { onChange(ev, arguments) }}
  />
}

export function addTextFieldMUI(size, label, name, variant, value, onChange, sx, inputProps) {
  return (<TextField 
    size={size}
    inputProps={inputProps}
    id="outlined-basic" 
    label={label}
    variant={variant}
    sx={sx}
    name={name}
    value={value ? String(value) : null}
    onChange={(ev) => { onChange(ev, arguments) }}
  />)
}


export function updateLocalField(...args) {
  if(args[3] === 'parent') {
    const releaseNew = structuredClone(args[2].releases);
    releaseNew[releaseNew.findIndex(f => f.id === args[1].id)][args[0].target.name] = args[0].target.value;
    args[2].setReleases(releaseNew);
    args[2].setLocalEvent({ type: args[3], fieldName: args[0].target.name, fieldValue: args[0].target.value, raw: args[1], timeout: 200 });
  }
  if(args[3] === 'child') {
    const releaseNew = structuredClone(args[2].releases);
    const foundParent = releaseNew.find(f => f.id === args[1].AdminReleaseId);
    const foundChild = foundParent.adminReleaseItem.find(f => f.id === args[1].id);
    foundChild[args[0].target.name] = args[0].target.value;
    args[2].setReleases(releaseNew);
    args[2].setLocalEvent({ type: args[3], fieldName: args[0].target.name, fieldValue: args[0].target.value, raw: foundChild, timeout: 200 });
  }
}

export async function uploadFile(props) {
  const fileUploadJobActiveNew = structuredClone(props.fileUploadJobActive);
  const showUploadDialogueNew = structuredClone(props.uploadFileHandler);
  showUploadDialogueNew.open = true;
  showUploadDialogueNew.stage = 1;
  fileUploadJobActiveNew.jobId = null;
  fileUploadJobActiveNew.status = null;
  fileUploadJobActiveNew.isActive = 0;
  fileUploadJobActiveNew.complete = 0;
  fileUploadJobActiveNew.progress = 0;
  props.setFileUploadJobActive(fileUploadJobActiveNew);
  props.setSelectedFile(null);
  props.setUploadFileHandler(showUploadDialogueNew);
}

export async function handleFileUpload(props, pollRate) {
  const data = new FormData();
  data.append('file', props.selectedFile);
  data.append('uploadFileHandler', JSON.stringify(props.uploadFileHandler));
  const result = await Axios.post(`/admin/releaseNotes/uploadFileNew`, data);
  const fileUploadJobActiveNew = structuredClone(props.fileUploadJobActive);
  fileUploadJobActiveNew.trigger = (fileUploadJobActiveNew.trigger === 0 ? 1 : 0);
  fileUploadJobActiveNew.jobId = result.data.id;
  fileUploadJobActiveNew.isActive = 1;
  fileUploadJobActiveNew.complete = 0;
  fileUploadJobActiveNew.timeout = pollRate;
  props.setFileUploadJobActive(fileUploadJobActiveNew);
}

export async function timerGetJobs(setFileUploadJobActive, fileUploadJobActive) {
  await new Promise(resolve => setTimeout(resolve, fileUploadJobActive.timeout));
  const fileUploadJobActiveNew = structuredClone(fileUploadJobActive);
  fileUploadJobActiveNew.trigger = (fileUploadJobActiveNew.trigger === 0 ? 1 : 0);
  setFileUploadJobActive(fileUploadJobActiveNew);
  return;
}

export async function getJob(setFileUploadJobActive, fileUploadJobActive) {
  const uploadResult = await Axios.get(`/admin/releaseNotes/getReleaseJob/${fileUploadJobActive.jobId}`);
  fileUploadJobActive.progress = uploadResult.data.result.percentComplete;
  fileUploadJobActive.isActive = uploadResult.data.result.isActive;
  fileUploadJobActive.complete = uploadResult.data.result.complete;
  fileUploadJobActive.status = uploadResult.data.result.status;
  setFileUploadJobActive(fileUploadJobActive);
  return;
}

export async function handleSelectedFile(event, props) {
  props.setSelectedFile(event.currentTarget.files[0] || null);
}

export async function handCloseUploadModal(props) {
  props.setUploadFileHandler({ show: false, stage: 1 });
  props.setReleaseRender(props.releaseRender === 0 ? 1 : 0);
}

export async function handleChangeInherit(event, row, setGroupRender, groupRender) {
  await Axios.post(`/admin/groups/setInheritance/`, { targetRouteId: Number(event.target.value), thisRouteId: row.route.id });
  setGroupRender(groupRender === 0 ? 1 : 0);
}

