import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { createTextInput, createDateInput, checkAndSet, newError } from 'classes/controls/genericControls';
import { formatDateTime } from 'classes/format';

const dataValidation = require('../../../../classes/dataValidation');

export default function RdHubUserDrawer({ onClose, tableRow, isEdit }) {
  const emptyEntity = {
    Id: '',
    name: '',
    email: '',
    dob: '',
    age: '',
  };

  const headerText = isEdit ? `Edit ${tableRow.name} - ` : 'Add User Information - ';

  const htmlButtons = () => {
    return (
      <div>
        <UILIB.Button loading={saving} className="mar-t10" value="Action" onClick={submitForm} />
      </div>
    );
  };

  const htmlRows = () => {
    return (
      <div className="mar-t15">
        {createTextInput('Name', 'name', true, 40, entity, entityErrors, changeInput)}
        {createTextInput('Email', 'email', true, 40, entity, entityErrors, changeInput)}
        {createDateInput('DOB', 'dob', true, entity, entityErrors, changeInput)}
        {createTextInput('Age', 'age', true, 3, entity, entityErrors, changeInput)}
      </div>
    );
  };

  const defaultPageMessage = 'Please complete the below and then click Action.';
  const failValidationMessage = 'Please correct any errors and click Action again.';
  const saveErrorMessage = 'Unable to save, please advise support.';
  const [entity, setEntity] = useState(emptyEntity);
  const [entityErrors, setEntityErrors] = useState(emptyEntity);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState(defaultPageMessage);

  useEffect(() => {
    if (isEdit) {
      tableRow.dob = formatDateTime(tableRow.dob, 'YYYY-MM-DD');
      setEntity(tableRow);
    }
  }, []);

  const changeInput = (event) => {
    let newFormData = JSON.parse(JSON.stringify(entity));
    newFormData[event.target.name] = event.target.value;
    setEntity(newFormData);

    if (event.target.name in entityErrors) {
      let newFormErrors = { ...entityErrors };
      newFormErrors[event.target.name] = '';
      setEntityErrors(newFormErrors);
    }
  };

  const validSubmit = () => {
    let newFormData = JSON.parse(JSON.stringify(entity));
    setEntity(newFormData);

    let formErrorsTemp = emptyEntity;
    if (!evaluatedRulesPassed(formErrorsTemp)) {
      return true;
    }

    setEntityErrors(formErrorsTemp);
    setMessage(failValidationMessage);
    return false;
  };

  const submitForm = async () => {
    try {
      if (validSubmit()) {
        setSaving(true);

        if (isEdit) {
          Axios.put(`/entities/rdHubUser/${tableRow.Id}`, entity).then(() => {
            setSaving(false);
          });
        } else {
          Axios.post('/entities/rdHubUser', entity).then(() => {
            setSaving(false);
          });
        }

        onClose();
      }
    } catch (err) {
      console.log(err);
      setSaving(false);
      setMessage(saveErrorMessage);
    }
  };

  const evaluatedRulesPassed = (formErrorsTemp) => {
    let e = 0;
    e = e + checkAndSet(!entity.name, formErrorsTemp, 'name', newError('name', "Please enter user's name"));
    e = e + checkAndSet(!dataValidation.regexValidation('emailPattern', entity.email), formErrorsTemp, 'email', newError('email', 'Please enter a valid email address'));
    e = e + checkAndSet(!entity.dob, formErrorsTemp, 'dob', newError('dob', "Please enter user's date of birth"));
    e = e + checkAndSet(!dataValidation.regexValidation('numericPattern', entity.age), formErrorsTemp, 'age', newError('age', "Please enter user's age"));

    return e > 0;
  };

  return (
    <div className="mar-l15 mar-r15">
      <h2 className="mar-b10">
        {headerText}
        {entity.id}
      </h2>
      <p>{message}</p>
      {htmlButtons()}
      {htmlRows()}
    </div>
  );
}
