import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { getAddressDropdown } from '../functions';


export default function DeleteInvoiceAddressDrawer(props) {

  const [saving, setSaving] = useState(false);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


  const goDelete = async () => {
    setSaving(true);
    try {
      const result = await Axios.delete(`/entities/address/${props.ourDeal.dealID}/${props.address.id}`, { data: { entity: props.address } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        setSaving(false);
      } else {
        props.setOurDeal({ ...props.ourDeal, invoiceAddress: [], addressDropdown: getAddressDropdown({ ...props.ourDeal, invoiceAddress: [] }) });  
        props.onFinished();
      }
    } catch (error) {
      console.log(error);
      setBannerError({ error: true, message: exceptionError });
      setSaving(false);
    }
  }

  return <Fragment>
    <div className='flex-container row'>
      <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Delete Invoice Address</div>
      <p className="flex-item start width-80 text-14 mar-b10">Are you sure you would like to delete this address?</p>
      <div className='flex-container row start width-100 mar-t20 mar-r5'>
        <UILIB.Button loading={saving} disabled={!props.isActive || saving} value="Confirm" onClick={async () => await goDelete()} />
        <UILIB.Button className='grey' disabled={saving} value='Cancel' onClick={() => props.onFinished()} />
        {bannerError.error && <div className="flex-item start wrap width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
    </div>
  </Fragment >
}