import { useEffect, Fragment, useState } from 'react';
import UILIB from 'components'

const headerData = [
  { label: "Include", value: "installSelected", align: "left", type: 'number', width: "2%" },
  { label: "Quantity", value: "quantity", align: "left", type: 'number', width: "5%" },
  { label: "Description", value: "description", align: "left", type: 'string', width: "28%" },
  { label: "Part Number", value: "partNo", align: "left", type: 'string', width: "10%" },
  { label: "Device", value: "device", align: "left", type: 'number', width: "5%" },
  { label: "Serial Number", value: "serial", align: "left", type: 'number', width: "12%"},
  { label: "Location", value: "location", align: "left", type: 'number', width: "10%" },
  { label: "Readings", value: "readings", align: "left",  type: 'number', width: "8%" }
]


export default function BlockInstallTable({ data, setFormData, type }) {

    const [tableData, setTableData] = useState([]);

    function renderTable() {

      if(!data.installs) return;

      const tableData = [];

      const parentDeviceNumbers = data.installs && data.installs.filter(p => p.isADevice === 1).map(device => { return device.deviceNo });
      const findSingletons = data.installs && data.installs.filter(s => !parentDeviceNumbers.includes(s.deviceNo)).map(device => { return device.prodID });

      data.installs && data.installs.sort((a, b) => { return a.deviceNo - b.deviceNo }).forEach((row, index) => {

        const readings = row.pdi[0] && row.pdi[0].readings.map(x => <div className="col-xs-12 col-sm-12 col-md-12">{`${x.meterName}: ${x.meterReading}`}</div>);
        const foundPDIData = row.pdi[0] && row.pdi.find(x => Number(x.productId) === Number(row.prodID));

        tableData.push({
          installSelected: { value: <UILIB.Checkbox disabled={true} name="installSelected" checked={true} />, raw: row.installSelected, tdStyle: { backgroundColor: "#FFFFFF" } },
          quantity: { value: row.qty, raw: row.qty, tdStyle: { backgroundColor: "#FFFFFF" } },    
          location: { value: row.specificDeviceLocation, raw: row.specificDeviceLocation, tdStyle: { backgroundColor: "#FFFFFF" } },
          device: { value: row.deviceNo || '', raw: row.deviceNo || '', tdStyle: { backgroundColor: "#FFFFFF" } },
          description: { value: row.description, raw: row.description, tdStyle: { backgroundColor: "#FFFFFF" } },
          partNo: { value: row.partNo, raw: row.partNo, tdStyle: { backgroundColor: "#FFFFFF" } },
          serial: { value: !findSingletons.includes(row.prodID) ? foundPDIData && foundPDIData.serialNo : '', raw: foundPDIData && foundPDIData.serialNo, tdStyle: { backgroundColor: "#FFFFFF" } },
          readings: { value: readings, raw: readings, tdStyle: { backgroundColor: "#FFFFFF" } }
        })

        row.attachments && row.attachments.forEach(child => {
          tableData.push({
            installSelected: { value: <UILIB.Checkbox disabled={true} name="installSelected" checked={true} />, raw: child.installSelected, tdStyle: { backgroundColor: "#EEEEEE" } },
            quantity: { value: child.qty, raw: child.qty, tdStyle: { backgroundColor: "#EEEEEE" } },   
            description: { value: `+ ${child.description}`, raw: child.description, tdStyle: { backgroundColor: "#EEEEEE" } }, 
            partNo: { value: child.partNo, raw: child.partNo, tdStyle: { backgroundColor: "#EEEEEE" } },
            device: { value: '', tdStyle: { backgroundColor: "#EEEEEE" } },
            location: { value: '', tdStyle: { backgroundColor: "#EEEEEE" } },
            serial: { value: '', tdStyle: { backgroundColor: "#EEEEEE" } },
            readings: { value: '', tdStyle: { backgroundColor: "#EEEEEE" } }
          })
        })
      })

      setTableData(tableData)
    }


    useEffect(() => {
        renderTable();
    }, [data]);


    return <Fragment>
      <UILIB.Table
        className="small"
        overflowX="auto"
        overflowY="hidden"
        header={headerData}
        data={tableData}
        sortable={false}
        autoResize={false}
        exportEnable={true} />
    </Fragment>
}