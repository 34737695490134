import { useState, Fragment } from 'react';
import { createDateInput, createButton } from 'classes/controls/formControls';


export default function MachineReportDrawer({ data, onFinished, sync }) {

  const [filtersLocal, setFiltersLocal] = useState(data.filters.machineReportDrawer);

  const actionInput = (event, key) => {
    setFiltersLocal({ ...filtersLocal, [key]: event.target.value });
  } 

  const syncAndClose = () => {
    data.setFilters({ ...data.filters, machineReportDrawer: filtersLocal });
    sync.set(!sync.get);
    onFinished();
  }

  return <Fragment>
    <div className='flex-container row width-100 end pad-b10'>
      <div className='flex-item start flex-grow-1 mar-b10'><b>Report Options</b></div>
      <div className='flex-container row width-100 wrap'>
        {createDateInput('Invoiced from', 'startDate', false, filtersLocal.startDate, (ev) => actionInput(ev, 'startDate'), null, 'flex-item width-50', null, null, 'fullBorder border-colour-grey-4')}
        {createDateInput('Invoiced to', 'endDate', false, filtersLocal.endDate, (ev) => actionInput(ev, 'endDate'), null, 'flex-item width-50', null, null, 'fullBorder border-colour-grey-4')}
        {createButton('', 'Apply Filters', 'Apply Filters', false, false, () => syncAndClose(), null, 'flex-item width-50', null, null, 'fullBorder border-colour-grey-4')}
      </div>   
    </div>     
  </Fragment> 
}
