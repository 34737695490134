import { useState, Fragment } from 'react';
import UILIB from 'components';
import { formatDateTime } from "classes/format";
import { getRegionsByCustomer, getCompanyDetails, getCompanies, getAccountUsers } from "classes/helpers";


export default function NewDeviceMovement({ formData, setFormData, loading, logisticsUsers, formErrors, setFormErrors, clearErrors }) {

    const [moveDate, setMoveDate] = useState(true);
    
    function handleUserSearch(result) {
      setFormData({ ...formData, salesPerson: result.label, salesPersonId: result.value });
    }

    async function handleAddSearch(result) {
      const regions = await getRegionsByCustomer(result.value);
      const customers = await getCompanyDetails(result.value);
      const baseData = { 
        companyName: result.label, 
        companyId: result.value, 
        serviceRegion: regions[0].name, 
        serviceRegionId: regions[0].id, 
        companyEmail: customers[0].EmailAddress, 
        companyContactName: customers[0].FullName, 
        companyTelephone: customers[0].PhoneNumber }
      setFormData({ ...formData, ...baseData });
    }

    const updateFormData = (event, field) => {
        const dataNew = JSON.parse(JSON.stringify(formData));
        switch (event.target.type) {
            case "checkbox":
                dataNew[event.target.name] = event.target.checked;
                break;
            case "radio":
              switch (true) {
                case field === 'requiresNetwork':
                  dataNew[event.target.name] = event.target.value;
                  formErrors.contactNameNetwork.disabled = (event.target.value === "Yes" ? false : true);
                  formErrors.contactEmailNetwork.disabled = (event.target.value === "Yes" ? false : true);
                  formErrors.contactTelephoneNetwork.disabled = (event.target.value === "Yes" ? false : true);
                  break;
                case field === 'parkingOptions':
                  dataNew[event.target.name] = event.target.value;
                  break;
                default:
                  break;
              }
              break;
            default:
                dataNew[event.target.name] = event.target.value;
                break;
        }
        setFormData(dataNew);
    }


    const hasMoveDate = (event) => {
        const formErrorsNew = JSON.parse(JSON.stringify(formErrors))
        switch (event.target.checked) {
            case true:
                setMoveDate(true)
                formErrorsNew[formErrorsNew.findIndex((obj => obj.name === "moveDate"))].disabled = false
                break;
            case false:
                setMoveDate(false)
                formErrorsNew[formErrorsNew.findIndex((obj => obj.name === "moveDate"))].disabled = true
                break;
            default:
               break;
        }
        setFormErrors(formErrorsNew)
    }


    if (loading) return <UILIB.Loading type={3} />
  
    return <Fragment>
              <div className="row">
                  <div className="col-xs-12">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-4 mar-b25">
                        <UILIB.Paper style={{ height: "100%" }}>
                          <div className="mar-b15 mar-t5"><b>General Information:</b></div>
                          <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                            <tr>
                              <td width="40%">Requires Move Date?</td>
                              <td width="60%">
                                <UILIB.Toggle 
                                    checked={!formData.chargeable ? true : moveDate} 
                                    value={formData.requiresMoveDate}
                                    name="requiresMoveDate" 
                                    onChange={(event) => { hasMoveDate(event); updateFormData(event); clearErrors();}}
                                    disabled={formData.processStage > 3 || !formData.chargeable ? true : false}
                                />
                              </td>
                            </tr>
                            {formData.requiresMoveDate &&
                            <tr>
                              <td >Movement Date</td>
                              <td>
                                  <UILIB.TextInput
                                    type="date"
                                    className="textInput input"
                                    name="moveDate"
                                    value={formatDateTime(formData.moveDate, 'YYYY-MM-DD')}
                                    onClick={() => clearErrors()}
                                    onChange={(event) => updateFormData(event)}
                                    errored={formErrors.moveDate}
                                    disabled={formErrors.moveDate.disabled || formData.processStage > 3}
                                  />
                                
                              </td>
                            </tr>}
                            <tr>
                              <td>PO Reference</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="purchaseReference"
                                  placeholder="Purchase Order Ref"
                                  value={formData.purchaseReference}
                                  onChange={(event) => updateFormData(event)}
                                  disabled={formData.processStage > 3 ? true : false}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Company Name</td>
                              <td>
                                <UILIB.AutoComplete
                                  className="min-height-50 max-width-400"
                                  name='customerId'
                                  blank='No Customers Found'
                                  query={getCompanies}
                                  enableButton={false}
                                  disabled={formData.processStage > 3 ? true : false}
                                  errored={formErrors.companyName}
                                  onClick={(data) => handleAddSearch(data)} />
                              </td>
                            </tr>
                            <tr>
                              <td>Company Email</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="companyEmail"
                                  placeholder="Company Email"
                                  value={formData.companyEmail}
                                  onClick={() => clearErrors()}
                                  onChange={(event) => updateFormData(event)}
                                  disabled={formData.processStage > 3 ? true : false}
                                  errored={formErrors.companyEmail}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Company Contact Name</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="companyContactName"
                                  placeholder="Company Contact Name"
                                  value={formData.companyContactName}
                                  onClick={() => clearErrors()}
                                  onChange={(event) => updateFormData(event)}
                                  disabled={formData.processStage > 3 ? true : false}
                                  errored={formErrors.companyContactName}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Company Telephone</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="companyTelephone"
                                  placeholder="Company Telephone"
                                  value={formData.companyTelephone}
                                  onClick={() => clearErrors()}
                                  onChange={(event) => updateFormData(event)}
                                  disabled={formData.processStage > 3 ? true : false}
                                  errored={formErrors.companyTelephone}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Logistics Point of Contact</td>
                              <td>
                                <UILIB.Select 
                                  data={logisticsUsers} 
                                  name="logisticsPoCId"
                                  value={formData.logisticsPoCId}
                                  onClick={() => clearErrors()}
                                  onChange={(event) => updateFormData(event)}
                                  disabled={formErrors.logisticsPoCId.disabled || formData.processStage > 3 }
                                  errored={formErrors.logisticsPoCId}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Sales Person</td>
                              <td>
                                <UILIB.AutoComplete
                                  className="min-height-50 max-width-400"
                                  name='customerId'
                                  blank='No Customers Found'
                                  query={getAccountUsers}
                                  enableButton={false}
                                  disabled={formData.processStage > 3 ? true : false}
                                  errored={formErrors.salesPerson}
                                  onClick={(data) => handleUserSearch(data)} />
                              </td>
                            </tr>
                            <tr>
                              <td>Delivery Method</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="delMethod"
                                  placeholder="Delivery Method"
                                  value={formData.delMethod}
                                  onClick={() => clearErrors()}
                                  onChange={(event) => updateFormData(event)}
                                  disabled={formData.processStage > 3 ? true : false}
                                  errored={formErrors.delMethod}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Courier</td>
                              <td>
                                <UILIB.TextInput
                                  type="text"
                                  className="textInput input"
                                  name="courier"
                                  placeholder="Courier"
                                  value={formData.courier}
                                  onClick={() => clearErrors()}
                                  onChange={(event) => updateFormData(event)}
                                  disabled={formData.processStage > 3 ? true : false}
                                  errored={formErrors.courier}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Parking</td>
                              <td>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                  <input
                                    type="radio"
                                    name="parkingOptions"
                                    placeholder="Not Required"
                                    value="Not Required"
                                    checked={formData.parkingOptions === "Not Required" ? true : false}
                                    disabled={formData.processStage > 3 ? true : false}
                                    onClick={(event) => updateFormData(event, 'parkingOptions')} /> Not Required
                                  <input
                                    type="radio"
                                    name="parkingOptions"
                                    placeholder="On Site"
                                    value="On Site"
                                    checked={formData.parkingOptions === "On Site" ? true : false}
                                    disabled={formData.processStage > 3 ? true : false}
                                    onClick={(event) => updateFormData(event, 'parkingOptions')} /> On Site
                                  <input
                                    type="radio"
                                    name="parkingOptions"
                                    placeholder="Street"
                                    value="Street"
                                    checked={formData.parkingOptions === "Street" ? true : false}
                                    disabled={formData.processStage > 3 ? true : false}
                                    onClick={(event) => updateFormData(event, 'parkingOptions')} /> Street
                                  <input
                                    type="radio"
                                    name="parkingOptions"
                                    placeholder="Meter"
                                    value="Meter"
                                    checked={formData.parkingOptions === "Meter" ? true : false}
                                    disabled={formData.processStage > 3 ? true : false}
                                    onClick={(event) => updateFormData(event, 'parkingOptions')} /> Meter
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Print Management System?</td>
                              <td>
                                <UILIB.Checkbox
                                    name="printManagerSystem"
                                    checked={formData.printManagerSystem}
                                    onChange={(event) => updateFormData(event)}
                                    disabled={formData.processStage > 3 ? true : false}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Decomission Required?</td>
                              <td>
                                <UILIB.Checkbox
                                    name="decomRequired"
                                    checked={formData.decomRequired}
                                    onChange={(event) => updateFormData(event)}
                                    disabled={formData.processStage > 3 ? true : false}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Machine(s) to be taken off service?</td>
                              <td>
                                <UILIB.Checkbox
                                    name="macService"
                                    checked={formData.macService}
                                    onChange={(event) => updateFormData(event)}
                                    disabled={formData.processStage > 3 ? true : false}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Chargeable?</td>
                              <td>
                                <UILIB.Checkbox
                                    name="chargeable"
                                    checked={formData.chargeable}
                                    onChange={(event) => updateFormData(event)}
                                    disabled={formData.processStage > 3 ? true : false}
                                />
                              </td>
                            </tr>
                          </table>
                        </UILIB.Paper>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-8 mar-b25">
                        <div className="row">
                          <div className="col-xs-12 col-sm-6 col-xs-6 mar-b25">
                            <UILIB.Paper style={{ height: "100%" }}>
                              <div className="mar-b15 mar-t5"><b>Collection Information:</b></div>
                              <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                                <tr>
                                  <td valign="top">Collection Address</td>
                                  <td>
                                    <UILIB.TextArea
                                        type="text"
                                        className="textInput input"
                                        name="collectionAddress"
                                        style={{ width: "100%", height: "120px", padding: "0", "resize": "none" }} 
                                        value={formData.collectionAddress}
                                        onClick={() => clearErrors()}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                        errored={formErrors.collectionAddress}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Name</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="collectionName"
                                        placeholder="Contact Name"
                                        value={formData.collectionName}
                                        onClick={() => clearErrors()}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                        errored={formErrors.collectionName}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Email</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="collectionEmail"
                                        placeholder="Contact Email"
                                        value={formData.collectionEmail}
                                        onClick={() => clearErrors()}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                        errored={formErrors.collectionEmail}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Telephone</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="collectionTelephone"
                                        placeholder="Contact Telephone"
                                        value={formData.collectionTelephone}
                                        onClick={() => clearErrors()}
                                        onChange={(event) =>updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                        errored={formErrors.collectionTelephone}
                                    />
                                  </td>
                                </tr>
                              </table>
                            </UILIB.Paper>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-xs-6 mar-b25">
                            <UILIB.Paper style={{ height: "100%" }}>
                              <div className="mar-b15 mar-t5"><b>Delivery Information:</b></div>
                              <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
                                <tr>
                                  <td valign="top">Delivery Address</td>
                                  <td>
                                    <UILIB.TextArea
                                        type="text"
                                        className="textInput input"
                                        name="delAddress"
                                        style={{ width: "100%", height: "120px", padding: "0", "resize": "none" }} 
                                        value={formData.delAddress}
                                        onClick={() => clearErrors()}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                        errored={formErrors.delAddress}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Name</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="delContactName"
                                        placeholder="Contact Name"
                                        value={formData.delContactName}
                                        onClick={() => clearErrors()}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                        errored={formErrors.delContactName}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Email</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="delContactEmail"
                                        placeholder="Contact Email"
                                        value={formData.delContactEmail}
                                        onClick={() => clearErrors()}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                        errored={formErrors.delContactEmail}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Contact Telephone</td>
                                  <td>
                                    <UILIB.TextInput
                                        type="text"
                                        className="textInput input"
                                        name="delContactTelephone"
                                        placeholder="Contact Telephone"
                                        value={formData.delContactTelephone}
                                        onClick={() => clearErrors()}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                        errored={formErrors.delContactTelephone}
                                    />
                                  </td>
                                </tr>
                              </table>
                            </UILIB.Paper>
                          </div>
                        </div>
                        <div className="col-xs-12" style={{ padding: "0" }}>
                          <UILIB.Paper style={{ height: "100%" }} >
                            <div className="row">
                              <div className="col-xs-12 col-md-4 mar-b5">
                                  <div className="mar-b15 mar-t5"><b>General Access:</b></div>
                                  <UILIB.TextArea
                                      name="genAccessNotes"
                                      className="textInput input"
                                      style={{ width: "100%", height: "100px", padding: "0", "resize": "none" }} 
                                      value={formData.genAccessNotes}
                                      onChange={(event) => updateFormData(event)}
                                      disabled={formData.processStage > 3 ? true : false}
                                  />
                              </div>
                              <div className="col-xs-12 col-md-4 mar-b5">
                                  <div className="mar-b15 mar-t5"><b>IT requirements:</b></div>
                                  <UILIB.TextArea
                                      name="itRequirementsNotes"
                                      className="textInput input"
                                      style={{ width: "100%", height: "100px", padding: "0", "resize": "none" }} 
                                      value={formData.itRequirementsNotes}
                                      onChange={(event) => updateFormData(event)}
                                      disabled={formData.processStage > 3 ? true : false}
                                  />
                              </div>
                              <div className="col-xs-12 col-md-4 mar-b5">
                                  <div className="mar-b15 mar-t5"><b>Logistics Notes:</b></div>
                                  <UILIB.TextArea
                                      className="textInput input"
                                      name="logisticsNotes"
                                      style={{ width: "100%", height: "100px", padding: "0", "resize": "none" }} 
                                      value={formData.logisticsNotes}
                                      onChange={(event) => updateFormData(event)}
                                      disabled={formData.processStage > 3 ? true : false}
                                  />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-12 col-md-5" style={{ padding: 10 }}>
                                <div className="mar-b15 mar-t20 mar-l5"><b>Devices Require Network Connection:</b></div>
                                <table width="100%" cellPadding="0" cellSpacing="0" className="subTable" style={{maring: 0}}>
                                  <tr>
                                    <td width="25%">If Yes, provide IT contact details</td>
                                    <td width="25%">
                                      <input
                                          type="radio"
                                          name="requiresNetwork"
                                          value="Yes"
                                          checked={formData.requiresNetwork === "Yes" ? true : false}
                                          disabled={formData.processStage > 3 ? true : false}
                                          onClick={(event) => updateFormData(event, 'requiresNetwork')} /> Yes
                                      <input
                                          type="radio"
                                          name="requiresNetwork"
                                          value="No"
                                          checked={formData.requiresNetwork === "No" ? true : false}
                                          disabled={formData.processStage > 3 ? true : false}
                                          onClick={(event) => updateFormData(event, 'requiresNetwork')} /> No
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="25%">Contact Name</td>
                                    <td width="25%">
                                      <UILIB.TextInput
                                          type="text"
                                          placeholder="Contact Name"
                                          value={formData.contactNameNetwork}
                                          className="textInput input"
                                          name="contactNameNetwork"
                                          onClick={() => clearErrors()}
                                          onChange={(event) => updateFormData(event)}
                                          disabled={formData.requiresNetwork === "No" || formData.processStage > 3} 
                                          errored={formErrors.contactNameNetwork}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="25%">Contact Email</td>
                                    <td width="25%">
                                      <UILIB.TextInput
                                          type="text"
                                          placeholder="Contact Email"
                                          value={formData.contactEmailNetwork}
                                          className="textInput input"
                                          name="contactEmailNetwork"
                                          onClick={() => clearErrors()}
                                          onChange={(event) => updateFormData(event)}
                                          disabled={formData.requiresNetwork === "No" || formData.processStage > 3 }
                                          errored={formErrors.contactEmailNetwork}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="25%">Contact Telephone</td>
                                    <td width="25%">
                                      <UILIB.TextInput
                                          type="text"
                                          placeholder="Contact Telephone"
                                          value={formData.contactTelephoneNetwork}
                                          className="textInput input"
                                          name="contactTelephoneNetwork"
                                          onClick={() => clearErrors()}
                                          onChange={(event) => updateFormData(event)}
                                          disabled={formData.requiresNetwork === "No" || formData.processStage > 3 }
                                          errored={formErrors.contactTelephoneNetwork}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </div>
                              <div className="col-xs-12 col-md-7" style={{ padding: 10 }}>
                                <div className="mar-b15 mar-t20 mar-l5"><b>Details of any existing PS:</b></div>
                                <table width="100%" cellPadding="0" cellSpacing="0" className="subTable" style={{margin: 0}}>
                                  <tr>
                                    <td width="30%">Address books?</td>
                                    <td width="70%">
                                      <UILIB.Checkbox
                                        name="psAddressBook"
                                        checked={formData.psAddressBook}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>KPAX/E-maintenance/FM</td>
                                    <td>
                                      <UILIB.Checkbox
                                        name="psKpax"
                                        checked={formData.psKpax}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Print management</td>
                                    <td>
                                      <UILIB.Checkbox
                                        name="psPrintManagement"
                                        checked={formData.psPrintManagement}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td valign="top">Other </td>
                                    <td colspan="5">
                                      <UILIB.TextArea
                                        type="text"
                                        className="textInput input"
                                        name="psOther"
                                        style={{ width: "100%", height: "80px", padding: "0", "resize": "none" }} 
                                        value={formData.psOther}
                                        onClick={() => clearErrors()}
                                        onChange={(event) => updateFormData(event)}
                                        disabled={formData.processStage > 3 ? true : false}
                                        errored={formErrors.psOther}
                                      />  
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </UILIB.Paper>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </Fragment>

}


