import axios from 'classes/axios';
import { updateLeasePeriodPayment, getDealPricing } from 'pages/cp/saleshub/accountmanagerview/shared/functions'

export async function checkAndSave(props) {
  try {

      let newCompatibles = [...props.compatibles];
      let confProd = {};
      props.saveError.current.error = false;
      props.setSaving(true);
      

      //grab conflicting ids of selected
      const conflicting = [].concat.apply([], newCompatibles.filter(c => c.selected).map(f => { return f.ConflictingParent.map(cp => cp.id) }));

      //check for required
      newCompatibles.filter(f => !conflicting.includes(f.id)).forEach(compat => {
          let checkAllRequired = true;
          if (compat.selected && compat.Parent && compat.Parent.length) {
              //if the selected product has children, check they arent required 
              compat.Parent.filter(f => !conflicting.includes(f.id)).forEach(pC => {

                  if (pC.SalesPartCompatible && pC.SalesPartCompatible.required) {

                      confProd = newCompatibles.find(c => c.id === pC.id);
                      if (typeof confProd === 'undefined') return;
                      confProd.error = "";

                      if (!confProd.selected) {
                          checkAllRequired = false;
                          confProd.error = "Required"
                          props.saveError.current = { error: true, message: 'Required products are missing. Please see below.'};
                      }
                  }
              })
          }

          if (checkAllRequired && compat.SalesPartCompatible.required && !compat.selected) {
              //see if we have any parents that are selected
              let hasSelectedConflicts = false;
              if (compat.ConflictingParent && compat.ConflictingParent.length) {
                  compat.ConflictingParent.forEach(cP => {
                      let confProd = newCompatibles.find(c => c.id === cP.id)
                      if (confProd && confProd.selected) hasSelectedConflicts = true;
                  })
              }
              if (compat.Parent && compat.Parent.length) {
                  compat.Parent.forEach(pC => {
                      let confProd = newCompatibles.find(c => c.id === pC.id)
                      if (confProd && confProd.selected) hasSelectedConflicts = true;
                  })
              }

              compat.error = "";
              if (!hasSelectedConflicts) {
                  compat.error = "Required"
                  props.saveError.current = { error: true, message: 'Required products are missing. Please see below.' };
              }
          }
      })

      if (props.saveError.current.error) {
        showError(props);
        return;
      }

      const mainData = {
        dealID: props.ourDeal.dealID,
        qty: 1,
        portalPartId: props?.selectedProduct?.id,
        specificDeviceLocation: null,
        partNo: props?.selectedProduct?.partNumber,
        newUsed: "New",
        siteAddress: "",
        showToCustomer: 1,
        isADevice: props?.selectedProduct?.SalesPartTypeId === 34 ? 1 : 0,
        networkGuysFlagged: 0,
        isCarePlus: 0,
        extraDiscount: 0,
        displayOrder: 0,
        estimatedVolume: 0,
        estimatedVolumeColour: 0,
        parentId: 0,
        rowType: 0,
        ...props.product,
        cpcMono: (props?.selectedProduct?.cpcMono) ? props.selectedProduct.cpcMono : 0,
        cpcColour: (props?.selectedProduct?.cpcColour) ? props.selectedProduct.cpcColour : 0,
        manufacturer: props.selectedProduct?.SalesPartManufacturer?.name,
        description: props?.selectedProduct?.name,
        supplier: props?.selectedProduct?.supplier,
        discount: props?.selectedProduct?.discount,
        costEach: props?.selectedProduct?.basePrice,
        origPriceEach: props?.selectedProduct?.listPrice,
        priceEach: props?.selectedProduct?.priceEach
      };

      
      const compatData = [];
      const foundChildRecords = props.ourDeal.product.filter(x => Number(x.parentId) === Number(props?.product?.prodID));

      props.compatibles.filter(f => f.selected).forEach(compatible => {
        const found = foundChildRecords.find(x => Number(x.portalPartId) === Number(compatible?.id));
        compatData.push({
          dealID: props.ourDeal.dealID,
          portalPartId: compatible?.id,
          specificDeviceLocation: null,
          description: compatible?.name,
          partNo: compatible?.partNumber,
          newUsed: "New",
          origPriceEach: compatible?.listPrice,
          siteAddress: "",
          cpcMono: (compatible?.monoBasePrice) ? compatible.monoBasePrice : 0,
          cpcColour: (compatible?.colourBasePrice) ? compatible.colourBasePrice : 0,
          showToCustomer: 1,
          isADevice: 0,
          networkGuysFlagged: 0,
          isCarePlus: 0,
          extraDiscount: 0,
          displayOrder: 0,
          estimatedVolume: 0,
          estimatedVolumeColour: 0,
          rowType: 0,
          ...found,
          qty: (compatible?.qty && Number(compatible.qty) > 1) ? compatible.qty : 1,
          supplier: compatible?.SalesPartSupplier?.name,
          discount: (isNaN(compatible?.discount)) ? 0 : compatible.discount,
          costEach: compatible?.basePrice,
          priceEach: compatible?.priceEach
        });
      })

      const baseData = [mainData, ...compatData];
      const result = await axios.post(`/entities/workflow/deals/actionCreateUpdateConfigurator/${props.ourDeal.dealID}`, baseData);
      
      await getDealPricing(props.ourDeal.dealID, props.ourDeal, props.setOurDeal);
      await updateLeasePeriodPayment(props.ourDeal, props.setOurDeal);

      props.setOurDeal({ 
        ...props.ourDeal, 
        product: result.data.result.product, 
        compatibles: result.data.result.compatibles, 
        selected: result.data.result.selected.map(x => { return { ...x, value: x.deviceNumber, label: x.deviceNumber } })
      });
      props.setSaving(false); 
      props.onFinish(); 

  }
  catch (err) {
      console.log(err);
      showError(props);
  }
}


export async function selectCompat(props, compat) {

  let compatObj = JSON.parse(JSON.stringify(props.compatibles));
  let compatible = compatObj.find(c => Number(c.id) === Number(compat.id));
  let compatibleIndex = compatObj.findIndex(obj => Number(obj.id) === Number(compat.id));

  if (!compatible || typeof (compatibleIndex) === 'undefined') return;

  if (compatible.selected) {
      compatObj[compatibleIndex].selected = false;
  }
  else {
      const listPrice = compatObj[compatibleIndex].listPrice;
      const basePrice = compatObj[compatibleIndex].basePrice;
      const discount = Math.abs((Number(basePrice) - Number(listPrice)) / Number(listPrice) * 100);
      compatObj[compatibleIndex].discount = discount.toFixed(2);
      compatObj[compatibleIndex].selected = true;
      compatObj[compatibleIndex].qty = (compatObj[compatibleIndex].qty > 1) ? compatObj[compatibleIndex].qty : 1;
      compatObj[compatibleIndex].error = "";
      compatObj[compatibleIndex].supplier = compatible.SalesPartSupplier.name;
  }
  props.setCompatibles(compatObj);
  props.setCompatiblesLoaded(true);
}


export function showError(props) {
  const pageErrorsNew = { ...props.pageErrors };
  pageErrorsNew.saveAndExit.error = true;
  props.setPageErrors(pageErrorsNew);
  setTimeout(() => {
    pageErrorsNew.saveAndExit.error = false;
    props.setPageErrors(pageErrorsNew);
    props.setSaving(false);
  }, 2000);
}


export async function getProducts(props) {
  props.setProductsLoading(true);
  if(props.rangeId) {
    const productData = await axios.get(`/sales/parts/?rangeId=${props.rangeId}`);
    props.setProducts(productData.data);
  } else {
    props.setProducts([]);
  }
  props.setProductsLoading(false);
}

export async function getTypes(props) {
  props.setTypesLoading(true);
  let typeData = await axios.get(`/sales/parts/type`);
  typeData = typeData.data.map(t => { return { label: t.name, value: t.id, displayOrder: t.displayOrder } });
  typeData.unshift({ label: "All Types", value: 0, displayOrder: 0 })
  props.setTypes(typeData);
  props.setTypesLoading(false);
}


export async function getCompatibles(props) {

  let prodID = null
  switch (true) {
      case props.options === 0:
          prodID = props.selectedProduct.id;
          break;
      case props.options === 1:
          prodID = props.portalProductId;
          break;
      default:
          break;
  }

  const results = await axios.get(`/sales/parts/compatible/` + prodID);

  let compats = results.data;

  compats.map(p => {
      p.selected = false;
      p.error = "";
      if (!p.qty) p.qty = 0;
      return p;
  })

  compats.sort((a, b) => {
      if (a.SalesPartTypeId < b.SalesPartTypeId) return -1;
      if (a.SalesPartTypeId > b.SalesPartTypeId) return 1;
      return 0;
  })

  compats.filter(c => c.selected === false).map(cp => {
    const listPrice = cp.listPrice;
    const basePrice = cp.basePrice;
    const discount = Math.abs((Number(basePrice) - Number(listPrice)) / Number(listPrice) * 100);
    return cp.discount = discount.toFixed(2);
  })

  if (props.selectedProduct && props.selectedProduct.children && props.selectedProduct.children.length) {
    compats.forEach(compat => {
      let found = props.selectedProduct.children.find(child => Number(child.portalPart && child.portalPart.id) === Number(compat.id))
      if (found) {
        const listPrice = compat.listPrice;
        const basePrice = compat.basePrice;
        const discount = Math.abs((Number(basePrice) - Number(listPrice)) / Number(listPrice) * 100);
        compat.discount = discount.toFixed(2);
        compat.selected = true;
        compat.priceEach = found.priceEach ? Number(found.priceEach) : '';
        compat.discount = found.discount ? Number(found.discount) : 0;
        compat.basePrice = found.costEach ? Number(found.costEach) : 0;
        compat.qty = !isNaN(found.qty) ? Number(found.qty) : 0;
        compat.supplier = found.supplier ? found.supplier : "";

        let tmpQty = compat.qty;
        if ((props.selectedProduct.qty || 1) > 1) {
            tmpQty = compat.qty / props.selectedProduct.qty;
            compat.qty = Number(tmpQty);
        }
      }
    });
  }

  props.setCompatibles(compats);
  props.setCompatiblesLoaded(true);
}


export async function getRanges(props) {

  if (props.manufacturerId === "refurbs") {
      props.setRefurbsLoading(true);
      let refurbsData = await axios.get(`/sales/parts/refurbs`);

      props.setRefurbs(refurbsData.data.map(r => {
          return {
              id: r.EQ_ID,
              name: r.ST_DESC,
              description: r.ST_NAME
          }
      }));
      props.setRefurbsLoading(false);
  }
  else {
      props.setRangesLoading(true);
      const rangeData = await axios.get(`/sales/parts/range/?manufacturerId=${props.manufacturerId}`);
      props.setRanges(rangeData.data.map(row => { return { label: row.name, value: row.id } }));
      props.setRangesLoading(false);
  }

}