import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import Axios from "classes/axios";
import UILIB from "components";
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getRequestPortalErrors } from 'classes/formErrors/repository';
import { getSearchMessage, getRequestMessage, getEmptyFormObject } from '../functions';
import { createTextInput, createButton, createTextArea } from 'classes/controls/formControls';
import { getCompaniesPortals } from 'classes/helpers';


export default function RequestPortalDrawer({ sync, onClose }) {

  const azureId = useSelector((state) => state.account.azureId);
  const [formData, setFormData] = useState(getEmptyFormObject(azureId));
  const [formErrors, setFormErrors] = useState(getEmptyFormObject());
  const defaultBanner = { error: false, message: '' };
  const [bannerSearch, setBannerSearch] = useState(defaultBanner);
  const [bannerRequest, setBannerRequest] = useState(defaultBanner);
  const [requestSubmitting, setRequestSubmitting] = useState(false);
  const [enableForm, setEnableForm] = useState(false);


  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];


  const handleAddSearch = (data) => {
    const result = getSearchMessage(data);
    setBannerSearch(result);
    setEnableForm(result.formEnable);
    if(result.formEnable) setFormData({ 
      ...formData, 
      customerId: data.id, 
      companyName: data.Name, 
      accountNumber: data.Reference, 
      serviceRegion: data.ServiceRegion });
  }

  const actionUpdateField = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  const actionClearData = () => {
    setBannerSearch(defaultBanner);
    setBannerRequest(defaultBanner);
    setEnableForm(false);
    setFormErrors(getEmptyFormObject());
  }

  const submitRequest = async () => {
    try {
      setRequestSubmitting(true);
      const formResult = await checkFormErrors(getRequestPortalErrors(formData), setFormErrors, null, null, formData, null);
      if(formResult.errorCount === 0) {
        const result = await Axios.post("/entities/workflow/portals/actionRequestPortal", formData);
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerRequest({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerRequest({ error: true, message: notFoundError });
        } else {
          const requestMessage = getRequestMessage(result.data);
          if(!requestMessage.error) { 
            onClose(); 
          } else {
            setBannerRequest(requestMessage);
          }
        }
      }
    } catch (err) {
      console.log(err);
      setBannerRequest({ error: true, message: exceptionError });
    }
    setRequestSubmitting(false);
  };


  return <Fragment>
      <div className='flex-container row start width-100 mar-b10'>
        <div className='flex-item start width-100 mar-b20'><h3>Request a Portal</h3></div>
        <UILIB.Paper className='flex-item grey wrap width-100 mar-2'>
          <UILIB.AutoComplete
            className="min-height-50 width-100"
            name='customerId'
            blank='No Customers Found'
            query={getCompaniesPortals}
            enableButton={true}
            onClearDown={() => actionClearData()}
            onClick={(data) => handleAddSearch(data)} />
        </UILIB.Paper>
        <div className="flex-item row width-100">
          {bannerSearch.error && <div className="flex-container flex-grow-1 mar-r10 mar-l10 end width-100">
            <div className="errored message">{bannerSearch.message}</div>
          </div>}
          <div className='flex-container row start width-100 mar-t10'>
            {createTextInput('First Name *', 'customerFirstName', (!enableForm || requestSubmitting), formData.customerFirstName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
            {createTextInput('Last Name *', 'customerLastName', (!enableForm || requestSubmitting), formData.customerLastName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
            {createTextInput('Email Address *', 'customerEmail', (!enableForm || requestSubmitting), formData.customerEmail, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
            {createTextInput('Company Name *', 'companyName', (!enableForm || requestSubmitting), formData.companyName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
            {createTextInput('Account Number *', 'accountNumber', (!enableForm || requestSubmitting), formData.accountNumber, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
            {createTextArea('Notes', 'notes', (!enableForm || requestSubmitting), formData.notes, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'height-100-px')}
          </div>
          <div className='flex-container row end width-100'>
            {createButton('', 'requestPortal', 'Request Portal', (!enableForm || requestSubmitting), requestSubmitting, async () => await submitRequest(), null, 'flex-item start min-width-150')}
          </div>
          {bannerRequest.error && <div className="flex-container end flex-grow-1 width-100 mar-r5 mar-l5 ">
            <div className="errored message">{bannerRequest.message}</div>
          </div>}  
        </div>
      </div>
  </Fragment>
}
