import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components'

const headerData = [
  { label: "#", value: "id", type: 'string', width: '50px', align: 'left' },
  { label: "First Name", value: "firstName", type: 'string', width: '150px', align: 'left' },
  { label: "Last Name", value: "lastName", type: 'string', width: '150px', align: 'left' },
  { label: "Department", value: "department", type: 'string', width: '150px', align: 'left' },
  { label: "Date Of Birth", value: "dateOfBirth", type: 'string', width: '150px', align: 'left' },
  { label: "Driver Type", value: "driverType", type: 'string', width: '150px', align: 'left' },
  { label: "Job Role", value: "jobRole", type: 'string', width: '150px', align: 'left' },
  { label: "Work Email", value: "workEmail", type: 'string', width: '150px', align: 'left' },
  { label: "Manager Email", value: "managersEmail", type: 'string', width: '150px', align: 'left' },
  { label: "Mobile Number", value: "mobileNumber", type: 'string', width: '150px', align: 'left' },
  { label: "Message", value: "reason", type: 'string', width: '250px', align: 'left' },
  { label: "Result", value: "operationType", type: 'number', width: '80px', align: 'left' }
]

export default function DriverImportTable(props) {
  
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState([]);

  const tablefilter = props.tableSortHandler.filter === 'View All Rows' ? null : props.tableSortHandler.filter;

  const tableDataResult = tableData.filter(f => !tablefilter ? f : tablefilter.toLowerCase() === f.operationType.toLowerCase()).map(row => {

    let operationType = { data: <UILIB.Chip className="table grey text-small width-100" value="Unknown" />, value: 0 };
    if (row.operationType === 'CREATE') operationType = { data: <UILIB.Chip className="table green text-small width-100" value="Create" />, value: 1 };
    if (row.operationType === 'UPDATE') operationType = { data: <UILIB.Chip className="table orange text-small width-100" value="Update" />, value: 2 };
    if (row.operationType === 'FAILED') operationType = { data: <UILIB.Chip className="table red text-small width-100" value="Failed" />, value: 3 };

    return {
      id: { value: row.id, raw: row.id },
      firstName: { value: row.line.firstName, raw: row.line.firstName },
      lastName: { value: row.line.lastName, raw: row.line.lastName },
      department: { value: row.line.department, raw: row.line.department },
      dateOfBirth: { value: row.line.dateOfBirth, raw: row.line.dateOfBirth },  
      driverType: { value: row.line.driverType, raw: row.line.driverType },
      jobRole: { value: row.line.jobRole, raw: row.line.jobRole },
      workEmail: { value: row.line.workEmail, raw: row.line.workEmail },
      managersEmail: { value: row.line.managersEmail, raw: row.line.managersEmail },
      mobileNumber: { value: row.line.mobileNumber, raw: row.line.mobileNumber },
      operationType: { value: operationType.data, raw: operationType.value },
      reason: { value: row.reason, raw: row.reason }
    }
  })

  useEffect(() => { 
    setLoading(true);
    setTableData(props.uploadedRowsModal.fleetHubImportRow);
    setLoading(false);
  }, [props.uploadedRowsModal.fleetHubImportRow]);


  return (
    <Fragment>
        <UILIB.Table
          className='small' 
          overflowX='auto'
          overflowY='auto'
          fitToParent={true}
          loading={loading}
          header={headerData}
          data={tableDataResult}
          sortable={true}
          filter={true}
          resetFilters={false}
          maxRows={20}
          autoResize={false}
          defaultOrderBy='importDate'
          defaultOrderDir='DESC'
          exportEnable={true} 
        />
    </Fragment>
  );
}