import { useState, Fragment, useEffect } from 'react';
import { setDrawer } from "store/actions";
import { useDispatch } from 'react-redux';
import UILIB from 'components';
import Modules from '../../Modules';


export default function Contacts(props) {

  const headerData = [
    { label: "From VO", value: "fromVo", align: "left", type: 'string' },
    { label: "Contact Name", value: "contactName", align: "left", type: 'string' },
    { label: "Email", value: "contactEmail", align: "left", type: 'string' },
    { label: "Phone", value: "contactPhone", align: "left", type: 'string' },
    { label: "Mobile", value: "contactMobile", align: "left", type: 'string' },
    { label: "Select", value: "source", align: "left", type: 'string', width: "50px" }, 
    { label: "Edit", value: "options", align: "left", type: 'string', width: "35px" }]

  const dispatch = useDispatch()
  const [tableData, setTableData] = useState([]);
  const [render, setRender] = useState(false);


  const contactSelected = async () => {
    dispatch(setDrawer({ open: false, content: null }));
    setRender(!render);
  }

  const contactEditComplete = async () => {
    dispatch(setDrawer({ open: false, content: null }));
    setRender(!render);
  }

  const selectContact = (contact) => {
    dispatch(setDrawer({ open: true, width: "900px", content: 
      <Modules.KeyContactSelectDrawer
        onFinished={contactSelected} 
        contact={contact}
        ourDeal={props.ourDeal} 
        setOurDeal={props.setOurDeal} 
        isActive={props.isActive} /> 
    }));
  }

  const editContact = (contact) => {
    dispatch(setDrawer({ open: true, content: 
      <Modules.KeyContactsDrawer 
        onFinished={contactEditComplete} 
        contact={contact}
        setOurDeal={props.setOurDeal} 
        ourDeal={props.ourDeal} 
        isActive={props.isActive} /> 
    }));
  }

  const getSelectButton = (contact, props, errorObj) => {
    return <UILIB.Button 
      disabled={!props.isActive} 
      style={{ width: "100%" }} 
      value="Select"
      className="green" 
      onClick={() => { selectContact(contact); }} 
      errored={errorObj} />
  }

  const getEditDrawer = (contact, props) => {
    return <div className="flex-container row center align-center">
        {props.isActive ? <div className="icon icon-pencil" style={{ cursor: "pointer" }} onClick={() => editContact(contact)}></div> : <div></div>}
    </div>
  }

  useEffect(() => {

    if(!props.dataLoaded.crm) return;

    const tableDataTmp = [];

    const slaStyleCRM = { backgroundColor: 'rgba(189, 195, 199, 0.5)' };
    const crmName = (props?.ourDeal?.crm?.contacts?.fullname);
    const crmEmail = (props?.ourDeal?.crm?.contacts?.emailaddress1);
    const crmNumber = (props?.ourDeal?.crm?.contacts?.telephone1);
    const crmMobile = (props?.ourDeal?.crm?.contacts?.mobilephone);

    tableDataTmp.push({
      fromVo: { value: "Order Contact", raw: "Order Contact", tdStyle: slaStyleCRM },
      contactName: { value: crmName, raw: crmName, tdStyle: slaStyleCRM },
      contactPhone: { value: crmNumber, raw: crmNumber, tdStyle: slaStyleCRM },
      contactEmail: { value: crmEmail, raw: crmEmail, tdStyle: slaStyleCRM },
      contactMobile: { value: crmMobile, raw: crmMobile, tdStyle: slaStyleCRM },
      source: { value: null, raw: null, tdStyle: slaStyleCRM },
      options: { value: <div></div>, raw: <div></div>, tdStyle: slaStyleCRM } })

    for (let i = 0; i < props?.ourDeal?.keyContact.length; i++) { 

      const contact = props?.ourDeal?.keyContact[i];
      const slaStyle = { backgroundColor: 'rgba(189, 195, 199, 0.0)' };
      const errorObj = (props.ourDeal.processStage > 0 && props.additionalInfoErrors) ? props?.additionalInfoErrors[contact.contactTypeTxt] : null;
      
      tableDataTmp.push({ 
        fromVo: { value: contact.contactTypeTxt, raw: contact.contactTypeTxt, tdStyle: slaStyle },
        contactName: { value: contact.contactName, raw: contact.contactName, tdStyle: slaStyle },
        contactPhone: { value: contact.contactPhone, raw: contact.contactName, tdStyle: slaStyle },
        contactEmail: { value: contact.contactEmail, raw: contact.contactName, tdStyle: slaStyle },
        contactMobile: { value: contact.contactMobile, raw: contact.contactName, tdStyle: slaStyle },
        source: { value: getSelectButton(contact, props, errorObj), raw: null, tdStyle: slaStyle },
        options: { value: getEditDrawer(contact, props), raw: null, tdStyle: slaStyle } })
    }
    setTableData(tableDataTmp)
  }, [props.ourDeal, props.refreshData, render, props.isActive]);

    
  return <Fragment>
    <UILIB.Table
      className="small" 
      overflowX="auto"
      overflowY="hidden"
      height={400}
      loading={false}
      header={headerData}
      data={tableData}
      sortable={false}
      filter={false}
      maxRows={10}
      autoResize={false}
      defaultOrderBy="" 
      defaultOrderDir="" />
  </Fragment>
}