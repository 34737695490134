import { useState} from 'react';
import { Fragment } from 'react';
import UILIB from 'components';
import RdHubUsers from './tabs/rdHubUsers';
import FailedEmails from './tabs/failedEmails';
import RefurbDevices from './tabs/refurbDevices';

export default function RdHub() {
  const [currentTab, setCurrentTab] = useState(2);

  const tabs = [
    { label: 'RD Hub Users', value: 0 },
    { label: 'Failed Emails', value: 1 },
    { label: 'Refurb Devices', value: 2 },
  ];

  return (
    <Fragment>
      <UILIB.SubNav tabs={tabs} currentTab={currentTab} outerClasses="mar-t15 mar-b15" onClick={(newId) => setCurrentTab(newId)} />

      {currentTab === 0 && <RdHubUsers />}
      {currentTab === 1 && <FailedEmails />}
      {currentTab === 2 && <RefurbDevices />}
    </Fragment>
  );
}
