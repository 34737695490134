import { useRef, Fragment } from "react";
import Axios from "classes/axios";
import UILIB from "components";
import { setFilterVars } from '../functions'

export default function MIFLookupTable({ context, filter, setFilter }) {
  const headerData = [
    { label: "Group Code", value: "groupCode", type: "string", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Account #", value: "accountnumber", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Parent Name", value: "parentname", type: "string", filtering: true, minWidth: 300, maxWidth: 300 },
    { label: "Total Devices", value: "total", type: "number", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Total Devices Impacted", value: "impacted", type: "number", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Never Communicated", value: "neverCommunicated", type: "number", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "No Comms 5 Days", value: "noComms5days", type: "number", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Exceptions List Count", value: "exceptions", type: "number", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Service Region", value: "serviceregionname", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
  ];

  let orderBy = '';

  if(context.value === 1) {
    orderBy = 'impacted'
  }
  else if(context.value === 2 || context.value === 3) {
    orderBy = 'neverCommunicated'
  }

  const pageContext = context.context;
  const entityData = useRef({});
  const pageSelectedNumber = 50;
  const tablePageDefaults = { deleteEnabled: false, caching: true, paging: { pages: [10, 20, 50, 100, 200], pageSelected: pageSelectedNumber, limit: pageSelectedNumber, offset: 0, orderBy: orderBy } };
  
  const handleDrilldown = (drilldownColumn, drilldownValue) => {

    const newTableFilter = { ...filter };

    if (newTableFilter.button === drilldownValue) {
        newTableFilter.button = '';
    } else {
        newTableFilter.button = drilldownColumn;
        newTableFilter[drilldownColumn] = drilldownValue;
    }
    setFilter(newTableFilter);
  };

  async function constructTable(mifData) {

    const tableData = mifData.map(row => {
        return {
          groupCode: {
            value: row.groupCode !== 'NA'
              ? <UILIB.Link onClick={() => handleDrilldown('groupCode', row.groupCode)}>{row.groupCode}</UILIB.Link>
              : row.groupCode,
            raw: row.groupCode
          },
          accountnumber: { value: <UILIB.Link onClick={() => handleDrilldown('accountNumber', row.accountnumber)}>{row.accountnumber}</UILIB.Link>, raw: row.accountnumber },
          parentname: { value: row.parentname, raw: row.parentname },
          total: { value: row.Total, raw: row.Total },
          impacted: { value: row.impacted, raw: row.impacted },
          neverCommunicated: { value: row.neverCommunicated, raw: row.neverCommunicated },
          noComms5days: { value: row.noComms5days, raw: row.noComms5days },
          exceptions: { value: row.exceptions, raw: row.exceptions },
          serviceregionname: { value: row.serviceregionname, raw: row.serviceregionname },
        };
    });
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const filterButtons = setFilterVars(pageContext, filter);
    const buttonAppend = filterButtons ? ` ${filterButtons} ` : '';

    entityData.current = await Axios.get(`/entities/mif/getSummaryBasic/?&$filter=${buttonAppend}${queryLocal}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }

  return (
    
    <Fragment>
      <UILIB.Paper className="width-100">
        <UILIB.TableNew
          name={`RMB${pageContext}`}
          className="small"
          overflowX="auto"
          overflowY="hidden"
          header={headerData}
          localQuery={() => constructTable(entityData.current && entityData.current.result ? entityData.current.result : [])}
          remoteQuery={getTableData}
          defaultSettings={tablePageDefaults} />
      </UILIB.Paper>
    </Fragment>
  );
}
