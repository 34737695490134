import { useState, useRef, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom'
import { exportData, toastContainer } from '../../shared/functions';
import { useDispatch, useSelector } from 'react-redux';
import { setFleetHubReportSelected, setDrawer } from 'store/actions';
import UILIB from 'components';
import Axios from 'classes/axios';
import FleetMenu from '../../shared/fleetMenu';
import DrawCRUD from './vehicleCRUD';
import DocumentsModal from '../documents/modal/documentsModal';

export default function VehicleTable(props) {
  const history = useHistory();

  //exports
  const [exporting, setExporting] = useState({ 
    name: 'Export All', 
    exportUrl: '/fleet/vehicles/export', 
    fileName: 'Driver_Export', 
    setExporting: false 
  });

  //documents
  const [showDocumentsModal, setShowDocumentsModal] = useState({ show: false, entity: null });

  //Specific to entity
  const entityName = 'Vehicle';
  const entityUrl = `/fleetHub/vehicles/all`;
  const entityApiUrl = '/fleet/vehicles/all';
  const entityUrlRenewalUpcoming = '/fleetHub/vehicles/renewalUpcoming';
  const entityApiUrlRenewalUpcoming = '/fleet/vehicles/renewalUpcoming';
  const entityUrlRenewalOrder = '/fleetHub/vehicles/renewalOrder';
  const entityApiUrlRenewalOrder = '/fleet/vehicles/renewalOrder';
  const entityAddText = 'Add Vehicle';
  const drawWidth = '700px';
  const headerData = [
    { label: 'Id', value: 'id', type: 'number' },
    { label: "Registration", value: "registration", type: 'string' },
    { label: "Make", value: "make", type: 'string' },
    { label: "Model", value: "model", type: 'string' },
    { label: "Region", value: "region", type: 'string' },
    { label: "Contract Type", value: "contractType", type: 'string' },
    { label: "Vehicle Type", value: "vehicleType", type: 'string' },
    { label: "First Registration Date", value: "registrationDate", type: 'string' },
    { label: "Contract End Date", value: "contractEndDate", type: 'string' },
    { label: "P11D", value: "p11d", type: 'string' },
    { label: "Engine Size", value: "engineSize", type: 'string' },
    { label: "Fuel Type", value: "fuelType", type: 'string' },
    { label: "CO2", value: "co2", type: 'string' },
    { label: 'Documents', value: 'documents', type: 'number', width: '110px' },
    { label: '', value: 'delete' } ];
  const [entityUrlCurrent, setEntityUrlCurrent] = useState(props.pick);

  async function getTableData(entityData) {
    let tableData = [];
    if (entityData && entityData.length) {
      tableData = await Promise.all(entityData.map(async entity => {
        const link = <UILIB.Button value={entity.id} onClick={() => drawCRUD({ type: 'edit', entity: entity })} className='secondary table text-small width-100' />
        const documentCount = await Axios.get(`/fleet/documents/${entityName}/count?documentId=${entity.documentIdentity}`);
        return {          
          id: { value: link, raw: entity.id },
          registration: { value: entity.registration, raw: entity.registration },
          make: { value: entity.make, raw: entity.make },
          model: { value: entity.model, raw: entity.model },
          region: { value: entity.region, raw: entity.region },
          contractType: { value: entity.contractType, raw: entity.contractType },
          vehicleType: { value: entity.vehicleType, raw: entity.vehicleType },
          registrationDate: { value: entity.registrationDate, raw: entity.registrationDate },
          contractEndDate: { value: entity.contractEndDate, raw: entity.contractEndDate },
          p11d: { value: entity.p11d, raw: entity.p11d },
          engineSize: { value: entity.engineSize, raw: entity.engineSize },
          fuelType: { value: entity.fuelType, raw: entity.fuelType },
          co2: { value: entity.co2, raw: entity.co2 },
          documents: { value: <UILIB.Button className='secondary table text-small width-100' value={`View (${documentCount.data.result})`} onClick={() => setShowDocumentsModal({ show: true, entity: entity })} />, raw: documentCount.data.result },
          delete: { value: <div onClick={() => drawCRUD({ type: 'delete', entity: entity })} className={'icon-trash2 pointer center text-primary mar-t5'} /> }
        }
      }));
    }

    setTableData(tableData);
  }

  //Generic from here on down
  const dispatch = useDispatch();
  const reduxSelected = useSelector((state) => state.fleetReportSelected);
  const [tableData, setTableData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const tableDataRaw = useRef([]);

  const css = (item) => {
    const entityUrlCurrentNew = (history.location.stats ? history.location.pathname.replace('fleetHub', 'fleet') : entityUrlCurrent);
    return (entityUrlCurrentNew === item) ? 'mar-b10 mar-r10 text-bold' : 'mar-b10 mar-r10';
  }

  const drawCRUD = (data) => {
    dispatch(setDrawer({ open: true, content: <DrawCRUD data={data} sync={syncEntity} />, width: drawWidth }));
  }

  const syncEntity = async (entity) => {
    let result = {};
    if ('deleted' in entity) {
      result = tableDataRaw.current;
      const index = result.map(x => x.id).indexOf(entity.id);
      result.splice(index, 1);
    } else if (!tableDataRaw.current.find(x => x.id === entity.id)) {
      result = tableDataRaw.current;
      result.push(entity);
    } else {
      result = tableDataRaw.current.map((item) => { return item.id === entity.id ? entity : item });
    }

    tableDataRaw.current = result;
    await getTableData(result);
  }

  useEffect(() => {    
    const actionAsync = async () => { 
      setLoadingData(true);
      await props.permissions.generalAccess();
      dispatch(setFleetHubReportSelected({ ...reduxSelected, selected: history.location.pathname.replace('fleetHub', 'fleet') }));
      const entities = await Axios.get(history.location.pathname.replace('fleetHub', 'fleet'));
      tableDataRaw.current = entities.data.result;
      await getTableData(entities.data.result);
      setLoadingData(false);
    }

    actionAsync();
  }, [entityUrlCurrent, history.location])

  return (
    <Fragment>
      <FleetMenu selectedButton={entityName} />
      {toastContainer(1000, 300)}
      <UILIB.Paper className='mar-l10 mar-r10'>
        <UILIB.Button className='mar-b10 mar-r15' value={entityAddText} onClick={() => drawCRUD({ type: 'add', entity: {}})} />
        <UILIB.Button className={css(entityApiUrl)} value='All' onClick={() => {
          setEntityUrlCurrent(entityApiUrl);
          history.push(entityUrl);
        }} />
        <UILIB.Button className={css(entityApiUrlRenewalUpcoming)} value='Upcoming Renewals' onClick={() => {
          setEntityUrlCurrent(entityApiUrlRenewalUpcoming);
          history.push(entityUrlRenewalUpcoming);
        }} />
         <UILIB.Button className={css(entityApiUrlRenewalOrder)} value='Renewals On Order' onClick={() => {
          setEntityUrlCurrent(entityApiUrlRenewalOrder);
          history.push(entityUrlRenewalOrder);
        }} />
        <UILIB.Button className='mar-l10 mar-b10 secondary' disabled={!tableDataRaw.current || !tableDataRaw.current.length}  value={exporting.name} onClick={() => exportData(exporting, setExporting)} />
        <UILIB.Table
          height='100%'
          overflowX='auto'
          overflowY='hidden'
          loading={loadingData}
          header={headerData}
          data={tableData}
          sortable={true}
          filter={true}
          resetFilters={false}
          goExport={false}
          maxRows={40}
          autoResize={false}
          exportEnable={true} />
      {showDocumentsModal.show && (
        <DocumentsModal 
          showDocumentsModal={showDocumentsModal}
          setShowDocumentsModal={setShowDocumentsModal}
          entity={showDocumentsModal.entity}
          entityTitle={showDocumentsModal.entity.registration}
          entityName={entityName}
        />)}
      </UILIB.Paper>
    </Fragment> 
  );
}