
import { Fragment } from 'react'
import BlockAddPermissionTable from '../tables/blockAddPermissionTable'
import UILIB from 'components';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';



export default function AddPermissionsModal(props) 
{

    const handleListItemClick = (event, newValue) => {
      props.setSelectedGroupIndex({ 
        entity: props.selectedGroupIndex.entity, 
        group: props.selectedGroupIndex.group,
        permission: newValue.id
      });
    }

    const hubSelectData = props.entities && props.entities.sort(function(a, b){
      if(a.hubName < b.hubName) { return -1; }
      if(a.hubName > b.hubName) { return 1; }
      return 0;
    }).map(hub => { return  { label: hub.hubName, id: hub.id } });
    hubSelectData.unshift({ label: "All Hubs", id: 0 });

    const selectedGroup = props.permissions.AdminGroup.find(f => f.id === props.selectedGroupIndex.group);
    
    return <Fragment>
      <div className="genericModalContainer">
        <div className="genericModalContent" style={{ width: "90%", height: "60%"}}>
          <UILIB.Paper className="row genericaModalChild" >
            <div className="mar-b10 col-xs-12 col-sm-12 col-md-12 flex-container"><h4 style={{ padding: 0, margin: 0 }}>
              Add new permissions rule for {selectedGroup.groupName}</h4>
            </div>
            <div className="mar-b15 col-xs-8 col-sm-8 col-md-8">
            <FormControl sx={{ m: 1, minWidth: '200px' }} variant="standard">
              <Autocomplete
                disablePortal
                size="small"
                onChange={(event, newValue) => handleListItemClick(event, newValue)}
                options={hubSelectData}
                sx={{ width: 300 }}
                defaultValue={{ label: "All Hubs", id: 0 }}
                renderInput={(params) => <TextField {...params} label="Select A Hub" />}
              />
            </FormControl>
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 flex-container right">
              <UILIB.Button
                className="red"
                value="Cancel"
                onClick={() => props.setShowPermissionsModal(false)}
              />
            </div>
          </UILIB.Paper>  
          <UILIB.Paper className="genericaModalChild" >
            <BlockAddPermissionTable
              entities={props.entities}
              permissions={props.permissions}
              selectedGroupIndex={props.selectedGroupIndex}
              permissionsTableData={props.permissionsTableData}
              setPermissionsTableData={props.setPermissionsTableData}
              showPermissionsModal={props.showPermissionsModal}
              setShowPermissionsModal={props.setShowPermissionsModal}
              loading={props.loading}
              groupRender={props.groupRender}
              setGroupRender={props.setGroupRender}
              setGroupExpandRow={props.setGroupExpandRow}
            />
          </UILIB.Paper>  
          
        </div> 
      </div> 
    </Fragment>
}