
import { Fragment } from 'react';

export default function OptionsContainer({ row }) {

  return <Fragment>
      <div className="flex-container column wrap height-100 width-100">
        <div title={row.salesPersonName} className={`flex-item`}>{row.salesPersonName}</div>
        <div title={row.salesRegionTxt} className={`flex-item`}>{row.salesRegionTxt}</div>
      </div>
    </Fragment>
}
