import { Fragment } from 'react';
import UILIB from 'components';


export default function ViewNotes({ entity, setAddNote }) 
{  

  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className={`flex-item flex-grow-1 end`}> 
        <UILIB.Button value={"Add Notes"} className="green" onClick={() => setAddNote(true)} />
      </div>
    </div>
    <div className={`flex-container row padding-4`}>
      {Boolean(!entity.notes || !entity.notes.length) && <div className="text-14 font-weight-bold">No notes available for this request.</div>}
      {Boolean(entity.notes && entity.notes.length) && <div className="width-100">
        <div className="text-14 font-weight-bold">Notes for Request: {entity.requestID}</div>
        {entity.notes.sort((a, b) => { return a.id - b.id }).map(note => {
          const createdBy = (note.createdByTxt) ? note.createdByTxt : '';
          const updatedAt = (note.updatedAt) ? note.updatedAt : note.createdAt;
          return <UILIB.NoteContainer type={'Notes'} createdBy={createdBy} updatedAt={updatedAt} message={note.notes} /> })}
      </div>}
    </div>
  </Fragment>
}
