import { useState, useEffect, Fragment } from "react"
import UILIB from 'components'


const headerData = [
  { label: "Address Type", value: "type", align: "left", type: 'string', width: "150px" },
  { label: "Address 1", value: "address1", align: "left", type: 'string', width: "450px" },
  { label: "Address 2", value: "address2", align: "left", type: 'string' },
  { label: "Address 3", value: "address3", align: "left", type: 'string' },
  { label: "Town", value: "town", align: "left", type: 'string' },
  { label: "County", value: "county", align: "left", type: 'string' },
  { label: "Country", value: "country", align: "left", type: 'string' },
  { label: "Postcode", value: "postcode", align: "left", type: 'string', width: "150px" },
  { label: "Telephone", value: "telephone", align: "left", type: 'string', width: "150px" },
  { label: "Select Address", value: "select", align: "center", type: 'string', width: "100px" },
  { label: "Delete", value: "delete", align: "center", width: "1%" }
]

const createAddressTypes = (formData) => {
  return [
    { label: 1, value: 'Invoice Address', disabled: false },
    { label: 2, value: 'Delivery Address', disabled: (formData.installs.length > 0) ? false : true },
    { label: 3, value: 'Collection Address', disabled: (formData.removals.length > 0) ? false : true }];
}


export default function AddressDataTable({ formData }) {

    const [tableData, setTableData] = useState([]);
    const [modalHandler, setModalHandler] = useState({ show: false, context: null });
    const emptyAddress =  headerData.map(x => x.value).reduce((accumulator,value) => (accumulator[value] = { value: '', raw: '', tdStyle: '' }), {});
    function renderTable() {

      const tableData = [];

      createAddressTypes(formData).filter(addr => !addr.disabled).forEach((addr, index) => {

        const slaStyle = {};
        const disable = (addr.label === 1);
        const trash = <div>{!disable && <span className="icon icon-trash primary" style={{ cursor: "pointer", paddingLeft: 10 }} onClick={() => { deleteRow(addr) }}></span>}</div>;
        const button = <div align='center'><UILIB.Button disabled={disable} key={index} name="addressSelect" value="Select" onClick={() => setModalHandler({ show: true, context: addr })} /></div>;
        const selected = formData.selected.find(f => Number(f.addressType) === Number(addr.label));
        const invoice = formData.addresses.find(dealAddress => Number(dealAddress.addressType) === Number(addr.label));
        const foundAddress = formData.addresses.find(dealAddress => selected && Number(dealAddress.id) === Number(selected.addressId));
        const loadInvoice = invoice && !formData.selected.some(x => Number(x.addressType) === Number(invoice.addressType));
        if(loadInvoice) formData.selected.push({ addressId: invoice.id, addressType: addr.label, installId: formData.id });
        const handler = invoice ? invoice : foundAddress;

        if(handler && formData.selected.some(x => Number(x.addressType) === Number(addr.label))) {
          const selectedInvoice = formData.selected.find(f => Number(f.addressType) === Number(addr.label));
          if(selectedInvoice && selectedInvoice.addressType === 1) slaStyle.backgroundColor = 'rgba(189, 195, 199, 0.5)';
          tableData.push({
            type: { value: addr.value, raw: addr.value, tdStyle: slaStyle },
            address1: { value: handler.address1, raw: handler.address1, tdStyle: slaStyle },
            address2: { value: handler.address2, raw: handler.address2, tdStyle: slaStyle },
            address3: { value: handler.address3, raw: handler.address3, tdStyle: slaStyle },
            town: { value: handler.town, raw: handler.town, tdStyle: slaStyle },
            county: { value: handler.county, raw: handler.county, tdStyle: slaStyle },
            country: { value: handler.country, raw: handler.country, tdStyle: slaStyle },
            postcode: { value: handler.postcode, raw: handler.postcode, tdStyle: slaStyle },
            telephone: { value: handler.telephone, raw: handler.telephone, tdStyle: slaStyle },
            select: { value: button, raw: '', tdStyle: slaStyle },
            delete: { value: trash, raw: '', tdStyle: slaStyle }
          })
        }
        else {
          const appendValue = { value: addr.value, raw: addr.value, tdStyle: {} };
          const appendSelect = { value: button, raw: '', tdStyle: {} };
          tableData.push({ ...emptyAddress, type: appendValue, select: appendSelect });
        }
      })
      setTableData(tableData);
    }

    const addAddress = (ev, handler) => {
      const index = formData.selected.findIndex(x => Number(x.addressType) === Number(handler.context.label));
      if(index > -1) formData.selected.splice(index, 1);
      formData.selected.push({ addressId: ev, addressType: modalHandler.context.label, installId: formData.id });
      setModalHandler({ show: false, context: null });
    }

    const deleteRow = (addr) => {
      const index = formData.selected.findIndex(x => Number(x.addressType) === Number(addr.label));
      formData.selected.splice(index, 1);
      renderTable();
    }
 
    useEffect(() => {
      renderTable();
    }, [formData, modalHandler]);
  

    return <Fragment>
      {modalHandler.show && <UILIB.SelectAddressModal 
        header={'Please select an Address'} 
        modalHandler={modalHandler}
        formData={formData}
        onConfirm={(ev) => addAddress(ev, modalHandler)}
        onCancel={() => setModalHandler({ show: false, context: null })} />}
      <UILIB.Table
        className="small"
        overflowX="auto"
        overflowY="hidden"
        header={headerData}
        data={tableData}
        sortable={false}
        filter={true}
        autoResize={false}
        defaultOrderBy=""
        defaultOrderDir="" />
     </Fragment>
}