import { useState, Fragment, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { createSelectInput } from 'classes/controls/formControls';


export default function BindProductDrawer(props) 
{    
    const [deviceData, setDeviceData] = useState([]);
    const defaultError = { error: false, message: '' };
    const [bannerError, setBannerError] = useState(defaultError);
    const [selected, setSelected] = useState({ value: -1 });
    const [saving, setSaving] = useState(false);
    const exceptions = [400,404];

    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';

    const updateSeleted = (event) => { 
      setSelected({ value: event.target.value });
    }

    useEffect(() => {
      const asyncEffect = async () => {
        const devices = props.ourDeal.product.filter(x => x.isADevice === 1).map(x => { return { value: x.prodID, dealID: x.dealID, label: x.deviceNo } });
        setDeviceData([{ value: -1, label: "Please Select" }, ...devices]);
      }
      asyncEffect();
    }, []);


    const actionBindToDevice = async () => {
      setSaving(true);
      setBannerError(defaultError);
      try { 
        const baseData = { sourceProduct: props.product, targetProduct: props.ourDeal.product.find(p => p.prodID === Number(selected.value)) };
        const result = await Axios.put(`/entities/workflow/deals/actionBindToDevice/${props.ourDeal.dealID}`, baseData); 
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          props.setOurDeal({ 
            ...props.ourDeal, 
            product: result.data.result.product, 
            compatibles: result.data.result.compatibles, 
            selected: result.data.result.selected.map(x => { return { ...x, value: x.deviceNumber, label: x.deviceNumber } }) });
          props.onFinish();
        }
      } catch (err) {
        console.log(err);
        setSaving(false);
        setBannerError({ error: true, message: exceptionError });
      }
      setSaving(false);
    }

    return <Fragment>
      <div className='flex-container row wrap mar-b10'>
        <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Bind to existing Device</div>
        <div className="flex-item row width-100">
          {createSelectInput('Select Device', 'quantity', (!props.isActive || saving), selected.value, deviceData, (ev) => updateSeleted(ev), null, 'flex-item end center align-center width-60', null, null, 'fullBorder border-colour-grey-4')}
        </div>
        <div className="flex-item row start width-100">
          <UILIB.Button 
            disabled={!props.isActive || selected.value === -1 || saving} 
            loading={saving}
            value="Bind to Device" 
            onClick={async () => await actionBindToDevice()} />
          <UILIB.Button 
            disabled={saving} 
            className="grey"
            value="Cancel" 
            onClick={() => props.onCancel()} />
          {bannerError.error && <div className="flex-item start wrap width-100 pad-r3">
            <div className="errored message">{bannerError.message}</div>
          </div>}
        </div>
      </div>
    </Fragment>
}
