import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';

export default function AdminNotes({ deal , isDisabled }) {

    const [adminNotes, setAdminNotes] = useState([]);
    const [newNotes, setNewNotes] = useState("");
    const [newNotesErrored, setNewNotesErrored] = useState("");
    const [addingNotes, setAddingNotes] = useState(false);
    const [loading, setLoading] = useState(true);

    const getNotes = () => {
        Axios.get(`/sales/deals/adminnotes/${deal.dealID}`).then(notes => {
            let tmp = notes.data.adminNotes;
            if (tmp && tmp.length) tmp = tmp.replace(/\n/g, "</br>");
            setLoading(false);
            setAdminNotes(tmp);
        })
    }

    const goAddNotes = () => {
        var err = "";
        if (!newNotes || newNotes.length < 3) {
            err = "Please enter longer notes";
        }
        setNewNotesErrored(err);
        if (err !== "") {
            return;
        }
        setAddingNotes(true);
        Axios.post('/sales/deals/adminnotes', { dealID: deal.dealID, notes: newNotes }).then(() => {
            setNewNotes("");
            setAddingNotes(false);
            getNotes();
        })
    }

    useEffect(() => {
        getNotes();
        // eslint-disable-next-line
    }, [])


    return <div className='flex-container row width-100'>
        <h2 className="flex-item row center width-100 mar-t20 mar-b20">Notes for deal {deal.finalDealNo}</h2>
        {loading && <UILIB.Loading type={3} />}
        <div className='flex-container row center width-100'>
          {!loading && <UILIB.TextArea className="flex-item row center" style={{ width: "320px", height: "740px", resize: 'none' }} value={adminNotes} placeholder="Enter notes here" errored={newNotesErrored} onChange={(ev) => {
                  setNewNotes(ev.target.value); setNewNotesErrored(""); }} disabled={addingNotes} />}
        </div>
        <div className="flex-item row width-100 height-100 mar-t20"><UILIB.Button value="Add Notes" onClick={goAddNotes} disabled={isDisabled} /></div>
    </div>
}
