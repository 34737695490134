import { useState, Fragment } from 'react';
import { useSelector } from "react-redux";
import { checkFormErrors } from '../../../../../classes/formErrors/formErrors';
import { getAddNewGroup } from '../../../../../classes/formErrors/repository';
import { getTonerTypes } from '../../functions';
import { createTextInput, createSelectInput } from 'classes/controls/genericControls';
import moment from "moment-business-time";
import UILIB from 'components';
import Axios from 'classes/axios';


export default function GroupDrawer({ entity, sync, onClose }) 
{  

  const emptyEntity = { groupName: '', locationName: '', useHvToner: '0', reasonForException: '' };
  const account = useSelector((state) => state.account);
  const [saving, setSaving] = useState(false);
  const [formData, setFormdata] = useState(emptyEntity);
  const [formErrors, setFormErrors] = useState({});
  const [bannerError, setBannerError] = useState({ error: false, message: '' });
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';

  const actionAddGroup = async () => {
    setSaving(true);
    const formResult = await checkFormErrors(getAddNewGroup(formData), setFormErrors, null, null, formData, null);
    if(formResult.errorCount === 0) {
      try {
        const result = await Axios.post(`/entities/serviceAdmin_siteStore/${entity.customerId}`, { entity: { ...formData, custID: entity.customerId } });
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          entity.groups.result.push({ 
            ...result.data.result[0], 
            totalDevices: 0, 
            createdByTxt: account.displayName, 
            createdAt: moment().format('YYYY-MM-DD HH:MM:ss') });
          sync.setDevice(!sync.device);
          sync.setLocal(!sync.local);
          onClose();
        }
      } catch (err) {
        console.log(err);
        setBannerError({ error: true, message: exceptionError });
      }
    }
    setSaving(false);
  };

  const actionUpdateField = async (event) => {
    setFormdata({ ...formData, [event.target.name]: event.target.value });
  };

  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className={`flex-item flex-grow-1 end`}> 
        <UILIB.Button disabled={saving} loading={saving} value={'Save Group'} className="green" onClick={async () => await actionAddGroup()} />
        <UILIB.Button disabled={saving} value={'Cancel'} className="red" onClick={() => onClose()} />
      </div>
      {bannerError.error && <div className="flex-container end">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
    <div className={`flex-container row width-100 padding-8`}>
      {createTextInput('Group Name', 'groupName', true, 100, formData, formErrors, (event) => actionUpdateField(event))}
      {createTextInput('Location', 'locationName', true, 100, formData, formErrors, (event) => actionUpdateField(event))}
      {createSelectInput('Toner Type', 'useHvToner', getTonerTypes(), true, formData, formErrors, (event) => actionUpdateField(event))}
      {createTextInput('Reason for exception', 'reasonForException', true, 100, formData, formErrors, (event) => actionUpdateField(event))}
    </div>
  </Fragment>
}
