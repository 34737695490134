import Button from '../Button/Button'
import { useState, Fragment } from 'react'
import UILIB from 'components'


export default function SelectAddressModal({ header, modalHandler, formData, confirmText = 'Select', cancelText = 'Cancel', onConfirm = () => { }, onCancel = () => { } }) 
{
    const currentlySelected = formData.selected.find(f => Number(f.addressType) === Number(modalHandler.context.label));
    const pleaseSelect = { label: 'Please Select', value: -1 };
    const [address, setAddress] = useState(currentlySelected ? currentlySelected.addressId : -1);

    const selectAddress = (event) => {
      setAddress(event.target.value);
    }
    
    const buildSelectData = (row) => {
      const { id, dealId, telephone, telNo, county, customerName, accountNumber, createdAt, updatedAt, addressType, DeviceInstallationId, ...addressObj } = row;
      return addressObj ? Object.values(addressObj).filter(f => f && f.length).map(val => { return val }).join(', ') : '';
    }

    return <Fragment>
      <div className="modalBackground">
        <div className="modalDealContent" style={{ width: "35%", minWidth: "300px" }}>
          {Boolean(header) && <div className="text-xlarge text-center flex-container center"><p>{header}</p></div>}
          <div className="mar-t5 mar-b15" style={{ display: "flex" , alignItems: "center", justifyContent: "center" }}>
            <UILIB.Select 
              outerstyle={{ width: "90%" }}
              style={{ padding: 2 }}
              data={[pleaseSelect, ...formData.addresses.map(addr => { return { label: buildSelectData(addr), value: addr.id } })]}
              value={address} 
              onChange={(ev) => selectAddress(ev)} 
            />
          </div>
          <div className="flex-container row">
            <div className="flex-item row center width-100">
              <Button 
                className="green mar-r5" 
                value={confirmText} 
                onClick={() => onConfirm(address)} 
                disabled={!Boolean(formData.addresses.length) || Number(address) === -1} 
              />
              <Button className="red mar-l5" value={cancelText} onClick={() => onCancel()} />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
}