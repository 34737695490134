
import { useEffect, useState, useRef, Fragment } from 'react';
import UILIB from 'components';


export default function TextInputContainer({ data, type = 'string', fieldName = '', placeholder = '', onChange }) {

  const inputClass = '';

  const firstLoad = useRef(true);
  const [event, setEvent] = useState(false);

  const generateInput = (fieldName, data, inputClass, defaultValue) => {
    return <UILIB.TextInput 
      type={type}
      className={inputClass}
      placeholder={defaultValue} 
      name={fieldName} 
      value={data[fieldName]} 
      onChange={(ev) => handleUserInput(ev, data)} />
  };

  const handleUserInput = (event, row) => {
    row[event.target.name] = event.target.value;
    setEvent({ value: event.target.value, field: event.target.name, row: row });
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false; 
    } else {
      const timer = setTimeout(async () => {
        if(typeof(onChange) === 'function' && typeof(event) === 'object') await onChange(event);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [event])

  return <Fragment>
          <div className="flex-container row flex width-100">
            <div className={`flex-item start container container-input `}>{generateInput(fieldName, data, inputClass, placeholder)}</div>
          </div>
        </Fragment>
}
