import { Fragment, useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';

export default function MigratePortalPermissions(props) {
    const [message, setMessage] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [currentTime, setCurrentTime] = useState('');
    const [startUserId, setStartUserId] = useState(0);

    const handleMigration = async () => {
      const start = new Date();
      setStartTime(start);
      setMessage('');
      try {
        await Axios.post(`/admin/portalUsers/portalUserbase`, { startUserId: startUserId });
        const end = new Date();
        const duration = Math.round((end - start) / 1000);
        setMessage(`Migration completed in ${formatDuration(duration)}.`);
      } catch (error) {
        setMessage(`Migration failed. Please try again. Error: ${error.value}`);
      }
      setStartTime(null);
    };

    useEffect(() => {
      let timer;
      if (startTime) {
        timer = setInterval(() => {
          const now = new Date();
          const duration = Math.round((now - startTime) / 1000);
          setCurrentTime(`Elapsed time: ${formatDuration(duration)}`);
        }, 1000);
      } else {
        setCurrentTime('');
        clearInterval(timer);
      }

      return () => clearInterval(timer);
    }, [startTime]);

    const formatDuration = (duration) => {
      const hours = Math.floor(duration / 3600);
      const minutes = Math.floor((duration % 3600) / 60);
      const seconds = duration % 60;

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handleKeyUp = (e) => {
      setStartUserId(e.target.value);
    };

    return (
      <Fragment>
        <div className="mar-l5 mar-r5">
          <div className="flex" style={{ alignItems: "center", padding: "10px" }}>
            <p className="mar-r10">Enter starting user ID: </p>
            <UILIB.TextInput
              style={{ textAlign: "left", width: "60px", height: "25px", padding: "0" }}
              onKeyUp={handleKeyUp}
              type="number"
              inputMode="numeric"
              min="0"
            />
          </div>
          <UILIB.Button
            className="mar-r10 mar-b10 small grey"
            value="Migrate Portal Permissions"
            disabled={!props.permissionsObject('workflowUpdateButton', props.localPermissions.permissions, props.permissions, props.location.pathname).entityEnable}
            onClick={handleMigration}
          />
          {message && <p>{message}</p>}
          {currentTime && <p>{currentTime}</p>}
        </div>
      </Fragment>
    );
}