import { useEffect, useState, Fragment } from 'react';
import UILIB from 'components';
import { updateDealData, calcActiveFieldMain, getProcurementDropdownData, updateField } from '../functions';
import { purchaseTypes } from 'classes/quotesHelpers';
import { getCompanyDetails, getCompanies } from 'classes/helpers';
import { addRow, addTextInputRow, addSelectInputRow } from './detailPageCommon';


export default function CoreDetails(props) 
{
  const [groupData, setGroupData] = useState(null);
  const doErrors = props.formErrors && Object.values(props.formErrors).length;
  const serviceContractToolTip = 'Is there a service included as part of this order';
  const companyPaymentTermsData = [
    { label: 'Please Select', value: '-1' },
    { label: 'Cash On Delivery', value: 'Cash On Delivery' },
    { label: 'Free of Charge', value: 'Free of Charge' },
    { label: 'Payment in Advance', value: 'Payment in Advance' },
    { label: '7 days', value: '7 days' },
    { label: '30 days', value: '30 days' },
    { label: '45 days', value: '45 days' },
    { label: '60 days', value: '60 days' } ];

    const yesNoData = [
      { label: 'Please Select', value: -1 },
      { label: 'Yes', value: 1 },
      { label: 'No', value: 0 } ];
    
    const yesNoExistingData = [
      { label: 'Please Select', value: -1 },
      { label: 'Yes', value: 1 } ,
      { label: 'No', value: 0 },
      { label: 'No - T&M', value: 3 },
      { label: 'Existing', value: 2 }];

    const procurementDropdownData = [
      { label: 'Please Select', value: '-1' },
      { label: 'Proc 4 - SP-21-046', value: 'Proc 4 - SP-21-046' },
      { label: 'Proc 3 - SP-16-013', value: 'Proc 3 - SP-16-013' },
      { label: 'Proc 2 - PS-12-008', value: 'Proc 2 - PS-12-008' },
      { label: 'Proc 1 - PS-08-003', value: 'Proc 1 - PS-08-003' },
    ]
      
  useEffect(() => {     
    const asyncEffect = async () => {
      if(props.ourDeal.largerGroupId) {
        const result = await getCompanyDetails(props.ourDeal.largerGroupId);
        setGroupData(result && result[0].altName);
      } else {
        setGroupData('');
      }
    }
    asyncEffect();
  }, [])

  const showGroup = Number(props.ourDeal.largerGroupSelect) === 1;

  const largerGroupSelectOnChange = async (event) => {
    await updateDealData({ event: event, type: 0, timeout: 500, props: props });
    if(Number(event.target.value) === -1 || Number(event.target.value) === 0) {
      await updateField(props.ourDeal, props.setOurDeal, 'largerGroupId', '');
    }
  };

  const organisationSelectOnChange = async (event) => {
    if(String(event.target.value) === '-1')
      await updateField(props.ourDeal, props.setOurDeal, 'organisation', '');
    else
      await updateDealData({ event: event, type: 0, timeout: 500, props: props });
  };

  const sectorSelectOnChange = async (event) => {
    if(String(event.target.value) === '-1') {
      await updateField(props.ourDeal, props.setOurDeal, 'sector', '');
    }
    else
      await updateDealData({ event: event, type: 0, timeout: 500, props: props });
  };

  const serviceAgreementSelectOnChange = async (event) => {
    let productDataNew;
    let type;

    if(Number(event.target.value) === 3 && props.ourDeal.product) {
      productDataNew = props.ourDeal.product;

      productDataNew.forEach(product => {
        product.cpcMono = product.cpcMono !== 0 ? 0 : product.cpcMono;
        product.cpcColour = product.cpcColour !== 0 ? 0 : product.cpcColour;
      });

      props.setCpcDisabled(true);
      props.setOurDeal({ ...props.ourDeal, product: productDataNew });
      props.setRefreshData(true);
      type = 3;
    }
    else {
      type = 0;
      props.setCpcDisabled(false);
    }

    await updateDealData({ event: event, type, timeout: 500, props: props });
  }

  const handleApplyCustomerSearch = async (data) => {
    await updateField(props.ourDeal, props.setOurDeal, 'largerGroupId', data.value);
  }

  const companyLookupRow = showGroup &&
    addRow('Group', doErrors && true, 
      <UILIB.AutoComplete 
        className="min-height-50 width-100"
        name='largerGroupId'
        blank='No Customers Found'
        defaultValue={(!showGroup) ? '' : groupData}
        query={getCompanies}
        enableButton={false}
        errored={props.formErrors.largerGroupId}
        disabled={!Boolean(props.isActive) || calcActiveFieldMain(props.activeFields, 'largerGroupId', 'largerGroupId')}
        onClick={(data) => handleApplyCustomerSearch(data)} />);

  if(groupData === null) return <Fragment>
    <UILIB.Paper className="flex-container height-100 width-100 center align-center around"></UILIB.Paper>
  </Fragment>  

  return <Fragment>  
      <UILIB.Paper className="flex-container column height-100">
        <table width='100%' cellPadding='0' cellSpacing='0' className='subTable'>
          <tbody>
            { addRow('Description', false, addTextInputRow('description', props, false, 400, 0, calcActiveFieldMain(props.activeFields, 'description', 'description'))) }
            { addRow('Part of a Trust/Group of Companies', doErrors && true, addSelectInputRow('largerGroupSelect', props, true, yesNoData, null, largerGroupSelectOnChange, 0, calcActiveFieldMain(props.activeFields, 'largerGroupSelect', 'largerGroupSelect'))) }
            { companyLookupRow }
            { addRow('PO Number', doErrors && true, addTextInputRow('dmcPONo', props, true, 30, 0, calcActiveFieldMain(props.activeFields, 'dmcPONo', 'dmcPONo'))) }
            { addRow('Purchase Type', doErrors && true, addSelectInputRow('leaseorcash', props, true, [{ label: 'Please Select', value: '-1' }, ...purchaseTypes.map(i => { return { label: i, value: i }})], null, null, 2, calcActiveFieldMain(props.activeFields, 'leaseorcash', 'leaseorcash'))) }
            { props.fieldWorkflowObj.companyPaymentTerms && addRow('Sales Invoice Terms', doErrors && true, addSelectInputRow('companyPaymentTerms', props, true, companyPaymentTermsData, null, null, 0, calcActiveFieldMain(props.activeFields, 'companyPaymentTerms', 'companyPaymentTerms'))) }
            { props.fieldWorkflowObj.serviceContract && addRow('Service Agreement', doErrors && true, addSelectInputRow('serviceContract', props, true, yesNoExistingData, serviceContractToolTip, serviceAgreementSelectOnChange, 3, calcActiveFieldMain(props.activeFields, 'serviceContract', 'serviceContract'))) }
            { addRow('Main Deal', false, addTextInputRow('mainDeal', props, false, 400, 0, calcActiveFieldMain(props.activeFields, 'mainDeal', 'mainDeal'))) }
            { props.fieldWorkflowObj.scottishProcurement && addRow('Procurement Level', doErrors && true, addSelectInputRow('procurementLevel', props, true, procurementDropdownData, null, null, 0, calcActiveFieldMain(props.activeFields, 'procurementLevel', 'procurementLevel'))) }
            { props.fieldWorkflowObj.scottishProcurement && addRow('Organisation', doErrors && true, addSelectInputRow('organisation', props, true, getProcurementDropdownData(30, props.ourDeal), null, organisationSelectOnChange, 0, calcActiveFieldMain(props.activeFields, 'organisation', 'organisation'))) }
            { props.fieldWorkflowObj.scottishProcurement && addRow('Sector', doErrors && true, addSelectInputRow('sector', props, true, getProcurementDropdownData(29, props.ourDeal), null, sectorSelectOnChange, 0, calcActiveFieldMain(props.activeFields, 'sector', 'sector'))) }
          </tbody>
        </table>
        <div className="flex-container column end flex-grow-1 height-100">
          <UILIB.BannerContainer classNameContent="text-12" background={'colour background-2 royalBlue'} title="Reminder" content={'Make sure the correct Purchase Type is selected.'} /> 
        </div>
    </UILIB.Paper>
  </Fragment>;
}