
import { useState } from 'react';
import UILIB from 'components';

export default function SwapQuoteDrawer({ otherQuotes, ourDeal, onFinished, dealID, isActive }) {

    const [selectedQuote, setSelectedQuote] = useState(null)
    let quoteData = otherQuotes.filter(q => q.dealID !== dealID).map(q => { return { label: q.dealID, value: q.dealID } });
    quoteData.unshift({ label: "Select Quote", value: '-1' })


    return <div className="row" style={{ width: "450px" }}>
        <h2>Swap to another quote</h2>
        <p className="mar-b15">Please select from the other available quotes for this opportunity.</p>
        <div className="mar-b15 mar-r10 flex-container">
            <UILIB.Select 
              style={{ minWidth: "200px", padding: 2 }}
              disabled={!isActive} 
              value={selectedQuote} 
              data={quoteData} 
              onChange={(event) => { setSelectedQuote(event.target.value) }} 
            />
            {<UILIB.Button 
              disabled={!isActive || selectedQuote === '-1' || selectedQuote === null} 
              value="Swap" onClick={() => { onFinished(selectedQuote) }} 
              className="mar-l10"
            />}
        </div>
    </div >
}
