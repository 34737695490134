import { Fragment } from 'react';
import Button from '../Button/Button'

export default function MessageBox({ header, text, subText = undefined, loading = false, showConfirm = true, showCancel = true, confirmText = 'Ok', cancelText = 'Cancel', onConfirm = () => { }, onCancel = () => { }, errored = null }) {

  const showError = (errored && typeof(errored) === 'object' && errored.error)

  return <Fragment>
    <div className="modalBackground">
      <div className="modalContent" style={{ minWidth: "360px", maxWidth: "420px" }}>
        {Boolean(header) && <div className="text-center text-xlarge mar-b5">{header}</div>}
        {Boolean(text) && <div className="text-center text-14 mar-b10">{text}</div>}
        {Boolean(subText) && <div className="text-center text-11"><i>{subText}</i></div>}
        <div className="flex-container row center width-100 mar-t10">
          {showConfirm && <Button loading={loading} disabled={loading} className="primary mar-5" value={confirmText} onClick={onConfirm} />}
          {showCancel && <Button disabled={loading} className="secondary mar-5" value={cancelText} onClick={onCancel} />}
        </div>
        {showError && <div className="flex-container row end flex-grow-1 width-100">
          <div className="errored message">{errored.message}</div>
        </div>}
      </div>
    </div>
  </Fragment>
}