import { Fragment } from 'react';
import UILIB from 'components';
import { useHistory } from "react-router-dom";
import { getCompanies } from 'classes/helpers';

export default function ConsumablesLookUpCustomer({ render, setRender }) {

  const history = useHistory();

  const handleAddSearch = (data) => {
    history.push(`/consumablesHub/consumablesLookup/customer/${data.Reference}`);
    setRender(!render);
  }

  return <Fragment>
    <UILIB.Paper className='flex-item grey flex-grow-1 wrap'>
      <div className='flex-item flex-grow-1 align-center start text-white'>Search for a Customer</div>
      <UILIB.AutoComplete
        className="min-height-50 max-width-400"
        name='customerId'
        blank='No Customers Found'
        query={getCompanies}
        enableButton={true}
        onClick={(data) => handleAddSearch(data)} />
    </UILIB.Paper>
  </Fragment>
}