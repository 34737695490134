import { Fragment } from 'react';
import UILIB from 'components';

export default function ViewAlerts({ entity }) 
{   

  const itemNote = (entity.itemNote) ? entity.itemNote.map(x => { return { ...x, type: 'Item Alert' } }) : [];
  const storeNote = (entity.storeNote) ? entity.storeNote.map(x => { return { ...x, type: 'Customer Alert' } }) : [];
  const stockHoldingNote = (entity.stockHoldingNote) ? entity.stockHoldingNote.map(x => { return { ...x, type: 'Equipment Alert' } }) : [];

  const alertTypes = Array.from(new Set([...itemNote.map(x => x.type), ...storeNote.map(x => x.type), ...stockHoldingNote.map(x => x.type)]));
  const alertData = [...itemNote.map(x => x), ...storeNote.map(x => x), ...stockHoldingNote.map(x => x)];

  return <Fragment>
      <div className="text-14 font-weight-bold">Alerts for {entity.customerName}</div>
      {alertTypes.map(alertType => {
        return alertData.filter(f => f.type === alertType).map(x => {
          return <UILIB.NoteContainer alert={x.IsAlert} type={alertType} createdBy={x.CreatedBy} updatedAt={x.ModifiedDate} message={x.Text} /> })
      })} 
  </Fragment>
}
