import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { pleaseSelect, createDateInput, createTextInput, createSelectInput, createPreFilledTextInput, createMultilineTextInput } from '../generic';
import { claimCleanData } from './claimCleanData';
import { claimEvaluatedRulesPassed } from './claimEvaluatedRules';
import { emptyEntity } from './claimEmptyEntity';

export default function ClaimCRUD(props) {
    //Specific to entity
    const entityUrl = `/fleet/claim`;
    const entityUrlWithId = `/fleet/claim/${props.data.entity.id}`;
    const addFromEntityWithKey = {...emptyEntity(), emailReg: props.data.entity.emailReg };
    const headerText = ' Claim - ';
    const yes = 'Yes';
    const no = 'No';
    const yesNo = [pleaseSelect,'Yes','No'];
    const proportionOfVehicleUseByDrivers = [pleaseSelect,'Business use only','Social, domestic pleasure, business use'];
    const claimStatuses = [pleaseSelect,'Closed (Non-Fault)', 'Closed (Fault)', 'Closed (50/50)', 'Open (Non-Fault)', 'Open (Fault)', 'Open (50/50)'];
    const [emailRegs, setEmailRegs] = useState();

    const loadEmailRegs = async () => {
        const emailRegsData = await Axios.get(`/fleet/emailRegs`);
        const emailRegsDataFormatted = emailRegsData.data.result.map(function(item){ return item.emailReg });
        setEmailRegs([pleaseSelect, ...emailRegsDataFormatted]);
    }

    const loadSpecificData = async () => {
        loadEmailRegs();
    }

    const cleanData = () => {
        setEntity(claimCleanData(entity));
    }

    const evaluatedRulesPassed = (formErrorsTemp) => {
        const rulesResult = claimEvaluatedRulesPassed(entity, formErrorsTemp);
        return rulesResult.errors;
	}

    const htmlButtons = (loading) => {
        if (loading) {
            return <div />
        } else {
            return <div>
                <UILIB.Button loading={saving} className='mar-t10' value='Action' onClick={submitForm} />
            </div>
        }
    }

    const thirdPartyHtmlRows = () => {
        return <span>
            {createTextInput('Third Party: Name', 'thirdPartyName', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Third Party: Address', 'thirdPartyAddress', isActive, 100, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Third Party: Telephone Number', 'thirdPartyTelephoneNumber', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Third Party: Vehicle Make', 'thirdPartyVehicleMake', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Third Party: Vehicle Model', 'thirdPartyVehicleModel', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Third Party: Vehicle Registration', 'thirdPartyVehicleRegistration', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Third Party: Insurers (if known)', 'thirdPartyInsurers', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Third Party: Reference No.', 'thirdPartyReferenceNumber', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Third Party: Speed', 'thirdPartySpeed', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Third Party: Vehicle Damage', 'thirdPartyVehicleDamage', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('How many occupants were in the vehicle? (Including the driver)', 'thirdPartyNumberOccupantsInVehicle', isActive, 999, entity, entityErrors, changeInput)}
            <h2 className='mar-t15 mar-b15'>Additional Third Party Details</h2>
            {createSelectInput('Any further third parties involved?', 'thirdPartyAdditionalInvolved', yesNo, isActive, entity, entityErrors, changeInput)}
            {entity.thirdPartyAdditionalInvolved === yes && thirdPartyAdditionalHtmlRows()}
        </span>
    }

    const thirdPartyAdditionalHtmlRows = () => {
        return <span>
            {createTextInput('Additional: Name', 'thirdPartyAdditionalName', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Additional: Address', 'thirdPartyAdditionalAddress', isActive, 100, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Additional: Telephone Number', 'thirdPartyAdditionalTelephoneNumber', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Additional: Vehicle Make', 'thirdPartyAdditionalVehicleMake', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Additional: Vehicle Model', 'thirdPartyAdditionalVehicleModel', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Additional: Vehicle Registration', 'thirdPartyAdditionalVehicleRegistration', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Additional: Insurers', 'thirdPartyAdditionalInsurers', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Additional: Reference Number', 'thirdPartyAdditionalReferenceNumber', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Additional: Speed', 'thirdPartyAdditionalSpeed', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Additional: Vehicle Damage', 'thirdPartyAdditionalVehicleDamage', isActive, 999, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Additional: Number Occupants In Vehicle', 'thirdPartyAdditionalNumberOccupantsInVehicle', isActive, 999, entity, entityErrors, changeInput)}
        </span>
    }

    const furtherInjuredPartiesInvolvedHtmlRows = () => {
        return <span>
            {createTextInput('Injured Persons: Name', 'furtherInjuredName', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Injured Persons: Address', 'furtherInjuredAddress', isActive, 100, entity, entityErrors, changeInput)}
            {createMultilineTextInput('Details of injuries', 'furtherInjuredInjuryDetails', isActive, 999, entity, entityErrors, changeInput)}
            {createSelectInput('Did an ambulance attend the scene', 'furtherInjuredAmbulance', yesNo, isActive, entity, entityErrors, changeInput)}
            {entity.furtherInjuredAmbulance === yes &&
                createMultilineTextInput('If Yes, enter details here', 'furtherInjuredAmbulanceDetails', isActive, 999, entity, entityErrors, changeInput)}
        </span>
    }

    const passenger1HtmlRows = () => {
        return <span>
            {createTextInput(`Passenger 1: Name`, 'passenger1Name', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Passenger 1: Address', 'passenger1Address', isActive, 100, entity, entityErrors, changeInput)}
            {createSelectInput('Were there any further passengers?', 'wasTherePassenger2', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.wasTherePassenger2 === yes && passenger2HtmlRows()}
         </span>
    }

    const passenger2HtmlRows = () => {
        return <span>
            {createTextInput(`Passenger 2: Name`, 'passenger2Name', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Passenger 2: Address', 'passenger2Address', isActive, 100, entity, entityErrors, changeInput)}
            {createSelectInput('Were there any further passengers?', 'wasTherePassenger3', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.wasTherePassenger3 === yes && passenger3HtmlRows()}
         </span>
    }

    const passenger3HtmlRows = () => {
        return <span>
            {createTextInput(`Passenger 3: Name`, 'passenger3Name', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Passenger 3: Address', 'passenger3Address', isActive, 100, entity, entityErrors, changeInput)}
         </span>
    }

    const witness1HtmlRows = () => {
        return <span>
            {createTextInput(`Witness 1: Name`, 'witness1Name', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Witness 1: Address', 'witness1Address', isActive, 100, entity, entityErrors, changeInput)}
            {createSelectInput('Were there any further witnesses?', 'wasThereWitness2', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.wasThereWitness2 === yes && witness2HtmlRows()}
         </span>
    }

    const witness2HtmlRows = () => {
        return <span>
            {createTextInput(`Witness 2: Name`, 'witness2Name', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Witness 2: Address', 'witness2Address', isActive, 100, entity, entityErrors, changeInput)}
            {createSelectInput('Were there any further witnesses?', 'wasThereWitness3', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.wasThereWitness3 === yes && witness3HtmlRows()}
         </span>
    }

    const witness3HtmlRows = () => {
        return <span>
            {createTextInput(`Witness 3: Name`, 'witness3Name', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Witness 3: Address', 'witness3Address', isActive, 100, entity, entityErrors, changeInput)}
         </span>
    }

    const police1HtmlRows = () => {
        return <span>
            {createTextInput('Police officers name', 'policeOfficerName', isActive, 50, entity, entityErrors, changeInput)}
            {createTextInput('Police officers number', 'policeOfficerNumber', isActive, 20, entity, entityErrors, changeInput)}
            {createTextInput('Police officers station address', 'policeOfficerStationAddress', isActive, 100, entity, entityErrors, changeInput)}
         </span>
    }

    const htmlRows = (loading) => { 
        if (loading) {
            return <div />
        } else {
            return <div className='mar-t15'>
                {createTextInput('First name', 'firstName', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Last name', 'lastName', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Driver email', 'workEmail', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Region', 'region', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Office', 'office', false, 40, entity, entityErrors, changeInput)}
                {createSelectInput('Email registration', 'emailReg', emailRegs, isActiveOnEdit, entity, entityErrors, changeInput)}
                {createTextInput('Association from', 'associationFrom', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Association to', 'associationTo', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Association type', 'associationType', false, 40, entity, entityErrors, changeInput)}
                <h2 className='mar-t15 mar-b15'>Claim Details</h2>
                {createTextInput('Policyholder', 'policyholder', false, 100, entity, entityErrors, changeInput)}
                {createPreFilledTextInput('Policy certificate number', 'policyCertificateNumber', false, 50, 'EGFLE6960303', entity, entityErrors, changeInput)}
                {createPreFilledTextInput('Sector', 'sector', false, 50, 'Workplace technologies', entity, entityErrors, changeInput)}
                {createPreFilledTextInput('Business office number', 'businessOfficeNumber', false, 50, '03300580505', entity, entityErrors, changeInput)}
                {createPreFilledTextInput('Is the insured VAT registered?', 'insuredVatRegistered', false, 50, 'Yes', entity, entityErrors, changeInput)}
                {createPreFilledTextInput('Is VAT recoverable on this vehicle?', 'vatRecoverableOnVehicle', false, 50, 'Yes', entity, entityErrors, changeInput)}
                {createPreFilledTextInput('Address line 1', 'policyholderAddressLine1', false, 100, 'Cando House', entity, entityErrors, changeInput)}
                {createPreFilledTextInput('Address line 2', 'policyholderAddressLine2', false, 100, 'Bournemouth Rd', entity, entityErrors, changeInput)}
                {createPreFilledTextInput('Town', 'policyholderAddressTown', false, 100, `Chandler's Ford`, entity, entityErrors, changeInput)}
                {createPreFilledTextInput('County', 'policyholderAddressCounty', false, 100, 'Southampton', entity, entityErrors, changeInput)}
                {createPreFilledTextInput('Postcode', 'policyholderAddressPostcode', false, 10, 'SO53 3QB', entity, entityErrors, changeInput)}
                {createTextInput('Mobile number', 'mobileNumber', false, 10, entity, entityErrors, changeInput)}
                {createTextInput('Date of birth', 'dateOfBirth', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Job role', 'jobRole', false, 50, entity, entityErrors, changeInput)}
                {createTextInput('Licence number', 'licenceNumber', false, 50, entity, entityErrors, changeInput)}
                {createDateInput('Date of first full licence?', 'dateOfFirstFullLicence', false, entity, entityErrors, changeInput)}
                {createSelectInput('Proportion of vehicle use by driver?', 'proportionOfVehicleUseByDriver', proportionOfVehicleUseByDrivers, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('If not the policyholder, did the driver have the policyholder’s permission to drive?', 'ifNotPolicyholderDidDriverHavePermissionToDrive', yesNo, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Has driver ever been involved in any accident or loss during the past three years?', 'hasDriverBeenInvolvedInAccidentOrLossPastThreeYears', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYears === yes &&
                    createMultilineTextInput('If Yes, enter details here', 'hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails', isActive, 999, entity, entityErrors, changeInput)}
                {createSelectInput('Has driver ever been prosecuted or incurred a fixed penalty for an endorsable offence in connection with a motor vehicle?', 'hasDriverBeenProsecutedOrFixedPenalty', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.hasDriverBeenProsecutedOrFixedPenalty === yes &&
                    createMultilineTextInput('If Yes, enter details here', 'hasDriverBeenProsecutedOrFixedPenaltyDetails', isActive, 999, entity, entityErrors, changeInput)}
                {createSelectInput('Has driver ever been declined or refused renewal for vehicle insurance?', 'hasDriverBeenDeclinedOrRefusedRenewal', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.hasDriverBeenDeclinedOrRefusedRenewal === yes &&
                    createMultilineTextInput('If Yes, enter details here', 'hasDriverBeenDeclinedOrRefusedRenewalDetails', isActive, 999, entity, entityErrors, changeInput)}
                {createSelectInput('Does the driver suffer from any conditions that affect their sight or hearing?', 'driverSufferAnyConditionsAffectSightOrHearing', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.driverSufferAnyConditionsAffectSightOrHearing === yes &&
                    createMultilineTextInput('If Yes, enter details here', 'driverSufferAnyConditionsAffectSightOrHearingDetails', isActive, 999, entity, entityErrors, changeInput)}
                <h2 className='mar-t15 mar-b15'>Vehicle Details</h2>
                {createTextInput('Make', 'make', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Model', 'model', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Registration', 'registration', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('First registration date', 'registrationDate', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Leasing company', 'leaseCompany', false, 20, entity, entityErrors, changeInput)}
                <h2 className='mar-t15 mar-b15'>Accident Details</h2>
                {createMultilineTextInput('Description of damage', 'descriptionOfDamage', isActive, 999, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Purpose for which vehicle was being used', 'purposeVehicleBeingUsed', isActive, 999, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Number of persons being carried (Including the driver)', 'numberPersonsCarried', isActive, 999, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Nature of goods carried (if any)', 'natureGoodsCarried', isActive, 999, entity, entityErrors, changeInput)}
                {createDateInput('Date of accident', 'dateOfAccident', isActive, entity, entityErrors, changeInput)}
                {createTextInput('Time of accident', 'timeOfAccident', isActive, 5, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Place (quote road names where applicable)', 'placeOfAccident', isActive, 999, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Road conditions', 'roadConditions', isActive, 999, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Weather conditions', 'weatherConditions', isActive, 999, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Estimated speed', 'estimatedSpeed', isActive, 999, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Lights used (if any)', 'lightsUsed', isActive, 999, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Position in road', 'positionInRoad', isActive, 999, entity, entityErrors, changeInput)}
                {createMultilineTextInput('Who was responsible for the accident?', 'whoWasResponsibleForAccident', isActive, 999, entity, entityErrors, changeInput)}
                <h2 className='mar-t15 mar-b15'>Third Party Details</h2>
                {createSelectInput('Any third parties involved?', 'thirdPartyInvolved', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.thirdPartyInvolved === yes && thirdPartyHtmlRows()}
                <h2 className='mar-t15 mar-b15'>Injured Persons</h2>
                {createSelectInput('Any further injured parties involved?', 'furtherInjuredPartiesInvolved', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.furtherInjuredPartiesInvolved === yes && furtherInjuredPartiesInvolvedHtmlRows()}
                <h2 className='mar-t15 mar-b15'>Passengers</h2>
                {createSelectInput('Was there a passenger?', 'wasTherePassenger1', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.wasTherePassenger1 === yes && passenger1HtmlRows()}
                <h2 className='mar-t15 mar-b15'>Witnesses</h2>
                {createSelectInput('Were there any witnesses?', 'wasThereWitness1', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.wasThereWitness1 === yes && witness1HtmlRows()}
                <h2 className='mar-t15 mar-b15'>Police</h2>
                {createSelectInput('Were there police on site?', 'wasTherePoliceOnSite', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.wasTherePoliceOnSite === yes && police1HtmlRows()}
                <h2 className='mar-t15 mar-b15'>State</h2>
                {createSelectInput('Claim Status', 'claimStatus', claimStatuses, isActive, entity, entityErrors, changeInput)}
                {createTextInput('Created At', 'createdAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Created By', 'createdBy', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Updated At', 'updatedAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated By', 'updatedBy', false, 40, entity, entityErrors, changeInput)}
            </div>
        }
    }

    //Generic
    const addType = 'add';
    const addFromType = 'addFrom';
    const editType = 'edit';
    const deleteType = 'delete';
    const defaultPageMessage = 'Please complete the below and then click Action.';
    const deleteConfirmationMessage = 'Please confirm deletion of the below details by clicking Action.';
    const failValidationMessage = 'Please correct any errors and click Action again.';
    const rowVersionMessage = 'A newer version of this record has already been saved. Please reload the data.';
    const missingRecordMessage = 'Unable to find record in db. Please reload the data.';
    const invalidSubmitMessage = 'Invalid submit request, please advise support.';
    const saveErrorMessage = 'Unable to save, please advise support.';
    const [loadingData, setLoadingData] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [isActiveOnEdit, setIsActiveOnEdit] = useState(true);
    const [entity, setEntity] = useState(emptyEntity());
    const [entityErrors, setEntityErrors] = useState(emptyEntity());
    const [errored, setErrored] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState(defaultPageMessage);

    useEffect(() => {
        const loadEntity = async () => {
            if (props.data.type === addType) {
                setEntity(emptyEntity());
            } else if (props.data.type === addFromType) {
                const entity = addFromEntityWithKey;
                setEntity(entity);
            } else {        
                const entityData = await Axios.get(entityUrlWithId);
                setEntity(entityData.data.result);
            }

            setLoadingData(false);
        }

        if (props.data.type === deleteType) {
            setIsActive(false);
            setIsActiveOnEdit(false);
            setMessage(deleteConfirmationMessage)
        }

        if (props.data.type === editType || props.data.type === addFromType) {
            setIsActiveOnEdit(false);
        }

        loadSpecificData();
        loadEntity();
    }, [])

    const changeInput = (event) => {
        let newFormData = JSON.parse(JSON.stringify(entity));
        newFormData[event.target.name] = event.target.value;
        setEntity(newFormData);

        if (event.target.name in entityErrors) {
			let newFormErrors = { ...entityErrors };
			newFormErrors[event.target.name] = '';
			setEntityErrors(newFormErrors);
		}
    }

    const validSubmit = () => {
        cleanData();
        let formErrorsTemp = emptyEntity();        
        if (!evaluatedRulesPassed(formErrorsTemp)) {
            return true;
        }

        setEntityErrors(formErrorsTemp);
        setMessage(failValidationMessage);
        return false;
    }

    const processDelete = async () => {
        if (props.data.type === deleteType) {
            setSaving(true);
            await Axios.delete(entityUrlWithId, entity);
            setDeleted(true);
            props.sync({ deleted: true, id: props.data.entity.id });
            setSaving(false);

            return true;
        }

        return false;
    }

    const processAdd = async () => {
        if (props.data.type === addType || props.data.type === addFromType) {
            setSaving(true);
            const entityResult = await Axios.post(entityUrl, entity);
            props.data.type = editType;
            props.data.entity.id = entityResult.data.result.id;
            setEntity(entityResult.data.result);
            props.sync(entityResult.data.result);
            setIsActiveOnEdit(false);
            setSaving(false);

            return true;
        }
        
        return false;
    }

    const processEdit = async () => {
        if (props.data.type === editType) {
            setSaving(true);
            const entityResult = await Axios.put(entityUrlWithId, entity);

            if (entityResult.status === 200) {
                setEntity(entityResult.data.result);
                props.sync(entityResult.data.result);
            }

            if (entityResult.status === 409) {
                setMessage(rowVersionMessage);
                setErrored(true);
            }

            if (entityResult.status === 400) {
                setMessage(missingRecordMessage);
                setErrored(true);
            }
            
            setSaving(false);
            return true;		
        }

        return false;
    }

    const submitForm = async () => {
        try {
            if (await processDelete()) { return; }
            if (validSubmit()) {
                if (await processAdd()) { return; }
                if (await processEdit()) { return; }

                setMessage(invalidSubmitMessage);
                setErrored(true);
            }
        }
        catch (err) {
            console.log(err);
            setMessage(saveErrorMessage);
            setErrored(true);
        }
    }

    if (deleted) return <UILIB.Paper className='width-100'><p className="center">Deleted</p></UILIB.Paper>

    if (errored) return <UILIB.Paper className='width-100'><p className="center">{message}</p></UILIB.Paper>

    if (loadingData) return <UILIB.Loading type={3} />

    return (
        <div className="mar-l15 mar-r15">
            <h2 className='mar-b10'>{(props.data.type === addType || props.data.type === addFromType) ? 'Add' : 'Edit'}{headerText}{entity.id}</h2>
            <p>{message}</p>
            {htmlButtons(loadingData)}
            {htmlRows(loadingData)}
        </div> 
    )
}