import { useRef, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { consumableChip } from '../../consumablesHub/functions';


export default function RequestSummary({ entityLookup, setEntityLookup, setShowSummary, data, history }) {

  const headerData = [
    { label: 'Toner ID', value: 'tonerID', type: '', filtering: false, minWidth: 20 },
    { label: 'Serial Number', value: 'serialNumber', type: 'string', filtering: false, minWidth: 250, maxWidth: 200 },
    { label: 'Order Date', value: 'tonerDate', type: 'datetime', filtering: false, minWidth: 140, maxWidth: 140 },
    { label: 'Requested', value: 'tonerTypeCast', type: 'string', filtering: false, minWidth: 100, maxWidth: 100 }];

  const entityData = useRef({});

  const link = <UILIB.Link onClick={() => handleLinkClicked()} className="mar-l5">Click Here</UILIB.Link>;
  const headerText = `Consumables added sucessfully for Customer: ${entityLookup.customerName}.`;

  const handleLinkClicked = () => {
    setEntityLookup({});
    setShowSummary({ show: false, data: [] });
    history.push('/requestConsumablesHub');
  }
    
  function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

      return {
        tonerID: { value: row.tonerID, raw: row.tonerID },
        serialNumber: { value: row.serialNumber, raw: row.serialNumber },
        tonerDate: { value: row.tonerDate, raw: row.tonerDate },
        tonerTypeCast: { value: consumableChip(row.tonerTypeCast), raw: row.tonerTypeCast }
      }
    })

    return tableData;
  }


  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    entityData.current.summary = await Axios.get(`/entities/tonerOrders/getSummary/request/?&$filter=tonerID in ${data.join(',')} ${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);

    if(!entityData.current.summary || !entityData.current.summary.result || !entityData.current.summary.result.length) return false;

    return { tableData: await constructTable(entityData.current.summary.result), raw: entityData.current.summary.result, nonePaged: entityData.current.summary.nonePaged };
  }


  return <Fragment>
    <div className='flex-container row flex-grow-1 width-100 mar-b10'>
      <p className="flex-item width-100 text-14">{headerText}</p>
      <p className="flex-item width-100 text-14 mar-b20">To raise a new order, {link}</p>
      <b className="flex-item row  width-100 start text-12 pad-b2 mar-b2">Summary of records created</b>
      <div className="pad-5 width-100">
        <UILIB.TableNew
          className='small'
          overflowX='auto'
          overflowY='hidden'    
          header={headerData}
          remoteQuery={getTableData}
          remoteRender={[entityLookup]} />
      </div>
    </div>
  </Fragment>
}