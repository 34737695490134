import { Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';

export default function Specials(props) {

    return <Fragment>
      <div className="mar-l5 mar-r5">
        <UILIB.Button
            className="mar-r10 mar-b10 small grey"
            value="Add User Roles"
            disabled={!props.permissionsObject('workflowUpdateButton', props.localPermissions.permissions, props.permissions, props.location.pathname).entityEnable}
            onClick={async () => await Axios.put(`/admin/userRoles/addUserRoles`)} />
      </div>
    </Fragment>;
}