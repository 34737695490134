import { Fragment } from 'react';

export default function Checkbox({ className, name, checked, onChange = () => { }, disabled = false }) {
    return <Fragment>
       <input
        name={name}
        className={`checkbox ${className} `}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled} />
      </Fragment>
}