import { useState, Fragment, useEffect } from 'react';
import UILIB from 'components';
import AddInvoiceAddressDrawer from '../drawer/addInvoiceAddressDrawer';
import DeleteInvoiceAddressDrawer from '../drawer/deleteInvoiceAddressDrawer';
import { setDrawer } from "store/actions";
import { useDispatch } from 'react-redux';


export default function Addresses(props) {
    const headerData = [
      { label: "Account Number", value: "accountNumber", align: "left", type: 'string' },
      { label: "Customer Name", value: "customerName",  align: "left", type: 'string' },
      { label: "Address 1", value: "address1", align: "left", type: 'string' },
      { label: "Address 2", value: "address2", align: "left", type: 'string' },
      { label: "Address 3", value: "address3", align: "left", type: 'string' },
      { label: "Town", value: "town", align: "left", type: 'string' },
      { label: "County", value: "county",align: "left", type: 'string' },
      { label: "Postcode", value: "postcode", align: "left", type: 'string' },
      { label: "Contact Name", value: "contactName", align: "left", type: 'string' },    
      { label: "Tel No", value: "telNo", align: "left", type: 'string' },    
      { label: "Email", value: "email", align: "left", type: 'string' },
      { label: "", value: "options", align: "center", type: 'string' }
    ];

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);

    const addAddress = () => {
        dispatch(setDrawer({ open: true, width: '500px', content: <AddInvoiceAddressDrawer 
            onFinished={() => dispatch(setDrawer({ open: false, content: null }))} 
            ourDeal={props.ourDeal} 
            isActive={props.isActive} 
            setRefreshData={props.setRefreshData}
            refreshData={props.refreshData}
            setOurDeal={props.setOurDeal}
            editAddress={false} /> 
        }));
    };

    const editAddress = (existingAddress) => {
        dispatch(setDrawer({ open: true, width: '500px', content: <AddInvoiceAddressDrawer 
              onFinished={() => dispatch(setDrawer({ open: false, content: null }))} 
              ourDeal={props.ourDeal} 
              address={existingAddress} 
              isActive={props.isActive} 
              setRefreshData={props.setRefreshData}
              refreshData={props.refreshData}
              setOurDeal={props.setOurDeal}
              editAddress={true} /> 
          }));
    };

    const deleteAddress = (address) => {
        dispatch(setDrawer({ open: true, content: <DeleteInvoiceAddressDrawer 
              onFinished={() => dispatch(setDrawer({ open: false, content: null }))} 
              ourDeal={props.ourDeal} 
              address={address} 
              isActive={props.isActive} 
              setRefreshData={props.setRefreshData}
              refreshData={props.refreshData}
              setOurDeal={props.setOurDeal}
            /> 
          }));
    };

    useEffect(() => {
        let tableDataTmp = props.ourDeal.invoiceAddress.map(address => {
            let options = null;
            if(props.isActive) {
                options = props.isActive &&
                    <span>
                        <span className="icon icon-pencil" style={{ cursor: "pointer" }} title="Edit" onClick={() => { editAddress(address) }} />
                        <span>&nbsp;&nbsp;</span>
                        <span className="icon icon-trash2" style={{ cursor: "pointer" }} title="Delete" onClick={() => { deleteAddress(address) }} /> 
                    </span>
            };

            return {
                fromCRM: { value: address.fromCrm ? "Yes" : "No", raw: address.fromCrm, },
                accountNumber: { value: address.accountNumber, raw: address.accountNumber, },
                customerName: { value: address.customerName, raw: address.customerName, },
                address1: { value: address.address1, raw: address.address1, },
                address2: { value: address.address2, raw: address.address2, },
                address3: { value: address.address3, raw: address.address3, },
                town: { value: address.town, raw: address.town, },
                county: { value: address.county, raw: address.county, },
                postcode: { value: address.postcode, raw: address.postcode, },
                contactName: { value: address.contactName, raw: address.contactName, },
                telNo: { value: address.telNo, raw: address.telNo, },
                email: { value: address.email, raw: address.email, },
                options: { value: options, raw: 0 }
            };
        });

        setTableData(tableDataTmp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.ourDeal, props.isActive]);

    return <Fragment>
        <div>
          <h2 className="text-16 mar-t5 mar-l10 float-left">Invoice Address</h2>
          <UILIB.Button 
            className="small mar-b15 text-right float-right"
            disabled={!props.isActive || (!props?.dataLoaded?.invoiceAddress || !props?.dataLoaded) || props?.ourDeal?.invoiceAddress.some(x => Number(x.addressType) === 1)} 
            value="Add" 
            name="addAddress"
            onClick={addAddress}
            errored={props.formErrors && props.formErrors.crmAddress} />
        </div>
        <UILIB.Table 
            className="small" 
            overflowX="auto"
            overflowY="hidden"
            height={props.height} 
            loading={false} 
            header={headerData} 
            data={tableData} 
            sortable={false} 
            filter={false} 
            maxRows={props.maxRows} 
            defaultOrderBy="" 
            defaultOrderDir="" 
            showRecords={false}/>
    </Fragment>
}