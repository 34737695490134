import axios from "classes/axios"
import { cleanCompanyName } from "classes/helpers"
import UILIB from "components"
import { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'

function getPostcode(address) {
    const split = address.trim().split(' ')
    return split[split.length - 1]
}

export default function LogisticsCalendar({ filter }) {
    const [movements, setMovements] = useState([])
    const [installs, setInstalls] = useState([])
    const [loading, setLoading] = useState(false)
    const [date, setDate] = useState(null)
    const [manualDate, setManualDate] = useState(null)
    const [company, setCompany] = useState(null)
    const [dealID, setDealID] = useState('')
    const [alertMessage, setAlertMessage] = useState('')
    const [alertOpen, setAlertOpen] = useState(false)
    const history = useHistory()

    const getData = async () => {
        if (!date) return
        setLoading(true)
        const results = await Promise.all([
            axios.get(`/devicemovement?startDate=${date.startOf('month').format('YYYY-MM-DD 00:00:00')}&endDate=${date.endOf('month').format('YYYY-MM-DD 23:59:59')}`),
            axios.get(`/installs?startDate=${date.startOf('month').format('YYYY-MM-DD 00:00:00')}&endDate=${date.endOf('month').format('YYYY-MM-DD 23:59:59')}`)
        ])
        setMovements(results[0].data)
        setInstalls(results[1].data)
        setLoading(false)
    }

    const companySearch = async () => {
        const results = await Promise.all([
            axios.get(`/devicemovement?companyId=${company.value}`),
            axios.get(`/installs?companyId=${company.value}`)
        ])
        let date
        if (results[1].data.length) {
            date = moment(results[1].data[0].installDate)
        } else if (results[0].data.length) {
            date = moment(results[0].data[0].moveDate)
        } else {
            setAlertMessage('No Events Found')
            setAlertOpen(true)
        }
        if (date) {
            setManualDate(date)
            setMovements(results[0].data)
            setInstalls(results[1].data)

        }
    }

    const dealSearch = async () => {
        if (!dealID) return getData()
        const res = await axios.get(`/installs?dealID=${dealID}`)
        if (res.data.length) {
            setManualDate(moment(res.data[0].installDate))
        } else {
            setAlertMessage('No Events Found')
            setAlertOpen(true)
        }
    }

    const onKeyPress = e => {
        if (e.key === 'Enter') {
            dealSearch()
        }
    }

    const today = () => {
        setDate(moment())
        setManualDate(moment())
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [date])

    useEffect(() => {
        if (company) {
            companySearch()
        }
        // eslint-disable-next-line
    }, [company])

    const events = movements.map(m => ({
        classes: 'event red',
        id: 'm-' + m.id,
        date: m.moveDate,
        title: <div style={{ width: '100%' }}>
            <div className="text-white text-center">{cleanCompanyName(m.companyName || 'UNKNOWN')}</div><div className="text-white text-center">{getPostcode(m.delAddress)}</div></div>,
        onClick: () => history.push(`/logisticsHub/deviceMovement/existingDeviceMovement/${m.deviceMovementId}`)
    })).concat(installs.map(i => ({
        classes: 'event green',
        id: 'i-' + i.finalDealNo || i.dealID,
        date: i.installDate,
        title: <div style={{ width: '100%' }}>
            <div className="text-white text-center">{i.finalDealNo || i.dealID} - {cleanCompanyName(i.companyName)}</div><div className="text-white text-center">{i.companyDeliveryPostCode}</div>
        </div>,
        onClick: () => window.open(`https://360.agilico.co.uk/serviceadmin/submitadeal/deal2.asp?dealID=${i.dealID}&referrer=`, '_blank')
    })))

    return (
        <div className="col-xs-12">
            <UILIB.Alert open={alertOpen} message={alertMessage} onClose={() => setAlertOpen(false)} />
            <UILIB.Paper style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', left: 10, top: 5, width: 600 }}>
                    <UILIB.Button onClick={today} value="Today" />
                </div>
                <div style={{ position: 'absolute', right: 10, top: 5, width: 600 }}>
                    <div className="row">
                        <div className="col-xs-6">
                            <UILIB.TextInput className="textInput input" value={dealID} onChange={e => setDealID(e.target.value)} onKeyPress={onKeyPress} placeholder="Search for Deal Id" button={<span className="icon-magnifier text-white"></span>} onClick={dealSearch} />
                        </div>
                        <div className="col-xs-6">
                            <UILIB.CompanyLookup onChange={setCompany} placeholder="Type to Start Searching for Company" />
                        </div>
                    </div>
                </div>
                <div className="mar-t10">
                    <UILIB.Calendar events={events} loading={loading} onChangeDate={setDate} date={manualDate} />
                </div>
            </UILIB.Paper>
        </div>
    )
}