import { Fragment } from 'react';

export default function MonoReadings({ row })
{
    return <Fragment>
        <div className="flex-container row width-100">
            {row.meters.filter(x => x.meterType === 1).map((x,i) => { return <div key={`mr_${i}`} className={`flex-item start width-100 refurbHub mono-readings-row`}>
              {`${x.bType}: ${x.bReading}`}
            </div>})}
        </div>
    </Fragment>
}