import { useState } from "react"
import { useHistory } from 'react-router-dom'
import Axios from "classes/axios"
import UILIB from 'components'


async function saveParts(items) {
  try {
    await Axios.post(`/sales/parts/type/updateOrder`, items[0].items);
  }
  catch (e) {
    console.log(e)
  }
}


async function saveRanges(ranges, deletedRanges) {
  try {
    await Axios.post(`/sales/parts/range/updateRange`, { updated: ranges, deleted: deletedRanges })
  }
  catch (e) {
    console.log(e)
  }
}

export default function ImportParts() {

    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([{items: []}])
    const [ranges, setRanges] = useState([])
    const [deletedRanges, setDeletedRanges] = useState([])

    const saveData = async (items, ranges) => {
        setLoading(true);
        await saveParts(items)
        await saveRanges(ranges, deletedRanges)
        setLoading(false); 
    }


    return ( 
        <div className="flex-container row end height-100 mar-t10 mar-l5 mar-r5">
            <div className="col-xs-6">
                <UILIB.Button className="white outline mar-b15"
                    iconBefore="icon-arrow-left" 
                    value="Back"
                    onClick={() => { history.push('/salesHub/partsManagement') }}
                /> 
            </div>
            {!loading &&<div className="col-xs-6 flex-container end">
                <UILIB.Button className="mar-b15"
                    value="Update and Save"
                    disabled={ !items[0].items.length || !ranges.length }
                    onClick={() =>  { saveData(items, ranges) }}
                />
            </div>}
            {loading && <div className="col-xs-6 flex-container end">
                    <UILIB.Button className="mar-b15"
                        value={<UILIB.Loading type={2} />}
                    />
            </div>}
            <div className="col-xs-12 col-sm-12 col-md-4 mar-b20">
                <UILIB.Paper style={{ height: "100%" }} >
                <h3>Parts Order Configuration</h3>
                    <UILIB.PartsAdminParts
                        items={items} 
                        setItems={setItems} 
                    />
                </UILIB.Paper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-8 mar-b20">
                <UILIB.Paper style={{ height: "100%" }} >
                <h3>Range Configuration</h3>
                    <UILIB.PartsAdminRanges
                      ranges={ranges}
                      setRanges={setRanges}
                      deletedRanges={deletedRanges}
                      setDeletedRanges={setDeletedRanges}
                    />
                </UILIB.Paper>
            </div>
        </div>
    )
}