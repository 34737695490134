import UILIB from 'components';
import { uploadFile } from '../../../shared/functions';


export default function UploadControl(props) {
    return (
      <div className='flex-container start'>
        <UILIB.Button value='Driver Upload' onClick={async () => await uploadFile({...props, name: 'Driver', jobRoute: '/fleet/getUploadJob/importDrivers', uploadRoute: '/fleet/drivers/importDrivers'})} className='mar-r10' />
        <UILIB.Button value='Vehicle Upload' onClick={async () => await uploadFile({...props, name: 'Vehicle', jobRoute: '/fleet/getUploadJob/importVehicles', uploadRoute: '/fleet/vehicles/importVehicles'})} className='mar-r10' />
        <UILIB.Button value='Ownership Upload' onClick={async () => await uploadFile({...props, name: 'Ownership', jobRoute: '/fleet/getUploadJob/importOwnerships', uploadRoute: '/fleet/ownerships/importOwnerships'})} className='mar-r10' />
      </div>
    );  
}