import { useState, useEffect, Fragment } from "react"
import UILIB from 'components'


const headerData = [
  { label: "Cyan", value: "cyan", align: "center", type: 'number', width: "10%" },
  { label: "Magenta", value: "magenta", align: "center", type: 'number', width: "10%" },
  { label: "Yellow", value: "yellow", align: "center", type: 'string', width: "10%", colour: "red" },
  { label: "Black", value: "black", align: "center", type: 'string', width: "10%" },
  { label: "Toner code", value: "tonerCode", align: "center",  type: 'string', width: "12%" },
  { label: "Waste Box", value: "wasteBox", align: "center", type: 'string', width: "10%" },
  { label: "Staples", value: "staples", align: "center", width: "10%" },
  { label: "Delete", value: "delete", align: "center", width: "1%" }
]


const colStyle = {
  cyan: { backgroundColor: "rgb(0,255,255,0.3)"  },
  magenta: { backgroundColor: "rgb(255,0,255,0.3)"  },
  yellow: { backgroundColor: "rgb(255,255,0,0.3)"  },
  black: { backgroundColor: "rgb(0,0,0,0.3)"  }
};


export default function ExistingDeviceMovementTable({ consumablesTable }) {

  const [tableData, setTableData] = useState([]);

  function renderTable() {

      if(!consumablesTable) return;
      
      let mapTableData = consumablesTable && consumablesTable.map((row, index) => {
        return {
          cyan: { value: row.cyan, raw: row.cyan, tdStyle: colStyle.cyan },
          magenta: { value: row.magenta, raw: row.cyan, tdStyle: colStyle.magenta },
          yellow: { value: row.yellow, raw: row.cyan, tdStyle: colStyle.yellow },
          black: {  value: row.black, raw: row.cyan, tdStyle: colStyle.black },
          tonerCode: { value: row.tonerCode },
          wasteBox: { value: row.wasteBox },
          staples: { value: row.staples },
          delete: { value: <div><span className="icon icon-trash primary" style={{ cursor: "pointer", paddingLeft: 10  }} onClick={() => { deleteRow(index) }}></span></div> }
        }
      });
      setTableData(mapTableData);
    }


    useEffect(() => {
        renderTable();
    }, [consumablesTable]);
    

    const deleteRow = (index) => {
        consumablesTable.splice(index, 1);
        renderTable();
    }


    return <Fragment>
      <UILIB.Table
        className="small"
        overflowX="auto"
        overflowY="hidden"
        header={headerData}
        data={tableData} />
    </Fragment>

}