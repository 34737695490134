import UILIB from 'components';

export const pleaseSelectYesNo = [
  { value: -1, label: 'Please Select' }, 
  { value: 0, label: 'No' },
  { value: 1, label: 'Yes' }
]

export const yesNoBool = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' }
]

export const yesNo = [
  { value: 0, label: 'No' },
  { value: 1, label: 'Yes' }
]

export const createCheckBox = (title, name, disabled = false, checked = false, onChange = () => { }, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <div className={controlClass} ><UILIB.Checkbox name={name} disabled={disabled} checked={checked} onChange={(ev) => onChange(ev)} /></div>
    </div>
  </div>
}

export const createToggle = (title, name, disabled = false, checked = false, onChange = () => { }, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <div className={controlClass} ><UILIB.Toggle name={name} disabled={disabled} checked={checked} onChange={(ev) => onChange(ev)} /></div>
    </div>
  </div>
}

export const createDateInput = (title, name, disabled, data = {}, onChange = () => { }, formErrors = {}, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.TextInput className={`textInput input ${controlClass}`} type='date' disabled={disabled} name={name} value={data} onChange={(ev) => onChange(ev)} errored={(formErrors) && formErrors[name]} />
    </div>
  </div>
}

export const createDateTimeInput = (title, name, disabled, data = {}, onChange = () => { }, formErrors = {}, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.TextInput className={`textInput input ${controlClass}`} type='datetime-local' disabled={disabled} name={name} value={data} onChange={(ev) => onChange(ev)} errored={(formErrors) && formErrors[name]} />
    </div>
  </div>
}

export const createTextInput = (title, name, disabled, data = {}, onChange = () => { }, formErrors = {}, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.TextInput className={`textInput colour background-2 lightGrey ${controlClass}`} disabled={disabled} name={name} value={data} onChange={(ev) => onChange(ev)} errored={(formErrors) && formErrors[name]} />
    </div>
  </div>
}

export const createNumberInput = (title, name, disabled, data = {}, onChange = () => { }, formErrors = {}, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.NumberInput className={`textInput colour background-2 lightGrey ${controlClass}`} disabled={disabled} name={name} value={data} onChange={(ev) => onChange(ev)} errored={(formErrors) && formErrors[name]} />
    </div>
  </div>
}

export const createAutoComplete = (title, name, disabled, blank, query = null, onClick = () => { }, enableButton = false, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.AutoComplete className={controlClass} disabled={disabled} name={name} blank={blank} query={query} enableButton={enableButton} onClick={(ev) => onClick(ev)} />
    </div>
  </div>
}

export const createSelectInput = (title, name, disabled, selected = null, data = [], onChange = () => { }, formErrors = {}, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container flex-grow-1 column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.Select className={`textInput colour background-2 lightGrey ${controlClass} `} disabled={disabled} name={name} value={selected} data={data} onChange={(ev) => onChange(ev)} errored={(formErrors) && formErrors[name]} />
    </div>
  </div>
}

export const createMultiSelectInput = (title, name, disabled, selected = [], data = [], placeholder = 'Please Select', onChange = () => { }, formErrors = {}, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.MultiSelect className={`textInput colour background-2 lightGrey ${controlClass} `} placeholder={placeholder} disabled={disabled} name={name} selected={selected} data={data} onChange={(ev) => onChange(ev)} errored={(formErrors) && formErrors[name]} />
    </div>
  </div>
}

export const createTextArea = (title, name, disabled, data = {}, onChange = () => { }, formErrors = {}, outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.TextArea className={`flex-item flex-grow-1 overflow-auto resize-none width-100 fullBorder border-colour-grey-4 ${controlClass}`} disabled={disabled} name={name} value={data} onChange={(ev) => onChange(ev)} errored={(formErrors) && formErrors[name]} />
    </div>
  </div>
}

export const createButton = (title, name, value, disabled, loading = false, onClick = () => { }, formErrors = null, outerClass = '', innerClass = '', titleClass = '', controlClass = '', toolTip = '') => {
  return <div className={outerClass}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.Button name={name} value={value} loading={loading} disabled={disabled} title={toolTip} className={`button formControl small width-100 pad-l10 pad-r10 ${controlClass}`} onClick={(ev) => onClick(ev)} />
      {formErrors && formErrors.error && <div className="flex-container end width-100">
        <div className="errored message">{formErrors.message}</div>
      </div>}
    </div>
  </div>
}

export const createUpload = (title, name, value, disabled, loading = false, onChange = () => { }, formErrors = null, outerClass = '', innerClass = '', titleClass = '', controlClass = '', toolTip = '') => {
  return <div className={outerClass}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b2 ${titleClass}`}>{title}</b>
      <UILIB.UploadFile name={name} value={value} loading={loading} disabled={disabled} title={toolTip} className={`button formControl small width-100 pad-l5 pad-r5 ${controlClass}`} onChange={(ev) => onChange(ev)} />
      {formErrors && formErrors.error && <div className="flex-container end width-100">
        <div className="errored message">{formErrors.message}</div>
      </div>}
    </div>
  </div>
}

export const createStaticField = (title, data = '', outerClass = '', innerClass = '', titleClass = '', controlClass = '') => {
  return <div className={`flex-item flex-grow-1 ${outerClass}`}>
    <div className={`flex-container column width-100 mar-5 ${innerClass}`}>
      <b className={`flex-item flex-grow-1 text-12 max-height-20-px pad-b1 ${titleClass}`}>{title}</b>
      <div className={`flex-item flex-grow-1 overflow-auto resize-none width-100 text-14 ${controlClass}`}>{data}</div>
    </div>
  </div>
}