import UILIB from 'components';


export default function ViewMfiles({ ourDeal }) {

  const Url = `https://mfiles.dmcplc.co.uk/Default.aspx?Applet=false#${ourDeal?.links?.mFilesVault}/views/_tempsearch?limit=50
                &0_qba=${ourDeal.dynamicsOpportunityNumber}&resultsFromEachRepository=true&includeUnmanaged=true`;
 
  return <UILIB.Button 
    className='secondary' 
    onClick={() => window.open(Url)} 
    value="View all in MFiles" />
}