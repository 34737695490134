import { useState, useEffect } from 'react';
import { setDrawer } from "store/actions";
import { useDispatch } from 'react-redux';
import { equipmentReturnMethods, equipmentOwnerships } from "classes/quotesHelpers"
import UILIB from 'components';
import Axios from "classes/axios"
import RemoveDevicesAdditionalDrawer from '../drawer/removeEquipmentAdditionalDrawer';
import RemoveDevicesExistingDrawer from '../drawer/removeEquipmentExistingDrawer';
import DeleteEquipmentRemovalDrawer from '../drawer/deleteEquipmentRemovalDrawer';
import EditEquipmentRemovalDrawer from '../drawer/editEquipmentRemovalDrawer';
import FillColumnDrawer from '../drawer/FillColumnDrawer';


const headerData = [
  { label: "Model Description", value: "model", align: "left", type: 'string' },
  { label: "Serial Number", value: "serial", align: "left", type: 'string' },
  { label: "Current Location Address", value: "currentLocationAddress", align: "left", type: 'string' },
  { label: "Location Onsite", value: "locationOnsite", align: "left", type: 'string' },
  { label: "Equipment Ownership", value: "equipmentOwnership", align: "center", type: 'string' },
  { label: "Equipment Owner Details", value: "equipmentOwnerDetails", align: "center", type: 'string' },
  { label: "Return Method", value: "returnMethod", align: "center", type: 'string' },
  { label: "Option", value: "options", align: "center", type: 'string', width: '50px' }
]

export default function RemoveDevices(props) {

    const [tableData, setTableData] = useState([]);
    const [pageSelect, setPageSelect] = useState(0);
    const [localEvent, setLocalEvent] = useState({});
    const dispatch = useDispatch()


    const fillColumn = () => {
        dispatch(setDrawer({ open: true, content: <FillColumnDrawer onFinished={fillColumnAdded} ourDeal={props.ourDeal} setOurDeal={props.setOurDeal} isActive={props.isActive} /> }));
    }
    const fillColumnAdded = async () => {
        dispatch(setDrawer({ open: false, content: null })); 
    }

    const addAdditional = () => {
        dispatch(setDrawer({ 
          open: true, 
          content: <RemoveDevicesAdditionalDrawer 
            onFinished={additionalAdded} 
            ourDeal={props.ourDeal} 
            setOurDeal={props.setOurDeal} 
            isActive={props.isActive}
            formErrors={props.formErrors} 
            foundErrors={props.foundErrors}
            equipmentErrors={props.equipmentErrors} 
           /> 
        }));
    }
    const additionalAdded = async () => {
        dispatch(setDrawer({ open: false, content: null }));
    }

    const addExisting = () => {
        dispatch(setDrawer({ open: true, width: "1100px", content: <RemoveDevicesExistingDrawer onFinished={existingAdded} tableData={tableData} ourDeal={props.ourDeal} setOurDeal={props.setOurDeal} isActive={props.isActive} /> }));
    }
    const existingAdded = async () => {
        dispatch(setDrawer({ open: false, content: null }));
    }

    const changeInput = async (fieldName, event, index, timeout) => {
        let ourDealNew = JSON.parse(JSON.stringify(props.ourDeal))
        ourDealNew.removal[index][fieldName] = event.target.value;
        props.setOurDeal(ourDealNew);
        setLocalEvent({ equipment: ourDealNew.removal[index], timeout: timeout });    
    }

    useEffect(() => { 
      const timer = setTimeout(async () => { 
        if(localEvent.equipment) {
          await Axios.post(`/sales/deals/${props.ourDeal.dealID}/equipmentremoval/editDevice`, localEvent.equipment);
        }
      }, localEvent.timeout);
      return () => clearTimeout(timer);
    }, [localEvent])

    const editEquipment = (equipmentRemoval) => {
      dispatch(setDrawer({ 
        open: true, 
        content: <EditEquipmentRemovalDrawer 
          onFinished={additionalAdded} 
          ourDeal={props.ourDeal} 
          setOurDeal={props.setOurDeal} 
          isActive={props.isActive}
          equipmentRemoval={equipmentRemoval} 
        /> 
      }));
    }

    const deleteEquipment = (equipmentRemovalId) => {
        dispatch(setDrawer({ 
          open: true, 
          content: <DeleteEquipmentRemovalDrawer 
            onFinished={additionalAdded} 
            ourDeal={props.ourDeal} 
            setOurDeal={props.setOurDeal} 
            isActive={props.isActive}
            equipmentRemovalId={equipmentRemovalId} 
          /> 
        }));
    }

    useEffect(() => {
        let tableDataTmp = props.ourDeal.removal.map((equipmentRemoval,index) => {

            const foundAddress = props.ourDeal.removalStore.find(x => Number(x.AddressId) === Number(equipmentRemoval.currentLocationAddress));

            return {
                model: { value: equipmentRemoval.model, raw: equipmentRemoval.model },
                serial: { value: equipmentRemoval.serial, raw: equipmentRemoval.serial },
                currentLocationAddress: { value: (foundAddress?.AddressString) ? foundAddress.AddressString : '', raw: (foundAddress?.AddressString) ? foundAddress.AddressString : '' },
                locationOnsite: { value: equipmentRemoval.locationOnsite, raw: equipmentRemoval.locationOnsite },
                equipmentOwnership: {
                    value: <div>
                      <UILIB.Select 
                        disabled={!props.isActive}
                        style={{ padding: "0" }} 
                        data={equipmentOwnerships} 
                        value={equipmentRemoval.equipmentOwnership} 
                        onChange={(event) => changeInput("equipmentOwnership",event,index, 500)} 
                      /> 
                    </div>,
                    raw: equipmentRemoval.equipmentOwnership,
                },
                equipmentOwnerDetails: {
                    value: <div className="text-small width-100">
                      <UILIB.TextInput
                        className="textInput input width-100" 
                        disabled={!props.isActive}
                        type="text" 
                        value={equipmentRemoval.equipmentOwnerDetails} 
                        onChange={(event) => { changeInput("equipmentOwnerDetails",event,index, 2000) }} 
                        />
                      </div>,
                    raw: equipmentRemoval.equipmentOwnerDetails,
                },
                returnMethod: {
                    value: <div>
                        <UILIB.Select 
                          disabled={!props.isActive}
                          style={{ padding: "0" }} 
                          data={equipmentReturnMethods} 
                          value={equipmentRemoval.returnMethod} 
                          onChange={(event) => changeInput("returnMethod",event,index, 500)} 
                        /> 
                      </div>,
                    raw: equipmentRemoval.returnMethod,
                },
                options: {
                    value: props.isActive ? <div> 
                        <div className="con icon-pencil" style={{ cursor: "pointer", float: "left" }} onClick={() => { editEquipment(equipmentRemoval) }} />
                        <div className="icon icon-trash2" style={{ cursor: "pointer" }} onClick={() => { deleteEquipment(equipmentRemoval.removalID) }} />
                    </div> : <div></div>,
                    raw: 0
                }
            }
        })
        setTableData(tableDataTmp);
    }, [props.ourDeal, props.isActive]);

    return <div>
        <div className="mar-b10 float-right">
            <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.removal} className="mar-r10" value="Add Additional" onClick={addAdditional} />
            <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.removal} className="mar-r10" value="Add from Existing Kit" onClick={addExisting} />
            <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.removal} value="Fill" onClick={fillColumn} />
        </div>
        <p className="clear-both mar-l10 mar-b10">Add here all devices that will be collected or disposed of to ensure the service system is kept up to date</p>
        <div>
            <UILIB.Table 
              className="small"
              overflowX="auto"
              overflowY="hidden"
              loading={false} 
              header={headerData} 
              data={tableData} 
              sortable={false} 
              filter={false} 
              maxRows={10} 
              autoResize={true}
              pageSelect={pageSelect}
              setPageSelect={setPageSelect}
              defaultOrderBy="" 
              defaultOrderDir="" 
            />
        </div>
    </div >
}