export default function StandaloneDrawer({ isOpen = false, showClose = true, onClose = () => { }, children, width = "400px" }) {
    const style = { width }
    if (!isOpen) {
        style.right = typeof width === 'number' ? '-' + width + 'px' : '-' + width
    }
    return <div className={isOpen ? "overlay" : ""}>
            <div className={"drawer"} style={style}>
                {showClose && <div className="drawerClose icon-cross" onClick={onClose}></div>}
                {children}
            </div>
          </div>
}
