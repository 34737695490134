import { Fragment } from 'react';
import UILIB from 'components';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setFleetHubReportSelected } from 'store/actions'
import TopStats from '../entities/topStats/blockTopStats';


export default function FleetMenu(props) {

    const reduxSelected = useSelector((state) => state.fleetReportSelected);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSelectedReport = async (target) => {
      dispatch(setFleetHubReportSelected({ ...reduxSelected , selected: null }));
      history.push(target);
    }

    const css = (item) => {
      return (props.selectedButton === item) ? 'mar-r5 mar-l5 mar-t5 mar-b5 text-bold' : 'mar-r5 mar-l5 mar-t5 mar-b5';
    }

    return (
      <Fragment>
        <TopStats />
        <UILIB.Paper className='mar-b10 mar-l10 mar-r10 row'>
            <UILIB.Button value='Driver Details' onClick={() => { handleSelectedReport('/fleetHub/drivers') }} className={css('Driver')} />
            <UILIB.Button value='Driver Checks' onClick={() => { handleSelectedReport('/fleetHub/driverChecks/all') }} className={css('DriverCheck')} />
            &nbsp;|&nbsp;
            <UILIB.Button value='Vehicle Details' onClick={() => { handleSelectedReport('/fleetHub/vehicles/all') }} className={css('Vehicle')} />
            <UILIB.Button value='MOTs' onClick={() => { handleSelectedReport('/fleetHub/mots/all') }} className={css('Mot')} />
            <UILIB.Button value='Services' onClick={() => { handleSelectedReport('/fleetHub/services/all') }} className={css('Service')} />
            <UILIB.Button value='Quarterly Checks' onClick={() => { handleSelectedReport('/fleetHub/quarterlyChecks/all') }} className={css('QuarterlyCheck')} />
            &nbsp;|&nbsp;
            <UILIB.Button value='Ownerships' onClick={() => { handleSelectedReport('/fleetHub/ownerships/all') }} className={css('Ownership')} />
            <UILIB.Button value='Claims' onClick={() => { handleSelectedReport('/fleetHub/claims') }} className={css('Claim')} />
            &nbsp;|&nbsp;
            <UILIB.Button value='Documents' onClick={() => { handleSelectedReport('/fleetHub/documents/viewDocuments') }} className={css('Documents')} />
            <UILIB.Button value='Upload' onClick={() => { handleSelectedReport('/fleetHub/uploadManagement') }} className={css('Upload')} />
        </UILIB.Paper>
      </Fragment>
    );  
}