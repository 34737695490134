import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'classes/axios';
import { store } from 'store/store'
import { setDrawer } from 'store/actions'
import { appStrings } from 'classes/quotesHelpers';
import { downloadFileHandler } from 'classes/download';
import DeleteProductDrawer from '../shared/drawer/deleteProductDrawer';


export function fieldWorkflow(ourDeal, props) {

  if(!ourDeal) return;

  let fieldWorkflowObjNew = JSON.parse(JSON.stringify(props.fieldWorkflowObj));
  Object.entries(fieldWorkflowObjNew).forEach(([key, value]) => fieldWorkflowObjNew[key] = false);

  let selected = ourDeal.leaseorcash;

  switch (selected) {
    case appStrings.Lease:
      fieldWorkflowObjNew.leaseCompanyInfo = true;
      fieldWorkflowObjNew.leaseRate = true;
      fieldWorkflowObjNew.rvIncluded = true;
      fieldWorkflowObjNew.companyPaymentLength = true;
      fieldWorkflowObjNew.leasePeriodPayment = true;
      fieldWorkflowObjNew.dealCurrency = true;
      fieldWorkflowObjNew.serviceContract = true;
      break;
    case appStrings.InternalRentalCPC:
      fieldWorkflowObjNew.leaseCompanyInfo = true;
      fieldWorkflowObjNew.leaseRate = true;
      fieldWorkflowObjNew.rvIncluded = true;
      fieldWorkflowObjNew.companyPaymentLength = true;
      fieldWorkflowObjNew.leasePeriodPayment = true;
      fieldWorkflowObjNew.dealCurrency = true;
      fieldWorkflowObjNew.serviceContract = true;
      fieldWorkflowObjNew.rebatePercent = true;
      fieldWorkflowObjNew.isMember = true;
      fieldWorkflowObjNew.cpcLocation = true;
      fieldWorkflowObjNew.cpcLot = true;
      break;
    case appStrings.ScottishProcurement: 
      fieldWorkflowObjNew.scottishProcurement = true;
      fieldWorkflowObjNew.procurementLevel = true;
      fieldWorkflowObjNew.sector = true;
      fieldWorkflowObjNew.organisation = true;
      fieldWorkflowObjNew.miniCompOrDirectBuy = true;
      break;
    case appStrings.ScottishProcurementLease:
      fieldWorkflowObjNew.leaseCompanyInfo = true;
      fieldWorkflowObjNew.leaseRate = true;
      fieldWorkflowObjNew.rvIncluded = true;
      fieldWorkflowObjNew.companyPaymentLength = true;
      fieldWorkflowObjNew.leasePeriodPayment = true;
      fieldWorkflowObjNew.dealCurrency = true;
      fieldWorkflowObjNew.serviceContract = true;
      fieldWorkflowObjNew.scottishProcurement = true;
      fieldWorkflowObjNew.scottishProcurementLease = true;
      fieldWorkflowObjNew.procurementLevel = true;
      fieldWorkflowObjNew.sector = true;
      fieldWorkflowObjNew.organisation = true;
      fieldWorkflowObjNew.miniCompOrDirectBuy = true;
      break;
    case appStrings.Cash:
      fieldWorkflowObjNew.companyPaymentTerms = true;
      fieldWorkflowObjNew.hidePanelRight = true;
      fieldWorkflowObjNew.serviceContract = true;
      break;
    case appStrings.CashCPC:
      fieldWorkflowObjNew.companyPaymentTerms = true;
      fieldWorkflowObjNew.serviceContract = true;
      fieldWorkflowObjNew.rebatePercent = true;
      fieldWorkflowObjNew.isMember = true;
      fieldWorkflowObjNew.cpcLocation = true;
      fieldWorkflowObjNew.serviceTerm = true;
      fieldWorkflowObjNew.cpcLot = true;
      break;
    case appStrings.ShortTermInternalRental:
      fieldWorkflowObjNew.companyPaymentLength = true;
      fieldWorkflowObjNew.dealCurrency = true;
      fieldWorkflowObjNew.serviceContract = true;
      break;
    case appStrings.Copycare:
      fieldWorkflowObjNew.companyPaymentLength = true;
      fieldWorkflowObjNew.dealCurrency = true;
      break;
    case appStrings.BackToBackCPC:
      fieldWorkflowObjNew.leaseRate = true;
      fieldWorkflowObjNew.leasePeriodPayment = true;
      fieldWorkflowObjNew.rvIncluded = true;
      fieldWorkflowObjNew.companyPaymentLength = true;
      fieldWorkflowObjNew.rebateValue = true;
      fieldWorkflowObjNew.rebatePercent = true;
      fieldWorkflowObjNew.isMember = true;
      fieldWorkflowObjNew.cpcLocation = true;
      fieldWorkflowObjNew.cpcLot = true;
      break;
    case appStrings.BackToBackEROY:
      fieldWorkflowObjNew.leaseRate = true;
      fieldWorkflowObjNew.leasePeriodPayment = true;
      fieldWorkflowObjNew.rvIncluded = true;
      fieldWorkflowObjNew.companyPaymentLength = true;
      fieldWorkflowObjNew.rebateValue = true;
      break;
    case appStrings.Subscription:
      fieldWorkflowObjNew.companyPaymentLength = true;
      fieldWorkflowObjNew.dealCurrency = true;
      fieldWorkflowObjNew.serviceContract = true;
      break;
    case appStrings.FOC:
    case appStrings.POC:
    case appStrings.GPOnly:
    case '-1':
      fieldWorkflowObjNew.hidePanelRight = true;
      break;
  default:
      break;
  }

  if (ourDeal.rvIncluded === "No" || ourDeal.rvIncluded === "-1" || !fieldWorkflowObjNew.rvIncluded) {
    fieldWorkflowObjNew.rvPercent = false;
  } else {
    fieldWorkflowObjNew.rvPercent = true;
  }

  if (Number(ourDeal.largerGroupSelect) === -1 || Number(ourDeal.largerGroupSelect) === 0) {
    fieldWorkflowObjNew.largerGroupId = false;
  } else {
    fieldWorkflowObjNew.largerGroupId = true;
  }

  if (ourDeal.procurementLevel === null) {
    fieldWorkflowObjNew.procurementLevel = false;
  } else {
    fieldWorkflowObjNew.procurementLevel = true;
  }

  if(props.formErrors) {
    const formErrorsObjNew = {...props.formErrors};
    Object.entries(fieldWorkflowObjNew).forEach(([key, value]) => { if(formErrorsObjNew[key]) formErrorsObjNew[key].disabled = !value })
    props.setFormErrors(formErrorsObjNew);
  }

  if(props.additionalInfoErrors) {
    const additionalInfoErrorsNew = {...props.additionalInfoErrors};
    if(selected === 'Cash - Trade (SOP Only)') {
      Object.values(additionalInfoErrorsNew).forEach(obj => obj.disabled = true);
    }
    else {
      Object.values(additionalInfoErrorsNew).forEach(obj => obj.disabled = false);
    }
    props.setAdditionalInfoErrors(additionalInfoErrorsNew);
  }

  props.setFieldWorkflowObj(fieldWorkflowObjNew);
}

export function getQuoteProcessData(ourDeal) {
  let finalDealNo = ourDeal.finalDealNo ? '#' + ourDeal.finalDealNo : '#N/A'
  let topText = null
  let procStage = null
  let typeText = null
  let tableStatus = null
  let status = null
  let submitted = null

  
  switch(true)
  {
    case (ourDeal.archived === 0 && ourDeal.dynamicsStateCode === 1):
      status = 'Won';
      break;
    case (ourDeal.archived === 0 && ourDeal.dynamicsStateCode === 2):
      status = 'Lost';
      break;
    case (!ourDeal.ordered && !ourDeal.closed && ourDeal.archived === 0):
      status = 'Active'
      break;
    case (ourDeal.ordered && ourDeal.closed && ourDeal.archived === 0):
      status = 'Ordered'
      break;
    case (!ourDeal.ordered && ourDeal.closed && ourDeal.archived === 0):
      status = 'Closed'
      break;
    case (ourDeal.archived === 1):
      status = 'Archived'
      break;
    default: // Do nothing
  }

  switch(true)
  {
    
    case (ourDeal.processStage === 2 && !ourDeal.declineSubmitted && ourDeal.archived === 0):
      submitted = 'Submitted'
      break;
    case (ourDeal.processStage === 2 && ourDeal.declineSubmitted && ourDeal.archived === 0):
      submitted = 'Declined'
      break;
    case (ourDeal.processStage === 3 && !ourDeal.declineSubmitted && ourDeal.archived === 0):
      submitted = 'Approved'
      break;
    case (ourDeal.archived === 1):
      submitted = 'Archived'
      break;
    default: // Do nothing
  }


  switch (ourDeal.processStage) {
      case 0:
          topText = 'Quote ' + finalDealNo
          procStage = <div className="text-16">{`Status: ${status}`}</div>
          typeText = `Quote`
          tableStatus = `${status}`
          break;
      case 1:
          topText = 'Order ' + finalDealNo
          procStage = <div className="text-16">Status: Draft</div>
          typeText = "Order"
          tableStatus = `Draft`
          break;
      case 2:
          topText = 'Order ' + finalDealNo
          procStage = <div className="text-16">Status: {submitted}</div>
          typeText = "Order"
          tableStatus = `${submitted}`
          break;
      case 3:
          topText = 'Order ' + finalDealNo
          procStage = <div className="text-16">Status: {submitted}</div>
          typeText = "Order"
          tableStatus = `${submitted}`
          break;
      case 4:
          topText = 'Order ' + finalDealNo
          procStage = <div className="text-16">Status: Invoiced</div>
          typeText = "Order"
          tableStatus = `Invoiced`
          break;
      case 5:
          topText = 'Order ' + finalDealNo
          procStage = <div className="text-16">Status: Completed</div>
          typeText = "Order"
          tableStatus = `Completed`
          break;
      default: // Do nothing
  }
  return { topText, procStage, typeText, tableStatus, status }
}

export function getAddressDropdown(data) {
  
  if(!data) return [];

  const addresses = [...data?.address, ...data?.invoiceAddress];
  const uniqueData = Array.from(new Set(addresses.map(x => JSON.stringify(x))))
  const parsedData = uniqueData.map(x => JSON.parse(x));

  const AddressData = parsedData
    .sort(function(a, b){
      if(String(a.accountNumber) < String(b.accountNumber)) { return -1; }
      if(String(a.accountNumber) > String(b.accountNumber)) { return 1; }
      return 0; })
    .map(address => {
      let addressFull = [];
      let addressString = "";
      if (address.accountNumber) addressFull.push(address.accountNumber);
      if (address.customerName) addressFull.push(address.customerName);
      if (address.address1) addressFull.push(address.address1);
      if (address.address2) addressFull.push(address.address2);
      if (address.address3) addressFull.push(address.address3);
      if (address.town) addressFull.push(address.town);
      if (address.county) addressFull.push(address.county);
      if (address.postcode) addressFull.push(address.postcode);
      addressString = String(addressFull.map(ad => { return ad + ', ' }))
      addressString = addressString.substring(0, addressString.length - 2).replace(/, ,/g, ', ');
      return { label: addressString, value: address.id }
  })
  AddressData.unshift({ label: "Please Select", value: "-1" });
  return AddressData;
}

export async function checkAccess(account, ourDeal) {

  let adminUser = false;
  let sopOwnerUser = false;
  let devSop = false;
  let isManager = false;
  let isLogistics = false;
  let isSales = false;
  let isSalesAdmin = false;
  let isFinance = false;
  let isProServices = false;

  let adminUserRead = false;
  let sopOwnerUserRead = false;
  let devSopRead = false;
  let isManagerRead = false;
  let isLogisticsRead = false;
  let isSalesRead = false;
  let isSalesAdminRead = false;
  let isFinanceRead = false;
  let isProServicesRead = false;


  if(account.groups.includes('Apps Dept')) { devSop = true; devSopRead = false; }
  if(account.groups.includes('DevSop')) { devSop = true; devSopRead = false; }
  if(account.groups.includes('Senior SOP')) { adminUser = true; adminUserRead = false; }
  if(account.groups.includes('SOP')) { adminUser = true; adminUserRead = false; }
  if(account.groups.includes('Sales')) { isSales = true; isSalesRead = false; }
  if(account.groups.includes('Sales Admin')) { isSalesAdmin = true; isSalesAdminRead = false; }
  if(account.azureId === ourDeal.activeDirectoryObjectGuidSopOwner) { sopOwnerUser = true; sopOwnerUserRead = false; }
  if(account.teamAzureIds.includes(ourDeal.activeDirectoryObjectGuid) && account.teamAzureIds.includes(account.azureId)) { isManager = true; isManagerRead = false; }
  if(account.groups.includes('Warehouse & Logistics')) { isLogistics = true; isLogisticsRead = true; }
  if(account.groups.includes('Finance')) { isFinance = true; isFinanceRead = true; }
  if(account.groups.includes('PS')) { isProServices = true; isProServicesRead = true; }


  return { 
    users: {
      isDevSop: devSop,
      isSopOwner: sopOwnerUser,
      isAdmin: adminUser, 
      isManager: isManager,
      isLogistics: isLogistics,
      isSales: isSales,
      isSalesAdmin: isSalesAdmin,
      isFinance: isFinance,
      isProServices: isProServices
    },
    read: {
      isDevSop: devSopRead,
      isSopOwner: sopOwnerUserRead,
      isAdmin: adminUserRead, 
      isManager: isManagerRead,
      isLogistics: isLogisticsRead,
      isSales: isSalesRead,
      isSalesAdmin: isSalesAdminRead,
      isFinanceRead: isFinanceRead,
      isProServicesRead: isProServicesRead
    }
  }
}

export async function getDocs(ourDeal, setOurDeal) {
  const docs = await Axios.get(`/documents/opportunityDocs/${ourDeal.dynamicsOpportunityNumber}`);
  setOurDeal({ ...ourDeal, document: docs.data });
}

export function viewOrder(ourDeal, history) {
  history.push(`/salesHub/accountmanagerview/order/${ourDeal.parentId}`);
}

export async function updateDealData(vals) { 
  const ev = vals.event;
  const type = vals.type;
  const timeout = vals.timeout;
  const ourDealNew = vals.props.ourDeal;

  ourDealNew[ev.target.name] = ev.target.value;
  vals.props.setOurDeal(ourDealNew);
  vals.props.setLocalEvent({ event: ev, type: type, timeout: timeout }); 
}

export async function updateFieldEvent(ourDeal, setOurDeal, event) {
  if(setOurDeal) setOurDeal({ ...ourDeal, [event.target.name]: event.target.value })
  const response = await Axios.put(`/entities/deal/${ourDeal.dealID}`, { entity: { [event.target.name]: event.target.value } });
  setOurDeal({ ...ourDeal, rowVersion: response?.data?.result[0]?.rowVersion });
}

export async function updateField(ourDeal, setOurDeal, name, value) {
  if(setOurDeal) setOurDeal({ ...ourDeal, [name]: value })
  const response = await Axios.put(`/entities/deal/${ourDeal.dealID}`, { entity: { [name]: value } });  
  setOurDeal({ ...ourDeal, rowVersion: response?.data?.result[0]?.rowVersion });
}

export async function updateProducts(dealID, mapped) {
  if(Array.isArray(mapped) && mapped.length) {
    await Axios.put(`/entities/products/${dealID}`, { entity: mapped }).then(api => api.data);
  }
}

export async function updateProductEntity(props, product, type, fields, timerString, timerObject, timeout) {
  return new Promise((resolve, reject) => {
    clearTimeout(timerObject.current[timerString]);
    const timer = setTimeout(async () => {
      const reduced = fields.reduce((acc, obj) => ({ ...acc, [obj]: product[obj] }), {});
      await Axios.put(`/entities/workflow/deals/actionUpdateProduct/${props.ourDeal.dealID}/${product.prodID}/${product.isADevice}/${type}`, reduced)
        .then(res => {
          props.setLocalEvent({ event: null, type: 4, timeout: 50 });
          resolve(res.data);
        })
        .catch(error => {
          reject(error.data);
        })
    }, timeout);
    timerObject.current[timerString] = timer;
  });
}

export async function updateMainFormEntity(ourDeal, setOurDeal, event) {

  const exceptions = [400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';

  try {

    const baseData = { [event.target.name]: event.target.value, rowVersion: ourDeal.rowVersion };

    const result = await Axios.put(`/entities/deal/${ourDeal.dealID}`, { entity: baseData, version: true });
    if(exceptions.includes(result.status)) {
      if(result.status === 400) toast.error(invalidConfig);
      if(result.status === 404) toast.error(notFoundError);
      if(result.status === 409) toast.error(versionError);  
    } else {
      setOurDeal({ ...ourDeal, ...result?.data?.result[0].rowVersion });
    }

  } catch (error) {
    console.log(error);
    toast.error(exceptionError);
  }
}

export function calcActiveField(activeField, field, identifier) {
  return activeField.current[`${field}-${identifier}`];
}

export function calcActiveFieldMain(activeField, field, identifier) {
  return activeField[`${field}-${identifier}`];
}

export function deleteProduct(props, row) {

  const { dispatch } = store; 

  const attachments = props.ourDeal.product.filter(x => Number(x.parentId) === Number(row.prodID));
  const baseData = { ...props, productTickedEvent: { selectedProducts: [row, ...attachments] } };

  dispatch(setDrawer({
      open: true,
      content: <DeleteProductDrawer {...baseData} 
                onFinish={() => dispatch(setDrawer({ open: false, content: null }))} 
                onCancel={() => dispatch(setDrawer({ open: false, content: null }))} />,
      width: ""
  })); 
}

export async function updateLeasePeriodPayment(ourDeal, setOurDeal) {

  const leaseCalculation = ((ourDeal.pricing.total / 1000) * ourDeal.leaseRate).toFixed(2);

  await Axios.put(`/entities/deal/${ourDeal.dealID}`, { entity: { leasePeriodPayment: leaseCalculation, rowVersion: ourDeal.rowVersion } }); 

  ourDeal.leasePeriodPayment = leaseCalculation;
  setOurDeal(ourDeal);
}


export async function getDealPricing(dealId, ourDeal, setOurDeal) {

  const pricingData = await Axios.get(`/entities/workflow/deals/actionGetDealPricing/${dealId}`); 

  ourDeal.pricing = pricingData.data.result;
  setOurDeal(ourDeal);
}


export function productOrigin(row) {

  let toolTip = 'Unable to determine Origin';
  if(Number(row.rowType) === 0) toolTip = 'Created in Product Configurator';
  if(Number(row.rowType) === 1) toolTip = 'Created as Blank Part';
  if(Number(row.rowType) === 2) toolTip = 'Created as Refinanced Part';
  if(Number(row.rowType) === 3) toolTip = 'Created as PS import';
  if(Number(row.rowType) === 4) toolTip = 'Created as Settlement Line';

  let colour = 'red';
  if(Number(row.rowType) === 0) colour = 'green';
  if(Number(row.rowType) === 1) colour = 'orange';
  if(Number(row.rowType) === 2) colour = 'purple';
  if(Number(row.rowType) === 3) colour = 'batched';
  if(Number(row.rowType) === 4) colour = 'royalblue';

  const productCircle = <div style={{ align: 'center' }} className={`circleProduct ${colour}`} title={toolTip} alt={toolTip} />;

  return productCircle;
}

export function getDoctype(leaseorcash, serviceContract) {
  const dd = 'Direct Debit';
  const bmsas = 'BM Sales & Service agreement';
  const bmsa = 'BM Service Addendum';
  const ssa = 'Software Support Agreement';
  const cso = 'Credit Safe Output';
  const lad = 'Lease additional documentation';
  const la = 'Lease Agreement';
  const lra = 'Lease / Rental Acceptance';
  const ldd = 'Lease Direct Debit';
  const p = 'Proposal';
  const bmpsq = 'BM PS Quote';
  const nf = 'Network Form';
  const nsa = 'Network Support Agreement';
  const od = 'Other Document';
  const a = 'Approval';
  const po = 'Purchase Order';
  const btbo = 'Back to Back Order';
  const ca = 'Copycare agreement';
  const ess = 'e-Signature Summary';
  const no = 0;
  const yes = 1;
  const existing = 2;
  
  if (leaseorcash) {
      switch (leaseorcash) {
          case appStrings.Lease:
              if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, lad, la, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, `${la}*`, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, lad, `${la}*`, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              break;
          case appStrings.InternalRentalCPC:
              if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, lad, la, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, `${la}*`, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, lad, `${la}*`, `${lra}*`, ldd, p, bmpsq, nf, nsa, od, a]; }
              break;
          case appStrings.Cash:
              if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, `${po}*`, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, p, bmpsq, nf, nsa, po, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, po, od, a]; }
              break;
          case appStrings.CashCPC:
            if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, `${po}*`, od, a]; }
            if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, p, bmpsq, nf, nsa, po, od, a]; }
            if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, po, od, a]; }
            break;
          case appStrings.CashTrade: return [dd, bmsas, bmsa, ssa, cso, p, bmpsq, nf, nsa, `${po}*`, od, a];
          case appStrings.BackToBackCPC: return [dd, `${btbo}*`, ssa, cso, p, bmpsq, nf, nsa, od, a];
          case appStrings.BackToBackEROY: return [dd, `${btbo}*`, ssa, cso, p, bmpsq, nf, nsa, od, a];
          case appStrings.ShortTermInternalRental:
              if(serviceContract === no) { return [dd, bmsas, bmsa, ssa, cso, lad, la, `${lra}*`, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, la, `${lra}*`, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, lad, la, `${lra}*`, p, bmpsq, nf, nsa, od, a]; }
              break;
          case appStrings.Subscription:
              if(serviceContract === no) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === yes) { return [dd, `${bmsas}*`, bmsa, ssa, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a]; }
              if(serviceContract === existing) { return [dd, bmsas, bmsa, ssa, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a]; }
              break;
          case appStrings.Copycare: return [dd, btbo, ssa, `${ca}*`, cso, p, bmpsq, nf, nsa, od, a];
          case appStrings.FOC: break;
          case appStrings.POC: return [dd, bmsas, bmsa, ssa, cso, ldd, p, bmpsq, nf, nsa, od, a];
          case appStrings.ScottishProcurement: return [`${po}*`, od];
          case appStrings.ScottishProcurementLease: return [`${bmsas}*`, od];
          default:  return [dd, btbo, bmsas, bmsa, ssa, ca, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a, ess];
      }
  }
  
  return [dd, btbo, bmsas, bmsa, ssa, ca, cso, lad, la, ldd, p, bmpsq, nf, nsa, od, a, ess];
}


export function searchArray(array, textArray, filterColumns) {
  textArray = textArray.map(t => t.toLowerCase());
  return array.filter((a) => {
    return textArray.every((t) => {
      return filterColumns.some((f) => {    
        return a[f] ? a[f].toString().toLowerCase().indexOf(t) !== -1 : null;
      });
    });
  });
}

export async function generateDealSheet(deal, setDealSheetLoading) {
  if(setDealSheetLoading) setDealSheetLoading(true);
  const dataResult = await Axios.get(`/entities/workflow/deals/actionGenerateDealSheet/${deal.dealID}`);
  downloadFileHandler(dataResult.data.result.filename, 'pdf', dataResult.data.result.content);
  if(setDealSheetLoading) setDealSheetLoading(false);
}

export async function generateQuoteSheet(deal, setQuoteSheetLoading) {
  if(setQuoteSheetLoading) setQuoteSheetLoading(true);
  const dataResult = await Axios.get(`/entities/workflow/deals/actionGenerateQuoteSheet/${deal.dealID}`);
  downloadFileHandler(dataResult.data.result.filename, 'pdf', dataResult.data.result.content);
  if(setQuoteSheetLoading) setQuoteSheetLoading(false);
}

export function getProcurementDropdownData(id, ourDeal) {
  
  if (ourDeal.procurementData) {

    const procurementData = ourDeal.procurementData.find(data => data.Id === id);

    if(!procurementData || !procurementData?.DropDownValues) return [];

    const dropdownArray = procurementData.DropDownValues.split(';').map(data => ({
      label: data,
      value: data
    }));

    dropdownArray.unshift({
      label: "Please Select",
      value: "-1"
    });

    return dropdownArray;
  } else {
    return [];
  }
}