import { useState, Fragment, useEffect } from "react";
import { checkFormErrors } from "../../../../classes/formErrors/formErrors";
import { getRMBNoteErrors } from "../../../../classes/formErrors/repository";
import { createMultilineTextInput } from 'classes/controls/genericControls';
import UILIB from "components";
import Axios from "classes/axios";

export default function EditNotes({ entity, editNote, setEditNote, sync }) {
  const emptyEntity = { note: "" };
  const [saving, setSaving] = useState(false);
  const [formData, setFormdata] = useState(emptyEntity);
  const [formErrors, setFormErrors] = useState({});
  const [bannerError, setBannerError] = useState({ error: false, message: "" });
  const placeholder = "Add your notes here...";
  const className = "consumablesHub siteDevices-notes-textArea";
  const exceptions = [400, 404];

  const invalidConfig = "The options selected are invalid, please try again or contact support";
  const notFoundError = "The server was unable to find the requested endpoint, please reload or contact support";
  const exceptionError = "There was an exception while saving this record, please reload or contact support";

  useEffect(() => {
    setFormdata({note: editNote.note })
  },[]);

  const actionEditNotes = async (data) => {

    setSaving(true);
    const formResult = await checkFormErrors(getRMBNoteErrors(formData), setFormErrors, null, null, formData, null);
    if (formResult.errorCount === 0) {
      try {
        const result = await Axios.put(`/entities/mifNote/${editNote.noteId}`, { entity: formData });
        if (exceptions.includes(result.status)) {
          if (result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if (result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          setEditNote({ noteId: '', note: '', show: false});
          sync.set(!sync.get);
        }
      } catch (err) {
        console.log(err);
        setBannerError({ error: true, message: exceptionError });
      }
    }
    
    const noteIndex = data.notes.findIndex(note => note.id === editNote.noteId);
    if (noteIndex !== -1) data.notes[noteIndex].note = formData.note;

    setSaving(false);
  };

  const actionUpdateNotes = async (event) => {
    setFormdata({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <Fragment>
      <div className={`flex-container row width-100`}>
        <div className={`flex-item flex-grow-1 end`}>
          <UILIB.Button
            disabled={saving}
            loading={saving}
            value={"Save Notes"}
            className="green"
            onClick={() => actionEditNotes(entity)}
          />
          <UILIB.Button
            disabled={saving}
            value={"Cancel"}
            className="red"
            onClick={() => setEditNote({noteId: '', note: '', show: false})}
          />
        </div>
        {bannerError.error && (
          <div className="flex-container end width-100">
            <div className="errored message">{bannerError.message}</div>
          </div>
        )}
      </div>
      <div className={`flex-container row width-100 height-50 padding-8`}>
        {createMultilineTextInput(
          "",
          "note",
          placeholder,
          true,
          formData,
          formErrors,
          (ev) => actionUpdateNotes(ev),
          false,
          className,
          true
        )}
      </div>
    </Fragment>
  );
}
