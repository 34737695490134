import { Fragment } from 'react';
import { formatDateTime } from 'classes/format';

export default function AuditContainerGrouped({ type, data, field = 'eventDetail' }) {
  return (
    <Fragment>
      <div className="flex-container row width-100 mar-t10 mar-b10">
        <div className="flex-container row nowrap center width-100">
          <div className="flex-item center width-100 colour background-8 orange text-14">{type}</div>
        </div>
        
        {data.length > 0 && (
          <div className="flex-container row nowrap start width-100 colour background lightGrey">
            <div className="flex-item start flex-grow-1 mar-r20 text-13">{data[0].createdByTxt}</div>
            <div className="flex-item end mar-r20 text-13">
              {formatDateTime(data[0].createdAt, "DD-MM-YYYY HH:mm:ss")}
            </div>
          </div>
        )}

        {data.map((x, i) => (
          <Fragment key={i}>
            <div className="flex-container row nowrap start flex-grow-1 width-100 height-100 colour background-4 lightGrey">
              <div className="flex-item text-12 mar-l5 pad-1 consumablesHub tonerRobotNotes-text-content">
                {x[field]}
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}
