
import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios'
import { useHistory } from "react-router-dom";
import { Fragment } from 'react';
export default function BlockCustomersOnStop({ user }) {

    const history = useHistory();
    const [customersOnStop, setCustomersOnStop] = useState(null);

    useEffect(() => {
        Axios.get('/sales/customers/onstop').then(customers => {
            setCustomersOnStop(customers.data);
        });
    }, []);


    return <UILIB.Paper className="flex-container column height-100">
        <div className="width-100 height-100">
            <h2 className="center">Customers On Stop</h2>
            {Boolean(customersOnStop) && <Fragment>
                {!customersOnStop.length && <div className="width-100">
                    You have no Customers On Stop
                </div>}
                {Object.values(customersOnStop).map((customer, index) => {
                     return <div key={"quote_" + index} className="flex-container center width-100 height-100">
                                <h2 className="flex-item around text-xlarge-count" >{customer.theCnt}</h2>
                            </div>
                })}
            </Fragment>}
            {!customersOnStop && <UILIB.Loading type={3} />}
        </div>
        <div className="flex-item end flex-grow-1 width-100">
            <UILIB.Button 
              className={`primary small consumablesHub buttonSize`}
              value="View All" 
              onClick={() => { history.push('/salesHub/accountmanagerview/customers/onstop') }} 
            />
        </div>
    </UILIB.Paper>


}