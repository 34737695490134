import UILIB from 'components'

export default function Refresh({ render, setRender, loading }) 
{

  const handleRefresh = async () => {
    setRender(!render);
  };

  const content = (loading) 
                    ? <UILIB.Loading type={3} className="-table" /> 
                    : <span className="icon-refresh"></span>
                    
  return <div title="Refresh" className={"refresh clickable"} onClick={() => handleRefresh()}>{content}</div>
}