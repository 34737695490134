import { memo } from 'react';
import { setCacheData } from '../functions';

const ExpandData = memo(({ settings, moduleRender, setModuleRender }) =>
{
  const expandShrinkRows = () => {
    settings.current = setCacheData({ ...settings.current, expanding: !settings.current.expanding });
    setModuleRender(!moduleRender);
  }

  return <div title={`${settings.current.expanding ? 'Shrink Columns' : 'Expand Columns'}`} className={`expand ${settings.current.expanding ? 'selected' : ''} clickable icon-expand`} onClick={() => expandShrinkRows()}></div>
})
export default ExpandData