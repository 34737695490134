import { useState, Fragment } from 'react';
import UILIB from 'components';
import UsersDrawerEdit from './usersDrawerEdit';
import UsersDrawerEvent from './usersDrawerEvent';
import UsersDrawerReport from './usersDrawerReport';

export default function NotesDrawer({ entity, sync, onClose }) 
{  

  const [selected, setSelected] = useState(0);

return <Fragment>
    <div className='flex-container row start'>
      <UILIB.Button className={'mar-r5 small ' + (selected === 0 ? 'primary' : 'secondary')} name='User Info' value='User Info' onClick={() => setSelected(0)} />
      <UILIB.Button className={'mar-l5 mar-r5 small ' + (selected === 1 ? 'primary' : 'secondary')} name='Service Report' value='Service Report' onClick={() => setSelected(1)} />
      <UILIB.Button className={'mar-l5 mar-r5 small ' + (selected === 2 ? 'primary' : 'secondary')} name='View Audit' value='View Audit' onClick={() => setSelected(2)} />
    </div>
    {(selected === 0) && <UsersDrawerEdit entity={entity} onClose={onClose} sync={sync} />}
    {(selected === 1) && <UsersDrawerReport entity={entity} onClose={onClose} sync={sync} />}
    {(selected === 2) && <UsersDrawerEvent entity={entity} />}
  </Fragment>
}
