import { Fragment } from 'react';
import UILIB from 'components';


export default function RobotDrawer({ entity }) 
{  

  const robotLog = (entity && entity.tonerRobotLog && entity.tonerRobotLog.length) 
    ? entity.tonerRobotLog.replace(/<br\/>/g, '\n').trim()
    : '';

  return <Fragment>
    <div className={`flex-container row padding-8`}>
        <h2>Toner Robot Log for {entity.serialNumber}</h2>
        <UILIB.NoteContainer type={'Robot Log Event'} createdBy={entity.fromAlertType} createdAt={entity.CheckedByBotDate} message={robotLog} />
    </div>
  </Fragment>
}
