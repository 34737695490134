import React from 'react'
import Button from '../Button/Button'

export default function Confirm({ header, text, confirmText = 'Yes', cancelText = 'No', onConfirm = () => { }, onCancel = () => { } }) {
    return (
        <div className="modalBackground">
            <div className="modalContent">
                {Boolean(header) && <div className="text-xlarge text-center mar-b5">{header}</div>}
                {Boolean(text) && <div className="text-small">{text}</div>}
                <div className="flex-container row center mar-t10">
                    <Button className="primary mar-r5" value={confirmText} onClick={onConfirm} />
                    <Button className="grey mar-l5" value={cancelText} onClick={onCancel} />
                </div>
            </div>
        </div>
    )
}