
import { Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { filterEngine, actionOpenDrawer, tableContainer } from '../functions';

export default function OptionsContainer({ row, dispatch, setPdiModalData, render, setRender }) {

  const location = useLocation();

  const filterData = filterEngine(row, location.pathname);

  const fn_editDraw = actionOpenDrawer.bind(null, 'ViewInstallDraw', row, filterData.dataObject.mergedEntity, dispatch, !filterData.dataObject.isDisabled, { get: render, set: setRender }, '800px');
  const fn_pdiDrawer = setPdiModalData.bind(null, {  show: true, entity: row, isDisabled: !filterData.dataObject.isDisabled });

  return <Fragment>
          <div className="flex-container row width-100 height-100">
            <div className={`flex-item start width-100`}>{tableContainer(fn_editDraw, 'icon-pencil', 'Edit', 'colour lightGrey', true)}</div>
            <div className={`flex-item start width-100`}>{tableContainer(fn_pdiDrawer, 'icon-ticket', 'PDI', 'colour lightGrey', true)}</div>
          </div>
        </Fragment>
}
