import { useState, Fragment } from 'react';
import MeterHistoryTable from './meterHistoryTable';
import DispatchedOrdersTable from './dispatchedOrdersTable';
import ConsumablesLookUpInkPercentages from './consumablesLookUpInkPercentages';
import ConsumablesLookUpDaysToEmpty from './consumablesLookUpDaysToEmpty';
import ConsumablesLookUpPagesPrinted from './consumablesLookUpPagesPrinted';
import ConsumablesLookUpScaler from './consumablesLookUpScaler';

export default function ConsumablesLookUpSerialNumberData({ entityLookup }) {
  const [months, setMonths] = useState(3)

  return <Fragment>
    {entityLookup.hasOwnProperty('serialNumber') && <div className='width-100 pad-t3'>
      <MeterHistoryTable serialNumber={entityLookup.serialNumber} />
    </div>}
    {entityLookup.hasOwnProperty('serialNumber') && <div className='flex-container wrap width-100 pad-t3 pad-b3'>
      <div className='flex-item width-100 pad-t3 pad-b3'><ConsumablesLookUpDaysToEmpty serialNumber={entityLookup.serialNumber} /></div>
      <div className='width-100 mar-b5 mar-t5'>
        <div className='pad-t2 pad-b2'><ConsumablesLookUpScaler setMonths={setMonths} /></div>
        <div className='flex-container row'>
          <div className='width-100 pad-t2 pad-b2'><ConsumablesLookUpInkPercentages months={months} serialNumber={entityLookup.serialNumber} /></div>
          <div className='width-100 pad-t2 pad-b2'><ConsumablesLookUpPagesPrinted months={months} serialNumber={entityLookup.serialNumber} /></div>
        </div>
      </div>
    </div>}
    {entityLookup.hasOwnProperty('serialNumber') && <div className='width-100'>
      <DispatchedOrdersTable serialNumber={entityLookup.serialNumber} />
    </div>} 
  </Fragment>
}