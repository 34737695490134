import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import 'react-toastify/dist/ReactToastify.css';
import { getRequestDrawerObject } from '../functions'; 
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getEditRequestPortalErrors } from 'classes/formErrors/repository';
import { createTextInput, createSelectInput, createAutoComplete, yesNo } from 'classes/controls/formControls';
import { getCompaniesVoAPI } from 'classes/helpers';


export default function EditUserDrawer({ entity, sync, onClose }) {

  const [formData, setFormData] = useState(getRequestDrawerObject(entity));
  const missingCustomer = (Number(formData.customerId) === 0)
  const defaultError = { error: false, message: '' };
  const defaultFormErrors = { syncInvNo: defaultError, syncInvDate: defaultError };
  const [bannerError, setBannerError] = useState(defaultError);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [saving, setSaving] = useState(false);
  const exceptions = [204,400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const actionUpdateField = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  const actionSelectCustomer = (event) => {
    setFormData({ ...formData, customerId: event.Id, companyName: event.AltName, accountNumber: event.Reference, serviceRegion: event.ServiceRegion });
  }

  const actionSaveForm = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const formResult = await checkFormErrors(getEditRequestPortalErrors(formData), setFormErrors, null, null, formData, null);
      if(formResult.errorCount === 0) {
        const result = await Axios.put(`/entities/portalsV3_request/${entity.id}`, { entity: formData }); 
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          Object.keys(result.data.result[0]).forEach(x => entity[x] = result.data.result[0][x]);
          entity.customerFullName = ` ${entity.customerFirstName} ${entity.customerLastName} `;
          sync.set(!sync.get);
          onClose();
          setSaving(false);
        }
      }
    } catch (err) {
      console.log(err);
      setSaving(false);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  }


  return <Fragment>
    <div className="flex-container row width-100 wrap">
      {missingCustomer && <div className="width-100">
        <div className="flex-item center align-center font-weight-bold text-16 colour text red width-100">Please Add a Customer</div>
        <i className="flex-item center align-center text-11 width-100 mar-b10">Some Customer related data is missing our end, please search to re-setup the Customer</i>
        <div className='flex-container row start width-100 mar-b5'>
          {createAutoComplete('Search for Customer *', 'customerSearch', false, 'Seach for Customer', getCompaniesVoAPI, (ev) => actionSelectCustomer(ev), false, null, null, null, 'width-100 fullBorder border-colour-grey-4')}
        </div>
      </div>}
      <div className="flex-item center align-center font-weight-bold text-16 width-100">Edit Request</div>
      <div className='flex-container row start width-100'>
        {createTextInput('Company Name *', 'companyName', (saving || Boolean(entity.status === 2)), formData.companyName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('First Name *', 'customerFirstName', (saving || Boolean(entity.status === 2)), formData.customerFirstName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Last Name *', 'customerLastName', (saving || Boolean(entity.status === 2)), formData.customerLastName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Telephone No', 'customerTelephoneNo', (saving || Boolean(entity.status === 2)), formData.customerTelephoneNo, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className="flex-item center align-center font-weight-bold text-16 mar-t10 width-100">Permissions</div>
      <div className='flex-container row start width-100'>
        {createSelectInput('Active', 'IsActive', (saving || Boolean(entity.status === 2)), Number(formData.IsActive), yesNo, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createSelectInput('Preferred Contact', 'IsPrimary', (saving || Boolean(entity.status === 2)), Number(formData.IsPrimary), yesNo, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createSelectInput('Meters', 'IsMeter', (saving || Boolean(entity.status === 2)), Number(formData.IsMeter), yesNo, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createSelectInput('Email Readings', 'IsUnsubscribed', (saving || Boolean(entity.status === 2)), Number(formData.IsUnsubscribed), yesNo, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createSelectInput('Send Invoices', 'IsInvoice', (saving || Boolean(entity.status === 2)), Number(formData.IsInvoice), yesNo, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createSelectInput('Send Tickets', 'IsWork', (saving || Boolean(entity.status === 2)), Number(formData.IsWork), yesNo, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createSelectInput('LivelinkContact', 'IsLiveLink', (saving || Boolean(entity.status === 2)), Number(formData.IsLiveLink), yesNo, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className='flex-container row start width-100 mar-t2 mar-l5 mar-r5'>
        <UILIB.Button className='red' disabled={saving || Boolean(entity.status === 2)} value='Cancel' onClick={() => onClose()} />
        <UILIB.Button className='green' disabled={saving || Boolean(entity.status === 2)} loading={saving} value='Save' onClick={async () => await actionSaveForm()} />
      </div>
      {bannerError.error && <div className="flex-container row start width-100 mar-l5">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>

}
