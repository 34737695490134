import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function AddEditWorkflow(props) {

    const [workflowData, setWorkflowData] = useState(props.workflow);

    const saveChanges = async () => {
        if (!props.workflow.id) {
          await Axios.post('/admin/workflows', workflowData)
        }
        else {
          await Axios.put(`/admin/workflows/${props.workflow.id}`, workflowData)
        }
        props.onClose();
    }

    const updateWorkflowData = (event) => {
        const workflowDataNew = structuredClone(workflowData);
        workflowDataNew[event.target.name] = event.target.value;
        setWorkflowData(workflowDataNew)
    }

    return <Fragment>
        <h2>{`${props.type === 0 ? 'Add' : 'Edit'} a Workflow`}</h2>
        <label>Workflow Name</label>
        <UILIB.TextInput
          name="name"
          className="textInput input"
          value={workflowData.name}
          onChange={(event) => { updateWorkflowData(event) }}
        />
        <label>Workflow Description</label>
        <UILIB.TextInput
          name="description"
          className="textInput input"
          value={workflowData.description}
          onChange={(event) => { updateWorkflowData(event) }}
        />
        <label>Mail Subject</label>
        <UILIB.TextInput
          name="subject"
          className="textInput input"
          value={workflowData.subject}
          onChange={(event) => { updateWorkflowData(event) }}
        />
        <label>Mail From</label>
        <UILIB.TextInput
          name="from"
          className="textInput input"
          value={workflowData.from}
          onChange={(event) => { updateWorkflowData(event) }}
        />
        <label>Workflow Trigger</label>
        <UILIB.TextInput
          name="trigger"
          className="textInput input"
          value={workflowData.trigger}
          onChange={(event) => { updateWorkflowData(event) }}
        />
        <label>Mail Contents</label>
        <UILIB.TextArea
          name="body"
          className="textInput input"
          value={workflowData.body}
          style={{ width: "100%", height: "220px", resize: 'none' }}
          onChange={(event) => { updateWorkflowData(event) }}
        />
        <UILIB.Button value="Save" onClick={async () => await saveChanges()} />
    </Fragment >
}