import { useState, useEffect, useRef, Fragment } from 'react';
import Axios from 'classes/axios'
import * as functions from '../../shared/functions';
import FleetMenu from '../../shared/fleetMenu';
import UploadFileContainer from './modal/uploadFileContainer';
import UploadTable from './tables/blockUploadTable';


export default function UploadManagement(props) {
  const [loading, setLoading] = useState(false);  
  const pageStartup = useRef({ uploadMain: true });
  const tableFilterDefaults = [functions.uploadClassData(0), functions.statusClassData(0)];
  const [fileUploadJobActive, setFileUploadJobActive] = useState(functions.queueJobBase);
  const uploadTableSortHandler = useRef({ ...functions.tableSortHandlerBase, filter: tableFilterDefaults });
  const [driverTableSortHandler, setDriverTableSortHandler] = useState(functions.tableSortHandlerBase);
  const [vehicleTableSortHandler, setVehicleTableSortHandler] = useState(functions.tableSortHandlerBase);
  const [driverVehicleTableSortHandler, setDriverVehicleTableSortHandler] = useState(functions.tableSortHandlerBase);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadFileHandler, setUploadFileHandler] = useState({ show: false, open: false });
  const [fleetRender, setFleetRender] = useState(0); 
  const [downloadingFile, setDownloadingFile] = useState({})
  const [showUloadedRowsModal, setShowUloadedRowsModal] = useState(false);
  const [uploadedRowsModal, setUploadedRowsModal] = useState({});
  const [tableData, setTableData] = useState([]);


  useEffect(() => {
    const asyncEffect = async () => {
      if (fileUploadJobActive.isActive === 1 && fileUploadJobActive.complete === 0) {
        await functions.getJob(setFileUploadJobActive, fileUploadJobActive);
        await functions.timerGetJobs(setFileUploadJobActive, fileUploadJobActive);
      }
    }
    asyncEffect();
  }, [fileUploadJobActive.trigger])
  

  useEffect(() => {
      const asyncRouter = async () => {
        await props.permissions.generalAccess();
      }
      if (pageStartup.current.uploadMain) { 
        setLoading(true);
        pageStartup.current.uploadMain = false; 
      }
      const asyncEffect = async () => {
        const uploadResult = await Axios.post(`/fleet/uploadManagement`, uploadTableSortHandler.current);
        uploadTableSortHandler.current = Object.assign(uploadResult.data.uploadTableSortHandler, { totalRows: uploadResult.data.totalRows });
        setTableData(uploadResult.data.result);
        setLoading(false);
      }
      asyncRouter();
      asyncEffect();
  }, [fleetRender])


  return <Fragment> 
            <FleetMenu selectedButton={'Upload'} />
            <UploadTable 
              showUloadedRowsModal={showUloadedRowsModal} 
              setShowUloadedRowsModal={setShowUloadedRowsModal}
              uploadTableSortHandler={uploadTableSortHandler}
              driverTableSortHandler={driverTableSortHandler}
              setDriverTableSortHandler={setDriverTableSortHandler}
              vehicleTableSortHandler={vehicleTableSortHandler}
              setVehicleTableSortHandler={setVehicleTableSortHandler}
              driverVehicleTableSortHandler={driverVehicleTableSortHandler}
              setDriverVehicleTableSortHandler={setDriverVehicleTableSortHandler}
              uploadFileHandler={uploadFileHandler}
              setUploadFileHandler={setUploadFileHandler}
              fileUploadJobActive={fileUploadJobActive}
              setFileUploadJobActive={setFileUploadJobActive}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              uploadedRowsModal={uploadedRowsModal} 
              setUploadedRowsModal={setUploadedRowsModal}
              downloadingFile={downloadingFile}
              setDownloadingFile={setDownloadingFile}
              tableData={tableData}
              setTableData={setTableData}
              fleetRender={fleetRender}
              setFleetRender={setFleetRender}
              loading={loading}
              setLoading={setLoading}
            />
            <UploadFileContainer
              selectedFile={selectedFile} 
              setSelectedFile={setSelectedFile}
              fileUploadJobActive={fileUploadJobActive}
              setFileUploadJobActive={setFileUploadJobActive}
              setUploadFileHandler={setUploadFileHandler}
              uploadFileHandler={uploadFileHandler}
              fleetRender={fleetRender}
              setFleetRender={setFleetRender}
            />
        </Fragment>        
}