import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function ActionPassBot({ records, callBack }) {

  const defaultDialogue = { count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [showDialogue, setShowDialoge] = useState(defaultDialogue);
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const exceptions = [400,404];
 
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const handleShowDialogue = (data = {}) => {
    setShowDialoge({ count: Object.keys(data).filter(x => data[x]).length, show: true }); 
  }

  const handleCloseDialogue = () => {
    setShowDialoge(defaultDialogue);
    setBannerError(defaultBanner); 
  }

  const handlePassRecords = async (data) => {
    try {
      setLoading(true);
      const tonerIds = Array.from(new Set(Object.keys(data).filter(x => data[x]).map(x => x)));
      const response = await Axios.put(`/entities/workflow/actionPassBot`, tonerIds);
      if(exceptions.includes(response.status)) {
        if(response.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(response.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        records = {};
        handleCloseDialogue();
        setBannerError(defaultBanner);
        setRender(!render);
        if(callBack && (typeof(callBack) === 'function')) await callBack();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBannerError({ error: true, message: exceptionError });
    }
  }

  return <Fragment>
    {showDialogue.show && <UILIB.MessageBox 
      header={'Pass Records'} 
      loading={loading}
      text={`Click OK to BOT PASS the selected ${(showDialogue.count) > 1 ? 'Records' : 'Record'}`} 
      onConfirm={async () => await handlePassRecords(records)} 
      onCancel={() => handleCloseDialogue()} 
      errored={bannerError} />}
    <div className={`flex-container row`}>
      <div className={`flex-item end width-100`}>
        <UILIB.Button disabled={loading || !Object.values(records).some(x => x)} value="Pass Selected" className="green" onClick={() => handleShowDialogue(records)} />
      </div>
    </div>
  </Fragment>
}