
import { Fragment } from 'react'
import UploadFileDialogue from './uploadFile';
import UploadFileRequiredFields from './uploadFileRequiredFields';
import { 
  Dialog, 
  DialogContent
} from '@mui/material';



export default function UploadFileContainer(props) 
{
    return <Fragment>
        <Dialog open={props.uploadFileHandler.open} >
          <DialogContent style={{ minWidth: "480px", minHeight: "150px" }}>
            {Number(props.uploadFileHandler.stage) === 1 && <UploadFileRequiredFields
              selectedFile={props.selectedFile} 
              setSelectedFile={props.setSelectedFile}
              fileUploadJobActive={props.fileUploadJobActive}
              setFileUploadJobActive={props.setFileUploadJobActive}
              setUploadFileHandler={props.setUploadFileHandler}
              uploadFileHandler={props.uploadFileHandler}
              releaseRender={props.releaseRender}
              setReleaseRender={props.setReleaseRender}
            />}
            {Number(props.uploadFileHandler.stage) === 2 && <UploadFileDialogue
              selectedFile={props.selectedFile} 
              setSelectedFile={props.setSelectedFile}
              fileUploadJobActive={props.fileUploadJobActive}
              setFileUploadJobActive={props.setFileUploadJobActive}
              setUploadFileHandler={props.setUploadFileHandler}
              uploadFileHandler={props.uploadFileHandler}
              releaseRender={props.releaseRender}
              setReleaseRender={props.setReleaseRender}
            />}
          </DialogContent>
        </Dialog>
    </Fragment>
}