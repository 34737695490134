import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios'


export default function FillColumnDrawer({ onFinished, ourDeal, isActive }) {

  
    const [pageLoaded, setPageLoaded] = useState(true);
    const [formData, setFormData] = useState({
        equipmentOwnerDetails: "",
        returnMethod: "",
        equipmentOwnership: ""
    });
    const [formErrors, setFormErrors] = useState({
        equipmentOwnership: "",
        equipmentOwnerDetails: "",
        returnMethod: ""
    });

    const returnMethods = [
      { value: 0, label: "Please Select" },
      { value: 1, label: "Agilico Arranging" },
      { value: 2, label: "Customer Disposing" },
      { value: 3, label: "Customer Arranging" },
    ]
    const equipmentOwnerships = [
      { value: 0, label: "Please Select" },
      { value: 1, label: "Lease - Agilico to purchase title" },
      { value: 2, label: "Lease - Title TBC" },
      { value: 3, label: "Lease - Return to Lease Company" },
      { value: 4, label: "Customer - DMC to Dispose" },
      { value: 5, label: "Customer - DMC to Store"}
    ]

    const returnMethodsSelect = returnMethods.map(rm => { return { value: rm.value, label: rm.label } });
    const equipmentOwnershipsSelect = equipmentOwnerships.map(eo => { return { value: eo.value, label: eo.label } });

    const changeInput = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    console.log(formData)
    const submitForm = async (event) => {

        try {
            Axios.post(`/sales/deals/${ourDeal.dealID}/equipmentremoval/fill`, formData).then(() => { onFinished() })
        }
        catch (err) {
            console.log(err)
            return;
        }
    }

    function GenerateErrorObject(errorMessage) {
        return { option: 2, messageText: errorMessage }
    }

    useEffect(() => { }, [])

    if (!pageLoaded) return <div></div>
    return  <Fragment>
              <h2>Fill equipment removal columns </h2>
              <UILIB.Select disabled={!isActive} name="equipmentOwnership" data={equipmentOwnershipsSelect} value={formData.equipmentOwnership} onChange={changeInput} errored={GenerateErrorObject(formErrors.equipmentOwnership)} />
              <label>Equipment Owner Details</label>
              <UILIB.TextInput className="textInput input" disabled={!isActive} name="equipmentOwnerDetails" value={formData.equipmentOwnerDetails} onChange={changeInput} errored={GenerateErrorObject(formErrors.equipmentOwnerDetails)} />
              <label>Return Method</label>
              <UILIB.Select disabled={!isActive} name="returnMethod" data={returnMethodsSelect} value={formData.returnMethod} onChange={changeInput} errored={GenerateErrorObject(formErrors.returnMethod)} />
              <UILIB.Button disabled={!isActive}value="Fill Records" onClick={submitForm} />
            </Fragment >
}