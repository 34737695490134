import { useState, useEffect, useRef, Fragment } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment-business-time";
import { useDispatch } from "react-redux";
import { getUtilTableDefaults, actionOpenDrawer } from '../functions';
import { createAutoComplete, createButton } from 'classes/controls/formControls';
import { getCompaniesPortalUsers } from 'classes/helpers';


export default function RequestLookupTable({ links }) {
  const headerData = [
    { label: 'Company Name', value: 'customerName', type: 'string', filtering: false, minWidth: 250, maxWidth: 250 },
    { label: 'Full Name', value: 'fullName', type: 'string', filtering: false, minWidth: 100, maxWidth: 100 },
    { label: 'Service Region', value: 'regionName', type: 'string', filtering: false, minWidth: 70, maxWidth: 70 },
    { label: 'Last On', value: 'lastOnDate', type: 'date', filtering: false, minWidth: 70, maxWidth: 70 },
    { label: 'Logins', value: 'loginCount', type: 'string', filtering: false, minWidth: 30, maxWidth: 30 }];

    
  const tablePageDefaults = { ...getUtilTableDefaults(), paging: { orderBy: 'lastOnDate', orderDir: 'DESC' } };
  const entityData = useRef([]);
  const dispatch = useDispatch();
  const [remoteRender, setRemoteRender] = useState(false);
  
 
  const actionDemoPortal = () => {
    window.open(`${links.customerPortal}CONDOR?actAs=${links.demoUser.id}`,'_blank', 'noopener noreferrer');
  }

  const actionOpenPortal = (event) => {
    window.open(`${links.customerPortal}${event.Reference}?actAs=${event.value}`,'_blank', 'noopener noreferrer');
  }

  const actionRequestPortal = () => {
    actionOpenDrawer('RequestPortalDrawer', null, dispatch, true)
  }

  useEffect(() => {
    const actionAsync = async () => {
      await new Promise(resolve => setTimeout(resolve, 20000));
      return setRemoteRender(!remoteRender);
    };
    actionAsync();
  }, [remoteRender]);

  async function constructTable(tonderData) {
    const tableData = tonderData.map(row => {
      return {
        fullName: { value: row.fullName, raw: row.fullName },
        customerName: { value: row.customerName, raw: row.customerName },
        regionName: { value: row.regionName, raw: row.regionName },
        lastOnDate: { value: moment(row.lastOnDate).format('DD/MM/YYYY HH:mm:ss'), raw: row.lastOnDate },
        loginCount: { value: row.loginCount, raw: row.loginCount }
      }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=20&$offset=0` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    entityData.current = await Axios.get(`/entities/portalsV3_userbases/getSummary/?&$filter=status eq 1 and lastOnDate isn null and ${queryLocal}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);

    if (!entityData.current || !entityData.current.result.length || !Array.isArray(entityData.current.result)) return false;

    return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    <div className="flex-container portalHubUtils paperOrder width-100">
      <div className="flex-item flex-grow-1 portalHubUtils paperLeft pad-5">
        <UILIB.Paper className='flex-item row width-100'>
          <div className='flex-item mar-b20'><h3>Top 20 Last Online</h3></div>
          <UILIB.TableNew
            name='PortalRequestLookup'
            className='small'
            overflowX='auto'
            overflowY='hidden'
            header={headerData}
            remoteQuery={getTableData}
            remoteRender={[remoteRender]}
            defaultSettings={tablePageDefaults} />
        </UILIB.Paper>
      </div>
      <div className="flex-item portalHubUtils paperRight pad-5">
        <UILIB.Paper className='flex-container column width-100'>
          <div className='flex-item mar-b20'><h3>Tools</h3></div>
          {createAutoComplete('Search for Customer', 'customerSearch', false, 'Seach for Customer', getCompaniesPortalUsers, (ev) => actionOpenPortal(ev), false, 'max-height-55-px pad-b20', 'max-height-30-px', null, 'width-100 fullBorder border-colour-grey-4')}
          <div className='mar-l5 mar-b20'>
            <p>Note, search results are restricted to those that you manage</p>
          </div>
          <div className="flex-container row start wrap evenly width-100">
            {createButton('Request a Portal', 'requestPortal', 'Request Portal', false, false, () => actionRequestPortal(), null, 'flex-item flex-grow-1')}
            {createButton('Demo Portal', 'demoPortal', 'Open Demo Portal', false, false, () => actionDemoPortal(), null, 'flex-item flex-grow-1', null, null, ' colour background darkPurple ')}
          </div>
        </UILIB.Paper>
      </div>
    </div>

  </Fragment>
}
