
import { Fragment } from 'react';

export default function NoteContainer(props) {

  const backgroundColour = (props.background) ? props.background : 'colour background grey';
  const textColour = (props.text) ? props.text : 'colour text black';

  if(!props.content) return <Fragment></Fragment>;

  return <Fragment>
    <div className={`flex-container row container container-banner flex-grow-1 width-100 ${backgroundColour} pad-2 ${props.className}`}>
      {(props?.title) && <div className={`flex-item start width-100 pad-t2 pad-l3 pad-r3 text-14 ${props.classNameTitle}`}><b className={textColour}>{props.title}</b></div>}
      <div className={`flex-item start width-100 overflow-hidden pad-5 ${textColour} text-13 ${props.classNameContent}`}>{props.content}</div>
    </div>
  </Fragment>
}
