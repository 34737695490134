import { useState, Fragment } from 'react';
import UILIB from 'components';
import { createSelectInput, createMultiSelectInput } from 'classes/controls/formControls';


export default function AwaitingFiltersDrawer ({ data, onFinished, sync }) {

  const [filtersLocal, setFiltersLocal] = useState(data.filters);

  const handleOptionsChanged = (event, name) => {
    if (filtersLocal[name] === event.target.name) {
      setFiltersLocal({ ...filtersLocal, [name]: '' });
    } else {
      setFiltersLocal({ ...filtersLocal, [name]: event.target.value });
    }
  };

  const handleButtonsChanged = (event) => {
    if (filtersLocal.slaButton === event.target.name) {
      setFiltersLocal({ ...filtersLocal, slaButton: '' });
    } else {
      setFiltersLocal({ ...filtersLocal, slaButton: event.target.name });
    }
  }

  const syncAndClose = () => {
    data.setFilters({ ...data, ...data.filters = filtersLocal });
    sync.set(!sync.get);
    onFinished();
  }

  return <Fragment>         
    <h2>Filters</h2>
    <div className="mar-b15">
      <h4 className="mar-t15">SLA</h4>
      <div className="flex-container">
        <UILIB.Button className={"mar-l5 mar-r5 mar-b5 small " + (filtersLocal.slaButton !== "NearSLA" ? "grey" : "yellow")} name="NearSLA" value="Near SLA" onClick={(ev) => handleButtonsChanged(ev)} />
        <UILIB.Button className={"mar-l5 mar-r5 mar-b5 small " + (filtersLocal.slaButton !== "OverSLA" ? "grey" : "pink")} name="OverSLA" value="Over SLA" onClick={(ev) => handleButtonsChanged(ev)} />
      </div>
    </div>
    <div className="flex-container row start width-100 mar-3">
      {createMultiSelectInput('', 'selectRegion', false, filtersLocal.regionIds, data.regions, 'All Regions', (ev) => handleOptionsChanged(ev), null, null, null, null, null)}
      {createSelectInput('', 'selectDataSource', false, filtersLocal.staff, data.staff, (ev) => handleOptionsChanged(ev, 'staff'), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
    </div>
    <div className="flex-container row start width-100 mar-3 mar-t20">
      <UILIB.Button name="Apply Filters" value="Apply Filters" onClick={() => syncAndClose()} />
    </div>
  </Fragment>   
}
