import { Fragment } from 'react';
import UILIB from 'components';


export default function NotesDrawer({ entity }) {
  const auditGroups = Array.from(
    new Set(
      entity.userbaseMigrationEvent.map(
        (x) => `${x.migratedTypeIdTxt}_${x.createdByTxt}_${x.createdAt}`
      )
    )
  ).map((groupKey) => {
    const [type, createdByTxt, createdAt] = groupKey.split('_');

    const filteredEvents = entity.userbaseMigrationEvent.filter(
      (event) =>
        event.migratedTypeIdTxt === type &&
        event.createdByTxt === createdByTxt &&
        event.createdAt === createdAt
    );

    return { type, createdByTxt, createdAt, data: filteredEvents };
  });

  return (
    <Fragment>
      <div className="flex-container row padding-4">
        {!entity.userbaseMigrationEvent || !entity.userbaseMigrationEvent.length ? (
          <div className="text-14 font-weight-bold">
            No audit history available for this customer.
          </div>
        ) : (
          <div className="width-100">
            <div className="text-14 font-weight-bold">
              Audit history for {entity.customerName}
            </div>

            {auditGroups.map((group, index) => (
              <UILIB.AuditContainerGrouped key={index} type={group.type} data={group.data} />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
}
