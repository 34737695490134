import { useEffect, useState } from 'react';
import UILIB from 'components'

const headerData = [
    {
        label: "Include",
        value: "ticked",
        align: "left",
        type: 'string',
        width: "2%"
    },
    {
      label: "Quantity",
      value: "qty",
      align: "left",
      type: 'number',
      width: "5%"
    },
    {
      label: "Description",
      value: "description",
      align: "left",
      type: 'string',
      width: "24%"
    },
    {
      label: "Current Floor",
      value: "currentFloor",
      align: "left",
      type: 'string',
      width: "5%"
    },
    {
      label: "Install Floor",
      value: "installFloor",
      align: "left",
      type: 'string',
      width: "5%"
    },
    {
      label: "Serial Number",
      value: "serial",
      align: "left",
      type: 'string',
      width: "12%"
    },
    {
      label: "IP Address",
      value: "ipAddress",
      align: "left",
      type: 'string',
      width: "5%"
    },
    {
        label: "Location",
        value: "location",
        align: "left",
        type: 'string',
        width: "10%"
    },
    {
        label: "Start Mono",
        value: "estimatedVolume",
        align: "left",
        width: "10%"
    },
    {
      label: "Start Colour",
      value: "estimatedVolumeColour",
      align: "left",
      width: "10%"
    },
    {
      label: "Delete",
      value: "deleteRow",
      align: "left",
      width: "1%"
    }
]


export default function BlockDeviceTable({ data, setFormData }) {
    const [tableData, setTableData] = useState([]);

    function renderTable() {
        let tableData = [];

        data.devices.forEach((row, index) => {

          tableData.push({
              "ticked": {
                value: <UILIB.Checkbox
                        name="ticked"
                        checked={row.ticked}
                        onChange={(event) => ticked(event, row.filterId, row)} 
                        disabled={data.processStage > 3 ? true : false}
                      />,
                raw: row.ticked,
                tdStyle: { backgroundColor: "#FFFFFF" }
              },
              "qty": {
                  value: <UILIB.TextInput
                          name="qty"
                          className="textInput input"
                          placeholder="0" 
                          type="number" 
                          inputmode="numeric" 
                          value={row.quantity}
                          onChange={(event) => updateFieldEvent('quantity', event, row.filterId, row)}
                          disabled={data.processStage > 3 ? true : false}
                        />,
                  raw: row.quantity,
                  tdStyle: { backgroundColor: "#FFFFFF" }
              },    
              "location": {
                  value: <UILIB.TextInput
                          name="location"
                          className="textInput input"
                          placeholder="Enter Location" 
                          type="text"
                          value={row.deviceLocationn}
                          onChange={(event) => updateFieldEvent('specificDeviceLocation', event, row.filterId, row)}
                          disabled={data.processStage > 3 ? true : false}
                        />,
                  raw: row.deviceLocationn,
                  tdStyle: { backgroundColor: "#FFFFFF" }
              },
              "description": {
                value: <UILIB.TextInput
                        name="description"
                        className="textInput input"
                        placeholder="Enter Description" 
                        type="text"
                        value={row.itemDescription}
                        onChange={(event) => updateFieldEvent('description', event, row.filterId, row)}
                        disabled={data.processStage > 3 ? true : false}
                      />,
                raw: row.itemDescription,
                tdStyle: { backgroundColor: "#FFFFFF" }
              },
              "currentFloor": {
                value: <UILIB.TextInput
                        className="textInput input"
                        placeholder="0" 
                        type="number" 
                        inputmode="numeric" 
                        value={row.currentFloor}
                        onChange={(event) => updateFieldEvent('currentFloor', event, row.filterId, row)}
                        disabled={data.processStage > 3 ? true : false}
                      />,
                raw: row.currentFloor,
                tdStyle: { backgroundColor: "#FFFFFF" }
              },
              "installFloor": {
                value: <UILIB.TextInput
                        className="textInput input"
                        placeholder="0" 
                        type="number" 
                        inputmode="numeric" 
                        value={row.installFloor}
                        onChange={(event) => updateFieldEvent('installFloor', event, row.filterId, row)}
                        disabled={data.processStage > 3 ? true : false}
                      />,
                raw: row.installFloor,
                tdStyle: { backgroundColor: "#FFFFFF" }
              },
              "ipAddress": {
                value: <UILIB.TextInput
                        className="textInput input"
                        placeholder="0.0.0.0" 
                        type="number" 
                        inputmode="numeric" 
                        value={row.ipAddress}
                        onChange={(event) => updateFieldEvent('ipAddress', event, row.filterId, row)}
                        disabled={data.processStage > 3 ? true : false}
                      />,
                raw: row.instaipAddressllFloor,
                tdStyle: { backgroundColor: "#FFFFFF" }
              },
              "serial": {
                  value:  <UILIB.TextInput
                              name="serial"
                              className="textInput input"
                              placeholder="Enter Serial" 
                              type="text"
                              value={row.itemSerialNumber}
                              onChange={(event) => updateFieldEvent('serialNumber', event, row.filterId, row)}
                              disabled={data.processStage > 3 ? true : false}
                            />,
                  raw: row.itemSerialNumber,
                  tdStyle: { backgroundColor: "#FFFFFF" }
              },
              "estimatedVolume": {
                value: <UILIB.TextInput 
                          className="textInput input"
                          placeholder="0" 
                          type="number" 
                          inputmode="numeric" 
                          value={(row.readings.length && row.readings[row.readings.findIndex((obj => Number(obj.meterType) === 1))].meterReading) || 0} 
                          onChange={(event) => updateReading(0, event, row.filterId, row)}
                          disabled={data.processStage > 3 ? true : false}
                        />,
                raw: "",
                tdStyle: { backgroundColor: "#FFFFFF" }
              },
              "estimatedVolumeColour": {
                value: <UILIB.TextInput 
                            className="textInput input"
                            placeholder="0" 
                            type="number" 
                            inputmode="numeric" 
                            value={(row.readings.length && row.readings[row.readings.findIndex((obj => Number(obj.meterType) === 2))].meterReading) || 0} 
                            onChange={(event) => updateReading(1, event, row.filterId, row)}
                            disabled={data.processStage > 3 ? true : false}
                          />,
                raw: "",
                tdStyle: { backgroundColor: "#FFFFFF" }
              },
              "deleteRow": {
                value: <div><span className="icon icon-trash primary" style={{ cursor: "pointer", paddingLeft: 10  }} onClick={() => { deleteParent(row.filterId, row, index) }}></span></div>,
              },
          })

          row.attachments.forEach((child, childIndex) => {

            tableData.push({
              "ticked": {
                      value: <UILIB.Checkbox
                        name="ticked"
                        checked={child.ticked}
                        onChange={(event) => isTickedChild(event, child.filterId, row)} 
                        disabled={data.processStage > 3 ? true : false}
                      />,
                  raw: child.ticked,
                  tdStyle: { backgroundColor: "#EEEEEE" }
              },
              "qty": {
                  value: <UILIB.TextInput
                          className="textInput input"
                          placeholder="0" 
                          name="qty"
                          type="text"
                          value={child.quantity}
                          onChange={(event) => updateFieldChild('quantity', event, child.filterId, row)}
                          disabled={data.processStage > 3 ? true : false}
                        />,
                  raw: child.quantity,
                  tdStyle: { backgroundColor: "#EEEEEE" }
              },   
              "description": {
                value: <div style={{ display: "flex" }}>
                          <span style={{ display: "flex", verticalAlign: "center", width: "10px", paddingTop: 6 }}>+</span>
                          <UILIB.TextInput
                              className="textInput input"
                              name="description"
                              outerStyle={{ width: "100%" }}
                              style={{ align: "left", height: "12px", width: "100%" }}
                              placeholder="Enter Product Name"
                              value={child.description}
                              onChange={(event) => updateFieldChild('description', event, child.filterId, row)}
                              disabled={data.processStage > 3 ? true : false}
                          />
                        </div>,
                raw: child.description,
                tdStyle: { backgroundColor: "#EEEEEE" }
              }, 
              "currentFloor": {
                value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>{"N/A"}</div>,
                tdStyle: { backgroundColor: "#EEEEEE" }
              },
              "installFloor": {
                value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>{"N/A"}</div>,
                tdStyle: { backgroundColor: "#EEEEEE" }
              },
              "ipAddress": {
                value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>{"N/A"}</div>,
                tdStyle: { backgroundColor: "#EEEEEE" }
              },
              "location": {
                  value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>{"N/A"}</div>,
                  tdStyle: { backgroundColor: "#EEEEEE" }
              },
              "serial": {
                  value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>{"N/A"}</div>,
                  tdStyle: { backgroundColor: "#EEEEEE" }
              },
              "estimatedVolume": {
                value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>{"N/A"}</div>,
                tdStyle: { backgroundColor: "#EEEEEE" }
              },
              "estimatedVolumeColour": {
                value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}>{"N/A"}</div>,
                tdStyle: { backgroundColor: "#EEEEEE" }
              },
              "deleteRow": {
                value: <div className="text-small" style={{ display: "flex", alignItems: "center" }}></div>,
                tdStyle: { backgroundColor: "#EEEEEE" }
              }
            })
          })
        })
      setTableData(tableData)
    }


    useEffect(() => {
        renderTable();
    }, [data]);


    const ticked = (event, index, row) => {
        const dataNew = JSON.parse(JSON.stringify(data));
        dataNew.devices[dataNew.devices.findIndex((obj => obj.filterId === row.filterId))].ticked = event.target.checked;
        dataNew.devices[dataNew.devices.findIndex((obj => obj.filterId === row.filterId))].attachments.forEach(c => { c.ticked = event.target.checked })
        setFormData(dataNew);
    }

    const isTickedChild = (event, index, row) => {
      const dataNew = JSON.parse(JSON.stringify(data));
      let device = dataNew.devices[dataNew.devices.findIndex(obj => obj.filterId === row.filterId)]
      device.attachments[device.attachments.findIndex(obj => obj.filterId === index)].ticked = event.target.checked;
      setFormData(dataNew);
    }

    const updateFieldEvent = (fieldName, event, index, row) => {
        const dataNew = JSON.parse(JSON.stringify(data));
        dataNew.devices[dataNew.devices.findIndex(obj => obj.filterId === row.filterId)][fieldName] = event.target.value;
        setFormData(dataNew);
    }

    const updateFieldChild = (fieldName, event, index, row) => {
      const dataNew = JSON.parse(JSON.stringify(data));
      let device = dataNew.devices[dataNew.devices.findIndex(obj => obj.filterId === row.filterId)]
      device.attachments[device.attachments.findIndex(obj => obj.filterId === index)][fieldName] = event.target.value;
      setFormData(dataNew);
    }

    const updateReading =  (fieldName, event, index, row) => {
      const dataNew = JSON.parse(JSON.stringify(data));
      dataNew.devices[dataNew.devices.findIndex(obj => obj.filterId === row.filterId)].readings[fieldName].meterReading = event.target.value;
      setFormData(dataNew);
    }

    const deleteParent = (filterId, row, index) => {
      const dataNew = JSON.parse(JSON.stringify(data));
      dataNew.devices = dataNew.devices.filter(d => d.filterId !== filterId)
      setFormData(dataNew);
    }


    return (
      <div className="mar-t15">
        <UILIB.Table
          className="small"
          header={headerData}
          data={tableData}
          height={"100%"}
        />
      </div>
    );
}