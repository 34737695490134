
import { Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { actionOpenDrawer, tableContainer } from '../functions';

export default function OptionsContainer({ row, warehouses, dispatch, sendLogisticsColour, editSopColour, handleSyncLogistics, localRender, setLocalRender }) {

  const location = useLocation();
  const enableDrawer = (Boolean(String(location.pathname).toLowerCase() === '/sophub'));

  const fn_sendLogistics = actionOpenDrawer.bind(null, 'SendToLogisticsDrawer', { ...row, warehouses: warehouses }, dispatch, enableDrawer, handleSyncLogistics, '800px');
  const fn_editSop = actionOpenDrawer.bind(null, 'EditSopDrawer', row, dispatch, enableDrawer, { get: localRender, set: setLocalRender }, '500px');

  const logisticsTool = (row.warehouseEmailSent === false) ? 'Send To Logistics' : 'Send To Logistics - Sent';
  
  return <Fragment>
          <div className="flex-container row width-100 height-100">
            <div className={`flex-item start width-100`}>{tableContainer(fn_sendLogistics, 'icon-envelope', logisticsTool, sendLogisticsColour, true)}</div>
            <div className={`flex-item start width-100`}>{tableContainer(fn_editSop, 'icon-pencil', 'Edit Details', editSopColour, true)}</div>
          </div>
        </Fragment>
}
