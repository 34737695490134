import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components';
import { useDispatch } from 'react-redux'
import { setFleetHubReportSelected } from 'store/actions'
import FleetMenu from './shared/fleetMenu';


export default function FleetHub(props) {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);    

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      dispatch(setFleetHubReportSelected(null))
      await props.permissions.generalAccess();
      setLoading(false);
    }
    asyncEffect();
  }, [])

 if(loading) return <UILIB.Loading type={3} />
 
 return <Fragment>
          <FleetMenu />
      </Fragment>
 
}