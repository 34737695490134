import { useState, Fragment } from 'react';
import NotesView from './notesView';
import NotesAdd from './notesAdd';

export default function NotesDrawer({ entity, allowAdd, source, sync }) 
{  

  const [addNote, setAddNote] = useState(false);

  return <Fragment>
    {!addNote && <NotesView entity={entity} setAddNote={setAddNote} allowAdd={allowAdd} />}
    {addNote && <NotesAdd entity={entity} setAddNote={setAddNote} source={source} sync={sync} />}
  </Fragment>
}
