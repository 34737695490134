const initialState = {
  drawer: {
    open: false,
    content: "",
    width: "400px",
  },
  pagePreferences: {
    consumablesHub: {
      regions: [],
      tab: 0
    },
    portalHub: {
      paging: {}
    }
  },
  momentSession: "",
  sessionExpire: false,
  sessionTimeout: 60,
  permissions: [],
  accessToken: "",
  refreshToken: "",
  account: ""
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_ACCOUNT': {
            return {
                ...state, account: action.payload
            }
        }

        case 'SET_REFRESH_TOKEN': {
            return {
                ...state, refreshToken: action.payload
            }
        }

        case 'SET_ACCESS_TOKEN': {
          return {
              ...state, accessToken: action.payload
          }
        }

        case 'SET_MOMENT_SESSION': {
          return {
              ...state, momentSession: action.payload
          }
        }

        case 'SET_DRAWERCONTENT': {
            document.body.style.overflow = 'auto';
            if (!action.payload.width) action.payload.width = "400px";

            return {
                ...state, drawer: action.payload
            }
        }

        case 'SET_PERMISSIONS': {
          return {
              ...state, permissions: action.payload
          }
        }

        case 'SET_RELEASEVERSION': {
          return {
              ...state, releaseVersion: action.payload
          }
        }

        case 'SET_FLEETREPORTSELECTED': {
          return {
              ...state, fleetReportSelected: action.payload
          }
        }

        case 'SET_SESSION_EXPIRE': {
          return {
              ...state, sessionExpire: action.payload
          }
        }

        case 'SET_PAGE_PREFERENCES': {
          return {
            ...state, pagePreferences: { ...state.pagePreferences, ...action.payload }
          }
        }

        case 'LOGOUT': {
          return {
            ...state, ...initialState
          }
        }

        default: return state;
    }
}