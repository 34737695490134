import { Fragment } from 'react';

export default function Attachments({ row })
{
    return <Fragment>
        <div className="flex-container row width-100">
            {row.attachments.map((x, i) => { return <div key={`at_${i}`} className={`flex-item start width-100 refurbHub attachments-row`}>
              {`${x.quantity}x ${x.description}`}
            </div>})}
        </div>
    </Fragment>
}