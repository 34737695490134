import UILIB from 'components';
import moment from "moment-business-time";
import { setDrawer } from 'store/actions';
import Modules from './Modules';


export function setFilterVars(filters) {

  const datesCreated = (!filters.datesCreated.disabled) ? ` ( createdAt gte ${moment(filters.datesCreated.startDate).format('YYYY-MM-DD 00:00')} and createdAt lte ${moment(filters.datesCreated.endDate).format('YYYY-MM-DD 23:59')} ) ` : null;

  const regions = (filters.regionIds.length) ? ` salesRegion in ${filters.regionIds.join(',')} ` : null;
  const staff = (filters.staff !== -1) ? ` activeDirectoryObjectGuid eq ${filters.staff} ` : null;

  const arrayData = [datesCreated, regions, staff].filter(x => x);

  if(filters.button === 'Active') {
    return [...arrayData, ' ( source eq SOP and approved eq 0 and status eq 0 and isActive eq 1 and archived eq 0 and processStage eq 0 and dynamicsStateCode ne 2 and ordered eq 0 ) '].join(' and ');
  }
  if(filters.button === 'Ordered') {
    return [...arrayData, ' ( source eq SOP and approved eq 0 and status eq 0 and isActive eq 0 and archived eq 0 and processStage eq 0 and dynamicsStateCode ne 2 and ordered eq 1 ) '].join(' and ');
  }
  if(filters.button === 'Closed') {
    return [...arrayData, ' ( source eq SOP and approved eq 0 and status eq 0 and isActive eq 0 and archived eq 0 and processStage eq 0 and dynamicsStateCode ne 2 and ordered eq 0 ) '].join(' and ');
  }
  if(filters.button === 'Archived') {
    return [...arrayData, ' ( source eq SOP and approved eq 0 and status eq 0 and archived eq 1 ) '].join(' and ');
  }
  if(filters.button === 'Lost') {
    return [...arrayData, ' ( source eq SOP and approved eq 0 and archived eq 0 and dynamicsStateCode eq 2 ) '].join(' and ');
  }
  return [...arrayData, ' ( source eq SOP and approved eq 0 and processStage lt 2 ) '].join(' and ');
}

export function setDraftFilterVars() { 
  return ' ( source eq SOP and status eq 0 and processStage eq 1 and isActive eq 1 and approved eq 0 and dynamicsStateCode ne 2 ) ';
}

export function setAdminFilterVars() { 
  return ' ( source eq SOP ) ';
}

export function setSalesReportFilterVars(filters) { 
  return ` ( invoiceDate gte ${moment(filters.startDate).format('YYYY-MM-DD 00:00:00')} and invoiceDate lte ${moment(filters.endDate).format('YYYY-MM-DD 23:59:59')} and approved eq 1 ) `;
}

export function setMachineReportFilterVars(filters) { 
  return ` ( invoiceDate gte ${moment(filters.startDate).format('YYYY-MM-DD 00:00:00')} and invoiceDate lte ${moment(filters.endDate).format('YYYY-MM-DD 23:59:59')} and invoiceDate isn null ) `;
}

export function setTotalSalesReportFilterVars(filters) { 
  return ` ( submittedDate gte ${moment(filters.startDate).format('YYYY-MM-DD 00:00:00')} and submittedDate lte ${moment(filters.endDate).format('YYYY-MM-DD 23:59:59')} and approved eq 1 ) `;
}

export function setPSSalesReportFilterVars(filters) { 
  return ` ( submittedDate gte ${moment(filters.startDate).format('YYYY-MM-DD 00:00:00')} and submittedDate lte ${moment(filters.endDate).format('YYYY-MM-DD 23:59:59')} and approved eq 1 ) `;
}


export function actionOpenDrawer(type, entity, dispatch, enabled, sync, width) {
  if(!enabled) return false;
  const selector = (type, data) => {
    if(type === 'QuoteFiltersDrawer') return <Modules.QuoteFiltersDrawer data={data} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if(type === 'MachineReportDrawer') return <Modules.MachineReportDrawer data={data} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if(type === 'PSSalesReportDrawer') return <Modules.PSSalesReportDrawer data={data} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if(type === 'SalesReportDrawer') return <Modules.SalesReportDrawer data={data} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
    if(type === 'TotalSalesReportDrawer') return <Modules.TotalSalesReportDrawer data={data} onFinished={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} />
  };
  dispatch(setDrawer({ 
    open: true, 
    width: width,
    content: selector(type, entity)
  }))
}

export function getStatusChip(row) {
  if (row.orderStatus === 'Draft') {
    return <UILIB.Chip toolTip="Awaiting Invoice" className="flex-container wrap table colour background-8 orange text-small width-100" value="Draft" />;
  }
  if (row.orderStatus === 'Ordered') {
    return <UILIB.Chip toolTip="Awaiting Invoice" className="flex-container wrap table colour background-8 orange text-small width-100" value="Ordered" />;
  }
  if (row.orderStatus === 'Closed') {
    return <UILIB.Chip toolTip="Invoiced" className="table colour background-8 grey text-small width-100" value="Closed" />;
  }
  if (row.orderStatus === 'Archived') {  
    return <UILIB.Chip toolTip="Archived" className="table colour background-8 purple text-small width-100" value="Archived" />;
  }
  if (row.orderStatus === 'Lost') {
    return <UILIB.Chip toolTip="Invoiced" className="table colour background-8 red text-small width-100" value="Lost" />;
  }
  return <UILIB.Chip toolTip="Active" className="table colour background-8 green text-small width-100" value="Active" />;  
}

export function calcResetEnable(filters, defaultfilters) {
  return Boolean(filters.button === 'Active' && JSON.stringify(defaultfilters) === JSON.stringify(filters));
};

export function getDefaultFilters() {
  return {
    button: "Active", 
    quotes: true, 
    ordered: false, 
    closed: false, 
    archived: 0,
    regionIds: [],
    regions: [],
    staff: -1,
    machineReportDrawer: {
      startDate: moment().startOf('month').startOf('day').format('YYYY-MM-DD').toString(),
      endDate: moment().endOf('month', 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD').toString()
    },
    pSSalesReportDrawer: {
      startDate: moment().startOf('month').startOf('day').format('YYYY-MM-DD').toString(),
      endDate: moment().endOf('month', 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD').toString()
    },
    totalSalesReportDrawer: {
      startDate: moment().startOf('month').startOf('day').format('YYYY-MM-DD').toString(),
      endDate: moment().endOf('month', 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD').toString(),
      supplier: 'All',
      suppliers: ['All', 'Canon', 'Kyocera']
    },
    salesReportDrawer: {
      startDate: moment().startOf('month').startOf('day').format('YYYY-MM-DD').toString(),
      endDate: moment().endOf('month', 'YYYY-MM-DD').endOf('day').format('YYYY-MM-DD').toString(),
      supplier: 'All',
      suppliers: ['All', 'Canon', 'Kyocera']
    },
    datesCreated: {
      startDate: moment().subtract(10,"year").format('YYYY-MM-DD').toString(),
      endDate: moment().format('YYYY-MM-DD').toString(),
      disabled: true
    },
    resetTableFilters: false
  }
}