import { useState, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import Axios from 'classes/axios';
import UILIB from 'components';
import Modules from '../Modules';
import { formatDateTime } from 'classes/format';
import { setFilterVarsStockRequests, tableContainer, actionOpenDrawer, stockRequestChip, getMissingStoreColour, handleSiteStockCustomerClicked, handleSiteStockSerialClicked } from '../../functions'

export default function ArchivedStocksRequestsTable({remoteRender, filter, filters, entityData}) {
  
  const dispatch = useDispatch();
  const invalidStoreIds = useRef({});
  const groupSelect = useRef({});
  const [localRender, setLocalRender] = useState(false);
  
  const tablePageDefaults = { paging: { pages: [10, 20, 50, 100, 200], pageSelected: 20, limit: 20, offset: 0, orderBy: 'dateAdded', orderDir: 'DESC' } };
    const headerData = [
        { label: '', value: 'selected', type: '', filtering: false, minWidth: 20, maxWidth: 20 },
        { label: 'Request #', value: 'requestID', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
        { label: 'customer Name', value: 'customerName', type: 'string', filtering: true, minWidth: 280 },
        { label: 'Job #', value: 'jobNumber', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
        { label: 'Requested By', value: 'staffName', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
        { label: 'Date', value: 'dateAdded', type: 'date', filtering: true, minWidth: 100, maxWidth: 100 },
        { label: 'Serial #', value: 'serialNo', type: 'string', filtering: true, minWidth: 116, maxWidth: 116 },
        { label: 'Group', value: 'groupID', type: 'string', filtering: true, minWidth: 116, maxWidth: 116 },
        { label: 'Actioned By', value: 'processedByTxt', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
        { label: 'Actioned At', value: 'updatedAtTxt', type: 'date', filtering: true, minWidth: 80, maxWidth: 80 },
        { label: 'Black', value: 'black', type: 'string', filtering: false, minWidth: 60, maxWidth: 60 },
        { label: 'Cyan', value: 'cyan', type: 'string', filtering: false, minWidth: 60, maxWidth: 60 },
        { label: 'Magenta', value: 'magenta', type: 'string', filtering: false, minWidth: 60, maxWidth: 60 },
        { label: 'Yellow', value: 'yellow', type: 'string', filtering: false, minWidth: 60, maxWidth: 60 },
        { label: 'Waste', value: 'waste', type: 'string', filtering: false, minWidth: 60, maxWidth: 60 },
        { label: 'Status', value: 'statusChip', type: 'string', filtering: true, minWidth: 50, maxWidth: 50 },
        { label: 'Notes', value: 'notes', type: 'string', filtering: false, minWidth: 30, maxWidth: 30 }
      ];

      async function constructTable(tonderData) {

        const tableData = tonderData.map(row => {
          const b_hasNotes = Boolean(row?.notes?.length);
          const notesColour = (b_hasNotes) ? 'colour background-6 red' : 'colour background lightGrey';
          const fn_notes = actionOpenDrawer.bind(null, 'ViewRequestNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });
          const customerContainer = handleSiteStockCustomerClicked(row, null);
          const bgColour = getMissingStoreColour(row, row.groupID);
    
          const groupSelectControl = <UILIB.Select 
            disabled={true}
            className="tableFilter mar-b0" 
            outerClassName="tableFilter mar-b0" 
            value={Number(row.groupID)} 
            data={groupSelect.current[row.requestID]} />
    
          return {
            selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox disabled={true} type='checkbox' />} />, class: bgColour },
            requestID: { value: row.requestID, raw: row.requestID, class: bgColour },
            groupID: { value: groupSelectControl, raw: row.groupID, class: bgColour },
            staffName: { value: row.staffName, raw: row.staffName, class: bgColour },
            jobNumber: { value: row.jobNumber, raw: row.jobNumber, class: bgColour },
            customerName: { value: customerContainer, raw: row.customerName, class: bgColour },
            dateAdded: { value: formatDateTime(row.dateAdded), raw: formatDateTime(row.dateAdded), class: bgColour },
            serialNo: { value: handleSiteStockSerialClicked(row, null), raw: row.serialNo, class: bgColour },
            black: { value: <Modules.TonerContainer bgCol="grey" data={row} levelName="blackLev" stockName="black" maxName="blackMin" />, raw: '' },
            cyan: { value: <Modules.TonerContainer bgCol="cyan" data={row} levelName="cyanLev" stockName="cyan" maxName="CyanMin" />, raw: '' },
            magenta: { value: <Modules.TonerContainer bgCol="magenta" data={row} levelName="magentaLev" stockName="magenta" maxName="magentaMin" />, raw: '' },
            yellow: { value: <Modules.TonerContainer bgCol="yellow" data={row} levelName="yellowLev" stockName="yellow" maxName="yellowMin" />, raw: '' },
            waste: { value: <Modules.TonerContainer bgCol="waste" data={row} levelName="wasteLev" stockName="waste" maxName="wasteMin" />, raw: '' },
            statusChip: { value: stockRequestChip(row.statusChip), raw: row.statusChip },
            notes: { value: tableContainer(fn_notes, 'icon-pencil', (b_hasNotes) ? 'View Notes' : 'Notes Unavailable', notesColour, true), raw: b_hasNotes },
            processedByTxt: { value: row.processedByTxt, raw: row.processedByTxt, class: bgColour },
            updatedAtTxt: { value: formatDateTime(row.updatedAtTxt), raw: formatDateTime(row.updatedAtTxt), class: bgColour }
          }
        })
    
        return tableData;
      }
    
      const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
    
        const expandArray = ['notes', 'stores'];
    
        const queryLocal = (query !== null) ? query : '';
        const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
        const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
        const filterButtons = setFilterVarsStockRequests(filter, filters);

        entityData.current = await Axios.get(`/entities/serviceAdmin_siteStoreRequests/getSummary/?&$filter=${filterButtons} ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    
        if (!entityData.current || !entityData.current.result.length || !Array.isArray(entityData.current.result)) return false;
    
        if(entityData.current && entityData.current.result) {
          entityData.current.result.forEach(x => {
            groupSelect.current[x.requestID] = [
              { value: -1, label: 'Please Select' },
              ...x.stores.map(m => { return { value: Number(m.rowID), label: m.groupName } })] });
        }
    
        invalidStoreIds.current = {};
        entityData.current.result.forEach(x => { invalidStoreIds.current[x.requestID] = (Number(x.storeCount) === 0 || !x.groupID || x.groupID === -1); }) 
    
        return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
      }

      return (
        <Fragment> 
            <UILIB.TableNew
                name="SiteStocksRequestsArchived"
                className='small'
                overflowX='auto'
                overflowY='hidden'
                header={headerData}
                localQuery={() => constructTable((entityData.current.result && entityData.current.result.length) ? entityData.current.result : [])}
                localRender={[localRender]}
                remoteQuery={getTableData}
                remoteRender={[remoteRender, filter, filters]}
                defaultSettings={tablePageDefaults} 
            />
        </Fragment>
    );
}