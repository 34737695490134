import { useState, useRef, memo, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { formatDateTime } from 'classes/format';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DealLinkTableCell from '../../shared/dealLink';
import { getYesNo, getApprovedRowColour, getNotesColour, getStatusChip, actionOpenDrawer, tableContainer, calcResetEnable, setApprovedFilterVars, getLogisticsStatus } from '../functions';
import Modules from '../Modules';

const Approved = memo(({ permissionsObject, localPermissions, permissions, location, route, logistics, regions, sopStaff, staff, warehouse, filters, defaultFilters, setFilters }) => {
  const headerData = [
    { label: "#", value: "finalDealNo", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Opp #", value: "opportunityNumber", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Account #", value: "accountNo", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Customer", value: "companyName", type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
    { label: "Sales Person / Region", value: "salesPersonSalesRegion", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "SOP Owner", value: "sopOwnerFullName", type: "string", filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Deal Type", value: "salesType", type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Payment Type", value: "leaseorcash", type: "string", filtering: true, minWidth: 90, maxWidth: 90 },
    { label: "Value", value: "valueStats", type: 'string', split: ['invoice', 'GP'], filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Submitted", value: "dateSubmittedCast", type: 'date', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Approved", value: "approvalDateCast", type: 'date', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Invoiced", value: "invoiced", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Days Active", value: "slaApproved", type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: "Logistics Status", value: "engStatusTxt", type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Status", value: "sopStatus", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: "Notes", value: "salesNotes", filtering: false, minWidth: 35, maxWidth: 35, filterArray: getYesNo() },
    { label: "Options", value: "options", filtering: false, minWidth: 35, maxWidth: 35, filterArray: getYesNo() }];

  const dispatch = useDispatch();
  const history = useHistory();
  const entityData = useRef({});
  const [render, setRender] = useState(false);
  const [localRender, setLocalRender] = useState(false);
  const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'finalDealNo', orderDir: 'DESC' } };
  const selectData = { logistics: logistics, regions: regions, staff: staff, sopStaff: sopStaff };


  const handleButtonsChanged = (buttonName) => {
    const newTableFilter = { ...filters };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = null;
    } else {
        newTableFilter.button = buttonName;
    }
    setFilters(newTableFilter);
  };

  const handleResetfilters = () => {
    setFilters(defaultFilters);
    setRender(!render);
  };

  const handleSyncLogistics = (row) => {
    const found = entityData.current.result.findIndex(x => Number(x.dealID) === Number(row.dealID));
    entityData.current.result[found] = row;
    setLocalRender(!localRender);
  };

  async function constructTable(dealData) {

    const permissionDealLink = permissionsObject('orderLink', localPermissions.current.permissions, permissions, location.pathname);

    const tableData = dealData.map(row => {
   
      const b_hasNotes = Boolean(row.dealNote && row.dealNote.length);
      const salesNotesColour = getNotesColour(row);

      const bgColour = getApprovedRowColour(row);
      const editSopColour = 'colour lightGrey';
      
      const sendLogisticsColour = (row.warehouseEmailSent) ? 'colour colour background-4 green' : 'colour colour background-4 red';

      const fn_SalesNotes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender }, '400px');

      const options = <Modules.OptionsContainer
        row={row}
        warehouses={warehouse}
        dispatch={dispatch}
        handleSyncLogistics={handleSyncLogistics}
        sendLogisticsColour={sendLogisticsColour}
        editSopColour={editSopColour}
        localRender={localRender}
        setLocalRender={setLocalRender} />
      
      return {
        finalDealNo: DealLinkTableCell(row, history, route, permissionDealLink.entityEnable, bgColour, true, 'class'),
        opportunityNumber: { value: row.opportunityNumber, raw: row.opportunityNumber, class: bgColour },
        accountNo: { value: row.accountNo, raw: row.accountNo, class: bgColour },
        groupCode: { value: row.groupCode, raw: row.groupCode, class: bgColour },
        salesType: { value: row.salesType, raw: row.salesType, class: bgColour },
        companyName: { value: row.companyName, raw: row.companyName, class: bgColour },
        salesPersonSalesRegion: { value: <Modules.SalesContainer row={row} />, raw: row.salesPersonSalesRegion, class: bgColour },
        sopOwnerFullName: { value: row.sopOwnerFullName, raw: row.sopOwnerFullName, class: bgColour },
        leaseorcash: { value: row.leaseorcash, raw: row.leaseorcash,  class: bgColour },
        valueStats: { value: <Modules.PricingContainer row={row} />, raw: [row.pricing.total, row.pricing.gp], class: bgColour },
        dateSubmittedCast: { value: formatDateTime(row.dateSubmitted), raw: row.dateSubmitted, class: bgColour },
        approvalDateCast: { value: formatDateTime(row.approvalDate), raw: row.approvalDate, class: bgColour },
        invoiced: { value: <Modules.InvoiceContainer row={row} />, raw: row.invoiced, class: bgColour },
        slaApproved: { value: row.slaApproved, raw: row.slaApproved, class: bgColour },
        engStatusTxt: { value: getLogisticsStatus(row.engStatus), raw: getLogisticsStatus(row.engStatus), class: bgColour },
        sopStatus: { value: getStatusChip(row), raw: row.sopStatus },
        salesNotes : { value: tableContainer(fn_SalesNotes, 'icon-pencil5', 'View Notes', salesNotesColour, true), raw: b_hasNotes },
        options : { value: options, raw: 0 }
      }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const calcFilters = setApprovedFilterVars(filters);
    const expandArray = ['pricing', 'dealNote'];

    setFilters({ ...filters, queryString: `${[calcFilters].join(' and ')}${queryLocal}` });

    entityData.current = await Axios.get(`/entities/deals/getSummary/?&$filter=${[calcFilters].join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }

  return <Fragment>
    <UILIB.Paper className='width-100'>
      <div className='flex-container row mar-b10'>
        <div className='flex-item flex-grow-1 start wrap'>
          <UILIB.Button className={'small ' + (!filters.button || filters.button === 'Active' ? 'primary' : 'secondary')} name='Active' value='Active' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
          <UILIB.Button className={'mar-l10 small ' + (!filters.button || filters.button === 'Archived' ? 'primary' : 'secondary')} name='Archived' value='Archived' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilters)} />
        </div>
        <div className='flex-item end'>
          <UILIB.Button disabled={calcResetEnable(filters, defaultFilters)} className={"mar-l20 mar-r5 small green"} name="resetFilters" value="Reset Filters" onClick={() => handleResetfilters()} />
          <UILIB.Button value="Filters" onClick={() => { actionOpenDrawer('ApprovedFiltersDrawer', { ...selectData, filters: filters, setFilters: setFilters }, dispatch, true, { get: render, set: setRender }, '400px') }} />
        </div>
      </div>
      <UILIB.TableNew
        name={`DealsApproved`}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[localRender, warehouse]}
        remoteQuery={getTableData}
        remoteRender={[filters.button, render]}
        defaultSettings={tablePageDefaults} />
    </UILIB.Paper>
  </Fragment>
})
export default Approved