import { useRef, Fragment } from 'react';
import UILIB from 'components';

export default function Button({ 
  name = '',
  value = '', 
  title = '',
  style = {}, 
  className = '', 
  iconBefore = null, 
  icon = null, 
  loading = false, 
  disabled = false, 
  onClick = function () { },
  type = 'button',
  errored = { option: 1, error: false, message: '' } 
}) {

    const nodeWidth = useRef(null);
    const nodeHeight = useRef(null);

    const classNameLocal = `button ${className}`;
    const iconBeforeLocal = (iconBefore) ? <span className={"iconBefore " + iconBefore} /> : "";
    const iconLocal = (icon) ? <span className={"iconAfter " + icon} /> : "";

    if (disabled) style.cursor = "not-allowed"

    let errorClass = ""
    if(errored?.error && errored?.option === 0 && !disabled) errorClass =  " errored button outline ";
    const showError = (errored?.error);

    return <Fragment>
      {(loading && !showError) && <button value={value} name={name} title={title} type={type} disabled={disabled} style={style} className={classNameLocal}>
        <div ref={nodeWidth} style={{ opacity: 0, height: 0 }}>{value}</div>
        <div className="flex-container row center height-100">
          <div ref={nodeHeight} style={{ opacity: 0, width: 0 }}>{value}</div>
          <UILIB.Loading style={{ 
            ...(nodeWidth?.current?.getBoundingClientRect()) ? { width: `${nodeWidth.current.getBoundingClientRect().width}px` } : {},
            ...(nodeHeight?.current?.getBoundingClientRect()) ? { width: `${nodeHeight.current.getBoundingClientRect().height}px` } : {} 
            }} className="width-100" type={2}/>
        </div>
      </button>}
      {(!loading && !showError) && 
        <button value={value} name={name} title={title} type={type} disabled={disabled} style={style} onClick={(ev) => onClick(ev)} className={classNameLocal + errorClass}>{iconBeforeLocal} {value} {iconLocal}</button>}
      {showError && <div>
        <button value={value} name={name} title={title} type={type} disabled={disabled} style={style} onClick={(ev) => onClick(ev)} className={classNameLocal + errorClass} >{iconBeforeLocal} {value} {iconLocal}</button>
        {errored.option === 1 && <div className="errored message flex-item end width-100">{errored.message}</div>}
      </div>}  
    </Fragment>

}
