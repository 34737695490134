import { Fragment } from 'react';
import BlockReleaseTable from '../tables/blockReleaseTable';


export default function ReleaseNotes(props) {
  return <Fragment> 
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12" style={{ padding: 10 }}> 
        <BlockReleaseTable
          releaseTableSortHandler={props.releaseTableSortHandler}
          setReleaseTableSortHandler={props.setReleaseTableSortHandler}
          releaseChildTableSortHandler={props.releaseChildTableSortHandler}
          setReleaseChildTableSortHandler={props.setReleaseChildTableSortHandler}
          downloadingRelease={props.downloadingRelease}
          setDownloadingRelease={props.setDownloadingRelease}
          uploadingRelease={props.uploadingRelease}
          setUploadingRelease={props.setUploadingRelease}
          publishRelease={props.publishRelease}
          setPublishRelease={props.setPublishRelease}
          releases={props.releases}
          setReleases={props.setReleases}
          releaseRender={props.releaseRender}
          setReleaseRender={props.setReleaseRender}
          localEvent={props.localEvent}
          setLocalEvent={props.setLocalEvent}
          fileUploadJobActive={props.fileUploadJobActive}
          setFileUploadJobActive={props.setFileUploadJobActive}
          uploadFileHandler={props.uploadFileHandler}
          setUploadFileHandler={props.setUploadFileHandler}
          setSelectedFile={props.setSelectedFile}
          releaseExpandRow={props.releaseExpandRow}
          setReleaseExpandRow={props.setReleaseExpandRow}
          renderLoading={props.renderLoading}
          setRenderLoading={props.setRenderLoading}
        />
      </div>
    </div>
  </Fragment> 
}
