import { useState, Fragment } from 'react';
import Modules from '../Modules';


export default function NotesDrawer({ entity }) 
{  

  const [addNote, setAddNote] = useState(false);

  return <Fragment>
    {!addNote && <Modules.ViewNotes entity={entity} setAddNote={setAddNote} />}
    {addNote && <Modules.AddNotes entity={entity} setAddNote={setAddNote} />}
  </Fragment>
}
