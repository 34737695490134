import cloneDeep from 'lodash/cloneDeep';
import Axios from 'classes/axios';

const missingStoreError = `At least one selected record has no store configured or selected, if the row is highlighted red then please visit the Site Stocks page to correct this or delete the record from this page`;
const defaultBanner = { error: false, message: '' };

export async function updateStoreRequests(groupId, requestId, customerId, row, setProcessing, localRender, setLocalRender, invalidStoreIds, requestCheckbox, setStoreBannerError, updateTrigger, setUpdateTrigger) {
    setProcessing(true);
    setLocalRender(!localRender);
    const dataRow = cloneDeep(row);
    await Axios.put(`/entities/serviceAdmin_siteStoreRequest/${requestId}`, { entity: { ...row, groupID: Number(groupId) } });
    invalidStoreIds.current[requestId] = (Number(groupId) === -1);
    row.groupID = Number(groupId);
    if(Number(groupId) !== -1) {
      const response = await Axios.get(`/entities/serviceAdmin_siteStore/${customerId}/${groupId}`);
      Object.keys(response.data.result[0]).forEach(x => row[x] = response.data.result[0][x]);
      row.notes = dataRow.notes;
      row.stores = dataRow.stores;
    }
    checkErrors(invalidStoreIds, requestCheckbox, localRender, setLocalRender, setStoreBannerError, updateTrigger, setUpdateTrigger);
    setProcessing(false);
};

export const checkErrors = (invalidStoreIds,requestCheckbox, localRender, setLocalRender, setStoreBannerError, updateTrigger, setUpdateTrigger) => {
    const storeIds = Object.keys(invalidStoreIds.current).filter(x => invalidStoreIds.current[x]).map(x => Number(x));
    if(Object.keys(requestCheckbox.current).filter(f => requestCheckbox.current[f]).some(x => storeIds.includes(Number(x)))) {
        setStoreBannerError({ error: true, message: missingStoreError });
    } else {
        setStoreBannerError(defaultBanner) 
    }
    setLocalRender(!localRender);
    setUpdateTrigger(!updateTrigger);
};

export const updateCheckbox = (requestID, row, invalidStoreIds, requestCheckbox, localRender, setLocalRender, setStoreBannerError, updateTrigger, setUpdateTrigger ) => {
    requestCheckbox.current = { ...requestCheckbox.current, [requestID]: !requestCheckbox.current[requestID] };
    checkErrors(invalidStoreIds, requestCheckbox, localRender, setLocalRender, setStoreBannerError, updateTrigger, setUpdateTrigger);
};