import { useState, Fragment } from 'react';
import UILIB from 'components';


export default function ApprovedFiltersDrawer ({ data, onFinished, sync }) {

  const [filtersLocal, setFiltersLocal] = useState(data.filters);

  const updateDates = (newDate, context) => {
    filtersLocal[context][newDate.target.name] = newDate.target.value;
    setFiltersLocal({ ...filtersLocal });
  }
  
  const syncAndClose = () => {
    data.setFilters({ ...data, ...data.filters = filtersLocal });
    sync.set(!sync.get);
    onFinished();
  }

  return <Fragment>         
    <h2>Filters</h2>
    <div className="mar-b15">
      <h4>Date Added</h4>
      <div className="flex-container start mar-3">
        <UILIB.TextInput type="date" className="textInput input" name="startDate" outerstyle={{ width: "50%" }} outerClassName="mar-r10" value={filtersLocal.dateAdded.startDate} onChange={(ev) => updateDates(ev, 'dateAdded')} />
        <UILIB.TextInput type="date" className="textInput input" name="endDate" outerstyle={{ width: "50%" }} outerClassName="" value={filtersLocal.dateAdded.endDate} onChange={(ev) => updateDates(ev, 'dateAdded')} />
      </div>
    </div>
    <div className="flex-container row start width-100 mar-3 mar-t20">
      <UILIB.Button name="Apply Filters" value="Apply Filters" onClick={() => syncAndClose()} />
    </div>
  </Fragment>   
}
