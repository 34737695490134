import { useEffect, Fragment, useState } from 'react';
import UILIB from 'components'
import { equipmentReturnMethods, equipmentOwnerships } from "classes/quotesHelpers"

export default function BlockCollectTable({ data, setFormData, type }) {
    const [tableData, setTableData] = useState([]);

    const headerData = [
      { label: <span className='flex top-xs gap-2'><UILIB.Checkbox onChange={(event) => removalSelected(event, undefined)} /> Include</span>, value: "removalSelected", align: "left", type: 'number', width: "6%" },
      { label: "Quantity", value: "quantity", align: "left", type: 'number', width: "5%" },
      { label: "Model", value: "model", align: "left", type: 'string', width: "18%" },
      { label: "Serial", value: "serial", align: "left", type: 'number', width: "8%" },
      { label: "Current Location Address", value: "currentLocationAddress", align: "left", type: 'number', width: "12%" },
      { label: "Location Onsite", value: "locationOnsite", align: "left", type: 'number', width: "14%" },
      { label: "Equipment Ownership", value: "equipmentOwnership", align: "left", type: 'number', width: "12%" },
      { label: "Equipment Owner Details", value: "equipmentOwnerDetails", align: "left", type: 'number', width: "14%" },
      { label: "Readings", value: "readings", align: "left", type: 'number',  width: "12%" }
    ]

    function renderTable() {

      if(!data.removals) return;

      const tableData = [];

      data.removals && data.removals.forEach(row => {
        const foundAddress = data.stores.find(x => Number(x.AddressId) === Number(row.currentLocationAddress))?.AddressString ?? '';
        const foundEquipmentOwnerships = equipmentOwnerships.find(x => Number(x.value) === Number(row.equipmentOwnership));
        const foundEquipmentReturnMethods = equipmentReturnMethods.find(x => Number(x.value) === Number(row.equipmentOwnerDetails));
        const readings = row.readings && row.readings.map(x => <div className="col-xs-12 col-sm-12 col-md-12">{`${x.meterName}: ${x.meterReading}`}</div>);

        tableData.push({
          removalSelected: { value: <UILIB.Checkbox name="removalSelected" checked={row.removalSelected} onChange={(event) => removalSelected(event, row.removalID)}  />, raw: row.removalSelected },
          quantity: { value: 1, raw: 1 },   
          model: { value: row.model, raw: row.model },    
          serial: { value: row.serial, raw: row.serial },
          currentLocationAddress: { value: foundAddress, raw: foundAddress },
          locationOnsite: { value: row.locationOnsite, raw: row.locationOnsite },
          equipmentOwnership: { value: (foundEquipmentOwnerships) && foundEquipmentOwnerships.label, raw:  (foundEquipmentOwnerships) && foundEquipmentOwnerships.value },
          equipmentOwnerDetails: { value: (foundEquipmentReturnMethods) && foundEquipmentReturnMethods.label, raw: (foundEquipmentReturnMethods) && foundEquipmentReturnMethods.value },  
          readings: { value: readings, raw: readings }
        })

      })
      setTableData(tableData);
    }

    useEffect(() => {
      renderTable();
    }, [data]);

    const removalSelected = (event, index) => {
      const dataNew = structuredClone(data);

      if (index === undefined) {
        const selectAllChecked = event.target.checked;
    
        dataNew[type].forEach((obj) => {
          obj.removalSelected = selectAllChecked;
          obj.attachments.forEach((c) => {
            c.removalSelected = selectAllChecked;
          });
        });
      } else {
        const theIndex = dataNew[type].findIndex((obj) => obj.removalID === index);
        dataNew[type][theIndex].removalSelected = event.target.checked;
    
        dataNew[type][theIndex].attachments.forEach((c) => {
          c.removalSelected = event.target.checked;
        });
      }

      setFormData(dataNew);
    }


    return <Fragment>
      <UILIB.Table
        className="small"
        overflowX="auto"
        overflowY="hidden"
        header={headerData}
        data={tableData}
        sortable={false}
        autoResize={false}
        exportEnable={true} />
    </Fragment>
}