import RequestForm from './modules/requestForm';
import SiteSearch from './modules/siteSearch';
import DevicesRequired from './modules/devicesRequired';
import RecentlyDespatched from './modules/recentlyDespatched';
import GroupContainer from './modules/inputContainer';
import CustomerStats from '../../../components/Container/basicStatContainer';
import InputContainer from './modules/inputContainer';
import Summary from './modules/summary';

const exported = {
  RequestForm,
  SiteSearch,
  DevicesRequired,
  RecentlyDespatched,
  GroupContainer,
  CustomerStats,
  InputContainer,
  Summary
}
export default exported;