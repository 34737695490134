
import { useEffect, useState, useRef, Fragment } from 'react';
import UILIB from 'components';


export default function QuantityContainer({ data, className, disabled = false, onChange }) {

  const inputClass = (className) ? className : '';

  const firstLoad = useRef(true);
  const [event, setEvent] = useState(false);

  const generateInput = (fieldName, data, inputClass) => {
    return <UILIB.TextInput 
      disabled={disabled}
      type='string'
      className={inputClass}
      name={fieldName} 
      value={data[fieldName]} 
      onChange={(ev) => handleUserInput(ev, data)} />
  };

  const handleUserInput = (event, row) => {
    const value = !isNaN(event.target.value) ? Number(String(event.target.value.replace(/^0+/, ''))) : row[event.target.name];
    row[event.target.name] = value;
    setEvent({ value: value, field: event.target.name, row: row });
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false; 
    } else {
      onChange(event);
    }
  }, [event])

  return <Fragment>
          <div className="flex-container row flex width-100">
            <div className={`flex-item start`}>{generateInput('quantity', data, inputClass)}</div>
          </div>
        </Fragment>
}
