import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { useDispatch } from "react-redux";
import { setDrawer } from "store/actions";

import AddEditWorkflow from "../pages/addEditWorkflow";
import AddEditRecipients from "../pages/addEditRecipients";


const tableHeader = [
    { label: "Workflow Name", value: "name", align: "left", type: 'text' }, 
    { label: "Workflow Description", value: "description", align: "left", type: 'text' }, 
    { label: "E-Mail Subject", value: "subject", align: "left", type: 'text' }, 
    { label: "Trigger", value: "trigger", align: "left", type: 'text' }, 
    { label: " ", value: "options1", align: "center", type: 'text', width: "10px" }, 
    { label: " ", value: "options2", align: "center", type: 'text', width: "10px" }
  ]


export default function Workflows() {
    const dispatch = useDispatch();
    const [pageLoading, setPageLoading] = useState(true);
    const [tableData, setTableData] = useState([]);

    const getWorkflows = async () => {
      try {
          let wfData = await Axios.get('/admin/workflows');
          wfData = wfData.data.map(row => {
            return {
              "name": { value: row.name, raw: row.name },
              "description": { value: row.description, raw: row.description },
              "subject": { value: row.subject, raw: row.subject },
              "trigger": { value: row.trigger, raw: row.trigger },
              "options1": {
                value: <UILIB.Button value="View" onClick={() => { addEditWorkflow(row, 1) }} />, raw: ""
              },
              "options2": {
                value: <UILIB.Button value="Recipients" onClick={() => { addEditRecipients(row) }} />, raw: ""
              }
            }
          })
          setTableData(wfData);
          setPageLoading(false);
      }
      catch (err) {
          setPageLoading(false);
      }
    }

    const addEditWorkflow = (workflow) => {
      dispatch(setDrawer({ 
        open: true, 
        content: <AddEditWorkflow workflow={workflow} onClose={closeDrawer} />, 
        width: "450px"
      }));
    }

    const addEditRecipients = (workflow) => {
      dispatch(setDrawer({ 
        open: true, 
        content: <AddEditRecipients workflow={workflow} onClose={getWorkflows} />, 
        width: "600px" 
      }));
    }

    const closeDrawer = () => {
        getWorkflows();
        dispatch(setDrawer({ open: false, content: null }));
    }

    useEffect(() => {
        getWorkflows();
    }, [])

    if (pageLoading) {
        return <UILIB.Loading type={3} />
    }
    return <Fragment>
        <div className="flex-container row mar-l5 mar-r5">
          <div className="flex-item start width-100">
              <h2>Notification Workflow Admin</h2>
              { /* <UILIB.Button value="Add a Workflow" onClick={() => addEditWorkflow({}, 0)} /> */}
          </div>
          <div className="paper flex-item start width-100">
              <UILIB.Table 
                height="100%"
                overflowX="auto"
                overflowY="hidden"
                header={tableHeader} 
                data={tableData} 
                maxRows={20}
                sortable={true}
                filter={true}
              />
          </div>
        </div>
    </Fragment>
}