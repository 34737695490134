import SiteStocksRequests from './modules/siteStocksRequests';
import NotesDrawer from './modules/notesDrawer';
import ViewNotes from './modules/notesView';
import AddNotes from './modules/notesAdd';
import SerialContainer from './modules/serialContainer';
import TonerContainer from './modules/tonerContainer';
import RequestFiltersDrawer from './modules/RequestFiltersDrawer'

const exported = {
  SiteStocksRequests,
  NotesDrawer,
  ViewNotes,
  AddNotes,
  SerialContainer,
  TonerContainer,
  RequestFiltersDrawer
}
export default exported;