
import { Fragment } from 'react'
import * as functions from '../shared/functions';
import { 
  DialogContent, 
  DialogTitle, 
  Box, 
  LinearProgress, 
  Typography, 
  Button 
} from '@mui/material';



export default function UploadFile(props) 
{
    const handleBack = () => {
      const uploadFileRequiredNew = structuredClone(props.uploadFileHandler);
      uploadFileRequiredNew.stage = 1;
      props.setUploadFileHandler(uploadFileRequiredNew);
    };

    return <Fragment>
      <DialogTitle >Please select a CSV to upload</DialogTitle>
      <DialogContent>
        <p className={"mar-b10"}>Please select a file and click upload to add this release document, this may take a few seconds to complete.</p>
        {Boolean(props.selectedFile && props.fileUploadJobActive.progress) && (
          <Box className="mb25" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={props.fileUploadJobActive.progress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${props.fileUploadJobActive.progress}%`}</Typography>
            </Box>
            
          </Box>)
        }
        {props.selectedFile && props.fileUploadJobActive.status && 
          <p>Status: {props.fileUploadJobActive.status}</p>
        }
        <Box 
          display="flex" 
          alignItems="center"
          sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', bgcolor: 'background.paper', borderRadius: 1 }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: 'none' }}
                type="file"
                disabled={props.fileUploadJobActive.complete || props.fileUploadJobActive.isActive}
                onChange={async (ev) => await functions.handleSelectedFile(ev, props)}
                />
              <Button
                size="small"
                className="btn-choose"
                variant="outlined"
                component="span"
                disabled={props.fileUploadJobActive.complete || props.fileUploadJobActive.isActive}
              > Select File
              </Button>
            </label>
          </Box>
          <Box>
            <Button
              size="small"
              className="btn-upload"
              color="primary"
              variant="contained"
              component="span"
              disabled={props.fileUploadJobActive.complete || props.fileUploadJobActive.isActive}
              onClick={handleBack}
              sx={{ ml: 1, backgroundColor: '#00B200' }}
            > Back
            </Button>
            <Button
              size="small"
              className="btn-upload"
              color="primary"
              variant="contained"
              component="span"
              disabled={!props.selectedFile || (props.fileUploadJobActive.complete || props.fileUploadJobActive.isActive)}
              onClick={async () => await functions.handleFileUpload(props, 1000)}
              sx={{ ml: 1, backgroundColor: '#00B200' }}
            > Upload
            </Button>
            <Button
              size="small"
              className="btn-upload"
              color="primary"
              variant="contained"
              component="span"
              disabled={props.fileUploadJobActive.isActive}
              onClick={() => functions.handCloseUploadModal(props)}
              sx={{ minWidth: 70, ml: 1, backgroundColor: props.selectedFile && props.fileUploadJobActive.complete ? '#00B200' : '#e74c3c' }}
            > {props.selectedFile && props.fileUploadJobActive.complete ?  'Exit' : 'Cancel'}
            </Button>
          </Box>
        </Box>
        {props.selectedFile && props.selectedFile.name && <Typography sx={{ mt: 2 }} className="list-header">
          <p >Selected file: <u>{props.selectedFile.name}</u></p>
        </Typography>}
      </DialogContent>
    </Fragment>
}