import UILIB from 'components';
import Axios from 'classes/axios'
import { useState, Fragment } from 'react';
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getEditContactsDrawerFormErrors } from 'classes/formErrors/repository';
import { createTextInput } from 'classes/controls/formControls';


export default function KeyContactsDrawer({ onFinished, ourDeal, setOurDeal, isActive, contact }) {

  const [saving, setSaving] = useState(false);
  const defaultError = { error: false, message: '' };
  const [formErrors, setFormErrors] = useState({ contactName: defaultError, contactEmail: defaultError, contactPhone: defaultError });
  const [formData, setFormData] = useState({ ...contact });
  const [bannerError, setBannerError] = useState(defaultError);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const actionUpdateField = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  const actionSaveForm = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const formResult = await checkFormErrors(getEditContactsDrawerFormErrors(formData), setFormErrors, null, null, formData, null);
      if(formResult.errorCount === 0 || Number(ourDeal.processStage) === 0) {
        const result = await Axios.put(`/entities/workflow/deals/actionUpdateKeyContacts`, [formData]); 
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          const index = ourDeal.keyContact.findIndex(x => Number(x.id) === Number(contact.id));
          ourDeal.keyContact[index] = { ...formData, ...result.data.result[0] };
          setOurDeal(ourDeal);
          onFinished();
          setSaving(false);
        }
      }
    } catch (error) {
      console.log(error);
      setSaving(false);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  }


  return <Fragment>
    <div className="flex-container row width-100">
      <div className="flex-item center align-center font-weight-bold text-16 mar-b10 width-100">Edit {contact.contactTypeTxt}</div>
      <div className="flex-container row start width-100 mar-b5">
        {createTextInput('Contact Name *', 'contactName', !isActive, formData.contactName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Contact Email *', 'contactEmail', !isActive, formData.contactEmail, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Contact Phone *', 'contactPhone', !isActive, formData.contactPhone, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
        {createTextInput('Contact Mobile', 'contactMobile', !isActive, formData.contactMobile, (ev) => actionUpdateField(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
      </div>
      <div className="flex-container row start width-100 mar-t20 mar-r5">
        <UILIB.Button className="green" disabled={!isActive || saving} loading={saving} value={"Save"} onClick={async () => await actionSaveForm()} />
        <UILIB.Button className="grey" disabled={saving} value="Cancel" onClick={() => onFinished()} />
      </div>
      {bannerError.error && <div className="flex-container start wrap width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>
}