import { useState, useRef, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { createSelectInput, createButton } from 'classes/controls/formControls';


export default function RemoveDeviceExistingDrawer({ onFinished, ourDeal, setOurDeal, tableData, isActive }) {

  const headerData = [
    { label: '', value: 'selected', filtering: false, minWidth: 20, maxWidth: 20 },
    { label: "Account #", value: "ExternalAccountNumber", type: 'number', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Serial", value: "SerialNumber", type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: "Model #", value: "Description", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Location Address", value: "AddressString", type: 'string', filtering: true, minWidth: 170, maxWidth: 170 },
    { label: "Location On Site", value: "Location", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 }];

    const entityData = useRef({});
    const cachedData = useRef([]);
    const addressData = useRef([]);
    const requestCheckbox = useRef({});
    const defaultError = { error: false, message: '' };
    const [bannerError, setBannerError] = useState(defaultError);
    const [selectedAddress, setSelectedAddress] = useState(-1);
    const [saving, setSaving] = useState(false);
    const [localRender, setLocalRender] = useState(false);
    const exceptions = [400,404];

    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


    const updateCheckbox = (equipmentId, row) => {
      requestCheckbox.current = { ...requestCheckbox.current, [equipmentId]: !requestCheckbox.current[equipmentId] };
      const addOrDelete = requestCheckbox.current[equipmentId];
      if(addOrDelete) cachedData.current.push(row);
      if(!addOrDelete) cachedData.current = cachedData.current.filter(x => Number(x.id) !== Number(equipmentId));
      setLocalRender(!localRender);
    };

    const actionsubmitForm = async () => {
      setSaving(true);
      try {
        const result = await Axios.post(`/entities/workflow/deals/actionAddDeviceRemoval/${ourDeal.dealID}/${selectedAddress}`, { devices: cachedData.current });
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          setOurDeal({ ...ourDeal, removal: [...ourDeal.removal, result.data.result[0]] })
          onFinished();
        }
      } catch (error) {
        console.log(error);
        setBannerError({ error: true, message: exceptionError });
        setSaving(false);
      }
    };


    async function constructTable(dealData) {

      const tableData = dealData.map(row => {

        return {
          selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox disabled={false} checked={requestCheckbox.current[row.id]} type='checkbox' onChange={() => updateCheckbox(row.id, row)}/>} /> },
          ExternalAccountNumber: { value: row.ExternalAccountNumber, raw: row.ExternalAccountNumber },
          Description: { value: row.Description, raw: row.Description },
          SerialNumber: { value: row.SerialNumber, raw: row.SerialNumber },
          AddressString: { value: row.AddressString, raw: row.AddressString },
          Location: { value: row.Location, raw: row.Location } }
      })
      return tableData;
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

      const queryLocal = (query !== null) ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const serialNumbers = (tableData.some(x => x?.serial?.value)) ? `SerialNumber nin ${tableData.map(x => x?.serial?.value).filter(x => x).join(',')}` : '';

      entityData.current = await Axios.get(`/entities/equipments/getSummaryDeviceRemoval/${ourDeal.accountNo}/?&$filter=${serialNumbers} and ${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
  
      if(!entityData.current || !entityData.current.result.length) return false;

      let checked = {};
      if(entityData.current && entityData.current.result) {
        entityData.current.result.forEach(x => { checked = { [x.tonerID]: false, ...checked } });
        requestCheckbox.current = { ...checked, ...requestCheckbox.current };
      }

      let addresses = [{ value: -1, label: 'Please Select' }];
      ourDeal.address.forEach(x => {
        const label = Object.values({ 
          address1: x.address1, 
          address2: x.address1, 
          address3: x.address3, 
          town: x.town, 
          county: x.county, 
          postcode: x.postcode}).filter(x => x && x.length).join(', ');
        addresses.push({ value: x.id, label: label });
      });
      addressData.current = addresses;

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }


    return <Fragment>
      <div className='flex-container row mar-b10'>
        <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold">Add from Existing Equipment</div>
      </div>
      <div className='flex-container row start wrap evenly width-100'>
        {createSelectInput('Select Address', 'selectAddress', (!isActive || !Object.keys(requestCheckbox.current).some(x => requestCheckbox.current[x] || saving)), selectedAddress, addressData.current, (ev) => setSelectedAddress(Number(ev.target.value)), null, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {createButton('', 'submit', 'Submit', (!isActive || !Object.keys(requestCheckbox.current).some(x => requestCheckbox.current[x]) || saving || (Number(selectedAddress) === -1)), saving, async () => await actionsubmitForm(), null, 'flex-item width-20')}
        {bannerError.error && <div className="flex-item end wrap width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
      <UILIB.TableNew
        name={'EquipmentRemoval'}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[localRender]}
        remoteQuery={getTableData}
        remoteRender={[ourDeal]} />
    </Fragment >
}