
import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios'
import { formatCurrency } from 'classes/format';
import { useHistory } from "react-router-dom";

export default function BlockInvoicedThisMonth({ user }) {
    const history = useHistory();
    const [deals, setDeals] = useState([]);

    useEffect(() => {
      const asyncEffect = async () => {
        const invoicedSummary = await Axios.get('/sales/deals/invoicedSummary');
        setDeals(invoicedSummary.data.results[0]);
      }
      asyncEffect();
    }, []);

    return <UILIB.Paper className="text-center flex-container column height-100">
      <h2 className="flex-item start width-100">Invoiced this Month</h2>
      <div className="flex-container flex-1 column evenly">
        <div>
          <h1 className="mar-b0">{deals.total}</h1>
          <p className="text-grey text-small">Total Invoiced</p>
        </div>
        <div>
          <h1 className="mar-b0">{formatCurrency(deals.GP)}</h1>
          <p className="text-grey text-small">Gross Profit</p>
        </div>
      </div>
      <div className="flex-container row width-100 center mar-t5">
        <div className="mar-b5 width-100 flex-container">
          <UILIB.Button 
            className="primary small consumablesHub buttonSize"
            value="View All Invoiced" 
            onClick={() => { history.push('/salesHub/accountmanagerview/deals/invoiced') }} />
        </div>
      </div>
    </UILIB.Paper >
}