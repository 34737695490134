import { useState, Fragment } from 'react';
import UILIB from 'components';
import { createMultiSelectInput } from 'classes/controls/formControls';


export default function AwaitingFiltersDrawer ({ data, onFinished, sync }) {

  const [filtersLocal, setFiltersLocal] = useState(data.filters);

  const handleOptionsChanged = (event, name) => {
    if (filtersLocal[name] === event.target.name) {
      setFiltersLocal({ ...filtersLocal, [name]: '' });
    } else {
      setFiltersLocal({ ...filtersLocal, [name]: event.target.value });
    }
  };

  const syncAndClose = () => {
    data.setFilters({ ...data, ...data.filters = filtersLocal });
    sync.set(!sync.get);
    onFinished();
  }

  return <Fragment>         
    <h2>Filters</h2>
    <div className="flex-container row start width-100 mar-3">
      {createMultiSelectInput('', 'selectRegion', false, filtersLocal.regionIds, data.regions, 'All Regions', (ev) => handleOptionsChanged(ev), null, null, null, null, null)}
    </div>
    <div className="flex-container row start width-100 mar-3 mar-t20">
      <UILIB.Button name="Apply Filters" value="Apply Filters" onClick={() => syncAndClose()} />
    </div>
  </Fragment>   
}
