import { useState, useEffect } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Axios from "classes/axios"
import UILIB from 'components'


export default function PartsAdminParts(props) {

    const [grid, setGrid] = useState()
    const [loading, setLoading] = useState(true)

    const getParts = async () => {
        try {
            setLoading(true) 
            let itemsNew = JSON.parse(JSON.stringify(props.items));
            await Axios.get(`/sales/parts/type`).then(res => { 
                res.data.map((p, index) => {
                    itemsNew[0].items[index] = {
                        id: `${p.id}`,
                        index: `${index}`,
                        contentRaw: `${p.name}`,
                        content: `${(index + 1 ) + " -> " + p.name}`
                    }
                }) 
                setLoading(false)
            })
            return itemsNew
        }
        catch (e) {
            setLoading(false)
            console.log(e)
        }

    }


    const getItemStyle = (isDragging, draggableStyle) => ({
        
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        width: "100%",
        border: "1px solid white", 
        borderRadius: "10px",
        paddingLeft: "15px",
        paddingRight: "15px",
        background: isDragging ? "rgba(108, 122, 137, 0.1)" : "rgba(108, 122, 137, 0.2)",

        // styles we need to apply on draggables
        ...draggableStyle
    });


    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "white" : "white",
        padding: grid,
        width: "100%"
    });


    const reorder = (list, startIndex, endIndex) => {
        let result = Array.from(list);
        let [removed] = result[0].items.splice(startIndex, 1);
        result[0].items.splice(endIndex, 0, removed);
        result[0].items.map((r,index) => { 
            { r.index = index }
            {r.content = `${ (index + 1 ) + " -> " + r.contentRaw}` }
        })
        return result;
    };


    const onDragEnd = (ev) => {
        // dropped outside the list
        if (!ev.destination) {
          return;
        }
        let itemsNew = JSON.parse(JSON.stringify(props.items));
        itemsNew = reorder(props.items, ev.source.index, ev.destination.index);
        props.setItems(itemsNew);
    }


    useEffect( async () => {
        await getParts().then(res => { props.setItems(res) })
    }, [])


    if (loading)  return <UILIB.Loading type={3} /> 
    return ( 
        <div className="flex-container row">
            <div className="col-xs-12">
                <DragDropContext onDragEnd={(event) => { onDragEnd(event) }}>
                    <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        >
                        {props.items[0].items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                                <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                )}
                                >
                                {item.content}
                                </div>
                            )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>
            </div>
        </div>
    )
}