import UILIB from 'components';

export const pleaseSelect = 'Please select...';

export const namePattern = /^[a-zA-Z '.-]*$/;
export const alphaPattern = /^[a-zA-Z]*$/;
export const alphaSpacePattern = /^[a-zA-Z\s]*$/;
export const alphaSpaceSpecialPattern = /^[a-zA-Z\s &-/]*$/;
export const alphaNumericPattern = /^[a-zA-Z0-9]*$/;
export const alphaNumericSpacePattern = /^[a-zA-Z0-9\s]*$/;
export const alphaNumericSpaceSpecialPattern = /^[a-zA-Z0-9\s -.]*$/;
export const numericPattern = /^[0-9]*$/;
export const numericSpecialPattern = /^[0-9 ,.]*$/;
export const mobilePattern = /^[0-9\s]*$/;
export const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const currencyPattern = /^(?=(?:,?\d){1,99}(?:\.|$))\d{1,3}(?:,\d{3})*(?:\.\d{2,10})?$/;
export const numberOnlyPattern = /^[0-9]*$/;
export const postcodePattern = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

export const createDateInput = (label, name, editable, entity, entityErrors, changeInput) => {
    return (createInput(
        label,
        (editable) 
            ? <div className='col-xs-8'><UILIB.TextInput type='date' className="textInput input" name={name} value={entity[name]} onChange={changeInput} errored={entityErrors[name]} /></div>
            : <div className='col-xs-8'><label className='text-small'>&gt; {entity[name]}</label></div>
    ));
}

export const createTextInput = (label, name, editable, maxLength, entity, entityErrors, changeInput) => {
    return (createInput(
        label,
        (editable) 
            ? <div className='col-xs-8'><UILIB.TextInput className="textInput input" name={name} value={entity[name]} maxLength={maxLength} onChange={changeInput} errored={entityErrors[name]} /></div>
            : <div className='col-xs-8'><label className='text-small'>&gt; {entity[name]}</label></div>
    ));
}

export const createPreFilledTextInput = (label, name, editable, maxLength, defaultValue, entity, entityErrors, changeInput) => {
    const theValue = (entity[name] === '') ? defaultValue : entity[name];
    const theReturn = 
        createInput(
            label,
            (editable) 
                ? <div className='col-xs-8'><UILIB.TextInput className="textInput input" name={name} value={theValue} maxLength={maxLength} onChange={changeInput} errored={entityErrors[name]} /></div>
                : <div className='col-xs-8'><label className='text-small'>&gt; {theValue}</label></div>
        )
    return theReturn;
}

export const createMultilineTextInput = (label, name, editable, maxLength, entity, entityErrors, changeInput) => {
    return (createInput(
        label,
        (editable) 
            ? <div className='col-xs-8'><UILIB.TextArea style={{ height: "100px", width: "100%", padding: "0", "resize": "none" }} name={name} value={entity[name]} maxLength={maxLength} onChange={changeInput} errored={entityErrors[name]} /></div>
            : <div className='col-xs-8'><label className='text-small'>&gt; {entity[name]}</label></div>
    ));
}

export const createSelectInput = (label, name, data, editable, entity, entityErrors, changeInput) => {
    return (createInput(
        label,
        (editable) 
            ? <div className='col-xs-8'><UILIB.Select name={name} value={entity[name]} onChange={changeInput} errored={entityErrors[name]} data={data} /></div>
            : <div className='col-xs-8'><label className='text-small'>&gt; {entity[name]}</label></div>
    ));
}

const createInput = (label, control) => {
    return (<div className='row offShade pad-t5 pad-b5 mar-b5'>
                <div className='col-xs-4'>{label}</div>
                {control}
            </div>)
}

export const checkAndSet = (check, obj, item, value) => {
    if (check) {
        obj[item] = value;
        return true;
    }

    return false;
}

export const newError = (name, msg) => {
    return { name: name, option: 1, messageText: msg, error: true };
}