import { useState, Fragment } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';
import { createButton, createUpload } from 'classes/controls/formControls';
import { getDealPricing, updateLeasePeriodPayment } from '../shared/functions';
import { csv2json } from 'classes/helpers';
import moment from "moment-business-time";


export default function ImportPSDrawer({ ourDeal, setOurDeal, isActive, onClose }) 
{

  const headerData = [
    { label: 'Product', value: 'description', type: 'string', filtering: false, minWidth: 300, maxWidth: 300 },
    { label: 'Part Number', value: 'partNo', type: 'string', filtering: false, minWidth: 130, maxWidth: 130 },
    { label: 'Quantity', value: 'qty', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 },
    { label: 'Supplier', value: 'supplier', type: 'string', filtering: false, minWidth: 190, maxWidth: 190 },
    { label: 'Cost', value: 'costEach', type: 'string', filtering: false, minWidth: 50, maxWidth: 50 },
    { label: 'Price', value: 'priceEach', type: 'string', filtering: false, minWidth: 50, maxWidth: 50 },
    { label: 'PS Time', value: 'psTime', type: 'string', filtering: false, minWidth: 50, maxWidth: 50 }];

  const defaultSettings =  {   
    expanding: false,
    filtering: false,
    sortable: false,
    caching: false,
    filterEnabled: false, 
    refreshEnabled: false,
    paginationEnabled: false,
    showRecords: false,
    exportEnabled: false,
    expandEnabled: false,
    deleteEnabled: false }

  const [formData, setFormData] = useState([]);
  const [selected, setSelected] = useState(false);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [misc, setMisc] = useState({ name: 'N/A', date: null });
  const [CSVError, setCSVError] = useState(defaultError);
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const invalidFileType = 'Please select a valid CSV file';
  const invalidFileAge = 'Quote expired. Please contact PS to receive an updated quote';
  const invalidOppNo = 'Quote generated for a different opportunity. Please import the correct one';
  const invalidConversion = 'There was an error converting the CSV data, please try again';


  const actionUpload = async (ev) => {

    const acceptedHeader = ['quoteDate', 'userName', 'partNo', 'description', 'qty', 'costEach', 'priceEach', 'supplier', 'psTime', 'oppNumber'];

    if (ev?.file?.type !== 'text/csv') {
      return setCSVError({ error: true, message: invalidFileType });
    } else {
      setCSVError(defaultError);
    }

    const convert = csv2json(ev?.reader.result);
    if (!convert.success) {
      return setCSVError({ error: true, message: invalidConversion });
    } else if (acceptedHeader.some(x => !convert.header.includes(x))) {
      return setCSVError({ error: true, message: invalidFileType });
    } else if (moment(convert.result[0].quoteDate, 'DD/MM/YYYY').diff(moment(), 'days') < 0) {
      return setCSVError({ error: true, message: invalidFileAge });
    } else if (Number(convert.result[0].oppNumber) !== Number(ourDeal.dynamicsOpportunityNumber)) {
      return setCSVError({ error: true, message: invalidOppNo });
    } else {
      setSelected(true);
      setMisc({ name: convert.result[0].userName, date: convert.result[0].quoteDate });
      setFormData(convert.result);
      setCSVError(defaultError);
    }
  }

  const actionImportPSData = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const result = await Axios.put(`/entities/workflow/deals/actionImportPSData/${ourDeal.dealID}`, { products: formData }); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        await getDealPricing(ourDeal.dealID, ourDeal, setOurDeal);
        await updateLeasePeriodPayment(ourDeal, setOurDeal);
        setOurDeal({ 
          ...ourDeal, 
          product: result.data.result.product, 
          compatibles: result.data.result.compatibles, 
          selected: result.data.result.selected.map(x => { return { ...x, value: x.deviceNumber, label: x.deviceNumber } })
        });
        onClose();
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  }

  async function constructTable(dealData) {

    const tableData = dealData.map(row => {
      return {
        description: { value: row.description, raw: row.description },
        partNo: { value: row.partNo, raw: row.partNo },
        qty: { value: row.qty, raw: row.qty },
        supplier: { value: row.supplier, raw: row.supplier },
        costEach: { value: row.costEach, raw: row.costEach },
        priceEach: { value: row.priceEach, raw: row.priceEach },
        psTime: { value: row.psTime, raw: row.psTime } }
    })
    return tableData;
  }
  

  return <Fragment>
    <div className='flex-container row wrap mar-b10'>
      <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Import PS records</div>
      <p className="flex-item start width-80 text-11"><i>Please first select a CSV file to upload and then check the data in the preview table.</i></p>
      <p className="flex-item start width-80 text-11"><i>Once happy, select Upload to add these records to the deal.</i></p>
      {selected && <div className="flex-contaner row mar-t15">
        <div className="flex-container row start width-100">
          <b className="flex-item start center text-13">PS Member:</b>
          <div className="flex-item flex-grow-1 text-13 pad-l5">{misc.name}</div>
        </div>
        <div className="flex-container row start width-100">
          <b className="flex-item start center text-13">Quote Expiry Date:</b>
          <div className="flex-item flex-grow-1 text-13 pad-l5">{misc.date}</div>
        </div>
      </div>}
      <div className='flex-container row end wrap evenly width-100 pad-r3 pad-l3'>
        {!selected && <div className='flex-container row end wrap width-100'>
          {createUpload('', 'selectFile', 'Select File (CSV)', false, false, async (ev) => await actionUpload(ev), null, 'mar-2')}
          {createButton('', 'cancel', 'Cancel', saving, false, async () => onClose(), null, 'mar-2', null, null, 'red')}
          {CSVError.error && <div className="flex-item end wrap width-100">
            <div className="errored message">{CSVError.message}</div>
          </div>}
        </div>}
        {selected && <div className='flex-container row end wrap width-100'>
          {createButton('', 'import', 'Import', (!isActive || saving), saving, async () => await actionImportPSData(), null, 'mar-2')}
          {createButton('', 'cancel', 'Cancel', saving, false, async () => onClose(), null, 'mar-2', null, null, 'red')}
          {bannerError.error && <div className="flex-item end wrap width-100">
            <div className="errored message">{bannerError.message}</div>
          </div>}
        </div>}
      </div>
      <UILIB.TableNew
        name={'PSImport'}
        outerClassName='max-height-600-px'
        className='small'
        overflowX='auto'
        overflowY='auto'    
        header={headerData}
        localQuery={() => constructTable((formData.length) ? formData : [])}
        localRender={[formData]} 
        defaultSettings={defaultSettings} />
    </div>
  </Fragment>
}