
import { useState, memo, Fragment } from 'react';
import UILIB from 'components';
import { timerPromise } from "classes/timers";


const SerialContainer = memo(({ data, disabled, processing, setProcessing, localRender, setLocalRender, onChange }) => 
{ 

  const inputClass = 'textInput fullBorder tableFilter tableInput mar-b0 width-100';

  const [timeout, setTimout] = useState(null);

  const handleUserInput = async (event, row) => {
    row[event.target.name] = event.target.value;
    setLocalRender(!localRender);
    const global = (event.target.name === 'groupName');
    const baseData = { value: event.target.value, field: event.target.name, row: row, global: global };
    await timerPromise(function() { return onChange(baseData); }, 500, timeout, setTimout, setProcessing);
  };

  const generateInput = (fieldName, data, inputClass, defaultValue) => {
    return <UILIB.TextInput 
      type="string" 
      disabled={processing || disabled}
      className={inputClass}
      placeholder={defaultValue} 
      name={fieldName} 
      value={data[fieldName]} 
      onChange={(ev) => handleUserInput(ev, data)} />
  };


  return <Fragment>
          <div className="flex-container row flex width-100">
            <div className={`flex-item start consumablesHub groupContainer-input `}>{generateInput('serialNo', data, inputClass, 'Enter Serial')}</div>
          </div>
        </Fragment>
})
export default SerialContainer
