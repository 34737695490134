import { useState, useEffect, Fragment } from "react"
import { useHistory } from 'react-router-dom'
import axios from "classes/axios"
import UILIB from 'components'
import { formatDateTime } from "classes/format"

const tableHeader = [{
    label: "Import Date",
    value: "createdAt",
    type: "date"
}, {
    label: "Status",
    value: 'status',
    type: 'string',
    options: [{ label: 'All', value: '' }, 'Awaiting Header Definition', 'Import Queued', 'Importing', 'Finished', 'Error']
}, {
    label: 'Total Rows',
    value: 'total',
    type: 'number'
}, {
    label: 'File',
    value: 'filename',
    type: 'string',
    width: 260
}, {
    label: 'User',
    value: 'user',
    type: 'string',
    options: 'auto'
}, {
    label: ' ',
    value: 'options',
    width: 40
}]

export default function ImportParts() {
    const history = useHistory()
    const [importJobs, setImportJobs] = useState([])
    const [downloadingStatus, setDownloadingStatus] = useState({})
    const [downloading, setDownloading] = useState({})
    const [pageSelect, setPageSelect] = useState(0)
    const [loading, setLoading] = useState(false)

    const getImportJobs = async () => {
        setLoading(true)
        await axios.get('/sales/parts/import').then(response => {
          setImportJobs(response.data)
          setLoading(false)
        })
    }

    const download = async (row) => {

          setDownloading(d => ({ ...d, [row.id]: 1 }))
      
          axios.get('/sales/parts/import/' + row.id + '/download').then((res) => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            
            const fileName = row.origFileName.split(".")
            const newFileName = fileName[0] + ".csv";
    
            link.href = url;
            link.setAttribute('download', newFileName);
            document.body.appendChild(link);
            link.click();

            setDownloading(d => ({ ...d, [row.id]: 0 }))
            setDownloadingStatus(d => ({ ...d, [row.id]: 200 }))

          }).catch((ex) => {
            setDownloading(d => ({ ...d, [row.id]: 2}))
            setDownloadingStatus(d => ({ ...d, [row.id]: JSON.parse(ex.request.response).message.code }))
        })

    }

    useEffect(() => {
        getImportJobs()
    }, [])


    const data = importJobs.map(row => {

        return {
            createdAt: {
                value: formatDateTime(row.createdAt, 'DD/MM/YYYY HH:mm'),
                raw: row.createdAt
            },
            filename: {
                value: 
                <UILIB.Button 
                    className={downloading[row.id] === 2 ? "red width-100 " : "width-100"}
                    style={downloading[row.id] === 2 ? {opacity: "1.0"} : null}
                    onClick={() => download(row)} 
                    value={
                        (!downloading[row.id] && row.origFileName) ||
                        (downloading[row.id] === 1 && <UILIB.Loading type={2}/>) ||
                        (downloading[row.id] === 2 && <div><p>{"FTP Error " + downloadingStatus[row.id]}</p></div>)}
                />,
                raw: row.origFileName
            },
            status: {
                value: row.status,
                raw: row.status
            },
            total: {
                value: row.totalLines,
                raw: row.totalLines
            },
            user: {
                value: row.AccountUser ? row.AccountUser.displayName : 'Unknown',
                raw: row.AccountUser ? row.AccountUser.displayName : 'Unknown'
            },
            options: {
                value: <div>
                    {row.status !== 'Awaiting Header Definition' && <UILIB.Button className="secondary" onClick={() => history.push('/saleshub/partsManagement/import/' + row.id)} value="View" />}
                    {row.status === 'Awaiting Header Definition' && <UILIB.Button className="secondary" onClick={() => history.push('/saleshub/partsManagement/import/setup/' + row.id)} value="Setup" />}
                </div>
            }
        }
    })


    return <Fragment>
            <div className="flex-container row mar-t10 mar-l5 mar-r5">
                <div className="col-xs-6 mar-b20">
                    <UILIB.Button className="grey mar-r10" onClick={() => history.push('/saleshub/partsManagement')} value="Back" />
                    <UILIB.Button onClick={() => history.push('/saleshub/partsManagement/import/setup')} value="Import" />
                </div>
                <div className="col-xs-12">
                    <UILIB.Paper>
                        <UILIB.Table
                            className="small" 
                            header={tableHeader}
                            data={data}
                            defaultOrderBy="createdAt"
                            defaultOrderDir="DESC"
                            sortable={true}
                            maxRows={15}
                            filter={true}
                            pageSelect={pageSelect}
                            setPageSelect={setPageSelect}
                            loading={loading}
                        />
                    </UILIB.Paper>
                </div>
            </div>
          </Fragment>
}