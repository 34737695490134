import { useEffect, useRef, Fragment, useState } from 'react';
import { getMeterNames } from '../../../../classes/helpers'
import UILIB from 'components'


const meterTypes = [
  { value: 1, label: 'Mono' },
  { value: 2, label: 'Colour' }
]


const headerData = [
  { label: "Meter Name", value: "meterName", align: "left", type: 'string', width: "45%" },
  { label: "Meter Type", value: "meterType", align: "left", type: 'number', width: "25%" },
  { label: "Meter Reading", value: "meterReading", align: "left", type: 'number', width: "25%" },
  { label: "", value: "add", align: "left", type: 'number', width: "1%" },
  { label: "", value: "remove", align: "left", type: 'number', width: "1%" }
]


export default function BlockMetersTable({ emptyRow, pleaseSelect, canEdit, isDisabled, loading, data }) {

    const [tableData, setTableData] = useState([]);
    const meterTypeLocal = useRef([pleaseSelect, ...meterTypes]);
    const meterNamesLocal = useRef([]);
    const [reading, setReading] = useState({});
    const [type, setType] = useState({});
    const [name, setName] = useState({});
    const [render, setRender] = useState(0);


    const changeInput = (event, index, get, set, field) => {
      set({ ...get, [index]: event.target.value });
      const newRow = data[index];
      newRow[field] = event.target.value;
    }

    const alterRow = (type, index) => {
      if(type === 0) data.push(structuredClone(emptyRow));
      if(type === 1) data.splice(index, 1);
      setRender(!render);
    }


    function renderTable() {

      if(!data) return;

      const tableData = [];

      data.forEach((row, index) => {
        const pointerAdd = (canEdit && isDisabled && data.length < 10) ? 'pointer' : 'none';
        const pointerRemove = (canEdit && isDisabled && data.length > 1) ? 'pointer' : 'none';
        const filtered = meterNamesLocal.current.map(x => data.some(s => s.meterName === x.label) ? { ...x, consumed: true }  : { ...x, consumed: false });
        tableData.push({
          meterName: { 
            value: <UILIB.Select behaviourType={1} disabled={!canEdit || !isDisabled} data={filtered} value={row.meterName} onChange={(ev) => changeInput(ev, index, name, setName, 'meterName')} />, 
            raw: 0, tdStyle: { backgroundColor: "#FFFFFF" } 
          }, 
          meterType: { 
            value: <UILIB.Select disabled={!canEdit || !isDisabled} data={meterTypeLocal.current} value={row.meterType} onChange={(ev) => changeInput(ev, index, type, setType, 'meterType')} />, 
            raw: 0, tdStyle: { backgroundColor: "#FFFFFF" } 
          },
          meterReading: { 
            value: <UILIB.TextInput className="textInput input" disabled={!isDisabled} type='number' value={row.meterReading} onChange={(ev) => changeInput(ev, index, reading, setReading, 'meterReading')} />, 
            raw: 0, tdStyle: { backgroundColor: "#FFFFFF" } 
          },
          add: { 
            value: <div title="Add Row" style={{ pointerEvents: pointerAdd, cursor: "pointer" }} onClick={() => alterRow(0, index)} className={"icon-plus center text-primary"} />, 
            raw: 0, tdStyle: { backgroundColor: "#FFFFFF" } 
          },
          remove: { 
            value: <div title="Remove Row" style={{ pointerEvents: pointerRemove, cursor: "pointer" }} onClick={() => alterRow(1, index)} className={"icon-trash2 center text-primary"} />, 
            raw: 0, tdStyle: { backgroundColor: "#FFFFFF" } 
          }
        })
      })

      setTableData(tableData)
    }

    useEffect(() => {
      const actionAsync = async () => {
        const meterNameResult = await getMeterNames();
        meterNamesLocal.current = [pleaseSelect, ...meterNameResult.map(x => { return { value: x.name, label: x.name, consumed: false }})];
        renderTable();
      }
      actionAsync();
      // eslint-disable-next-line
    }, [])

    useEffect(() => {
        renderTable();
    }, [data, loading, render, reading, name, type]);


    return <Fragment>
      <UILIB.Table
        className="small"
        header={headerData}
        data={tableData}
        loading={loading}
        overflowX="auto"
        overflowY="hidden"
        sortable={false}
        filter={false}
        autoResize={false}
        defaultOrderBy="quantity"
        defaultOrderDir="ASC"
        exportEnable={false}
      />
    </Fragment>
}