import { useState, useRef, Fragment } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';
import { formatDateTime } from "classes/format";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setFilterVarsGuardian, getToastMessage, getGridChip, tableContainer, actionOpenDrawer } from '../functions';


export default function GuardianReportTable({ links }) {
  const headerData = [
    { label: '', value: 'id', type: 'delete', filtering: false, minWidth: 20, maxWidth: 20 },
    { label: 'Is a Request', value: 'isRequestTxt', type: 'string', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: 'Type', value: 'statusTxt', type: 'string', filtering: true, minWidth: 30, maxWidth: 30 },
    { label: 'Contact', value: 'emailaddress1', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Name', value: 'fullName', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Email Address', value: 'emailAddress', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Attempts', value: 'checkCount', type: 'string', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: 'Actioned By', value: 'actionedByTxt', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Actioned At', value: 'actionedAtTxt', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Status', value: 'guardianStatusTxt', type: 'string', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: 'Send Email', value: 'sendWelcomeEmail', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 },
    { label: 'View', value: 'ViewContact', type: 'string', filtering: false, minWidth: 25, maxWidth: 25 },
    { label: 'Report', value: 'report', type: 'string', filtering: false, minWidth: 25, maxWidth: 25 }];


  const tablePageDefaults = { deleteEnabled: true, paging: { pages: [10, 20, 50, 100, 200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'id', orderDir: 'DESC' } };
  const dispatch = useDispatch();
  const entityData = useRef([]);
  const welcomeEmailMapped = useRef([]);
  const requestCheckbox = useRef({});
  const [filter, setFilter] = useState({ button: 'Unactioned' });
  const defaultDialogue = { count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [showDeleteDialogue, setShowDeleteDialoge] = useState(defaultDialogue);
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [localRender, setLocalRender] = useState(false);
  const [remoteRender, setRemoteRender] = useState(false);
  const [loading, setLoading] = useState(false);


  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];
  

  const handleButtonsChanged = (buttonName, set) => {
    const newTableFilter = { ...filter };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = '';
    } else {
        newTableFilter.button = buttonName;
    }
    set(newTableFilter);
  };

  const tableDeleteWebhook = (event) => {
    setBannerError(defaultBanner);
    setShowDeleteDialoge({ ids: event, count: event.length, show: true });
  };

  const actionDeleteWebhook = async () => {
    try {
      setLoading(true);
      const requestIdArray = showDeleteDialogue.ids.map(x => Number(x));
      const filtered = entityData.current.result.filter(x => requestIdArray.includes(x.id));
      const result = await Axios.delete('/entities/dynamicsV3_webhook_logs', { data: { entity: filtered } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setShowDeleteDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setRemoteRender(!remoteRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setLoading(false);
  };

  const handleSendEmail = async (row, ref) => {
    try { 
      ref.current = { ...ref.current, [row.id]: true };
      setLocalRender(Math.random());
      const respose = await Axios.post(`/entities/workflow/portals/actionSendWelcomeGuardian/${row.id}`, row);
      if(exceptions.includes(respose.status)) {
        if(respose.status === 400) toast.error(invalidConfig);
        if(respose.status === 404) toast.error(notFoundError); 
      } else {
        entityData.current.result = entityData.current.result.filter(x => Number(x.id) !== Number(row.id));
        toast.info(getToastMessage(row, 0));
      }
      ref.current = { ...ref.current, [row.id]: false };
      setLocalRender(Math.random());
    } catch (err) {
      ref.current = { ...ref.current, [row.id]: false };
      setLocalRender(Math.random());
      toast.error(exceptionError);
    }
  };
  
  async function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

      const allowSelect = ((!Object.values(requestCheckbox.current).some(x => x)) && filter.button !== 'Unactioned');

      const b_hasNotes = Boolean(row.report && row.report.length);
      const notesColour = (b_hasNotes) ? 'colour background-6 red' : 'colour lightGrey';
      const fn_notes = actionOpenDrawer.bind(null, 'ReportDrawer', row, dispatch, b_hasNotes, { get: localRender, set: setLocalRender });

      const fn_viewContact = window.open.bind(null, `${links.dynamics_base_url}main.aspx?app=d365default&forceUCI=1&pagetype=entityrecord&etn=contact&id=${row.contactId}`,'_blank', 'noopener noreferrer');
      const viewContact = <UILIB.Button disabled={!row.contactId} className={"buttom table text-small width-100"} value={'View'} onClick={() => fn_viewContact()} />

      const fn_sendEmail = handleSendEmail.bind(null, row, welcomeEmailMapped);

      const buttonClass = (row.actioned && !row.deletedAt) 
        ? "table colour background orange text-small text-black width-100" : (row.deletedAt) ? "table text-small colour background red width-100" 
        : "table text-small colour background green width-100";
      const buttonText = (row.actioned && !row.deletedAt) 
        ? "Welcome Email Sent" : (row.deletedAt) ? "Unable To Send Email" 
        : "Send Welcome Email";

      const welcomeEmail = <UILIB.Button 
        className={buttonClass}
        value={buttonText}
        loading={welcomeEmailMapped.current[row.id]} 
        onClick={(row.actioned || row.deletedAt || !row.isRequest) ? function () { } : fn_sendEmail} />

      return {
        id: { value: row.id, raw: row.id, disabled: allowSelect },
        emailaddress1: { value: row.emailaddress1, raw: row.emailaddress1 },
        fullName: { value: row.fullName, raw: row.fullName },
        emailAddress: { value: row.emailAddress, raw: row.emailAddress },
        checkCount: { value: row.checkCount, raw: row.checkCount },
        ViewContact: { value: viewContact, raw: row.ViewContact },
        actionedByTxt: { value: row.actionedByTxt, raw: row.actionedByTxt },
        actionedAtTxt: { value: formatDateTime(row.actionedAtTxt), raw: formatDateTime(row.actionedAtTxt) },
        isRequestTxt: { value: getGridChip('isRequest', row.isRequest), raw: row.isRequestTxt },
        guardianStatusTxt: { value: getGridChip('requestStatus', row.guardianStatus), raw: row.guardianStatusTxt },
        statusTxt: { value: getGridChip('status', row.status), raw: row.statusTxt },
        sendWelcomeEmail: { value: welcomeEmail, raw: 0 },
        report: { value: tableContainer(fn_notes, 'icon-pencil', 'View Report', notesColour, true), raw: b_hasNotes }
      }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const filterButtons = setFilterVarsGuardian(filter);

    entityData.current = await Axios.get(`/entities/dynamics_webhook_logs/getSummary/?&$filter=${filterButtons} ${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);

    if (!entityData.current || !entityData.current.result.length || !Array.isArray(entityData.current.result)) return false;

    let checked = {};
    if (entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x => { return checked = { ...checked, [x.id]: false } })
      requestCheckbox.current = checked;
    }

    let welcomeEmail = {};
    if(entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x =>  { return welcomeEmail = { ...welcomeEmail, [x.id]: false } });
      welcomeEmailMapped.current = welcomeEmail;
    }

    return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    {showDeleteDialogue.show && <UILIB.MessageBox
      header={'Delete Requests'}
      text={`Click OK to DELETE the selected ${(showDeleteDialogue.count === 1) ? 'Request' : 'Requests' }`}
      loading={loading}
      errored={bannerError}
      onConfirm={async () => await actionDeleteWebhook()}
      onCancel={() => setShowDeleteDialoge({ ...showDeleteDialogue, show: false })} />}
    <UILIB.Paper className='width-100'>
      <div className='flex-container row mar-b10'>
        <div className='flex-item flex-grow-1 start wrap'>
          <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Unactioned' ? 'primary' : 'secondary')} name='Unactioned' value='Unactioned' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Actioned' ? 'primary' : 'secondary')} name='Actioned' value='Actioned' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Deleted' ? 'primary' : 'secondary')} name='Deleted' value='Deleted' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
        </div>
      </div>
      <div className='width-100'>
        <UILIB.TableNew
          name='PortalGuardianLookup'
          className='small'
          overflowX='auto'
          overflowY='hidden'
          header={headerData}
          deleteQuery={(ev) => tableDeleteWebhook(ev)}
          localQuery={() => constructTable((entityData.current.result && entityData.current.result.length) ? entityData.current.result : [])}
          localRender={[localRender]}
          remoteQuery={getTableData}
          remoteRender={[remoteRender, filter]}
          defaultSettings={tablePageDefaults} />
      </div>
    </UILIB.Paper>
  </Fragment>
}
