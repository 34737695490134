import { Fragment, useState, useRef } from 'react';
import { useDispatch } from "react-redux";
import Axios from 'classes/axios';
import UILIB from 'components';
import { formatDateTime } from 'classes/format';
import { useHistory } from 'react-router-dom';
import { handleSerialClicked, actionOpenDrawer, tableContainer, setFilterVarsVoRequest, voRequestChip } from '../functions'


export default function FailedRequests() {
  const headerData = [
    { label: '', value: 'selected', type: '', filtering: false, minWidth: 20 },
    { label: 'Request #', value: 'requestId', type: 'number', align: 'left', filtering: true, minWidth: 30 },
    { label: 'Request By', value: 'userCreated', type: 'string', align: 'left', filtering: true, minWidth: 70 },
    { label: 'Date', value: 'dateTime', type: 'datetime', align: 'left', filtering: true, minWidth: 70 },
    { label: 'Customer', value: 'customername', type: 'string', filtering: true, minWidth: 70, },
    { label: 'Serial #', value: 'serialnumber', type: 'string', filtering: true, minWidth: 130, maxWidth: 130 },
    { label: 'Acc No.', value: 'accountNumber', type: 'string', filtering: true, minWidth: 70 },
    { label: 'Status Code', value: 'apiResponse', type: 'number', filtering: true, minWidth: 70 },
    { label: 'Retry Count', value: 'retryCount', type: 'number', filtering: true, minWidth: 70 },
    { label: 'Status', value: 'statusTxt', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'API Logs', value: 'logging', type: 'string', filtering: false, minWidth: 60, maxWidth: 60 }]; 


  const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'dateTime', orderDir: 'DESC' } };
  const dispatch = useDispatch();
  const history = useHistory();
  const entityData = useRef({});
  const [filters, setFilter] = useState({ button: 'Failed' });
  const postData = useRef({ data: null, resendId: null, equipmentId: null });
  const requestCheckbox = useRef({});
  const defaultError = { error: false, message: '' };
  const defaultDialogue = { count: 0, show: false };
  const [showDialogue, setShowDialoge] = useState(defaultDialogue);
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(defaultDialogue);
  const [bannerError, setBannerError] = useState(defaultError);
  const [deleteBannerError, setDeleteBannerError] = useState(defaultError);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState(false);
  const [localRender, setLocalRender] = useState(false);
  const [resend, setResend] = useState([]);
  const exceptions = [400,404];

  const invalidConfigGeneric = 'The options selected are invalid, please try again or contact support';
  const invalidConfig = 'The request to send to VO failed, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


  const handleButtonsChanged = (buttonName, set) => {
    const newTableFilter = { ...filters };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = '';
    } else {
        newTableFilter.button = buttonName;
    }
    set(newTableFilter);
  };

  const generateChecked = (entityData) => {
    let checked = {};
    if(entityData && entityData.result) {
      entityData.result.forEach(x =>  { return checked = { ...checked, [x.id]: { checked: false } } })
      return checked;
    }
  };

  const handleDeleteClicked = () => {
    setDeleteBannerError(defaultError);
    setShowDeleteDialogue({ count: Object.values(requestCheckbox.current).filter(x => x.checked).length, show: true }); };

  const handleResendClicked = () => {
    setShowDialoge(defaultDialogue);
    setShowDialoge({ count: Object.values(requestCheckbox.current).filter(x => x.checked).length, show: true }); };

  const actionDeleteRequest = async () => {
    setDeleting(true);
    try {
      const mappedArray = Object.keys(requestCheckbox.current).filter(x => requestCheckbox.current[x].checked).map(x => Number(x));
      const result = await Axios.delete(`/entities/serviceAdmin_failedApiRequests`, { data: { entity: mappedArray.map(x => { return { id: Number(x) } }) } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setDeleteBannerError({ error: true, message: invalidConfigGeneric });
        if(result.status === 404) setDeleteBannerError({ error: true, message: notFoundError });
      } else {
        entityData.current.result = entityData.current.result.filter(x => !mappedArray.includes(Number(x.id)));
        requestCheckbox.current = generateChecked(entityData.current);
        setDeleteBannerError(defaultError);
        setShowDeleteDialogue(defaultDialogue);
        setLocalRender(!localRender);
      }
    } catch (error) {
      console.log(error);
      setDeleteBannerError({ error: true, message: exceptionError });
    }
    setDeleting(false);
  };

  const updateCheckbox = (id, row) => {
    const requestData = JSON.parse(row.originalRequest);
    postData.current = { data: requestData, resendId: id, equipmentId: requestData.equipmentId };
    const checked = !requestCheckbox.current[id].checked;
    Object.keys(requestCheckbox.current).forEach(x => requestCheckbox.current[x] = { ...requestCheckbox.current[x], checked:false });
    requestCheckbox.current = { ...requestCheckbox.current, [id]: { checked: checked } };
    setLocalRender(!localRender);
  };

  const actionProcessData = async () => {
    setProcess(true);
    try {
      const result = await Axios.put(`/entities/workflow/requestSendToVO/${postData.current.equipmentId}/1/${postData.current.resendId}`, postData.current.data);
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setShowDialoge(defaultDialogue);
        setBannerError(defaultError);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    requestCheckbox.current = generateChecked(entityData.current);
    setResend(!resend);
    setProcess(false);
  };

  async function constructTable(apiData) {

    const tableData = apiData.map(row => {

      const fn_logs = actionOpenDrawer.bind(null, 'ViewSendVoDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });

      const bgColour = (Boolean(row.enabled)) ? '' : 'colour background-2 grey';

      const disableSelect = (!Boolean(row.enabled) || row.status === 1);
      
      return {
        selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox disabled={disableSelect} checked={requestCheckbox.current[row.id].checked} type='checkbox' onChange={() => updateCheckbox(row.id, row)}/>} />, class: bgColour },
        requestId: { value: row.requestId, raw: row.requestId, class: bgColour },
        userCreated: { value: row.userCreated, raw: row.userCreated, class: bgColour },
        dateTime: { value: formatDateTime(row.dateTime, "DD/MM/YYYY HH:mm:ss"), raw: row.dateTime, class: bgColour },
        customername: { value: row.customername, raw: row.customername, class: bgColour },
        serialnumber: { value: handleSerialClicked(row, history, null), raw: row.serialnumber, class: bgColour },
        accountNumber: { value: row.accountNumber, raw: row.accountNumber, class: bgColour },
        apiData: { value: row.apiData, raw: row.apiData, class: bgColour },
        apiResponse: { value: row.apiResponse, raw: row.apiResponse, class: bgColour },
        retryCount: { value: row.retryCount, raw: row.retryCount, class: bgColour },
        statusTxt: { value: voRequestChip(row.status), raw: row.statusTxt },
        logging: { value: tableContainer(fn_logs, 'icon-book', 'View Request Body', 'colour background-6 red', true), raw: true }
      }
    })

    return tableData;
  }


  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    setLoading(true);

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const filterButtons = setFilterVarsVoRequest(filters);

    entityData.current = await Axios.get(`/entities/serviceAdmin_failedApiRequests/getSummary/?&$filter=${filterButtons} ${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    requestCheckbox.current = generateChecked(entityData.current);
    
    setLoading(false);

    return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    {showDeleteDialogue.show && <UILIB.MessageBox 
      header={'Delete Request'} 
      loading={deleting}
      text={`Click OK to DELETE the selected ${(showDeleteDialogue.count) > 1 ? 'Requests' : 'Request'}`} 
      onConfirm={async () => await actionDeleteRequest(showDeleteDialogue.requestIds)} 
      onCancel={() => { setShowDeleteDialogue({ ...showDeleteDialogue, show: false }); setDeleteBannerError(defaultDialogue); }} 
      errored={deleteBannerError} />}
    {showDialogue.show && <UILIB.MessageBox 
      header={'Resend To VO'} 
      loading={process}
      text={`Click OK to RESEND the selected ${(showDialogue.count) > 1 ? 'Requests' : 'Request'}`} 
      onConfirm={async () => await actionProcessData()} 
      onCancel={() => { setShowDialoge({ ...showDialogue, show: false }); setBannerError(defaultError); }} 
      errored={bannerError} />}
    <div className="flex-container row width-100 mar-b15 mar-t10">    
      <UILIB.Paper className='flex-item row width-100 mar-b20'>
        <div className='flex-item row flex-grow-1 mar-b10'>
          <div className='flex-item flex-grow-1 start wrap'>
            <UILIB.Button className={'mar-r5 small ' + (!filters.button || filters.button === 'Failed' ? 'primary' : 'secondary')} name='Failed' value='Failed' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
            <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filters.button || filters.button === 'Completed' ? 'primary' : 'secondary')} name='Completed' value='Completed' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          </div>
        </div>
        <div className="flex-item row end">
          <div className="flex-container row end">
            <UILIB.Button 
              value="Delete" 
              className="button red mar-b5" 
              disabled={loading || process || !Object.values(requestCheckbox.current).some(x => x.checked)} 
              onClick={() => handleDeleteClicked()} />
          </div>
          <div className="flex-container row end">
            <UILIB.Button 
              value="Resend" 
              className="button secondary mar-b5" 
              disabled={loading || process || !Object.values(requestCheckbox.current).some(x => x.checked)} 
              onClick={() => handleResendClicked()} />
          </div>
        </div>
        <div className="width-100">
          <UILIB.TableNew
            name='FailedVORequests'
            className='small'
            overflowX='auto'
            overflowY='hidden'    
            header={headerData}
            localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
            localRender={[localRender]}
            remoteQuery={getTableData}
            remoteRender={[resend, filters]}
            defaultSettings={tablePageDefaults} />
        </div>
      </UILIB.Paper>
    </div>
  </Fragment>

}