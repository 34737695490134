import UILIB from 'components';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Footer() {
    const history = useHistory();
    const yearNow = new Date().getFullYear();
    const copyright = `© ${yearNow} Agilico`;
    const releaseVersion = useSelector((state) => state.releaseVersion);
    
    return <div className='flex-container row between footerContainer'>
        <div className='flex-item mar-l10 footerChild'>Version No. {releaseVersion}</div>
        <div className='flex-container row footerChild'>  
          <div className='icon-document2 flex-item center mar-r5' />
          <div className='flex-item mar-r10'>
            <UILIB.Link onClick={() => { history.push('/releaseNotes')}}><span style={{ fontSize: 14 }}>View Release Notes</span></UILIB.Link>
          </div>
          <div className='icon-wrench flex-item center mar-r5' />
          <div className='flex-item mar-r10'>
            <a href = "mailto: app.support@agilico.co.uk">Raise Support Ticket</a>
            </div>
          <div className='icon-bubble-user flex-item center mar-r5' />
          <div className='flex-item'>
            <UILIB.Link href={`https://forms.office.com/r/1V0NPz2K66`} rel='noreferrer' target='_blank'><div>Suggestions</div>
            </UILIB.Link>
          </div>
        </div>
        <div className='flex-item mar-r10 footerChild'>{copyright}</div>
    </div>
}
