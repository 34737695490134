import { useState, useEffect, useRef, Fragment } from 'react';
import { permissionDecider } from 'classes/permissions';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getEmptyFormObject } from '../consumablesHub/functions';
import Modules from './Modules';
import UILIB from 'components';


export default function RequestConsumables(props) {

  const history = useHistory();
  const location = useLocation();
  const account = useSelector((state) => state.account);
  const localPermissions = useRef({});
  const permissions = useSelector((state) => state.permissions);
  const permissionsData = useRef([{ name: 'requestConsumablesHub', hubName: 'Request Consumables Hub', routeName: location.pathname }]);
  const [entityLookup, setEntityLookup] = useState({});
  const [formData, setFormData] = useState(getEmptyFormObject);
  const [showSummary, setShowSummary] = useState({ show: false, data: [] });
  const loaded = (Object.keys(entityLookup).length);
  const [render, setRender] = useState(false);


  useEffect(() => {
    const actionAsync = async () => {
      await permissionDecider(permissions, account, permissionsData.current, localPermissions.current);
      await props.permissions.generalAccess();
    }
    actionAsync();
  }, []);

  
  return <Fragment>
    <div className="flex-container column mar-t10 mar-r5 mar-l5">
      <UILIB.Paper className='flex-item row width-100 mar-b20'>
        <div className='flex-item start width-100 mar-b20'><h3>{`Request Consumables ${(entityLookup.customerName) ? ` - ${entityLookup.customerName}` : ''}`}</h3></div>
        <div className='flex-item start'>
          <UILIB.Button value="Back" className="grey" title="return to hub selection" onClick={() => history.push('/')} />
        </div>
      </UILIB.Paper>
      {!showSummary.show && <div className='flex-item row width-100 mar-b20'>
        <Modules.SiteSearch entityLookup={{ get: entityLookup, set: setEntityLookup }} setFormData={setFormData} />
      </div>}
      {(Boolean(loaded) && !showSummary.show) && <UILIB.Paper className='flex-container row mar-b5'>
        <Modules.RequestForm entityLookup={entityLookup} render={render} setRender={setRender} formData={formData} setFormData={setFormData} showSummary={showSummary} setShowSummary={setShowSummary} />
        <Modules.DevicesRequired entityLookup={entityLookup} render={render} setRender={setRender} formData={formData} setFormData={setFormData} />
        <Modules.RecentlyDespatched entityLookup={entityLookup} />
      </UILIB.Paper>}
      {(Boolean(loaded) && showSummary.show) && <UILIB.Paper className='flex-container row center width-100 pad-l5 pad-r5'>
        <Modules.Summary entityLookup={entityLookup} setEntityLookup={setEntityLookup} setShowSummary={setShowSummary} data={showSummary.data} history={history} />
      </UILIB.Paper>}
    </div>
  </Fragment>
}