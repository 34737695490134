import UILIB from 'components'
import { memo } from 'react';

const SelectAll = memo(({ checked, setChecked, selectAll, tableData, col, render, setRender, selectQuery }) => {

  const pageIds = tableData.current.map(x => Number(x[col?.value]?.value));
  if(pageIds.length) selectAll.current = !Object.keys(checked).filter(x => pageIds.includes(Number(x))).some(x => !checked[Number(x)]); 

  const updateCheckbox = () => {
    selectAll.current = !selectAll.current;
    Object.keys(checked).filter(x => pageIds.includes(Number(x))).forEach(x => checked[Number(x)] = selectAll.current);
    if(selectQuery) selectQuery(checked);
    setChecked(checked);
    setRender(!render);
  };

  return <UILIB.Checkbox disabled={false} checked={selectAll.current} type='checkbox' onChange={() => updateCheckbox()} />

})
export default SelectAll