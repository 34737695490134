import { useState, useEffect, useRef, memo, Fragment } from 'react';
import Axios from 'classes/axios';
import Modules from '../Modules';
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getSubmitConsumablesErrors } from 'classes/formErrors/repository';
import { createSelectInput, createTextInput, createTextArea, createButton } from 'classes/controls/formControls';
import { getYesNo, getDeviceNeedsToner, getEmptyFormObject ,getEmptyStatsObject, getCustomerGradingClass } from '../../consumablesHub/functions';


const BatchedOrders = memo(({ entityLookup, formData, setFormData, showSummary, setShowSummary }) => {

  const entityData = useRef({});
  const appendForm = useRef({});
  const defaultBanner = { error: false, message: '' };
  const [formErrors, setFormErrors] = useState(getEmptyFormObject);
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [stats, setStats] = useState(getEmptyStatsObject);
  const [constactSelect, setConstactSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];


  const actionUpdateField = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  const actionChangeContact = (event) => {
    const found = entityData.current.contact.find(x => Number(x.Id) === Number(event.target.value));
    if(found) {
      const baseData = { [event.target.name]: event.target.value, ...found };
      setFormData({ ...formData, ...baseData }); 
    } else if (Number(event.target.value) === 0 || Number(event.target.value) === -1) {
      const baseData = { FullName: '', PhoneNumber: '', EmailAddress: '' };
      setFormData({ ...formData, ...baseData, [event.target.name]: event.target.value }); 
    }
  }

  const actionSubmitRequest = async () => {
    setSaving(true);
    setBannerError(defaultBanner);
    const formResult = await checkFormErrors(getSubmitConsumablesErrors(formData), setFormErrors, null, null, formData, null);
    if(formResult.errorCount === 0) {
      try {
        const result = await Axios.post(`/entities/workflow/actionRequestConsumables/`, formData);
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          setSaving(false);
          setBannerError(defaultBanner);
          setShowSummary({ ...showSummary, show: true, data: result.data.result });
        }
      } catch (err) {
        console.log(err);
        setSaving(false);
        setBannerError({ error: true, message: exceptionError });
      }
    }
    setSaving(false);
  };

  useEffect(() => { 
    const actionAsync = async () => {

      if(!entityLookup.customerId) return;

      setLoading(true);

      const contactSelect = '&$select=Id and FullName and PhoneNumber and EmailAddress';
      const contactOrder = '&$order=FullName&$direction=ASC';

      entityData.current.grading = await Axios.get(`/entities/storeCustomValues/?&$filter=ParentId eq ${entityLookup.customerId} and DefinitionId eq 1`).then(api => api.data.result);
      entityData.current.devices = await Axios.get(`/entities/equipments/?&$filter=CustomerId eq ${entityLookup.customerId}&$count=true`).then(api => api.data.result);
      entityData.current.customer = await Axios.get(`/entities/Customer/${entityLookup.customerId}`).then(api => api.data.result);
      entityData.current.contact = await Axios.get(`/entities/customerPersons/?&$filter=CustomerId eq ${entityLookup.customerId} and DeletedDate is null ${contactSelect}${contactOrder}`).then(res => res.data.result);

      const statData = { data: { 
          grading: { class: 'colour background-8 lightGrey', primary: '' }, 
          onStop: { class: 'colour background-8 lightGrey', primary: '' }, 
          isActive: { class: 'colour background-8 lightGrey', primary: '' }, 
          devices: { class: 'colour background-8 lightGrey', primary: 'This Customer has', secondary: '0 Devices' } } };

      if(entityData.current.grading.length) {
        const primary = entityData.current.grading[0].Value;
        statData.data.grading = { primary: primary, class: getCustomerGradingClass(primary) }; 
        appendForm.current.customerGrade = primary }

      if(entityData.current.devices.length) {
        const primary = 'This Customer has';
        const secondary = `${entityData.current.devices[0].count} Devices`;
        statData.data.devices = { primary: primary, secondary: secondary, class: 'colour background-8 lightGrey' } }

      if(entityData.current.customer.length) {
        const onStop = entityData.current.customer[0].IsOnStop;
        const primary = `This Customer ${(onStop) ? 'is On Stop' : 'is not On Stop'}`;
        statData.data.onStop = { primary: primary, class: `colour ${(onStop) ? 'background-4 red' : 'background-8 lightGrey'}` } }

      if(entityData.current.customer.length) {
        const isActive = entityData.current.customer[0].IsActive;
        const primary = `This Customer ${(isActive) ? 'is Active' : 'is not Active'}`;
        statData.data.isActive = { primary: primary, class: `colour ${(!isActive) ? 'background-4 red' : 'background-8 lightGrey'}` } }

      if(entityData.current.contact.length) {
        const select = { value: -1, label: "Select" };
        const otherContact = { label: '* Other Contact *', value: 0 };
        const baseData = [select, ...entityData.current.contact.map(x => { return { label: x.FullName, value: x.Id } }), otherContact]
        appendForm.current.contacts = baseData;
        setConstactSelect(baseData); 
      } else {
        const select = { value: -1, label: "Select" };
        const otherContact = { label: '* Other Contact *', value: 0 };
        const baseData = [select, otherContact];
        appendForm.current.contacts = baseData;
        setConstactSelect(baseData); 
      }

      const totalDevices = (entityData.current.devices.length) ? entityData.current.devices[0].count : 0;
      const customer = (entityData.current.customer.length) ? entityData.current.customer[0] : null;

      setFormData({ ...formData, ...appendForm.current, totalDevices: totalDevices, customerRecord: customer });
      setStats({ ...stats, ...statData.data });
      setLoading(false);
      
    }
    actionAsync();
  }, [entityLookup]);


  return <Fragment>
    <div className='flex-container consumablesHub requestConsumables-form-reverse'>
      <div className='flex-item column wrap consumablesHub requestConsumables-form'>
        <div className='flex-container row start wrap evenly width-100'>
          {createSelectInput('Contact Quick Select', 'quickContact', loading, formData.quickContact, constactSelect, (ev) => actionChangeContact(ev), null, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
          {createTextInput('Contact Name', 'FullName', (loading || Number(formData.quickContact) === -1), formData.FullName, (ev) => actionUpdateField(ev), formErrors, 'width-100', null, null, 'fullBorder border-colour-grey-4')}
          {createTextInput('Telephone No', 'PhoneNumber', (loading || Number(formData.quickContact) === -1), formData.PhoneNumber, (ev) => actionUpdateField(ev), formErrors, 'flex-grow-1', null, null, 'fullBorder border-colour-grey-4')}
          {createTextInput('Email Address', 'EmailAddress', (loading || Number(formData.quickContact) === -1), formData.EmailAddress, (ev) => actionUpdateField(ev), formErrors, 'flex-grow-1', null, null, 'fullBorder border-colour-grey-4')}
        </div>
        <div className='flex-container row start wrap width-100'>
          {createTextArea('Notes', 'notes', (loading || Number(formData.quickContact) === -1), formData.notes, (ev) => actionUpdateField(ev), null, 'flex-grow-1', null, null, 'height-100-px')}
        </div>
        <div className='flex-container row start wrap evenly width-100 mar-t2'>
          {createSelectInput(<div className="colour text red">FOC JIT Toner</div>, 'focJit', (loading || Number(formData.quickContact) === -1), formData.focJit, getYesNo(),  (ev) => actionUpdateField(ev), formErrors, 'flex-grow-1', null, null, 'fullBorder border-colour-grey-4')}
          {createSelectInput(<div className="colour text red">Urgent Order</div>, 'urgentOrder', (loading || Number(formData.quickContact) === -1), formData.urgentOrder, getDeviceNeedsToner(), (ev) => actionUpdateField(ev), formErrors, 'flex-grow-1', null, null, 'fullBorder border-colour-grey-4')}
          {createButton('', 'submitRequest', 'Submit Request', (saving || !formData.selectedDevices.length || Number(formData.quickContact) === -1), saving, async () => await actionSubmitRequest(), null, 'flex-item flex-grow-1')}
        </div>
        {bannerError.error && <div className="flex-container flex-grow-1 end width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
      <div className='flex-container column consumablesHub requestConsumables-stats mar-t5'>
        {<Modules.CustomerStats data={stats.grading} type={1} loading={loading} className={stats.grading.class} />}
        {<Modules.CustomerStats data={stats.devices} type={0} loading={loading} className={stats.devices.class} secondaryClass='font-weight-bold' />}
        {<Modules.CustomerStats data={stats.onStop} type={0} loading={loading} className={stats.onStop.class} secondaryClass='font-weight-bold' />}
        {<Modules.CustomerStats data={stats.isActive} type={0} loading={loading} className={stats.isActive.class} secondaryClass='font-weight-bold' />}
      </div>
    </div>
  </Fragment>
})
export default BatchedOrders