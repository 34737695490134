import { Fragment } from 'react'


export default function Avatar({ className, account, color, disabled = false, onClick = () => { } }) {
    let classNames = "avatar";

    if (className) {
        classNames += " " + className;
    }
    let shortName = account.displayName ? account.displayName.substring(0, 1) : "";
    const names = account.displayName ? account.displayName.split(' ') : []
    if (names.length > 1) {
        shortName = names.slice(0, 2).map(n => n.substring(0, 1)).join('')
    }
    let bgColor = color
    if (!bgColor) {
        bgColor = account.userAvatarScheme;
    }
    if (disabled) {
        bgColor = '#696969'
    }
    return <Fragment>
        <span title={account.displayName} className={classNames} alt={account.displayName} style={{ backgroundColor: bgColor }} onClick={onClick}>{shortName}</span>
    </Fragment>
}
