import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components'

const headerData = [
  { label: "#", value: "id", type: 'string', width: '50px', align: 'left' },
  { label: "Make", value: "make", type: 'string', width: '120px', align: 'left' },
  { label: "P11D", value: "p11d", type: 'string', width: '120px', align: 'left' },
  { label: "Model", value: "model", type: 'string', width: '120px', align: 'left' },
  { label: "Office", value: "office", type: 'string', width: '120px', align: 'left' },
  { label: "Region", value: "region", type: 'string', width: '150px', align: 'left' },
  { label: "Fuel Type", value: "fuelType", type: 'number', width: '100px', align: 'left' },
  { label: "Emissions", value: "emissions", type: 'string', width: '100px', align: 'left' },
  { label: "Engine Size", value: "engineSize", type: 'number', width: '100px', align: 'left' },
  { label: "Registration", value: "registration", type: 'string', width: '120px', align: 'left' },
  { label: "Message", value: "reason", type: 'string', width: '250px', align: 'left' },
  { label: "Result", value: "operationType", type: 'number', width: '80px', align: 'left' }
]


export default function VehicleImportTable(props) {

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState([]);

  const tablefilter = props.tableSortHandler.filter === 'View All Rows' ? null : props.tableSortHandler.filter;

  const tableDataResult = tableData.filter(f => !tablefilter ? f : tablefilter.toLowerCase() === f.operationType.toLowerCase()).map(row => {

    let operationType = { data: <UILIB.Chip className="table grey text-small width-100" value="Unknown" />, value: 0 };
    if (row.operationType === 'CREATE') operationType = { data: <UILIB.Chip className="table green text-small width-100" value="Create" />, value: 1 };
    if (row.operationType === 'UPDATE') operationType = { data: <UILIB.Chip className="table orange text-small width-100" value="Update" />, value: 2 };
    if (row.operationType === 'FAIL') operationType = { data: <UILIB.Chip className="table red text-small width-100" value="Failed" />, value: 3 };

    return {
      id: { value: row.id, raw: row.id },
      make: { value: row.line.make, raw: row.line.make },
      p11d: { value: row.line.p11d, raw: row.line.p11d },
      model: { value: row.line.model, raw: row.line.model },
      office: { value: row.line.office, raw: row.line.office },
      region: { value: row.line.region, raw: row.line.region },
      fuelType: { value: row.line.fuelType, raw: row.line.fuelType },
      emissions: { value: row.line.emissions, raw: row.line.emissions },
      engineSize: { value: row.line.engineSize, raw: row.line.engineSize },
      registration: { value: row.line.registration, raw: row.line.registration },
      operationType: { value: operationType.data, raw: operationType.value },
      reason: { value: row.reason, raw: row.reason }
    }
  })

  useEffect(() => { 
    setLoading(true);
    setTableData(props.uploadedRowsModal.fleetHubImportRow);
    setLoading(false);
  }, []);


  return (
    <Fragment>
      <UILIB.Table
        className='small' 
        overflowX='auto'
        overflowY='auto'
        fitToParent={true}
        loading={loading}
        header={headerData}
        data={tableDataResult}
        sortable={true}
        filter={true}
        resetFilters={false}
        maxRows={20}
        autoResize={false}
        defaultOrderBy='importDate'
        defaultOrderDir='DESC'
        exportEnable={true} 
      />
    </Fragment>
  );
}