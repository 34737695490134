import { Fragment } from 'react';
import UILIB from 'components';
import { leaseCompanies } from 'classes/quotesHelpers';
import { updateDealData, calcActiveFieldMain, getDealPricing, updateLeasePeriodPayment } from '../functions';

export default function LeaseDetails(props)
{
  const doErrors = props.formErrors && Object.values(props.formErrors).length;

  function addTitle(title, mandatory) {
    return (
      <td width="200">
        <div className="flex-container row nowrap">
          <div className="flex-item flex-grow-1 start">{title}</div>
          <div className="flex-item end">{Boolean(doErrors && mandatory) && <span className='text red'>*</span>}</div>
        </div>
      </td>);
  }

  return <Fragment>
    <UILIB.Paper className="flex-container row height-100">
      {!props.fieldWorkflowObj.hidePanelRight && <div className="width-100">   
        <table width="100%" cellPadding="0" cellSpacing="0" className="subTable">
          <tbody>
            {props.fieldWorkflowObj.leaseCompanyInfo && <tr>
              {addTitle('Lease Company', true)}
              <td>
                <UILIB.Select name="leaseCompanyInfo"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'leaseCompanyInfo', 'leaseCompanyInfo')} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) } 
                  value={props.ourDeal.leaseCompanyInfo}
                  errored={props.formErrors && props.formErrors.leaseCompanyInfo}
                  data={leaseCompanies} />
              </td>
            </tr>}
            {props.ourDeal.leaseCompanyInfo === 'Other' && <tr>
              {addTitle('Other Lease Company', false)}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'otherLeaseCompany', 'otherLeaseCompany')} 
                  name="otherLeaseCompany" 
                  value={props.ourDeal.otherLeaseCompany} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) }  />
              </td>
            </tr>}
            {props.fieldWorkflowObj.companyPaymentLength && <tr>
              {addTitle('Lease Agreement Length (Months)', true)}
              <td>
                <UILIB.TextInput
                  className="textInput input"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'companyPaymentLength', 'companyPaymentLength')} 
                  placeholder="0"
                  type="number"
                  name="companyPaymentLength"
                  value={props.ourDeal.companyPaymentLength}
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) } 
                  errored={props.formErrors && props.formErrors.companyPaymentLength} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.leaseRate && <tr>
              {addTitle('Lease Rate', true)}
              <td>
                <UILIB.TextInput
                  className="textInput input"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'leaseRate', 'leaseRate')} 
                  placeholder="0"
                  name="leaseRate"
                  type="number"
                  value={props.ourDeal.leaseRate}
                  onChange={async event => {
                    updateDealData({ event: event, type: 0, timeout: 500, props: props}) 
                    await getDealPricing(props.ourDeal.dealID, props.ourDeal, props.setOurDeal);
                    await updateLeasePeriodPayment(props.ourDeal, props.setOurDeal);
                  }}
                  errored={props.formErrors && props.formErrors.leaseRate} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.leasePeriodPayment && <tr>
              {addTitle('Lease Period Payment', false)}
              <td>
                <span className="mar-l10">{props.ourDeal.leasePeriodPayment}</span>
              </td>
            </tr>}
            {props.fieldWorkflowObj.dealCurrency && <tr>
              {addTitle('Sales Invoice Currency', true)}
              <td>
                <UILIB.Select 
                  name="dealCurrency"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'dealCurrency', 'dealCurrency')} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) } 
                  value={props.ourDeal.dealCurrency}
                  errored={props.formErrors && props.formErrors.dealCurrency}
                  data={[
                      { label: "GBP", value: "GBP" },
                      { label: "Euro", value: "Euro" },
                      { label: "Dollar", value: "Dollar" }]} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.rvIncluded && <tr>
              {addTitle('RV Included', true)}
              <td>
                <UILIB.Select name="rvIncluded"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'rvIncluded', 'rvIncluded')} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) } 
                  value={props.ourDeal.rvIncluded}
                  errored={props.formErrors && props.formErrors.rvIncluded}
                  data={[
                      { label: "Please Select", value: "-1" },
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" }]} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.rvIncluded && props.ourDeal.rvIncluded === 'Yes' && <tr>
              {addTitle('RV%', true)}
              <td>
                <UILIB.TextInput
                  className="textInput input"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'rvPercent', 'rvPercent')} 
                  placeholder="0"
                  name="rvPercent"
                  value={props.ourDeal.rvPercent}
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) } 
                  type="number"
                  errored={props.formErrors && props.formErrors.rvPercent} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.rebateValue && <tr>
              {addTitle('Rebate Value', true)}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'rebateValue', 'rebateValue')} 
                  placeholder="0" 
                  name="rebateValue" 
                  value={props.ourDeal.rebateValue} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) }  
                  type="number" 
                  errored={props.formErrors && props.formErrors.rebateValue} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.rebatePercent && <tr>
              {addTitle('Rebate Percent', true)}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'rebatePercent', 'rebatePercent')} 
                  placeholder="0" 
                  name="rebatePercent" 
                  value={props.ourDeal.rebatePercent} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) }  
                  type="number" 
                  errored={props.formErrors && props.formErrors.rebatePercent} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.isMember && <tr>
              {addTitle('Is Member', true)}
              <td>
                <UILIB.Select 
                  name="isMember"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'isMember', 'isMember')} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) } 
                  value={props.ourDeal.isMember}
                  errored={props.formErrors && props.formErrors.isMember}
                  data={[
                      { label: "Please Select", value: "-1" },
                      { label: "Yes", value: 1 },
                      { label: "No", value: 0 }]} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.cpcLocation && <tr>
              {addTitle('CPC Location', true)}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'cpcLocation', 'cpcLocation')} 
                  name="cpcLocation" 
                  value={props.ourDeal.cpcLocation} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) }  
                  errored={props.formErrors && props.formErrors.cpcLocation} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.cpcLot && <tr>
              {addTitle('CPC Lot', true)}
              <td>
                <UILIB.Select 
                  name="cpcLot" 
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'cpcLot', 'cpcLot')} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) }  
                  value={props.ourDeal.cpcLot} 
                  errored={props.formErrors && props.formErrors.cpcLot} 
                  data={[
                    { label: "Please Select", value: -1 },
                    { label: "Lot 1", value: 1 },
                    { label: "Lot 2", value: 2 }]}/>
              </td>
            </tr>}
            {props.fieldWorkflowObj.serviceTerm && <tr>
              {addTitle('Service Term', true)}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'serviceTerm', 'serviceTerm')} 
                  placeholder="0" 
                  name="serviceTerm" 
                  value={props.ourDeal.serviceTerm} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) }  
                  type="number" 
                  errored={props.formErrors && props.formErrors.serviceTerm} />
              </td>
            </tr>}
            {props.fieldWorkflowObj.scottishProcurement && <tr>
              {addTitle('Mini Competition or Direct Buy', true)}
              <td>
              <UILIB.Select name="miniCompOrDirectBuy"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'miniCompOrDirectBuy', 'miniCompOrDirectBuy')} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) } 
                  value={props.ourDeal.miniCompOrDirectBuy}
                  errored={props.formErrors && props.formErrors.miniCompOrDirectBuy}
                  data={[
                      { label: "Please Select", value: "-1" },
                      { label: "MC", value: "MC" },
                      { label: "DB", value: "DB" }]} />
              </td>
            </tr>
            }
            {props.fieldWorkflowObj.scottishProcurement && <tr>
              {addTitle(`Contracting Authority's Tender or Contract Ref`, false)}
              <td>
                <UILIB.TextInput 
                  className="textInput input"
                  disabled={!props.isActive || calcActiveFieldMain(props.activeFields, 'tenderOrContractRef', 'tenderOrContractRef')}  
                  name="tenderOrContractRef" 
                  value={props.ourDeal.tenderOrContractRef} 
                  onChange={event => updateDealData({ event: event, type: 0, timeout: 500, props: props}) }  
                  errored={props.formErrors && props.formErrors.tenderOrContractRef} />
              </td>
            </tr>
            }
          </tbody>
        </table>
      </div>}
    </UILIB.Paper>
  </Fragment>
}