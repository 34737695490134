import { useEffect, useState } from 'react'
import axios from 'classes/axios'
import UILIB from 'components'

export default function ImportErrors({ importJob }) {
    const [errors, setErrors] = useState(null)
    const [showCleared, setShowCleared] = useState(false)

    const clear = async (row) => {
        const res = await axios.put('/sales/parts/import/' + importJob.id + '/errors/' + row.id + '/clear')
        const index = errors.findIndex(e => e.id === row.id)
        const errs = [...errors]
        errs[index] = res.data
        setErrors(errs)
    }

    useEffect(() => {
        axios.get('/sales/parts/import/' + importJob.id + '/errors').then(res => {
            setErrors(res.data)
        })
        // eslint-disable-next-line
    }, [])
    const cleared = errors ? errors.filter(e => e.cleared).length : 0
    const arr = (errors || []).filter(e => showCleared ? e : !e.cleared)

    return (
        <div className="row">
            <div className="col-xs-12 mar-b20">
                <div className="text-large text-center">Failed Imports</div>
                {!errors && <UILIB.Loading type={3} />}

            </div>
            {Boolean(errors) && <div className="col-xs-12">
                <div className="text-center mar-b20">
                    <UILIB.Button className="small primary" value={showCleared ? `Hide Cleared (${cleared})` : `Show Cleared (${cleared})`} onClick={() => setShowCleared(c => !c)} />
                </div>
                {!Boolean(arr.length) && <div className="text-center">No Failed Imports</div>}
                {Boolean(arr.length) && <div className="row">
                    {arr.map(row => {
                        const part = rowToObj(row, importJob.headerDefinition)
                        return <div key={row.id} className="col-xs-12 mar-b10 border-bottom" style={{ paddingBottom: 10 }}>
                            {part}
                            <div className="text-center">{row.reason}</div>
                            <div className="text-center">
                                <UILIB.Button disabled={row.cleared} className="small primary" value={row.cleared ? "cleared" : "clear"} onClick={() => clear(row)} />
                            </div>
                        </div>
                    })}
                </div>}
            </div>}

        </div>
    )
}


function rowToObj(row, mapping) {
  const line = JSON.parse(row.line);
  const mapped = JSON.parse(mapping);
  return <div className="mar-b10">{Object.keys(mapped).map(x => {
      return <div key={`${row.id}_${x}`}>{x}: <span className="text-grey">{line[x]}</span> </div>
  })}
  </div>
}