import { Fragment, useEffect, useState } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';


export default function ConsumablesLookUpDaysToEmpty({ serialNumber }) {
    const [loadingData, setLoadingData] = useState(true);
    const [showEmpty, setShowEmpty] = useState(false);
    const [showYield, setShowYield] = useState(false);

    const [daysInBlack, setDaysInBlack] = useState('?');
    const [printsPerDayBlack, setPrintsPerDayBlack] = useState('?');
    const [daysToEmptyBlack, setDaysToEmptyBlack] = useState('?');
    const [yieldExpectedBlack, setYieldExpectedBlack] = useState('?');
    const [yieldDeltaBlack, setYieldDeltaBlack] = useState('?');

    const [daysInCyan, setDaysInCyan] = useState('?');
    const [printsPerDayCyan, setPrintsPerDayCyan] = useState('?');
    const [daysToEmptyCyan, setDaysToEmptyCyan] = useState('?');
    const [yieldExpectedCyan, setYieldExpectedCyan] = useState('?');
    const [yieldDeltaCyan, setYieldDeltaCyan] = useState('?');

    const [daysInMagenta, setDaysInMagenta] = useState('?');
    const [printsPerDayMagenta, setPrintsPerDayMagenta] = useState('?');
    const [daysToEmptyMagenta, setDaysToEmptyMagenta] = useState('?');
    const [yieldExpectedMagenta, setYieldExpectedMagenta] = useState('?');
    const [yieldDeltaMagenta, setYieldDeltaMagenta] = useState('?');

    const [daysInYellow, setDaysInYellow] = useState('?');
    const [printsPerDayYellow, setPrintsPerDayYellow] = useState('?');
    const [daysToEmptyYellow, setDaysToEmptyYellow] = useState('?');
    const [yieldExpectedYellow, setYieldExpectedYellow] = useState('?');
    const [yieldDeltaYellow, setYieldDeltaYellow] = useState('?');

    useEffect(() => {    
        const actionAsync = async () => {
            setLoadingData(true);
            const tableType = (showYield) ? 'all' : 'daysOnly';

            const black = await Axios.get(`/entities/workflow/actionDaysToEmpty/${tableType}/${serialNumber}/black`).then(api => api.data.result);
            setDaysInBlack((black.daysTonerIn !== undefined) ? black.daysTonerIn : '?');
            setPrintsPerDayBlack((black.printsPerDay !== undefined) ? black.printsPerDay : '?')
            setDaysToEmptyBlack((black.daysToEmpty !== undefined) ? black.daysToEmpty : '?');
            setYieldExpectedBlack((black.yieldExpected !== undefined) ? black.yieldExpected : '?');
            setYieldDeltaBlack((black.yieldDelta !== undefined) ? black.yieldDelta : '?');

            const cyan = await Axios.get(`/entities/workflow/actionDaysToEmpty/${tableType}/${serialNumber}/cyan`).then(api => api.data.result);
            setDaysInCyan((cyan.daysTonerIn !== undefined) ? cyan.daysTonerIn : '?');
            setPrintsPerDayCyan((cyan.printsPerDay !== undefined) ? cyan.printsPerDay : '?')
            setDaysToEmptyCyan((cyan.daysToEmpty !== undefined) ? cyan.daysToEmpty : '?');
            setYieldExpectedCyan((cyan.yieldExpected !== undefined) ? cyan.yieldExpected : '?');
            setYieldDeltaCyan((cyan.yieldDelta !== undefined) ? cyan.yieldDelta : '?');

            const magenta = await Axios.get(`/entities/workflow/actionDaysToEmpty/${tableType}/${serialNumber}/magenta`).then(api => api.data.result);
            setDaysInMagenta((magenta.daysTonerIn !== undefined) ? magenta.daysTonerIn : '?');
            setPrintsPerDayMagenta((magenta.printsPerDay !== undefined) ? magenta.printsPerDay : '?')
            setDaysToEmptyMagenta((magenta.daysToEmpty !== undefined) ? magenta.daysToEmpty : '?');
            setYieldExpectedMagenta((magenta.yieldExpected !== undefined) ? magenta.yieldExpected : '?');
            setYieldDeltaMagenta((magenta.yieldDelta !== undefined) ? magenta.yieldDelta : '?');

            const yellow = await Axios.get(`/entities/workflow/actionDaysToEmpty/${tableType}/${serialNumber}/yellow`).then(api => api.data.result);
            setDaysInYellow((yellow.daysTonerIn !== undefined) ? yellow.daysTonerIn : '?');
            setPrintsPerDayYellow((yellow.printsPerDay !== undefined) ? yellow.printsPerDay : '?')
            setDaysToEmptyYellow((yellow.daysToEmpty !== undefined) ? yellow.daysToEmpty : '?');
            setYieldExpectedYellow((yellow.yieldExpected !== undefined) ? yellow.yieldExpected : '?');
            setYieldDeltaYellow((yellow.yieldDelta !== undefined) ? yellow.yieldDelta : '?');
          
            setLoadingData(false);
        }
    
        if(showEmpty || showYield) {
            actionAsync();
        }
    }, [serialNumber, showEmpty, showYield]);

    const blackTd = (data, postData) => { return <td className='colour background grey pad-l5 pad-r5'>{data}{postData}</td> }
    const cyanTd = (data, postData) => { return <td className='colour background cyan pad-l5 pad-r5'>{data}{postData}</td> }
    const magentaTd = (data, postData) => { return <td className='colour background magenta pad-l5 pad-r5'>{data}{postData}</td> }
    const yellowTd = (data, postData) => { return <td className='colour background yellow pad-l5 pad-r5'>{data}{postData}</td> }

    const yieldExpected = <tr><td>Yield Expected</td>{blackTd(yieldExpectedBlack)}{cyanTd(yieldExpectedCyan)}{magentaTd(yieldExpectedMagenta)}{yellowTd(yieldExpectedYellow)}</tr>;
    const yieldDelta = <tr><td>Yield Delta (est)</td>{blackTd(yieldDeltaBlack,'%')}{cyanTd(yieldDeltaCyan,'%')}{magentaTd(yieldDeltaMagenta,'%')}{yellowTd(yieldDeltaYellow,'%')}</tr>;

    return <Fragment>
        <UILIB.Paper className='flex-container column width-100'>
            <div className='flex-item width-100'>
                <div className='flex-item align-center start min-width-175'><h3>Days To Empty</h3></div>
                <div className='flex-item align-center start'>
                    <UILIB.Button className="min-width-150" name='showHideEmpty' value='Show/Hide' 
                        onClick={() => {
                            setShowYield(false);
                            setShowEmpty(!showEmpty);
                        }} />
                </div>
            </div>
            <div className='flex-item width-100 mar-b10'>
                <div className='flex-item align-center start min-width-175'><h3>Empty And Yield</h3></div>
                <div className='flex-item align-center start'>
                    <UILIB.Button className="min-width-150" name='showHideYield' value='Show/Hide (slow)' 
                        onClick={() => {
                            setShowEmpty(false);
                            setShowYield(!showYield);
                        }} />
                </div>
            </div>
            {loadingData &&  (showEmpty || showYield) && <p>Loading...</p>}
            {!loadingData && (showEmpty || showYield) && <table>
                <tbody>
                    <tr className='colour background lightGrey pad-l5 pad-r5'><td></td>{blackTd('Black')}{cyanTd('Cyan')}{magentaTd('Magenta')}{yellowTd('Yellow')}</tr>
                    <tr className='colour background lightGrey pad-l5 pad-r5'><td>Days Toner In</td>{blackTd(daysInBlack)}{cyanTd(daysInCyan)}{magentaTd(daysInMagenta)}{yellowTd(daysInYellow)}</tr>
                    <tr className='colour background lightGrey pad-l5 pad-r5'><td>Prints Per Day</td>{blackTd(printsPerDayBlack)}{cyanTd(printsPerDayCyan)}{magentaTd(printsPerDayMagenta)}{yellowTd(printsPerDayYellow)}</tr>
                    <tr className='colour background lightGrey pad-l5 pad-r5'><td>Days To Empty</td>{blackTd(daysToEmptyBlack)}{cyanTd(daysToEmptyCyan)}{magentaTd(daysToEmptyMagenta)}{yellowTd(daysToEmptyYellow)}</tr>
                    {showYield && yieldExpected}
                    {showYield && yieldDelta}
                </tbody>
            </table>}
            </UILIB.Paper>
    </Fragment>
}