import { useEffect, Fragment, useState } from 'react';
import UILIB from 'components'

const headerData = [
  { label: "Include", value: "installSelected", align: "left", type: 'number', width: "2%" },
  { label: "Quantity", value: "quantity", align: "left", type: 'number', width: "5%" },
  { label: "Description", value: "description", align: "left", type: 'string', width: "28%" },
  { label: "Part Number", value: "partNo", align: "left", type: 'string', width: "10%" },
  { label: "Device", value: "device", align: "left", type: 'number', width: "5%" },
  { label: "Location", value: "location", align: "left", type: 'number', width: "10%" }
]


export default function BlockSelectProductTable({ data, setData, loading, setSelectedProducts }) {

    const [tableData, setTableData] = useState([]);

    function renderTable() {

      if(!data) return;

      setSelectedProducts(data.some(x => Boolean(x.installSelected)));

      const tableData = [];

      data.sort((a, b) => { return a.deviceNo - b.deviceNo }).forEach(row => {

        tableData.push({
          installSelected: { value: <UILIB.Checkbox name="installSelected" checked={row.installSelected} onChange={(event) => installSelected(event, row.prodID)} />, raw: row.installSelected, tdStyle: { backgroundColor: "#FFFFFF" } },
          quantity: { value: row.qty, raw: row.qty, tdStyle: { backgroundColor: "#FFFFFF" } },    
          location: { value: row.specificDeviceLocation, raw: row.specificDeviceLocation, tdStyle: { backgroundColor: "#FFFFFF" } },
          device: { value: row.deviceNo || '', raw: row.deviceNo || '', tdStyle: { backgroundColor: "#FFFFFF" } },
          description: { value: row.description, raw: row.description, tdStyle: { backgroundColor: "#FFFFFF" } },
          partNo: { value: row.partNo, raw: row.partNo, tdStyle: { backgroundColor: "#FFFFFF" } }
        })

        row.attachments && row.attachments.forEach(child => {
          tableData.push({
            installSelected: { value: <UILIB.Checkbox disabled={true} name="installSelected" checked={child.installSelected} />, raw: child.installSelected, tdStyle: { backgroundColor: "#EEEEEE" } },
            quantity: { value: child.qty, raw: child.qty, tdStyle: { backgroundColor: "#EEEEEE" } },   
            description: { value: `+ ${child.description}`, raw: child.description, tdStyle: { backgroundColor: "#EEEEEE" } }, 
            partNo: { value: child.partNo, raw: child.partNo, tdStyle: { backgroundColor: "#EEEEEE" } },
            device: { value: '', tdStyle: { backgroundColor: "#EEEEEE" } },
            location: { value: '', tdStyle: { backgroundColor: "#EEEEEE" } }
          })
        })
      })

      setTableData(tableData);
    }


    useEffect(() => {
        renderTable();
    }, [data]);


    const installSelected = (event, index) => {
      const dataNew = structuredClone(data);
      dataNew[dataNew.findIndex((obj => obj.prodID === index))].installSelected = event.target.checked;
      const theIndex = dataNew.findIndex((obj => obj.prodID === index));
      dataNew[theIndex].attachments && dataNew[theIndex].attachments.forEach(c => { c.installSelected = event.target.checked });
      setData(dataNew);
    }

    return <Fragment>
      <UILIB.Table
        className="small"
        overflowX="auto"
        overflowY="hidden"
        header={headerData}
        data={tableData}
        loading={loading}
        sortable={false}
        autoResize={false}
      />
    </Fragment>
}