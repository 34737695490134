export function claimCleanData(entity) {
    const yes = 'Yes';
    const no = 'No';

    if (entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYears === no) { entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails = '' }
    if (entity.hasDriverBeenProsecutedOrFixedPenalty === no) { entity.hasDriverBeenProsecutedOrFixedPenaltyDetails = '' }
    if (entity.hasDriverBeenDeclinedOrRefusedRenewal === no) { entity.hasDriverBeenDeclinedOrRefusedRenewalDetails = '' }
    if (entity.driverSufferAnyConditionsAffectSightOrHearing === no) { entity.driverSufferAnyConditionsAffectSightOrHearingDetails = '' }

    if (entity.thirdPartyInvolved === no) {
        entity.thirdPartyName = '';
        entity.thirdPartyAddress = '';
        entity.thirdPartyTelephoneNumber = '';
        entity.thirdPartyVehicleMake = '';
        entity.thirdPartyVehicleModel = '';
        entity.thirdPartyVehicleRegistration = '';
        entity.thirdPartyInsurers = '';
        entity.thirdPartyReferenceNumber = '';
        entity.thirdPartySpeed = '';
        entity.thirdPartyVehicleDamage = '';
        entity.thirdPartyNumberOccupantsInVehicle = '';
        entity.thirdPartyAdditionalInvolved = no;
    }

    if (entity.thirdPartyAdditionalInvolved === no) {   
        entity.thirdPartyAdditionalName = '';
        entity.thirdPartyAdditionalAddress = '';
        entity.thirdPartyAdditionalTelephoneNumber = '';
        entity.thirdPartyAdditionalVehicleMake = '';
        entity.thirdPartyAdditionalVehicleModel = '';
        entity.thirdPartyAdditionalVehicleRegistration = '';
        entity.thirdPartyAdditionalInsurers = '';
        entity.thirdPartyAdditionalReferenceNumber = '';
        entity.thirdPartyAdditionalSpeed = '';
        entity.thirdPartyAdditionalVehicleDamage = '';
        entity.thirdPartyAdditionalNumberOccupantsInVehicle = '';
    }

    if (entity.furtherInjuredPartiesInvolved === no) {
        entity.furtherInjuredName = '';
        entity.furtherInjuredAddress = '';
        entity.furtherInjuredInjuryDetails = '';
        entity.furtherInjuredAmbulance = no;
    }

    if (entity.furtherInjuredAmbulance === no) { entity.furtherInjuredAmbulanceDetails = ''; }

    if (entity.wasTherePassenger1 === no) {
        entity.passenger1Name = '';
        entity.passenger1Address = '';
        entity.wasTherePassenger2 = no;
    }

    if (entity.wasTherePassenger2 === no) {
        entity.passenger2Name = '';
        entity.passenger2Address = '';
        entity.wasTherePassenger3 = no;
    }

    if (entity.wasTherePassenger3 === no) {
        entity.passenger3Name = '';
        entity.passenger3Address = '';
    }

    if (entity.wasThereWitness1 === no) {
        entity.witness1Name = '';
        entity.witness1Address = '';
        entity.wasThereWitness2 = no;
    }

    if (entity.wasThereWitness2 === no) {
        entity.witness2Name = '';
        entity.witness2Address = '';
        entity.wasThereWitness3 = no;
    }

    if (entity.wasThereWitness3 === no) {
        entity.witness3Name = '';
        entity.witness3Address = '';
    }

    if (entity.wasTherePoliceOnSite === no) {
        entity.policeOfficerName = '';
        entity.policeOfficerNumber = '';
        entity.policeOfficerStationAddress = '';
    }

    return entity;
}