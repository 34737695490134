import { useState, useEffect, useRef, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

export default function MultiSelect({ 
  name, 
  data, 
  placeholder, 
  onChange = () => { }, 
  disabled, 
  outerstyle, 
  style = { }, 
  outerClassName, 
  className = "",
  errored = { messageText: "", option: 0 }, 
  selected 
}) {

    const node = useRef();
    const nodeWidth = useRef({});
    const [DropDownOpen, setDropDownOpen] = useState(false);
    const [render, setRender] = useState(false);
    const [clicked, setClicked] = useState(0);

    library.add(fas);
    const fontAwesome = <FontAwesomeIcon icon="fas fa-chevron-down"/>

    const toggleDropDown = () => {
      if (DropDownOpen) {
        setDropDownOpen(false);
      }
      else {
        setDropDownOpen(true);
      }
    }

    const updateSelected = (val, c) => {
      setClicked(c === 0 ? 1 : 0);
      if (selected.indexOf(val) > -1) {
        selected.splice(selected.indexOf(val), 1)
      } else {
        selected.push(val);
      }
      onChange({ target: { name, value: selected } })
    }

    const handleClick = e => {
        if (node.current.contains(e.target)) return;
        // outside click 
        setDropDownOpen(false);
    };


    useEffect(() => {     
      document.addEventListener("mousedown", handleClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }, [clicked])

    useEffect(() => {
      const actionAsync = async () => { 
        if(DropDownOpen) {
          new ResizeObserver(() => {
            (node.current && node.current && node.current.getBoundingClientRect()) 
            ? nodeWidth.current = { width: `${Math.round(node.current.getBoundingClientRect().width)}px` }
            : nodeWidth.current = {}
            setRender(!render)
          }).observe((DropDownOpen) ? document.body : null) }
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      actionAsync();
    });

    let outerClassNameF = "align-center multiSelect noBorder"
    if (className) className += " " + className;
    if (errored) className += " errored";
    if (outerClassName) outerClassNameF += " " + outerClassName;
    let initialValue = placeholder;

    if (data && data.length && selected && selected.length) {
        const count = data.filter(w => selected.indexOf(w.value) > -1);
        initialValue = `${count.length} Selected`;
    }

    if (errored?.error && errored?.option === 0) {
        style.border = "1px solid rgba(217, 30, 24, 0.8)";
        style.borderRadius = "5px";
        style.transition = "border-color 2s";
    }


    return <Fragment>
        <div className={outerClassNameF} style={{ ...outerstyle, minWidth: 200 }} ref={node} id={"multiSelect" + name}>
          <div className={`multiSelect fakeSelect ${className}`} style={style} onClick={!Boolean(disabled) ? toggleDropDown : null} >
            <div className={"content" + (Boolean(disabled) ? " colour text grey" : "")}>{initialValue}</div>
            <div className="fakeSelectIcon"><span>{fontAwesome}</span></div>
          </div>
            {DropDownOpen && <div style={nodeWidth.current} className={"fakeSelect fakeSelectDropDown flex-container row flex-grow-1"}>
              {(data && data.length) && data.map((opt, index) => {
                const checked = (selected && selected.length) ? selected.indexOf(opt.value) > -1 : [];
                return <div className="flex-item start clickable" key={index}><input type="checkbox" checked={checked} onChange={() => updateSelected(opt.value, clicked)} /> {opt.label}</div> })}
            </div>}
            {errored?.error && errored?.option === 1 && <div className="errored message">{errored.messageText}</div>}
        </div>
      </Fragment>
}
