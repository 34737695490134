import { useState, useRef, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios'
import { formatDateTime } from 'classes/format';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DealLinkTableCell from '../../shared/dealLink';
import { getYesNo, getAwaitingRowColour, getNotesColour, tableContainer, actionOpenDrawer, setAwaitingFilterVars, calcResetEnable, getGp, getInvoice } from '../functions';

export default function BlockAwaitingApprovalTable({ permissionsObject, localPermissions, permissions, location, route, regions, staff, filters, setFilters, defaultFilters }) {
  const headerData = [
    { label: '#', value: 'finalDealNo', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Opp #', value: 'opportunityNumber', type: 'number', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: 'Account #', value: 'accountNo', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },  
    { label: 'Customer', value: 'companyName', type: 'string', filtering: true, minWidth: 400, maxWidth: 400 },
    { label: 'Sales Person', value: 'salesPersonName', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
    { label: 'Region', value: 'salesRegionTxt', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
    { label: 'GP', value: 'valueGp', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: 'Invoice', value: 'valueInvoice', type: 'string', filtering: true, minWidth: 140, maxWidth: 140 },
    { label: 'Submitted', value: 'dateSubmittedCast', type: 'date', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Time Unapproved (hrs)', value: 'slaUnapproved', type: 'number', filtering: true , minWidth: 130, maxWidth: 130 },
    { label: 'Status', value: 'sopStatus', type: 'string', filtering: true, minWidth: 120, filterArray: getYesNo() },
    { label: 'Notes', value: 'notes', filtering: false, minWidth: 30, maxWidth: 30, filterArray: getYesNo() }];

  const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'slaUnapproved', orderDir: 'DESC' } };
  const dispatch = useDispatch();
  const history = useHistory();
  const entityData = useRef({});
  const [render, setRender] = useState();
  const [localRender, setLocalRender] = useState(false);
  const [buttonFilter, setButtonFilter] = useState({ button: 'Active' });
  const selectData = { regions: regions, staff: staff };

  const handleResetfilters = () => {
    setButtonFilter({ button: 'Active' });
    setFilters(defaultFilters);
    setRender(!render);
  };

  async function constructTable(dealData) {

      const tableData = dealData.map(row => {

          const status = <UILIB.Chip className="table red text-small center width-100" value="Unapproved" />
          const bgColour = getAwaitingRowColour(row);

          const b_hasNotes = Boolean(row.dealNote && row.dealNote.length);
          const notesColour = getNotesColour(row);
          const fn_notes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: localRender, set: setLocalRender });

          return {
              finalDealNo: DealLinkTableCell(row, history, route, permissionsObject('orderLink', localPermissions.current.permissions, permissions, location.pathname).entityEnable, bgColour, true, 'class'),
              opportunityNumber: { value: row.opportunityNumber, raw: row.opportunityNumber, class: bgColour },
              accountNo: { value: row.accountNo, raw: row.accountNo, class: bgColour },
              groupCode: { value: row.groupCode, raw: row.groupCode, class: bgColour },
              companyName: { value: row.companyName, raw: row.companyName, class: bgColour },
              salesPersonName: { value: row.salesPersonName, raw: row.salesPersonName, class: bgColour },
              salesRegionTxt: { value: row.salesRegionTxt, raw: row.salesRegionTxt, class: bgColour },
              valueGp: { value: getGp(row), raw: row.valueGp, class: bgColour },
              valueInvoice: { value: getInvoice(row), raw: row.valueInvoice, class: bgColour },
              dateSubmittedCast: { value: formatDateTime(row.dateSubmitted, "DD/MM/YYYY HH:mm"), raw: row.dateSubmitted, class: bgColour },
              slaUnapproved: { value: row.slaUnapproved, raw: row.slaUnapproved, class: bgColour },
              sopStatus: { value: status, raw: "Unapproved" },
              notes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes }
          }
      })
      return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const calcFilters = setAwaitingFilterVars(filters, buttonFilter);
    const expandArray = ['pricing', 'dealNote'];

    setFilters({ ...filters, queryString: `${[calcFilters].join(' and ')}${queryLocal}` });

    entityData.current = await Axios.get(`/entities/deals/getSummary/?&$filter=${[calcFilters].join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }

  return <Fragment>
    <UILIB.Paper className='width-100'>
      <div className='flex-container row mar-b10'>
        <div className='flex-item end width-100'>
          <UILIB.Button disabled={calcResetEnable(filters, defaultFilters)} className={"mar-l20 mar-r5 small green"} name="resetFilters" value="Reset Filters" onClick={() => handleResetfilters()} />
          <UILIB.Button value="Filters" onClick={() => { actionOpenDrawer('AwaitingFiltersDrawer', { ...selectData, filters: filters, setFilters: setFilters }, dispatch, true, { get: render, set: setRender }, '400px') }} />
        </div>
      </div>
      <UILIB.TableNew
        name={`DealsAwaitingApproval`}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[localRender]}
        remoteQuery={getTableData}
        remoteRender={[filters.button, buttonFilter, render]}
        defaultSettings={tablePageDefaults} />
    </UILIB.Paper>
  </Fragment>
}
