import { useEffect, Fragment, useState } from 'react';
import UILIB from 'components'

const headerData = [
  { label: "Quantity", value: "quantity", align: "left", type: 'number', width: "5%" },
  { label: "Description", value: "description", align: "left", type: 'string', width: "35%" },
  { label: "Supplier", value: "supplier", align: "left", type: 'string', width: "30%" },
  { label: "New/Used", value: "newUsed", align: "left", type: 'string', width: "10%" },
  { label: "Part Number", value: "partNo", align: "left", type: 'string', width: "10%" },
  { label: "Location", value: "location", align: "left", type: 'string', width: "10%" },
]


export default function BlockAttachmentsTable({ data }) {

    const [tableData, setTableData] = useState([]);

    function renderTable() {

      if(!data) return;

      const tableData = [];

      data.forEach(row => {
        tableData.push({
          quantity: { value: row.qty, raw: row.qty, tdStyle: { backgroundColor: "#FFFFFF" } },    
          location: { value: row.specificDeviceLocation, raw: row.specificDeviceLocation, tdStyle: { backgroundColor: "#FFFFFF" } },
          description: { value: row.description, raw: row.description, tdStyle: { backgroundColor: "#FFFFFF" } },
          partNo: { value: row.partNo, raw: row.partNo, tdStyle: { backgroundColor: "#FFFFFF" } },
          supplier: { value: row.supplier, raw: row.supplier, tdStyle: { backgroundColor: "#FFFFFF" } },
          newUsed: { value: row.newUsed, raw: row.newUsed, tdStyle: { backgroundColor: "#FFFFFF" } }
        })
      })
      setTableData(tableData)
    }


    useEffect(() => {
        renderTable();
    }, [data]);


    return <Fragment>
      <UILIB.Table
        className="small"
        header={headerData}
        data={tableData}
        overflowX="auto"
        overflowY="hidden"
        sortable={false}
        filter={false}
        autoResize={false}
        defaultOrderBy="quantity"
        defaultOrderDir="ASC"
        exportEnable={false}
      />
    </Fragment>
}