import { useState, Fragment } from 'react';
import { checkFormErrors } from '../../../../../classes/formErrors/formErrors';
import { getCustomerRequestErrors } from '../../../../../classes/formErrors/repository';
import { createMultilineTextInput } from 'classes/controls/genericControls';
import { useSelector } from 'react-redux';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function AddNotes({ entity, setAddNote }) 
{  

  const account = useSelector((state) => state.account);
  const emptyEntity = { notes: '' };
  const [saving, setSaving] = useState(false);
  const [formData, setFormdata] = useState(emptyEntity);
  const [formErrors, setFormErrors] = useState({});
  const [bannerError, setBannerError] = useState({ error: false, message: '' });
  const placeholder = 'Add your notes here...';
  const className = 'consumablesHub siteDevices-notes-textArea';
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';

  const actionAddNotes = async (data) => {
    setSaving(true);
    const formResult = await checkFormErrors(getCustomerRequestErrors(formData), setFormErrors, null, null, formData, null);
    if(formResult.errorCount === 0) {
      try { 
        const result = await Axios.post(`/entities/serviceAdmin_siteStoreNote/${data.rowID}`, { entity: { ...formData, rowID: data.rowID } });
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          entity.notes.push({ ...result.data.result[0], createdByTxt: account.displayName });
          setAddNote(false);
        }
      } catch (err) {
        console.log(err);
        setBannerError({ error: true, message: exceptionError });
      }
    }
    setSaving(false);
  };

  const actionUpdateNotes = async (event) => {
    setFormdata({ ...formData, [event.target.name]: event.target.value });
  };

  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className={`flex-item flex-grow-1 end`}> 
        <UILIB.Button disabled={saving} loading={saving} value={'Save Notes'} className="green" onClick={() => actionAddNotes(entity)} />
        <UILIB.Button disabled={saving} value={'Cancel'} className="red" onClick={() => setAddNote(false)} />
      </div>
      {bannerError.error && <div className="flex-container end width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
    <div className={`flex-container row width-100 height-50 padding-8`}>
      {createMultilineTextInput('', 'notes', placeholder, true, formData, formErrors, (ev) => actionUpdateNotes(ev), false, className, true)}
    </div>
  </Fragment>
}
