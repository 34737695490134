import axios from 'classes/axios';
import camelCase from "lodash/camelCase";

export async function permissionsRouter(hist, perm, account, hubName, routeName, entityName) {

  let foundAdminHubRoute = null;
  const entityPermissions = { 
    entityEnable: false
  }

  const foundRouteAccess = hubNameDecider(hubName, routeName, perm);

  foundAdminHubRoute = foundRouteAccess && foundRouteAccess.adminRoute.find(f => f.routeName.toLowerCase() === routeName.toLowerCase());

  if(foundAdminHubRoute && foundAdminHubRoute.inheriting) {
    foundAdminHubRoute = foundRouteAccess && foundRouteAccess.adminRoute.find(f => Number(f.id) === Number(foundAdminHubRoute.parentId));
  }

  const foundAdminHubRouteEntity = foundAdminHubRoute && foundAdminHubRoute.adminEntity.find(f => f.entityName.toLowerCase() === entityName.toLowerCase());

  const foundPermissions = foundAdminHubRouteEntity ? foundAdminHubRouteEntity.adminGroup.filter(item => account.groups.includes(item.groupName)) : [];

  if(foundPermissions.length > 0 ) {
    foundPermissions.forEach(p => {
      if(p.AdminEntityGroupPermission.entityEnable === true && entityPermissions.entityEnable === false) {
        entityPermissions.entityEnable = p.AdminEntityGroupPermission.entityEnable;
      }
    })
  }

  if(!entityPermissions.entityEnable) {
    if (hist && hist.location && hist.location.key) {
      hist.goBack();
    }
    else {
      hist.push('/');
    }
  }
}


export function hubNameDecider(hubName, routeName, perm) {
  if(!perm) {
    return null;
  }
  else if(hubName && hubName.length) {
    return perm.find(f => f.hubName.toLowerCase() === hubName.toLowerCase());
  }
  else {
    return perm.find(f => f.adminRoute.find(route => route.routeName.toLowerCase() === routeName.toLowerCase()));
  }
}


export function permissionsObject(entityName, localPermissions, permissions, location) {
  const hubResult = hubNameDecider(null, location, permissions);
  const camelCaseHubName = camelCase(hubResult && hubResult.hubName ? hubResult.hubName : '');
  const permissionsObj = {
      entityEnable: (camelCaseHubName && entityName && localPermissions && localPermissions[camelCaseHubName][entityName]) ? localPermissions[camelCaseHubName][entityName].entityEnable : false,
      entityWrite: (camelCaseHubName && entityName && localPermissions && localPermissions[camelCaseHubName][entityName]) ? localPermissions[camelCaseHubName][entityName].entityWrite : false,
      entityDelete: (camelCaseHubName && entityName && localPermissions && localPermissions[camelCaseHubName][entityName]) ? localPermissions[camelCaseHubName][entityName].entityDelete : false
  }

  return permissionsObj;
}


export function groupsObject(entityName, localGroups, permissions, location) {
  const hubResult = hubNameDecider(null, location, permissions);
  const camelCaseHubName = camelCase(hubResult && hubResult.hubName ? hubResult.hubName : '');
  return (camelCaseHubName && entityName && localGroups && localGroups[camelCaseHubName][entityName]) ? localGroups[camelCaseHubName][entityName] : [];
}


export async function permissionsGroupExporter(permissions, account, hubs, localPermissions, setLocalPermissions) {
  const checkGroups = await axios.post(`/admin/groups/permissionsGroupExporter`, {
    routeAccess: permissions,
    account: account,
    hubs: hubs
  });
  localPermissions.groups = checkGroups.data.result;
  if(setLocalPermissions) setLocalPermissions(localPermissions);
  return checkGroups.data.result;
}


export async function permissionDecider(permissions, account, hubs, localPermissions, setLocalPermissions) {
  const checkPermissions = await axios.post(`/admin/groups/permissionDecider`, { 
    routeAccess: permissions, 
    account: account, 
    hubs: hubs
  })
  localPermissions.permissions = checkPermissions.data.result;
  if(setLocalPermissions) setLocalPermissions(localPermissions);
  return checkPermissions.data.result;
}


export async function permissionAccount(account, localPermissions, setLocalPermissions) {
  localPermissions.account = account;
  if(setLocalPermissions) setLocalPermissions(localPermissions);
  return localPermissions.account;
}
