import { Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import UILIB from 'components';


export default function LogsDrawer({ entity }) 
{  

  const createdBy = (entity.createdByTxt) ? entity.createdByTxt : 'N/A';
  const createdAt = formatDateTime(entity.createdAt, "DD/MM/YYYY HH:mm:ss")

  return <Fragment>
    <div className={`flex-container row padding-8`}>
      {Boolean(!entity.logging || !entity.logging.length) && <h2>No logs available for this record.</h2>}
      {Boolean(entity.logging && entity.logging.length) && <div className="width-100">
        <h2>Email log for {entity.serial}</h2>
        <UILIB.NoteContainer type={'Email Body'} createdBy={createdBy} createdAt={createdAt} message={entity.logging} />
      </div>}
    </div>
  </Fragment>
}
