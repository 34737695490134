
import { useState, useEffect, Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import Axios from 'classes/axios'
import UILIB from 'components'
import * as functions from '../../../shared/functions';


export default function DocumentsTable(props) {

  const [tableData, setTableData] = useState([]);
  const [axiosAppend] = useState(props && props.entity ? `&documentId=${props.entity.documentIdentity}&entity=${props.entityName}&modal=true` : '');
  const [classData, setClassData] = useState([]);
  const [tableFilter, setTableFilter] = useState({ classId: 'unlinked', statusId: 'd=include' });
  const [downloadingFile, setDownloadingFile] = useState({});
  const [loading, setLoading] = useState(true);

  const headerData = [
    { label: "Document Name", value: "name", type: 'string', width: '200px', align: 'left' },
    { label: "Document Class", value: "class", type: 'string', width: '120px', align: 'left' },
    { label: "Created By", value: "checkedOutToUserName", type: 'string', width: '80px', align: 'left' },
    { label: "Document Version", value: "version", type: 'number', width: '95px', align: 'left' },
    { label: "Document Created", value: "created", type: 'number', width: '100px', align: 'left' },
    { label: "Document Modified", value: "modified", type: 'number', width: '100px', align: 'left' },
    { label: "Status", value: "status", type: 'number', width: '50px', align: 'left' },
    { label: "Download File", value: "download", type: 'string', width: '85px', align: 'left' },
    { label: "View In mFiles", value: "view", type: 'string', width: '80px', align: 'left' }
  ]

  const statusTypeDecider = (deleted) => {
    if(deleted) return { data: <UILIB.Chip className="table red text-small width-100" value='Deleted' />, value: 2 }
    if(!deleted) return { data: <UILIB.Chip className="table green text-small width-100" value='Available' />, value: 1 }
    return { data: <UILIB.Chip className="table grey text-small width-100" value='Unknown' />, value: 0 }
  }

  const tableDataResult = tableData && tableData.map(row => {
    const object = row.ObjVer;
    const file = { EscapedName: '', Extension: '', Link: '', isFile: row.Files[0] ? true : false, ...row.Files[0] };
    const downloadUrl = `/fleet/documents/download?typeId=${object.Type}&objectId=${object.ID}&version=${object.Version}&fileId=${file.ID}`;
    const downloadHandler = functions.downloadFileHandler.bind(null, 'get', downloadUrl, row.ObjectGUID, file.EscapedName, file.Extension, downloadingFile, setDownloadingFile);
    const addDownloadButton = functions.addDownloadButtonUILIB.bind(null, row.Deleted || !file.isFile, row.ObjectGUID, 'Download', downloadingFile, setDownloadingFile, downloadHandler);
    const classType = classData.find(f => Number(f.value) === Number(row.Class));

    return {
      checkedOutToUserName: { value: row.CheckedOutToUserName, raw: row.CheckedOutToUserName },
      name: { value: file.EscapedName, raw: file.EscapedName },
      version: { value: Number(object.Version), raw: Number(object.Version) },
      class: { value: classType && classType.label, raw: classType && classType.id },
      created: { value: formatDateTime(row.Created, 'YYYY-MM-DD HH:MM:ss'), raw: row.Created },
      modified: { value: formatDateTime(row.LastModified, 'YYYY-MM-DD HH:MM:ss'), raw: row.LastModified },
      status: { value: statusTypeDecider(row.Deleted).data, raw: statusTypeDecider(row.Deleted).value },
      download: { value: addDownloadButton(), raw: 0 },
      view: { value: <UILIB.Button disabled={(!row.Deleted && file.Link) ? false : true} className="secondary table text-small width-100" onClick={() => window.open(file.Link)} value="view" />, raw: 0 }
    }
  })

  const handleTableFilterChange = (property, value) => {
    setTableFilter({ ...tableFilter, [property] : value });
  }

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      const result = await Axios.get(`/fleet/documents?classId=${tableFilter.classId}&statusId=${tableFilter.statusId}${axiosAppend}`);
      setTableData(result.data.result.documents);
      setClassData(result.data.result.classes);
      setLoading(false);
    }
    asyncEffect();
  }, [tableFilter, props.render])

  const controlStyle = { 
    select: { outerClass: 'mar-b5 col-xs-12 col-sm-2' },
    status: { outerClass: 'mar-b5 col-xs-12 col-sm-2' }
  }

  return <Fragment>
          <div className="row flex-container end">
            {functions.addSelectFieldUILIB('selectStatus', loading, controlStyle.status, functions.documentStatusData(), tableFilter.statusId, (ev) => handleTableFilterChange('statusId', ev.target.value))}
            {functions.addSelectFieldUILIB('selectClass', loading, controlStyle.select, classData, tableFilter.classId, (ev) => handleTableFilterChange('classId', ev.target.value))}
          </div>
          <UILIB.Table
            className='small' 
            overflowX='auto'
            overflowY='auto'
            fitToParent={true}
            loading={loading}
            header={headerData}
            data={tableDataResult}
            sortable={true}
            filter={true}
            resetFilters={false}
            maxRows={(props && props.modalView ? 20 : 40)}
            autoResize={false}
            exportEnable={true} 
          />
        </Fragment>
}