import { useEffect } from 'react';
import { addPermission } from '../shared/functions';
import UILIB from 'components'

const headerData = [
    { label: "Hub Name", value: "hubName", type: 'string', width: '150px', align: 'left' },
    { label: "Page Name", value: "pageName", type: 'string', align: 'left' },
    { label: "Entity", value: "entity", type: 'string', align: 'left' },
    { label: "Select", value: "select", type: 'number', width: '100px', align: 'left' },
]


export default function EntityTable(props) {

    function renderTable() {
      
      let tableData = [];

      const permission = props.selectedGroupIndex.permission;
      const foundEntities = props.permissions.AdminEntity.filter(f => (permission === 0 ? f.id : f.AdminHubId === permission));
      const entityIds = props.permissions.AdminEntityGroupPermission.filter(p => p.AdminGroupId === props.selectedGroupIndex.group).map(perm => { return perm.AdminEntityId });

      foundEntities.filter(f => !entityIds.includes(f.id)).forEach((entity, index) => {
        const foundHub = props.permissions.AdminHub.find(e => e.id === entity.AdminHubId);
        const foundRoute = props.permissions.AdminRoute.find(r => r.id === entity.AdminRouteId);
          tableData.push({
            hubName: { 
              value: <div align='left'>{foundHub.hubName}</div>, 
              raw: foundHub.hubName
            },
            pageName: { 
              value: <div align='left'>{foundRoute.routeName}</div>, 
              raw: foundRoute.routeName
            },
            entity: { 
              value: <div align='left'>{entity.entityName}</div>, 
              raw: entity.entityName
            },
            select: { 
              value: <div align='center'>
                <UILIB.Button
                  value="Select"
                  onClick={async (event) => await addPermission(props, entity)}
                />
              </div>, 
              raw: index
            }
          })
        })
      props.setPermissionsTableData(tableData)
    }

    useEffect(() => {
        renderTable();
    }, [props.selectedGroupIndex.permission]);


    return (
        <UILIB.Table
          className='small' 
          overflowX='auto'
          overflowY='auto'
          fitToParent={true}
          defaultOrderBy="hubName"
          defaultOrderDir="ASC"
          sortable={true}
          filter={true}
          maxRows={10}
          autoResize={false}
          header={headerData}
          data={props.permissionsTableData}
          loading={props.loading}
          exportEnable={false}
        />
    );
}