import { useState, useEffect, Fragment } from "react"
import BlockRangeTable from './tables/blockRangeTable';
import UILIB from 'components'
import Axios from "classes/axios"


export default function PartsAdminRanges({ ranges, setRanges, deletedRanges, setDeletedRanges }) 
{
  const [loading, setLoading] = useState(true)

  const loadRanges = async () => {
      setLoading(true) 
      await Axios.get(`/sales/parts/range/updateRange`).then(data => {
          setRanges(data.data)
          setLoading(false) 
      }).catch(err => {
          console.log(err)
      })
  }

  useEffect( async () => {
    await loadRanges(); 
  }, [])

  if (loading)  return <UILIB.Loading type={3} /> 
  return  <Fragment>
            <div className="flex-container row">
              <div className="col-xs-12">
                <BlockRangeTable 
                  data={ranges}
                  setRangeData={setRanges}
                  deletedRanges={deletedRanges}
                  setDeletedRanges={setDeletedRanges}
                />
              </div>
            </div>
          </Fragment>
}