import { useState, useEffect } from 'react';
import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions';
import UILIB from 'components';
import Axios from 'classes/axios';
import RdHubUserDrawer from '../drawer/rdHubUserDrawer';
const format = require('../../../../classes/format');

export default function RdHubUsers() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [renderMe, setRenderMe] = useState(true);
  const [buttonDisabled, setButtonDisable] = useState(true);

  const getCurrentUserId = async () => {
    const user = await Axios.get('/users/me').then((api) => api.data);
    return user.userId;
  };

  useEffect(() => {
    async function fetchPermissions() {
      if ((await getCurrentUserId()) === 2198) {
        // Dan is 2198, Paul is 4508
        setButtonDisable(false);
      } else {
        setButtonDisable(false);
      }
      setRenderMe(!renderMe);
    }
    fetchPermissions();
  }, []);

  const rdUsersTableHeader = [
    {
      label: 'Name',
      value: 'name',
      type: 'string',
      filtering: true,
      minWidth: 250,
    },
    {
      label: 'Email',
      value: 'email',
      type: 'string',
      filtering: true,
      minWidth: 250,
    },
    {
      label: 'DOB',
      value: 'dob',
      type: 'date',
      filtering: true,
      minWidth: 250,
    },
    {
      label: 'Age',
      value: 'age',
      type: 'number',
      filtering: true,
      minWidth: 80,
    },
    {
      label: '',
      value: 'admin',
      type: '',
      filtering: false,
      minWidth: 30,
      maxWidth: 100,
    },
  ];

  function renderTable(userData) {
    const tableData = userData.map((row) => {
      const adminButtons = (
        <div align="center">
          <UILIB.Button disabled={buttonDisabled} key={row.courierID} className="button table width-50" name="editSelect" value="Edit" onClick={() => onEditClicked(row)} />
          <UILIB.Button disabled={buttonDisabled} key={row.courierID} className="button table width-50 red" name="deleteSelect" value="Delete" onClick={() => onDeleteClicked(row)} />
        </div>
      );
      return {
        name: { value: row.name, raw: row.name },
        email: { value: row.email, raw: row.email },
        dob: {
          value: format.formatDateTime(row.dob, 'DD/MM/YYYY'),
          raw: row.dob,
        },
        age: { value: row.age, raw: row.age },
        admin: { value: adminButtons, raw: '' },
      };
    });
    return tableData;
  }

  const onAddClicked = () => {
    dispatch(
      setDrawer({
        open: true,
        content: <RdHubUserDrawer onClose={addUserClose} />,
        width: '700px',
      })
    );
  };

  const onEditClicked = async (row) => {
    dispatch(
      setDrawer({
        open: true,
        content: <RdHubUserDrawer onClose={addUserClose} tableRow={row} isEdit={true} />,
        width: '700px',
      })
    );
  };

  const onDeleteClicked = async (row) => {
    if (window.confirm(`Delete ${row.name}\nAre you sure?`)) {
      await Axios.delete(`/entities/rdHubUser/${row.Id}`);
      setRenderMe(!renderMe);
      console.log('Deleted');
    } else {
      console.log('Not deleted');
    }
  };

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
    const apiData = {};
    const queryLocal = query !== null ? query : '';
    const pagingLocal = limit !== null && offset !== null ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = orderBy !== null && orderDir !== null ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    apiData.rdHubUsers = await Axios.get(`/entities/rdHubUsers//?&$filter=${queryLocal}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then((api) => api.data);

    return {
      tableData: await renderTable(apiData.rdHubUsers.result),
      nonePaged: apiData.rdHubUsers.nonePaged,
    };
  };

  const tablePageDefaults = { paging: {
    limit: 40,
    offset: 0,
    orderBy: 'name',
    orderDir: 'DESC' }
  };

  const addUserClose = async () => {
    dispatch(setDrawer({ open: false, content: null }));
    setRenderMe(!renderMe);
  };

  return (
    <Fragment>
      <div className="flex-container row mar-b15 mar-l5 mar-r5 mar-t10">
        <UILIB.Paper className="width-100 mar-b15">
          <h1>RD Hub Users</h1>
          <div class="flex-container row">
            <div class="flex-item start flex-grow-1 ">
              <UILIB.Button value="Back" onClick={() => history.push('/')} className="button height-100 grey"></UILIB.Button>
            </div>
            <div class="flex-item end">
              <UILIB.Button
                value="Add"
                onClick={() => {
                  onAddClicked();
                }}
                className="button height-100 fmar-l5 mar-r5 small"
              ></UILIB.Button>
            </div>
          </div>
        </UILIB.Paper>
        <UILIB.Paper className="width-100">
          <h3 className="flex-item start width-100 text-16 mar-t5">RD Hub Users</h3>
          <div>
            <UILIB.TableNew name="rdHubUserList" className="small" overflowX="auto" overflowY="hidden" header={rdUsersTableHeader} remoteQuery={getTableData} remoteRender={[renderMe]} defaultSettings={tablePageDefaults} />
          </div>
        </UILIB.Paper>
      </div>
    </Fragment>
  );
}
