import { useState, Fragment } from 'react';
import UILIB from 'components';
import { setDrawer } from "store/actions";
import { useDispatch } from 'react-redux';
import SettlementAdditionalDrawer from '../drawer/settlementAdditionalDrawer';
import SettlementExistingDrawer from '../drawer/settlementExistingDrawer';
import DeleteSettlementDrawer from '../drawer/deleteSettlementDrawer';


export default function Settlement({ dataLoaded, ourDeal, setOurDeal, isActive }) {

  const headerData = [
    { label: "Agreement Number", value: "agreementNumber", align: "left", type: 'string' },
    { label: "Agreement Type (Lease/Service)", value: "agreementType", align: "left", type: 'string' },
    { label: "Agreement Provider", value: "agreementProvider", align: "left", type: 'string' },
    { label: "Settlement Method", value: "settlementHow", align: "left", type: 'string' },
    { label: "Notes", value: "notes", align: "left", type: 'string' }, 
    { label: "Total Lines", value: "count", align: "left", type: 'number' }, 
    { label: "Option", value: "options", align: "center", type: 'string', width: "50px" }];

  const dispatch = useDispatch();
  const [render, setRender] = useState(false);


  const editSettlement = (settlement = {}) => {
    dispatch(setDrawer({ open: true, content: 
      <SettlementAdditionalDrawer 
        onFinished={actionCloseDrawer} 
        ourDeal={ourDeal} 
        setOurDeal={setOurDeal} 
        settlement={settlement} 
        isActive={isActive} /> }));
  }

  const addExisting = () => {
    dispatch(setDrawer({ open: true, content: 
      <SettlementExistingDrawer 
        onFinished={actionCloseDrawer} 
        ourDeal={ourDeal} 
        setOurDeal={setOurDeal} 
        isActive={isActive} /> }));
  }

  const deleteSettlement = (recordId) => {
    dispatch(setDrawer({ open: true, content: 
      <DeleteSettlementDrawer 
        onFinished={actionCloseDrawer} 
        ourDeal={ourDeal} 
        setOurDeal={setOurDeal} 
        recordId={recordId} 
        isActive={isActive} /> }));
  }

  const actionCloseDrawer = async () => {
    dispatch(setDrawer({ open: false, content: null }));
    setRender(!render);
  }

  const tableData = ourDeal.settlement.map(settlement => {

    const found = ourDeal.product.filter(x => Number(x.settlementId) === Number(settlement.id));

    const className = (found.length) ? 'icon icon-warning' : 'icon icon-trash2';
    const optionsFunction = (found.length) ? function () { } : deleteSettlement.bind(null, settlement.id);

    const optionsValue = (isActive)
      ? <div><div className={className} style={{ cursor: "pointer", float: "right" }} onClick={() => optionsFunction()} /><div className="icon icon-pencil" style={{ cursor: "pointer" }} onClick={() => { editSettlement(settlement) }} /></div>
      : <div></div>

    return {
      agreementNumber: { value: settlement.agreementNumber, raw: settlement.agreementNumber },
      agreementType: { value: settlement.agreementType, raw: settlement.agreementType },
      agreementProvider: { value: settlement.agreementProvider, raw: settlement.agreementProvider },
      settlementHow: { value: settlement.settlementHow, raw: settlement.settlementHow },
      notes: { value: settlement.notes, raw: settlement.notes },
      count: { value: found.length, raw: found.length },
      options: { value: optionsValue, raw: 0 }
    }
  })


  return <Fragment>
    <div className="mar-b10 float-right">
      <UILIB.Button disabled={!isActive || !dataLoaded?.settlement} className="mar-r10" value="Add Additional Agreement" onClick={() => editSettlement()} />
      <UILIB.Button disabled={!isActive || !dataLoaded?.settlement} value="Add from Existing Agreement" onClick={addExisting} />
    </div>
    <p className="clear-both mar-l10 mar-b10">Add any Agreements that are ending or require settling below. To add lines associated with a Settlement, please use the  'Add Settlement' option on the main page</p>
    <div>
      <UILIB.Table 
        className="small" 
        overflowX="auto"
        overflowY="hidden"
        loading={false} 
        header={headerData} 
        data={tableData} 
        sortable={false} 
        filter={false}
        maxRows={10} 
        autoResize={false}
        defaultOrderBy="" 
        defaultOrderDir="" />
    </div>
  </Fragment>
}