import { useState, useEffect, useRef, Fragment } from 'react';
import { permissionsRouter, permissionsObject, groupsObject, permissionsGroupExporter, permissionDecider, permissionAccount } from 'classes/permissions';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'
import { deleteProduct } from './functions'
import UILIB from 'components';
import axios from 'classes/axios';
import BlockProductsTable from './tables/blockProductsTable';


export default function PartsManagement() {

    const location = useLocation();
    const account = useSelector((state) => state.account);
    const permissions = useSelector((state) => state.permissions);
    const history = useHistory();
    const productFilterInput = useRef(null);
    const permissionHubs = useRef([{ name: 'partsManagementHub', hubName: 'Parts Management Hub', routeName: location.pathname }]);
    const localPermissions = useRef({});
    const [loading, setLoading] = useState(false);
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);
    const [tableDataRaw, setTableDataRaw] = useState([]);
    const [deleting, setDeleting] = useState(null);
    const [exporting, setExporting] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [partsTableSortHandler, setPartsableSortHandler] = useState({ active: false, direction: 'asc', headName: 'id', offset: 0, page: 0, limit: 20, totalRows: 0, filter: '' });
    const [columnFilterHander, setColumnFilterHander] = useState({ active: false, headName: null, headValue: null, propertyType: null, clientX: null, clientY: null, filter: '', columnFilters: [] });
    const [productExpandRow, setProductExpandRow] = useState([]);
    const [deletingProduct, setDeletingProduct] = useState(false);
    const [productRender, setProductRender] = useState(0); 
    const [renderLoading, setRenderLoading] = useState(false);


    const serviceMeterData = [
      { label: "Please Select", value: -1 },
      { label: "Colour", value: "Colour" },
      { label: "Mono", value: "Mono" },
      { label: "None", value: "None" }
    ];


    const getParts = async () => {

      setTableDataRaw([]);

      const result = await axios.post(`/sales/parts/`, { partsTableSortHandler: partsTableSortHandler, columnFilterHander: columnFilterHander });

      const manufacturers = await axios.get("/sales/parts/manufacturer").then((data) => data.data);
      const suppliers = await axios.get("/sales/parts/supplier").then((data) => data.data);
      const rangeParts = await axios.get("/sales/parts/range/rangeParts").then((data) => data.data);

      result.data.result.forEach(product => {
        const salesParts = [];
        const salesPartsConflicting = [];
        const rangeIds = product.SalesPartRanges.map(rangePart => { return rangePart.id });
        const foundRangeParts = rangeParts.filter(f => rangeIds.includes(f.id));
        foundRangeParts.forEach(f => f.SalesParts.forEach(p => { if(!salesParts.some(contains => contains.name === p.name)) salesParts.push(p) }));
        salesParts.forEach(compat => compat.ConflictingParent.forEach(cp => { 
          if(cp.SalesPartConflicting.ParentId === product.id && !salesPartsConflicting.some(contains => contains.id === cp.id)) salesPartsConflicting.push(cp); 
        }));
        const accessories = result.data.result.filter(f => product.accessories.includes(f.id))
        product.incompatiblesRaw = salesPartsConflicting;
        product.compatibles = accessories;
      })

      result.data.result.forEach(product => {
        product.manufacturer = manufacturers.find(man => man.id === product.SalesPartManufacturerId);
        product.supplier = suppliers.find(sup => sup.id === product.SalesPartSupplierId);
        product.meterData = serviceMeterData.find(service => service.value === product.serviceMeters);
      })

      setTableDataRaw(result.data.result);
      setPartsableSortHandler(result.data.uploadTableSortHandler);
    };

    useEffect(() => {
      async function fetchData() {
        setPermissionsLoaded(false);
        await permissionsRouter(history, permissions, account, null, location.pathname, 'General Access');
        await permissionsGroupExporter(permissions, account, permissionHubs.current, localPermissions.current);
        await permissionDecider(permissions, account, permissionHubs.current, localPermissions.current);
        await permissionAccount(account, localPermissions.current);
        setPermissionsLoaded(true);
      }
      fetchData();
    }, []);


    useEffect(() => {
      const timer = setTimeout(async () => {
        setPartsableSortHandler({ ...partsTableSortHandler });
        setLoading(true);
        await getParts();
        setLoading(false);
        setRenderLoading(false);
      }, 100);
      return () => clearTimeout(timer);
    }, [productRender])


    if (!permissionsLoaded) return <UILIB.Loading type={3} />

    const theTable =
        <div className="flex-container row end height-100 mar-t10 mar-l5 mar-r5">
          <BlockProductsTable
            tableDataRaw={tableDataRaw} 
            setTableDataRaw={setTableDataRaw} 
            loading={loading}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            partsTableSortHandler={partsTableSortHandler}
            setPartsableSortHandler={setPartsableSortHandler}
            productExpandRow={productExpandRow}
            setProductExpandRow={setProductExpandRow}
            columnFilterHander={columnFilterHander}
            setColumnFilterHander={setColumnFilterHander}
            deletingProduct={deletingProduct}
            setDeletingProduct={setDeletingProduct}
            productRender={productRender}
            setProductRender={setProductRender}
            deleting={deleting} 
            setDeleting={setDeleting}
            exporting={exporting}
            setExporting={setExporting}
            setAlertMessage={setAlertMessage}
            setAlertOpen={setAlertOpen}
            renderLoading={renderLoading}
            setRenderLoading={setRenderLoading}
            localPermissions={localPermissions.current}
            permissionsObject={permissionsObject}
            permissions={permissions}
            groupsObject={groupsObject}
            productFilterInput={productFilterInput}
            history={history}
            location={location}
          />
        </div>;

    const confirmationPopupDelete = deleting && !deleting.hasPartConflictingBind && !deleting.hasPartCompatibleBind &&
        <UILIB.Confirm 
            onConfirm={async () => await deleteProduct(deleting, setDeleting, tableDataRaw, setTableDataRaw)}
            onCancel={() => setDeleting(null)}
            header="Delete Part"
            text={'Are you sure you want to delete ' + deleting.name + '?'} 
        />;

    const confirmationPopupCantDelete = deleting && (deleting.hasPartConflictingBind || deleting.hasPartCompatibleBind) &&
        <UILIB.MessageBox 
            onConfirm={() => { setDeleting(null) }} 
            header="Unable to Delete" 
            text={'Part cannot be deleted as it is currently associated to one or more Parts. To delete, first remove the association from the Part(s)'} 
        />;

    return <Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="row" style={{ flex: 1 }}>
                {theTable}
            </div>
            {confirmationPopupDelete}
            {confirmationPopupCantDelete}
            <UILIB.Alert message={alertMessage} open={alertOpen} onClose={() => setAlertOpen(false)} />
        </div >
    </Fragment>
}