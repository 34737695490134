
import { Fragment } from 'react';
import { tableContainer } from '../functions';

export default function DeviceModalContainer({ row, setDeviceModalData }) {

  const className = `${row.installs === 0 ? 'colour background-4 green' : 'colour background-4 red'}`;
  const toolTip = `${row.installs === 0 ? 'All Products Booked' : 'Book Install'}`;

  const fn_installModal = setDeviceModalData.bind(null, {  show: true, entity: row });

  return <Fragment>
          <div className="flex-container row width-100 height-100">
            <div className={`flex-item start width-100`}>{tableContainer(fn_installModal, 'icon-truck', toolTip, className, true)}</div>
          </div>
        </Fragment>
}
