import { useState, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import Axios from 'classes/axios';
import UILIB from 'components';
import { formatCurrency, formatDateTime } from 'classes/format';
import { actionOpenDrawer, setMachineReportFilterVars } from '../../functions';


export default function MachineReport({ filters, setFilters }) {

  const headerData = [
    { label: 'Deal Number', value: 'finalDealNo', type: 'string', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: 'Company Name', value: 'companyName', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
    { label: 'Sales Person', value: 'salesPerson', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Sales Region', value: 'salesPerson', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Deal Type', value: 'dealType', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Payment Type', value: 'leaseorcash', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'QTY', value: 'qty', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Part Number', value: 'partNo', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Product Description', value: 'description', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Supplier', value: 'supplier', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Is a Device', value: 'isADevice', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'New/Used', value: 'newUsed', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Cost', value: 'costEach', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Total Cost', value: 'costTotal', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Invoice Date', value: 'invoiceDateConvert', type: 'date', filtering: true, minWidth: 70, maxWidth: 70 }];

  const dispatch = useDispatch();
  const entityData = useRef({});
  const [remoteRender, setRemoteRender] = useState(false);


  async function constructTable(quoteData) {

    const tableData = quoteData.map(row => {
      return {
        finalDealNo: { value: row.finalDealNo, raw: row.finalDealNo },
        companyName: { value: row.companyName, raw: row.companyName },
        salesPerson: { value: row.salesPerson, raw: row.salesPerson },
        serviceRegion: { value: row.serviceRegion, raw: row.serviceRegion },
        dealType: { value: row.dealType, raw: row.dealType },
        leaseorcash: { value: row.leaseorcash, raw: row.leaseorcash },
        qty: { value: row.qty, raw: Number(row.qty) },
        partNo: { value: row.partNo, raw: row.partNo },
        description: { value: row.description, raw: row.description },
        supplier: { value: row.supplier, raw: row.supplier },
        isADevice: { value: row.isADevice, raw: Number(row.isADevice) },
        newUsed: { value: row.newUsed, raw: row.newUsed },
        costEach: { value: formatCurrency(row.costEach), raw: Number(row.costEach) },
        costTotal: { value: formatCurrency(row.costTotal), raw: Number(row.costTotal) },
        invoiceDateConvert: { value: formatDateTime(row.invoiceDate, 'YYYY-MM-DD'), raw: row.invoiceDate } 
      }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const calcFilters = setMachineReportFilterVars(filters.machineReportDrawer);

    entityData.current = await Axios.get(`/entities/workflow/machineReport?&$filter=${[calcFilters].join(' and ')}${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    <div className='flex-container row width-100 end pad-b10'>
      <div className='flex-item start flex-grow-1'><b>Machine Report</b></div>
      <div className='flex-item end'>
        <UILIB.Button value="Filter Options" onClick={() => { actionOpenDrawer('MachineReportDrawer', { filters, setFilters }, dispatch, true, { get: remoteRender, set: setRemoteRender }, '400px') }} />
      </div> 
    </div>   
    <UILIB.TableNew
      name={`machineReport`}
      className='small'
      overflowX='auto'
      overflowY='hidden'    
      header={headerData}
      remoteQuery={getTableData}
      remoteRender={[filters.machineReportDrawer]} />   
  </Fragment>
}