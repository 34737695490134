import Axios from 'axios';
import UILIB from 'components';
import { Fragment, useRef, useState } from 'react';
import TextInput from 'components/TextInput/TextInput';
import ClickAway from 'components/ClickAway';


export default function AutoComplete({ 
    className = '',
    textInputClassName = '',
    buttonClassName = '',
    buttonText,
    style = {}, 
    name = '',
    query = null, 
    componentName = null,
    errored = { messageText: "", option: 0 },
    loading = { get: null, set: null },
    onClick = async () => { }, 
    onClearDown = async () => { }, 
    enableButton = true,
    placeholder = "Type to Start Searching", 
    blank = '', 
    defaultValue = '', 
    minLength = 3,
    disabled = false }) 
{

    const timer = useRef(null);
    const cancelToken = useRef(Axios.CancelToken);
    const source = useRef(null);
    const input = useRef(null);

    const [firstLoad, setFirstLoad] = useState(true);
    const [searchText, setsearchText] = useState(defaultValue);
    const [data, setData] = useState([]);
    const [selectd, setSelected] = useState(null);
    const [show, setShow] = useState(false);
    const [searching, setSearching] = useState(false);

    const search = async (searchText) => {

      if(!searchText || !searchText.length) return;

      setShow(true);
      setSearching(true);

      if (timer.current) clearTimeout(timer.current);

      timer.current = setTimeout(async () => {

        try {

          if (source.current) source.current.cancel('cancelled');

          const newSource = cancelToken.current.source();
          source.current = newSource;

          if (searchText && searchText.length < minLength) {
            setData([]);
          } else if (typeof (query) === 'function') {
            if(typeof(loading) === 'object' && loading.set) loading.set(true);
            const results = await query(searchText, { cancelToken: newSource.token }, ...arguments);
            setData(results);
          }

          setSearching(false);

        } catch (err) {
          if(typeof(loading) === 'object' && loading.set) loading.set(false);
          setSearching(false);
        }

      }, 200)
    }

    const select = async (option, ev) => {
      ev.target.value = option.value;
      if(componentName) ev.target.name = componentName;
      if(!enableButton) await onClick(option, ...arguments, ev);
      setSelected(option);
      setsearchText((option.label) ? option.label : '');
      setFirstLoad(false);
      setShow(false);
    }

    const onChangeValue = async (ev) => {
      setFirstLoad(false);
      setsearchText((ev.target.value) ? ev.target.value : '');
      await search(ev.target.value);
    }

    const actionClearData = async () => {
      setsearchText('');
      setSelected(null);
      await onClearDown();
    }

    return <Fragment>
      <div className={`flex-item align-center end flex-grow-1 height-100 ${className}`}>
        <div className={`flex-container row width-100 nowrap`}>
          <div className={`autocomplete flex-container column overflow-hidden height-100 width-100`}>
            <TextInput 
              name={name}
              outerClassName='autocomplete height-100'
              className={`textInput autocomplete searchBox noBorder height-25-px ${textInputClassName}`}
              style={style}
              ref={input} 
              value={(firstLoad && (searchText && !searchText.length)) ? defaultValue : searchText} 
              onChange={async (ev) => await onChangeValue(ev)} 
              onClick={async () => await actionClearData()} 
              placeholder={placeholder}
              errored={errored}
              disabled={disabled} />
            {Boolean(searchText && searchText.length >= 3 && show) && <ClickAway onClickAway={() => setShow(false)}>
              <div className="container flex-item row" style={{ width: (input.current) ? Math.round(input.current.getBoundingClientRect().width) : 'auto', align: "left", marginTop: 0 }}>
                {(searching && !enableButton) && <div className="flex-item row start center align-center width-100 height-22-px"><UILIB.Loading colourClass={'colour background grey'} type={2}/></div>}
                {Boolean(!searching && data && data.length) && data.map((c, i) => {
                    return <div className="item hover width-100" searchText={c.value} value={c.value} key={c.id || i} onClick={async (event) => await select(c, event)}>{c.label}</div> })}
                {Boolean(!data && data.length && !searching) && <div>{blank}</div>}
              </div>
            </ClickAway>}
          </div>
          {enableButton && 
            <UILIB.Button 
            loading={searching} 
            disabled={(disabled || searching || !selectd)} 
            value={buttonText || "Apply"} 
            className={`button autoComplete small min-width-80 height-25-px ${buttonClassName}`} 
            onClick={async (ev) => await onClick(selectd, ...arguments, ev)} />}
        </div>
      </div>
    </Fragment>
}