import moment from 'moment-timezone'

export function exportData(header, tableData, name) {

    let csvContent = "data:text/csv;charset=utf-8,";

    csvContent += header.filter(r => !r.dontExport).map((row) => {
      if(row.split && Array.isArray(row.split)) { 
        return row.split.map(s => { return s }).join(",");
      }
      else {
        return row.value
      }
    }).join(",") + "\n";

    tableData.forEach((row) => {
        let lineOut = "";
        header.filter(r => !r.dontExport).forEach(header => {

          let val = "";

          if(header.split && Array.isArray(header.split)) {
            for(var i = 0; i < header.split.length; i++) {
              let foundRow = row[header.value]
              if (foundRow && header.split[i] !== '') {
                val = foundRow.raw[i];
              }
              lineOut += `"${typeof(val) === "undefined" ? '' : val}",`;
            }
          }
          else {
            let foundRow = row[header.value]
            if (foundRow) {
              val = foundRow.raw;
            }
            lineOut += `"${typeof(val) === "undefined" ? '' : val}",`;
          }
        })
        csvContent += lineOut.substring(0, lineOut.length - 1) + "\n";
    })

    const date = moment().format('YYYYMMDD-HHMMss');
    const encodedUri = encodeURI(csvContent.replace(/#/g, " "));
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", (name) ? `${name}_${date}.csv` : `my_data_${date}.csv`);
    document.body.appendChild(link); 
    link.click();
}