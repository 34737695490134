import { useEffect, useRef, useState } from 'react';
import UILIB from 'components';
import axios from 'classes/axios';


const tableHeader = [
  { label: "", value: "check", width: "30px" }, 
  { label: "Name", value: "name" },
  { label: "Part Number", value: "partNumber" }
]


export default function AccConflictingDrawer({ finished, drawerType, manufacturers, suppliers, ranges, categories, types, partId, existingCompatibles }) {

    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [manufacturerId, setManufacturerId] = useState(0);
    const [rangeId, setRangeId] = useState(0);
    const [existing, setExisting] = useState([]);
    const [existingAccessories, setExistingAccessories] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [selectedProds, setSelected] = useState([]);
    const [searchText, setSearchText] = useState('')
    const [processing, setProcessing] = useState(false)

    const searchTimer = useRef(null)

    const changeFilters = function (event) {
        if (event.target.name === "manufacturerId") {
            setManufacturerId(Number(event.target.value));
            setRangeId(0);
        }
        if (event.target.name === "rangeId") {
            setRangeId(Number(event.target.value));
            setLoading(true);
            axios.get("/sales/parts/?rangeId=" + event.target.value).then(data => {
                let finalData = data.data.filter(d => {
                    let found = existing.find(ex => ex.id === d.id)
                    if (found) { return false }
                    return true;
                })

                finalData = finalData.filter(d => {
                    let found = existingAccessories.find(ex => ex.id === d.id)
                    if (found) { return false }
                    return true;
                })

                setTableData(finalData);
                setLoading(false);
            });
        }

    }

    const getCompatibles = async function (event) {
        let existingData = await axios.get(`/sales/parts/conflicting/${partId}`);
        let accessoryData = await axios.get(`/sales/parts/compatible/${partId}`);
        setExisting(existingData.data)
        setExistingAccessories(accessoryData.data);
        setLoading(false);
    }


    const goAddAccessory = async function () {
        setProcessing(true)
        let tCnt = 0;
        while (tCnt < selectedProds.length) {
            try {
                await axios.post(`/sales/parts/conflicting/${partId}`, { partId: selectedProds[tCnt] })
            }
            catch (err) { }
            tCnt++;
        }
        finished();
        setProcessing(false)
    }

    const selectCheck = function (value) {
        let newSelected = JSON.parse(JSON.stringify(selectedProds))
        if (newSelected.indexOf(value) > -1) {
            newSelected.splice(newSelected.indexOf(value), 1)
        }
        else {
            newSelected.push(value)
        }
        setSelected(newSelected);
    }

    const buildTable = async function () {
        let final = tableData.filter(f => f.id !== partId).map(row => {
          const isSelected = selectedProds.indexOf(row.id) > -1;
          return {
            check: { value: <UILIB.Checkbox onChange={() => { selectCheck(row.id) }} name="checkBox" checked={isSelected} /> },
            name: { value: row.name },
            partNumber: { value: row.partNumber }
          }
        })
        setTableRows(final);
    }

    const updateSearchText = e => {
        setSearchText(e.currentTarget.value)
        if (searchTimer.current) clearTimeout(searchTimer.current)
        searchTimer.current = setTimeout(search, 250)
    }

    const search = async () => {
        setSelected([])
        let qry = "?";
        if (searchText !== "" && searchText.length > 2) qry += `&searchText=${searchText}`
        else return
        setLoading(true)
        const res = await axios.get(`/sales/parts${qry}`)
        let finalData = res.data.filter(d => {
            let found = existing.find(ex => ex.id === d.id)
            if (found) { return false }
            return true;
        })

        finalData = finalData.filter(d => {
            let found = existingAccessories.find(ex => ex.id === d.id)
            if (found) { return false }
            return true;
        })
        setTableData(finalData)
        setLoading(false)
    }

    useEffect(() => {
        buildTable();
        // eslint-disable-next-line
    }, [tableData, selectedProds])

    useEffect(() => {
        getCompatibles();
        return function () {
            if (searchTimer.current) clearTimeout(searchTimer.current)
        }
        // eslint-disable-next-line
    }, []);

    const mans = [{ label: 'Select Manufacturer', value: 0 }].concat(...manufacturers)

    return <div className="flex-container row">
        <h2>Add a new Conflicting Product</h2>
        <p>Find a part or accessory that conflicts using the search.</p>

        <UILIB.TextInput className="textInput input" value={searchText} disabled={manufacturerId} onChange={updateSearchText} placeholder="Search Product Name or Number" />
        {!searchText.length && <p>Or</p>}

        {!searchText.length && <UILIB.Select data={mans} name="manufacturerId" value={manufacturerId} onChange={changeFilters} />}
        {manufacturerId > 0 && !searchText.length && <UILIB.Select name="rangeId" placeholder="Select a Range" data={ranges.filter(r => r.SalesPartManufacturerId === manufacturerId)} value={rangeId} onChange={changeFilters} />}

        {loading && <UILIB.Loading type={3} />}
        {!loading && (rangeId > 0 || searchText.length > 2) && <div className="flex-item width-100">
            {!tableData.filter(f => f.id !== partId).length && <div>No available or unbound products</div>}
            {tableData.filter(f => f.id !== partId).length > 0 && <UILIB.Table className="small" overflowX="auto" overflowY="hidden" autoResize={true} maxRows={15} sortable={true} filter={true} loading={false} header={tableHeader} data={tableRows} showRecords={false} />}
        </div>}
        {selectedProds.length > 0 && <div className="flex-item width-100">
            <UILIB.Button disabled={processing} loading={processing} value={selectedProds.length > 1 ? "Add Accessories" : "Add Accessory"} onClick={goAddAccessory} />
        </div>}
    </div >

}
