import { Fragment, useRef, useState, useEffect } from "react"
import { useHistory, useParams } from 'react-router-dom'
import axios from "classes/axios"
import UILIB from 'components'
import Loading from "components/Loading/Loading"

const fields = [
  { label: 'Product Name', value: 'name', default: 'Product' }, 
  { label: 'Part #', value: 'partNumber', default: 'Manufacturer Part No' }, 
  { label: 'Manufacturer Part #', value: 'manufacturerPartNumber', default: 'EDP' }, 
  { label: 'Description', value: 'description', default: 'Product Description' }, 
  { label: 'List Price', value: 'listPrice', default: 'List' }, 
  { label: 'Partner Price', value: 'partnerPrice', default: 'Partner' }, 
  { label: 'Sales Base Price', value: 'basePrice', default: 'Sales Base' }, 
  { label: 'Special Price (eg. CPC, LGEM)', value: 'specialPrice', default: 'Special Base' }, 
  { label: 'Notes', value: 'productNotes', default: 'Notes' }, 
  { label: 'Manufacturer', value: 'SalesPartManufacturer', default: 'Manufacturer' }, 
  { label: 'Category', value: 'SalesPartCategory', default: 'VO Category' }, 
  { label: 'Type', value: 'SalesPartType', default: 'Price List Part Type' }, 
  { label: 'VO Type', value: 'SalesPartVoType', default: 'VO Type' }, 
  { label: 'Supplier', value: 'SalesPartSupplier', default: 'Default Supplier' }, 
  { label: 'Ranges', value: 'SalesPartRange', default: 'Range' }, 
  { label: 'Incompatibles', value: 'incompatibles', default: 'Incompatibilities' }, 
  { label: 'Dependancies', value: 'dependancies', default: 'Dependancies' }, 
  { label: 'Available Accessories', value: 'availableAccessories', default: 'Accessories' }, 
  { label: 'Required Accessories', value: 'required', default: 'Required' },
  { label: 'Conflicting Products', value: 'conflictingProducts', default: 'Conflicting' }, 
  { label: 'Color Base Price', value: 'colourBasePrice', default: 'Colour base' }, 
  { label: 'Mono Base Price', value: 'monoBasePrice', default: 'Mono Base' }
]

function calcHeader(importJob) {

    if (!importJob.headerDefinition) importJob.headerDefinition = {}
    importJob.headerSample[0].forEach(header => {

        const found = fields.find(f => {
            return f.label.toLocaleLowerCase() === header.toLowerCase() || f.value.toLowerCase() === header.toLowerCase() || (f.default && f.default.toLowerCase() === header.toLowerCase())
        }
        )
        if (found) {
            importJob.headerDefinition[header] = found.value
        }
    })
}

export default function SetupPartImport() {
    const fileInput = useRef(null);
    const history = useHistory();
    const { id } = useParams();
    const [uploading, setUploading] = useState(false);
    const [importJob, setImportJob] = useState(null);
    const [importing, setImporting] = useState(null);
    const [errors, setErrors] = useState([]);

    const upload = async () => {
        if (!fileInput.current || !fileInput.current.files.length) return
        setUploading(true)
        const file = fileInput.current.files[0]
        const formData = new FormData()
        formData.append('file', file)
        const response = await axios.post('/sales/parts/import/header', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })
        calcHeader(response.data)
        setImportJob(response.data)
        fileInput.current.value = '';
        setUploading(false)
    }

    const setHeaderValue = e => {
        const job = { ...importJob }
        if (!job.headerDefinition) job.headerDefinition = {}
        job.headerDefinition[e.currentTarget.name] = e.currentTarget.value;
        setImportJob(job)
    }

    const clear = header => {
        const job = { ...importJob }
        if (!job.headerDefinition) job.headerDefinition = {}
        delete job.headerDefinition[header];
        setImportJob(job)
    }

    const goImport = async () => {
        setImporting(true)
        // check all the required fields are done
        const _errors = []
        const vals = []
        Object.keys(headerDefinition).forEach(hd => {
            vals.push(headerDefinition[hd])
        })
        fields.filter(f => f.required).forEach(field => {
            if (vals.indexOf(field.value) === -1) {
                _errors.push(field.label + ' Required')
            }
        })

        setErrors(_errors)
        if (_errors.length) {
            return setImporting(false)
        }

        await axios.put('/sales/parts/import/' + importJob.id, importJob)
        history.push('/saleshub/partsManagement/import/' + importJob.id)
    }

    useEffect(() => {
        if (id) {
            axios.get('/sales/parts/import/' + id).then(res => setImportJob(res.data))
        } else {
            fileInput.current.click()
        }
        // eslint-disable-next-line
    }, [])

    let headers = [], samples = [], headerDefinition = {}

    if (importJob && importJob.headerSample) {
        headers = new Array(importJob.headerSample[0].length).fill(1)
        samples = importJob.headerSample.filter((v, i) => !!i)
        headerDefinition = importJob.headerDefinition || {}
    }

    const vals = []
    Object.keys(headerDefinition).forEach(hd => {
        vals.push(headerDefinition[hd])
    })

    const availableFields = [
      { label: 'Select Field', value: -1 },
      ...fields.map(f => ({ ...f, disabled: vals.indexOf(f.value) > -1 }))];

    return (
        <div className="flex-container row end mar-t10 mar-l5 mar-r5">
            <div className="flex-item flex-grow-1 start mar-b10">
              <UILIB.Button value="Back" className="grey" onClick={() => history.push('/saleshub/partsManagement/import')} />
            </div>
            <div className="flex-item flex-grow-1 end mar-b10">
              <UILIB.Button value="Upload File" disabled={uploading || importing} onClick={() => fileInput.current.click()} />
            </div>

            <input type="file" ref={fileInput} style={{ display: 'none' }} onChange={upload} />

            {uploading && <Loading type={3} />}

            {!uploading && Boolean(importJob) && <Fragment>
                <div className="importSample mar-b10">
                    {headers.map((head, index) => {
                        const header = importJob.headerSample[0][index]
                        const selected = Boolean(headerDefinition[header] && Number(headerDefinition[header]) !== -1)
                        return <UILIB.Paper key={index} className={"flex-item start height-100 sample " + (selected ? 'secondary' : '')}>
                            <h3>{header}</h3>
                            <UILIB.Select disabled={importing} data={availableFields} value={headerDefinition[header]} name={header} onChange={setHeaderValue} />
                            {samples.filter(s => s !== null).map((row, rIndex) => <div key={rIndex}>
                                {row[index]}
                            </div>)}
                            {selected && <div className="clearButton">
                                <UILIB.Button className="small grey" value="clear" onClick={() => clear(header)} />
                            </div>}
                        </UILIB.Paper>
                    })}
                </div>
                <UILIB.Button value="Complete Import" onClick={goImport} disabled={importing} />
                {Boolean(errors.length) && errors.map((err, index) => <div key={index} className="text-red text-small">{err}</div>)}
                {importing && <div className="text-primary">{importJob.status}</div>}
            </Fragment>}
        </div>
    )
}