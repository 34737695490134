import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function OrderDeclineDrawer({ ourDeal, history, onCancel }) 
{

  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404,409];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const versionError = 'A newer version of this record has already been saved. Please reload the data';
  const heading = 'You are about to decline this deal.';

  
  const actionDeclineOrder = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const result = await Axios.put(`/entities/workflow/deals/actionDeclineDeal/${ourDeal.dealID}`, { data: ourDeal }); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        if(result.status === 409) setBannerError({ error: true, message: versionError });
      } else {
        history.push('/sopHub');
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  }


  return <Fragment>
    <div className="flex-container row wrap start align-center mar-b10 font-weight-bold text-16">{heading}</div>
    <p>Are you sure you want to continue?</p>
    <div className="flex-container row start width-100">
      <UILIB.Button className='red' disabled={saving} value='Cancel' onClick={() => onCancel()} />
      <UILIB.Button className='green' disabled={saving} loading={saving} value='Decline' onClick={async () => await actionDeclineOrder()} />
      {bannerError.error && <div className="flex-container start wrap width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>
}