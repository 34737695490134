
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import UILIB from 'components';
import Axios from 'classes/axios';
import { formatDateTime } from 'classes/format';
import { exportData } from 'classes/export';

const header = [
    {
        label: "Serial #",
        value: "serialnumber",
        align: "center",
        type: 'string'
    },
    {
        label: "Device",
        value: "devicename",
        align: "center",
        type: 'string'
    },
    {
        label: "Install Date",
        value: "installdate",
        align: "center",
        type: 'number'
    },
    {
        label: "Customer",
        value: "customername",
        width: "400px",
        align: "center",
        type: 'string'
    },
    {
        label: "Salesperson",
        value: "salesperson",
        type: 'string',
        options: 'auto'
    },
    {
        label: "Ticket Number",
        value: "jobno",
        width: "120px",
        align: "center",
        type: 'string'
    },
    {
        label: "Date Logged",
        value: "datelogged",
        align: "center",
        type: 'number'
    },
    {
        label: "Logged By",
        value: "loggedby",
        align: "center",
        type: 'number'
    },
    {
        label: "No Faults, last 30 days",
        value: "numberFaults",
        width: "170px",
        align: "center",
        type: 'string'
    },
    {
        label: "Call Type",
        value: "calltype",
        align: "center",
        type: 'string'
    }
];



export default function ServiceView({ sessionData }) {
    const history = useHistory();
    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);
    const [callTypes, setCallTypes] = useState([]);
    const [tableFilter, setTableFilter] = useState({ typeId: [] });


    const generateTable = () => {
        let qs = tableFilter.typeId.length > 0 ? `&callType=${tableFilter.typeId}` : "&callType=-1";
        Axios.get(`/sales/customers/tableview?tableType=servicealerts&justMe=true${qs}`).then(customers => {

            let tableData = customers.data.map((row) => {
                return {
                    "serialnumber": { value: row.serialnumber, raw: row.serialnumber },
                    "devicename": { value: row.devicename, raw: row.devicename },
                    "installdate": { value: formatDateTime(Date.parse(row.installdate), "DD/MM/YYYY HH:mm:ss"), raw: row.installdate },
                    "customername": { value: row.customername, raw: row.customername },
                    salesperson: { value: row.salesPerson ? row.salesPerson.displayName : '', raw: row.salesPerson ? row.salesPerson.displayName : '' },
                    "jobno": { value: row.jobno, raw: row.jobno },
                    "datelogged": { value: formatDateTime(Date.parse(row.datelogged), "DD/MM/YYYY HH:mm:ss"), raw: row.datelogged },
                    "loggedby": { value: row.loggedby, raw: row.loggedby },
                    "numberFaults": { value: row.numIssues, raw: row.numIssues },
                    "calltype": { value: row.calltype, raw: row.calltype }
                }
            })


            setTableData(tableData);
            setLoading(false)
        })
    }


    const goExport = () => {
        exportData(header, tableData);
    }

    const handleTypeChange = (typeVal) => {
        const newTableFilter = { ...tableFilter };
        if (typeVal === "-1") {
            newTableFilter.typeId = [];
        } else {
            newTableFilter.typeId = typeVal;
        }
        setTableFilter(newTableFilter);
    };

    useEffect(() => {
        let filters =
            [
                { value: -1, label: "All Types" },
                { value: 1, label: "Machines Down" },
                { value: 2, label: "High Callouts" },
                { value: 3, label: "Service Calls" }
            ]
        setCallTypes(filters);
        generateTable();
        // eslint-disable-next-line
    }, [tableFilter]);


    return <div className="flex-container row">
        <div className="col-xs-12 mar-b15 mar-t5">
            <UILIB.Button className="white outline" value="Back" iconBefore="icon-arrow-left" onClick={() => { history.push('/salesHub/accountmanagerview') }} />
        </div>
        <div className="col-xs-12 col-sm-6 mar-b10 mar-t10">
            <h2 className="mar-b0">Service Alerts</h2>
        </div>
        <div className="col-xs-12 col-sm-6 flex-container end">
            <UILIB.Select style={{ float: 'right', width: '180px', padding: 2 }} data={callTypes} value={tableFilter.typeId} onChange={(ev) => { handleTypeChange(ev.target.value) }} />
        </div>
        <div className="col-xs-12 mar-b15">
            <UILIB.Paper>
                <UILIB.Table className="small" filter={true} header={header} data={tableData} loading={loading} sortable={true} defaultOrderBy="calltype" defaultOrderDir="DESC" maxRows={12} />
            </UILIB.Paper>
        </div>
        <div className="col-xs-12 mar-b15 text-right">
            <UILIB.ExportButton onClick={goExport} />
        </div>
    </div>
}