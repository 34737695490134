import { useParams, useHistory } from 'react-router-dom'
import axios from 'classes/axios'
import { Fragment, useEffect, useRef, useState } from 'react'
import UILIB from 'components'
import Errors from './errors'

export default function ImportProgress() {
    const { id } = useParams()
    const checkTimer = useRef(null)
    const history = useHistory()
    const [importJob, setImportJob] = useState(null)
    const [showErrors, setShowErrors] = useState(false)

    const checkJob = async () => {
        if (checkTimer.current) clearTimeout(checkTimer.current)
        const response = await axios.get('/sales/parts/import/' + id)
        setImportJob(response.data)
        if (response.data.status !== 'Finished' && response.data.status !== 'Error') {
            checkTimer.current = setTimeout(checkJob, 500)
        }
    }

    useEffect(() => {
        axios.get('/sales/parts/import/' + id).then(response => {
            setImportJob(response.data)
            checkJob()
        })
    }, [id])

    useEffect(() => {
        return function () {
            if (checkTimer.current) {
                clearTimeout(checkTimer.current)
            }
        }
    }, [])

    if (!importJob) return <UILIB.Loading type={3} />

    return (
        <Fragment>
            <div className="row">
                <div className="col-xs-12 col-md-8 col-md-offset-2 mar-b20">
                    <UILIB.Button className="grey" value="Back" onClick={() => history.push('/saleshub/partsManagement/import')} />
                </div>
                <div className="col-xs-12 col-md-8 col-md-offset-2 mar-b20">
                    {importJob.status}
                    <UILIB.ProgressBar complete={importJob.status === 'Finished' ? 100 : importJob.percentComplete} />
                </div>
                <div className="col-xs-12 col-md-4 col-md-offset-2">
                    <UILIB.Paper>
                        <div className=" text-large text-center">Complete</div>
                        <div className="text-xlarge-count text-center">{importJob.complete}</div>
                    </UILIB.Paper>
                </div>
                <div className="col-xs-12 col-md-4">
                    <UILIB.Paper>
                        <div className="align-right"><UILIB.Button className="primary small" onClick={() => setShowErrors(true)} value="view" /></div>
                        <div className=" text-large text-center">Failed</div>
                        <div className="text-xlarge-count text-center text-red">{importJob.failed}</div>
                    </UILIB.Paper>
                </div>
            </div>
            <UILIB.StandaloneDrawer isOpen={showErrors} onClose={() => setShowErrors(false)}>
                {showErrors && <Errors importJob={importJob} />}
            </UILIB.StandaloneDrawer>
        </Fragment>
    )
}