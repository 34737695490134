import * as React from 'react';
import * as functions from '../shared/functions';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Typography from '@mui/material/Typography';
import { formatDateTime } from 'classes/format';



const Collapse = functions.styleCollapse();


function Row(data) {

  if(!data.row) return <React.Fragment></React.Fragment>

  const open = data.row.innerProps.releaseExpandRow[data.row.createdAt] || false;
  const foundRows = data.row.innerProps.releases.find(f => f.id === data.row.id);

  const subRowSx = {
    backgroundColor: "#EEEEEE",
    borderBottom: "1px solid black"
  }

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderColor: 'grey.500' } }}>
        <TableCell>
          <IconButton
            sx={{ pb: 0, pt: 0 }}
            aria-label="expand row"
            size="small"
            onClick={() => functions.expandRowHander(data.row, data.row.createdAt, data.row.innerProps.setReleaseExpandRow, open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{functions.addSortedReleasePublishedButton(data.row, { backgroundColor: '#ffa500', width: '100%', minWidth: '140px', fontSize: 12, padding: 0  }, data.row.innerProps)}</TableCell>
        <TableCell component="th" scope="row"><Typography sx={{ fontSize:13 }} variant="body2">{data.row.versionNumber}</Typography></TableCell>
        <TableCell component="th" scope="row">{data.row.incrementNumber}</TableCell>
        <TableCell><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{data.row.createdBy}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{formatDateTime(data.row.createdAt, "DD/MM/YYYY HH:mm:ss")}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{data.row.updatedBy}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{formatDateTime(data.row.updatedAt, "DD/MM/YYYY HH:mm:ss")}</Typography></TableCell>
        <TableCell component="th" scope="row">{functions.addSortedReleaseDownloadButton(data.row, { backgroundColor: '#ffa500', width: '100%', minWidth: '160px', fontSize: 12, padding: 0 }, data.row.innerProps)}</TableCell>
        <TableCell component="th" scope="row">{functions.addSortedReleaseDeleteButton(data.row, { backgroundColor: '#E93D24', width: '100%', minWidth: '140px', fontSize: 12, padding: 0 }, data.row.innerProps)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} sx={{ p: 0, m: 0, border: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="tasks">
              <TableHead>  
                <TableRow sx={{ pb: 0, pt: 0 }}>
                  {functions.addSortedHeader('Task#', "2%", 'taskNumber', '#EEEEEE', data.row.innerProps.releaseTableSortHandler, data.row.innerProps.setReleaseTableSortHandler)}
                  {functions.addSortedHeader('Title', "20%", 'title', '#EEEEEE', data.row.innerProps.releaseTableSortHandler, data.row.innerProps.setReleaseTableSortHandler)}
                  {functions.addSortedHeader('Tags', "20%", 'tags', '#EEEEEE', data.row.innerProps.releaseTableSortHandler, data.row.innerProps.setReleaseTableSortHandler)}
                  {functions.addSortedHeader('Assigned To', "20%", 'assigned', '#EEEEEE', data.row.innerProps.releaseTableSortHandler, data.row.innerProps.setReleaseTableSortHandler)}
                  {functions.addSortedHeader('State', "12%", 'state', '#EEEEEE', data.row.innerProps.releaseTableSortHandler, data.row.innerProps.setReleaseTableSortHandler)}
                  {functions.addSortedHeader('Closed Date', "10%", 'closed date', '#EEEEEE', data.row.innerProps.releaseTableSortHandler, data.row.innerProps.setReleaseTableSortHandler)}
                  {functions.addSortedHeader('Iteration Path', "15%", 'iterationPath', '#EEEEEE', data.row.innerProps.releaseTableSortHandler, data.row.innerProps.setReleaseTableSortHandler)}
                </TableRow>
              </TableHead>
              <TableBody>
                {foundRows.adminReleaseItem.map((task) => {
                  return <TableRow key={task.id} sx={subRowSx}>
                    <TableCell component="th" scope="row">{task.taskNumber}</TableCell>
                    <TableCell component="th" scope="row">{functions.addTextFieldUILIB('title', task.title, (ev) => functions.updateLocalField(ev, task, data.row.innerProps, 'child'))}</TableCell>
                    <TableCell component="th" scope="row">{functions.addTextFieldUILIB('tags', task.tags, (ev) => functions.updateLocalField(ev, task, data.row.innerProps, 'child'))}</TableCell>
                    <TableCell component="th" scope="row">{functions.addTextFieldUILIB('assignedTo', task.assignedTo, (ev) => functions.updateLocalField(ev, task, data.row.innerProps, 'child'))}</TableCell>
                    <TableCell component="th" scope="row">{functions.addTextFieldUILIB('state', task.state, (ev) => functions.updateLocalField(ev, task, data.row.innerProps, 'child'))}</TableCell>
                    <TableCell component="th" scope="row">{functions.addTextFieldUILIB('closedDate', formatDateTime(task.closedDate, "DD/MM/YYYY HH:mm:ss"), (ev) => functions.updateLocalField(ev, task, data.row.innerProps, 'child'))}</TableCell>
                    <TableCell component="th" scope="row">{functions.addTextFieldUILIB('iterationPath', task.iterationPath, (ev) => functions.updateLocalField(ev, task, data.row.innerProps, 'child'))}</TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}




export default function CollapsibleTable(props) {

  const handleChangePage = (event, newPage) => {  
    const releaseTableSortHandler = structuredClone(props.releaseTableSortHandler);
    releaseTableSortHandler.page = newPage;
    props.setReleaseTableSortHandler(releaseTableSortHandler);
  };

  const handleChangeRowsPerPage = (event) => {
    const releaseTableSortHandler = structuredClone(props.releaseTableSortHandler);
    releaseTableSortHandler.rowsPerPage = parseInt(event, 10);
    releaseTableSortHandler.page = 0;
    props.setReleaseTableSortHandler(releaseTableSortHandler);
  };

  const handleFilterChange = (event) => {
    const releaseTableSortHandler = structuredClone(props.releaseTableSortHandler);
    releaseTableSortHandler.filter = event.target.value;
    props.setReleaseTableSortHandler(releaseTableSortHandler);
  };

  const foundReleaseData = functions.findReleaseData(props, Row);


  return (
    <React.Fragment>
      <Box display="flex" justifyContent="flex-end">
        {functions.addTextFieldMUI("small", "Search", 'search', "outlined", null, handleFilterChange, { width: '30%', minWidth: 300, pr: 2, mb: 1 })}
      <Button 
        size="small"
        variant="contained"
        sx={{ mb: 1, height: '38px', backgroundColor: '#00B200', fontSize: 12 }}
        onClick={() => functions.uploadFile(props)}
      >
        Upload New
      </Button>
        {functions.addTableRefreshButton({ ml: 2, mb: 1, height: '38px', backgroundColor: '#00B200', fontSize: 12 }, props, props.releaseRender, props.setReleaseRender)}
      </Box>
      <TableContainer component={Paper}>
        <Table 
          size="small" 
          aria-label="collapsible table">
            <TableRow>
              <TableCell sx={{ backgroundColor: "lightgray" }}/>
              {functions.addSortedHeader('Published', "12%", 'published', 'lightgray', props.releaseTableSortHandler, props.setReleaseTableSortHandler)}
              {functions.addSortedHeader('Version Number', "16%", 'versionNumber', 'lightgray', props.releaseTableSortHandler, props.setReleaseTableSortHandler)}
              {functions.addSortedHeader('Increment', "17%", 'incrementNumber', 'lightgray', props.releaseTableSortHandler, props.setReleaseTableSortHandler)}
              {functions.addSortedHeader('Created By', "8%", 'createdAt', 'lightgray', props.releaseTableSortHandler, props.setReleaseTableSortHandler)}
              {functions.addSortedHeader('CreatedAt', "8%", 'createdAt', 'lightgray', props.releaseTableSortHandler, props.setReleaseTableSortHandler)}
              {functions.addSortedHeader('Updated By', "8%", 'updatedAt', 'lightgray', props.releaseTableSortHandler, props.setReleaseTableSortHandler)}
              {functions.addSortedHeader('UpdatedAt', "8%", 'updatedAt', 'lightgray', props.releaseTableSortHandler, props.setReleaseTableSortHandler)}
              {functions.addSortedHeader('File Download', "12%", 'fileDownload', 'lightgray', props.releaseTableSortHandler, props.setReleaseTableSortHandler)}
              {functions.addSortedHeader('Delete', "12%", 'fileDelete', 'lightgray', props.releaseTableSortHandler, props.setReleaseTableSortHandler)}
            </TableRow>
          <TableBody>
            {foundReleaseData.releases}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
          size="small"
          component="div"
          showFirstButton
          showLastButton
          rowsPerPageOptions={[25, 50, 100, 200]}
          count={foundReleaseData.length}
          rowsPerPage={props.releaseTableSortHandler.rowsPerPage}
          page={props.releaseTableSortHandler.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(ev) => handleChangeRowsPerPage(ev.target.value)}

        />
    </React.Fragment>
  );
}
