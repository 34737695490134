
import { useState, memo, Fragment } from 'react';
import UILIB from 'components';
import { timerPromise } from "classes/timers";


const TonerContainer = memo(({ bgCol, txtCol, data, stockName, maxName, disabled, processing, setProcessing, localRender, setLocalRender, onChange }) => 
{ 

  const backgroundCol = (bgCol) ? `colour background ${bgCol}` : '';
  const textCol = (txtCol) ? `colour text ${txtCol}` : '';
  const inputClass = 'borderlessInput Innerinput radius-1';

  const [timeout, setTimout] = useState(null);

  const handleUserInput = async (event, row) => {
    const value = (event.target.value !== '' && (Number(event.target.value) >= 0)) ? Number(String(event.target.value.replace(/^0+/, ''))) : 0;
    row[event.target.name] = value;
    setLocalRender(!localRender);
    const baseData = { value: value, field: event.target.name, row: row }
    await timerPromise(function() { return onChange(baseData); }, 500, timeout, setTimout, setProcessing);
  };

  const generateInput = (fieldName, data, inputClass) => {
    return <UILIB.TextInput 
      disabled={processing || disabled}
      type="string" 
      name={fieldName} 
      value={data[fieldName]} 
      className={inputClass}
      onChange={async (ev) => await handleUserInput(ev, data)} />
  };


  return <Fragment>
    <div className={`flex-container column around height-100 ${backgroundCol}`}>
      <div className={`flex-container row nowrap pad-2`}>
        <div className={`flex-item center pad-l5 pad-r5 consumablesHub tonerContainer-item`}><div className={textCol}>Stock:</div></div>
        <div className={`flex-item flex-grow-1 center consumablesHub tonerContainer-item mar-r5`}>{generateInput(stockName, data, inputClass)}</div>
      </div>
      <div className={`flex-container row nowrap pad-2`}>
        <div className={`flex-item center pad-l5 pad-r5 consumablesHub tonerContainer-item`}><div className={textCol}>Max:</div></div>
        <div className={`flex-item flex-grow-1 center consumablesHub tonerContainer-item mar-r5`}>{generateInput(maxName, data, inputClass)}</div>
      </div>
    </div>
  </Fragment>
})
export default TonerContainer
