
import { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import UILIB from 'components';
import Axios from 'classes/axios'
import { useDispatch, useSelector } from 'react-redux';
import { permissionsGroupExporter, permissionDecider, permissionAccount, groupsObject } from 'classes/permissions';
import { setRefreshToken, setAccessToken, setPermissions, setAccount } from 'store/actions'
import BgImage1 from 'assetts/backgrounds/frontPageTempBg.jpg';


export default function FrontPage() {
  
    const permissionHubs = useRef([
      { name: 'salesHub', hubName: 'Sales Hub', routeName: '/salesHub/accountmanagerview' },
      { name: 'logisticsHub', hubName: 'Logistics Hub', routeName: '/logisticsHub' },
      { name: 'partsManagementHub', hubName: 'Parts Management Hub', routeName: '/salesHub/partsManagement' },
      { name: 'sopHub', hubName: 'Sop Hub', routeName: '/sopHub' },
      { name: 'consumablesHub', hubName: 'Consumables Hub', routeName: '/consumablesHub' },
      { name: 'requestConsumablesHub', hubName: 'Request Consumables Hub', routeName: '/requestConsumablesHub' },
      { name: 'contractsHub', hubName: 'Contracts Hub', routeName: '/contractsHub' },
      { name: 'adminHub', hubName: 'Admin Hub', routeName: '/adminHub' },
      { name: 'orderHub', hubName: 'Order Hub', routeName: '/orderHub' },
      { name: 'fleetHub', hubName: 'Fleet Hub', routeName: '/fleetHub' },
      { name: 'rdHub', hubName: 'RD Hub', routeName: '/rdHub' },
      { name: 'portalHub', hubName: 'Portal Hub', routeName: '/portalHub' },
      { name: 'rmbHub', hubName: 'RMB Hub', routeName: '/rmbHub' } ]);

    const history = useHistory();
    const permissions = useSelector((state) => state.permissions);
    const localPermissions = useRef({});
    const [switchUserError, setSwitchUserError] = useState({ option: 1, messageText: 'Unable to switch user', error: false });
    const [pageLoaded, setPageLoaded] = useState(false);
    const [users360, set360Users] = useState(null);
    const [changingUser, setChangingUser] = useState(false);
    const [userRender, setUserRender] = useState(false);
    const dispatch = useDispatch();

    async function changeUser(event) {
      try {
        setChangingUser(true);
        const loginResult = await Axios.post("/login", { azureId: event.target.value });
        dispatch(setRefreshToken(loginResult.data.refreshToken));
        dispatch(setAccessToken(loginResult.data.accessToken));
        const result = await Axios.get("/users/me");
        dispatch(setAccount(result.data));
        setUserRender(userRender === 0 ? 1 : 0);
        setChangingUser(false);
      }
      catch (error) {
        setChangingUser(false);
        if(error.message.response.status === 500 && error.message.response.data.message === 'Switch User Failed') {
          setSwitchUserError({ ...structuredClone(switchUserError), error: true })
        }
      }
    }

    useEffect(() => {
      const asyncEffect = async () => {
        setPageLoaded(false);
        const accountResult = await Axios.get("/users/me");
        const usersResult = await Axios.get('/users/360users');
        const routeAccessResult = await Axios.get(`/admin/groups/getRouteAccess`);
        await permissionsGroupExporter(routeAccessResult.data.result, accountResult.data, permissionHubs.current, localPermissions.current);
        await permissionDecider(routeAccessResult.data.result, accountResult.data, permissionHubs.current, localPermissions.current);
        await permissionAccount(accountResult.data, localPermissions.current);
        dispatch(setPermissions(routeAccessResult.data && routeAccessResult.data.result));
        set360Users(usersResult && usersResult.data.map(user => { return { label: user.fullName, value: user.activeDirectoryObjectGuid } }));
        setPageLoaded(true);
      }
      asyncEffect();
    }, [userRender])

    

    if (!pageLoaded) return <UILIB.Loading type={3} />

    let isAppDept = false
    let hasPages = false;

    if (localPermissions.current.account.groups.length > 0) hasPages = true;

    if (localPermissions.current.account.groups.indexOf("Apps Dept") > -1 || localPermissions.current.account.groups.indexOf("DevSop") > -1) {
      isAppDept = true;
    }

  return <Fragment>
    <div className="frontPage frontPageModal" style={{ backgroundImage: `url(${BgImage1})` }}>
      <div className="frontPage frontPageModalContent">
        <div>
          <h2>Welcome to Agilico Business Hubs</h2>
          <p className="mar-b10">Please select from one of the following business hubs</p>
        </div>
        {!hasPages && <div>
            <strong>We haven't finished the sections that are relevant to you yet. Please come back soon!</strong>
        </div>}
        {!hasPages && <div></div>}
        {hasPages && <div className="frontPage frontPageOverflowContainer">
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/salesHub/accountmanagerview')} account={localPermissions.current.account}>
            <UILIB.Button value="Sales Hub" disabled={changingUser} onClick={() => history.push('/salesHub/accountmanagerview')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/logisticsHub')} account={localPermissions.current.account}>
            <UILIB.Button value="Logistics Hub" disabled={changingUser} onClick={() => history.push('/logisticsHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/salesHub/partsManagement')} account={localPermissions.current.account}>
            <UILIB.Button value="Product &amp; Accessory Management" disabled={changingUser} onClick={() => history.push('/salesHub/partsManagement')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/sopHub')} account={localPermissions.current.account}>
            <UILIB.Button value="SOP Hub" disabled={changingUser} onClick={() => history.push('/sopHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/contractsHub')} account={localPermissions.current.account}>
            <UILIB.Button value="Contracts Hub" disabled={changingUser} onClick={() => history.push('/contractsHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/consumablesHub')} account={localPermissions.current.account}>
            <UILIB.Button value="Consumables Hub" disabled={changingUser} onClick={() => history.push('/consumablesHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/requestConsumablesHub')} account={localPermissions.current.account}>
            <UILIB.Button value="Request Consumables Hub" disabled={changingUser} onClick={() => history.push('/requestConsumablesHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/adminHub')} account={localPermissions.current.account}>
            <UILIB.Button value="Admin Hub" disabled={changingUser} onClick={() => history.push('/adminHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/orderHub')} account={localPermissions.current.account}>
            <UILIB.Button value="Order Hub" disabled={changingUser} onClick={() => history.push('/orderHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/fleetHub')} account={localPermissions.current.account}>
            <UILIB.Button value="Fleet Hub" disabled={changingUser} onClick={() => history.push('/fleetHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/portalHub')} account={localPermissions.current.account}>
            <UILIB.Button value="Portal Hub" disabled={changingUser} onClick={() => history.push('/portalHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>
          {isAppDept && <UILIB.Button value="RD Hub" disabled={changingUser} onClick={() => history.push('/rdHub')} className="mar-l5 mar-r5 mar-b10" />}
          <UILIB.PermissionsWrapperNew departments={groupsObject('generalAccess', localPermissions.current.groups, permissions, '/rmbHub')} account={localPermissions.current.account}>
            <UILIB.Button value="RMB Hub" disabled={changingUser} onClick={() => history.push('/rmbHub')} className="mar-l5 mar-r5 mar-b10" />
          </UILIB.PermissionsWrapperNew>       
        </div>}
        {(hasPages && isAppDept) && <div>
          <p>Change to another staff member...</p>
          <div>
            <UILIB.Select 
              className="textInput input"
              disabled={changingUser} 
              value={localPermissions.current.account.azureId} 
              data={users360} 
              placeholder="Select a staff member" 
              onChange={async (event) => await changeUser(event)} 
              errored={switchUserError} />
          </div>
        </div>}
        {!isAppDept && <Fragment></Fragment>}
      </div>
    </div>
  </Fragment>
}
