import AdminNotes from './modules/adminNotes';
import EditSop from './modules/editSop';
import ApprovedFiltersDrawer from './modules/approvedFiltersDrawer';
import AwaitingFiltersDrawer from './modules/awaitingFiltersDrawer';
import NotesAdd from '../shared/drawers/notesAdd';
import NotesDrawer from '../shared/drawers/notesDrawer';
import NotesView from '../shared/drawers/notesView';
import SendToLogistics from './modules/sendToLogistics';
import OptionsContainer from './modules/optionsContainer';
import SalesContainer from './modules/salesContainer';
import InvoiceContainer from './modules/invoiceContainer';
import PricingContainer from './modules/pricingContainer';
import MasterDealDrawer from './modules/masterDealDrawer';

const exported = {
  AdminNotes,
  EditSop,
  ApprovedFiltersDrawer,
  AwaitingFiltersDrawer,
  NotesAdd,
  NotesDrawer,
  NotesView,
  SendToLogistics,
  OptionsContainer,
  SalesContainer,
  InvoiceContainer,
  PricingContainer,
  MasterDealDrawer
}
export default exported;