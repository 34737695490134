import { pleaseSelect, checkAndSet, newError, alphaNumericSpaceSpecialPattern, mobilePattern } from '../generic';

export function claimEvaluatedRulesPassed(entity, formErrorsTemp) {
    const yes = 'Yes';
    const no = 'No';

    let e = 0;
    e = e + checkAndSet(!entity.emailReg || entity.emailReg.length < 1 || entity.emailReg === pleaseSelect, formErrorsTemp, 'emailReg', newError('emailReg', 'Please select Email registration'));
    e = e + checkAndSet(!entity.proportionOfVehicleUseByDriver || entity.proportionOfVehicleUseByDriver.length < 1 || entity.proportionOfVehicleUseByDriver === pleaseSelect, formErrorsTemp, 'proportionOfVehicleUseByDriver', newError('proportionOfVehicleUseByDriver', 'Please select Proportion of vehicle use by driver'));
    e = e + checkAndSet(!entity.ifNotPolicyholderDidDriverHavePermissionToDrive || entity.ifNotPolicyholderDidDriverHavePermissionToDrive.length < 1 || entity.ifNotPolicyholderDidDriverHavePermissionToDrive === pleaseSelect, formErrorsTemp, 'ifNotPolicyholderDidDriverHavePermissionToDrive', newError('ifNotPolicyholderDidDriverHavePermissionToDrive', 'Please select Did driver have permission'));

    e = e + checkAndSet(!entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYears || entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYears.length < 1 || entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYears === pleaseSelect, formErrorsTemp, 'hasDriverBeenInvolvedInAccidentOrLossPastThreeYears', newError('hasDriverBeenInvolvedInAccidentOrLossPastThreeYears', 'Please select Involved in accident or loss'));
    if (entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYears && entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYears === yes) {
        e = e + checkAndSet(!entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails || entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails.length < 1, formErrorsTemp, 'hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails', newError('hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails', 'Please enter details'));
        e = e + checkAndSet(!entity.hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails', newError('hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.hasDriverBeenProsecutedOrFixedPenalty || entity.hasDriverBeenProsecutedOrFixedPenalty.length < 1 || entity.hasDriverBeenProsecutedOrFixedPenalty === pleaseSelect, formErrorsTemp, 'hasDriverBeenProsecutedOrFixedPenalty', newError('hasDriverBeenProsecutedOrFixedPenalty', 'Please select Prosecuted or fixed penalty'));
    if (entity.hasDriverBeenProsecutedOrFixedPenalty && entity.hasDriverBeenProsecutedOrFixedPenalty === yes) {
        e = e + checkAndSet(!entity.hasDriverBeenProsecutedOrFixedPenaltyDetails || entity.hasDriverBeenProsecutedOrFixedPenaltyDetails.length < 1, formErrorsTemp, 'hasDriverBeenProsecutedOrFixedPenaltyDetails', newError('hasDriverBeenProsecutedOrFixedPenaltyDetails', 'Please enter details'));
        e = e + checkAndSet(!entity.hasDriverBeenProsecutedOrFixedPenaltyDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'hasDriverBeenProsecutedOrFixedPenaltyDetails', newError('hasDriverBeenProsecutedOrFixedPenaltyDetails', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.hasDriverBeenDeclinedOrRefusedRenewal || entity.hasDriverBeenDeclinedOrRefusedRenewal.length < 1 || entity.hasDriverBeenDeclinedOrRefusedRenewal === pleaseSelect, formErrorsTemp, 'hasDriverBeenDeclinedOrRefusedRenewal', newError('hasDriverBeenDeclinedOrRefusedRenewal', 'Please select Declined or refused renewal'));
    if (entity.hasDriverBeenDeclinedOrRefusedRenewal && entity.hasDriverBeenDeclinedOrRefusedRenewal === yes) {
        e = e + checkAndSet(!entity.hasDriverBeenDeclinedOrRefusedRenewalDetails || entity.hasDriverBeenDeclinedOrRefusedRenewalDetails.length < 1, formErrorsTemp, 'hasDriverBeenDeclinedOrRefusedRenewalDetails', newError('hasDriverBeenDeclinedOrRefusedRenewalDetails', 'Please enter details'));
        e = e + checkAndSet(!entity.hasDriverBeenDeclinedOrRefusedRenewalDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'hasDriverBeenDeclinedOrRefusedRenewalDetails', newError('hasDriverBeenDeclinedOrRefusedRenewalDetails', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.driverSufferAnyConditionsAffectSightOrHearing || entity.driverSufferAnyConditionsAffectSightOrHearing.length < 1 || entity.driverSufferAnyConditionsAffectSightOrHearing === pleaseSelect, formErrorsTemp, 'driverSufferAnyConditionsAffectSightOrHearing', newError('driverSufferAnyConditionsAffectSightOrHearing', 'Please select Conditions affect sight or hearing'));
    if (entity.driverSufferAnyConditionsAffectSightOrHearing && entity.driverSufferAnyConditionsAffectSightOrHearing === yes) {
        e = e + checkAndSet(!entity.driverSufferAnyConditionsAffectSightOrHearingDetails || entity.driverSufferAnyConditionsAffectSightOrHearingDetails.length < 1, formErrorsTemp, 'driverSufferAnyConditionsAffectSightOrHearingDetails', newError('driverSufferAnyConditionsAffectSightOrHearingDetails', 'Please enter details'));
        e = e + checkAndSet(!entity.driverSufferAnyConditionsAffectSightOrHearingDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'driverSufferAnyConditionsAffectSightOrHearingDetails', newError('driverSufferAnyConditionsAffectSightOrHearingDetails', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.descriptionOfDamage || entity.descriptionOfDamage.length < 1, formErrorsTemp, 'descriptionOfDamage', newError('descriptionOfDamage', 'Please enter Description of damage'));
    e = e + checkAndSet(!entity.descriptionOfDamage.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'descriptionOfDamage', newError('descriptionOfDamage', 'Please enter Description of damage without special characters'));

    e = e + checkAndSet(!entity.purposeVehicleBeingUsed || entity.purposeVehicleBeingUsed.length < 1, formErrorsTemp, 'purposeVehicleBeingUsed', newError('purposeVehicleBeingUsed', 'Please enter Purpose vehicle being used'));
    e = e + checkAndSet(!entity.purposeVehicleBeingUsed.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'purposeVehicleBeingUsed', newError('purposeVehicleBeingUsed', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.numberPersonsCarried || entity.numberPersonsCarried.length < 1, formErrorsTemp, 'numberPersonsCarried', newError('numberPersonsCarried', 'Please enter Number persons carried'));
    e = e + checkAndSet(!entity.numberPersonsCarried.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'numberPersonsCarried', newError('numberPersonsCarried', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.natureGoodsCarried || entity.natureGoodsCarried.length < 1, formErrorsTemp, 'natureGoodsCarried', newError('natureGoodsCarried', 'Please enter Nature good carried'));
    e = e + checkAndSet(!entity.natureGoodsCarried.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'natureGoodsCarried', newError('natureGoodsCarried', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.dateOfAccident, formErrorsTemp, 'dateOfAccident', newError('dateOfAccident', 'Please enter Date of accident'));
    e = e + checkAndSet(!entity.timeOfAccident || entity.timeOfAccident.length < 1, formErrorsTemp, 'timeOfAccident', newError('timeOfAccident', 'Please enter Time of accident'));

    e = e + checkAndSet(!entity.placeOfAccident || entity.placeOfAccident.length < 1, formErrorsTemp, 'placeOfAccident', newError('placeOfAccident', 'Please enter Place of accident'));
    e = e + checkAndSet(!entity.placeOfAccident.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'placeOfAccident', newError('placeOfAccident', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.roadConditions || entity.roadConditions.length < 1, formErrorsTemp, 'roadConditions', newError('roadConditions', 'Please enter Road conditions'));
    e = e + checkAndSet(!entity.roadConditions.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'roadConditions', newError('roadConditions', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.weatherConditions || entity.weatherConditions.length < 1, formErrorsTemp, 'weatherConditions', newError('weatherConditions', 'Please enter Weather conditions'));
    e = e + checkAndSet(!entity.weatherConditions.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'weatherConditions', newError('weatherConditions', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.estimatedSpeed || entity.estimatedSpeed.length < 1, formErrorsTemp, 'estimatedSpeed', newError('estimatedSpeed', 'Please enter Estimated speed'));
    e = e + checkAndSet(!entity.estimatedSpeed.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'estimatedSpeed', newError('estimatedSpeed', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.lightsUsed || entity.lightsUsed.length < 1, formErrorsTemp, 'lightsUsed', newError('lightsUsed', 'Please enter Lights used'));
    e = e + checkAndSet(!entity.lightsUsed.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'lightsUsed', newError('lightsUsed', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.positionInRoad || entity.positionInRoad.length < 1, formErrorsTemp, 'positionInRoad', newError('positionInRoad', 'Please enter Position in road'));
    e = e + checkAndSet(!entity.positionInRoad.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'positionInRoad', newError('positionInRoad', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.whoWasResponsibleForAccident || entity.whoWasResponsibleForAccident.length < 1, formErrorsTemp, 'whoWasResponsibleForAccident', newError('whoWasResponsibleForAccident', 'Please enter Who was responsible'));
    e = e + checkAndSet(!entity.whoWasResponsibleForAccident.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'whoWasResponsibleForAccident', newError('whoWasResponsibleForAccident', 'Please enter without special characters'));

    e = e + checkAndSet(!entity.thirdPartyInvolved || entity.thirdPartyInvolved.length < 1 || entity.thirdPartyInvolved === pleaseSelect, formErrorsTemp, 'thirdPartyInvolved', newError('thirdPartyInvolved', 'Please select Third party involved'));
    if (entity.thirdPartyInvolved && entity.thirdPartyInvolved === yes) {
        e = e + checkAndSet(!entity.thirdPartyName || entity.thirdPartyName.length < 1, formErrorsTemp, 'thirdPartyName', newError('thirdPartyName', 'Please enter Third party name'));
        e = e + checkAndSet(!entity.thirdPartyName.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyName', newError('thirdPartyName', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAddress || entity.thirdPartyAddress.length < 1, formErrorsTemp, 'thirdPartyAddress', newError('thirdPartyAddress', 'Please enter Third party address'));
        e = e + checkAndSet(!entity.thirdPartyAddress.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAddress', newError('thirdPartyAddress', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyTelephoneNumber || entity.thirdPartyTelephoneNumber.length < 1, formErrorsTemp, 'thirdPartyTelephoneNumber', newError('thirdPartyTelephoneNumber', 'Please enter Third party telephone number'));
        e = e + checkAndSet(!entity.thirdPartyTelephoneNumber.match(mobilePattern), formErrorsTemp, 'thirdPartyTelephoneNumber', newError('thirdPartyTelephoneNumber', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyVehicleMake || entity.thirdPartyVehicleMake.length < 1, formErrorsTemp, 'thirdPartyVehicleMake', newError('thirdPartyVehicleMake', 'Please enter Third party make'));
        e = e + checkAndSet(!entity.thirdPartyVehicleMake.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyVehicleMake', newError('thirdPartyVehicleMake', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyVehicleModel || entity.thirdPartyVehicleModel.length < 1, formErrorsTemp, 'thirdPartyVehicleModel', newError('thirdPartyVehicleModel', 'Please enter Third party model'));
        e = e + checkAndSet(!entity.thirdPartyVehicleModel.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyVehicleModel', newError('thirdPartyVehicleModel', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyVehicleRegistration || entity.thirdPartyVehicleRegistration.length < 1, formErrorsTemp, 'thirdPartyVehicleRegistration', newError('thirdPartyVehicleRegistration', 'Please enter Third party registration'));
        e = e + checkAndSet(!entity.thirdPartyVehicleRegistration.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyVehicleRegistration', newError('thirdPartyVehicleRegistration', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyInsurers || entity.thirdPartyInsurers.length < 1, formErrorsTemp, 'thirdPartyInsurers', newError('thirdPartyInsurers', 'Please enter Third party insurers'));
        e = e + checkAndSet(!entity.thirdPartyInsurers.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyInsurers', newError('thirdPartyInsurers', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyReferenceNumber || entity.thirdPartyReferenceNumber.length < 1, formErrorsTemp, 'thirdPartyReferenceNumber', newError('thirdPartyReferenceNumber', 'Please enter Third party reference number'));
        e = e + checkAndSet(!entity.thirdPartyReferenceNumber.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyReferenceNumber', newError('thirdPartyReferenceNumber', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartySpeed || entity.thirdPartySpeed.length < 1, formErrorsTemp, 'thirdPartySpeed', newError('thirdPartySpeed', 'Please enter Third party speed'));
        e = e + checkAndSet(!entity.thirdPartySpeed.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartySpeed', newError('thirdPartySpeed', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyVehicleDamage || entity.thirdPartyVehicleDamage.length < 1, formErrorsTemp, 'thirdPartyVehicleDamage', newError('thirdPartyVehicleDamage', 'Please enter Third party vehicle damage'));
        e = e + checkAndSet(!entity.thirdPartyVehicleDamage.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyVehicleDamage', newError('thirdPartyVehicleDamage', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyNumberOccupantsInVehicle || entity.thirdPartyNumberOccupantsInVehicle.length < 1, formErrorsTemp, 'thirdPartyNumberOccupantsInVehicle', newError('thirdPartyNumberOccupantsInVehicle', 'Please enter Third party number of occupants'));
        e = e + checkAndSet(!entity.thirdPartyNumberOccupantsInVehicle.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyNumberOccupantsInVehicle', newError('thirdPartyNumberOccupantsInVehicle', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.thirdPartyAdditionalInvolved || entity.thirdPartyAdditionalInvolved.length < 1 || entity.thirdPartyAdditionalInvolved === pleaseSelect, formErrorsTemp, 'thirdPartyAdditionalInvolved', newError('thirdPartyAdditionalInvolved', 'Please select Third party additional involved'));
    if (entity.thirdPartyAdditionalInvolved && entity.thirdPartyAdditionalInvolved === yes) {
        e = e + checkAndSet(!entity.thirdPartyAdditionalName || entity.thirdPartyAdditionalName.length < 1, formErrorsTemp, 'thirdPartyAdditionalName', newError('thirdPartyAdditionalName', 'Please enter Third party additional name'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalName.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalName', newError('thirdPartyAdditionalName', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalAddress || entity.thirdPartyAdditionalAddress.length < 1, formErrorsTemp, 'thirdPartyAdditionalAddress', newError('thirdPartyAdditionalAddress', 'Please enter Third party additional address'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalAddress.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalAddress', newError('thirdPartyAdditionalAddress', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalTelephoneNumber || entity.thirdPartyAdditionalTelephoneNumber.length < 1, formErrorsTemp, 'thirdPartyAdditionalTelephoneNumber', newError('thirdPartyAdditionalTelephoneNumber', 'Please enter Third party additional telephone number'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalTelephoneNumber.match(mobilePattern), formErrorsTemp, 'thirdPartyAdditionalTelephoneNumber', newError('thirdPartyAdditionalTelephoneNumber', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalVehicleMake || entity.thirdPartyAdditionalVehicleMake.length < 1, formErrorsTemp, 'thirdPartyAdditionalVehicleMake', newError('thirdPartyAdditionalVehicleMake', 'Please enter Third party additional make'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalVehicleMake.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalVehicleMake', newError('thirdPartyAdditionalVehicleMake', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalVehicleModel || entity.thirdPartyAdditionalVehicleModel.length < 1, formErrorsTemp, 'thirdPartyAdditionalVehicleModel', newError('thirdPartyAdditionalVehicleModel', 'Please enter Third party additional model'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalVehicleModel.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalVehicleModel', newError('thirdPartyAdditionalVehicleModel', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalVehicleRegistration || entity.thirdPartyAdditionalVehicleRegistration.length < 1, formErrorsTemp, 'thirdPartyAdditionalVehicleRegistration', newError('thirdPartyAdditionalVehicleRegistration', 'Please enter Third party additional registration'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalVehicleRegistration.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalVehicleRegistration', newError('thirdPartyAdditionalVehicleRegistration', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalInsurers || entity.thirdPartyAdditionalInsurers.length < 1, formErrorsTemp, 'thirdPartyAdditionalInsurers', newError('thirdPartyAdditionalInsurers', 'Please enter Third party additional insurers'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalInsurers.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalInsurers', newError('thirdPartyAdditionalInsurers', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalReferenceNumber || entity.thirdPartyAdditionalReferenceNumber.length < 1, formErrorsTemp, 'thirdPartyAdditionalReferenceNumber', newError('thirdPartyAdditionalReferenceNumber', 'Please enter Third party additional reference number'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalReferenceNumber.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalReferenceNumber', newError('thirdPartyAdditionalReferenceNumber', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalSpeed || entity.thirdPartyAdditionalSpeed.length < 1, formErrorsTemp, 'thirdPartyAdditionalSpeed', newError('thirdPartyAdditionalSpeed', 'Please enter Third party additional speed'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalSpeed.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalSpeed', newError('thirdPartyAdditionalSpeed', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalVehicleDamage || entity.thirdPartyAdditionalVehicleDamage.length < 1, formErrorsTemp, 'thirdPartyAdditionalVehicleDamage', newError('thirdPartyAdditionalVehicleDamage', 'Please enter Third party additional vehicle damage'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalVehicleDamage.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalVehicleDamage', newError('thirdPartyAdditionalVehicleDamage', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.thirdPartyAdditionalNumberOccupantsInVehicle || entity.thirdPartyAdditionalNumberOccupantsInVehicle.length < 1, formErrorsTemp, 'thirdPartyAdditionalNumberOccupantsInVehicle', newError('thirdPartyAdditionalNumberOccupantsInVehicle', 'Please enter Third party additional number of occupants'));
        e = e + checkAndSet(!entity.thirdPartyAdditionalNumberOccupantsInVehicle.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'thirdPartyAdditionalNumberOccupantsInVehicle', newError('thirdPartyAdditionalNumberOccupantsInVehicle', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.furtherInjuredPartiesInvolved || entity.furtherInjuredPartiesInvolved.length < 1 || entity.furtherInjuredPartiesInvolved === pleaseSelect, formErrorsTemp, 'furtherInjuredPartiesInvolved', newError('furtherInjuredPartiesInvolved', 'Please select Further injured parties involved'));
    if (entity.furtherInjuredPartiesInvolved && entity.furtherInjuredPartiesInvolved === yes) {
        e = e + checkAndSet(!entity.furtherInjuredName || entity.furtherInjuredName.length < 1, formErrorsTemp, 'furtherInjuredName', newError('furtherInjuredName', 'Please enter Further injured name'));
        e = e + checkAndSet(!entity.furtherInjuredName.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'furtherInjuredName', newError('furtherInjuredName', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.furtherInjuredAddress || entity.furtherInjuredAddress.length < 1, formErrorsTemp, 'furtherInjuredAddress', newError('furtherInjuredAddress', 'Please enter Further injured address'));
        e = e + checkAndSet(!entity.furtherInjuredAddress.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'furtherInjuredAddress', newError('furtherInjuredAddress', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.furtherInjuredInjuryDetails || entity.furtherInjuredInjuryDetails.length < 1, formErrorsTemp, 'furtherInjuredInjuryDetails', newError('furtherInjuredInjuryDetails', 'Please enter Further injured injury details'));
        e = e + checkAndSet(!entity.furtherInjuredInjuryDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'furtherInjuredInjuryDetails', newError('furtherInjuredInjuryDetails', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.furtherInjuredAmbulance || entity.furtherInjuredAmbulance.length < 1 || entity.furtherInjuredAmbulance === pleaseSelect, formErrorsTemp, 'furtherInjuredAmbulance', newError('furtherInjuredAmbulance', 'Please select Further injured ambulance'));
        if (entity.furtherInjuredAmbulance && entity.furtherInjuredAmbulance === yes) {
            e = e + checkAndSet(!entity.furtherInjuredAmbulanceDetails || entity.furtherInjuredAmbulanceDetails.length < 1, formErrorsTemp, 'furtherInjuredAmbulanceDetails', newError('furtherInjuredAmbulanceDetails', 'Please enter Further injured ambulance details'));
            e = e + checkAndSet(!entity.furtherInjuredAmbulanceDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'furtherInjuredAmbulanceDetails', newError('furtherInjuredAmbulanceDetails', 'Please enter details without special characters'));
        }
    }

    e = e + checkAndSet(!entity.wasTherePassenger1 || entity.wasTherePassenger1.length < 1 || entity.wasTherePassenger1 === pleaseSelect, formErrorsTemp, 'wasTherePassenger1', newError('wasTherePassenger1', 'Please select Was there passenger 1'));
    if (entity.wasTherePassenger1 && entity.wasTherePassenger1 === yes) {
        e = e + checkAndSet(!entity.passenger1Name || entity.passenger1Name.length < 1, formErrorsTemp, 'passenger1Name', newError('passenger1Name', 'Please enter Passenger 1 name'));
        e = e + checkAndSet(!entity.passenger1Name.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'passenger1Name', newError('passenger1Name', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.passenger1Address || entity.passenger1Address.length < 1, formErrorsTemp, 'passenger1Address', newError('passenger1Address', 'Please enter Passenger 1 address'));
        e = e + checkAndSet(!entity.passenger1Address.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'passenger1Address', newError('passenger1Address', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.wasTherePassenger2 || entity.wasTherePassenger2.length < 1 || entity.wasTherePassenger2 === pleaseSelect, formErrorsTemp, 'wasTherePassenger2', newError('wasTherePassenger2', 'Please select Was there passenger 2'));
    if (entity.wasTherePassenger2 && entity.wasTherePassenger2 === yes) {
        e = e + checkAndSet(!entity.passenger2Name || entity.passenger2Name.length < 1, formErrorsTemp, 'passenger2Name', newError('passenger2Name', 'Please enter Passenger 2 name'));
        e = e + checkAndSet(!entity.passenger2Name.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'passenger2Name', newError('passenger2Name', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.passenger2Address || entity.passenger2Address.length < 1, formErrorsTemp, 'passenger2Address', newError('passenger2Address', 'Please enter Passenger 2 address'));
        e = e + checkAndSet(!entity.passenger2Address.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'passenger2Address', newError('passenger2Address', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.wasTherePassenger3 || entity.wasTherePassenger3.length < 1 || entity.wasTherePassenger3 === pleaseSelect, formErrorsTemp, 'wasTherePassenger3', newError('wasTherePassenger3', 'Please select Was there passenger 3'));
    if (entity.wasTherePassenger3 && entity.wasTherePassenger3 === yes) {
        e = e + checkAndSet(!entity.passenger3Name || entity.passenger3Name.length < 1, formErrorsTemp, 'passenger3Name', newError('passenger3Name', 'Please enter Passenger 3 name'));
        e = e + checkAndSet(!entity.passenger3Name.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'passenger3Name', newError('passenger3Name', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.passenger3Address || entity.passenger3Address.length < 1, formErrorsTemp, 'passenger3Address', newError('passenger3Address', 'Please enter Passenger 3 address'));
        e = e + checkAndSet(!entity.passenger3Address.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'passenger3Address', newError('passenger3Address', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.wasThereWitness1 || entity.wasThereWitness1.length < 1 || entity.wasThereWitness1 === pleaseSelect, formErrorsTemp, 'wasThereWitness1', newError('wasThereWitness1', 'Please select Was there witness 1'));
    if (entity.wasThereWitness1 && entity.wasThereWitness1 === yes) {
        e = e + checkAndSet(!entity.witness1Name || entity.witness1Name.length < 1, formErrorsTemp, 'witness1Name', newError('witness1Name', 'Please enter Witness 1 name'));
        e = e + checkAndSet(!entity.witness1Name.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'witness1Name', newError('witness1Name', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.witness1Address || entity.witness1Address.length < 1, formErrorsTemp, 'witness1Address', newError('witness1Address', 'Please enter Witness 1 address'));
        e = e + checkAndSet(!entity.witness1Address.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'witness1Address', newError('witness1Address', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.wasThereWitness2 || entity.wasThereWitness2.length < 1 || entity.wasThereWitness2 === pleaseSelect, formErrorsTemp, 'wasThereWitness2', newError('wasThereWitness2', 'Please select Was there witness 2'));
    if (entity.wasThereWitness2 && entity.wasThereWitness2 === yes) {
        e = e + checkAndSet(!entity.witness2Name || entity.witness2Name.length < 1, formErrorsTemp, 'witness2Name', newError('witness2Name', 'Please enter Witness 2 name'));
        e = e + checkAndSet(!entity.witness2Name.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'witness2Name', newError('witness2Name', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.witness2Address || entity.witness2Address.length < 1, formErrorsTemp, 'witness2Address', newError('witness2Address', 'Please enter Witness 2 address'));
        e = e + checkAndSet(!entity.witness2Address.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'witness2Address', newError('witness2Address', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.wasThereWitness3 || entity.wasThereWitness3.length < 1 || entity.wasThereWitness3 === pleaseSelect, formErrorsTemp, 'wasThereWitness3', newError('wasThereWitness3', 'Please select Was there witness 3'));
    if (entity.wasThereWitness3 && entity.wasThereWitness3 === yes) {
        e = e + checkAndSet(!entity.witness3Name || entity.witness3Name.length < 1, formErrorsTemp, 'witness3Name', newError('witness3Name', 'Please enter Witness 3 name'));
        e = e + checkAndSet(!entity.witness3Name.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'witness3Name', newError('witness3Name', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.witness3Address || entity.witness3Address.length < 1, formErrorsTemp, 'witness3Address', newError('witness3Address', 'Please enter Witness 3 address'));
        e = e + checkAndSet(!entity.witness3Address.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'witness3Address', newError('witness3Address', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.wasTherePoliceOnSite || entity.wasTherePoliceOnSite.length < 1 || entity.wasTherePoliceOnSite === pleaseSelect, formErrorsTemp, 'wasTherePoliceOnSite', newError('wasTherePoliceOnSite', 'Please select Was there police on site'));
    if (entity.wasTherePoliceOnSite && entity.wasTherePoliceOnSite === yes) {
        e = e + checkAndSet(!entity.policeOfficerName || entity.policeOfficerName.length < 1, formErrorsTemp, 'policeOfficerName', newError('policeOfficerName', 'Please enter Police officer name'));
        e = e + checkAndSet(!entity.policeOfficerName.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'policeOfficerName', newError('policeOfficerName', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.policeOfficerNumber || entity.policeOfficerNumber.length < 1, formErrorsTemp, 'policeOfficerNumber', newError('policeOfficerNumber', 'Please enter Police officer number'));
        e = e + checkAndSet(!entity.policeOfficerNumber.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'policeOfficerNumber', newError('policeOfficerNumber', 'Please enter details without special characters'));

        e = e + checkAndSet(!entity.policeOfficerStationAddress || entity.policeOfficerStationAddress.length < 1, formErrorsTemp, 'policeOfficerStationAddress', newError('policeOfficerStationAddress', 'Please enter Police officer station address'));
        e = e + checkAndSet(!entity.policeOfficerStationAddress.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'policeOfficerStationAddress', newError('policeOfficerStationAddress', 'Please enter details without special characters'));
    }

    e = e + checkAndSet(!entity.claimStatus || entity.claimStatus.length < 1 || entity.claimStatus === pleaseSelect, formErrorsTemp, 'claimStatus', newError('claimStatus', 'Please select Claim status'));

    return { errors: e > 0, errorObject: formErrorsTemp };
}