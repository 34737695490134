import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions';
import { pleaseSelect, createDateInput, createTextInput, createSelectInput, checkAndSet, newError, numericPattern } from '../generic';
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from 'moment';
import ClaimCRUD from '../claim/claimCRUD';

export default function OwnershipCRUD(props) {
    const dispatch = useDispatch();
    const emptyEntity = {
        workEmail: '',
        registration: '',
        associationFrom: '',
        associationFromTime: '',
        associationTo: '',
        associationToTime: '',
        associationType: '' };
    const entityUrl = `/fleet/Ownership`;
    const entityUrlWithId = `/fleet/Ownership/${props.data.entity.id}`;
    const addFromEntityWithKey = {...emptyEntity };
    const headerText = ' Ownership - ';
    const associateTypes = [pleaseSelect,'Pool','Own','Lease','On Order'];
    const [workEmails, setWorkEmails] = useState();
    const [registrations, setRegistrations] = useState();

    const loadDriverEmails = async () => {
        const driverEmailsData = await Axios.get(`/fleet/driverEmails`);
        const driverEmailsDataFormatted = driverEmailsData.data.result.map(function(item){ return item.workEmail });
        setWorkEmails([pleaseSelect, ...driverEmailsDataFormatted]);
    }

    const loadVehicleRegs = async () => {
        const vehicleRegsData = await Axios.get(`/fleet/vehicleRegs`);
        const vehicleRegsDataFormatted = vehicleRegsData.data.result.map(function(item){ return item.registration });
        setRegistrations([pleaseSelect, ...vehicleRegsDataFormatted]);
    }

    const loadSpecificData = async () => {
        loadDriverEmails();
        loadVehicleRegs();
    }

    const evaluatedRulesPassed = (formErrorsTemp) => {
        let e = 0;
        e = checkAndSet(!entity.workEmail || entity.workEmail.length < 1 || entity.workEmail === pleaseSelect, formErrorsTemp, 'workEmail', newError('workEmail', 'Please select Work Email of ownership'));
        e = e + checkAndSet(!entity.registration || entity.registration.length < 1 || entity.workEmail === pleaseSelect, formErrorsTemp, 'registration', newError('registration', 'Please select Registration of ownership'));
        e = e + checkAndSet(!entity.associationFrom, formErrorsTemp, 'associationFrom', newError('associationFrom', 'Please enter Association From of ownership'));

        e = e + checkAndSet(!entity.associationFromTime || entity.associationFromTime.length < 1, formErrorsTemp, 'associationFromTime', newError('associationFromTime', 'Please enter From Time of ownership'));
        e = e + checkAndSet(!entity.associationFromTime.match(numericPattern), formErrorsTemp, 'associationFromTime', newError('associationFromTime', 'Please enter From Time without special characters'));

        e = e + checkAndSet(!entity.associationToTime.match(numericPattern), formErrorsTemp, 'associationToTime', newError('associationToTime', 'Please enter To Time without special characters'));
        e = e + checkAndSet(!entity.associationType || entity.associationType.length < 1 || entity.associationType === pleaseSelect, formErrorsTemp, 'associationType', newError('associationType', 'Please select Association Type of ownership'));

        return e > 0;
	}

    const htmlButtons = (loading) => {
        if (loading) {
            return <div />
        } else {
            return <div>
                <UILIB.Button loading={saving} className='mar-t10' value='Action' onClick={submitForm} />
                {(props.data.type === editType) && <UILIB.Button className='mar-t10 mar-l10' value='Add Claim' onClick={addClaim} />}
            </div>
        }
    }
    
    const htmlRows = (loading) => { 
        if (loading) {
            return <div />
        } else {
            return <div className='mar-t15'>
                {createTextInput('First Name', 'firstName', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Last Name', 'lastName', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('People HR Number', 'peopleHrNumber', false, 20, entity, entityErrors, changeInput)}
                {createSelectInput('Driver Email', 'workEmail', workEmails, isActive, entity, entityErrors, changeInput)}
                {createTextInput('Region', 'region', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Office', 'office', false, 40, entity, entityErrors, changeInput)}
                {createSelectInput('Vehicle Reg', 'registration', registrations, isActive, entity, entityErrors, changeInput)}
                {createTextInput('Make', 'make', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Model', 'model', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('P11D', 'p11d', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Engine Size', 'engineSize', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Fuel Type', 'fuelType', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('CO2', 'co2', false, 40, entity, entityErrors, changeInput)}
                {createDateInput('Association From', 'associationFrom', isActive, entity, entityErrors, changeInput)}
                {createTextInput('From Hour', 'associationFromTime', isActive, 2, entity, entityErrors, changeInput)}
                {createDateInput('Association To', 'associationTo', isActive, entity, entityErrors, changeInput)}
                {createTextInput('To Hour', 'associationToTime', isActive, 2, entity, entityErrors, changeInput)}
                {createSelectInput('Association Type', 'associationType', associateTypes, isActive, entity, entityErrors, changeInput)}
                {createTextInput('Contract Type', 'contractType', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Created At', 'createdAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Created By', 'createdBy', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Updated At', 'updatedAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated By', 'updatedBy', false, 40, entity, entityErrors, changeInput)}
            </div>
        }
    }

    const addClaim = (data) => {
        dispatch(setDrawer({ open: true, content: <ClaimCRUD data={{ type: 'addFrom', entity: { emailReg: entity['workEmail'] + '<->' + entity['registration'] }}} sync={syncEditSave} />, width: '600px' }));
    };

    const syncEditSave = (data) => {
    }

    //Generic
    const addType = 'add';
    const addFromType = 'addFrom';
    const editType = 'edit';
    const deleteType = 'delete';
    const defaultPageMessage = 'Please complete the below and then click Action.';
    const deleteConfirmationMessage = 'Please confirm deletion of the below details by clicking Action.';
    const failValidationMessage = 'Please correct any errors and click Action again.';
    const rowVersionMessage = 'A newer version of this record has already been saved. Please reload the data.';
    const missingRecordMessage = 'Unable to find record in db. Please reload the data.';
    const invalidSubmitMessage = 'Invalid submit request, please advise support.';
    const saveErrorMessage = 'Unable to save, please advise support.';
    const [loadingData, setLoadingData] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [isActiveOnEdit, setIsActiveOnEdit] = useState(true);
    const [entity, setEntity] = useState(emptyEntity);
    const [entityErrors, setEntityErrors] = useState(emptyEntity);
    const [errored, setErrored] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState(defaultPageMessage);

    useEffect(() => {
        const loadEntity = async () => {
            if (props.data.type === addType) {
                setEntity(emptyEntity);
            } else if (props.data.type === addFromType) {
                const entity = addFromEntityWithKey;
                setEntity(entity);
            } else {        
                const entityData = await Axios.get(entityUrlWithId);
                setEntity(entityData.data.result);
            }

            setLoadingData(false);
        }

        if (props.data.type === deleteType) {
            setIsActive(false);
            setIsActiveOnEdit(false);
            setMessage(deleteConfirmationMessage)
        }

        if (props.data.type === editType || props.data.type === addFromType) {
            setIsActiveOnEdit(false);
        }

        loadSpecificData();
        loadEntity();
    }, [])

    const changeInput = (event) => {
        let newFormData = JSON.parse(JSON.stringify(entity));
        newFormData[event.target.name] = event.target.value;
        setEntity(newFormData);

        if (event.target.name in entityErrors) {
			let newFormErrors = { ...entityErrors };
			newFormErrors[event.target.name] = '';
			setEntityErrors(newFormErrors);
		}
    }

    const validSubmit = () => {
        let formErrorsTemp = emptyEntity;        
        if (!evaluatedRulesPassed(formErrorsTemp)) {
            return true;
        }

        setEntityErrors(formErrorsTemp);
        setMessage(failValidationMessage);
        return false;
    }

    const processDelete = async () => {
        if (props.data.type === deleteType) {
            setSaving(true);
            await Axios.delete(entityUrlWithId, entity);
            setDeleted(true);
            props.sync({ deleted: true, id: props.data.entity.id });
            setSaving(false);

            return true;
        }

        return false;
    }

    const processAdd = async () => {
        if (props.data.type === addType || props.data.type === addFromType) {
            setSaving(true);
            const entityResult = await Axios.post(entityUrl, entity);
            props.data.type = editType;
            props.data.entity.id = entityResult.data.result.id;
            setEntity(entityResult.data.result);
            props.sync(entityResult.data.result);
            setIsActiveOnEdit(false);
            setSaving(false);

            return true;
        }
        
        return false;
    }

    const processEdit = async () => {
        if (props.data.type === editType) {
            setSaving(true);
            const entityResult = await Axios.put(entityUrlWithId, entity);

            if (entityResult.status === 200) {
                setEntity(entityResult.data.result);
                props.sync(entityResult.data.result);
            }

            if (entityResult.status === 409) {
                setMessage(rowVersionMessage);
                setErrored(true);
            }

            if (entityResult.status === 400) {
                setMessage(missingRecordMessage);
                setErrored(true);
            }
            
            setSaving(false);
            return true;		
        }

        return false;
    }

    const submitForm = async () => {
        try {
            if (await processDelete()) { return; }
            if (validSubmit()) {
                if (await processAdd()) { return; }
                if (await processEdit()) { return; }

                setMessage(invalidSubmitMessage);
                setErrored(true);
            }
        }
        catch (err) {
            console.log(err);
            setMessage(saveErrorMessage);
            setErrored(true);
        }
    }

    if (deleted) return <UILIB.Paper className='width-100'><p className="center">Deleted</p></UILIB.Paper>

    if (errored) return <UILIB.Paper className='width-100'><p className="center">{message}</p></UILIB.Paper>

    if (loadingData) return <UILIB.Loading type={3} />

    return (
        <div className="mar-l15 mar-r15">
            <h2 className='mar-b10'>{(props.data.type === addType || props.data.type === addFromType) ? 'Add' : 'Edit'}{headerText}{entity.id}</h2>
            <p>{message}</p>
            {htmlButtons(loadingData)}
            {htmlRows(loadingData)}
        </div> 
    )
}