import { useState, useRef, Fragment } from 'react';
import Axios from 'classes/axios'
import UILIB from 'components';
import { formatDateTime } from "classes/format";
import { createButton } from 'classes/controls/formControls';


export default function QuoteCopyDrawer({ ourDeal, isActive, onFinished }) {

  const headerData = [
    { label: '', value: 'selected', filtering: false, minWidth: 20, maxWidth: 20 },
    { label: `Quote #`, value: 'finalDealNo', type: 'number', filtering: false, minWidth: 80, maxWidth: 80 },
    { label: 'Opportunity #', value: 'opportunityNumber', type: 'number', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: 'Customer Name', value: 'companyName', type: 'string', filtering: true, minWidth: 300, maxWidth: 300 },
    { label: 'Quote Description', value: 'description', type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
    { label: 'Created Date', value: 'createdAtTxt', type: 'string', filtering: true, minWidth: 130, maxWidth: 130 }];

  const entityData = useRef({});
  const requestCheckbox = useRef({});
  const selected = useRef(null);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [saving, setSaving] = useState(false);
  const [localRender, setLocalRender] = useState(false);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


  const updateCheckbox = (dealID) => {
    Object.keys(requestCheckbox.current).filter(x => Number(x) !== Number(dealID)).map(x => requestCheckbox.current[x] = false);
    requestCheckbox.current = { ...requestCheckbox.current, [dealID]: !requestCheckbox.current[dealID] };
    selected.current = dealID;
    setLocalRender(!localRender);
  };

  const actionsubmitForm = async () => {
    setSaving(true);
    try {
      const baseData = { source: selected.current, target: ourDeal.dealID };
      const result = await Axios.put(`/entities/workflow/deals/actionCopyExistingProductData`, baseData);
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        onFinished();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      setBannerError({ error: true, message: exceptionError });
      setSaving(false);
    }
  };


  async function constructTable(dealData) {

    const tableData = dealData.map(row => {

      return {
        selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox disabled={false} checked={requestCheckbox.current[row.dealID]} type='checkbox' onChange={() => updateCheckbox(row.dealID)}/>} /> },
        finalDealNo: { value: row.finalDealNo, raw: row.finalDealNo },
        opportunityNumber: { value: row.opportunityNumber, raw: row.opportunityNumber },
        companyName: { value: row.companyName, raw: row.companyName },
        description: { value: row.description, raw: row.description },
        createdAtTxt: { value: formatDateTime(row.createdAtTxt), raw: row.createdAt } }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    const accountSplit = (String(ourDeal.accountNo).includes('-')) ? String(ourDeal.accountNo).split('-')[0] : ourDeal.accountNo;
    const baseFilter = `accountNo startsLike ${accountSplit} and ( status eq 0 and processStage eq 0 ) `;

    entityData.current = await Axios.get(`/entities/deals/getSummary/?&$filter=${baseFilter} ${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    let checked = {};
    if(entityData.current && entityData.current.result) {
      entityData.current.result.forEach(x => { checked = { [x.dealID]: false, ...checked } });
      requestCheckbox.current = { ...checked, ...requestCheckbox.current };
    }

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }
  

  return <Fragment>
    <div className='flex-container row mar-b10'>
      <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Copy Existing Quote</div>
      <p className="flex-item start width-80 text-11"><i>Clones non customer specific data including parts and pricing from a previous quote.</i></p>
    </div>
    <div className='flex-container row end wrap mar-l10 mar-r10'>
      {createButton('', 'cancel', 'Cancel', saving, false, async () => onFinished(), null, 'width-10 mar-2', null, null, 'red')}
      {createButton('', 'clone', 'Clone', (!isActive || !Object.keys(requestCheckbox.current).some(x => requestCheckbox.current[x]) || saving), saving, async () => await actionsubmitForm(), null, 'width-10 mar-2')}
      {bannerError.error && <div className="flex-item end wrap width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
    <div className="mar-l5 mar-r5">
      <UILIB.TableNew
        name={'MyExistingQuotes'}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[localRender]}
        remoteQuery={getTableData}
        remoteRender={[ourDeal]} />
    </div>
  </Fragment >
}