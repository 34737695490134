
import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios'


export default function ReleaseNotes() {

  const [releases, setReleases] = useState([]); 

  useEffect(() => {
    const asyncEffect = async () => {
      const resultReleaseNotes = await Axios.get(`/admin/releaseNotes/getReleaseNotes`);
      setReleases(resultReleaseNotes.data && resultReleaseNotes.data.result)
    }
    asyncEffect();
  }, [])


  return <Fragment>
          <div className="row mar-t20 mar-b20">
            <h2>SOP Release Notes</h2>
          </div>
          {releases.filter(r => r.published === true).map(item => { 
            return <UILIB.ReleaseNote releaseData={item}></UILIB.ReleaseNote> 
          })}
    </Fragment>

}
