import UILIB from 'components'
import { useState } from 'react';
import { memo } from 'react';

const Delete = memo(({ query, checked }) => {

  const [loading, setLoading] = useState(false);

  const haveSelected = Object.values(checked).some(x => x);

  const handleDelete = async () => {
    if(haveSelected) {
      setLoading(true);
      const selected = Object.keys(checked).filter(x => checked[x]);
      await query(selected);
      setLoading(false);
    }
  };

  const content = (loading) 
                    ? <UILIB.Loading type={3} /> 
                    : <span className="icon-trash2"></span>

  return <div title="Delete" className={`export clickable colour ${!haveSelected ? '' : 'background mediumGrey'}`} onClick={async () => await handleDelete()}>{content}</div>
})
export default Delete