
export default  function Select({ 
  name = '', 
  data = [], 
  placeholder = '', 
  onChange = function () { },
  onClick = function () { },
  value = '', 
  disabled = false, 
  style = { }, 
  outerstyle = {}, 
  className = '',
  outerClassName = '',
  errored = { option: 1, error: false, message: '' } ,
  behaviourType = 0,
  toolTip = null
}) {

    let outerClassNameF = "";
    let setDisabled = null;
    if (outerClassName) outerClassNameF += " " + outerClassName;

    const errorObject = (typeof(errored) === 'object') ? errored : false;

    const handleOnChange = async (event, errorObject, callback) => {
      if(errorObject && event.target.value) errorObject.data = event.target.value;
      await callback(event);
    };


    return <div className={outerClassNameF} style={outerstyle} title={toolTip}>
        <select 
            name={name} 
            onChange={async (ev) => await handleOnChange(ev, errorObject, onChange)} 
            onClick={onClick} 
            style={style} 
            placeholder={placeholder} 
            value={(!isNaN(value)) ? Number(value) : String(value)} 
            disabled={disabled} 
            className={`textInput fakeSelect flex-item width-100 height-100 ${(className) ? className : ''} ${(errorObject && errorObject.error && errorObject.option === 0) ? " fullBorder errored border" : ""}`} 
          >
          {placeholder && <option value="-1" disabled="disabled">{placeholder}</option>}
          {(Array.isArray(data)) && data.length && data.map((opt, index) => {
              const isObject = typeof opt === 'object'
              switch(true) {
                case behaviourType === 0:
                  setDisabled = opt.disabled;
                  break;
                case behaviourType === 1:
                  setDisabled = opt.consumed;
                  break;
                default: 
                  break;
              }
              return <option key={"sel_" + index} value={isObject ? opt.value : opt} disabled={setDisabled}>{isObject ? opt.label : opt}</option>
          })}
        </select>
        {(errorObject && errorObject.error && errorObject.option === 1) && <div className="errored message">{(errorObject) && errorObject.messageText}</div>}
    </div>
}
