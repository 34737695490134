import { Fragment } from 'react';
import UILIB from 'components';
import { getCompanies, getSerials, getCompaniesByPostCode, getSerialsByAssetNo } from "classes/helpers";
import { getEmptyFormObject } from '../../consumablesHub/functions';

export default function SiteSearch({ entityLookup, setFormData }) {

  const handleApplyCustomerSearch = (data) => {
    setFormData(getEmptyFormObject());
    entityLookup.set({ customerId: data.id, equipmentId: null, customerName: data.customerName, context: 'customer' });
  }

  const handleApplyEquipmentSearch = (data) => {
    setFormData(getEmptyFormObject());
    entityLookup.set({ customerId: data.CustomerId, equipmentId: data.id, customerName: data.customerName, context: 'equipment' });
  }

  const handleApplyAssetNumberSearch = (data) => {
    setFormData(getEmptyFormObject());
    entityLookup.set({ customerId: data.CustomerId, assetNumber: data.AssetNumber, equipmentId: data.id, customerName: data.companyName, context: 'assetNumber' });
  }

  const handleApplyPostCodeSearch = (data) => {
    setFormData(getEmptyFormObject());
    entityLookup.set({ customerId: data.id, postCode: data.PostCode, equipmentId: null, customerName: data.AltName, context: 'postCode' });
  }

  return <Fragment>
    <UILIB.Paper className="flex-container column wrap width-100">
      <div className="flex-container consumablesHub requestConsumables-search wrap width-100">
        <UILIB.Paper className='flex-item flex-1 grey wrap mar-5'>
          <div className='flex-item align-center start flex-grow-1'>Search for a Customer</div>
          <UILIB.AutoComplete
            className="min-height-50 max-width-400"
            name='customerSearch'
            blank='No Customers Found'
            query={getCompanies}
            enableButton={true}
            onClick={(data) => handleApplyCustomerSearch(data)} />
        </UILIB.Paper>
        <UILIB.Paper className='flex-item flex-1 grey wrap mar-5'>
          <div className='flex-item align-center start flex-grow-1'>Search for a Serial</div>
          <UILIB.AutoComplete
            className="min-height-50 max-width-400"
            name='equipmentId'
            width={'min-width-300'}
            blank="No Devices Found"
            query={getSerials}
            enableButton={true}
            onClick={(data) => handleApplyEquipmentSearch(data)} />
        </UILIB.Paper>
      </div>
      <div className="flex-container consumablesHub requestConsumables-search wrap width-100">
        <UILIB.Paper className='flex-item flex-1 grey wrap mar-5'>
          <div className='flex-item align-center start flex-grow-1'>Search for Asset No.</div>
          <UILIB.AutoComplete
            className="min-height-50 max-width-400"
            name='equipmentId'
            width={'min-width-300'}
            blank="No Assets Found"
            query={getSerialsByAssetNo}
            enableButton={true}
            onClick={(data) => handleApplyAssetNumberSearch(data)} />
        </UILIB.Paper>
        <UILIB.Paper className='flex-item flex-1 grey wrap mar-5'>
          <div className='flex-item align-center start flex-grow-1'>Search for PostCode</div>
          <UILIB.AutoComplete
            className="min-height-50 max-width-400"
            name='equipmentId'
            width={'min-width-300'}
            blank="No PostCodes Found"
            query={getCompaniesByPostCode}
            enableButton={true}
            onClick={(data) => handleApplyPostCodeSearch(data)} />
        </UILIB.Paper>
      </div>
    </UILIB.Paper>
  </Fragment>
}