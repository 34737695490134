import { Fragment } from 'react';
import UILIB from 'components';
import ReactJson from 'react-json-view'

export default function APIDrawer({ entity }) 
{  

  return <Fragment>
    <div className={`flex-container row padding-8`}>
        <h2>Request Body for {entity.id}</h2>
        <UILIB.NoteContainer type={'VO Request Body'} createdBy={entity.userCreated} createdAt={entity.tonerDate} message={<ReactJson src={JSON.parse(entity.apiData)}/>} />
    </div>
  </Fragment>
}
