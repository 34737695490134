import { useEffect, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setFleetHubReportSelected } from 'store/actions'
import { store } from 'store/store'
import UILIB from 'components';
import Axios from "classes/axios";


export default function TopStats() {

  const baseUrl = '/fleetHub/reports/';
  const history = useHistory();
  const state = store.getState();
  const reduxSelected = useSelector((state) => state.fleetReportSelected);
  const dispatch = useDispatch();

  useEffect(() => {
    const asyncEffect = async () => {

      const statResult = {
        motOverdueResults: await Axios.get(`/fleet/mots/overdue/stats`).then(stat => stat.data.result),
        motDueResults: await Axios.get(`/fleet/mots/due/stats`).then(stat => stat.data.result),
        serviceOverdueResults: await Axios.get(`/fleet/services/overdue/stats`).then(stat => stat.data.result),
        serviceDueResults: await Axios.get(`/fleet/services/due/stats`).then(stat => stat.data.result),
        renewalsUpcomingResults: await Axios.get(`/fleet/vehicles/renewalUpcoming/stats`).then(stat => stat.data.result),
        poolCarsAvlResults: await Axios.get(`/fleet/ownerships/poolCars/stats`).then(stat => stat.data.result),
        totalCarsAvlResults: await Axios.get(`/fleet/ownerships/totalCars/stats`).then(stat => stat.data.result)
      }

      if(!reduxSelected.loaded) dispatch(setFleetHubReportSelected({ ...reduxSelected, loaded: true }));
      if(JSON.stringify(statResult) !== JSON.stringify(reduxSelected.stats)) {
        dispatch(setFleetHubReportSelected({ ...reduxSelected, stats: statResult }));
      }
    }
    asyncEffect();
  }, [state.drawer.open, reduxSelected])

  const handleClicked = (event, target, type) => {
    dispatch(setFleetHubReportSelected({ ...reduxSelected , selected: event.target.id }));
    if(type === 0) history.push({ pathname: target, stats: true });
    if(type === 1) history.push(baseUrl + target);
  }

  const decider = (controls) => {
    const dataObj = {
      '/fleet/mots/all': ['/fleet/mots/overdue', '/fleet/mots/due'],
      '/fleet/services/all': ['/fleet/services/overdue', '/fleet/services/due'],
      '/fleet/ownerships/all': ['/fleet/ownerships/poolCars', '/fleet/ownerships/totalCars'],
      '/fleet/vehicles/all': ['/fleet/vehicles/renewalUpcoming', '/fleet/vehicles/renewalOrder'],
      controls: Object.assign({}, ...controls)
    }
    if(reduxSelected.selected && reduxSelected.selected.indexOf('/all') > -1) {
      return dataObj[reduxSelected.selected].includes(dataObj.controls && dataObj.controls.id);
    }
    return reduxSelected.selected;
  }

  const statCard = (loading, canClick, title, data, controls) => {
    return (
      <UILIB.StatContainerFleet 
        controls={controls.length > 0 ? Object.assign({}, ...controls) : {}} 
        loading={loading} 
        canClick={canClick} 
        title={title} 
        data={data} 
        selected={decider(controls)}
      />)
  }

  const createControl = (key, value) => {
    return { [key]: value };
  }

  return <Fragment>  
    <div className="row center mar-l10 mar-r10">
      <div className="cardFleet">
        {statCard(false, false, "MOT", null, [createControl('className', 'cardFleet cardChild red')])}
        {statCard(!reduxSelected.loaded, true, "Due", reduxSelected.stats.motDueResults, [createControl('className', 'cardFleet cardChild'), createControl('id', '/fleet/mots/due'), createControl('onClick', (ev) => handleClicked(ev, '/fleetHub/mots/due', 0))])}
        {statCard(!reduxSelected.loaded, true, "Overdue", reduxSelected.stats.motOverdueResults, [createControl('className', 'cardFleet cardChild'), createControl('id', '/fleet/mots/overdue'), createControl('onClick', (ev) => handleClicked(ev, '/fleetHub/mots/overdue', 0))])}
      </div>
      <div className="cardFleet">
        {statCard(false, false, "Service", null, [createControl('className', 'cardFleet cardChild orange')])}
        {statCard(!reduxSelected.loaded, false, "Due", reduxSelected.stats.serviceDueResults, [createControl('className', 'cardFleet cardChild'), createControl('id', '/fleet/services/due'), createControl('onClick', (ev) => handleClicked(ev, '/fleetHub/services/due', 0))])}
        {statCard(!reduxSelected.loaded, false, "Overdue", reduxSelected.stats.serviceOverdueResults, [createControl('className', 'cardFleet cardChild'), createControl('id', '/fleet/services/overdue'), createControl('onClick', (ev) => handleClicked(ev, '/fleetHub/services/overdue', 0))])}
      </div>
      <div className="cardFleet">
        {statCard(false, false, "Renewals", null, [createControl('className', 'cardFleet cardChild green')])}
        {statCard(!reduxSelected.loaded, true, "Upcoming", reduxSelected.stats.renewalsUpcomingResults, [createControl('className', 'cardFleet cardChild'), createControl('id', '/fleet/vehicles/renewalUpcoming'), createControl('onClick', (ev) => handleClicked(ev, '/fleetHub/vehicles/renewalUpcoming', 0))])}
        {statCard(!reduxSelected.loaded, true, "On order", 0, [createControl('className', 'cardFleet cardChild'), createControl('id', '/fleet/vehicles/renewalOrder'), createControl('onClick', (ev) => handleClicked(ev, '/fleetHub/vehicles/renewalOrder', 0))])}
      </div>
      <div className="cardFleet">
        {statCard(false, false, "Fleet", null, [createControl('className', 'cardFleet cardChild blue')])}
        {statCard(!reduxSelected.loaded, true, "Allocated", reduxSelected.stats.poolCarsAvlResults, [createControl('className', 'cardFleet cardChild'), createControl('id', '/fleet/ownerships/poolCars'), createControl('onClick', (ev) => handleClicked(ev, '/fleetHub/ownerships/poolCars', 0))])}
        {statCard(!reduxSelected.loaded, true, "Unallocated", reduxSelected.stats.totalCarsAvlResults, [createControl('className', 'cardFleet cardChild'), createControl('id', '/fleet/ownerships/totalCars'), createControl('onClick', (ev) => handleClicked(ev, '/fleetHub/ownerships/totalCars', 0))])}
      </div>
    </div>
  </Fragment >
}
