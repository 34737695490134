import { Fragment } from 'react';
import { useLocation } from "react-router-dom";
import UILIB from 'components';

export default function NotesView({ entity, setAddNote, allowAdd = '' }) 
{  

  const location = useLocation();
  const noteTypes = Array.from(new Set(entity.dealNote.map(x => x.noteSource)))

  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className={`flex-item flex-grow-1 end`}> 
        <UILIB.Button disabled={!Boolean(String(location.pathname).toUpperCase() === String(allowAdd).toUpperCase())} value={"Add Notes"} className="green" onClick={() => setAddNote(true)} />
      </div>
    </div>
    <div className={`flex-container row padding-4`}>
      {Boolean(!entity.dealNote || !entity.dealNote.length) && <div className="text-14 font-weight-bold">No notes available for this deal.</div>}
      {Boolean(entity.dealNote && entity.dealNote.length) && <div className="width-100">
        <div className="text-14 font-weight-bold">Notes for Deal: {entity.finalDealNo}</div>
        {noteTypes.map(x => {
          const filtered = entity.dealNote.filter(f => f.noteSource === x);
          return <UILIB.NoteContainerGrouped type={x} data={filtered} /> })}
      </div>}
    </div>
  </Fragment>
}
