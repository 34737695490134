import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { getAddressDropdown } from '../functions';
import { addressToString, convertObject, getAddresses } from 'classes/helpers';
import { checkFormErrors } from 'classes/formErrors/formErrors';
import { getAddAddressFormErrors } from 'classes/formErrors/repository';
import { createTextInput, createSelectInput, createAutoComplete } from 'classes/controls/formControls';

export default function AddAddressDrawer({ onFinished, ourDeal, setOurDeal, address = {}, isActive, editAddress = false }) {

    const emptyFormObject = {
      customerName: '',
      address1: '',
      address2: '',
      address3: '',
      town: '',
      county: '',
      country: '',
      postcode: '',
      accountNumber: '',
      contactName: '',
      telNo: '',
      email: '',
      fromCRMId: '',
      addressType: 0,
      fromCrm: '' };

    const [formData, setFormData] = useState({ ...emptyFormObject, ...address, country: 'United Kingdom', fromCrm: null, addressType: 0 });
    const [formErrors, setFormErrors] = useState({});
    const [pageLoaded, setPageLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [countries, setCountries] = useState([]);
    const defaultBanner = { error: false, message: '' };
    const [bannerError, setBannerError] = useState(defaultBanner);
    const [existingAddresses, setExistingAddresses] = useState(null);
    const [existingAddressId, setExistingAddressId] = useState(null);
    const [addingNewAddress, setAddingNewAddress] = useState(editAddress);
    const exceptions = [400,404];
  
    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';
    const invalidCRMConfig = 'Some of the options are invalid, please amend in CRM then try again';

    const getCountries = async () => {
        let countryDat = await Axios.get(`/helpers/countries`);
        countryDat = countryDat.data.map(c => { return { label: c.name, value: c.name } });
        setCountries(countryDat);
    };

    let drawTitle = (editAddress) ? 'Edit address' : 'Add an Address';
    let postcodeText = 'Select an Address';
    let existingText = 'From an existing address';
    let buttonText = 'Or add a new address';

    
    const getExistingAddresses = async (accounts) => {
      const addresses = [];
      const defaultObj = { postcode: postcodeText, fromCrm: 0, addressType: 0 };
        (accounts) && accounts
          .sort(function(a, b){
            if(String(a.accountnumber) < String(b.accountnumber)) { return -1; }
            if(String(a.accountnumber) > String(b.accountnumber)) { return 1; }
            return 0; })
          .forEach(x => addresses.push({ 
            accountNumber: (x.accountnumber) ? x.accountnumber : null, 
            customerName: (x.name) ? x.name : null, 
            address1: (x.address1_line1) ? x.address1_line1 : null, 
            address2: (x.address1_line2) ? x.address1_line2 : null, 
            address3: (x.address1_line3) ? x.address1_line3 : null,
            town: (x.address1_city) ? x.address1_city : null,
            county: (x.address1_stateorprovince) ? x.address1_stateorprovince : null, 
            country: (x.address1_country) ? x.address1_country : null, 
            postcode: (x.address1_postalcode) ? x.address1_postalcode : null, 
            fromCRMId: (x.address1_addressid) ? x.address1_addressid : null,
            addressType: 0,
            fromCrm: 1
        }));
        setExistingAddresses([ defaultObj, ...addresses ]);
    };

    const actionMapAddresses = (data) => {
      return data.map((x, i) => { return { label: addressToString(x), value: x.fromCRMId } });
    };

    const updateRowData = (event) => {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    const actionSelectAddressDynamic = async (data) => {
      const addressItems = ['name','accountNumber','address1_line1',',address1_line2','address1_line3','address1_city','address1_stateorprovince','address1_country','address1_postalcode'];
      const conversion = {
        name: "customerName",
        accountNumber: "accountnumber",
        address1_line1: "address1",
        address1_line2:  "address2",
        address1_line3: "address3",
        address1_stateorprovince: "county",
        address1_country: "country",
        address1_postalcode: "postcode",
      }
      setExistingAddressId(data);
      setFormData({ ...formData, ...convertObject(data.raw, addressItems, conversion), fromCrm: 0 });
      setAddingNewAddress(true);
    };

    const actionSelectAddress = (value) => {
      const found = existingAddresses.find(x => String(x.fromCRMId) === String(value.target.value));
      setExistingAddressId(value.target.value);
      setFormData({ ...formData, ...found, fromCrm: 1 });
      setAddingNewAddress(true);
    };

    const actionCancelAddAddress = () => {
      setAddingNewAddress(false);
      setBannerError(defaultBanner);
      setFormErrors({});
    };

    const actionCreateAddressCustom = () => {
      setFormData({ ...formData, fromCrm: 0 });
      setAddingNewAddress(true);
    };


    const submitForm = async () => {
      try {

        setSaving(true);

        const formResult = await checkFormErrors(getAddAddressFormErrors(formData), setFormErrors, null, null, formData, null);
        const formDataNew = structuredClone(formData);
  
        setFormData(formDataNew);
        setBannerError(defaultBanner);
  
        if(formData.fromCrm === 1 && formResult.errorCount > 0) {
          setBannerError({ error: true, message: invalidCRMConfig }) 
          return setSaving(false);
        }

        if (formResult.errorCount === 0) {
          const result = await Axios.put(`/entities/workflow/deals/actionCreateUpdateAddress/${ourDeal.dealID}`, formData);
          if(exceptions.includes(result.status)) {
            if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
            if(result.status === 404) setBannerError({ error: true, message: notFoundError });
          } else {
            if((!formData.id || formData.id === '' || formData.id == null)) {
              setOurDeal({ 
                ...ourDeal, 
                address: [...ourDeal.address, ...result.data.result.address],
                addressDropdown: getAddressDropdown({ ...ourDeal, address: [...ourDeal.address, ...result.data.result.address] }) });  
              onFinished();
            } else {
              const filtered = ourDeal.address.filter(x => Number(x.id) !== Number(formData.id));
              setOurDeal({ 
                ...ourDeal, 
                address: [...filtered, ...result.data.result.address], 
                addressDropdown: getAddressDropdown({ ...ourDeal, address: [...filtered, ...result.data.result.address] }) });    
              onFinished();
            }
          }
          setSaving(false);
        } 
      } catch (error) {
        console.log(error);
        setBannerError({ error: true, message: exceptionError });
        setSaving(false);
      }
    }


    useEffect(() => {
      const actionAsync = async () => {
        await getCountries();
        const accountCheck = (ourDeal && ourDeal.crm.organisation);
        await getExistingAddresses((accountCheck) ? ourDeal.crm.organisation.accounts : null);
        setPageLoaded(true);
      }
      actionAsync();
    }, []);


    if (!pageLoaded) return <UILIB.Loading type={3} />;
    
    return <Fragment>
        <h2>{drawTitle}</h2>
        {(!addingNewAddress && existingAddresses !== null) &&
          <div className='flex-container row start'>
            <div className="flex-item start align-center font-weight-bold text-14 mar-b20 width-100">{existingText}</div>
            {createSelectInput('Select Address', 'existingAddressId', (!isActive), existingAddressId, actionMapAddresses(existingAddresses), (ev) =>  actionSelectAddress(ev), null, 'width-100', null, null, 'width-100')}
            {createAutoComplete('Search for Address', 'addressId', false, 'Seach for Customer', (name, token) => getAddresses(name, token, ourDeal.crm.primaryAccount.accountid), (ev) => actionSelectAddressDynamic(ev), false, null, null, null, 'width-100 fullBorder border-colour-grey-4')}
            <div className='flex-item start width-100'>
              <UILIB.Button className='mar-t15' disabled={!isActive} value={buttonText} onClick={() => actionCreateAddressCustom()} />
            </div>
          </div>}
        {addingNewAddress &&
          <div className="flex-container row width-100">
            <div className="flex-container row width-100">
              {createTextInput('Company Name', 'customerName', (!isActive || formData?.fromCrm), formData?.customerName, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('Account Number', 'accountNumber', (!isActive || formData?.fromCrm), formData?.accountNumber, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('Address Line 1', 'address1', (!isActive || formData?.fromCrm), formData?.address1, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('Address Line 2', 'address2', (!isActive || formData?.fromCrm), formData?.address2, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('Address Line 3', 'address3', (!isActive || formData?.fromCrm), formData?.address3, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('Town', 'town', (!isActive || formData?.fromCrm), formData?.town, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('County', 'county', (!isActive || formData?.fromCrm), formData?.county, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createSelectInput('Country', 'country', (!isActive || formData?.fromCrm), formData?.country, countries, (ev) =>  updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('Postcode', 'postcode', (!isActive || formData?.fromCrm), formData?.postcode, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('Contact Name', 'contactName', (!isActive || formData?.fromCrm), formData?.contactName, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('Telephone Number', 'telNo', (!isActive || formData?.fromCrm), formData?.telNo, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
              {createTextInput('Email', 'email', (!isActive || formData?.fromCrm), formData?.email, (ev) => updateRowData(ev), formErrors, 'width-100', null, null, 'width-100')}
            </div>
            <div className="flex-item flex-grow-1 start mar-t10 width-100">
              <UILIB.Button disabled={(!isActive || saving)} loading={saving} className='button green' value='Save' onClick={async (ev) => await submitForm(ev)} />
              <UILIB.Button disabled={saving} className='grey' value='Cancel' onClick={(ev) => actionCancelAddAddress(ev)} />
            </div>
            {bannerError.error && <div className="flex-item flex-grow-1 start width-100">
              <div className="errored message">{bannerError.message}</div>
            </div>}
          </div>}
    </Fragment>;
}