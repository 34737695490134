import NotesAdd from '../shared/drawers/notesAdd';
import NotesDrawer from '../shared/drawers/notesDrawer';
import NotesView from '../shared/drawers/notesView';
import DealFiltersDrawer from './modules/dealFiltersDrawer';

const exported = {
  NotesAdd,
  NotesDrawer,
  NotesView,
  DealFiltersDrawer
}
export default exported;