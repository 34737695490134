import { Fragment } from 'react';
import UILIB from 'components';


export default function GenerateQuote({ formData, quoteErrors, clearErrors, loading }) {

    const updateQuoteData = (fieldName, event) => {
      formData.quote[0][fieldName] = event.target.value;
    }

    
    if (loading) return <UILIB.Loading type={3} />

    return  <Fragment>    
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-6 mar-b15">
                  <UILIB.Paper style={{ width: "100%", height: "100%" }}>
                    <div className="row">
                        <div className="col-xs-12 mar-b15">
                            <div className="mar-b15 mar-t5"><b>Total Cost</b></div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                            <label>Total Price (Excluding VAT):</label>
                            <UILIB.TextInput
                                type="text"
                                className="textInput input"
                                name="totalValue"
                                placeholder="0.00"
                                value={formData.quote[0].totalValue}
                                onClick={() => clearErrors()}
                                onChange={(event) => updateQuoteData('totalValue', event)}
                                disabled={formData.processStage > 3 ? true : false}
                                errored={quoteErrors[quoteErrors.findIndex((obj => obj.name === "totalValue"))]}
                            />
                        </div>
                    </div>
                    <div className="row mar-l15">
                        <div className="col-xs-6 mar-t20"><label>Pricing notes:</label></div>
                    </div>
                    <div className="col-xs-12 mar-t5 mar-l15">
                        <UILIB.TextArea
                            className="textInput input"
                            name="quoteValueNotes"
                            style={{ width: "100%", height: "200px" }}
                            outerClassName="mar-b10"
                            value={formData.quote[0].quoteValueNotes}
                            onClick={() => clearErrors()}
                            onChange={(event) => updateQuoteData('quoteValueNotes', event)}
                            disabled={formData.processStage > 3 ? true : false}
                            errored={quoteErrors[quoteErrors.findIndex((obj => obj.name === "quoteValueNotes"))]}
                        />
                    </div>
                  </UILIB.Paper>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-6 mar-b15">
                  <UILIB.Paper style={{ width: "100%", height: "100%" }}>
                    <div className="row">
                        <div className="col-xs-12 mar-b15">
                            <div className="mar-b15 mar-t5"><b>Email Details:</b></div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                        <label>Email Address:</label>
                        <UILIB.TextInput
                                type="text"
                                className="textInput input"
                                name="companyEmailAddress"
                                placeholder="Company Email Address"
                                value={formData.quote[0].companyEmailAddress}
                                onClick={() => clearErrors()}
                                onChange={(event) => updateQuoteData('companyEmailAddress', event)}
                                disabled={formData.processStage > 3 ? true : false}
                                errored={quoteErrors[quoteErrors.findIndex((obj => obj.name === "companyEmailAddress"))]}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6">
                        <label>Contact Name:</label>
                        <UILIB.TextInput
                                type="text"
                                className="textInput input"
                                name="companyContactName"
                                placeholder="Company Contact Name"
                                value={formData.quote[0].companyContactName}
                                onClick={() => clearErrors()}
                                onChange={(event) => updateQuoteData('companyContactName', event)}
                                disabled={formData.processStage > 3 ? true : false}
                                errored={quoteErrors[quoteErrors.findIndex((obj => obj.name === "companyContactName"))]}
                            />
                        </div>
                    </div>
                    <div className="row mar-l15">
                        <div className="col-xs-6 mar-t20"><label>Email Notes:</label></div>
                    </div>
                    <div className="col-xs-12 mar-t5 mar-l15">
                        <UILIB.TextArea
                            className="textInput input"
                            name="companyEmailNotes"
                            style={{ width: "100%", height: "200px" }}
                            outerClassName="mar-b10"
                            value={formData.quote[0].companyEmailNotes}
                            onClick={() => clearErrors()}
                            onChange={(event) => updateQuoteData('companyEmailNotes', event)}
                            disabled={formData.processStage > 3 ? true : false}
                            errored={quoteErrors[quoteErrors.findIndex((obj => obj.name === "companyEmailNotes"))]}
                        />
                    </div>
                  </UILIB.Paper>
                </div>
            </div>
          </Fragment> 
}


