import { useState, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios'
import { updateLeasePeriodPayment, getDealPricing } from '../functions';

export default function DeleteMultiDrawer(props) 
{    

    const [loading, setLoading] = useState(false);
    const defaultBanner = { error: false, message: '' };
    const [bannerError, setBannerError] = useState(defaultBanner);

    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while saving this record, please reload or contact support';
    const exceptions = [400,404];


    const actionDeleteProduct = async (data) => {
      try {
        setLoading(true);
        const products = data.selectedProducts;
        const result = await Axios.delete(`/entities/workflow/deals/actionDeleteProduct/${props.ourDeal.dealID}`, { data: products });
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          setBannerError(defaultBanner);
          await getDealPricing(props.ourDeal.dealID, props.ourDeal, props.setOurDeal);
          await updateLeasePeriodPayment(props.ourDeal, props.setOurDeal);
          props.setOurDeal({ 
            ...props.ourDeal, 
            product: result.data.result.product, 
            compatibles: result.data.result.compatibles, 
            selected: result.data.result.selected.map(x => { return { ...x, value: x.deviceNumber, label: x.deviceNumber } }) });
          await props.onFinish();
        }
      } catch (err) {
        console.log(err);
        setBannerError({ error: true, message: exceptionError });
      }
      setLoading(false);
    };

    return <Fragment>
        <div className='flex-container row wrap width-100'>
          <h2>Delete Products</h2>
          <div className="flex-item width-100 mar-b15">{`Would you really like to delete the selected product(s) from the quote?`}</div>
          <div className='flex-item start with-100'>
            <UILIB.Button loading={loading} disabled={loading} value={'Confirm'} className="mar-r5" onClick={async () => await actionDeleteProduct(props.productTickedEvent)} />
            <UILIB.Button value="Cancel" disabled={loading} className="grey" onClick={() => props.onCancel()} />
          </div>
          {bannerError.error && <div className="flex-item start">
            <div className="errored message">{bannerError.message}</div>
          </div>}
      </div>  
    </Fragment>
}
