import { useEffect, useState } from 'react';
import UILIB from 'components'
import moment from 'moment-timezone';


const headerData = [
  { label: "#", value: "rowNumber", type: 'number', width: "5%" },
  { label: "Name", value: "name", type: 'string', width: "40%" },
  { label: "Manufacturer", value: "manufacturerName", type: 'string', width: "10%" },
  { label: "Created Date", value: "created", type: 'string', width: "10%" },
  { label: "Updated Date", value: "updated", type: 'string', width: "10%" },
  { label: "Delete", value: "deleteRow", align: "center", width: "2%" }
]


export default function BlockInstallTable({ data, setRangeData, deletedRanges, setDeletedRanges }) {
    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(null);

    function renderTable() {

        let tableData = [];

        data.forEach((row, index) => {

          let style = row.hasPartsBind ? { backgroundColor: "rgba(108, 122, 137, 0.2)" } : { backgroundColor: "#FFFFFF" }

          tableData.push({
              rowNumber: {
                value: <div style={{ padding: 0, display: "flex" , alignItems: "center" }}>{index + 1}</div>,
                raw: index + 1,
                tdStyle: style
              },
              name: {
                  value: <UILIB.TextInput
                          style={{padding: 0, height: 24}}
                          className="textInput input"
                          name="name"
                          type="string" 
                          value={row.name}
                          onChange={(event) => updateFieldEvent('name', event, row, index)}
                        />,
                  raw: row.name,
                  tdStyle: style
              },    
              manufacturerName: {
                value: <div style={{ padding: 0, display: "flex" , alignItems: "center" }}>{row.SalesPartManufacturer.name}</div>,
                raw: row.SalesPartManufacturer.name,
                tdStyle: style
              },
              created: {
                  value: <div style={{ padding: 0, display: "flex" , alignItems: "center" }}>{moment(row.createdAt).format('DD/MM/YYYY').toString()}</div>,
                  raw: row.createdAt,
                  tdStyle: style
              },
              updated: {
                value: <div style={{ padding: 0, display: "flex" , alignItems: "center" }}>{moment(row.updatedAt).format('DD/MM/YYYY').toString()}</div>,
                raw: row.updatedAt,
                tdStyle: style
              },
              deleteRow: {
                value: row.hasPartsBind
                ? <div 
                    className="center icon icon-question-circle" 
                    title="Info" 
                    alt="Info" 
                    onClick={() => { setDeleting({ id: row.id, row: row, index: index }) }} 
                    style={{ padding: 0, cursor: "pointer" }}
                  />
                : <div 
                    className="center icon icon-trash2" 
                    title="Delete" 
                    alt="Delete" 
                    onClick={() => { setDeleting({ id: row.id, row: row, index: index }) }} 
                    style={{ padding: 0, cursor: "pointer" }}
                  />,
                  tdStyle: style
              },
          })

   
        })
      setTableData(tableData)
    }


    useEffect(() => {
        renderTable();
    }, [data]);



    const updateFieldEvent = (fieldName, event, row, index) => {
      const dataNew = JSON.parse(JSON.stringify(data));
      let range = dataNew[dataNew.findIndex(obj => obj.id === row.id)]
      range.name = event.target.value;
      setRangeData(dataNew);
    }

    const deleteRow = () => {
      const dataNew = JSON.parse(JSON.stringify(data));
      dataNew.splice(deleting.index, 1)
      setRangeData(dataNew);

      const deleteNew = JSON.parse(JSON.stringify(deletedRanges));
      deleteNew.push(deleting.row)
      setDeletedRanges(deleteNew);
      setDeleting(null)
    }

    return (
      <div>
        {deleting && !deleting.row.hasPartsBind &&
          <UILIB.Confirm 
            onConfirm={() => deleteRow()} 
            onCancel={() => setDeleting(null)} 
            header="Delete Range" 
            text={'Are you sure you want to delete range ' + deleting.row.name + '?'} 
          />
        }
        {deleting && deleting.row.hasPartsBind &&
          <UILIB.MessageBox 
            onConfirm={() => { setDeleting(null) }} 
            header="Unable to Delete" 
            text='Range cannot be deleted as it is currently associated to one or more Parts. To delete, first remove the association from the Part(s)' 
          />
        }
        <div className="mar-t15">
          <UILIB.Table
            className="small" 
            header={headerData}
            data={tableData}
            sortable={true}
            filter={true}
            showRecords={false}
            maxRows={60} 
          />
        </div>
      </div>
    );
}