import { useState, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import Axios from 'classes/axios';
import UILIB from 'components';
import { formatCurrency, formatDateTime } from 'classes/format';
import { actionOpenDrawer, setPSSalesReportFilterVars } from '../../functions';


export default function PSSalesReport({ filters, setFilters }) {

  const headerData = [
    { label: 'Deal', value: 'deal', type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: 'Customer', value: 'customer', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
    { label: 'Invoice No', value: 'invoiceNo', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Invoice Date', value: 'invoiceDateConvert', type: 'date', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Submitted Date', value: 'submittedDateConvert', type: 'date', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Item Number', value: 'itemNumber', type: 'string', filtering: true, minWidth: 70, maxWidth: 70 },
    { label: 'Item Description', value: 'itemDescription', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: 'List Price', value: 'listPrice', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Total Qty', value: 'totalQty', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Total Cost', value: 'totalCost', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Per Item', value: 'perItem', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'List Price', value: 'listPriceRepeat', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Sales Price', value: 'salesPriceTotal', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Discount %', value: 'dealSheetDiscPct', type: 'number', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Total Sales', value: 'totalSales', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Payment Terms', value: 'paymentTerms', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Sales Rep', value: 'salesRep', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    { label: 'Service Region', value: 'serviceRegion', type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },
    ];

  const dispatch = useDispatch();
  const entityData = useRef({});
  const [remoteRender, setRemoteRender] = useState(false);


  async function constructTable(quoteData) {

    const tableData = quoteData.map(row => {
      return {
        customer: { value: row.customer, raw: row.customer },
        deal: { value: row.deal, raw: row.deal },
        invoiceNo: { value: row.invoiceNo, raw: row.invoiceNo },
        itemNumber: { value: row.itemNumber, raw: row.itemNumber },
        invoiceDateConvert: { value: formatDateTime(row.invoiceDate, 'YYYY-MM-DD HH:MM'), raw: row.invoiceDate },
        submittedDateConvert: { value: formatDateTime(row.submittedDate, 'YYYY-MM-DD HH:MM'), raw: row.submittedDate },
        itemDescription: { value: row.itemDescription, raw: row.itemDescription },
        listPrice: { value: formatCurrency(row.listPrice), raw: Number(row.listPrice) },
        totalQty: { value: row.totalQty, raw: Number(row.totalQty) },
        totalCost: { value: formatCurrency(row.totalCost), raw: Number(row.totalCost) },
        perItem: { value: formatCurrency(row.perItem), raw: Number(row.perItem) },
        dealSheetDiscPct: { value: row.dealSheetDiscPct + '%', raw: Number(row.dealSheetDiscPct) },
        totalSales: { value: (row.totalSales === '' ? '' : formatCurrency(row.totalSales)), raw: row.totalSales },
        paymentTerms: { value: row.paymentTerms, raw: row.paymentTerms },
        salesRep: { value: row.salesRep, raw: row.salesRep } 
      }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const calcFilters = setPSSalesReportFilterVars(filters.pSSalesReportDrawer);

    entityData.current = await Axios.get(`/entities/workflow/psSalesReport?&$filter=${[calcFilters].join(' and ')}${queryLocal}&$supplier=${filters.pSSalesReportDrawer.supplier} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }


  return <Fragment>
    <div className='flex-container row width-100 end pad-b10'>
      <div className='flex-item start flex-grow-1'><b>PS Sales Report</b></div>
      <div className='flex-item end'>
        <UILIB.Button value="Filter Options" onClick={() => { actionOpenDrawer('PSSalesReportDrawer', { filters, setFilters }, dispatch, true, { get: remoteRender, set: setRemoteRender }, '400px') }} />
      </div> 
    </div>   
    <UILIB.TableNew
      name={`salesReport`}
      className='small'
      overflowX='auto'
      overflowY='hidden'    
      header={headerData}
      remoteQuery={getTableData}
      remoteRender={[filters.pSSalesReportDrawer]} />   
  </Fragment>
}