import { Fragment } from 'react';
import UILIB from 'components';


export default function DealHeader({ ourDeal, processData, otherQuotes }) {

  const oppertunityLink = (ourDeal?.links?.dynamics_base_url && ourDeal?.dynamicsOpportunityNumber) 
    ? <UILIB.Link 
        href={`${ourDeal?.links?.dynamics_base_url}main.aspx?app=d365default&forceUCI=1&pagetype=entityrecord&etn=opportunity&id=${ourDeal?.dynamicsOpportunityGuid}`} 
        rel="noreferrer" 
        target="_blank">
        <div className="text-18 font-weight-bold">{ourDeal?.dynamicsOpportunityNumber}</div>
      </UILIB.Link>
    : <div className="flex-item align-center text-18"><b>{ourDeal.dynamicsOpportunityNumber}</b></div>

  return <Fragment>
    <div className="flex-container center row wrap width-100 mar-t10 mar-b10">
      <div className="flex-item align-center text-18"><b>{processData.current.topText} Opportunity #</b></div>
      {oppertunityLink}
      {(otherQuotes && otherQuotes.length) 
        ? <div className="flex-item align-center text-18"><b>&nbsp;{`(${otherQuotes.length} quotes added)`}</b></div> 
        : <div></div>}
    </div>
  </Fragment>
}