
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react';

export default function ViewLinkError({ pushUrl = '', doPush = false, stateObj = null, timeout = 6000, redirectMessage = '' }) {

    const history = useHistory();

    useEffect(() => {
        setTimeout(() => {
            if(doPush) history.push({ pathname: pushUrl, stateObj })
        }, timeout)
    }, [])

    return (
        <div> 
            <div style={{position: 'absolute', left: '50%', top: '20%',transform: 'translate(-50%, -50%)'}}>
                <div className="col center"><h2>Oops, looks like this link is no longer active.</h2></div>
                <div className="col center"><p>{redirectMessage}</p></div>
            </div>
        </div>
    );
}

