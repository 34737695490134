import { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import UILIB from "components";
import Axios from "classes/axios";
import { formatCurrency, formatDateTime } from "classes/format";
import { dateWorkingDays } from "classes/dateWorkingDays";
import { useDispatch } from "react-redux";
import { tableContainer, actionOpenDrawer, getNotesColour } from './functions';
import moment from "moment";

const headerData = [
  { label: "", value: "salesPerson", width: "20px" },
  { label: "#", value: "finalDealNo", align: "center", type: "number" },
  { label: "Customer", value: "companyName", width: "500px", align: "center", type: "string" },
  { label: "GP", value: "gp", align: "center", type: "number" },
  { label: "Invoice Value", value: "invoiced", align: "center", type: "number" },
  { label: "Submitted", value: "dateSubmitted",  align: "center", type: "date" },
  { label: "Approved", value: "approvalDate", align: "center", type: "date" },
  { label: "Deal Type", value: "custType", width: "150px", align: "center", type: "string" },
  { label: "Engineer Status", value: "engStatus", width: "150px", align: "center", type: "string" },
  { label: "Days Active", value: "daysActive", align: "center", type: "number" },
  { label: "Invoice No.", value: "invoiceNo", align: "center", type: "string" },
  { label: "Invoiced", value: "invoiceDate", align: "center", type: "date" },
  { label: "Status", value: "status", width: "150px", align: "center", type: "string" },
  { label: "Notes", value: "adminNotes", width: "90px", align: "center" },
];

export default function BlockOrdersTable({ filter, staff }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tableData, setTableData] = useState({ headerData, tableData: [] });
  const [dealsDataRaw, setDealsDataRaw] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [render, setRender] = useState(true);

  const buildTable = () => {
    let dealsData = dealsDataRaw;
    let tableData = [];
    if (dealsData && dealsData.length) {
      tableData = dealsData
        .filter((r) => filter.staffIds.some((f) => f === r.activeDirectoryObjectGuid))
        .map((row) => {

          let approvedColor = "";
          let approvedBgColor = "";
          let statusTxt = "Quote/Draft";

          const b_hasNotes = Boolean(row.dealNote && row.dealNote.length);
          const notesColour = getNotesColour(row);
          const fn_notes = actionOpenDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: render, set: setRender });

          if (row.status === 1 && row.approved === 0) {
            statusTxt = "Awaiting Approval";
          }
          if (row.status === 1 && row.approved === 1 && !row.invoiceDate) {
            statusTxt = "Awaiting Invoice";

            if (moment().diff(moment(row.dateSubmitted), "days") > 7) {
              approvedColor = "white";
              approvedBgColor = "orange";
            }
            if (moment().diff(moment(row.dateSubmitted), "days") > 10) {
              approvedColor = "white";
              approvedBgColor = "red";
            }
          }
          if (row.status === 1 && row.approved === 1 && row.invoiceDate === 1) {
            statusTxt = "Complete";
          }
          if (row.archived === 1) {
            statusTxt = "Archived";
          }

          let engStatus = "None";
          switch (row.engStatus) {
            case 0:
              engStatus = "None";
              break;
            case 2:
              engStatus = "Parts on Order";
              break;
            case 3:
              engStatus = "In build progress";
              break;
            case 4:
              engStatus = "On Hold";
              break;
            case 5:
              engStatus = "Install Date Agreed";
              break;
            case 6:
              engStatus = "Awaiting Network Form";
              break;
            case 25:
              engStatus = "Invoice Only";
              break;
            case 7:
              engStatus = "Software";
              break;
            case 30:
              engStatus = "All stock in - awaiting Build";
              break;
            case 15:
              engStatus = "Build Complete";
              break;
            case 10:
              engStatus = "Completed";
              break;
            default:
              engStatus = "None";
              break;
          }

          let custType = "New";
          switch (row.custType) {
            case 0:
              custType = "New";
              break;
            case 1:
              custType = "Existing - Do Not Use";
              break;
            case 2:
              custType = "Existing - Net New";
              break;
            case 3:
              custType = "Existing - Renewal";
              break;
            default:
              custType = "New";
              break;
          }

          if (statusTxt !== "Quote/Draft") {
            var daysActive = dateWorkingDays(row.approvalDate, row.invoiceDate, 0);
            var daysActiveStyle = {
              textAlign: "center",
            };
            if (daysActive >= 7) {
              daysActiveStyle.backgroundColor = "orange";
              daysActiveStyle.color = "white";
              if (daysActive >= 10) daysActiveStyle.backgroundColor = "red";
            }
          }
          else {
            daysActive = 0;
          }

          let dealLink = null;
          let finalDealNo = row.dealID;
          if (row.finalDealNo) finalDealNo = row.finalDealNo;

          switch(true) {
            case row.source === "360":
              dealLink = <a href={`https://360.agilico.co.uk/serviceadmin/submitadeal/deal2.asp?dealID=${row.dealID}&referrer=`} rel="noreferrer" target="_blank">
                            <div>{finalDealNo}</div>
                          </a>
              break;
            case row.source === "SOP":
              dealLink = <UILIB.Link onClick={() => history.push('/salesHub/accountmanagerview/quote/' + row.dealID)} rel="noreferrer" target="_blank">
                            <div>{finalDealNo}</div>
                          </UILIB.Link>
              break;
            default: // Do nothing
          }
          
          const user = staff.find((s) => s.azureId === row.activeDirectoryObjectGuid) || {};

          return {
            salesPerson: {
              value: (
                <UILIB.Avatar account={user} />
              ),
              raw: row.salesPersonName || "",
            },
            finalDealNo: {
              value: dealLink,
              raw: dealLink,
            },
            companyName: {
              value: row.companyName || "",
              raw: row.companyName || "",
            },
            salesPersonName: {
              value: row.salesPersonName || "",
              raw: row.salesPersonName || "",
            },
            gp: { value: formatCurrency(row.gp) || 0, raw: row.gp || 0 },
            invoiced: {
              value: formatCurrency(row.invoiced || 0),
              raw: row.invoiced || 0,
            },
            invoiceDate: { value: formatDateTime(row.invoiceDate || "", "DD/MM/YYYY HH:mm"), raw: row.invoiceDate },
            approvalDate: {
              value: formatDateTime(row.approvalDate, "DD/MM/YYYY HH:mm"),
              raw: row.approvalDate || "",
            },
            daysActive: {
              value: daysActive || <div>0</div>,
              raw: daysActive,
              tdStyle: daysActiveStyle,
            },
            invoiceNo: {
              value: <div>{row.invoiceNo || 0}</div>,
              raw: row.invoiceNo || 0,
            },
            dateSubmitted: {
              value: formatDateTime(row.dateSubmitted || "", "DD/MM/YYYY HH:mm"),
              raw: row.dateSubmitted || "",
              tdStyle: {
                backgroundColor: approvedBgColor,
                color: approvedColor,
              },
            },

            custType: {
              value: custType,
              raw: row.custType || "",
            },
            engStatus: {
              value: engStatus,
              raw: row.engStatus || "",
            },
            status: { value: statusTxt, raw: statusTxt },
            adminNotes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes }
          };
        });
    }

    let final = {
      tableData,
      headerData,
    };
    setTableData(final);
  };

  const retrieveTableData = () => {
    setLoadingData(true);
    Axios.get(`/sales/deals/tableview?status=${filter.status}`).then(
      (deals) => {
        setDealsDataRaw(deals.data);
        setLoadingData(false);
      }
    );
  };

  useEffect(() => {
    retrieveTableData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    retrieveTableData();
    // eslint-disable-next-line
  }, [filter, render]);

  useEffect(() => {
    buildTable();
    // eslint-disable-next-line
  }, [dealsDataRaw]);

  return (
    <Fragment>
      <UILIB.Paper className="mar-b5">
        <UILIB.Table
          height="100%"
          className="small" 
          overflowX="auto"
          overflowY="hidden"
          header={tableData.headerData}
          data={tableData.tableData}
          loading={loadingData}
          sortable={true}
          filter={true}
          resetFilters={filter.resetTableFilters}
          maxRows={40}
          autoResize={false}
          exportEnable={true} 
        />
      </UILIB.Paper>
    </Fragment>
  );
}
