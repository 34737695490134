import { useState, Fragment } from 'react';
import ViewNotes from './notesView';
import AddNotes from './notesAdd';


export default function NotesDrawer({ entity, sync }) 
{  

  const [addNote, setAddNote] = useState(false);

  return <Fragment>
    {!addNote && <ViewNotes entity={entity} setAddNote={setAddNote} />}
    {addNote && <AddNotes entity={entity} setAddNote={setAddNote} sync={sync} />}
  </Fragment>
}
