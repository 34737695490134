import { memo } from 'react';
import { setCacheData } from '../functions';

const ShowTableFilters = memo(({ settings, moduleRender, setModuleRender }) =>
{
  const showHideFilter = () => {
    settings.current = { ...settings.current, filtering: !settings.current.filtering };
    if(settings.caching) setCacheData(settings);
    setModuleRender(!moduleRender);
  }
  
  return <div title={`${settings.current.filtering ? 'Close filters' : 'Open Filters'}`} className={`filter ${settings.current.filtering ? 'selected' : ''} clickable icon-funnel`} onClick={() => showHideFilter()}></div>
})
export default ShowTableFilters