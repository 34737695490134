import UILIB from 'components';
import { updateDealData } from '../functions';

export function addRow(title, mandatory, control) {
    return (
        <tr>
            <td width='200'>
                <div className='flex-container row nowrap'>
                  <div className='flex-item start flex-grow-1'>{ title }</div>
                  <div className='flex-item end'>{Boolean(mandatory) && <span className='text red'>*</span>}</div>
                </div>
            </td>
            <td>{control}</td>
        </tr>);
}

export function addTextInputRow(name, props, mandatory, maxLength, eventType, disabled = false) {
    var opts ={};
    opts['onChange'] = async event => updateDealData({ event: event, type: eventType, timeout: 500, props: props });
    if (mandatory) {
        opts['errored'] = props.formErrors && props.formErrors[name];
    }

    return (<UILIB.TextInput className="textInput input" disabled={!props.isActive || disabled} maxLength={maxLength} name={name} value={props.ourDeal[name]} {...opts} /> );
  }

  export function addSelectInputRow(name, props, mandatory, data, toolTip, onChange, eventType, disabled = false) {
    var opts ={};
    if (mandatory) {
        opts['errored'] = props.formErrors && props.formErrors[name];
    }

    if (toolTip) {
        opts['toolTip'] = toolTip;
    }

    if (onChange) {
        opts['onChange'] = onChange;
    } else {
        opts['onChange'] = async (event) => { updateDealData({ event: event, type: eventType, timeout: 500, props: props }) }
    }

    if(name === 'serviceContract' && String(props.ourDeal[name]) === '3') {
        props.setCpcDisabled(true);
    }

    return (<UILIB.Select name={ name } disabled={!props.isActive || disabled} onChange={onChange} value={String(props.ourDeal[name])} data={data} {...opts} /> );
  }