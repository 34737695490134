import UILIB from 'components';
import { useState, useEffect } from 'react';
import DocumentUpload from './documentUpload';
import Link from 'components/Link';
import moment from 'moment-timezone';
import * as functions from '../../shared/functions';

const headerData = [
    { label: "Document Name", value: "name", align: "left", type: 'string' },
    { label: "Document Type", value: "docType", align: "left", type: 'string' },
    { label: "Modified Date", value: "updatedAt", align: "left", type: 'string' },
]

export default function DocumentList(props) {

  const [mapped, setMapped] = useState([]);

    useEffect(() => { 
      if(!props.dataLoaded.document) return;
      const asyncEffect = async () => {
        const mapped = props.ourDeal.document.map(doc => {
          return { 
            name: <Link href={doc.Link}><div>{doc.EscapedName}</div></Link>, 
            docType: doc.Class, 
            updatedAt: moment(doc.LastModified).locale('en').add(1, 'hours').format("DD-MM-YYYY HH:mm") 
          }
        })
        setMapped(mapped);
      }
      asyncEffect();
    }, [props.ourDeal])


    return <UILIB.Paper style={{ height: "100%" }}>
        <DocumentUpload 
          className={"mar-b20"} 
          isActive={props.isActive} 
          ourDeal={props.ourDeal} 
          dataLoaded={props.dataLoaded}
          onUpload={async () => await functions.getDocs(props.ourDeal, props.setOurDeal)} 
        />
        <UILIB.Table
            className="small" 
            loading={!props.dataLoaded.document}
            header={headerData}
            data={mapped}
            sortable={false}
            filter={false}
            maxRows={30}
            defaultOrderBy=""
            defaultOrderDir=""
        />
    </UILIB.Paper>
}