import UILIB from 'components';
import { formatDateTime } from 'classes/format';

export const pleaseSelectYesNo = [
  { value: -1, label: 'Please Select' }, 
  { value: 0, label: 'No' },
  { value: 1, label: 'Yes' }
]

export const yesNoBool = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' }
]

export const yesNo = [
  { value: 0, label: 'No' },
  { value: 1, label: 'Yes' }
]

export const createDateInput = (label, name, editable, parentLength, titleLength, controlLength, entity, entityErrors, entityData, changeInput, link) => {
  if(entityData.current && !Array.from(Object.keys(entityData.current)).includes(name)) entityData.current = { ...entityData.current, [name]: entity[name] };
  return (createInputTable(
    parentLength,
    label,
      (editable) 
          ? <div className='col-xs-8'><UILIB.TextInput className="textInput input" style={{ maxWidth: titleLength, padding: 0, margin: 0 }} type='date' name={name} value={entityData.current[name]} onChange={changeInput} errored={entityErrors[name]} /></div>
          : <div className='col-xs-8'><div style={{ maxWidth: controlLength, padding: 0, margin: 0 }} className='text-small'>{formatDateTime(entityData.current[name], 'DD/MM/YYYY HH:mm:ss')}</div></div>
  ));
}

export const createTextInputTable = (label, name, editable, parentLength, titleLength, controlLength, entity, entityErrors, entityData, changeInput, link) => {
  if(entityData.current && !Array.from(Object.keys(entityData.current)).includes(name)) entityData.current = { ...entityData.current, [name]: entity[name] };
  const linkResult = (link !== null) ? link : entityData.current[name];
  return (createInputTable(
      parentLength,
      label,
      (editable) 
          ? <div className='col-xs-12 col-sm-12 col-md-10'><UILIB.TextInput className="textInput input" style={{ maxWidth: titleLength, padding: 0, margin: 0 }} name={name} value={linkResult} onChange={(ev) => changeInput(ev.target.value, ev.target.name, entityData.current)} errored={entityErrors[name]} /></div>
          : <div className='col-xs-12 col-sm-12 col-md-10'><div style={{ maxWidth: controlLength, padding: 0, margin: 0 }} className='text-small align left'>{linkResult}</div></div>
  ));
}

export const createLookupTextInputTable = (label, name, editable, parentLength, blank, controlLength, entity, entityErrors, entityData, query, onClick) => {
  if(entityData.current && !Array.from(Object.keys(entityData.current)).includes(name)) entityData.current = { ...entityData.current, [name]: entity[name] };
  return (createInputTable(
      parentLength,
      label,
      (editable) 
          ? <div className='col-xs-12 col-sm-12 col-md-10'><UILIB.AutoComplete className="min-height-50 width-100" name={name} blank={blank} defaultValue={entityData.current[name]} query={query} enableButton={false} onClick={(data) => onClick(data, name)} errored={entityErrors[name]} /></div>
          : <div className='col-xs-12 col-sm-12 col-md-10'><div style={{ maxWidth: controlLength }} className='text-small align left'> {entityData.current[name]}</div></div>
  ));
}

export const createSelectInputTable = (label, name, data, editable, parentLength, titleLength, controlLength, entity, entityErrors, entityData, changeInput) => {
  if(entityData.current && !Array.from(Object.keys(entityData.current)).includes(name)) entityData.current = { ...entityData.current, [name]: entity[name] };
  return (createInputTable(
        parentLength,
        label,
        <div className='col-xs-12 col-sm-12 col-md-10'><UILIB.Select style={{ maxWidth: titleLength, padding: 0, margin: 0 }} disabled={!editable} name={name} value={entityData.current[name]} onChange={(ev) => changeInput(ev.target.value, ev.target.name, entityData.current)} errored={entityErrors[name]} data={data} /></div>
    ));
}

export const createMultilineTextInput = (label, name, editable, maxLength, entity, entityErrors, entityData, changeInput) => {
  if(entityData.current && !Array.from(Object.keys(entityData.current)).includes(name)) entityData.current = { ...entityData.current, [name]: entity[name] };
  return (createInputTable(
        label,
        (editable) 
            ? <div className='col-xs-8'><UILIB.TextArea style={{ height: "100px", maxWidth: "100%", padding: "0", "resize": "none" }} name={name} value={entityData.current[name]} maxLength={maxLength} onChange={changeInput} errored={entityErrors[name]} /></div>
            : <div className='col-xs-8'><label className='text-small'> {entityData.current[name]}</label></div>
    ));
}

export const createControlInput = (label, editable, control, readonlyControl) => {
    return (createInputTable(
        label,
        (editable) 
            ? <div className='col-xs-8'>{control}</div>
            : <div className='col-xs-8'>{readonlyControl}</div>
    ));
}

export const createLookupControlInput = (label, editable, control, readonlyControl) => {
  return (createInputTable(
      label,
      (editable) 
          ? <div className='col-xs-8'>{control}</div>
          : <div className='col-xs-8'>{readonlyControl}</div>
  ));
}


const createInputTable = (maxWidth, label, control) => {
  return (<div style={{ maxWidth: maxWidth, padding: 0, margin: 0 }} className='row'>
              <div className='text-14 col-xs-12 col-sm-12 col-md-2'>{label}</div>
              {control}
          </div>)
}

export const checkAndSet = (check, obj, item, value) => {
    if (check) {
        obj[item] = value;
        return true;
    }

    return false;
}

export const newError = (name, msg) => {
    return { name: name, option: 1, messageText: msg, error: true };
}

