import { useState } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import {  createTextInput, checkAndSet, newError } from 'classes/controls/genericControls';


export default function CourierDrawer({ onClose }) {    
    const emptyEntity = {
        courier: '',
        website: '',
        store: '',
        login: '',
        password: '' };
    const headerText = ' Courier Information - ';



    const htmlButtons = () => {
        return <div>
            <UILIB.Button loading={saving} className='mar-t10' value='Action' onClick={submitForm} />
        </div>
    }


    const htmlRows = () => { 
        return <div className='mar-t15'>
            {createTextInput('Courier', 'courier', true, 40, entity, entityErrors, changeInput)}
            {createTextInput('Website', 'website', true, 40, entity, entityErrors, changeInput)}
            {createTextInput('Store', 'store', true, 40, entity, entityErrors, changeInput)}
            {createTextInput('Login', 'login', true, 40, entity, entityErrors, changeInput)}
            {createTextInput('Password', 'password', true, 40, entity, entityErrors, changeInput)}
            </div>
        }
    
    const defaultPageMessage = 'Please complete the below and then click Action.';
    const failValidationMessage = 'Please correct any errors and click Action again.';
    const saveErrorMessage = 'Unable to save, please advise support.';
    const [entity, setEntity] = useState(emptyEntity);
    const [entityErrors, setEntityErrors] = useState(emptyEntity);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState(defaultPageMessage);


    const changeInput = (event) => {
        let newFormData = JSON.parse(JSON.stringify(entity));
        newFormData[event.target.name] = event.target.value;
        setEntity(newFormData);

        if (event.target.name in entityErrors) {
			let newFormErrors = { ...entityErrors };
			newFormErrors[event.target.name] = '';
			setEntityErrors(newFormErrors);
		}
    }
    

    const evaluatedRulesPassed = (formErrorsTemp) => {
        let e = 0;        
        e = e + checkAndSet(!entity.courier, formErrorsTemp, 'courier', newError('courier', 'Please enter courier name'));
        e = e + checkAndSet(!entity.website, formErrorsTemp, 'website', newError('courier', 'Please enter website'));
        e = e + checkAndSet(!entity.store, formErrorsTemp, 'store', newError('courier', 'Please enter store name'));
        e = e + checkAndSet(!entity.login, formErrorsTemp, 'login', newError('courier', 'Please enter login'));
        e = e + checkAndSet(!entity.password, formErrorsTemp, 'password', newError('courier', 'Please enter password'));
        return e > 0;
	}

    const validSubmit = () => {
        let newFormData = JSON.parse(JSON.stringify(entity));
        setEntity(newFormData);

        let formErrorsTemp = emptyEntity;        
        if (!evaluatedRulesPassed(formErrorsTemp)) {
            return true;
        }

        setEntityErrors(formErrorsTemp);
        setMessage(failValidationMessage);
        return false;
    }


    const submitForm = async () => {
        try {
            if (validSubmit()) {
                // Check website address is formatted correctly for link
                if(!entity.website.toUpperCase().startsWith("HTTP://")) {
                    entity.website = "http://" + entity.website;
                }

                setSaving(true);
                console.log(entity);
                Axios.post('/entities/teamInfo', entity).then(() => {setSaving(false)});  
                onClose();
            }
        }
        catch (err) {
            console.log(err);
            setSaving(false);
            setMessage(saveErrorMessage);
        }        
    }

    return (
        <div className="mar-l15 mar-r15">
            <h2 className='mar-b10'>Add{headerText}{entity.id}</h2>
            <p>{message}</p>
            {htmlButtons()}
            {htmlRows()}
        </div> 
    )
}