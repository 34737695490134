
import { useEffect, Fragment } from 'react';
import UILIB from 'components'
import DocumentsTable from './tables/tableView';
import FleetMenu from '../../shared/fleetMenu';

export default function ViewDocuments(props) {

  useEffect(() => {
    const asyncRouter = async () => {
      await props.permissions.generalAccess();
    }
    asyncRouter();
  }, [])

  return <Fragment>
          <FleetMenu selectedButton={'Documents'} />
          <UILIB.Paper className='mar-l10 mar-r10'>
            <DocumentsTable modalView={false} />
          </UILIB.Paper>
        </Fragment>
}