
import { Fragment, useState, useEffect } from 'react'
import UILIB from 'components';
import Axios from 'classes/axios'
import { handleModalStyle, getJob, timerGetJobs, uploadFile, queueJobBase } from '../../../shared/functions' 
import UploadFileContainer from './uploadFileContainer'
import DocumentsTable from '../tables/tableView' 

export default function ViewDocumentsModal(props) 
{

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadFileHandler, setUploadFileHandler] = useState({ show: false, open: false });
    const [fileUploadJobActive, setFileUploadJobActive] = useState({ ...queueJobBase, name: props.entityName });
    const [render, setRender] = useState(0); 
    const [classData, setClassData] = useState([]);
    const [tableFilter, setTableFilter] = useState({ classId: 'unlinked' });
    const [loading, setLoading] = useState(false); 
    
    useEffect(() => {
      const asyncEffect = async () => {
        setLoading(true);
        const result = await Axios.get(`/fleet/documents/classes`);
        setClassData(result.data.result);
        setLoading(false);
      }
      asyncEffect();
    }, [])

    useEffect(() => {
      const asyncEffect = async () => {
        if (fileUploadJobActive.isActive === 1 && fileUploadJobActive.complete === 0) {
          await getJob(setFileUploadJobActive, fileUploadJobActive);
          await timerGetJobs(setFileUploadJobActive, fileUploadJobActive);
        }
      }
      asyncEffect();
    }, [fileUploadJobActive.trigger])

    return <Fragment>
      <div className="body modal genericModalContainer">
        <div className="genericModalContent" style={handleModalStyle(props, 'documents')}>
          <UILIB.Paper className="genericaModalChild" style={{ height: '15%' }}>
            <div className="flex-container row mar-t10">
              <div className="flex-item start flex-grow-1"><h4 style={{ padding: 0, margin: 0 }}>
                View / Upload - {props.entityName} Documents for {props.entityTitle}</h4>
              </div>
              <div className="flex-item end flex-grow-1">
                <UILIB.Button
                  className="green mar-r10"
                  value="Upload"  
                  onClick={async () => { await uploadFile({
                    fileUploadJobActive, 
                    setFileUploadJobActive,
                    uploadFileHandler, 
                    setUploadFileHandler,
                    selectedFile,
                    setSelectedFile,
                    name: props.entityName, 
                    uploadRoute: '/fleet/documents/upload',
                    jobRoute: '/fleet/getUploadJob/importDocuments'
                  })} }
                />
                <UILIB.Button
                  className="green"
                  value="Close"
                  onClick={() => props.setShowDocumentsModal(false)}
                />
              </div>
            </div> 
          </UILIB.Paper>
          <UILIB.Paper className="genericaModalChild">
            <DocumentsTable
              modalView={true}
              entity={props.entity}
              entityName={props.entityName}
              render={render}
              setRender={setRender}
            />
          </UILIB.Paper>
          <UploadFileContainer
            entity={props.entity}
            fileUploadJobActive={fileUploadJobActive}
            setFileUploadJobActive={setFileUploadJobActive}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            uploadFileHandler={uploadFileHandler}
            setUploadFileHandler={setUploadFileHandler}
            loading={loading}
            setLoading={setLoading}
            render={render}
            setRender={setRender}
            classData={classData}
            tableFilter={tableFilter}
            setTableFilter={setTableFilter}
          />
        </div> 
      </div> 
    </Fragment>
}

