import { useState, Fragment } from 'react';
import UILIB from 'components';
import BlockInstallTable from '../tables/blockInstallTable';

export default function DeviceCollect({ formData, setFormData, loading }) {

    const [filtersOpen, setFiltersOpen] = useState(false);
    const [rowTemp, setRowTemp] = useState({});

    const updateRowData = (event) => {
        setRowTemp({ ...rowTemp, [event.target.name] : event.target.value });
    }

    const updateConsumablesTable = () => {
        const dataNew = JSON.parse(JSON.stringify(formData));
        dataNew.consumables.push(rowTemp)
        setFormData(dataNew)
        setRowTemp({});
    }

    
  if (loading) return <UILIB.Loading type={3} />

  return  <Fragment>
            <div className="row">
                <div className="col-xs-12">
                    <UILIB.Paper className="col-xs-12 mar-b15">
                        <div className="flex-container between mar-b15">
                            <b>Kit Being Installed</b>
                        </div>
                        <BlockInstallTable data={formData} setFormData={setFormData} type={"installs"} sortable={true} filter={true} />
                    </UILIB.Paper>    
                </div>
                <div className="col-xs-12 flex-container end mar-b15 mar-t15">
                    <UILIB.StandaloneDrawer width={400} isOpen={filtersOpen} showClose={true} onClose={() => { setFiltersOpen(false) }}>
                        <h2>Add Consumable</h2>
                        <div className="mar-b20">
                            <label>Toner code</label>
                            <UILIB.TextInput type="text" className="textInput input" name="tonerCode" value={rowTemp.tonerCode} onChange={(event) => updateRowData(event)} />
                            <label>Cyan</label>
                            <UILIB.TextInput type="text" className="textInput input" name="cyan" value={rowTemp.cyan} onChange={(event) => updateRowData(event)} />
                            <label>Magenta</label>
                            <UILIB.TextInput type="text" className="textInput input" name="magenta" value={rowTemp.magenta} onChange={(event) => updateRowData(event)} />
                            <label>Yellow</label>
                            <UILIB.TextInput type="text" className="textInput input" name="yellow" value={rowTemp.yellow} onChange={(event) => updateRowData(event)} />
                            <label>Black</label>
                            <UILIB.TextInput type="text" className="textInput input" name="black" value={rowTemp.black} onChange={(event) => updateRowData(event)} />
                            <label>Waste Box</label>
                            <UILIB.TextInput type="text" className="textInput input" name="wasteBox" value={rowTemp.wasteBox} onChange={(event) => updateRowData(event)} />
                            <label>Staples</label>
                            <UILIB.TextInput type="text" className="textInput input" name="staples" value={rowTemp.staples} onChange={(event) => updateRowData(event)} />
                            <UILIB.Button name="addRow" value="Add" onClick={() => { updateConsumablesTable(); setFiltersOpen(false); }} />
                        </div>
                    </UILIB.StandaloneDrawer>
                </div>
            </div>
          </Fragment>
}


