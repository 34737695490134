import { useState, useRef, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { createCheckBox } from 'classes/controls/formControls';


export default function QuoteAddAdditionalModal(props) 
{

  const baseRoute = useRef(props.route.split('quote/')[0]);
  const defaultOptions = { option1: false, option2: false, option3: false };
  const [option, setOption] = useState(defaultOptions);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const [saving, setSaving] = useState(false);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const heading = 'You are about to create a duplicate of this quote. Please select how much information you want to carry over to the new quote.';


  const actionAddQuote = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const baseData = { context: option };
      const result = await Axios.post(`/entities/workflow/deals/actionAddAdditionalQuote/${props.ourDeal.dynamicsOpportunityNumber}/${props.ourDeal.dealID}`, baseData); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        if (!result?.data?.result?.deal?.dealID) {
          throw new Error('invalid dealID');
        } else {
          props.setShowAddQuoteModal(false);
          props.history.push(`${baseRoute.current}quote/${result?.data?.result?.deal?.dealID}`);
          window.location.reload();
        }
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  }

  const actionHandleTicked = async (event) => {
    setOption({ ...defaultOptions, [event.target.name]: !option[event.target.name] });
  }
  

  return <Fragment>
    <div className="addQuoteModalContainer">
      <div className="addQuoteModalContent flex-container row wrap" style={{ width: '30%', height: '35%' }}>
        <div className="flex-container row wrap center align-center pad-b10 pad-l30 pad-r30 font-weight-bold text-13">{heading}</div>
        <div className="flex-container row wrap pad-b10 pad-l50 pad-r50">
          <div className="flex-item row width-100 colour background-6 lightGrey mar-t6 mar-b6 pad-l10 pad-r10">
            <div className="flex-item width-80 center align-center font-weight-bold text-11">All information (customer data, deal info, products, etc)</div>
            {createCheckBox('', 'option1', saving, option.option1,  (ev) => actionHandleTicked(ev), 'width-20 center align-center', 'flex-item end width-100 mar-1', '', 'flex-item end')}
          </div>
          <div className="flex-item row width-100 colour background-6 lightGrey mar-t6 mar-b6 pad-l10 pad-r10">
            <div className="flex-item width-80 center align-center font-weight-bold text-11">All Information, no products</div>
            {createCheckBox('', 'option2', saving, option.option2,  (ev) => actionHandleTicked(ev), 'width-20 center align-center', 'flex-item end width-100 mar-1', '', 'flex-item end')}
          </div>
          <div className="flex-item row width-100 colour background-6 lightGrey mar-t6 mar-b6 pad-l10 pad-r10">
            <div className="flex-item width-80 center align-center font-weight-bold text-11">Basic client information only</div>
            {createCheckBox('', 'option3', saving, option.option3,  (ev) => actionHandleTicked(ev), 'width-20 center align-center', 'flex-item end width-100 mar-1', '', 'flex-item end')}
          </div>
          <div className="flex-container row end width-100">
            <UILIB.Button className='red' disabled={saving} value='Cancel' onClick={() => props.setShowAddQuoteModal(false)} />
            <UILIB.Button className='green' disabled={saving || !Object.values(option).some(x => x)} loading={saving} value='Action' onClick={async () => await actionAddQuote()} />
            {bannerError.error && <div className="flex-container end wrap width-100">
              <div className="errored message">{bannerError.message}</div>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </Fragment>
}