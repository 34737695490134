import { useEffect,  useState, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions';
import UILIB from 'components';
import Axios from 'classes/axios';
import CourierDrawer from './courierDrawer';


const teamInfoTableHeader = [
    { label: "Courier", value: "courier", type: "string", minWidth: 250 },
    { label: "Website", value: "website", type: "string", minWidth: 250 },
    { label: "Store", value: "store", type: "string", minWidth: 250 },
    { label: "Login", value: "login", align: "left", minWidth: 80, maxWidth: 80 },
    { label: "Password", value: "password", align: "left", minWidth: 80, maxWidth: 80 },
    { label: "Delete", value: "delete", align: "center", minWidth: 45, maxWidth: 45 },
];      



export default function TeamInfo() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [renderMe, setRenderMe] = useState(false);
    const [buttonDisabled, setButtonDisable] = useState(true);

    const getCurrentUserId = async () => {
      const user = await Axios.get("/users/me").then(api => api.data);
      return user.userId;
    }


    useEffect(() => {
      async function fetchPermissions() {
        if( await getCurrentUserId() === 3900) // Dan is 2198, Paul is 4508, Julie is 3900       
        {
          setButtonDisable(false);          
        }
        else {
          setButtonDisable(true);
        }
        setRenderMe(!renderMe);
      }
      fetchPermissions();
    }, []);
    

    function renderTable(courierData) {

        const tableData = courierData.map(row => {
            const deleteButton = <div align='center'><UILIB.Button  disabled={ buttonDisabled } key={row.courierID} className="button table width-100" name="deleteSelect" value="Delete" onClick={() => delete_onClick(row)} /></div>;
            const websiteLink = <div align='left'><UILIB.Link href={row.website}><div>{row.website}</div></UILIB.Link></div>;
            const passwordContainer = <UILIB.PasswordContainer value={row.password}>{row.password}</UILIB.PasswordContainer>;

            return {
              courier: { value: row.courier, raw: row.courier },
              website: { value: websiteLink, raw: '' },
              store: { value: row.store, raw: row.store },
              login: { value: row.login, raw: row.login },
              password: { value: passwordContainer, raw: row.password },
              delete: { value: deleteButton, raw: '' }
            }
        });
        return tableData;
    }                            

    const delete_onClick = async (row) => {
        if(window.confirm(`Delete ${row.courier}\nAre you sure?`)) { 
            await Axios.delete(`/entities/teamInfo/${row.courierID}`);
            getTableData();
            setRenderMe(!renderMe);
            console.log("Deleted!");
        }
        else {
            console.log("No delete");
        };
    }


    const getTableData = async () => {
        const apiData = {};
        apiData.couriers = await Axios.get(`/entities/teamInfos`).then(api => api.data);
        return { tableData: await renderTable(apiData.couriers.result), nonePaged: apiData.couriers.nonePaged };
    }

    const addCourierClose = async () => {
       dispatch(setDrawer({ open: false, content: null }));
       setRenderMe(!renderMe);
    }

    const onAddClicked = () => {
        dispatch(setDrawer({ open: true, content: <CourierDrawer onClose={addCourierClose}  />, width: '700px' }));
    }


    const tablePageDefaults = { paging: { limit: 40, offset: 0, orderBy: 'courier', orderDir: 'DESC' } };
    
    return <Fragment>       
        <div className="flex-container row mar-b15 mar-l5 mar-r5 mar-t10">    
          <UILIB.Paper className='width-100 mar-b15'>
            <div className='flex-item width-100 mar-b10'>
              <div className='col-xs-6 '><h3>Consumable Team Information</h3></div>
            </div>
            <div className='flex-container row'>
              <div className='flex-item start flex-grow-1 '> 
                <UILIB.Button value="Back" className="grey" onClick={() => history.push('/consumablesHub')} />
              </div>  
              <div className='flex-item end '>
                <UILIB.Button className='fmar-l5 mar-r5 small' disabled={ buttonDisabled } name='Add' value='Add' onClick={() => {onAddClicked()}} />
              </div>            
            </div>
          </UILIB.Paper>
          <UILIB.Paper className="width-100">
            <h3 className="flex-item start width-100 text-16 mar-t5">Courier Information</h3>
            <div>
              <UILIB.TableNew
                name="teamInfoList"
                className='small'
                overflowX='auto'
                overflowY='hidden'    
                header={teamInfoTableHeader}
                remoteQuery={getTableData}
                remoteRender={[renderMe]}
                defaultSettings={tablePageDefaults} />
            </div>
          </UILIB.Paper>
        </div>
      </Fragment>

}