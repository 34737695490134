import UILIB from 'components';
import { setDrawer } from 'store/actions';
import ViewNotesDrawer from './drawer/viewNotesDrawer';

export function getYesNo() {
  return [{ value: -1, label: "Select" },{ value: 0, label: "No" },{ value: 1, label: "Yes" }]
}

export function actionOpenNotesDrawer(type, entity, dispatch, enabled, sync) {
  if(!enabled) return false;
  const selector = (type, data) => {
    if(type === 'ViewNotesDrawer') return <ViewNotesDrawer entity={data} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
  };
  dispatch(setDrawer({ 
    open: true, 
    width: "600px",
    content: selector(type, entity)
  }))
}

export function tableContainer(onClick, icon, toolTip, colour, hover) {
  return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export const setFilterVars = (filter) => {
  if(filter.button === 'Available') {
    return '';
  }
  if(filter.button === 'Reserved') {
    return ' ( statusID eq 0 ) ';
  }
  if(filter.button === 'Sold') {
    return ' ( statusID eq 1 ) ';
  }
}