
export default function ColumnHeader({ groupHeader }) 
{

  return groupHeader.map((col, index) => {
    const align = (col.align) ? col.align : '';
    const style = { textAlign: align };
    const className = (col.className) ? col.className : '';
    return <th key={"thg_" + index} colspan={col.colspan} style={style} className={className}>
            <div className="flex-container center row nowrap width-100 height-100">
              <div className="flex-item start flex-grow-1 sortLabel width-100 text-10">{col.label}</div>
            </div>
          </th>
  })
}
