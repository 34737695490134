import { memo, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import UILIB from 'components';
import RequestsView from './requestsView';


const sendToVantageOnline = memo(() => {

  const history = useHistory();

  return <Fragment>
    <div className="flex-container row center width-100 pad-l5 pad-r5">
      <div className="flex-container column width-100 mar-t10">
        <UILIB.Paper className='flex-item row width-100 mar-b5'>
          <div className='flex-item start width-100 mar-b20'><h3>VO Requests</h3></div>
          <div className='flex-item start flex-grow-1'>
            <UILIB.Button value="Back" className="grey" onClick={() => history.push('/consumablesHub')} />
          </div>
        </UILIB.Paper>
        <RequestsView />
      </div>
    </div>
  </Fragment>
})
export default sendToVantageOnline