import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './reducers'
import thunk from 'redux-thunk'

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['drawer']
};
const pReducer = persistReducer(persistConfig, rootReducer);

let preloadedState;
const persistedToken = localStorage.getItem('refreshToken:');

if (persistedToken) {
    preloadedState = {
      refreshToken: persistedToken
    }
}

export const store = createStore(pReducer, applyMiddleware(thunk), structuredClone(preloadedState));
export const persistor = persistStore(store);