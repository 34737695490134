
import React from 'react';

export default function Paper({ children, className, style, id }) {
    let classes = "";
    if (className) classes += className;
    return <div className={"paper " + classes} style={style} id={id}>
        {children}
    </div>
}
