import * as mime from 'react-native-mime-types';

export function downloadFileHandler(fileName, ext, data) {

  try {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = `data:${mime.lookup(ext)};base64,` + data;
    document.body.appendChild(link);
    link.click();
  }
  catch (error) {
    return error;
  }
}