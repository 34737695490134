import { Fragment } from 'react';
import UILIB from 'components';

export default function CustomerStats({ type, data, loading = false, className, primaryClass = '', secondaryClass = '' }) {

  const classLocal = (loading) 
    ? 'colour background-8 lightGrey' 
    : className;

  return <Fragment>
      <div className={`flex-container row center height-100 ${classLocal} mar-5 border-radius-3`}>
        {(loading) && <div className={`flex-item row center width-100 colour background-8 lightGrey`}><UILIB.Loading type={3} /></div>}
        {(type === 0 && !loading) && <div className={`flex-item row center width-100 ${primaryClass}`}>{(data && data.primary ? data.primary : '')}</div>}
        {(type === 0 && !loading) && <div className={`flex-item row center width-100 ${secondaryClass}`}>{(data && data.secondary ? data.secondary : '')}</div>}
        {(type === 1 && !loading) && <div className={`flex-item row center width-100 ${primaryClass}`}>{(data && data.primary ? data.primary : '')}</div>}
      </div>
    </Fragment>
}