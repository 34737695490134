import { useState, useRef, Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import { calculateStoreBind } from '../../functions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'classes/axios';
import UILIB from 'components';


export default function SiteDevicesTable({ entityLookup, siteStockRender, setSiteStockRender, siteDeviceRender }) {

  const headerData = [
    { label: 'Serial', value: 'serialNumber', type: 'string', filtering: true, minWidth: 250, maxWidth: 200 },
    { label: 'Device Name', value: 'description', type: 'string', filtering: true, minWidth: 250, maxWidth: 300 },
    { label: 'Last Scanned', value: 'statsDate', type: 'date', filtering: true, minWidth: 150, maxWidth: 150 },
    { label: 'Black', value: 'blackLev', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: 'Cyan', value: 'cyanLev', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: 'Magenta', value: 'magentaLev', type: 'date', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: 'Yellow', value: 'yellowLev', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: 'Group', value: 'group', type: 'string', filtering: false, minWidth: 120, maxWidth: 120 }];

  const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 20, limit: 20, offset: 0, orderBy: 'serialNumber', orderDir: 'ASC' } };
  const pleaseSelect = { label: 'Please Select', value: -1 };
  const entityData = useRef({});
  const groupSelect = useRef({});
  const [processing, setProcessing] = useState(false);
  const [localRender, setLocalRender] = useState(false);

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];


  const handleChangeGroup = async (event, serial, cust, bind) => {
    try {
      setProcessing(true);
      const eventValue = Number(event.target.value);
      const respose = await Axios.post(`/entities/workflow/actionChangeDeviceGroup`, { 
        serialNumber: serial, 
        customerId: cust, 
        storeId: eventValue, 
        bind });
      if(exceptions.includes(respose.status)) {
        if(respose.status === 400) toast.error(invalidConfig);
        if(respose.status === 404) toast.error(notFoundError);
      } else {
        if(eventValue !== -1) {
          groupSelect.current = { ...groupSelect.current, [serial]: Number(respose.data.result[0].storeID) };
        } else {
          groupSelect.current = { ...groupSelect.current, [serial]: eventValue };
        }
        setSiteStockRender(!siteStockRender); 
        setLocalRender(!localRender);
      }
    } catch (error) {
      console.log(error);
      toast.error(exceptionError);
    }
    setProcessing(false);
  };

  async function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

        const blackLev = (row.blackLev) ? `${row.blackLev}%` : '0%';
        const cyanLev = (row.cyanLev) ? `${row.cyanLev}%` : '0%';
        const magentaLev = (row.magentaLev) ? `${row.magentaLev}%` : '0%';
        const yellowLev = (row.yellowLev) ? `${row.yellowLev}%` : '0%';
        const groupSelectData = [pleaseSelect, ...row.siteStore.map(x => { return { label: x.groupName, value: Number(x.rowID) } })];

        const groupSelectControl = <UILIB.Select 
          disabled={processing}
          className="tableFilter mar-b0" 
          outerClassName="tableFilter mar-b0" 
          value={Number(groupSelect.current[row.serialNumber])} 
          data={groupSelectData}
          onChange={async (ev) => await handleChangeGroup(ev, row.serialNumber, row.customerId, row.storeBind[0])} />

        return {
          serialNumber: { value: row.serialNumber, raw: row.serialNumber },
          description: { value: row.description, raw: row.description },
          statsDate: { value: formatDateTime(row.statsDate, 'DD/MM/YYYY HH:mm'), raw: row.statsDate },
          blackLev: { value: blackLev, raw: row.blackLev },
          cyanLev: { value: cyanLev, raw: row.cyanLev },
          magentaLev: { value: magentaLev, raw: row.magentaLev },
          yellowLev: { value: yellowLev, raw: row.yellowLev },
          group: { value: groupSelectControl, raw: calculateStoreBind(row.siteStore, row.storeBind) }
        }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const expandArray = ['siteStores', 'storebind'];

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    if(entityLookup.hasOwnProperty('customerId')) {
      const customerId = entityLookup['customerId'];
      entityData.current.devices = await Axios.get(`/entities/emailer_SAINVENTORYs/getSummary/?&$filter=customerId eq ${customerId} ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    }

    if(entityLookup.hasOwnProperty('equipmentId')) {
      const equipmentId = entityLookup['equipmentId'];
      entityData.current.equipment = await Axios.get(`/entities/equipment/${equipmentId}/?&$select=CustomerId and SerialNumber`, { cancelToken: cancelToken.token }).then(res => res.data.result[0]);
      entityData.current.devices = await Axios.get(`/entities/emailer_SAINVENTORYs/getSummary/?&$filter=serialNumber eq ${entityData.current.equipment.SerialNumber} ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    }

    if(!entityData.current || !entityData.current.devices || !entityData.current.devices.result) return false;

    let groupSelectMapped = {};
    if(entityData.current.devices && entityData.current.devices.result) {
      entityData.current.devices.result.forEach(x =>  { return groupSelectMapped = { ...groupSelectMapped, [x.serialNumber]: calculateStoreBind(x.siteStore, x.storeBind) } });
      groupSelect.current = groupSelectMapped;
    }

    return { tableData: await constructTable(entityData.current.devices.result), raw: entityData.current.devices.result, nonePaged: entityData.current.devices.nonePaged };
  }

  return <Fragment>
    <UILIB.Paper className='flex-item row width-100 flex-1 mar-b20'>
      <div className='flex-item row  width-100 start'><h4>Site Devices</h4></div>
      <div className="width-100">
        <UILIB.TableNew
          name="SiteDevices"
          className='small'
          overflowX='auto'
          overflowY='hidden'    
          header={headerData}
          localQuery={() => constructTable((entityData.current && entityData.current.devices) ? entityData.current.devices.result : [])}
          localRender={[localRender, processing]}
          remoteQuery={getTableData}
          remoteRender={[entityLookup, siteDeviceRender]}
          defaultSettings={tablePageDefaults} />
      </div>
    </UILIB.Paper>
  </Fragment>
}