import { useState, Fragment } from 'react';
import UILIB from 'components';
import { v4 as uuidv4 } from 'uuid';
import BlockDeviceTable from '../tables/blockDeviceTable';
import { getDeviceDetails, getSerials } from "classes/helpers";


export default function DeviceCollect({ formData, setFormData, loading }) {

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [addStatus, setAddStatus] = useState(true)
  const [rowTemp, setRowTemp] = useState({ attachments: [], readings: [] });


  async function getDeviceData(result) {

    const data = await getDeviceDetails(result.deviceid);
    if(data.length) setAddStatus(false);

    const baseData = {
      filterId: uuidv4(),
      itemId: data.itemId,
      itemDescription: data.description,
      itemSerialNumber: data.serialNumber,
      itemSerialNumberId: data.equipmentId,
      quantity: data.qty,
      ipAddress: null,
      currentFloor: null,
      installFloor: null,
      dmcNumber: null,
      deviceValue: data.baseUnitPrice,
      deviceLocationn: data.location,
      ticked: true }

    const attachments = data.attachments.map(a => {
      return {
        filterId: uuidv4(),
        quantity: a.Quantity,
        description: a.Item.Description,
        itemId: a.Item.Id,
        ticked: true  
      } })

    const readings = (data.readings)
      ? data.readings 
      : [{ equipmentId: null, meterName: "Mono Total", meterReading: 0, meterType: 1 },
        { equipmentId: null, meterName: "Colour Total", meterReading: 0, meterType: 2 }]

    setRowTemp({ ...baseData, attachments: attachments, readings: readings });
  }

  const updateMovementTable = () => {
    const dataNew = JSON.parse(JSON.stringify(formData));
    dataNew.devices.push(rowTemp);
    setFormData(dataNew);
    setRowTemp({ attachments: [], readings: [] });
  }


  if (loading) return <Fragment></Fragment>

  return  <Fragment>
            <div className="row">
              <div className="col-xs-12">
                <UILIB.Paper className="col-xs-12 mar-b15">
                  <div className="col-xs-12 flex-container between mar-b15">
                    <b>Devices to be moved</b>
                    {formData.processStage < 4 && 
                      <UILIB.Button value="Add Device" onClick={() => { (filtersOpen) ?  setFiltersOpen(false) :  setFiltersOpen(true) }} />}
                  </div>
                  <BlockDeviceTable
                    data={formData}
                    setFormData={setFormData}
                    type={"removals"}
                    sortable={true}
                    filter={true}
                  />
                </UILIB.Paper>    
              </div>
              <UILIB.StandaloneDrawer width={400} isOpen={filtersOpen} showClose={true} onClose={() => { setFiltersOpen(false) }}>
                <h2>Add Device</h2>
                <div className="mar-b15">
                <UILIB.AutoComplete
                  className="min-height-50 max-width-400"
                  name='serialNumber'
                  blank='No Serials Found'
                  query={getSerials}
                  defaultValue={formData.serialNumber}
                  enableButton={false}
                  onClick={(data) => getDeviceData(data)} />
                </div>
                <div className="mar-b15">
                    <UILIB.Button
                        name="addRow"
                        value="Add"
                        disabled={addStatus}
                        onClick={() => { updateMovementTable(); setFiltersOpen(false); setAddStatus(true) }}
                    />
                </div>
              </UILIB.StandaloneDrawer>
            </div>
          </Fragment>
}


