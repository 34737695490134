import { useState, useEffect, Fragment } from 'react';
import UILIB from 'components'

const headerData = [
  { label: "#", value: "id", type: 'string', width: '50px', align: 'left' },
  { label: "Registration", value: "registration", type: 'string', width: '150px', align: 'left' },
  { label: "Work Email", value: "workEmail", type: 'string', width: '150px', align: 'left' },
  { label: "Associate From", value: "associationFrom", type: 'string', width: '150px', align: 'left' },
  { label: "Associate To", value: "associationTo", type: 'string', width: '150px', align: 'left' },
  { label: "Message", value: "reason", type: 'string', width: '250px', align: 'left' },
  { label: "Result", value: "operationType", type: 'number', width: '80px', align: 'left' }
]

export default function DriverVehicleImportTable(props) {

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState([]);

  const tablefilter = props.tableSortHandler.filter === 'View All Rows' ? null : props.tableSortHandler.filter;

  const tableDataResult = tableData.filter(f => !tablefilter ? f : tablefilter.toLowerCase() === f.operationType.toLowerCase()).map(row => {

    let operationType = { data: <UILIB.Chip className="table grey text-small width-100" value="Unknown" />, value: 0 };
    if (row.operationType === 'CREATE') operationType = { data: <UILIB.Chip className="table green text-small width-100" value="Create" />, value: 1 };
    if (row.operationType === 'UPDATE') operationType = { data: <UILIB.Chip className="table orange text-small width-100" value="Update" />, value: 2 };
    if (row.operationType === 'FAILED') operationType = { data: <UILIB.Chip className="table red text-small width-100" value="Failed" />, value: 3 };

    return {
      id: { value: row.id, raw: row.id },
      registration: { value: row.line.registration, raw: row.line.registration },
      workEmail: { value: row.line.workEmail, raw: row.line.workEmail },
      associationFrom: { value: row.line.associationFrom, raw: row.line.associationFrom },
      associationTo: { value: row.line.associationTo, raw: row.line.associationTo },
      operationType: { value: operationType.data, raw: operationType.value },
      reason: { value: row.reason, raw: row.reason }
    }
  })

  useEffect(() => { 
    setLoading(true);
    setTableData(props.uploadedRowsModal.fleetHubImportRow);
    setLoading(false);
  }, []);


  return (
    <Fragment>
      <UILIB.Table
        className='small' 
        overflowX='auto'
        overflowY='auto'
        fitToParent={true}
        loading={loading}
        header={headerData}
        data={tableDataResult}
        sortable={true}
        filter={true}
        resetFilters={false}
        maxRows={20}
        autoResize={false}
        defaultOrderBy='importDate'
        defaultOrderDir='DESC'
        exportEnable={true} 
      />
    </Fragment>
  );
}