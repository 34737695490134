
import { Fragment } from 'react';

export default function TableContainer({ onChange = () => { }, onClick = () => { }, data, icon, toolTip, colour, className, hover }) {

  const colourLocal = (colour) ? colour : '';
  const iconLocal = (icon) ? icon : '';
  const classLocal = (className) ? className : '';
  const clickable = (hover) ? ' clickable ' : '';

  return <Fragment>
          <div className={`flex-container center row around width-100 height-100 ${colourLocal} ${clickable}`} title={toolTip} onChange={(ev) => onChange(ev)} onClick={(ev) => onClick(ev)}>
            <div className={`${iconLocal} flex-item center height-100 ${classLocal}`}>{data}</div>
          </div>
        </Fragment>
}
