import { useState, useEffect, Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import UILIB from 'components';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';


const Accordion = styled((props) => (
  <MuiAccordion 
    disableGutters 
    elevation={0}  
    square 
    sx={{ backgroundColor: '#EFEFEF', mb: 1 }}
    {...props} 
  />
))(({ theme }) => ({
  marginBottom: 5,
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary 
    expandIcon={<ArrowForwardIosSharpIcon 
      sx={{ fontSize: '0.9rem' }} />} 
      {...props} 
    />
))(({ theme }) => ({
  backgroundColor: 'lightgrey',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export default function ReleaseNote(props) {

  const [release, setRelease] = useState(); 
  const [dataTags, setDataTags] = useState(); 

  useEffect(() => {
    const release = structuredClone(props.releaseData);
    const dataTagsNew = [];
    release.adminReleaseItem.forEach(i => { if(!dataTagsNew.includes(i.tags)) dataTagsNew.push(i.tags) });
    setDataTags(dataTagsNew.sort(function(a, b){
      if(a < b) { return -1; }
      if(a > b) { return 1; }
      return 0;
    }))
    setRelease(release);
  }, [])


  if (!release) { return <UILIB.Loading type={3} /> }

  return <Fragment>  
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box sx={{ width: '100%', display: 'flex' , justifyContent: 'space-between' }}>
            <Typography>{release.versionNumber}</Typography>
            <Typography>{formatDateTime(release.createdAt, "DD/MM/YYYY")}</Typography>
          </Box>
        </AccordionSummary>
        <Box sx={{ width: '100%', display: 'flex' , justifyContent: 'center', pt: 2, pb: 1 }}>
          <Typography sx={{ fontSize: 16 }}>Release Name: {release.incrementNumber}</Typography>
        </Box>
        {dataTags.map(tag => {
          return <Box style={{ paddingLeft: 10, paddingTop: 5, backgroundColor: '#EFEFEF' }}>
            {tag}
            {release.adminReleaseItem.filter(f => String(f.tags) === String(tag)).map(item => {
              return <div style={{ paddingLeft: 20, paddingTop: 5, paddingBottom: 10, fontSize: 14 }}>
                <li>{item.title}</li> 
              </div> 
            })}
          </Box>
        })}
      </Accordion>
    </Fragment>
}