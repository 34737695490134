import { useState, Fragment } from 'react';
import UILIB from 'components';
import { createDateInput, createSelectInput, createToggle } from 'classes/controls/formControls';


export default function InstallFiltersDrawer ({ data, onFinished, sync }) {

  const [filtersLocal, setFiltersLocal] = useState(data.filters);

  const updateDates = (newDate, context) => {
    filtersLocal[context][newDate.target.name] = newDate.target.value;
    setFiltersLocal({ ...filtersLocal });
  }

  const handleOptionsChanged = (event, name) => {
    setFiltersLocal({ ...filtersLocal, [name]: event.target.value });
  };

  const handleEnableFilter = (event) => {
    setFiltersLocal({ ...filtersLocal, [event.target.name]: !filtersLocal[event.target.name] });
  };

  const syncAndClose = () => {
    data.setFilters({ ...data, ...data.filters = filtersLocal });
    sync.set(!sync.get);
    onFinished();
  }

  return <Fragment>         
    <h2 className="mar-b20">Invoice Filters</h2>
    <div className="flex-container row start width-100 mar-3">
      {createToggle('Enable this filter', 'enabled', false, filtersLocal.enabled, (ev) => handleEnableFilter(ev, 'enabled'), null, 'flex-grow-1')}
      <div className="flex-item row width-100">
        {createDateInput('Invoice Date Start', 'startDate', false, filtersLocal.datesInvoiced.startDate, (ev) => updateDates(ev, 'datesInvoiced'), null, 'flex-grow-1 width-45')}
        {createDateInput('Invoice Date End', 'endDate', false, filtersLocal.datesInvoiced.endDate, (ev) => updateDates(ev, 'datesInvoiced'), null, 'flex-grow-1 width-45')}
      </div>
      {createSelectInput('New Or Used', 'newUsed', false, filtersLocal.newUsed, data.selectData, (ev) => handleOptionsChanged(ev, 'newUsed'), null, 'flex-grow-1 width-100')}
    </div>
    <div className="flex-container row start width-100 mar-3 mar-t20">
      <UILIB.Button name="Apply Filters" value="Apply Filters" loading={false} disabled={false} onClick={() => syncAndClose()} />
    </div>
  </Fragment>   
}
