import { useState, useRef, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { createButton, createSelectInput } from 'classes/controls/formControls';


export default function AddRefinanced({ onFinish, ourDeal, setOurDeal, isActive }) {

  const headerData = [
    { label: '', value: 'selected', type: 'select', filtering: false, minWidth: 10, maxWidth: 10 },
    { label: "Part #", value: "PartNumber", type: 'string', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Asset #", value: "AssetNumber", type: 'string', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Serial", value: "SerialNumber", type: 'string', filtering: true, minWidth: 40, maxWidth: 40 },
    { label: "Description", value: "Description", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Location Address", value: "AddressString", type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Location On Site", value: "Location", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 }];

    const entityData = useRef({});
    const addressData = useRef([]);
    const requestCheckbox = useRef({});
    const defaultError = { error: false, message: '' };
    const [bannerError, setBannerError] = useState(defaultError);
    const [selectedAddress, setSelectedAddress] = useState(-1);
    const [saving, setSaving] = useState(false);
    const [localRender, setLocalRender] = useState(false);
    const exceptions = [400,404];

    const invalidConfig = 'The options selected are invalid, please try again or contact support';
    const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
    const exceptionError = 'There was an exception while processing the record(s), please reload or contact support';


    const updateSelected = (result) => {
      requestCheckbox.current = result;
      setLocalRender(!localRender);
    };

    const actionsubmitForm = async () => {
      setSaving(true);
      try {
        const requestIds = Object.keys(requestCheckbox.current).filter(x => requestCheckbox.current[x]).map(x => Number(x));
        const cachedData = entityData.current.result.filter(x => requestIds.includes(Number(x.id)));
        const result = await Axios.post(`/entities/workflow/deals/actionAddDeviceRefinanced/${ourDeal.dealID}/${selectedAddress}`, { devices: cachedData });
        if(exceptions.includes(result.status)) {
          if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
          if(result.status === 404) setBannerError({ error: true, message: notFoundError });
        } else {
          setOurDeal({ 
            ...ourDeal, 
            product: result.data.result.product, 
            compatibles: result.data.result.compatibles, 
            selected: result.data.result.selected.map(x => { return { ...x, value: x.deviceNumber, label: x.deviceNumber } })
          });
          onFinish();
        }
      } catch (error) {
        console.log(error);
        setBannerError({ error: true, message: exceptionError });
        setSaving(false);
      }
    };

    async function constructTable(dealData) {

      const tableData = dealData.map(row => {

        return {
          selected: { value: row.id, raw: row.id },
          PartNumber: { value: row.PartNumber, raw: row.PartNumber },
          AssetNumber: { value: row.AssetNumber, raw: row.AssetNumber },
          SerialNumber: { value: row.SerialNumber, raw: row.SerialNumber },
          Description: { value: row.Description, raw: row.Description },
          AddressString: { value: row.AddressString, raw: row.AddressString },
          Location: { value: row.Location, raw: row.Location } }
      })
      return tableData;
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

      const queryLocal = (query !== null) ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const serialNumbers = (ourDeal.product.some(x => x?.serialNo)) ? `SerialNumber nin ${ourDeal.product.map(x => x?.serialNo).filter(x => x).join(',')}` : '';

      entityData.current = await Axios.get(`/entities/equipments/getSummaryDeviceRetained/${ourDeal.accountNo}/?&$filter= ${serialNumbers} and ${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
  
      if(!entityData.current || !entityData.current.result.length) return false;

      let addresses = [{ value: -1, label: 'Please Select' }];
      ourDeal.address.forEach(x => {
        const label = Object.values({ 
          address1: x.address1, 
          address2: x.address1, 
          address3: x.address3, 
          town: x.town, 
          county: x.county, 
          postcode: x.postcode}).filter(x => x && x.length).join(', ');
        addresses.push({ value: x.id, label: label });
      });
      addressData.current = addresses;

      setLocalRender(!localRender);

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }


    return <Fragment>
      <div className='flex-container row mar-b10'>
        <div className="flex-item start align-center flex-grow-1 text-16 font-weight-bold width-100">Add from Existing Equipment</div>
        <p className="flex-item start width-80 text-11"><i>Please note, any devices you select can be assigned to a single address only.</i></p>
        <p className="flex-item start width-80 text-11"><i>If you need multiple addresses, either open this drawer again, or change them from the product line dropdown</i></p>
      </div>
      <div className='flex-container row start wrap evenly width-100'>
        {createSelectInput('Select Address', 'selectAddress', (!isActive || !Object.keys(requestCheckbox.current).some(x => requestCheckbox.current[x] || saving)), selectedAddress, addressData.current, (ev) => setSelectedAddress(Number(ev.target.value)), null, 'width-80 flex-grow-1', null, null, 'width-100 fullBorder border-colour-grey-4')}
        {createButton('', 'submit', 'Submit', (!isActive || !Object.keys(requestCheckbox.current).some(x => requestCheckbox.current[x]) || saving || (Number(selectedAddress) === -1)), saving, async () => await actionsubmitForm(), null, 'flex-item width-20')}
        {bannerError.error && <div className="flex-item end wrap width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
      <div className="mar-l5 mar-r5">
        <UILIB.TableNew
          name={'EquipmentRefinance'}
          className='small'
          overflowX='auto'
          overflowY='hidden'    
          header={headerData}
          selectQuery={updateSelected}
          localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
          localRender={[localRender]}
          remoteQuery={getTableData}
          remoteRender={[ourDeal]} />
      </div>
    </Fragment >
}