import { useState, Fragment } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import UILIB from 'components';
import { useDispatch } from 'react-redux';
import ProductsTable from '../tables/quoteProductsTable';
import { setDrawer } from 'store/actions';
import BindExistingProductDrawer from '../../shared/drawer/bindExistingProductDrawer';
import CloneProductDrawer from '../../shared/drawer/cloneProductDrawer';
import DeleteMultiDrawer from '../../shared/drawer/deleteProductDrawer';
import AddNewProductDrawer from '../../shared/drawer/addNewProductDrawer';
import AddressesFrontPage from '../../shared/additionalInfoPage/invoiceAddress';
import CRMDetails from '../../shared/detailPage/crmDetails';
import SingleStatCard from '../../shared/detailPage/singleStatCard';
import CoreDetails from '../../shared/detailPage/coreDetails';
import LeaseDetails from '../../shared/detailPage/leaseDetails';
import Modules from '../../Modules';


export default function QuoteDetailPage(props) 
{
    const dispatch = useDispatch();
    const [updateNo, setUpdateNo] = useState(0);
    const [cpcDisabled, setCpcDisabled] = useState(false);
    const [formErrors, setFormErrors] = useState({}); // only here to conform in shared quote <-> order logic
    const bannerMessage = 'When selecting multiple quantities of a product, the system will account for this in the total figures.';
    const hasQuantity = props.ourDeal.product.some(x => Number(x.qty) > 1);

    const actionCloseDrawer = async () => { 
      dispatch(setDrawer({ open: false, content: null })); 
      props.setRefreshData(!props.refreshData);
    }

    const addBlankProduct = () => {
        dispatch(setDrawer({ 
          open: true, 
          content: 
            <AddNewProductDrawer 
              dealID={props.ourDeal.dealID} 
              ourDeal={props.ourDeal} 
              setOurDeal={props.setOurDeal} 
              isActive={props.isActive}
              setRefreshData={props.setRefreshData}
              onCancel={() => dispatch(setDrawer({ open: false, content: null }))}
              onFinish={() => actionCloseDrawer()} />,
          width: "18w" }));
    }

    const addSettlementProducts = () => {
      dispatch(setDrawer({ 
        open: true, 
        content: 
          <Modules.AddSettlementLine 
            dealID={props.ourDeal.dealID} 
            ourDeal={props.ourDeal} 
            setOurDeal={props.setOurDeal} 
            isActive={props.isActive}
            setRefreshData={props.setRefreshData}
            onCancel={() => dispatch(setDrawer({ open: false, content: null }))}
            onFinish={() => actionCloseDrawer()} />,
        width: "18w" }));
    }

    const addRefinancedProducts = () => {
      dispatch(setDrawer({ 
        open: true, 
        content: 
          <Modules.AddRefinanced 
            ourDeal={props.ourDeal}
            setOurDeal={props.setOurDeal} 
            isActive={props.isActive}
            onFinish={() => actionCloseDrawer()} />,
        width: "1000px" }));
    }

    const addPSProducts = () => {
      dispatch(setDrawer({ 
        open: true, 
        content: 
          <Modules.ImportPSDrawer
            ourDeal={props.ourDeal}
            setOurDeal={props.setOurDeal}
            isActive={props.isActive}
            onClose={() => actionCloseDrawer()} />,
        width: "1000px" }));
    }
    
    const closeProductConfigurator = async (opts) => {
        switch(true) {
          case opts === 0:
            setUpdateNo(i => i + 1);
            props.setProductConfigurationModal({ open: false, type: null, product: null, dealProductId: null });
            props.setRefreshData(props.refreshData ? false : true);
            document.body.style.overflow = 'auto';
            break;
          case opts === 1:
            props.setProductConfigurationModal({ open: false, type: null, product: null, dealProductId: null });
            props.setRefreshData(props.refreshData ? false : true);
            document.body.style.overflow = 'auto';
            break;
          default:
            break;
        }
    }

    const openProductConfigurator = (opts, product, dealProductId, portalProductId) => { 
      props.setProductConfigurationModal({ open: true, type: opts, product: product, dealProductId: dealProductId, portalProductId: portalProductId });
      document.body.style.overflow = 'hidden';
    }

    const openDeleteMulti = (productTickedEvent) => { 
      dispatch(setDrawer({ 
        open: true, 
        content: 
          <DeleteMultiDrawer 
            ourDeal={props.ourDeal} 
            setOurDeal={props.setOurDeal}
            refreshData={props.refreshData}
            setRefreshData={props.setRefreshData}
            productTickedEvent={productTickedEvent}
            isActive={props.isActive}
            onCancel={closeDeleteMulti}
            onFinish={async () => { 
              props.setRefreshData(!props.refreshData);
              closeDeleteMulti(); 
            }} />,
        width: "18w" }));
    }

    const closeDeleteMulti = async () => { 
      props.setRefreshData(!props.refreshData);
      dispatch(setDrawer({ open: false, content: null })); 
    }


    const openCloneDrawer = (product) => { 
      dispatch(setDrawer({ 
        open: true, 
        content: 
          <CloneProductDrawer 
            ourDeal={props.ourDeal} 
            setOurDeal={props.setOurDeal} 
            refreshData={props.refreshData}
            setRefreshData={props.setRefreshData}
            product={product}
            isActive={props.isActive}
            onCancel={closeCloneDrawer}
            onFinish={async () => { 
              props.setRefreshData(props.refreshData ? false : true);
              closeCloneDrawer(); 
            }} />,
        width: "18w" }));
    }

    const closeCloneDrawer = async () => { 
      props.setRefreshData(props.refreshData ? false : true);
      dispatch(setDrawer({ open: false, content: null })); 
    }

    const openProductBindDrawer = async (product) => { 
      dispatch(setDrawer({ 
        open: true, 
        content: 
          <BindExistingProductDrawer 
            ourDeal={props.ourDeal} 
            setOurDeal={props.setOurDeal} 
            product={product}
            isActive={props.isActive}
            onCancel={() => dispatch(setDrawer({ open: false, content: null }))}
            onFinish={async () => { 
              props.setRefreshData(props.refreshData ? false : true);
              closeProductBindDrawer(); 
            }} />,
        width: "18w" }));
    }

    const closeProductBindDrawer = async () => { 
      props.setRefreshData(props.refreshData ? false : true);
      dispatch(setDrawer({ open: false, content: null })); 
    }

    let headerClasses = {
        class: props.fieldWorkflowObj.hidePanelRight ? "col-xs-12 col-sm-12 col-md-4 mar-b15" : "col-xs-12 col-sm-12 col-md-4 mar-b15"
    }

    
    return <Fragment> 
        {props.productConfigurationModal.open && <UILIB.ProductConfigurationModal 
            onFinish={() => closeProductConfigurator(0)} 
            onCancel={() => closeProductConfigurator(1)}
            ourDeal={props.ourDeal}
            setOurDeal={props.setOurDeal}
            hostConfig={props.ourDeal.hostConfig}
            product={props.productConfigurationModal.product}
            options={props.productConfigurationModal.type}
            dealProductId={props.productConfigurationModal.dealProductId}
            portalProductId={props.productConfigurationModal.portalProductId} />}

        <div style={{ minHeight: "300px" }} className={headerClasses.class}>
          <CRMDetails  
            ourDeal={props.ourDeal}
            setOurDeal={props.setOurDeal}
            account={props.account}
            isActive={props.isActive}
            setLocalEvent={props.setLocalEvent}
            updateDealDataType={2} />
        </div>
        <div style={{ minHeight: "300px" }} className={headerClasses.class}>
          <CoreDetails  
            activeFields={props.activeFields}
            ourDeal={props.ourDeal}
            setOurDeal={props.setOurDeal}
            setLocalEvent={props.setLocalEvent}
            isActive={props.isActive}
            fieldWorkflowObj={props.fieldWorkflowObj}
            setFieldWorkflowObj={props.setFieldWorkflowObj}
            fieldWorkflow={props.fieldWorkflow}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            setRefreshData={props.setRefreshData}
            setCpcDisabled={setCpcDisabled}
          />
        </div> 
        <div style={{ minHeight: "300px" }} className={headerClasses.class}>     
          <LeaseDetails  
            activeFields={props.activeFields}
            ourDeal={props.ourDeal}
            setOurDeal={props.setOurDeal}
            setLocalEvent={props.setLocalEvent}
            isActive={props.isActive}
            fieldWorkflowObj={props.fieldWorkflowObj}
            formErrors={formErrors}
          />
        </div> 
        <div className="col-xs-12">
          <UILIB.Paper className="mar-b15">
            <AddressesFrontPage 
              ourDeal={props.ourDeal} 
              setOurDeal={props.setOurDeal} 
              dataLoaded={props.dataLoaded} 
              isActive={props.isActive}
              refreshData={props.refreshData} 
              setRefreshData={props.setRefreshData} 
              maxRows={1} />
          </UILIB.Paper>
        </div>
        <div className="col-xs-12">
           <UILIB.Paper className="mar-b15" style={{ paddingRight: 0 }}>
                <div className="flex-container row mar-l10 mar-r10 mar-b10">
                  <div className="flex-item start flex-grow-1 align-center text-16 font-weight-bold">Products</div>
                  <div className="flex-item end align-center">
                    <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.product} className="small mar-r10" value="Product Configurator" onClick={() => openProductConfigurator(0, null, null)} />
                    <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.product}  className="small" value="Add PS Lines" onClick={addPSProducts} />
                    <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.product} className="small" value="Add Refinanced" onClick={addRefinancedProducts} />
                    <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.settlement || !props?.dataLoaded?.product}  className="small" value="Add Settlement" onClick={addSettlementProducts} />
                    <UILIB.Button disabled={!props.isActive || !props?.dataLoaded?.product} className="small" value="Add Blank Product" onClick={addBlankProduct} />
                  </div>
                </div>
                {String(props.ourDeal.compatibles.length) && <div className="mar-r10"><ProductsTable
                  dealID={props.ourDeal.dealID} 
                  ourDeal={props.ourDeal}
                  setOurDeal={props.setOurDeal}
                  updateNo={updateNo} 
                  isActive={props.isActive}
                  refreshData={props.refreshData} 
                  setRefreshData={props.setRefreshData} 
                  dealAddress={props.dealAddress}
                  localEvent={props.localEvent}
                  setLocalEvent={props.setLocalEvent}
                  openProductConfigurator={openProductConfigurator}
                  openProductBindDrawer={openProductBindDrawer}
                  dealLoaded={props.dealLoaded}
                  productValue={props.productValue}
                  productTickedEvent={props.productTickedEvent}
                  openCloneDrawer={openCloneDrawer}
                  openDeleteMulti={openDeleteMulti}
                  hostConfig={props.ourDeal.hostConfig} 
                  cpcDisabled={cpcDisabled}
                  fieldWorkflowObj={props.fieldWorkflowObj} 
                  formErrors={formErrors} /></div>}
            </UILIB.Paper>
        </div>
        <div className="row width-100">
          {hasQuantity && <div className="width-100 mar-8">
            <UILIB.BannerContainer background={'colour background-2 royalBlue'} title={'Reminder'} content={bannerMessage} /> 
          </div>}
          <div className="col-xs-12 col-sm-4 mar-b15">
            <SingleStatCard header={'Sale Price'} stat={props.ourDeal.pricing.total} />
          </div>
          <div className="col-xs-12 col-sm-4 mar-b15">
            <SingleStatCard header={'Cost'} stat={props.ourDeal.pricing.cost} />
          </div>
          <div className="col-xs-12 col-sm-4 mar-b15">
            <SingleStatCard header={'GP'} stat={props.ourDeal.pricing.gp} />
          </div>
        </div>
    </Fragment >
}