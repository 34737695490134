import { Fragment, useState, useRef, useEffect } from "react";
import { useDispatch } from 'react-redux';
import UILIB from 'components';
import Axios from 'classes/axios';
import { setFilterVars, tableContainer, actionOpenNotesDrawer, getYesNo } from '../functions';
import Modules from '../refurb/modules';
import { formatDateTime } from "classes/format";

export default function RefurbDevicesTable({filter, selected}) {

  const dispatch = useDispatch();
  const defaultDeleteDialogue = { refurbIds: [], count: 0, show: false };
  const defaultUnReserveDialogue = { Id: '', serialNo: '', show: false };
  const [customers, setCustomers] = useState([]);
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(defaultDeleteDialogue);
  const [showUnReserveDialogue, setShowUnReserveDialogue] = useState(defaultUnReserveDialogue);
  const [deleting, setDeleting] = useState(false);
  const [unReserving, setUnReserving] = useState(false);
  const [localRender, setLocalRender] = useState(false);


// Refurb Table Headers
 const baseHeaderData = [
  { label: '', value: 'refurbID', type: 'delete', filtering: false, minWidth: 30, maxWidth: 30 },
  { label: 'Location', value: 'deviceLocation', type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
  { label: 'Make', value: 'make', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
  { label: 'Model', value: 'model', type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
  { label: 'Serial No', value: 'serialNumber', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
  { label: 'Attached', value: 'attachments', type: 'string', filtering: true, minWidth: 220, maxWidth: 220 },
  { label: 'Mono Reading', value: 'monoReading', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
  { label: 'Colour Reading', value: 'colourReading', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
  { label: 'Value', value: 'value', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
 ]

 const reservedHeaderData = [
  { label: 'Reserved For', value: 'customerName', type: 'string', filtering: true, minWidth: 150, maxWidth: 100 },
  { label: 'Reserved By', value: 'requestName', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
  { label: 'Date Reserved', value: 'dateReserved', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
  { label: 'Auto Un-Reserve in', value: 'reserveDays', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
  { label: 'Action', value: 'action', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
  { label: "Notes", value: "notes", type: "string", filtering: false, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
 ]

 const soldHeaderData = [
  { label: 'Sold To', value: 'customerName', type: 'string', filtering: true, minWidth: 150, maxWidth: 100 },
  { label: 'Sold By', value: 'requestName', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
  { label: 'Date Sold', value: 'dateSold', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
  { label: "Notes", value: "notes", type: "string", filtering: false, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
 ]

  const entityData = useRef({});
  const tablePageDefaults = { deleteEnabled: true, paging: { pages: [25, 50, 75, 100, 300], pageSelected: 20, limit: 20, offset: 0, orderBy: filter.button === 'Reserved' ? 'reserveDays' : 'deviceLocation', orderDir: 'ASC' } };
  const [remoteRender, setRemoteRender] = useState(false);

  let refurbTableHeader; 

  if(filter.button === 'Reserved')
    refurbTableHeader = baseHeaderData.concat(reservedHeaderData);
  else if(filter.button === 'Sold')
    refurbTableHeader = baseHeaderData.concat(soldHeaderData);
  else 
    refurbTableHeader = baseHeaderData;

  useEffect(() => {
    const actionAsync = async () => { 
      const customersResult = await getCustomers();
      const customersMapped = customersResult.map(customer => { return { label: customer.Name, value: customer.Id } });
      setCustomers(customersMapped); 
    }
    actionAsync();
  }, []);

  const getCustomers = async(name, token = {}) =>  {
    const results = await Axios.get(`/entities/customers/?&$filter=AltName like ${name}&$select=id and AltName`, token).then(api => api.data.result);
    return results.map(x => { return { ...x, label: x.AltName, customerName: x.AltName, value: x.id } });
  }

  const tableUnReserveRecord = async (refurbId, serialNo) => {
    setShowUnReserveDialogue({ refurbId, serialNo, show: true });
  };

  const tableDeleteRefurb = async (event) => {
    setShowDeleteDialogue({ refurbIds: event, count: event.length, show: true });
  };

  const actionDeleteRefurb = async (refurbIds) => {
    setDeleting(true);
    await Axios.delete(`/entities/serviceAdmin_refurbs`, { data: { refurbIds: refurbIds } });
    entityData.current.result = entityData.current.result.filter(x => !refurbIds.map(m => Number(m)).includes(Number(x.id)));
    setShowDeleteDialogue(defaultDeleteDialogue);
    setDeleting(false);
    setLocalRender(!localRender);
  };

  const actionUnReserveRefurb = async (data, field) => {
    setUnReserving(true);
    const refurbID = data.refurbID;
    //const baseData = { tonerID: tonerId, rowVersion: tonerResult.result[0].rowVersion, isSnoozed: 0, isSnoozedQueued: 0, tonerReleaseDate: moment().format("YYYY-MM-DD HH:mm:ss"), daysLeft: null, snoozedBy: null, snoozeUntilDate: null };

    console.log(data, field);
    //id: 2787, AltName: '20187 (TLT LLP)', label: '20187 (TLT LLP)', customerName: '20187 (TLT LLP)', value: 2787

    //await Axios.put(`/entities/serviceAdmin_refurbs/${refurbID}`, { entity: baseData })

    // await Axios.delete(`/entities/serviceAdmin_refurbs`, { data: { refurbIds: refurbIds } });
    // entityData.current.result = entityData.current.result.filter(x => !refurbIds.map(m => Number(m)).includes(Number(x.id)));
    // setShowDeleteDialogue(defaultDeleteDialogue);
    setUnReserving(false);
    setLocalRender(!localRender);
  };

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
    const expandArray = ['notes','attachments','meters'];
    const queryLocal = query !== null ? query : '';
    const pagingLocal = limit !== null && offset !== null ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = orderBy !== null && orderDir !== null ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const filterButtons = setFilterVars(filter);
    const buttonAppend = (filter.button.length) && (filterButtons !== '') ? ` ${filterButtons} ` : '';
    const storeSelected = (selected.length) ? ` storeID in ${selected.join(',')} ` : '';
    entityData.current = await Axios.get(`/entities/serviceAdmin_refurbs/getSummary/?&$filter=${[buttonAppend, storeSelected].filter(x => x).join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }; 

  async function constructTable(refurbDevices) {
    const tableData = refurbDevices.map(row => {

      const b_hasNotes = Boolean(row.notes && row.notes.length);
      const attachments = <Modules.Attachments row={row} />
      const monoReadings = <Modules.MonoReadings row={row} />
      const colourReadings = <Modules.ColourReadings row={row} />
      const fn_notes = actionOpenNotesDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: remoteRender, set: setRemoteRender });
      const notesColour = (b_hasNotes) ? 'colour colour background-6 red' : 'colour lightGrey';
      const unReserve = <UILIB.TableContainer data={<UILIB.Button value="Un-Reserve" className="table small red" onClick={async () => await tableUnReserveRecord(row.refurbID, row.serialNumber)}/>} className="width-100 height-100" />

      return {
        refurbID: { value: row.refurbID, raw: row.refurbID },
        deviceLocation: {value: row.deviceLocation, raw: row.deviceLocation},
        make: {value: row.make, raw: row.make},
        model: {value: row.model, raw: row.model},
        serialNumber: {value: row.serialNumber, raw: row.serialNumber},
        attachments: {value: attachments, raw: attachments},
        monoReading: {value: monoReadings, raw: monoReadings},
        colourReading: {value: colourReadings, raw: colourReadings},
        value: {value: '£' + row.value, raw: row.value},
        customerName: {value: row.customerName, raw: row.customerName},
        requestName: {value: row.requestName, raw: row.requestName},
        dateReserved: {value: formatDateTime(row.dateReserved, "DD/MM/YYYY"), raw: row.dateReserved},
        reserveDays: {value: `${row.reserveDays} days`, raw: row.reserveDays},
        dateSold: {value: formatDateTime(row.dateSold, "DD/MM/YYYY"), raw: row.dateSold},
        action: {value: unReserve, raw: row.unreserveforcustomer},
        notes: { value: tableContainer(fn_notes, 'icon-pencil', 'View Notes', notesColour, true), raw: b_hasNotes }
      };
    });
    return tableData;
  }

  return (
      <Fragment>
        {showDeleteDialogue.show && <UILIB.MessageBox 
        header={'Delete Records'} 
        loading={deleting}
        text={`Click OK to DELETE the selected ${(showDeleteDialogue.count) > 1 ? 'Records' : 'Record'}`} 
        onConfirm={async () => await actionDeleteRefurb(showDeleteDialogue.refurbIds)} 
        onCancel={() => setShowDeleteDialogue({ ...showDeleteDialogue, show: false })} />}
        {showUnReserveDialogue.show && <UILIB.MessageBox 
        header={'Un-Reserve'} 
        loading={unReserving}
        text={`Click to un-reserve ${(showUnReserveDialogue.serialNo)}`} 
        onConfirm={actionUnReserveRefurb} 
        onCancel={() => setShowUnReserveDialogue({ ...showUnReserveDialogue, show: false })} 
        />}
      <div className="flex-container row mar-b15 mar-l5 mar-r5 mar-t10">
      <UILIB.Paper className="width-100">
        <div>
          <UILIB.TableNew 
            name="failedEmailList" 
            className="small" 
            overflowX="auto" 
            overflowY="hidden" 
            header={refurbTableHeader} 
            localQuery={() => constructTable(entityData.current && entityData.current.result ? entityData.current.result : [])}
            localRender={[localRender]}
            remoteQuery={getTableData} 
            remoteRender={[selected, filter, remoteRender]} 
            defaultSettings={tablePageDefaults} 
            deleteQuery={async (ev) => await tableDeleteRefurb(ev)}
            />
        </div>
      </UILIB.Paper>
    </div>
  </Fragment>
  )
}