import { Fragment } from 'react';
import UILIB from 'components';
import ActionPassBot from '../actionPassBot';


export default function RobotDrawer({ entity, onClose, sync }) 
{  

  const robotLog = (entity && entity.tonerRobotLog && entity.tonerRobotLog.length) 
    ? entity.tonerRobotLog.replace(/<br\/>/g, '\n').trim()
    : '';

  const actionButtonPressed = (object) => {
    sync.data.result = sync.data.result.filter(x => Number(x.tonerID) !== Number(object.tonerID));
    sync.set(!sync.get);
    onClose(); 
  }

  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className={`flex-item start width-100`}>
        <h2>Toner Robot Log for {entity.serialNumber}</h2>
      </div>
      <div className={`flex-item end width-100`}>
        <UILIB.NoteContainer type={'Robot Log Event'} createdBy={entity.fromAlertType} updatedAt={entity.CheckedByBotDate} message={robotLog} />
      </div>
      <ActionPassBot records={{ [entity.tonerID]: true }} callBack={() => actionButtonPressed(entity)} />
    </div>
  </Fragment>
}
