import { useState, useRef, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatDateTime } from 'classes/format';
import DealLinkTableCell from '../../shared/dealLink';
import { setFilterVars, calcResetEnable, actionOpenDrawer, getStatusChip } from '../functions';
import Modules from '../../sophub/Modules';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function BlockQuotesTable({ location, route, regions, staff, filters, setFilters, defaultfilters, context }) {

  const headerData = [
    { label: "#", value: "finalDealNo", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Opp #", value: "opportunityNumber", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Account #", value: "accountNo", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Customer", value: "companyName", type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
    { label: "Sales Person / Region", value: "salesPersonSalesRegion", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Description", value: "description", type: "string", filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Deal Type", value: "salesType", type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Payment Type", value: "leaseorcash", type: "string", filtering: true, minWidth: 90, maxWidth: 90 },
    { label: "Value", value: "valueStats", type: 'string', split: ['invoice', 'GP'], filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Created On", value: "createdAtConvert", type: 'date', filtering: true, minWidth: 110, maxWidth: 110 },
    { label: "Status", value: "orderStatus", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 }];
    
  const dispatch = useDispatch();
  const history = useHistory();
  const entityData = useRef({});
  const pageContext = context.context;
  const [render, setRender] = useState(false);
  const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'finalDealNo', orderDir: 'DESC' } };
  const selectData = { regions: regions, staff: staff };

  const handleResetfilters = () => {
    setFilters(defaultfilters);
    setRender(!render);
  };

  const handleButtonsChanged = (buttonName) => {
    const newTableFilter = { ...filters };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = null;
    } else {
        newTableFilter.button = buttonName;
    }
    setFilters(newTableFilter);
  };

  async function constructTable(quoteData) {

    const tableData = quoteData.map(row => {
      return {
        finalDealNo: DealLinkTableCell(row, history, route, true, true, 'class'),
        opportunityNumber: { value: row.opportunityNumber, raw: row.opportunityNumber },
        accountNo: { value: row.accountNo, raw: row.accountNo },
        groupCode: { value: row.groupCode, raw: row.groupCode },
        companyName: { value: row.companyName, raw: row.companyName },
        salesPersonSalesRegion: { value: row.salesPersonSalesRegion, raw: row.salesPersonSalesRegion },
        description: { value: row.description, raw: row.description },
        salesType: { value: row.salesType, raw: row.salesType },
        leaseorcash: { value: row.leaseorcash, raw: row.leaseorcash },
        valueStats: { value: <Modules.PricingContainer row={row} />, raw: [row.pricing.total, row.pricing.gp] },
        createdAtConvert: { value: formatDateTime(row.createdAtConvert), raw: row.createdAtConvert },
        orderStatus: { value: getStatusChip(row), raw: row.orderStatus } }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const calcFilters = setFilterVars(filters);
    const expandArray = ['pricing'];

    entityData.current = await Axios.get(`/entities/deals/getSummary/?&$filter=${[calcFilters].join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    if(!entityData.current || !entityData.current.result.length) return false;

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }

  return <Fragment>
    <UILIB.Paper>
      <div className="flex-container row mar-b10">
        <div className="flex-item flex-grow-1 start wrap">
          <UILIB.Button className={"mar-l5 mar-r5 small " + (!filters.button || filters.button === "Active" ? "primary" : "secondary")} name="Active" value="Active" onClick={(ev) => handleButtonsChanged(ev.target.name)} />
          <UILIB.Button className={"mar-l5 mar-r5 small " + (!filters.button || filters.button === "Ordered" ? "primary" : "secondary")} name="Ordered" value="Ordered" onClick={(ev) => handleButtonsChanged(ev.target.name)} />
          <UILIB.Button className={"mar-l5 mar-r5 small " + (!filters.button || filters.button === "Closed" ? "primary" : "secondary")} name="Closed" value="Closed" onClick={(ev) => handleButtonsChanged(ev.target.name)} />
          <UILIB.Button className={"mar-l5 mar-r5 small " + (!filters.button || filters.button === "Archived" ? "primary" : "secondary")} name="Archived" value="Archived" onClick={(ev) => handleButtonsChanged(ev.target.name)} />
          <UILIB.Button className={"mar-l5 mar-r5 small " + (!filters.button || filters.button === "Lost" ? "primary" : "secondary")} name="Lost" value="Lost" onClick={(ev) => handleButtonsChanged(ev.target.name)} />
        </div>
        <div className='flex-item end'>
          <UILIB.Button disabled={calcResetEnable(filters, defaultfilters, location)} className={"mar-l20 mar-r5 small green"} name="resetFilters" value="Reset Filters" onClick={() => handleResetfilters()} />
          <UILIB.Button value="Filters" onClick={() => { actionOpenDrawer('QuoteFiltersDrawer', { ...selectData, filters: filters, setFilters: setFilters }, dispatch, true, { get: render, set: setRender }, '400px') }} />
        </div>
      </div>  
      <UILIB.TableNew
        name={`BlockQuotes-${pageContext}`}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        remoteQuery={getTableData}
        remoteRender={[filters.button, render, pageContext]}
        defaultSettings={tablePageDefaults} />
    </UILIB.Paper>   
  </Fragment>
}