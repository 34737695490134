import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Modules from './Modules';
import UILIB from 'components';


export default function CustomerSiteStockRequests() {

  const history = useHistory();

  return <Fragment>
    <div className="flex-container column height-100 mar-t10 mar-r5 mar-l5">
      <UILIB.Paper className='flex-item row width-100 mar-b20'>
        <div className='flex-item start width-100 mar-b20'><h3>Site Stock Requests</h3></div>
        <div className='flex-item start flex-grow-1'>
          <UILIB.Button value="Back" className="grey" onClick={() => history.push('/consumablesHub')} />
        </div>
      </UILIB.Paper>
      <Modules.SiteStocksRequests />
    </div>
  </Fragment>
}