import { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onClickAway = () => { }) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickAway()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
        // eslint-disable-next-line
    }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function ClickAway(props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, props.onClickAway);

    return <div style={{ width: '100%' }} ref={wrapperRef}>{props.children}</div>;
}