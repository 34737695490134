import DraftsTable from './modules/draftsTable';
import QuotesTable from './modules/quotesTable';
import NotesAdd from '../../shared/drawers/notesAdd';
import NotesDrawer from '../../shared/drawers/notesDrawer';
import NotesView from '../../shared/drawers/notesView';
import PatchCRM from './modules/patchCRM';
import DealHeader from './modules/dealHeader';
import AddRefinanced from './modules/addRefinanced';
import ViewMfiles from './modules/viewMfiles';
import OrderSubmitModal from './modules/orderSubmitModal';
import AddAdditionalQuote from './modules/addAdditionalQuote';
import OrderApproveModal from './modules/orderApproveModal';
import OrderDeclineDrawer from './modules/orderDeclineDrawer';
import QuoteCopyDrawer from './modules/quoteCopyDrawer';
import OrderCopyDrawer from './modules/orderCopyDrawer';
import OrderDeleteDrawer from './modules/orderDeleteDrawer';
import ImportPSDrawer from './modules/importPSDrawer';
import KeyContactsDrawer from'./modules/keyContactsDrawer';
import KeyContactSelectDrawer from './modules/keyContactSelectDrawer';
import AddSettlementLine from './modules/addSettlementLine';


const exported = {
  DraftsTable,
  QuotesTable,
  NotesAdd,
  NotesDrawer,
  NotesView,
  PatchCRM,
  DealHeader,
  AddRefinanced,
  ViewMfiles,
  OrderSubmitModal,
  AddAdditionalQuote,
  OrderApproveModal,
  OrderDeclineDrawer,
  QuoteCopyDrawer,
  OrderCopyDrawer,
  OrderDeleteDrawer,
  ImportPSDrawer,
  KeyContactsDrawer,
  KeyContactSelectDrawer,
  AddSettlementLine
}

export default exported;