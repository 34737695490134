import { Fragment } from 'react';
import { useHistory } from 'react-router-dom'
import { formatDateTime } from 'classes/format';
import Axios from 'classes/axios';
import UILIB from 'components';
import { handleSerialClicked } from '../functions';

export default function ConsumablesLookUpSerialNumberDetails({ customerId, setSearchActioned }) {
  const headerData = [
    { label: 'Serial', value: 'serialNumber', type: 'string', filtering: true },
    { label: 'Description', value: 'description', type: 'string', filtering: true },
    { label: 'Last Scanned', value: 'lastScanned', type: 'date', filtering: true },
    { label: 'Source', value: 'readingSource', type: 'string', filtering: true },
    { label: 'Black', value: 'st_blackPercentage', type: 'string', filtering: true },
    { label: 'Cyan', value: 'st_cyanPercentage', type: 'date', filtering: true },
    { label: 'Magenta', value: 'st_magentaPercentage', type: 'date', filtering: true },
    { label: 'Yellow', value: 'st_yellowPercentage', type: 'date', filtering: true }];

  const history = useHistory();
  const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 20, limit: 20, offset: 0, orderBy: 'lastScanned', orderDir: 'DESC' } };

  async function constructTable(data) {
    const tableData = data.map(row => {
        return {
          serialNumber: { value: handleSerialClicked(row, history, null), raw: row.serialNumber },
          description: { value: row.description, raw: row.description },
          lastScanned: { value: formatDateTime(row.lastScanned, 'DD/MM/YY'), raw: row.lastScanned },
          readingSource: { value: row.readingSource, raw: row.readingSource },
          st_blackPercentage: { value: row.st_blackPercentage, raw: row.st_blackPercentage },
          st_cyanPercentage: { value: row.st_cyanPercentage, raw: row.st_cyanPercentage },
          st_magentaPercentage: { value: row.st_magentaPercentage, raw: row.st_magentaPercentage },
          st_yellowPercentage: { value: row.st_yellowPercentage, raw: row.st_yellowPercentage }
        }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
    const apiData = {};
    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    apiData.productResult = await Axios.get(`/entities/equipments/getSummaryCustomerDetails/${customerId}/?&$filter=${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);
    return { tableData: await constructTable(apiData.productResult.result), nonePaged: apiData.productResult.nonePaged };
  }


  return <Fragment>
    <UILIB.Paper className='width-100'>
        <div className='flex-container mar-b10'>
          <h3 className='flex-item flex-grow-1 start align-center mar-b0'>Customer Details</h3>
          <UILIB.Button name='hide' value='Hide' onClick={() => { setSearchActioned(false) }} />
        </div>
        <UILIB.TableNew
          name="CustomerDetails"
          className='small'
          overflowX='auto'
          overflowY='hidden'    
          header={headerData}
          remoteQuery={getTableData}
          remoteRender={[customerId]}
          defaultSettings={tablePageDefaults} />
    </UILIB.Paper>
  </Fragment>
}