import { Fragment } from 'react';
import UILIB from 'components';

export default function ConsumablesLookUpScaler({ setMonths }) {
  return <Fragment>
      <UILIB.Paper className='width-100'>
        <div className='flex-container row'>
          <div className='flex-item align-center mar-r20'><h3 className='mar-b0'>Graph History (months)</h3></div>
          <div className='flex-item mar-r5'><UILIB.Button name='months' value='1' onClick={() => setMonths(1)} /></div>
          <div className='flex-item mar-r5'><UILIB.Button name='months' value='3' onClick={() => setMonths(3)} /></div>
          <div className='flex-item mar-r5'><UILIB.Button name='months' value='6' onClick={() => setMonths(6)} /></div>
          <div className='flex-item mar-r5'><UILIB.Button name='months' value='9' onClick={() => setMonths(9)} /></div>
          <div className='flex-item mar-r5'><UILIB.Button name='months' value='12' onClick={() => setMonths(12)} /></div>
          <div className='flex-item mar-r5'><UILIB.Button name='months' value='18' onClick={() => setMonths(18)} /></div>
          <div className='flex-item mar-r5'><UILIB.Button name='months' value='24' onClick={() => setMonths(24)} /></div>
          <div className='flex-item mar-r5'><UILIB.Button name='months' value='36' onClick={() => setMonths(36)} /></div>
        </div>     
      </UILIB.Paper>
  </Fragment>
}