import { useState, Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import Axios from 'classes/axios';
import UILIB from 'components';

export default function MeterHistoryTable({ serialNumber }) {
    const headerDataSimple = [
      { label: 'Date', value: 'statsDate', type: 'date', filtering: true },
      { label: 'Black', value: 'st_BlackPercentage', type: 'string', filtering: true },
      { label: 'Cyan', value: 'st_CyanPercentage', type: 'string', filtering: true },
      { label: 'Magenta', value: 'st_MagentaPercentage', type: 'string', filtering: true },
      { label: 'Yellow', value: 'st_YellowPercentage', type: 'string', filtering: true },
      { label: 'Total', value: 'st_TotalPages', type: 'string', filtering: true },
      { label: 'Total Mono', value: 'st_TotalMonoPages', type: 'string', filtering: true },
      { label: 'Total Colour', value: 'st_TotalColourPages', type: 'string', filtering: true }];

    const headerSource = [
      { label: 'Source', value: 'readingSource', type: 'string', filtering: true }];

    const headerDataExtended = [
      { label: 'A4M', value: 'st_TotalA4MonoScan', type: 'string', filtering: true },
      { label: 'A4C', value: 'st_TotalA4ColourScan', type: 'string', filtering: true },
      { label: 'A3M', value: 'st_TotalA3MonoScan', type: 'string', filtering: true },
      { label: 'A3C', value: 'st_TotalA3ColourScan', type: 'string', filtering: true },
      { label: '101', value: 'st_101', type: 'string', filtering: true },
      { label: '103', value: 'st_103', type: 'string', filtering: true },
      { label: '106', value: 'st_106', type: 'string', filtering: true },
      { label: '105/125', value: 'st_105', type: 'string', filtering: true },
      { label: '108', value: 'st_108', type: 'string', filtering: true },
      { label: '109', value: 'st_109', type: 'string', filtering: true },
      { label: '112', value: 'st_112', type: 'string', filtering: true },
      { label: '113', value: 'st_113', type: 'string', filtering: true },
      { label: '122', value: 'st_122', type: 'string', filtering: true },
      { label: '123', value: 'st_123', type: 'string', filtering: true },
      { label: '124', value: 'st_124', type: 'string', filtering: true },
      { label: '301', value: 'st_301', type: 'string', filtering: true },
      { label: 'Mono Dev', value: 'st_blackDevelopment', type: 'string', filtering: true },
      { label: 'Colour Dev', value: 'st_colorDevelopment', type: 'string', filtering: true }];

    const simple = 'simple';
    const extended = 'extended';
    const tablePageDefaults = { paging: { limit: 10, offset: 0, orderBy: 'statsDate', orderDir: 'DESC' } };
    const [simpleOrExtended, setSimpleOrExtended] = useState(simple);

    const headerData = (simpleOrExtended === simple)
      ? headerDataSimple.concat(headerSource)
      : headerDataSimple.concat(headerDataExtended, headerSource);

      async function constructTable(data) {
        const tableData = data.map(row => {
          const simpleData = {
            statsDate: { value: formatDateTime(row.statsDate, 'DD/MM/YY'), raw: row.statsDate },
            st_BlackPercentage: { value: row.st_BlackPercentage, raw: row.st_BlackPercentage },
            st_CyanPercentage: { value: row.st_CyanPercentage, raw: row.st_CyanPercentage },
            st_MagentaPercentage: { value: row.st_MagentaPercentage, raw: row.st_MagentaPercentage },
            st_YellowPercentage: { value: row.st_YellowPercentage, raw: row.st_YellowPercentage },
            st_TotalPages: { value: row.st_TotalPages, raw: row.st_TotalPages },
            st_TotalMonoPages: { value: row.st_TotalMonoPages, raw: row.st_TotalMonoPages },
            st_TotalColourPages: { value: row.st_TotalColourPages, raw: row.st_TotalColourPages }
          };

          const source = {readingSource: { value: row.readingSource, raw: row.readingSource }}

          if (simpleOrExtended === simple) {
            return { ...simpleData, ...source };
          }

          return {
            ...simpleData,
            st_TotalA4MonoScan: { value: row.st_TotalA4MonoScan, raw: row.st_TotalA4MonoScan },
            st_TotalA4ColourScan: { value: row.st_TotalA4ColourScan, raw: row.st_TotalA4ColourScan },
            st_TotalA3MonoScan: { value: row.st_TotalA3MonoScan, raw: row.st_TotalA3MonoScan },
            st_TotalA3ColourScan: { value: row.st_TotalA3ColourScan, raw: row.st_TotalA3ColourScan },
            st_101: { value: row.st_101, raw: row.st_101 },
            st_103: { value: row.st_103, raw: row.st_103 },
            st_106: { value: row.st_106, raw: row.st_106 },
            st_105: { value: row.st_105, raw: row.st_105 },
            st_108: { value: row.st_108, raw: row.st_108 },
            st_109: { value: row.st_109, raw: row.st_109 },
            st_112: { value: row.st_112, raw: row.st_112 },
            st_113: { value: row.st_113, raw: row.st_113 },
            st_122: { value: row.st_122, raw: row.st_122 },
            st_123: { value: row.st_123, raw: row.st_123 },
            st_124: { value: row.st_124, raw: row.st_124 },
            st_301: { value: row.st_301, raw: row.st_301 },
            st_blackDevelopment: { value: row.st_blackDevelopment, raw: row.st_blackDevelopment },
            st_colorDevelopment: { value: row.st_colorDevelopment, raw: row.st_colorDevelopment },
            ...source
          }
        })
        
        return tableData;
      }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
      const queryLocal = (query !== null && query !== 'and ') ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const customerMachinesStatsNResult = await Axios.get(`/entities/customerMachinesStatsNs?$filter=st_SerialNumber eq ${serialNumber} ${queryLocal}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      return { tableData: await constructTable(customerMachinesStatsNResult.result), nonePaged: customerMachinesStatsNResult.nonePaged };
    }

    return <Fragment>
        <UILIB.Paper className='width-100'>
            <div className='flex-container wrap mar-b10'>
              <div className='flex-item flex-grow-1 start align-center'><h3 className='mar-b0'>Meter History - {`${simpleOrExtended}`}</h3></div>
              <div className='flex-item end wrap'>
                <UILIB.Button className='mar-l5 mar-r5 small align-right' name='Extended' value='Extended' onClick={() => setSimpleOrExtended(extended)} />
                <UILIB.Button className='mar-l5 mar-r5 small align-right' name='Simple' value='Simple' onClick={() => setSimpleOrExtended(simple)} />
              </div>
            </div>
            <UILIB.TableNew
              name="MeterHistory"
              className='small'
              overflowX='auto'
              overflowY='hidden'    
              header={headerData}
              remoteQuery={getTableData}
              remoteRender={[simpleOrExtended, serialNumber]}
              defaultSettings={tablePageDefaults} />
        </UILIB.Paper>
    </Fragment>
}