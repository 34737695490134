
import { useEffect, useState, useRef, Fragment } from 'react';
import UILIB from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

export default function PasswordContainer({ value, disabled = true, onChange = async () => { }, onChangeTimout = 500, revealTimout = 6000 }) {

  const [show, setShow] = useState(false);
  const [render, setRender] = useState(false);
  const onChangeEvent = useRef(null);
  const [password, setPassword] = useState(value && Array(value.length).join('*'));

  library.add(fas);
  const fontAwesome = <FontAwesomeIcon title={show ? 'Hide' : 'Show'} icon={`fas ${show ? 'fa-eye-slash' : 'fa-eye'}`}/>
  const inputClassName = "borderlessInput Innerinput flex-item align-center mar-b0" ;
  const inputOuterClassName = "flex-item start align-center flex-grow-1 mar-l5";

  const generateShowHide = (pwd) => {
    if(!show) return pwd && Array(pwd.length).join('*');
    if(show) return pwd;
  }

  const onPasswordChange = (ev) => {
    onChangeEvent.current = { name: ev.target.name, value: ev.target.value };
    setRender(!render);
  }

  useEffect(() => {
    const timer = setTimeout(async () => {
      if(show) await onChange(onChangeEvent.current);
    }, onChangeTimout);
    return () => clearTimeout(timer);
  }, [render])
  
  useEffect(() => {
    setPassword(generateShowHide(value));
    const timer = setTimeout(() => {
      setShow(false);
    }, revealTimout);
    return () => clearTimeout(timer);
  }, [show, render])

  return <Fragment>
          <div className="borderlessInput flex-container row nowrap width-100">
            <UILIB.TextInput disabled={disabled} placeholder="Password" type="string" className={inputClassName} outerClassName={inputOuterClassName} value={password} onChange={(ev) => onPasswordChange(ev)}/>
            <div className="flex-item end align-center mar-l10 mar-r5" onClick={() => setShow(!show)}>{fontAwesome}</div>
          </div>
        </Fragment>
}
