import UILIB from 'components';
import ReactTooltip from 'react-tooltip';

export default function DealLinkTableCell(row, history, routeBase, activeLink, slaStyle, newWindow, styleName, enableCircle = true) 
{
    let dealID = row.finalDealNo;
    if (dealID === undefined) { dealID = row.dealID }

    const style = (styleName) ? styleName : 'tdStyle';

    if (dealID === 'N/A') { return { value: dealID, raw: dealID, [style]: slaStyle } }

    let link = 'unset';
    let dealCircle = <div />;
    if (!activeLink) {  
        link = dealID;
    } else if (row.source === '360') {
        if(enableCircle) dealCircle = <div className='circle background-color-grey min-width-10' title='Created in 360' />;
        link = <UILIB.Link href={`https://360.agilico.co.uk/serviceadmin/submitadeal/deal2.asp?dealID=${row.dealID}`} rel="noreferrer" target="_blank" className="table">{dealID}</UILIB.Link>;
    } else {
        if(enableCircle) dealCircle = <div className='circle min-width-10' title='Created through Sales Hub' />;
        const stageRoute = (row.processStage === 0) ? 'quote' : 'order';
        if (newWindow) {
            link = <UILIB.Link onClick={() => window.open(`${routeBase}/${stageRoute}/${row.dealID}`)} className="table">{dealID}</UILIB.Link>
        } else {
            link = <UILIB.Link onClick={() => history.push({pathname: `${routeBase}/${stageRoute}/${row.dealID}`, state: {route: routeBase}})} className="table">{dealID}</UILIB.Link>;
        }
    }

    return { 
        value: <div title={row.finalDealNo} className='flex-container row nowrap width-100'>{dealCircle}<ReactTooltip effect="solid" />{link}</div>,
        raw: dealID,
        [style]: slaStyle };    
}