import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import Logo from 'assetts/logos/agilicoLogo.png';
import LogoDark from 'assetts/logos/agilicoLogoDark.png';
import UILIB from 'components/index';
import * as Session from "classes/session";


export default function Header() {

    const account = useSelector(state => state.account);
    const [logoutConfirm, setLogoutConfirm] = useState(false);

    const isProduction = (process.env.REACT_APP_ENV_MODE === 'live');
    const agilicoLogo = (isProduction) ? Logo : LogoDark;
    const className = (isProduction) ? 'flex-grow-1' : 'width-30';

    const logoutMessage = `This action will log you out of all open tabs, if this is the desired result please click Ok
                            otherwise click Cancel and close the individual browser tab.`

    return <Fragment>
        {logoutConfirm && <UILIB.MessageBox 
          header={"Confirm Logout"}
          text={logoutMessage}
          onConfirm={() => Session.onLogout(0)}
          onCancel={() => setLogoutConfirm(false)} />}
        <div className={`flex-container row center nowrap header background-${process.env.REACT_APP_ENV_MODE} width-100`}>
          <div className={`flex-item start ${className} mar-l5`}><a href="/" alt="home"><img className="headerLogo max-height-35-px" src={agilicoLogo} alt="home" /></a></div>
          {!isProduction && <div className={'flex-item center align-center width-40 min-width-150'}>
            <div className="flex-container row around nowrap">
              <span className="flex-item center align-center header showModeText modeText">{process.env.REACT_APP_HEADER_NAME}</span>
            </div>
          </div>}
          <div className={`flex-item end ${className}`}>
            <div className="flex-container end row nowrap">
              <div className={'flex-item align-center'}><UILIB.Avatar className="mar-r5" account={account} hideTooltip={true} /></div>
              <div className={'flex-item align-center'}><span className={`header showText accountName-${process.env.REACT_APP_ENV_MODE}`}>{account.displayName}</span></div>
              <div className={'flex-item align-center'}><UILIB.Button className="small" value="Logout" onClick={() => setLogoutConfirm(true)} /></div>
            </div>
          </div>
        </div>
      </Fragment>
}
