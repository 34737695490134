export async function timerPromise(query, timeout, timer, setTimer, setProcessing) {
  return new Promise((resolve, reject) => {
    clearTimeout(timer);
    const timerLocal = setTimeout(async () => {
      try {
        if(setProcessing) setProcessing(true);
        const response = await query(...arguments);
        if(setProcessing) setProcessing(false);
        resolve({ success: true, result: response });
      } catch (error) {
        if(setProcessing) setProcessing(false);
        reject({ success: false, result: error });
      }
    }, timeout);
    setTimer(timerLocal);
  });
}