import { Fragment } from 'react';
import UILIB from 'components';
import AddressesPage from './addresses';
import ContactsPage from './contacts';
import RemoveDevices from './removeEquipment';
import Settlement from './settlement';
import AddNotes from './addNotes';


export default function AdditionalInfoPage(props) {

  const externalNotes = 'External Notes';
  const internalNotes = 'Internal Notes';

  return <Fragment>
    <div className={"col-xs-12 mar-b25 col-xs-12 "}>
      <UILIB.Paper className="mar-b15">
        <AddressesPage 
          ourDeal={props.ourDeal} 
          isActive={props.isActive}
          setOurDeal={props.setOurDeal} 
          dataLoaded={props.dataLoaded}
          setDealAddress={props.setDealAddress}
          formErrors={props.formErrors}
          addressType={0}
          maxRows={6}
        />
      </UILIB.Paper>
      <UILIB.Paper className="mar-b15">
        <h2 className="text-16 mar-t5 mar-l10 float-left">Key Contacts</h2>
        <ContactsPage 
          ourDeal={props.ourDeal} 
          isActive={props.isActive}
          setOurDeal={props.setOurDeal} 
          dataLoaded={props.dataLoaded}
          additionalInfoErrors={props.additionalInfoErrors}
          refreshData={props.refreshData}
        />
      </UILIB.Paper>
      <UILIB.Paper className="mar-b15">
        <h2 className="text-16 mar-t5 mar-l10 float-left">Equipment Removal</h2>
        <RemoveDevices dataLoaded={props.dataLoaded} ourDeal={props.ourDeal} setOurDeal={props.setOurDeal} isActive={props.isActive} />
      </UILIB.Paper>
      <UILIB.Paper className="mar-b15">
        <h2 className="text-16 mar-t5 mar-l10 float-left">Settlement</h2>
        <Settlement dataLoaded={props.dataLoaded} ourDeal={props.ourDeal} setOurDeal={props.setOurDeal} isActive={props.isActive} />
      </UILIB.Paper>
      <UILIB.Paper className="mar-b15">
        <h2 className="text-16 mar-t5 mar-l10 float-left">{externalNotes}</h2>
        <AddNotes dataLoaded={props.dataLoaded} ourDeal={props.ourDeal} context={externalNotes} isActive={props.isActive} />
      </UILIB.Paper>
      <UILIB.Paper className="mar-b15">
        <h2 className="text-16 mar-t5 mar-l10 float-left">{internalNotes}</h2>
        <AddNotes dataLoaded={props.dataLoaded} ourDeal={props.ourDeal} context={internalNotes} isActive={props.isActive} />
      </UILIB.Paper>            
    </div>
  </Fragment>
}

