
import { useState, memo, Fragment } from 'react';
import UILIB from 'components';
import { timerPromise } from "classes/timers";
import moment from "moment";


const GroupContainer = memo(({ data, disabled = false, processing, setProcessing, localRender, setLocalRender, onChange }) => 
{ 

  let lastUpdateBy = null;
  let lastUpdateAt = null;
  let lastEngUpdateBy = null;
  let lastEngUpdateAt = null;

  if(data.updatedByTxt) lastUpdateBy = `Last update: ${(data.updatedByTxt)}`;
  if(!data.updatedByTxt && data.createdByTxt) lastUpdateBy = `Last update: ${(data.createdByTxt)}`;

  if(data.updatedAt) lastUpdateAt = `@${moment(data.updatedAt).format('YYYY/MM/DD HH:mm:ss')}`;
  if(!data.updatedAt && data.createdAt) lastUpdateAt = `@${moment(data.createdAt).format('YYYY/MM/DD HH:mm:ss')}`;

  if(data.lastEngineerUpdatedBy) lastEngUpdateBy = `Last eng update: ${data.lastEngineerUpdatedBy}`;
  if(data.lastEngineerUpdated) lastEngUpdateAt = `@${moment(data.lastEngineerUpdated).format('YYYY/MM/DD HH:mm:ss')}`;

  const userUpdate = (lastUpdateBy && lastUpdateAt) ? `${lastUpdateBy} ${lastUpdateAt}` : '';
  const engUpdate = (lastEngUpdateBy && lastEngUpdateAt) ? `${lastEngUpdateBy} ${lastEngUpdateAt}` : '';

  const inputClass = 'textInput fullBorder tableFilter tableInput mar-b0';

  const [timeout, setTimout] = useState(null);

  const handleUserInput = async (event, row) => {
    row[event.target.name] = event.target.value;
    setLocalRender(!localRender);
    const global = (event.target.name === 'groupName');
    const baseData = { value: event.target.value, field: event.target.name, row: row, global: global };
    await timerPromise(function() { return onChange(baseData); }, 500, timeout, setTimout, setProcessing);
  };

  const generateInput = (fieldName, data, inputClass, defaultValue) => {
    return <UILIB.TextInput 
      disabled={processing || disabled}
      type="string" 
      className={inputClass}
      placeholder={defaultValue} 
      name={fieldName} 
      value={data[fieldName]} 
      onChange={async (ev) => await handleUserInput(ev, data)} />
  };


  return <Fragment>
          <div className="flex-container row flex width-100">
            <div className={`flex-item start consumablesHub groupContainer-input `}>{generateInput('groupName', data, inputClass, 'Enter Group')}</div>
            <div className={`flex-item start flex-grow-1 consumablesHub groupContainer-input `}>{generateInput('locationName', data, inputClass, 'Enter Location')}</div>
            <div className={`flex-item start width-100 consumablesHub groupContainer-text-upper`}>{userUpdate}</div>
            <div className={`flex-item start width-100 consumablesHub groupContainer-text-lower`}>{engUpdate}</div>
          </div>
        </Fragment>
})
export default GroupContainer
