import { Fragment } from 'react';
import UILIB from 'components';
import { useHistory } from 'react-router-dom';
import { getCompanies, getSerials } from 'classes/helpers';

export default function SiteSearch({ entityLookup, setEntityLookup, render, setRender }) {

  const history = useHistory();

  const handleAddSearch = (data, context) => {
    switch(context) {
      case 'customer':
        history.push(`/consumablesHub/customerSiteStock/${context}/${data?.Reference}`);
        setEntityLookup(data);
        setRender(!render);
        break;
      case 'equipment':
        history.push(`/consumablesHub/customerSiteStock/${context}/${data?.SerialNumber}`);
        setEntityLookup(data);
        setRender(!render);
        break;
      default:
        console.info("no incomming context detected");
    }
  }

  return <Fragment>
    <UILIB.Paper className="flex-container row wrap width-100">
      <UILIB.Paper className='flex-item grey flex-grow-1 wrap mar-5'>
        <div className='flex-item align-center start flex-grow-1'>Search for a Customer</div>
        <UILIB.AutoComplete
          className="min-height-50 max-width-400"
          name='customer'
          defaultValue={entityLookup?.altName}
          blank='No Customers Found'
          query={getCompanies}
          enableButton={true}
          onClick={(data) => handleAddSearch(data, 'customer')} />
      </UILIB.Paper>
      <UILIB.Paper className='flex-item grey flex-grow-1 wrap mar-5'>
        <div className='flex-item align-center start flex-grow-1'>Search for a Serial</div>
        <UILIB.AutoComplete
          className="min-height-50 max-width-400"
          name='equipment'
          defaultValue={entityLookup?.SerialNumber}
          blank='No Serials Found'
          query={getSerials}
          enableButton={true}
          onClick={(data) => handleAddSearch(data, 'equipment')} />
      </UILIB.Paper>
    </UILIB.Paper>
  </Fragment>
}