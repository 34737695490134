import { useRef, Fragment } from 'react';


export default function UploadFile({ 
  name = '',
  value = '', 
  title = '',
  className = '', 
  disabled = false, 
  onChange = function () { },
  errored = { option: 1, error: false, message: '' } 
}) {

  const node = useRef(null)
  const classNameLocal = `button ${className}`;
  const errorClass = (errored?.error && errored?.option === 0 && !disabled) ? " errored button outline " : '';

  const actionClick = async (event) => {
    const file = node.current.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    const result = await new Promise((resolve, reject) => {
      reader.onload = function() { resolve(reader) };
      reader.onerror = function(error) { reject(error) };
    });
    onChange({ event, node: node.current, file, reader: result });
  }

  return <Fragment>
    <div className="flex-container row">
      <button value={value} name={name} title={title}  disabled={disabled} type='button' className={`${classNameLocal} ${errorClass}`}>
        <div className="flex-container row center height-100" onClick={() => node.current.click()}>{value}</div>
        <input ref={node} style={{ display: 'none' }} onChange={async (ev) => await actionClick(ev)} type="file"></input>
      </button>
      {errored.error && errored.option === 1 && <div className="errored message width-100">{errored.messageText}</div>}
    </div>
  </Fragment>
}