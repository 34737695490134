export function emptyEntity() {
    return {
        policyholder: '',
        policyCertificateNumber: '',
        sector: '',
        businessOfficeNumber: '',
        insuredVatRegistered: '',
        vatRecoverableOnVehicle: '',
        policyholderAddressLine1: '',
        policyholderAddressLine2: '',
        policyholderAddressTown: '',
        policyholderAddressCounty: '',
        policyholderAddressPostcode: '',
        proportionOfVehicleUseByDriver: '',
        ifNotPolicyholderDidDriverHavePermissionToDrive: '',
        hasDriverBeenInvolvedInAccidentOrLossPastThreeYears: '',
        hasDriverBeenInvolvedInAccidentOrLossPastThreeYearsDetails: '',
        hasDriverBeenProsecutedOrFixedPenalty: '',
        hasDriverBeenProsecutedOrFixedPenaltyDetails: '',
        hasDriverBeenDeclinedOrRefusedRenewal: '',
        hasDriverBeenDeclinedOrRefusedRenewalDetails: '',
        driverSufferAnyConditionsAffectSightOrHearing: '',
        driverSufferAnyConditionsAffectSightOrHearingDetails: '',
        descriptionOfDamage: '',
        purposeVehicleBeingUsed: '',
        numberPersonsCarried: '',
        natureGoodsCarried: '',
        dateOfAccident: '',
        timeOfAccident: '',
        placeOfAccident: '',
        roadConditions: '',
        weatherConditions: '',
        estimatedSpeed: '',
        lightsUsed: '',
        positionInRoad: '',
        whoWasResponsibleForAccident: '',
        thirdPartyInvolved: '',
        thirdPartyName: '',
        thirdPartyAddress: '',
        thirdPartyTelephoneNumber: '',
        thirdPartyVehicleMake: '',
        thirdPartyVehicleModel: '',
        thirdPartyVehicleRegistration: '',
        thirdPartyInsurers: '',
        thirdPartyReferenceNumber: '',
        thirdPartySpeed: '',
        thirdPartyVehicleDamage: '',
        thirdPartyNumberOccupantsInVehicle: '',
        thirdPartyAdditionalInvolved: '',
        thirdPartyAdditionalName: '',
        thirdPartyAdditionalAddress: '',
        thirdPartyAdditionalTelephoneNumber: '',
        thirdPartyAdditionalVehicleMake: '',
        thirdPartyAdditionalVehicleModel: '',
        thirdPartyAdditionalVehicleRegistration: '',
        thirdPartyAdditionalInsurers: '',
        thirdPartyAdditionalReferenceNumber: '',
        thirdPartyAdditionalSpeed: '',
        thirdPartyAdditionalVehicleDamage: '',
        thirdPartyAdditionalNumberOccupantsInVehicle: '',
        furtherInjuredPartiesInvolved: '',
        furtherInjuredName: '',
        furtherInjuredAddress: '',
        furtherInjuredInjuryDetails: '',
        furtherInjuredAmbulance: '',
        furtherInjuredAmbulanceDetails: '',
        wasTherePassenger1: '',
        passenger1Name: '',
        passenger1Address: '',
        wasTherePassenger2: '',
        passenger2Name: '',
        passenger2Address: '',
        wasTherePassenger3: '',
        passenger3Name: '',
        passenger3Address: '',
        wasThereWitness1: '',
        witness1Name: '',
        witness1Address: '',
        wasThereWitness2: '',
        witness2Name: '',
        witness2Address: '',
        wasThereWitness3: '',
        witness3Name: '',
        witness3Address: '',
        wasTherePoliceOnSite: '',
        policeOfficerName: '',
        policeOfficerNumber: '',
        policeOfficerStationAddress: '',
        claimStatus: ''
    }
};