import { useRef, memo, Fragment } from 'react';
import { useDispatch } from "react-redux";
import { tableContainer, actionOpenDrawer, getTableHeaderColour, getValidRequestObject, generateChecked } from '../../consumablesHub/functions';
import Axios from 'classes/axios';
import UILIB from 'components';
import Modules from '../Modules';


const SiteDevicesTable = memo(({ entityLookup, formData, setFormData }) => {

  const headerData = [
    { label: '', value: 'selected', type: '', filtering: false, minWidth: 20 },
    { label: 'Device', value: 'Description', type: 'string', filtering: true, minWidth: 250, maxWidth: 200 },
    { label: 'Serial Number', value: 'SerialNumber', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Asset Number', value: 'AssetNumber', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Location', value: 'Location', type: 'string', filtering: true, minWidth: 160, maxWidth: 160 },
    { label: getTableHeaderColour('black'), value: 'black', type: 'string', filtering: false, minWidth: 100, maxWidth: 100 },
    { label: getTableHeaderColour('cyan'), value: 'cyan', type: 'string', filtering: false, minWidth: 100, maxWidth: 100 },
    { label: getTableHeaderColour('magenta'), value: 'magenta', type: 'string', filtering: false, minWidth: 100, maxWidth: 100 },
    { label: getTableHeaderColour('yellow'), value: 'yellow', type: 'string', filtering: false, minWidth: 100, maxWidth: 100 },
    { label: 'Waste Toner', value: 'waste', type: 'string', filtering: false, minWidth: 100, maxWidth: 100 },
    { label: 'Staples', value: 'staples', type: 'string', filtering: false, minWidth: 100, maxWidth: 100 },
    { label: 'Booklet Staples', value: 'bookletstaples', type: 'string', filtering: false, minWidth: 100, maxWidth: 100 },
    { label: 'Alerts', value: 'alerts', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 }];

  const dispatch = useDispatch();
  const tablePageDefaults = { paging: { pages: [25,50,75,100,200], pageSelected: 20, limit: 20, offset: 0, orderBy: 'SerialNumber', orderDir: 'ASC' } };
  const requiredCheckbox = useRef({});
  const entityData = useRef({});


  const updateCheckbox = (event, data) => {
    const validObject = getValidRequestObject(data, false);
    if(validObject) {
      const index = formData.cachedData.findIndex(x => Number(x.Id) === Number(data.Id));
      if(index > -1) formData.cachedData[index] = data;
      if(index === -1) formData.cachedData.push(data);
    } else {
      formData.cachedData = formData.cachedData.filter(x => Number(x.Id) !== Number(data.Id));
    }
    setFormData({ ...formData, selectedDevices: formData.cachedData });
  };

  async function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

      const b_hasAlerts = Boolean((row.storeNote && row.storeNote.length) || (row.itemnote && row.itemnote.length) || (row.stockHoldingNote && row.stockHoldingNote.length));
      const alertsColour = (b_hasAlerts) ? 'colour background-6 red' : 'colour lightGrey';
      const fn_alerts = actionOpenDrawer.bind(null, 'ViewAlertsDrawer', row, dispatch, b_hasAlerts);

      return {
        selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox checked={getValidRequestObject(row, true, requiredCheckbox)} type='checkbox' />} /> },
        Description: { value: row.Description, raw: row.Description },
        SerialNumber: { value: row.SerialNumber, raw: row.SerialNumber },
        AssetNumber: { value: row.AssetNumber, raw: row.AssetNumber },
        Location: { value: row.Location, raw: row.Location },
        black: { value: <Modules.InputContainer data={row} field={'black'} placeholder={0} disabled={!row.isMono} onChange={(ev, data) => updateCheckbox(ev, data)} />, raw: row.black },
        cyan: { value: <Modules.InputContainer data={row} field={'cyan'} placeholder={0} disabled={!row.isColour} onChange={(ev, data) => updateCheckbox(ev, data)} />, raw: row.cyan },
        magenta: { value: <Modules.InputContainer data={row} field={'magenta'} placeholder={0} disabled={!row.isColour} onChange={(ev, data) => updateCheckbox(ev, data)} />, raw: row.magenta },
        yellow: { value: <Modules.InputContainer data={row} field={'yellow'} placeholder={0} disabled={!row.isColour} onChange={(ev, data) => updateCheckbox(ev, data)} />, raw: row.yellow },
        waste: { value: <Modules.InputContainer data={row} field={'waste'} placeholder={0} disabled={!row.isMono} onChange={(ev, data) => updateCheckbox(ev, data)} />, raw: row.waste },
        staples: { value: <Modules.InputContainer data={row} field={'staples'} placeholder={0} disabled={!row.isMono} onChange={(ev, data) => updateCheckbox(ev, data)} />, raw: row.staples },
        bookletstaples: { value: <Modules.InputContainer data={row} field={'bookletstaples'} placeholder={0} disabled={!row.isMono} onChange={(ev, data) => updateCheckbox(ev, data)} />, raw: row.bookletStaples },
        alerts: { value: tableContainer(fn_alerts, 'icon-bullhorn', (b_hasAlerts) ? 'View Alerts' : 'Alerts Unavailable', alertsColour, true), raw: b_hasAlerts }
      }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    if(formData.totalDevices === 0) return false;

    const expandArray = ['storenote','worknote','stockholdingnote','customer'];

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

    if(entityLookup.context === 'customer' && entityLookup.customerId) {
      entityData.current.devices = await Axios.get(`/entities/equipments/getSummaryRequestConsumables/?&$filter=CustomerId eq ${entityLookup.customerId} and deletedDate is null ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    }

    if(entityLookup.context === 'equipment' && entityLookup.equipmentId) {
      entityData.current.devices = await Axios.get(`/entities/equipments/getSummaryRequestConsumables/?&$filter=Id eq ${entityLookup.equipmentId} and deletedDate is null ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    }

    if(entityLookup.context === 'assetNumber' && entityLookup.assetNumber) {
      entityData.current.devices = await Axios.get(`/entities/equipments/getSummaryRequestConsumables/?&$filter=AssetNumber eq ${entityLookup.assetNumber} and deletedDate is null and CustomerId isn null ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    }

    if(entityLookup.context === 'postCode' && entityLookup.postCode) {
      entityData.current.devices = await Axios.get(`/entities/equipments/getSummaryRequestConsumables/?&$filter=CustomerId eq ${entityLookup.customerId} and deletedDate is null ${queryLocal}&$expand=${expandArray.join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);
    }

    if(!entityData.current.devices || !entityData.current.devices.result || !entityData.current.devices.result.length) return false;

    requiredCheckbox.current = { ...generateChecked(entityData.current), ...requiredCheckbox.current }; 

    const deviceIds = Array.from(new Set(formData.cachedData.map(x => Number(x.Id))));
    const devices = entityData.current.devices.result.map(x => { 
      return (deviceIds.includes(Number(x.Id))) ? formData.cachedData.find(f => Number(f.Id) === Number(x.Id)) : x });

    return { tableData: await constructTable(devices), raw: devices, nonePaged: entityData.current.devices.nonePaged };
  }


  return <Fragment>
    <div className="pad-5 width-100 mar-b20 mar-t20">
      <b className="flex-item row  width-100 start text-12 pad-b2 mar-b2">Devices in need of toner</b>
      <div className="width-100">
        <UILIB.TableNew
          name="DevicesRequired"
          className='small'
          overflowX='auto'
          overflowY='hidden'    
          header={headerData}
          localQuery={() => constructTable((entityData.current && entityData.current.devices) ? entityData.current.devices.result : [])}
          localRender={[formData]}
          remoteQuery={getTableData}
          remoteRender={[entityLookup, formData.totalDevices]}
          defaultSettings={tablePageDefaults} />
      </div>
    </div>
  </Fragment>
})
export default SiteDevicesTable