
import { Fragment } from 'react';
import { handleCustomerClicked } from '../functions'

export default function ManualOrderContainer({ data, history, showChip = true, clickable = true }) {

  const textClass = ' consumablesHub manual-order-container-text ';
  const link = (clickable) 
   ? handleCustomerClicked(data, history, 'font-weight-bold')
   : data.customerName

  return <Fragment>
          <div className="flex-container row width-100 wrap mar-1">
            <div className={`flex-item start width-100 mar-l2 mar-r2 text-11 ${textClass}`}><b>{link}</b></div>
            {showChip && <div className={`flex-item start center width-100 height-100 chip table colour background-8 darkPurple pad-2 mar-2`}>MANUAL ORDER</div>}
            <div className={`flex-item start width-100 mar-l2 mar-r2 ${textClass}`}>{`${data.custName} - ${data.telNo}`}</div>
            <div className={`flex-item start width-100 mar-l2 mar-r2 ${textClass}`}>{data.custEmail}</div>
          </div>
        </Fragment>
}