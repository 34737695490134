import { useState, useEffect, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom'
import Modules from './Modules';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function CustomerSiteStock() {

  const history = useHistory();
  const { context, id } = useParams();
  const [siteStockRender, setSiteStockRender] = useState(false);
  const [siteDeviceRender, setSiteDeviceRender] = useState(false);
  const [render, setRender] = useState(false);
  const [entityLookup, setEntityLookup] = useState({ showSearch: false });

  useEffect(() => { 
    const actionAsync = async () => {
      switch(context) {
        case 'customer':
          const customerResponse = await Axios.get(`/entities/workflow/actionGetLookupData/${context}/${id}`);
          setEntityLookup({ ...entityLookup, context, ...customerResponse?.data?.result, showSearch: true });
          break;
        case 'equipment':
          const equipmentResponse = await Axios.get(`/entities/workflow/actionGetLookupData/${context}/${id}`);
          setEntityLookup({ ...entityLookup, context, ...equipmentResponse?.data?.result, showSearch: true });
          break;
        default:
          console.info("no incomming params detected");
          setEntityLookup({ ...entityLookup, showSearch: true });
      }
    }
    actionAsync(); 
  }, [render, context, id])


  return <Fragment>
    <div className="flex-container column height-100 mar-t10 mar-r5 mar-l5">
      <UILIB.Paper className='flex-item row width-100 mar-b20'>
        {context !== 'equipment' && <div className='flex-item start width-100 mar-b20'><h3>{`Customer Site Stock ${(entityLookup?.altName) ? ` - ${entityLookup.altName}` : ''}`}</h3></div>}
        {context === 'equipment' && <div className='flex-item start width-100 mar-b20'><h3>{`Customer Site Stock ${(entityLookup?.altName && entityLookup?.serialNumber) ? ` - ${entityLookup.altName} - #${entityLookup.serialNumber}` : ''}`}</h3></div>}
        <div className='flex-item start flex-grow-1'>
          <UILIB.Button value="Back" className="grey" onClick={() => history.push('/consumablesHub')} />
        </div>
        <div className='flex-item end'>
          <Modules.GenerateStocksReport />
        </div>
      </UILIB.Paper>
      <div className='flex-item row width-100 mar-b20'>
        <Modules.SiteSearch entityLookup={entityLookup} setEntityLookup={setEntityLookup} render={render} setRender={setRender} />
      </div>
      <Modules.BatchedOrders entityLookup={entityLookup} />
      <Modules.SiteStocks entityLookup={entityLookup} siteDeviceRender={siteDeviceRender} setSiteDeviceRender={setSiteDeviceRender} siteStockRender={siteStockRender} />
      <Modules.SiteDevices entityLookup={entityLookup} siteStockRender={siteStockRender} setSiteStockRender={setSiteStockRender} siteDeviceRender={siteDeviceRender} />
    </div>
  </Fragment>
}