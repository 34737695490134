import UILIB from 'components';

export function statCard(loading, canClick, multiSelect, title, data, parentClass, childClass, selected, setSelected, onClick, subData, dataSecondary) {

  return (
    <UILIB.StatContainer 
      parentClass={parentClass}
      childClass={childClass}
      loading={loading} 
      canClick={canClick} 
      multiSelect={multiSelect}
      title={title} 
      data={data}
      dataSecondary={dataSecondary}
      subData={subData}
      selected={selected}
      setSelected={setSelected}
      onClick={onClick}
    />)
}
