import { useState, useRef, Fragment } from "react";
import Axios from "classes/axios";
import UILIB from "components";
import { getGridChip, setFilterVarsCustomer } from '../functions';


export default function CustomerLookup({ setCurrentTab, setSelectedCustomer }) {
  const headerData = [
    { label: 'Company Name', value: 'Name', type: 'string', filtering: true, minWidth: 300, maxWidth: 300 },
    { label: 'Account #', value: 'Reference', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },  
    { label: 'Region', value: 'regionName', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Address Line 1', value: 'Address1', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
    { label: 'Address Line 2', value: 'Address2', type: 'string', filtering: true, minWidth: 150, maxWidth: 150 },
    { label: 'Town', value: 'Town', type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: 'Post Code', value: 'PostCode', type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: 'Status', value: 'statusTxt', type: 'string', filtering: true, minWidth: 80, maxWidth: 80 },
    { label: 'View', value: 'viewUsers', type: 'string', filtering: false, minWidth: 80, maxWidth: 80 }];

  const tablePageDefaults = { paging: { pages: [10, 20, 50, 100, 200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'Name', orderDir: 'ASC' } };
  const entityData = useRef({});
  const [filter, setFilter] = useState({ button: 'Active' });
  const defaultDialogue = { count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [showDeleteDialogue, setShowDeleteDialoge] = useState(defaultDialogue);
  const [remoteRender, setRemoteRender] = useState(false);
  const [loading, setLoading] = useState(false);

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';
  const exceptions = [400,404];


  const handleButtonsChanged = (buttonName, set) => {
    const newTableFilter = { ...filter };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = '';
    } else {
        newTableFilter.button = buttonName;
    }
    set(newTableFilter);
  };

  function actionViewUsers(data) {
    setSelectedCustomer({ selected: true, customerName: data.Name, customerId: data.id, deleted: data.status });
    setCurrentTab(2);
  }

  const actionDeleteRequest = async () => {
    try {
      setLoading(true);
      const requestIdArray = showDeleteDialogue.ids.map(x => Number(x));
      const filtered = entityData.current.result.filter(x => requestIdArray.includes(x.id));
      const result = await Axios.delete('/entities/workflow/portals/actionDeleteCustomer', { data: { entity: filtered } });
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setShowDeleteDialoge(defaultDialogue);
        setBannerError(defaultBanner);
        setRemoteRender(!remoteRender);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setLoading(false);
  };

  async function constructTable(tonderData) {

    const tableData = tonderData.map(row => {

      const viewUsers = <UILIB.Button className={"table colour background-8 green text-small width-100"} value={'View Users'} onClick={() => actionViewUsers(row)} />

      return {
        id: { value: row.id, raw: row.id, disabled: (filter.button !== 'Active') },
        Name: { value: row.Name, raw: row.Name },
        groupCode: { value: row.groupCode, raw: row.groupCode },
        Reference: { value: row.Reference, raw: row.Reference },
        regionName: { value: row.regionName, raw: row.regionName },
        Address1: { value: row.Address1, raw: row.Address1 },
        Address2: { value: row.Address2, raw: row.Address2 },
        Town: { value: row.Town, raw: row.Town },
        PostCode: { value: row.PostCode, raw: row.PostCode },
        statusTxt: { value: getGridChip('customer', row.status), raw: row.statusTxt },
        viewUsers: { value: viewUsers, raw: 0 }
      }
    })

    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const filterButtons = setFilterVarsCustomer(filter);

    entityData.current = await Axios.get(`/entities/portalsV3_userbases/getCustomerSummary/?&$filter=${filterButtons} ${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(res => res.data);

    if (!entityData.current || !entityData.current.result.length || !Array.isArray(entityData.current.result)) return false;

    return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged };
  }

  return <Fragment>
    {showDeleteDialogue.show && <UILIB.MessageBox
      header={'Delete Requests'}
      text={`Click OK to DELETE the selected ${(showDeleteDialogue.count === 1) ? 'User' : 'Users' }`}
      loading={loading}
      errored={bannerError}
      onConfirm={async () => await actionDeleteRequest()}
      onCancel={() => setShowDeleteDialoge({ ...showDeleteDialogue, show: false })} />}
    <UILIB.Paper className='width-100'>
      <div className='flex-container start wrap width-100'>
        <div className="flex-item start  flex-grow-1">
          <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Active' ? 'primary' : 'secondary')} name='Active' value='Active' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
          <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'Deleted' ? 'primary' : 'secondary')} name='Deleted' value='Deleted' onClick={(ev) => handleButtonsChanged(ev.target.name, setFilter)} />
        </div>
      </div>
      <div className='width-100'>
        <UILIB.TableNew
          name='PortalCustomerLookup'
          className='small'
          overflowX='auto'
          overflowY='hidden'
          header={headerData}
          remoteQuery={getTableData}
          remoteRender={[remoteRender, filter]}
          defaultSettings={tablePageDefaults} />
      </div>
    </UILIB.Paper>
  </Fragment>
}
