import Button from '../Button/Button'
import { useState } from 'react'
import { useEffect } from 'react';

export default function SessionExpireModal({ onLogout = () => { }, onLoggedIn = () => { }, sessionTimeout, setSessionTimeout }) 
{
    const [currentTokenCount, setTokenCount] = useState(0);
    const tokenTimer = () => setTokenCount(currentTokenCount + 1);

    useEffect(() => {
      const id = setInterval(tokenTimer, 1000);
          setSessionTimeout(sessionTimeout === 0 ? onLogout() : sessionTimeout - 1)
      return () => clearInterval(id);
      },
      [currentTokenCount]
    );

    return (
        <div className="modalTimeout">
            <div className="modalContent" style={{ width: "20%", minWidth: "360px" }}>
                <div className="flex-container row center">{`Your session will expire in ${sessionTimeout} seconds`}</div>
                <div className="flex-container row center width-100 mar-t10">
                  <Button className="primary mar-r10" value="Stay logged in" onClick={() => { onLoggedIn() }}/>
                  <Button className="primary mar-l10" value="Logout now" onClick={() => { onLogout() }}/>
                </div>
            </div>
        </div>
    )
}