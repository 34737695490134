import { useState, useEffect, Fragment } from 'react';
import { checkAccess } from '../shared/functions'
import UILIB from 'components';
import Axios from 'classes/axios';
import moment from 'moment-timezone';


export default function PatchCRM({ ourDeal, setOurDeal, account }) {

  const [permissions, setPermissions] = useState(false);
  const emptyError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(emptyError);
  const [loading, setLoading] = useState(false);
  const exceptions = [400,404];
 
  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  useEffect(() => {
    const asyncEffect = async () => {
      const permissionResult = await checkAccess(account, ourDeal);
      setPermissions(permissionResult.users.isDevSop || permissionResult.users.isAdmin);
    }
    asyncEffect();
  }, []);

  const handlePatchCRM = async () => {
    try {
      setLoading(true);
      const response = await Axios.patch(`/entities/workflow/deals/actionDealUpdateCRM/${ourDeal.dealID}`);
      if(exceptions.includes(response.status)) {
        if(response.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(response.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        setBannerError(emptyError);
        setOurDeal({ ...ourDeal, crmUpdatedAt: response.data.result[0].crmUpdatedAt });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setBannerError({ error: true, message: exceptionError });
    }
  }

  if(!permissions || Number(ourDeal.dynamicsStateCode) === 1) return <Fragment></Fragment>

  return <Fragment>
    <div className={`flex-container row width-100`}>
      <div className="flex-item start width-100">
        <UILIB.Button 
          loading={loading} 
          disabled={loading || (Number(ourDeal.processStage) < 3) || (Number(ourDeal.archived) === 1)} 
          value="Update CRM" 
          className="green" 
          onClick={async () => await handlePatchCRM()} />
      </div>
      {ourDeal.crmUpdatedAt && <div className="flex-item start width-100"><p>Last Saved: {moment(ourDeal.crmUpdatedAt).format("DD/MM/YYYY HH:mm:ss")}</p></div>}
      {bannerError.error && <div className="flex-container start width-100">
        <div className="errored message">{bannerError.message}</div>
      </div>}
    </div>
  </Fragment>
}