import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions';
import { pleaseSelect, createDateInput, createTextInput, createSelectInput, checkAndSet, newError, numericSpecialPattern, numericPattern, alphaNumericSpacePattern, alphaNumericSpaceSpecialPattern } from '../generic';
import UILIB from 'components';
import Axios from 'classes/axios';
import ServiceCRUD from '../service/serviceCRUD';
import MotCRUD from '../mot/motCRUD';
import QuarterlyCheckCRUD from '../quarterlyCheck/quarterlyCheckCRUD';

export default function VehicleCRUD(props) {
    const dispatch = useDispatch();
    const emptyEntity = {
        registration: '',
        make: '',
        model: '',
        fuelCardNumber: '',
        fuelCardExpiryDate: '',
        derivative: '',
        region: '',
        office: '',
        registrationDate: '',
        p11d: '',
        engineSize: '',
        fuelType: '',
        co2: '',
        contractType: '',
        vehicleType: '',
        serviceIntervalMileage: '',
        serviceIntervalMonth: '',
        motInterval: '',
        taxDate: '',
        maintenanceType: '',
        contractStartDate: '',
        contractEndDate: '',
        leaseCompany: '',
        leaseCost: '' };
    const entityUrl = `/fleet/vehicle`;
    const entityUrlWithId = `/fleet/vehicle/${props.data.entity.id}`;
    const addFromEntityWithKey = {...emptyEntity };
    const headerText = ' Vehicle - ';
    const makes = [pleaseSelect,'Audi','BMW','Cupra','Ford','Jaguar','Kia','Mazda','Mercedes Benz','Mini','Nissan','Peugeot','Skoda','Tesla','Toyota','Vauxhall','Volvo'];
    const regions = [pleaseSelect,'North','Midlands','South','Scotland'];
    const offices = [pleaseSelect,'Southampton','London','Northampton','Croydon','Newcastle','Manchester','Falkirk','Bristol','Leicester','York','Rochester','Scotland']
    const fuelTypes = [pleaseSelect,'Petrol','Diesel','Hybrid (Petrol/Diesel)','Electric'];
    const contractTypes = [pleaseSelect,'Leased','Owned','Hire'];
    const vehicleTypes = [pleaseSelect,'Car','Van'];
    const maintenanceTypes = [pleaseSelect,'Maintenance Included','Self-Maintained'];
    const leaseCompanys = [pleaseSelect,'TFS','Alphabet','Kinto','Skoda Financial services','Arval','Network leases','Ogilvie','SMG Leasing','Arnold Clark','Toomey Leasing','ALD','Lex Autolease','Other'];

    const loadSpecificData = async () => {
    }

    const evaluatedRulesPassed = (formErrorsTemp) => {
        let e = 0;
        e = checkAndSet(!entity.registration || entity.registration.length < 1, formErrorsTemp, 'registration', newError('registration', 'Please enter Registration of vehicle'));
        e = e + checkAndSet(!entity.registration.match(alphaNumericSpacePattern), formErrorsTemp, 'registration', newError('registration', 'Please enter Registration without special characters'));

        e = e + checkAndSet(!entity.make || entity.make.length < 1 || entity.make === pleaseSelect, formErrorsTemp, 'make', newError('make', 'Please select Make of vehicle'));

        e = e + checkAndSet(!entity.model || entity.model.length < 1, formErrorsTemp, 'model', newError('model', 'Please enter Model of vehicle'));
        e = e + checkAndSet(!entity.model.match(alphaNumericSpacePattern), formErrorsTemp, 'model', newError('model', 'Please enter Model without special characters'));

        e = e + checkAndSet(!entity.fuelCardNumber.match(numericPattern), formErrorsTemp, 'fuelCardNumber', newError('fuelCardNumber', 'Please enter Fuel Card Number without special characters'));
        e = e + checkAndSet(!entity.derivative.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'derivative', newError('derivative', 'Please enter Derivative without special characters'));
        e = e + checkAndSet(!entity.region || entity.region.length < 1 || entity.region === pleaseSelect, formErrorsTemp, 'region', newError('region', 'Please select Region'));
        e = e + checkAndSet(!entity.office || entity.office.length < 1 || entity.office === pleaseSelect, formErrorsTemp, 'office', newError('office', 'Please select Office'));
        e = e + checkAndSet(!entity.registrationDate, formErrorsTemp, 'registrationDate', newError('registrationDate', 'Please enter Registration Date of vehicle'));

        e = e + checkAndSet(!entity.p11d || entity.p11d.length < 1, formErrorsTemp, 'p11d', newError('p11d', 'Please enter P11D value'));
        e = e + checkAndSet(!entity.p11d.match(numericSpecialPattern), formErrorsTemp, 'p11d', newError('p11d', 'Please enter P11D without special characters'));

        e = e + checkAndSet(!entity.engineSize || entity.engineSize.length < 1, formErrorsTemp, 'engineSize', newError('engineSize', 'Please enter Engine Size of vehicle'));
        e = e + checkAndSet(!entity.engineSize.match(numericSpecialPattern), formErrorsTemp, 'engineSize', newError('engineSize', 'Please enter valid Engine Size of vehicle'));

        e = e + checkAndSet(!entity.fuelType || entity.fuelType.length < 1 || entity.fuelType === pleaseSelect, formErrorsTemp, 'fuelType', newError('fuelType', 'Please select Fuel Type'));

        e = e + checkAndSet(!entity.co2 || entity.co2.length < 1, formErrorsTemp, 'co2', newError('co2', 'Please enter CO2 value'));
        e = e + checkAndSet(!entity.co2.match(numericPattern), formErrorsTemp, 'co2', newError('co2', 'Please enter CO2 without special characters'));

        e = e + checkAndSet(!entity.contractType || entity.contractType.length < 1 || entity.contractType === pleaseSelect, formErrorsTemp, 'contractType', newError('contractType', 'Please select Contract Type'));
        e = e + checkAndSet(!entity.vehicleType || entity.vehicleType.length < 1 || entity.vehicleType === pleaseSelect, formErrorsTemp, 'vehicleType', newError('vehicleType', 'Please select Vehicle Type'));

        e = e + checkAndSet(!entity.serviceIntervalMileage.match(numericPattern), formErrorsTemp, 'serviceIntervalMileage', newError('serviceIntervalMileage', 'Please enter Service Interval Mileage without special characters'));
        e = e + checkAndSet(!entity.serviceIntervalMonth.match(numericPattern), formErrorsTemp, 'serviceIntervalMonth', newError('serviceIntervalMonth', 'Please enter Service Interval Month without special characters'));

        e = e + checkAndSet(!entity.motInterval, formErrorsTemp, 'motInterval', newError('motInterval', 'Please enter MOT Interval of vehicle'));
        e = e + checkAndSet(!entity.maintenanceType || entity.maintenanceType.length < 1 || entity.maintenanceType === pleaseSelect, formErrorsTemp, 'maintenanceType', newError('maintenanceType', 'Please select Maintenance Type'));
        e = e + checkAndSet(!entity.leaseCompany || entity.leaseCompany.length < 1 || entity.leaseCompany === pleaseSelect, formErrorsTemp, 'leaseCompany', newError('leaseCompany', 'Please select Lease Company'));
        e = e + checkAndSet(!entity.leaseCost.match(numericSpecialPattern), formErrorsTemp, 'leaseCost', newError('leaseCost', 'Please enter Lease cost without special characters'));

        return e > 0;
    }

    const htmlButtons = (loading) => {
        if (loading) {
            return <div />
        } else {
            return <div>
                <UILIB.Button loading={saving} className='mar-t10' value='Action' onClick={submitForm} />
                {(props.data.type === editType) && <UILIB.Button className='mar-t10 mar-l10' value='Add Service' onClick={addService} />}
                {(props.data.type === editType) && <UILIB.Button className='mar-t10 mar-l10' value='Add MOT' onClick={addMot} />}
                {(props.data.type === editType) && <UILIB.Button className='mar-t10 mar-l10' value='Add Quarterly Check' onClick={addQuarterlyCheck} />}
            </div>
        }
    }

    const htmlRows = (loading) => { 
        if (loading) {
            return <div />
        } else {
            return <div className='mar-t15'>
                {createTextInput('Registration', 'registration', isActive, 20, entity, entityErrors, changeInput)}
                {createSelectInput('Make', 'make', makes, isActive, entity, entityErrors, changeInput)}
                {createTextInput('Model', 'model', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('Fuel Card Number', 'fuelCardNumber', isActive, 40, entity, entityErrors, changeInput)}
                {createDateInput('Fuel Card Expiry Date', 'fuelCardExpiryDate', isActive, entity, entityErrors, changeInput)}
                {createTextInput('Derivative', 'derivative', isActive, 40, entity, entityErrors, changeInput)}
                {createSelectInput('Region', 'region', regions, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Office', 'office', offices, isActive, entity, entityErrors, changeInput)}
                {createDateInput('Registration Date', 'registrationDate', isActive, entity, entityErrors, changeInput)}
                {createTextInput('P11D', 'p11d', isActive, 40, entity, entityErrors, changeInput)}
                {createTextInput('EngineSize', 'engineSize', isActive, 20, entity, entityErrors, changeInput)}
                {createSelectInput('Fuel Type', 'fuelType', fuelTypes, isActive, entity, entityErrors, changeInput)}
                {createTextInput('CO2', 'co2', isActive, 20, entity, entityErrors, changeInput)}
                {createSelectInput('Contract Type', 'contractType', contractTypes, isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Vehicle Type', 'vehicleType', vehicleTypes, isActive, entity, entityErrors, changeInput)}
                {createTextInput('Service Interval Mileage', 'serviceIntervalMileage', isActive, 6, entity, entityErrors, changeInput)}
                {createTextInput('Service Interval Month', 'serviceIntervalMonth', isActive, 2, entity, entityErrors, changeInput)}
                {createDateInput('Mot Interval', 'motInterval', isActive, entity, entityErrors, changeInput)}
                {createDateInput('Tax Date', 'taxDate', isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Maintenance Type', 'maintenanceType', maintenanceTypes, isActive, entity, entityErrors, changeInput)}
                {createDateInput('Contract Start Date', 'contractStartDate', isActive, entity, entityErrors, changeInput)}
                {createDateInput('Contract End Date', 'contractEndDate', isActive, entity, entityErrors, changeInput)}
                {createSelectInput('Lease Company', 'leaseCompany', leaseCompanys, isActive, entity, entityErrors, changeInput)}
                {createTextInput('Lease cost', 'leaseCost', isActive, 20, entity, entityErrors, changeInput)}
                {createTextInput('Created At', 'createdAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Created By', 'createdBy', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated At', 'updatedAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated By', 'updatedBy', false, 20, entity, entityErrors, changeInput)}
           </div>
        }
    }

    const addService = (data) => {
        dispatch(setDrawer({ open: true, content: <ServiceCRUD data={{ type: 'addFrom', entity: { registration: entity['registration'] }}} sync={syncEditSave} />, width: '600px' }));
    };

    const addMot = (data) => {
        dispatch(setDrawer({ open: true, content: <MotCRUD data={{ type: 'addFrom', entity: { registration: entity['registration'] }}} sync={syncEditSave} />, width: '600px' }));
    };

    const addQuarterlyCheck = (data) => {
        dispatch(setDrawer({ open: true, content: <QuarterlyCheckCRUD data={{ type: 'addFrom', entity: { registration: entity['registration'] }}} sync={syncEditSave} />, width: '600px' }));
    };

    const syncEditSave = (data) => {
    }

    //Generic
    const addType = 'add';
    const addFromType = 'addFrom';
    const editType = 'edit';
    const deleteType = 'delete';
    const defaultPageMessage = 'Please complete the below and then click Action.';
    const deleteConfirmationMessage = 'Please confirm deletion of the below details by clicking Action.';
    const failValidationMessage = 'Please correct any errors and click Action again.';
    const rowVersionMessage = 'A newer version of this record has already been saved. Please reload the data.';
    const missingRecordMessage = 'Unable to find record in db. Please reload the data.';
    const invalidSubmitMessage = 'Invalid submit request, please advise support.';
    const saveErrorMessage = 'Unable to save, please advise support.';
    const [loadingData, setLoadingData] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [isActiveOnEdit, setIsActiveOnEdit] = useState(true);
    const [entity, setEntity] = useState(emptyEntity);
    const [entityErrors, setEntityErrors] = useState(emptyEntity);
    const [errored, setErrored] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState(defaultPageMessage);

    useEffect(() => {
        const loadEntity = async () => {
            if (props.data.type === addType) {
                setEntity(emptyEntity);
            } else if (props.data.type === addFromType) {
                const entity = addFromEntityWithKey;
                setEntity(entity);
            } else {        
                const entityData = await Axios.get(entityUrlWithId);
                setEntity(entityData.data.result);
            }

            setLoadingData(false);
        }

        if (props.data.type === deleteType) {
            setIsActive(false);
            setIsActiveOnEdit(false);
            setMessage(deleteConfirmationMessage)
        }

        if (props.data.type === editType || props.data.type === addFromType) {
            setIsActiveOnEdit(false);
        }

        loadSpecificData();
        loadEntity();
    }, [])

    const changeInput = (event) => {
        let newFormData = JSON.parse(JSON.stringify(entity));
        newFormData[event.target.name] = event.target.value;
        setEntity(newFormData);

        if (event.target.name in entityErrors) {
            let newFormErrors = { ...entityErrors };
            newFormErrors[event.target.name] = '';
            setEntityErrors(newFormErrors);
        }
    }

    const validSubmit = () => {
        let formErrorsTemp = emptyEntity;        
        if (!evaluatedRulesPassed(formErrorsTemp)) {
            return true;
        }

        setEntityErrors(formErrorsTemp);
        setMessage(failValidationMessage);
        return false;
    }

    const processDelete = async () => {
        if (props.data.type === deleteType) {
            setSaving(true);
            await Axios.delete(entityUrlWithId, entity);
            setDeleted(true);
            props.sync({ deleted: true, id: props.data.entity.id });
            setSaving(false);

            return true;
        }

        return false;
    }

    const processAdd = async () => {
        if (props.data.type === addType || props.data.type === addFromType) {
            setSaving(true);
            const entityResult = await Axios.post(entityUrl, entity);
            props.data.type = editType;
            props.data.entity.id = entityResult.data.result.id;
            setEntity(entityResult.data.result);
            props.sync(entityResult.data.result);
            setIsActiveOnEdit(false);
            setSaving(false);

            return true;
        }
        
        return false;
    }

    const processEdit = async () => {
        if (props.data.type === editType) {
            setSaving(true);
            const entityResult = await Axios.put(entityUrlWithId, entity);

            if (entityResult.status === 200) {
                setEntity(entityResult.data.result);
                props.sync(entityResult.data.result);
            }

            if (entityResult.status === 409) {
                setMessage(rowVersionMessage);
                setErrored(true);
            }

            if (entityResult.status === 400) {
                setMessage(missingRecordMessage);
                setErrored(true);
            }
            
            setSaving(false);
            return true;		
        }

        return false;
    }

    const submitForm = async () => {
        try {
            if (await processDelete()) { return; }
            if (validSubmit()) {
                if (await processAdd()) { return; }
                if (await processEdit()) { return; }

                setMessage(invalidSubmitMessage);
                setErrored(true);
            }
        }
        catch (err) {
            console.log(err);
            setMessage(saveErrorMessage);
            setErrored(true);
        }
    }

    if (deleted) return <UILIB.Paper className='width-100'><p className="center">Deleted</p></UILIB.Paper>

    if (errored) return <UILIB.Paper className='width-100'><p className="center">{message}</p></UILIB.Paper>

    if (loadingData) return <UILIB.Loading type={3} />

    return (
        <div className="mar-l15 mar-r15">
            <h2 className='mar-b10'>{(props.data.type === addType || props.data.type === addFromType) ? 'Add' : 'Edit'}{headerText}{entity.id}</h2>
            <p>{message}</p>
            {htmlButtons(loadingData)}
            {htmlRows(loadingData)}
        </div> 
    )
}