import { useEffect, useRef } from "react"

export default function Alert({ open = false, message = '', autoClose = 2500, onClose = () => { } }) {
    const closeTimer = useRef(null)

    useEffect(() => {
        if (!autoClose) return
        if (closeTimer.current) clearTimeout(closeTimer.current)
        if (open) {
            closeTimer.current = setTimeout(onClose, autoClose)
        }
        // eslint-disable-next-line
    }, [open])

    let className = "alert"
    if (open) className += " open"

    return (
        <div className={className}>
            {message}
        </div>
    )
}