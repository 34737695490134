
import { Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

export default function NoteContainer({ alert, type, createdBy, updatedAt, message, noteId, setShowDialogue, setEditNote }) {

  library.add(fas);
  const fontAwesome = <FontAwesomeIcon title={'Alert'} icon="fas fa-triangle-exclamation"/>

  return <Fragment>
          <div className={`flex-container row width-100 mar-t10 mar-b10`}>
            <div className={`flex-container row nowrap center width-100`}>
              <div className={`flex-container row width-100 colour background-8 orange text-14 padding-2`}>
                <div className={`text-14 flex-item start flex-grow-1 mar-r20`}>{type}</div>
                <div className={`text-16 text-right flex-container`}>
                  {setEditNote && <span title='Edit' className={`icon-pencil flex-item center height-100 flex-items-center padding-2 pointer`} onClick={() => setEditNote({ noteId: noteId, note: message, show: true })}></span>}
                  {setShowDialogue && <span title='Delete' className={`icon-trash2 flex-item center height-100 flex-items-center padding-2 pointer`} onClick={() => setShowDialogue({ noteId: noteId, note: message, show: true })}></span>}
                </div>
              </div>
            </div>
            <div className={`flex-container row nowrap start width-100 colour background lightGrey padding-2`}>
              {alert && <div className={`flex-item center mar-l5 mar-r20`}>{fontAwesome}</div>}
              <div className={`flex-item start flex-grow-1 mar-r20 text-13`}>{createdBy}</div>
              <div className={`flex-item end mar-r20 text-13`}>{formatDateTime(updatedAt, "DD-MM-YYYY HH:mm:ss")}</div>
            </div>
            <div className={`flex-container row nowrap start flex-grow-1 width-100 height-100 padding-2 colour background-4 lightGrey`}>
              <div className={`flex-item text-12 pad-8 consumablesHub tonerRobotNotes-text-content`}>{message}</div>
            </div>
          </div>
        </Fragment>
}
