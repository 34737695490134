import { memo } from 'react';
import { setCacheData, getDefaultSettings } from '../functions';

const ClearFilters = memo(({ header, settings, defaultSettings, render, setRender }) =>
{

  const clearfilters = () => {
    settings.current = { ...settings.current, ...getDefaultSettings(header, settings.current.tableName, defaultSettings), paging: settings.current.paging, filtering: settings.current.filtering };
    setCacheData({ ...settings.current, ...getDefaultSettings(header, settings.current.tableName, defaultSettings), paging: settings.current.paging, filtering: settings.current.filtering });
    setRender(!render);
  }

  return <div title={'Clear Filters'} className={`clear clickable icon-cross`} onClick={() => clearfilters()}></div>
})
export default ClearFilters