import { useState, useRef, useEffect, memo } from 'react';
import { TableCell } from '@mui/material';
import { getTablefilters, setCacheData } from '../functions'
import UILIB from 'components'

const ColumnFilters = memo(({ header, settings, moduleRender, setModuleRender, render, setRender }) => 
{
  const firstRender = useRef(true);
  const [renderLocal, setRenderLocal] = useState({ cloned: null, value: null });
  const validTypes = ['number', 'string', 'date', 'datetime'];
  const removeTypes = [-1,0,1];

  const changeOption = (event, columnName) => {
    const found = settings.current.filters.find(x => x.name === columnName);
    const renderValue = structuredClone(found.value);
    found.filter = Number(event.target.value);
    if(removeTypes.includes(Number(found.value))) found.value = '';
    settings.current = setCacheData({ ...settings.current, paging: { ...settings.current.paging, page: 0, offset: 0 } });
    setRenderLocal({ cloned: renderValue, value: found.value });
    setModuleRender(!moduleRender);
  }

  useEffect(() => {
    if(firstRender.current) { firstRender.current = false; return; }
    if((renderLocal.value !== renderLocal.cloned) || (renderLocal.value && renderLocal.value.length)) setRender(!render);
  }, [renderLocal])

  return header.map((col, index) => {
    const style = (col?.style) ? col.style : {};
    return <TableCell key={"columnFilter_" + index} style={{...style}}>
      {validTypes.includes(col.type) &&
      <UILIB.Select 
        disabled={!col.filtering} 
        className="textInput fullBorder tableInput tableFilter mar-b0" 
        outerClassName="tableFilter mar-b0" 
        value={settings.current.filters.find(x => x.name === col.value)?.filter} 
        data={getTablefilters(col.type, settings.current.filters, col.value).filter(x => col.filterArray && col.filterArray.length ? (x.value === 1 || x.value === 5) : x)} 
        onChange={(ev) => changeOption(ev, col.value)} />}
      {!validTypes.includes(col.type) && <UILIB.TextInput disabled={true} className="textInput fullBorder tableInput tableFilter mar-b0" outerClassName="tableFilter mar-b0"  />}
    </TableCell>
  })

})
export default ColumnFilters