import { Fragment } from 'react';
import UILIB from 'components';
import BlockAddressTable from '../tables/blockAddressTable';

export default function DeviceAddress({ formData, setFormData, formErrors }) {

  return  <Fragment>
            <div className="row">
              <div className="col-xs-12">
                <UILIB.Paper className="col-xs-12 mar-b15">
                  <div className="flex-container between mar-b15">
                      <b>Addresses</b>
                  </div>
                  <BlockAddressTable
                    formData={formData}
                    setFormData={setFormData}
                    sortable={true}
                    filter={true}
                  />
                </UILIB.Paper>    
              </div>
            </div>
          </Fragment>
}


