
import { Fragment } from 'react'
import * as functions from '../../../shared/functions';
import { 
  DialogContent, 
  DialogTitle, 
  Box, 
  LinearProgress, 
  Typography, 
  Button 
} from '@mui/material';



export default function UploadFile(props) 
{

    const selectDecider = (props.fileUploadJobActive.complete || props.fileUploadJobActive.isActive)
    const controlStyle = { 
      select: { outerClass: 'mar-b5 col-xs-12 col-sm-10' }
    }

    const handleTableFilterChange = (property, value) => {
      props.setTableFilter({ ...props.tableFilter, [property] : value });
    }

    return <Fragment>
      <DialogTitle >Please select a Document to upload</DialogTitle>
      <DialogContent>
        <p className={"mar-b10"}>
          <u>Please note</u>: <i>your file will upload directly into M-Files and will display on the document list a short time later (up to 15 seconds). Updates are available.</i>
        </p>
        {Boolean(props.selectedFile && props.fileUploadJobActive.progress) && (
          <Box className="mb25" display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={props.fileUploadJobActive.progress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${props.fileUploadJobActive.progress}%`}</Typography>
            </Box>
          </Box>)
        }
        {props.selectedFile && props.fileUploadJobActive.status && 
          <p>Status: {props.fileUploadJobActive.status}</p>}
        {<Typography sx={{ mt: 2 }} className="list-header">
          {functions.addSelectFieldUILIB('selectClass', selectDecider, controlStyle.select, props.classData, props.tableFilter.classId, (ev) => handleTableFilterChange('classId', ev.target.value))}
        </Typography>}
        <Box 
          display="flex" 
          alignItems="center"
          sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', bgcolor: 'background.paper', borderRadius: 1 }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <label htmlFor="btn-upload">
              <input
                id="btn-upload"
                name="btn-upload"
                style={{ display: 'none' }}
                type="file"
                disabled={props.fileUploadJobActive.complete || props.fileUploadJobActive.isActive || props.tableFilter.classId === 'unlinked'}
                onChange={async (ev) => await functions.handleSelectedFile(ev, props)}
                />
              <Button
                size="small"
                className="btn-choose"
                variant="outlined"
                component="span"
                disabled={props.fileUploadJobActive.complete || props.fileUploadJobActive.isActive || props.tableFilter.classId === 'unlinked'}
              > Select File
              </Button>
            </label>
          </Box>
          <Box>
            <Button
              size="small"
              className="btn-upload"
              color="primary"
              variant="contained"
              component="span"
              disabled={!props.selectedFile || (props.fileUploadJobActive.complete || props.fileUploadJobActive.isActive || props.tableFilter.classId === 'unlinked')}
              onClick={async () => await functions.handleFileUpload(props, 200)}
              sx={{ ml: 1, backgroundColor: '#00B200' }}
            > Upload
            </Button>
            <Button
              size="small"
              className="btn-upload"
              color="primary"
              variant="contained"
              component="span"
              disabled={props.fileUploadJobActive.isActive}
              onClick={async () => await functions.handCloseUploadModal(props)}
              sx={{ minWidth: 70, ml: 1, backgroundColor: props.selectedFile && props.fileUploadJobActive.complete ? '#00B200' : '#e74c3c' }}
            > {props.selectedFile && props.fileUploadJobActive.complete ?  'Exit' : 'Cancel'}
            </Button>
          </Box>
        </Box>
        {props.selectedFile && props.selectedFile.name && <Typography sx={{ mt: 2 }} className="list-header">
          <p >Selected file: <u>{props.selectedFile.name}</u></p>
        </Typography>}
      </DialogContent>
    </Fragment>
}