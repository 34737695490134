import { useState, Fragment } from 'react';
import UILIB from 'components';
import Modules from '../Modules';


export default function Reports ({ filters, setFilters }) {

  const [button, setButton] = useState(null);

  const handleButtonsChanged = (event) => {
    setButton(event.target.name);
  };


  return <Fragment>
    <UILIB.Paper className="mar-r5 mar-l5">
      <div className="flex-container row mar-b10">
        <div className="flex-item flex-grow-1 start wrap">
          <UILIB.Button className={"mar-l5 mar-r5 small " + (button === "salesReport" ? "primary" : "secondary")} name="salesReport" value="Sales Report" onClick={(ev) => handleButtonsChanged(ev)} />
          <UILIB.Button className={"mar-l5 mar-r5 small " + (button === "machineReport" ? "primary" : "secondary")} name="machineReport" value="Machine Report" onClick={(ev) => handleButtonsChanged(ev)} />
          <UILIB.Button className={"mar-l5 mar-r5 small " + (button === "totalSalesReport" ? "primary" : "secondary")} name="totalSalesReport" value="Total Sales Report" onClick={(ev) => handleButtonsChanged(ev)} />
          <UILIB.Button className={"mar-l5 mar-r5 small " + (button === "psSalesReport" ? "primary" : "secondary")} name="psSalesReport" value="PS Sales Report" onClick={(ev) => handleButtonsChanged(ev)} />
        </div>
      </div>  
      {(button === 'salesReport') && <Modules.SalesReport filters={filters} setFilters={setFilters} />}
      {(button === 'machineReport') && <Modules.MachineReport filters={filters} setFilters={setFilters} />}
      {(button === 'totalSalesReport') && <Modules.TotalSalesReport filters={filters} setFilters={setFilters} />}
      {(button === 'psSalesReport') && <Modules.PSSalesReport filters={filters} setFilters={setFilters} />}
    </UILIB.Paper>  
  </Fragment>  
}
