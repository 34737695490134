import { Fragment } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import DocumentTypes from './documentTypes';
import DocumentList from './documentList';


export default function DocumentsPage(props) {
    return <Fragment>
        <div className={"col-xs-12 mar-b25 col-xs-12 "}>
            <DocumentTypes {...props} />
        </div>
        <div className={"col-xs-12 mar-b25 col-xs-12 "}>
            <DocumentList {...props} />
        </div>
    </Fragment>
}