import UILIB from 'components';
import Axios from 'classes/axios';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import MuiCollapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import { searchArray } from '../../../../classes/helpers';


export function styleCollapse() {
  const Collapse = styled((props) => (
    <MuiCollapse 
      disableGutters 
      sx={{ backgroundColor: '#EFEFEF' }}
      {...props} 
    />
  ))(({ theme }) => ({
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:before': {
      display: 'none',
    },
  }));
  return Collapse
}

export function deleteProductCell(row, props) {
  return (
    row.hasPartConflictingBind || row.hasPartCompatibleBind ? 
      <UILIB.PermissionsWrapperNew 
        departments={props.groupsObject('deleteOption', props.localPermissions.groups, props.permissions, props.location.pathname)} 
        account={props.localPermissions.account}
      >
        <div 
            className="center icon icon-question-circle" 
            title="Info" 
            alt="Info" 
            onClick={() => { props.setDeleting(row) }} 
            style={{ padding: 0, float: 'left', cursor: "pointer" }}
        />
      </UILIB.PermissionsWrapperNew> : 
      <UILIB.PermissionsWrapperNew 
        departments={props.groupsObject('deleteOption', props.localPermissions.groups, props.permissions, props.location.pathname)} 
        account={props.localPermissions.account}
      >
        <div 
            className="center icon icon-trash2" 
            title="Delete" 
            alt="Delete" 
            onClick={() => { props.setDeleting(row) }} 
            style={{ padding: 0, float: 'left', cursor: "pointer" }}
        />
      </UILIB.PermissionsWrapperNew>
  )
}

export function addTableRefreshButton(sx, props, renderHandle, setRenderHandle) {
  return ( 
    <LoadingButton
      size="small"
      sx={sx}
      onClick={() => { props.setRenderLoading(true); setRenderHandle(renderHandle === 0 ? 1 : 0) }}
      loading={props.renderLoading}
      loadingPosition="start"
      startIcon={<RefreshIcon/>}
      variant="contained"     
    >
      {'Refresh'}
    </LoadingButton>
  )
}

export function addTableExportButton(sx, props) {
  return ( 
    <LoadingButton
      size="small"
      sx={sx} 
      disabled={!props.permissionsObject('exportOption', props.localPermissions.permissions, props.permissions, props.location.pathname).entityEnable}
      onClick={async () => await exportParts(props)}
      loading={props.exporting}
      loadingPosition="start"
      startIcon={<SaveIcon/>}
      variant="contained"     
    >
      {'Export'}
    </LoadingButton>
  )
}

export function updateColumnFilterHolder(columnFilterHander, setColumnFilterHander, property, value) {
  const columnFilterHanderNew = structuredClone(columnFilterHander);  
  if(String(property) === 'filter') {
    if(!columnFilterHanderNew.columnFilters.some(chip => String(chip.id) === String(columnFilterHanderNew.headName))) {
      columnFilterHanderNew.columnFilters.push({ 
        [columnFilterHanderNew.headValue]: value,
        tableName: columnFilterHanderNew.tableName,
        columnName: columnFilterHanderNew.columnName,
        id: columnFilterHanderNew.headName, 
        headName: columnFilterHanderNew.headName,
        headValue: columnFilterHanderNew.headValue,
        filterText: value
      })
    }
    else {
      const foundFilter = columnFilterHanderNew.columnFilters.find(f => f.id === columnFilterHanderNew.headName);
      if(foundFilter && Number(value.length) === 0) {
        columnFilterHanderNew.columnFilters = columnFilterHanderNew.columnFilters.filter(f => f.id !== columnFilterHanderNew.headName);
      }
      foundFilter.filterText = value;
    }
  }
  columnFilterHanderNew[property] = value;
  setColumnFilterHander(columnFilterHanderNew);
}

export function addColumnFilterHolder(columnFilterHander, setColumnFilterHander, filterInput, productRender, setProductRender) {
  const hideFilter = () => {
    updateColumnFilterHolder(columnFilterHander, setColumnFilterHander, 'active', false);
  }

  let type = 'string';
  switch (columnFilterHander.propertyType) {
    case 'number':
        type = 'number';
        break;
    case 'string':
        type = 'string';
        break;
    case 'date':
        type = 'date';
        break;
    default:
        break;
  }

  const content = (
  
    <UILIB.TextInput 
      className="textInput input"
      type={type}
      value={columnFilterHander.filter} 
      ref={filterInput} 
      onChange={(event) => updateColumnFilterHolder(columnFilterHander, setColumnFilterHander, 'filter', event.target.value)} 
      focus onKeyPress={e => {
        if (e.key === 'Enter') {
          setProductRender(productRender === 0 ? 1 : 0);
          updateColumnFilterHolder(columnFilterHander, setColumnFilterHander, 'active', false);
        }
      }}
      
  />)

  return ( 
    <div className="columnFilterHolder" style={{ top: columnFilterHander.clientY - 75, left: columnFilterHander.clientX }}>
      <UILIB.ClickAway onClickAway={hideFilter}>
        <UILIB.Paper>
          <div className="form-group">
            <label style={{ fontSize: 13, padding: 0, maring: 0 }}>{columnFilterHander.headName}</label>
            {content}
          </div>
        </UILIB.Paper>
      </UILIB.ClickAway>
    </div>
  )
}

export async function expandRowHander(row, id, handler, open) {
  handler(d => ({ ...d, [id]: open ? false : true }))
}


export function columnFilterHander(event, headName, tableName, headValue, columnName, propertyType, columnFilterHander, setColumnFilterHander) {
  const tableHandlerNew = structuredClone(columnFilterHander);
  tableHandlerNew.active = true;
  tableHandlerNew.headName = headName;
  tableHandlerNew.tableName = tableName;
  tableHandlerNew.columnName = columnName;
  tableHandlerNew.headValue = headValue;
  tableHandlerNew.propertyType = propertyType;
  tableHandlerNew.clientX = event.clientX;
  tableHandlerNew.clientY = event.clientY;
  tableHandlerNew.filter = '';
  tableHandlerNew.columnFilters = columnFilterHander.columnFilters;
  setColumnFilterHander(tableHandlerNew);
}

export function searchTextTrigger(props, filtered, Row) {

  let filterDataArray = [];
  let products = [];

  for(let row of props.tableDataRaw) { 

    const rangesLocal = row.SalesPartRanges.map(r => r.name).join(', ');
    const compatibles = row.compatibles ? row.compatibles.map(r => r.name).join(', ') : '';

    filterDataArray.push({
      id: row.id,
      name: row.name ? row.name : '',
      rangesRaw: row.SalesPartRanges ? row.SalesPartRanges : [],
      range: rangesLocal ? rangesLocal : '',
      compatiblesRaw: row.compatibles ? row.compatibles : [],
      compatibles: compatibles,
      incompatiblesRaw: row.incompatiblesRaw ? row.incompatiblesRaw : [],
      incompatibles: row.incompatibles || '',
      partNumber: row.partNumber || '',
      manufacturerPartNumber: row.manufacturerPartNumber || '',
      salesPartType: row.SalesPartType ? row.SalesPartType.name : '',
      manufacturer: row.manufacturer ? row.manufacturer.name : '',
      supplier: row.supplier ? row.supplier.name : '',
      listPrice: row.listPrice || '',
      partnerPrice: row.partnerPrice || '',
      basePrice: row.basePrice || '',
      serviceMeters: row.meterData ? row.meterData.label : '',
      category: row.SalesPartCategory ? row.SalesPartCategory.name : '',
      hasPartConflictingBind: row.hasPartConflictingBind,
      hasPartCompatibleBind: row.hasPartCompatibleBind,
      delete: row.hasPartConflictingBind || row.hasPartCompatibleBind ? 0 : 1
    })
  }

  const filterColumns = [
    'range',
    'incompatibles',
    'partNumber',
    'manufacturerPartNumber',
    'name',
    'salesPartType',
    'manufacturer',
    'supplier',
    'listPrice',
    'partnerPrice',
    'basePrice',
    'serviceMeters',
    'category',
    'delete'
  ]

  const tableDataFiltered = filtered.length ? searchArray(filterDataArray, [filtered], filterColumns) : filterDataArray;
  const sorted = stableSort(tableDataFiltered, getComparator(props.partsTableSortHandler.direction, props.partsTableSortHandler.headName));
  sorted.map((row, index) => products.push(<Row key={index} row={{...row, props}} />));

  return { products: products, length: tableDataFiltered.length, tableDataRaw: tableDataFiltered };
}


export function addSortedHeader(name, width, propertyName, columnName, propertyType, colour, tableName, tableHandler, setTableHandler, columnHander, setColumnHander, productRender, setProductRender) {
  return (
    <TableCell 
        key={name} 
        sortDirection={tableHandler.direction}  
        sx={{ backgroundColor: colour, width: width, fontSize: '12px', pl: 1, pr: 0, m: 0 }}
      > 
      <Box display="flex" justifyContent="flex-start">
        <div 
          className="filterIcon active" 
          style={{ marginRight: 2 }} 
          onClick={(event) => columnFilterHander(event, name, tableName, propertyName, columnName, propertyType, columnHander, setColumnHander)}
        ><span className="icon-funnel"></span>
        </div>
        <TableSortLabel 
          name={name} 
          active={tableHandler.headName === name ? true : false} 
          direction={tableHandler.direction} 
          onClick={() => createSortHandler(propertyName, tableName, columnName, tableHandler, setTableHandler, productRender, setProductRender)}>
          {name} 
        </TableSortLabel>
      </Box>
    </TableCell>
  )
}


export function createSortHandler(event, tableName, columnName, tableHandler, setTableHandler, productRender, setProductRender) {
  const activeLocal = event ? (tableHandler.headName === event ? tableHandler.active : true) : tableHandler.active;
  const directionLocal = tableHandler.direction === 'asc' ? 'desc' : 'asc';
  setTableHandler({ ...tableHandler, tableName: tableName, columnName: columnName, headName: event, active: activeLocal, direction: directionLocal });
  setProductRender(productRender === 0 ? 1 : 0);
}

export function stableSort(array, comparator) {
  
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy)  {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export async function exportParts(props) {
  props.setExporting(true);
  props.setAlertMessage('Exporting');
  props.setAlertOpen(true);
  try {
      const res = await Axios.get('/sales/parts/export');
      const link = document.createElement('a');
      link.download = "parts export.csv";
      link.href = 'data:text/csv;base64,' + res.data;
      document.body.appendChild(link);
      link.click();
  } catch (e) {
    props.setAlertMessage('Error Exporting');
    props.setAlertOpen(true);
  }
  props.setExporting(false);
};


export async function deleteProduct(deleting, setDeleting, tableDataRaw, setTableDataRaw) {
  await Axios.delete(`/sales/parts/${deleting.id}`);
  setTableDataRaw(tableDataRaw.filter(f => f.id !== deleting.id));
  setDeleting(null);
};