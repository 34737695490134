import { Fragment } from 'react';
import { formatDateTime } from 'classes/format';
import { dispatchedChip } from '../../consumablesHub/functions';
import Axios from 'classes/axios';
import UILIB from 'components';

export default function DispatchedOrdersTable({ entityLookup }) {
    const headerData = [
        { label: 'Date', value: 'OrderDate', type: 'date', filtering: true },
        { label: 'Part #', value: 'PartNumber', type: 'string', filtering: true },
        { label: 'Description', value: 'Details', type: 'string', filtering: true },
        { label: 'Serial Number', value: 'SerialNumber', type: 'string', filtering: true },
        { label: 'Qty', value: 'Quantity', type: 'string', filtering: true },
        { label: 'Order #', value: 'Reference', type: 'string', filtering: true },
        { label: 'Status', value: 'orderStatus', type: 'string', filtering: true }];

    const tablePageDefaults = { paging: { pages: [10,20,50,100,200], limit: 20, offset: 0, orderBy: 'OrderDate', orderDir: 'DESC' } };
    
    async function constructTable(data) {
      const tableData = data.map(row => {

        return {
          OrderDate: { value: formatDateTime(row.OrderDate, 'DD/MM/YYYY'), raw: row.OrderDate },
          PartNumber: { value: row.PartNumber, raw: row.PartNumber },
          Details: { value: row.Details, raw: row.Details },
          SerialNumber: { value: row.SerialNumber, raw: row.SerialNumber },
          Quantity: { value: row.Quantity, raw: row.Quantity },
          Reference: { value: row.Reference, raw: row.Reference },
          orderStatus: { value: dispatchedChip(row), raw: row.orderStatus }
        }
      })
      return tableData;
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
      const apiData = {};
      const queryLocal = (query !== null && query !== 'and ') ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      apiData.productResult = await Axios.get(`/entities/salesOrders/getSummary?&$filter=CustomerId eq ${entityLookup.customerId} and Details notLike delivery and Details notLike next and deletedDate is null ${queryLocal}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      return { tableData: await constructTable(apiData.productResult.result), nonePaged: apiData.productResult.nonePaged };
    }

    return <Fragment>
      <div className="pad-5 width-100">
        <b className="flex-item row  width-100 start text-12 pad-b2 mar-b2">Recently Despatched</b>   
        <div className="width-100">    
          <UILIB.TableNew
            name="DispatchedOrders"
            className='small'
            overflowX='auto'
            overflowY='hidden'    
            header={headerData}
            remoteQuery={getTableData}
            remoteRender={[entityLookup]}
            defaultSettings={tablePageDefaults} />
        </div>
      </div>
    </Fragment>
}