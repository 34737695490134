export default function ProgressBar({ complete, error = false, width = '100%', height = 20, fontSize = 14, colour }) {

    const containerStyles = {
        height: height,
        width: `${width}`,
        backgroundColor: "#e0e0de",
        borderRadius: 50,
    }

    const fillerStyles = {
        height: '100%',
        width: `${complete}%`,
        fontSize: fontSize,
        backgroundColor: error ? '#E93D24' : complete === 100 ? '#00B200' : colour ? colour :'#000033',
        borderRadius: 'inherit',
        textAlign: 'right'
    }

    const labelStyles = {
        padding: 5,
        color: 'white',
        fontWeight: 'bold'
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${complete}%`}</span>
            </div>
        </div>
    );
}