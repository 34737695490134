
export default function ExportButton({ onClick }) {


    return <div className="text-small exportButton" onClick={onClick}>
        <span className="icon-arrow-down" /> Export
    </div >


}
