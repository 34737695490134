
import { Fragment } from 'react';
import { formatCurrency } from 'classes/format';

export default function PricingContainer({ row }) {

  return <Fragment>
    <div className="flex-container column nowrap height-100 width-100">
      <div className="flex-container row nowrap">
          <div className="flex-item start sopHub columns">Invoice:</div>
          <div title={row.pricing.total} className="flex-item end flex-grow-1 sopHub columns">{formatCurrency(row.pricing.total)}</div>
      </div>
      <div className="flex-container row nowrap">
          <div className="flex-item start sopHub columns">GP:</div>
          <div title={row.pricing.gp} className="flex-item end flex-grow-1 sopHub columns">{formatCurrency(row.pricing.gp)}</div>
      </div>
    </div>
    </Fragment>
}
