import { useState, useReducer, useEffect, useRef, memo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { setDrawer } from "store/actions";
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from 'react-router';
import { store } from 'store/store'
import UILIB from 'components';
import Axios from 'classes/axios';
import Modules from '../Modules';
import QuoteDetailPage from './detailPage/quoteDetails';
import AdditionalInfoPage from '../shared/additionalInfoPage/additionalInfoPage';
import SwapQuoteDrawer from '../shared/drawer/swapQuoteDrawer';
import ConfirmCreateOrder from '../shared/drawer/confirmCreateOrderDrawer';
import DocumentsPage from '../shared/documents/documentsPage';
import { DATA_TO_LOAD, reducer, reduceArray } from '../shared/reducer';
import * as functions from '../shared/functions';
import * as sideLoad from '../shared/sideLoad';


const BlockQuotes = memo(() => 
{
  const dispatch = useDispatch();
  const state = store.getState();
  const account = state.account;
  const history = useHistory({ forceRefresh: true });
  const params = useParams();
  const location = useLocation();
  const route = location.pathname;
  const productTickedEvent = useRef({ selectedProducts: [], productCount: 0 });
  const processData = useRef('');
  const userAccess = useRef([]);
  const [isActive, setisActive] = useState(false);
  const [localEvent, setLocalEvent] = useState({ timeout: 200 });
  const [opportunityNumber, setOpportunityNumber] = useState(0);
  const [dealLoaded, setDealLoaded] = useState(false);
  const [quoteSheetLoading, setQuoteSheetLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [refreshData, setRefreshData] = useState(false);
  const [dealAddress, setDealAddress] = useState([]);
  const [render, setRender] = useState(Math.random());
  const [activeFields, setActiveFields] = useState([]);
  const [showAddQuoteModal, setShowAddQuoteModal] = useState(false);
  const [dealData, setDealData] = useState(undefined);
  const [dataLoaded, setDataLoaded] = useState(reduceArray(DATA_TO_LOAD)); 
  const [dataToLoad, reducerDispatch] = useReducer(reducer, { dataLoaded: [] }); 
  const [productConfigurationModal, setProductConfigurationModal] = useState({
    open: false,
    type: null,
    product: null,
    dealProductId: null,
    portalProductId: null
  });
  const [fieldWorkflowObj, setFieldWorkflowObj] = useState({
    leaseCompanyInfo: false,
    leaseRate: false,
    rvIncluded: false,
    rvPercent: false,
    companyPaymentTerms: false,
    companyPaymentLength: false,
    leasePeriodPayment: false,
    dealCurrency: false,
    leasePeriod: false,
    rebateValue: false,
    rebatePercent: false,
    isMember: false,
    cpcLocation: false,
    cpcLot: false,
    serviceTerm: false,
    serviceContract: false,
    scottishProcurement: false,
    scottishProcurementLease: false,
    hidePanelRight: false,
    organisation: false,
    sector: false,
    procurementLevel: false
  });


  const loadQuote = async (dealID) => {
    try {

      setDealLoaded(false);

      let finalDealId = undefined;
      if (dealID) {
        finalDealId = dealID;
      } else if (dealLoaded && dealData && dealData.dealID) {
        finalDealId = dealData.dealID;
      } else {
        throw new Error('error loading deal, no deal id');
      }

      //load deal data
      const baseData = await Axios.get(`/entities/workflow/deals/actionGetDealData/${finalDealId}`); 

      //redirect if not a quote
      if (baseData.data.result.processStage > 0) history.push(route.replace('/quote/', '/order/'));

      //check group specific access
      userAccess.current = await functions.checkAccess(account, baseData.data.result);

      //check if deal is active
      setisActive(baseData.data.result.isActive && baseData.data.result.processStage < 2 && Object.values(userAccess.current.read).indexOf(false) > -1);

      //check for user permissions
      if (Object.values(userAccess.current.users).indexOf(false) === -1) {
        history.push({
          pathname: `/salesHub/accountmanagerview/quoteview`,
          state: {
            route: route,
            newQuote: history.location.pathname.indexOf(`${route}/quotes/add`) > -1 ? true : false,
            message: {
              showPermissionMessage: history.location.pathname.indexOf(`${route}/quotes/add`) === -1 ? true : false,
              dealNumber: baseData.data.result.dealID,
              crmOwner: baseData.data.result.crm?.systemusers?.fullname,
              processStage: baseData.data.result.processStage
            }
          }
        })
      }

      //calcualte active fields
      const calculateFields = {};
      Object.keys(baseData.data.result).forEach(k => calculateFields[`${k}-${k}`] = false);
      setActiveFields(calculateFields);

      processData.current = functions.getQuoteProcessData(baseData.data.result);

      setDealData(baseData.data.result);
      setDealLoaded(true);
    }
    catch (error) {
      console.log(error);
      if (history && history.location && history.location.key) {
        history.goBack();
      }
      else {
        history.push('/');
      }
    }
  }

  const sideLoadData = (dealID, oppNumber, accountNo) => {
    sideLoad.getProducts(reducerDispatch, dealID);
    sideLoad.getCrm(reducerDispatch, oppNumber);
    sideLoad.getDocuments(reducerDispatch, oppNumber);
    sideLoad.getCompatibles(reducerDispatch);
    sideLoad.getPSUsers(reducerDispatch);
    sideLoad.getDeviceNumbers(reducerDispatch, dealID);
    sideLoad.getHostConfig(reducerDispatch);
    sideLoad.getOtherDeals(reducerDispatch, oppNumber);
    sideLoad.getRemovals(reducerDispatch, dealID);
    sideLoad.getRemovalStores(reducerDispatch, accountNo);
    sideLoad.updateDealData(reducerDispatch, dealID, oppNumber);
    sideLoad.getProcurmentData(reducerDispatch);
    sideLoad.getDealPricing(reducerDispatch, dealID);
    sideLoad.getKeyContacts(reducerDispatch, dealID);
    sideLoad.getNotesData(reducerDispatch, dealID);
    sideLoad.getSettlementData(reducerDispatch, dealID);
    sideLoad.getAddressData(reducerDispatch, dealID);
    sideLoad.getInvoiceAddressData(reducerDispatch, dealID);
  }

  useEffect(() => {
    if(!dealLoaded) return;
    sideLoadData(dealData.dealID, dealData.dynamicsOpportunityNumber, dealData.accountNo);
  }, [dealLoaded])

  useEffect(() => {
    if(!dealLoaded) return;
    setDealData({ 
      ...dealData, 
      ...dataToLoad,
      addressDropdown: functions.getAddressDropdown({ ...dealData, ...dataToLoad })
    });
  }, [dataToLoad])

  useEffect(() => {
    if(!dealLoaded) return;
    setDataLoaded({ ...dataLoaded, ...dataToLoad.dataLoaded });
  }, [dataToLoad])
  
  useEffect(() => {
    functions.fieldWorkflow(dealData, {
      fieldWorkflowObj: fieldWorkflowObj,
      setFieldWorkflowObj: setFieldWorkflowObj,
      formErrors: null,
      setFormErrors: null
    });
  }, [dealData])

  useEffect(() => {
    const asyncEffect = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const qString = Object.fromEntries(urlSearchParams.entries());

      if ((qString && qString.OpportunityNumber) && typeof (qString.OpportunityNumber) !== 'undefined') {
        setOpportunityNumber(qString.OpportunityNumber);
      }
      else if (params && params.dealID) {
        await loadQuote(params.dealID);
      }
      else {
        history.push("/");
      }
    }
    asyncEffect();
  }, [])

  useEffect(() => {
    const asyncEffect = async () => {
      if (opportunityNumber !== 0) await lookupCRMOpportunity();
    }
    asyncEffect();
  }, [opportunityNumber])

  useEffect(() => {
    try {
      let activeFieldsLocal = {};
      
      Object.keys(activeFields).forEach(x => (x === `${localEvent?.event?.target?.name}-${localEvent?.event?.target?.name}`) 
        ? activeFieldsLocal[x] = false 
        : activeFieldsLocal[x] = true); 
      setActiveFields(activeFieldsLocal);
  
      const timer = setTimeout(async () => {

        const element = document.getElementsByName(`${localEvent?.event?.target?.name}`);
        if(element.length) element[0].disabled = true;

        if (Number(localEvent?.type) === 0) await functions.updateMainFormEntity(dealData, setDealData, localEvent?.event);
        if (Number(localEvent?.type) === 2) {
          await functions.updateMainFormEntity(dealData, setDealData, localEvent?.event);
          functions.fieldWorkflow(dealData, { fieldWorkflowObj: fieldWorkflowObj, setFieldWorkflowObj: setFieldWorkflowObj });
        }
        if (Number(localEvent?.type) === 3) {
          const mapped = dealData.product.map(row => { 
            return { 
              prodID: row?.prodID, 
              dealID: row?.dealID, 
              rowVersion: row?.rowVersion, 
              cpcMono: row?.cpcMono, 
              cpcColour: row?.cpcColour,
              supression: true,
              lease: row?.lease }
          });

          await functions.updateMainFormEntity(dealData, setDealData, localEvent?.event);
          await functions.updateProducts(dealData?.dealID, mapped);
        }
        if(Number(localEvent?.type) === 4) {
          await functions.getDealPricing(dealData?.dealID, dealData, setDealData);
          await functions.updateLeasePeriodPayment(dealData, setDealData);
        }

        if(element.length) element[0].disabled = false;
        if(element.length) element[0].focus();
        setRender(Math.random());
        
      }, localEvent.timeout);
      return () => clearTimeout(timer);

    } catch (error) {
      console.log(error);
      setRender(Math.random());
    }
  }, [localEvent])

  useEffect(() => {
    let activeFieldsLocal = {};
    Object.keys(activeFields).forEach(x => activeFieldsLocal[x] = false);
    setActiveFields(activeFieldsLocal);
  }, [render])


  const lookupCRMOpportunity = async (addQuote) => {
    try {

      const oppNumber = opportunityNumber || dealData.data.result.dynamicsOpportunityNumber;
      const postData = { addAdditional: addQuote ? addQuote : false };

      const ourDealData = await Axios.post(`/sales/deals/byOpportunity/${oppNumber}`, postData);

      history.push(`/salesHub/accountmanagerview/quote/${ourDealData.data.dealID}`);

      if (ourDealData.data.duplicate === false) await loadQuote(ourDealData.data.dealID);
      if (ourDealData.data.duplicate === true) {
        history.push({
          pathname: `/salesHub/accountmanagerview/quoteview`,
          state: {
            message: {
              duplicate: true,
              opportunityNumber: ourDealData.data.opp
            }
          }
        })
      }
    }
    catch (error) {
      console.log(error);
      history.push({
        pathname: `/salesHub/accountmanagerview/quoteview`,
        state: {
          message: {
            response: error.data.result,
            error: true
          }
        }
      })
    }
  }

  const confirmCreateOrder = () => {
    dispatch(setDrawer({ open: false, content: null }));
  }

  const createOrder = () => {
    dispatch(setDrawer({
      open: true,
      content: <ConfirmCreateOrder
        onConfirm={async () => confirmCreateOrder()}
        ourDeal={dealData}
        isActive={isActive}
        route={route}
        history={history}
        onCancel={() => dispatch(setDrawer({ open: false, content: null }))}
      />
    }));
  }

  const copyQuote = () => {
    dispatch(setDrawer({
      open: true,
      width: "1000px",
      content: <Modules.QuoteCopyDrawer
        onFinished={() => dispatch(setDrawer({ open: false, content: null }))}
        account={account}
        ourDeal={dealData}
        isActive={isActive}
      />
    }));
  }

  const swapQuote = () => {
    dispatch(setDrawer({
      open: true,
      width: "450px",
      content: <SwapQuoteDrawer
        onFinished={async (ev) => await swappedQuote(ev)}
        otherQuotes={dealData.otherDeals}
        ourDeal={dealData}
        isActive={isActive}
        dealID={dealData.dealID}
      />
    }));
  }

  const swappedQuote = async (dealID) => {
    await loadQuote(dealID);
    history.push(`/salesHub/accountmanagerview/quote/${dealID}`);
    dispatch(setDrawer({ open: false, content: null }));
  }


  const tabs = [{ label: `${processData.current.typeText} Details`, value: 0 }, { label: "Additional Info", value: 1 }, { label: "Documents", value: 2 }]

  if (!dealLoaded) return <UILIB.Loading type={3} />

  return <div className="flex-container row wrap mar-t10 mar-r5 mar-l5">
    {showAddQuoteModal && 
      <Modules.AddAdditionalQuote 
        ourDeal={dealData} 
        history={history}
        route={route}
        setShowAddQuoteModal={setShowAddQuoteModal} />}
    <div className="flex-container row wrap start width-100">
      <div className="flex-item start flex-grow-1">
        <UILIB.Button
          className="outline"
          value="Home"
          onClick={() => history.push('/')} />
      </div>
    </div>
    <Modules.DealHeader ourDeal={dealData} processData={processData} otherQuotes={dealData.otherDeals} />
    <div className="flex-item end width-100">
      {dealData.dynamicsOpportunityNumber && dealData.dynamicsOpportunityNumber !== "" && dealData.dynamicsOpportunityNumber !== "0" && <div>
        {(Number(isActive) === 0) &&
          <UILIB.Button
            disabled={!dealData.parentId}
            className="mar-b5 small grey"
            value="View Order"
            onClick={() => { functions.viewOrder(dealData, history) }} />}
        {(Number(isActive) === 1) &&
          <UILIB.Button
            disabled={!isActive}
            className="mar-b5 small grey"
            value="Add Additional Quote"
            title='Adds an additional quote for this opportunity'
            onClick={() => setShowAddQuoteModal(true)} />}
        {(dealData.otherDeals && Number(isActive) === 1) &&
          <UILIB.Button disabled={!isActive}
            className="mar-b5 small grey"
            style={{ display: "inline-block" }}
            value="Swap Quote"
            title='View a different quote for this opportunity'
            onClick={() => swapQuote()} />}
        {(Number(isActive) === 1) &&
          <UILIB.Button
            disabled={!isActive}
            className="mar-b5 small grey"
            value="Clone Previous Quote"
            title='Clones non customer specific data including parts and pricing from a previous quote'
            onClick={() => copyQuote()} />}
        {(Number(isActive) === 1) && 
          <UILIB.Button 
            disabled={!isActive} 
            value="Create Draft Order" 
            className="small" 
            onClick={() => createOrder()} />}
      </div>}
    </div>
    <div className="flex-container row wrap between width-100">
      <h2 className="flex-item wrap start">{processData.current.procStage}</h2>
      {<div className="flex-item wrap end">
        <UILIB.Button 
          className="orange"
          loading={quoteSheetLoading}
          value={"Download Quote Sheet"}
          onClick={async () => await functions.generateQuoteSheet(dealData, setQuoteSheetLoading)} />
      </div>}
    </div>
    <UILIB.SubNav tabs={tabs} currentTab={currentTab} outerClasses="mar-t15 mar-b15" onClick={(newId) => setCurrentTab(newId)} />
    {currentTab === 0 &&
      <QuoteDetailPage
        ourDeal={dealData}
        setOurDeal={setDealData}
        account={account}
        activeFields={activeFields}
        refreshData={refreshData}
        setRefreshData={setRefreshData}
        isActive={isActive}
        localEvent={localEvent}
        setLocalEvent={setLocalEvent}
        fieldWorkflowObj={fieldWorkflowObj}
        setFieldWorkflowObj={setFieldWorkflowObj}
        dealAddress={dealAddress}
        setDealAddress={setDealAddress}
        productConfigurationModal={productConfigurationModal}
        setProductConfigurationModal={setProductConfigurationModal}
        productTickedEvent={productTickedEvent}
        dataLoaded={dataLoaded}
      />}
    {currentTab === 1 &&
      <AdditionalInfoPage
        ourDeal={dealData}
        setOurDeal={setDealData}
        isActive={isActive}
        processData={processData}
        setDealAddress={setDealAddress}
        dataLoaded={dataLoaded}
      />}
    {currentTab === 2 &&
      <DocumentsPage
        ourDeal={dealData}
        setOurDeal={setDealData}
        isActive={isActive}
        dataLoaded={dataLoaded}
      />}
  </div >
})
export default BlockQuotes