import Button from '../Button/Button'
import { Fragment, useState } from 'react'
import UILIB from 'components'
import { getDealAddresses, getDeals } from "classes/helpers";

export default function SelectDealModal({ header, textDeal, textAddress, dealData, setDealData, confirmText = 'Continue', cancelText = 'Cancel', onConfirm = () => { }, onCancel = () => { } }) 
{

    const pleaseSelect = { label: 'Please Select', value: -1 };
    const [addressData, setAddressData] = useState([]);
    const [address, setAddress] = useState({});
    const [loadingAdressData, setLoadingAdressData] = useState(false);

    const selectDeal = async (deal) => {
      setDealData({ ...structuredClone(deal), addressId: 0 });
      setLoadingAdressData(true);
      const addressData = await getDealAddresses(deal.value);
      setAddressData([pleaseSelect, ...addressData.map(addr => { return { label: buildSelectData(addr), value: addr.id } })])
      setLoadingAdressData(false);
    } 

    const buildSelectData = (row) => {
      const { id, dealId, telephone, telNo, county, customerName, accountNumber, createdAt, updatedAt, addressType, DeviceInstallationId, ...addressObj } = row;
      return addressObj ? Object.values(addressObj).filter(f => f && f.length).map(val => { return val }).join(', ') : '';
    }

    const selectAddress = (event) => {
        setAddress(addressData.find(f => Number(f.value) === Number(event.target.value)));
        setDealData({ ...structuredClone(dealData), addressId: Number(event.target.value) });
    }

    return <Fragment>
        <div className="modalBackground">
            <div className="modalDealContent" style={{ width: "30%", minWidth: "300px" }}>
                {Boolean(header) && <div className="text-xlarge text-center flex-container center"><p>{header}</p></div>}
                {Boolean(textDeal) && <div className="mar-t20 text-small flex-container center">{textDeal}</div>}
                <div className="mar-t5 mar-b15" style={{ display: "flex" , alignItems: "center", justifyContent: "center" }}>
                  <UILIB.AutoComplete  
                    className="min-height-50 max-width-400"
                    name='dealSearch'
                    blank='No Deals Found'
                    query={getDeals}
                    enableButton={false}
                    onClick={async (data) => await selectDeal(data, dealData)} />
                </div>
                {loadingAdressData && <UILIB.Loading type={3} />}
                {Boolean(Object.entries(dealData).length) && Boolean(addressData.length) && Boolean(textAddress) && !loadingAdressData && <div className="mar-t20 text-small flex-container center">{textAddress}</div>}
                {Boolean(Object.entries(dealData).length) && Boolean(addressData.length) && !loadingAdressData && <div>
                  <div className="mar-t5 mar-b15" style={{ display: "flex" , alignItems: "center", justifyContent: "center" }}>
                    <UILIB.Select 
                        outerstyle={{ width: "80%" }}
                        style={{ padding: 2 }}
                        data={addressData} 
                        value={address.value} 
                        onChange={(ev) => selectAddress(ev)} />
                  </div>
                </div>}
                <div className="flex-container row">
                    <div className="col-xs-12 col-sm-12 col-md-12 flex-container center">
                        <Button 
                          className="primary mar-r5" 
                          value={confirmText} 
                          onClick={onConfirm} 
                          disabled={!Boolean(addressData.length) || Number(address.value) === -1} />
                        <Button className="grey mar-l5" value={cancelText} onClick={onCancel} />
                    </div>
                </div>
            </div>
        </div>
      </Fragment>
}