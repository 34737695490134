import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import MasterTemplate from './cp/masterTemplate.jsx';
import Login from './login/login.jsx';


export default function App() {

  const accessToken = useSelector((state) => state.accessToken);

  return (
    <Switch>
        {!accessToken && <Route exact key="login" to='/login' render={(props) => { return <Login {...props}/> }} />}
        {accessToken && <Route key="materTemplate" path="/"><MasterTemplate /></Route>}
    </Switch>
  );
}