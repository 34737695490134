import { useState, Fragment } from 'react';
import UILIB from 'components';
import { checkFormErrors } from '../../../../../classes/formErrors/formErrors';
import { getDeviceCollectConsumablesFormErrors } from '../../../../../classes/formErrors/repository';
import { createTextInput } from 'classes/controls/genericControls';
import BlockCollectTable from '../tables/blockCollectTable';
import BlockConsumableTable from '../tables/blockConsumableTable';


export default function DeviceCollect({ formData, setFormData,loading }) {

  const emptyRow = { tonerCode: '', cyan: '', magenta: '', yellow: '', black: '', wasteBox: '', staples: '' };
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [localData, setLocalData] = useState(emptyRow);
  const [formErrors, setFormErrors] = useState({});

  const updateRowData = (event) => {
    setLocalData({ ...localData, [event.target.name]: event.target.value });
  }

  const closeDrawer = () => {
    setLocalData(emptyRow);
    setFiltersOpen(false);
    setFormErrors({});
  }

  const submitForm = async () => {
    const formResult = await checkFormErrors(getDeviceCollectConsumablesFormErrors(localData), setFormErrors, null, null, localData, null);
    if(formResult.errorCount > 0) return;
    const formDataNew = structuredClone(formData);
    formDataNew.consumables.push(localData);
    setFormData(formDataNew);
    setLocalData(emptyRow);
    setFiltersOpen(false); 
  }


  if (loading) return <UILIB.Loading type={3} />

  return  <Fragment>
            <div className="row">
              <div className="col-xs-12">
                <UILIB.Paper className="col-xs-12 mar-b15">
                  <div className="flex-container between mar-b15">
                      <b>Kit Being Removed</b>
                  </div>
                  <BlockCollectTable
                    data={formData}
                    setFormData={setFormData}
                    type={"removals"}
                    sortable={true}
                    filter={true}
                  />
                </UILIB.Paper>    
              </div>
              <div className="col-xs-12">
                <UILIB.Paper className="col-xs-12 mar-b15">
                  <div className="flex-container between mar-b15">
                    <b>Consumables to collect</b>
                    <UILIB.Button 
                      value="Add Consumable" 
                      onClick={() => {
                        if (filtersOpen) setFiltersOpen(false)
                        else {
                          setFiltersOpen(true)
                        }
                      }} 
                    />
                  </div>
                    <BlockConsumableTable
                      consumablesTable={formData.consumables}
                      sortable={false}
                      filter={false}
                    />
                </UILIB.Paper>
              </div>
              <div className="col-xs-12 flex-container end mar-b15 mar-t15">
                <UILIB.StandaloneDrawer width={400} isOpen={filtersOpen} showClose={true} onClose={() => { closeDrawer() }}>
                  <h2>Add Consumable</h2>
                  <div className="mar-b20">
                    {createTextInput('Toner code', 'tonerCode', true, 100, localData, formErrors, (event) => updateRowData(event))}
                    {createTextInput('Cyan', 'cyan', true, 100, localData, formErrors, (event) => updateRowData(event))}
                    {createTextInput('Magenta', 'magenta', true, 100, localData, formErrors, (event) => updateRowData(event))}
                    {createTextInput('Yellow', 'yellow', true, 100, localData, formErrors, (event) => updateRowData(event))}
                    {createTextInput('Black', 'black', true, 100, localData, formErrors, (event) => updateRowData(event))}
                    {createTextInput('Waste Box', 'wasteBox', true, 100, localData, formErrors, (event) => updateRowData(event))}
                    {createTextInput('Staples', 'staples', true, 100, localData, formErrors, (event) => updateRowData(event))}
                    <UILIB.Button
                      name="addRow"
                      value="Add"
                      onClick={() => { submitForm(); }}
                    />
                  </div>
                </UILIB.StandaloneDrawer>
              </div>
            </div>
          </Fragment>
}


