
import { Fragment } from 'react';
import { formatDateTime } from 'classes/format';

export default function InvoiceContainer({ row }) {

  return <Fragment>
      <div className="flex-container column wrap height-100 width-100">
        <div title={row.invoiceNo} className={`flex-item`}>{row.invoiceNo}</div>
        <div title={row.invoiceDate} className={`flex-item`}>{formatDateTime(row.invoiceDate, 'DD/MM/YYYY')}</div>
      </div>
    </Fragment>
}
