import { Fragment } from 'react';

export default function ColourReadings({ row })
{
    return <Fragment>
        <div className="flex-container row width-100">
            {row.meters.filter(x => x.meterType === 2).map((x,i) => { return <div key={`cr_${i}`} className={`flex-item start width-100 refurbHub mono-readings-row`}>
                {`${x.cType}: ${x.cReading}`}
            </div>})}
        </div>
    </Fragment>
}