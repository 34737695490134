import { Fragment } from 'react';

export default function PwdRevealButton(props) {

    const myProps = { ...props }
    let errored = { messageText: "", option: 0 }

    let className = "button";
    if (props.className) className += " " + props.className;

    let style = props.style || {};

    let value = props.value;
    let iconBefore = "";
    let icon = "";
    if (props.iconBefore) {
        iconBefore = <span className={"iconBefore " + props.iconBefore} />
        delete myProps.iconBefore
    }
    if (props.icon) {
        icon = <span className={"iconAfter " + props.icon} />
        delete myProps.icon
    }

    if (props.errored) errored = props.errored


    let errorType;
    let errorMessage;
    if (typeof errored === "object") {
        errorType = errored.option;
        errorMessage = errored.messageText;
    }

    let onClickEv = props.onClick || function () { }
    const type = props.type || 'button'

    if (props.disabled) {
        style.cursor = "not-allowed"
        onClickEv = function () { };
    }

    let errorClass = ""
    if(errored.error && errorType === 0 && !props.disabled) errorClass =  " errored button outline ";

    return <Fragment>
      {!errored.error &&
        <button {...myProps} type={type} style={style} onClick={() => onClickEv(props.name)} className={className + errorClass} >
          {iconBefore} {value} {icon}
      </button>}
      {errored.error && errorType === 0 &&
        <button {...myProps} type={type} style={style} onClick={() => onClickEv(props.name)} className={className + errorClass} >
          {iconBefore} {value} {icon}
      </button>}
      {errored.error && errorType === 1 && <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <button {...myProps} type={type} style={style} onClick={() => onClickEv(props.name)} className={className + errorClass} >
            {iconBefore} {icon} {value}
          </button>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 text-red text-small">
          {errored.error && errorType === 1 && errorMessage}
        </div>
      </div>}  
    </Fragment>

}
