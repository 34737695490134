import UILIB from 'components';
import { setDrawer } from 'store/actions';
import Modules from './Modules';


export function setQuoteFilterVars(filters, account) {

  const baseQuery = ` ( status eq 0 and source eq SOP and processStage eq 0 and approved eq 0 and activeDirectoryObjectGuid eq ${account.azureId} ) `;

  let filterButton = null;
  if(filters.button === 'Active') filterButton = ' ( isActive eq 1 and ordered eq 0 and closed eq 0 and archived eq 0 and dynamicsStateCode ne 2 ) ';
  if(filters.button === 'Ordered') filterButton = ' ( isActive eq 0 and ordered eq 1 and archived eq 0 and dynamicsStateCode ne 2 ) ';
  if(filters.button === 'Closed') filterButton = ' ( isActive eq 0 and ordered eq 0 and closed eq 1 and archived eq 0 and dynamicsStateCode ne 2 ) ';
  if(filters.button === 'Archived') filterButton = ' ( isActive eq 0 and archived eq 1 ) ';
  if(filters.button === 'Lost') filterButton = ' ( isActive eq 0 and archived eq 0 and dynamicsStateCode eq 2 ) ';

  const arrayData = [baseQuery, filterButton].filter(x => x);

  return arrayData.join(' and ');
}

export function setDraftFilterVars(filters, account) {

  const baseQuery = ` ( status eq 0 and source eq SOP and processStage eq 1 and approved eq 0 and activeDirectoryObjectGuid eq ${account.azureId} ) `;

  let filterButton = null;
  if(filters.button === 'Draft') filterButton = ' ( isActive eq 1 and ordered eq 0 and closed eq 0 and isDraft eq 1 and archived eq 0 and dynamicsStateCode ne 2 ) ';
  if(filters.button === 'Lost') filterButton = ' ( isActive eq 0 and archived eq 0 and dynamicsStateCode eq 2 ) ';

  const arrayData = [baseQuery, filterButton].filter(x => x);

  return arrayData.join(' and ');
}

export function getDefaultQuoteFilters() { 
  return { 
    button: null
  };
}

export function tableContainer(onClick, icon, toolTip, colour, hover) {
  return <UILIB.TableContainer onClick={onClick} icon={icon} toolTip={toolTip} colour={colour} hover={hover} />
}

export function getYesNo() {
  return [{ value: -1, label: "Select" },{ value: 0, label: "No" },{ value: 1, label: "Yes" }];
}

export function actionOpenDrawer(type, entity, dispatch, enabled, sync, width) {
  if(!enabled) return false;
  const selector = (type, data) => {
    if(type === 'ViewNotesDrawer') return <Modules.NotesDrawer entity={entity} allowAdd={'/salesHub/accountmanagerview/quoteview'} onClose={() => dispatch(setDrawer({ open: false, content: null }))} sync={sync} /> 
  };
  dispatch(setDrawer({ 
    open: true, 
    width: width,
    content: selector(type, entity)
  }))
}

export function getStatusChip(row) {
  if (Number(row.processStage) === 0 && Number(row.ordered) === 1 && Number(row.isActive) === 0 && Number(row.archived) === 0 && Number(row.dynamicsStateCode) !== 2) {
    return <UILIB.Chip toolTip="Ordered" className="flex-container wrap table colour background-8 orange text-small width-100" value="Ordered" />;
  }
  if (Number(row.processStage) === 0 && Number(row.ordered) === 0 && Number(row.isActive) === 0 && Number(row.archived) === 0 && Number(row.dynamicsStateCode) !== 2) {
    return <UILIB.Chip toolTip="Closed" className="table colour background-8 grey text-small width-100" value="Closed" />;
  }
  if (Number(row.processStage) === 0 && Number(row.archived) === 0 && Number(row.dynamicsStateCode) === 2) {  
    return <UILIB.Chip toolTip="Lost" className="table colour background-8 red text-small width-100" value="Lost" />;
  }
  if (Number(row.processStage) === 0 && Number(row.archived) === 1) {  
    return <UILIB.Chip toolTip="Archived" className="table colour background-8 purple text-small width-100" value="Archived" />;
  }
  if (Number(row.processStage) === 1 && Number(row.archived) === 0 && Number(row.dynamicsStateCode) === 2) {  
    return <UILIB.Chip toolTip="Lost" className="table colour background-8 red text-small width-100" value="Lost" />;
  }
  if (Number(row.processStage) === 1 && Number(row.archived) === 0) {
    return <UILIB.Chip toolTip="Draft" className="table colour background-8 green text-small width-100" value="Draft" />;
  }
  if (Number(row.processStage) === 1 && Number(row.archived) === 1) {  
    return <UILIB.Chip toolTip="Archived" className="table colour background-8 purple text-small width-100" value="Archived" />;
  }
  return <UILIB.Chip toolTip="Active" className="table colour background-8 green text-small width-100" value="Active" />;  
}

export function getNotesColour(row) {
  if (!row.dealNote.length) {
    return 'colour lightGrey'
  }
  if(row.dealNote.some(x => (x.noteSource.trim() === 'Internal Notes' || x.noteSource.trim() === 'External Notes')) && !row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 orange'
  }
  if(row.dealNote.some(x => x.noteSource.trim() === 'General Notes')) {
    return 'colour background-6 red'
  }
}