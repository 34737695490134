

export default function TextArea({ 
  outerClassName, 
  className,
  value, 
  placeholder, 
  errored = { messageText: "", option: 0 }, 
  onChange, 
  disabled, 
  style, 
  name, 
  outerstyle 
}) {

    const errorObject = (typeof(errored) === 'object') ? errored : undefined;
    const errorClass = (errorObject && errorObject?.error && errorObject?.option === 0)
      ? "errored border" 
      : "";

    return <div className={outerClassName} style={outerstyle}>
        <textarea 
          className={`textInput textArea fullBorder overflow-auto pad-3 ${className} ${errorClass}`} 
          placeholder={placeholder} 
          onChange={onChange} 
          disabled={disabled} 
          value={(value) ? value : null} 
          style={(style) ? style : {}} 
          name={name} >
        </textarea>
        {(errorObject && errorObject.error && errorObject.option === 1) && <div className="errored message">{(errorObject) && errorObject.messageText}</div>}
    </div>
}