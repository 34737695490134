import { useState, useEffect, useRef, Fragment } from 'react';
import UILIB from 'components';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getRegions, getUsers, getUsersByGroup, getLogisticsStatus } from "classes/helpers";
import { permissionsObject, permissionDecider } from 'classes/permissions';
import { getDefaultFilters } from './functions';
import { getDefaultFilters as sopDefaultFilters } from '../sophub/functions';
import Modules from './Modules';


export default function OrderHub(props) {

    const location = useLocation();
    const account = useSelector((state) => state.account);
    const permissions = useSelector((state) => state.permissions);
    const permissionsData = useRef([{ name: 'orderHub', hubName: 'Order Hub', routeName: location.pathname }]);
    const localPermissions = useRef({});
    const history = useHistory();
    const [currentTab, setCurrentTab] = useState(0);
    const defaultfilters = { ...sopDefaultFilters(location), ...getDefaultFilters() };
    const [filters, setFilters] = useState(defaultfilters);
    const [staff, setStaff] = useState([]);
    const [sopStaff, setSopStaff] = useState([]);  
    const [regions, setRegions] = useState([]);
    const [logistics, setLogistics] = useState([]);  


    const handleChangefilters = (tabId) => {
      setCurrentTab(tabId);
      setFilters(defaultfilters); };

    useEffect(() => {
      const actionAsync = async () => {
  
        await permissionDecider(permissions, account, permissionsData.current, localPermissions.current);
        await props.permissions.generalAccess();
  
        const regionsResult = await getRegions();
        const staffResult = await getUsers();
        const sopStaffResult = await getUsersByGroup("sop");
        const logisticsResult = getLogisticsStatus([0, 2, 3, 4, 5, 6, 25, 7, 30, 15, 10]);
  
        const regionsMapped = regionsResult.map(region => { return { label: region.name, value: region.id } });
        const staffMapped = staffResult.map(user => { return { label: user.fullName, value: user.activeDirectoryObjectGuid } });
        const sopStaffMapped = sopStaffResult.map(user => { return { label: user.displayName, value: user.azureId } });
        const logisticsMapped = logisticsResult.map(status => { return { label: status.status, value: status.value } });
  
        staffMapped.unshift({ label: "All Sales Staff", value: -1 });
        sopStaffMapped.unshift({ label: "All SOP Staff", value: -1 });
        logisticsMapped.unshift({ label: "All Deal Statuses", value: -1 });
        
        setRegions(regionsMapped);
        setSopStaff(sopStaffMapped);
        setStaff(staffMapped);
        setLogistics(logisticsMapped);
  
      }
      actionAsync();
    }, []);


    const tabs = [
      { label: 'Quotes', disabled: !permissionsObject('quotesTab', localPermissions.current.permissions, permissions, location.pathname).entityEnable, value: 0, context: 'quotes' },
      { label: 'Drafts', disabled: !permissionsObject('ordersTab', localPermissions.current.permissions, permissions, location.pathname).entityEnable, value: 1, context: 'drafts' },
      { label: 'Awaiting Approval', disabled: !permissionsObject('awaitingApporvalTab', localPermissions.current.permissions, permissions, location.pathname).entityEnable, value: 2 },
      { label: 'Orders', disabled: !permissionsObject('ordersTab', localPermissions.current.permissions, permissions, location.pathname).entityEnable, value: 3 },
      { label: "Install Checklist", value: 4 },
      { label: "Reports", disabled: !permissionsObject('reportsControl', localPermissions.current.permissions, permissions, location.pathname).entityEnable, value: 5 },
      { label: 'Admin', disabled: process.env.REACT_APP_ENV_MODE === 'live' || !permissionsObject('orderHubAdmin', localPermissions.current.permissions, permissions, location.pathname).entityEnable, value: 6 }];

      
    return <Fragment>
      <div className="flex-container column mar-t10 mar-r5 mar-l5">
        <UILIB.Paper className='flex-item row width-100 mar-b5'>
          <div className='flex-item start width-100 mar-b20'><h3>Order Hub</h3></div>
          <div className='flex-item start flex-grow-1'>
            <UILIB.Button value="Back" className="grey" onClick={() => history.push('/')} />
          </div>
        </UILIB.Paper>
      </div>
      <UILIB.SubNav
        onClick={(newId) => { handleChangefilters(newId); }}
        tabs={tabs}
        currentTab={currentTab}
        outerClasses="mar-t15 mar-b15" />
      {currentTab === 0 && <Modules.QuotesTable
        filters={filters}
        setFilters={setFilters}
        defaultfilters={defaultfilters}
        permissionsObject={permissionsObject}
        localPermissions={localPermissions}
        permissions={permissions}
        route={location.pathname}
        history={history}
        location={location}
        context={tabs[currentTab]}
        staff={staff}
        regions={regions} />}
      {currentTab === 1 && <Modules.DraftTable
        permissionsObject={permissionsObject}
        localPermissions={localPermissions}
        permissions={permissions}
        route={location.pathname}
        history={history}
        location={location}
        context={tabs[currentTab]}
        staff={staff}
        regions={regions} />}
      {currentTab === 2 && <Modules.AwaitingApprovalTable 
        filters={filters} 
        setFilters={setFilters} 
        defaultfilters={defaultfilters}
        location={location} 
        history={history} 
        permissions={permissions} 
        permissionsObject={permissionsObject} 
        localPermissions={localPermissions} 
        route={location.pathname} 
        currentTab={currentTab} 
        regions={regions} 
        staff={staff} 
        logistics={logistics} />}  
      {currentTab === 3 && <Modules.ApprovedTable 
        filters={filters} 
        setFilters={setFilters}
        defaultfilters={defaultfilters}
        location={location} 
        history={history} 
        permissions={permissions} 
        permissionsObject={permissionsObject} 
        localPermissions={localPermissions} 
        route={location.pathname} 
        currentTab={currentTab} 
        regions={regions} 
        staff={staff} 
        sopStaff={sopStaff} 
        logistics={logistics} />}
      {currentTab === 4 && <Modules.InstallChecklistTable
        regions={regions}
        route={location.pathname}
        localPermissions={localPermissions}
        permissionsObject={permissionsObject}
        permissions={permissions}
        location={location}
        dealId={0} />}
      {currentTab === 5 && <Modules.ReportsTable 
        filters={filters}
        setFilters={setFilters} />}
      {currentTab === 6 && <Modules.AdminTable
        permissionsObject={permissionsObject}
        localPermissions={localPermissions}
        permissions={permissions}
        route={location.pathname}
        history={history}
        location={location}
        context={tabs[currentTab]}
        staff={staff}
        regions={regions} />}
    </Fragment>
}