import moment from "moment-business-time";

export function dateWorkingDays(start, end, opts) {

    //  ****OPTS****
    //    0: Days
    //    1: Hours


    let regexNumber = /^-?[0-9]\d*(\.\d+)?$/;
    let regexDate = /(\d{1,4})\w[/-](\d{1,4})\w[/-](\d{1,4}) (\d{2}):(\d{2})/;
    let regexDateUnix = /(\d{1,4})\w[/-](\d{1,4})\w[/-](\d{1,4})\w[T](\d{2}):(\d{2})/;
    let startDate;
    let endDate;

    //working hours -1 hour for lunch, exclude weekends
    moment.locale("en", {
        workinghours: {
            0: null,
            1: ['08:30:00', '17:30:00'],
            2: ['08:30:00', '17:30:00'],
            3: ['08:30:00', '17:30:00'],
            4: ['08:30:00', '17:30:00'],
            5: ['08:30:00', '17:30:00'],
            6: null
        }
    });

    //detect date type
    try {
        moment.isMoment(start)
            ? (startDate = moment(start))
            : regexNumber.test(start)
            ? (startDate = moment().add(start, "hours"))
            : regexDate.test(start)
            ? (startDate = moment(start))
            : regexDateUnix.test(start)
            ? (startDate = moment(start))
            : (startDate = moment());

        moment.isMoment(end)
            ? (endDate = moment(end))
            : regexNumber.test(end)
            ? (endDate = moment().add(end, "hours"))
            : regexDate.test(end)
            ? (endDate = moment(end))
            : regexDateUnix.test(end)
            ? (endDate = moment(end))
            : (endDate = moment());

        
        //generate date diff number
        if (opts === 0) {
            return parseFloat(Math.round(Math.abs(startDate.workingDiff(endDate, 'hours', true) / 8) * 10) / 10);
        }
        if (opts === 1) {
            return parseFloat(Math.round(Math.abs(startDate.workingDiff(endDate, 'hours', true) ) * 10) / 10);
        }
        if (!opts) return parseFloat(0);
    } catch (err) {
        return parseFloat(0);
    }
}