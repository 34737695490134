
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import UILIB from 'components';
import Axios from 'classes/axios';
import { formatDateTime } from 'classes/format';

const header = [
  { label: "Account Number", value: "accountNumber", type: 'number' },
  { label: "Customer", value: "companyName", type: 'string' },
  { label: "Salesperson", value: "salesperson", type: 'string', options: 'auto' },
  { label: "Date On Stop", value: "dateOnStop", align: "center", type: 'date' },
  { label: "Last Edited By", value: "lastEditedBy", align: "center", type: 'string' }
];


export default function CustomersView() {
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true)

    const generateTable = () => {
        Axios.get(`/sales/customers/tableview?tableType=onstop&justMe=true`).then(customers => {

            const tableData = customers.data.map((row) => {
                return {
                    "accountNumber": { value: row.accountNumber, raw: row.accountNumber },
                    "companyName": { value: row.companyName, raw: row.companyName },
                    salesperson: { value: row.salesPerson ? row.salesPerson.displayName : '', raw: row.salesPerson ? row.salesPerson.displayName : '' },
                    "dateOnStop": { value: formatDateTime(Date.parse(row.dateOnStop), "DD/MM/YYYY HH:mm:ss"), raw: row.dateOnStop },
                    "lastEditedBy": { value: row.lastEditedBy, raw: row.lastEditedBy }
                }
            })
            setTableData(tableData);
            setLoading(false)
        })
    }

    useEffect(() => {
        generateTable();
        // eslint-disable-next-line
    }, []);


    return <div className="flex-container row">
        <div className="col-xs-12 mar-b15 mar-t5">
            <UILIB.Button className="white outline" value="Back" iconBefore="icon-arrow-left" onClick={() => { history.push('/salesHub/accountmanagerview') }} />
        </div>
        <div className="col-xs-12 col-sm-6 mar-b15">
            <h2 className="mar-b0">On Stop</h2>
        </div>
        <div className="col-xs-12 mar-b15">
            <UILIB.Paper>
                <UILIB.Table 
                  className="small" 
                  filter={true} 
                  header={header} 
                  data={tableData} 
                  loading={loading} 
                  sortable={true} 
                  defaultOrderBy="accountNumber" 
                  defaultOrderDir="DESC" 
                  maxRows={10} 
                  exportEnable={true} 
                />
            </UILIB.Paper>
        </div>
    </div>
}