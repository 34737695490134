
import { Fragment, createElement } from 'react';
import UILIB from 'components';

export default function StatContainer(props) {
  const selected = ((Boolean(props.selected === true) || String(props.selected) === String(props.controls && props.controls.id)) && (Boolean(props.canClick))) ? 'lightgrey' : '';
  const children = [
    <h4 style={{ pointerEvents : 'none', margin: 8 }}>{props.title}</h4>,
    props.loading && <UILIB.Loading type={3} />,
    !props.loading && <h4 style={{ textAlign: 'center', fontWeight: 'lighter', pointerEvents : 'none', margin: 8 }}><b>{props.data}</b></h4>
  ]
  const controls = [
    { className: `${props.controls && props.controls.className ? props.controls.className : ''}${props.canClick ? ' clickable ' : ''}` },
    { style: { ...props.style, backgroundColor: selected, pointerEvents : props.canClick ? 'pointer' : 'none', cursor: props.canClick ? 'pointer' : 'auto' } }
  ]
  return <Fragment>
      {createElement('div', Object.assign(props.controls, ...controls), ...children)}
    </Fragment>
}
