import { useEffect, useState } from 'react';
import UILIB from 'components';
import axios from 'classes/axios';

export default function DrawerProductCats({ finished, drawerType, manufacturers, categories, ranges, types, suppliers, minLength = 0 }) {

    

    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(true)
    const [newName, setNewName] = useState("");
    const [manuError, setManuError] = useState("");
    const [newNameError, setNewNameError] = useState("");
    const [manufacturer, setManufacturer] = useState(0);
    const allDone = (value) => {
        finished(value, drawerType);
    }

    const addEntry = (event) => {

        console.log(minLength)

        setNewNameError("");
        setManuError("");

        if (!newName || newName.length < minLength) {
            setNewNameError("Please enter a longer name");
            return;
        }
        if (drawerType === "range" && manufacturer === 0) {
            setManuError("Please select a manufacturer");
            return;
        }
        setLoading(true);
        axios.post(`/sales/parts/` + drawerType, { name: newName, manufacturer, categories, ranges, types, suppliers }).then(data => {
            setNewName("")
            return allDone(data.data.id);
        }).catch(err => {
            setLoading(false);
        })
    }

    const getData = () => {
        axios.get(`/sales/parts/` + drawerType).then(data => {
            setTableData(data.data);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);


    if (loading) return <UILIB.Loading type={3} />
    return <div>
        <h1><span style={{ textTransform: "capitalize" }}>{drawerType}'s</span></h1>
        <p>Please add or edit {drawerType}'s' using the below list</p>
        {tableData.length > 0 && <UILIB.Paper className="mar-t15">
            {tableData.map((row, index) => <div key={index}>{row.name}</div>)}
        </UILIB.Paper>}
        {tableData.length === 0 && <UILIB.Paper className="red mar-t15 text-center">
            No {drawerType} Added
        </UILIB.Paper>}

        <UILIB.Paper className="mar-t15">
            <h5>Add a {drawerType}</h5>
            <UILIB.TextInput className="textInput input" value={newName} placeholder={drawerType + " Name"} onChange={(event) => { setNewName(event.target.value) }} errored={newNameError} />
            {drawerType === "range" && <UILIB.Select placeholder="Select parent manufacturer" name="SalesPartManufacturerIdBind" data={manufacturers} errored={manuError} value={manufacturer} onChange={(event) => setManufacturer(event.target.value)} />}
            <UILIB.Button value="Add" onClick={addEntry} />
        </UILIB.Paper>

    </div >

}