import { useState } from 'react';
import UILIB from 'components';


export default function EmailDataDrawer({ onClose, data }) {
  const [filter, setFilter] = useState({ button: 'Raw' });

  const handleButtonsChanged = (buttonName) => {
    const newTableFilter = { ...filter };
    if (newTableFilter.button === buttonName) {
        newTableFilter.button = '';
    } else {
        newTableFilter.button = buttonName;
    }
    setFilter(newTableFilter);
  };

  return <div className='flex-container row width-100'>
    <h2 className="flex-item row left width-100 mar-t20 mar-b20">Email Body:</h2>
    <div className='flex-item flex-grow-1 start wrap'>
                <UILIB.Button className={'mar-r5 small ' + (!filter.button || filter.button === 'Raw' ? 'primary' : 'secondary')} name='Raw' value='Raw' onClick={handleButtonsChanged} />
                <UILIB.Button className={'mar-l5 mar-r5 small ' + (!filter.button || filter.button === 'HTML' ? 'primary' : 'secondary')} name='HTML' value='HTML' onClick={handleButtonsChanged} />
              </div>
    { filter.button === "HTML" && <div className="flex-item row left offShade" style={{ width: "650px", height: "100%", resize: 'none' }} dangerouslySetInnerHTML={{ __html: data.body }} /> }
    { filter.button === "Raw" && <div className="flex-item row left offShade" style={{ width: "650px", height: "100%", resize: 'none' }}>{data.body} </div> }
  </div>;
}


