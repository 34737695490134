import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions'
import "../../styles/main.scss";

export default function Drawer({ drawer }) {

    const dispatch = useDispatch();

    let showClose = true;
    let content = "";
    let width = "400px";
    if (drawer && drawer.content) content = drawer.content;
    if (drawer && drawer.width) width = drawer.width;
    if (drawer && drawer.showClose !== undefined) { showClose = drawer.showClose; }

    if(drawer.open) document.body.style.overflow = 'hidden';

    const actionClose = () => {
      dispatch(setDrawer({ open: false, content: "" }));
      document.body.style.overflow = 'auto';
    }
    
    return <Fragment>
            <div className={drawer.open ? "overlay" : ""}>
              <div className={"drawer " + (drawer && drawer.open ? '' : 'closed')} 
                  style={{ width: width }}>
                {showClose && <div className="drawerClose icon-cross" onClick={() => actionClose()}></div> }
                {content}
              </div>
            </div>
          </Fragment>
}
