import { useState, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDateTime } from 'classes/format';
import DealLinkTableCell from '../../shared/dealLink';
import { setAdminFilterVars, getStatusChip } from '../functions';
import Modules from '../../sophub/Modules';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function BlockADminTable({ route, context }) {

  const headerData = [
    { label: '', value: 'finalDealNoDelete', type: 'delete', filtering: false, minWidth: 20, maxWidth: 20 },
    { label: "#", value: "finalDealNo", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Opp #", value: "opportunityNumber", type: 'number', filtering: true, minWidth: 50, maxWidth: 50 },
    { label: "Account #", value: "accountNo", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Group #", value: "groupCode", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 },   
    { label: "Customer", value: "companyName", type: 'string', filtering: true, minWidth: 200, maxWidth: 200 },
    { label: "Sales Person / Region", value: "salesPersonSalesRegion", type: 'string', filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Description", value: "description", type: "string", filtering: true, minWidth: 80, maxWidth: 80 },
    { label: "Deal Type", value: "salesType", type: 'string', filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Payment Type", value: "leaseorcash", type: "string", filtering: true, minWidth: 90, maxWidth: 90 },
    { label: "Value", value: "valueStats", type: 'string', split: ['invoice', 'GP'], filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Created On", value: "createdAtConvert", type: 'date', filtering: true, minWidth: 110, maxWidth: 110 },
    { label: "Status", value: "orderStatus", type: 'string', filtering: true, minWidth: 60, maxWidth: 60 }];
    
  const history = useHistory();
  const entityData = useRef({});
  const seleted = useRef({});
  const tablePageDefaults = { deleteEnabled: true, paging: { pages: [10,20,50,100,200], pageSelected: 50, limit: 50, offset: 0, orderBy: 'finalDealNo', orderDir: 'DESC' } };
  const defaultDialogue = { dealIds: [], count: 0, show: false };
  const defaultBanner = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultBanner);
  const [showDialogue, setShowDialoge] = useState(defaultDialogue);
  const [localRender, setLocalRender] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const exceptions = [400,404,500];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const tableDeleteData = async (event) => {
    setShowDialoge({ dealIds: event, count: event.length, show: true });
  };

  const actionCloseDelete = () => {
    setShowDialoge({ ...showDialogue, show: false });
    setBannerError(defaultBanner);
  };

  const actionDeleteRecords = async (dealIds) => {
    setDeleting(true);
    try { 
      const deleteObjects = dealIds.map(x => { return { finalDealNo: x } });
      const result = await Axios.delete('/entities/workflow/deals/actionDeleteOpportunity', { data: deleteObjects }); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        entityData.current.result = entityData.current.result.filter(x => !dealIds.map(m => Number(m)).includes(Number(x.finalDealNo)));
        setShowDialoge(defaultDialogue);
        setLocalRender(!localRender);
        setDeleting(false);
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setDeleting(false);
  };

  async function constructTable(quoteData) {

    const tableData = quoteData.map(row => {
      return {
        finalDealNoDelete: { value: row.finalDealNoDelete, raw: row.finalDealNoDelete },
        finalDealNo: DealLinkTableCell(row, history, route, true, true, 'class'),
        opportunityNumber: { value: row.opportunityNumber, raw: row.opportunityNumber },
        accountNo: { value: row.accountNo, raw: row.accountNo },
        groupCode: { value: row.groupCode, raw: row.groupCode },
        companyName: { value: row.companyName, raw: row.companyName },
        salesPersonSalesRegion: { value: row.salesPersonSalesRegion, raw: row.salesPersonSalesRegion },
        description: { value: row.description, raw: row.description },
        salesType: { value: row.salesType, raw: row.salesType },
        leaseorcash: { value: row.leaseorcash, raw: row.leaseorcash },
        valueStats: { value: <Modules.PricingContainer row={row} />, raw: [row.pricing.total, row.pricing.gp] },
        createdAtConvert: { value: formatDateTime(row.createdAtConvert), raw: row.createdAtConvert },
        orderStatus: { value: getStatusChip(row), raw: row.orderStatus } }
    })
    return tableData;
  }

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

    const queryLocal = (query !== null) ? query : '';
    const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
    const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
    const calcFilters = setAdminFilterVars();
    const expandArray = ['pricing'];

    entityData.current = await Axios.get(`/entities/deals/getAdminSummary/?&$filter=${[calcFilters].join(' and ')}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

    seleted.current = entityData.current.result.reduce((acc, obj) => ({ ...acc, [obj.finalDealno]: false }), {});

    if(!entityData.current || !entityData.current.result.length) return false;

    return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
  }

  return <Fragment>
    <UILIB.Paper>
      {showDialogue.show && <UILIB.MessageBox 
        header={'Delete Records'} 
        loading={deleting}
        text={`Click OK to DELETE the selected ${(showDialogue.count) > 1 ? 'Records' : 'Record'}`} 
        onConfirm={async () => await actionDeleteRecords(showDialogue.dealIds)} 
        onCancel={() => actionCloseDelete()} 
        errored={bannerError} />}
      <UILIB.TableNew
        name={`BlockAdminTable`}
        className='small'
        overflowX='auto'
        overflowY='hidden'    
        header={headerData}
        deleteQuery={async (ev) => await tableDeleteData(ev)}
        localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
        localRender={[localRender]}
        remoteQuery={getTableData}
        remoteRender={[context]}
        defaultSettings={tablePageDefaults} />
    </UILIB.Paper>   
  </Fragment>
}