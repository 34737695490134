import { useState } from "react";
import UILIB from 'components';
import TabDealsView from './tabDealsView';
import TabCustomersView from './tabCustomersView';
import BlockOpenQuotes from './blockOpenQuotes';
import BlockActiveDeals from './blockActiveDeals';
import BlockInvoicedThisMonth from './blockInvoicedThisMonth';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

const tabs = [{ label: "Deals", value: 0 }, { label: "Customers", value: 1 }]

export default function AccountManagerView() {
    const [currentTab, setCurrentTab] = useState(0);
    const history = useHistory();
    const account = useSelector(state => state.account)
    const isManager = account.managerUsers && !!account.managerUsers.length;


    return <div className="flex-container row mar-t5">
        <div className="col-xs-12 col-sm-6 mar-b15">
            <UILIB.Button onClick={() => { window.open('https://portfolio.agilico.co.uk', '_blank') }} className="primary mar-r10" value="Sales Portfolio" />
            <UILIB.Button className="primary mar-r10" value="Refurb kit for sale" onClick={() => { window.open("https://360.agilico.co.uk/serviceadmin/sales/refurbkit/", "_blank") }} />
        </div>
        <div className="col-xs-12 col-sm-6 mar-b15 text-right">
            {isManager && <UILIB.Button className="mar-l10" value="Team View" onClick={() => history.push('/saleshub/salesmanagerview')} />}
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mar-b15">
            <BlockOpenQuotes/>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mar-b15">
            <BlockActiveDeals />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 mar-b15">
            <BlockInvoicedThisMonth />
        </div>
        <div className="col-xs-12">
            <UILIB.SubNav tabs={tabs} currentTab={currentTab} outerClasses="mar-t15 mar-b15" onClick={(newId) => setCurrentTab(newId)} />
            {currentTab === 0 && <TabDealsView />}
            {currentTab === 1 && <TabCustomersView />}
        </div>
    </div >
}
