import { useState, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import moment from 'moment-timezone';
import UILIB from 'components';
import Axios from 'classes/axios';
import ActionPassBot from './actionPassBot';
import { consumableChip, tableContainer, actionOpenDrawer, handleSerialClicked, setFilterVarsFailedBot, getBGColour } from '../functions'


export default function FailedBot() {
    const failedBotTableHeader = [
      { label: '', value: 'selected', type: '', filtering: false, minWidth: 20 },
      { label: "Toner #", value: "tonerID", type: "string", filtering: true },
      { label: 'Customer Name', value: 'customerName', type: 'string', filtering: true, minWidth: 370, maxWidth: 370 },
      { label: 'Model', value: 'machineName', type: 'string', filtering: true, minWidth: 250, maxWidth: 250 },
      { label: 'Serial #', value: 'serialNumber', type: 'string', filtering: true, minWidth: 130, maxWidth: 130 },
      { label: "Source", value: "fromAlertType", type: "string", filtering: true },
      { label: "Date Received", value: "tonerDate", type: "date", filtering: true },
      { label: "Consumable", value: "tonerTypeCast", type: "string", filtering: true },
      { label: 'Logs', value: 'logging', type: 'string', filtering: false, minWidth: 60, maxWidth: 60 }]; 

    const dispatch = useDispatch();
    const history = useHistory();
    const tablePageDefaults = { paging: { pages: [10,20,50,100,200], pageSelected: 20, limit: 20, offset: 0, orderBy: 'tonerDateCast', orderDir: 'DESC' } };  

    const entityData = useRef({});  
    const botCheckbox = useRef({});

    const [localRender, setLocalRender] = useState(false);
    const [dates, setDates] = useState({
      startDate: moment().format('YYYY-MM-DD').toString(),
      endDate: moment().format('YYYY-MM-DD').toString() });


    const updateCheckbox = (row) => {
      botCheckbox.current[row.tonerID] = !botCheckbox.current[row.tonerID]; 
      setLocalRender(!localRender); };

    const actionPassRecords = () => {
      const selected = Array.from(new Set(Object.keys(botCheckbox.current).filter(x => botCheckbox.current[x]))).map(x => Number(x));
      entityData.current.result = entityData.current.result.filter(x => !selected.includes(Number(x.tonerID)));
      setLocalRender(!localRender);
    };

    const updateDates = (newDate) => {
      setDates({ ...dates, [newDate.target.name] : newDate.target.value }); };


    function constructTable(failedBotData) {

      const tableData = failedBotData.map(row => {

        const b_hasLogs = Boolean(row.tonerRobotLog && row.tonerRobotLog.length);
        const logsColour = (b_hasLogs) ? 'colour colour background-6 red' : 'colour lightGrey';
        const fn_logs = actionOpenDrawer.bind(null, 'ViewRobotDrawer', row, dispatch, true, { data: entityData.current, get: localRender, set: setLocalRender });
        const tonerDate = moment(row.tonerDate).format("DD/MM/YYYY HH:mm:ss");

        const bgColour = getBGColour(row);

        return {
          selected: { value: <UILIB.TableContainer data={<UILIB.Checkbox checked={botCheckbox.current[row.tonerID]} type='checkbox' onChange={() => updateCheckbox(row)} />} />, class: bgColour },
          tonerID: { value: row.tonerID, raw: row.tonerID, class: bgColour },
          customerName: { value: row.customerName, raw: row.customerName, class: bgColour },
          tonerDate: { value: tonerDate, raw: row.tonerDate, class: bgColour },
          machineName: { value: row.machineName, raw: row.machineName, class: bgColour },
          fromAlertType: { value: row.fromAlertType, raw: row.fromAlertType, class: bgColour },
          serialNumber: { value: handleSerialClicked(row, history, null), raw: row.serialNumber, class: bgColour },
          tonerTypeCast: { value: consumableChip(row.tonerTypeCast), raw: row.tonerTypeCast, class: bgColour },
          logging: { value: tableContainer(fn_logs, 'icon-book', (b_hasLogs) ? 'View Logs' : 'Logs Unavailable', logsColour, true), raw: row.tonerRobotLog }
        }

      });
      return tableData;
    }                            

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {

        const queryLocal = (query !== null) ? query : '';      
        const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
        const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
        const processedOptions = setFilterVarsFailedBot(dates.startDate, dates.endDate);
        
        entityData.current = await Axios.get(`/entities/tonerOrders/getSummaryFailedBot/?&$filter=${[processedOptions].filter(x => x).join(' and ')}${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

        let checked = {};
        if (entityData.current && entityData.current.result) {
          entityData.current.result.forEach(x => { return checked = { ...checked, [x.tonerID]: false } })
          botCheckbox.current = { ...checked, ...botCheckbox.current };
        }

        return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }


    return <Fragment>       
        <div className="flex-container row mar-b15 mar-l5 mar-r5 mar-t10">    
          <UILIB.Paper className='width-100 mar-b15'>
            <div className='flex-item width-100 mar-b10'>
              <div className='col-xs-6 '><h3>Failed Bot Requests</h3></div>
            </div>
            <div className='flex-container row'>
              <div className='flex-item start flex-grow-1 align-center'> 
                <UILIB.Button value="Back" className="grey" onClick={() => history.push('/consumablesHub')} />
              </div>       
            </div>
          </UILIB.Paper>
          <UILIB.Paper className="width-100">
              <div className='flex-container row end mar-b10'>
                <UILIB.TextInput className="textInput input flex-item" type="date" name="startDate" outerClassName="flex-item mar-r10 align-center" value={dates.startDate} onChange={updateDates} />
                <UILIB.TextInput className="textInput input flex-item" type="date" name="endDate" outerClassName="flex-item mar-r10 align-center" value={dates.endDate} onChange={updateDates} />
                <ActionPassBot records={botCheckbox.current} callBack={actionPassRecords}/>
              </div>
              <UILIB.TableNew
                name="failedBotList"
                className='small'
                overflowX='auto'
                overflowY='hidden'    
                header={failedBotTableHeader}
                localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
                localRender={[localRender]}
                remoteQuery={getTableData}
                remoteRender={[dates]}
                defaultSettings={tablePageDefaults} />
          </UILIB.Paper>
        </div>
      </Fragment>

}