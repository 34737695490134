import * as React from 'react';
import * as functions from '../shared/functions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { formatDateTime } from 'classes/format';


const yesNoData = [
  { label: 'Please Select', value: -1 },
  { label: 'False', value: 0 },
  { label: 'True', value: 1 }
];

const Collapse = functions.styleCollapse();

function Row(data) {

  if(!data.row) return <React.Fragment></React.Fragment>

  const open = data.row.innerProps.groupExpandRow[data.row.route.id] || false;
  const foundRows = data.row.sortedPermissions.filter(f => f.routeId === data.row.route.id);
  const routes = data.row.innerProps.permissions.AdminRoute.map(route => { return { label: route.routeName, value: route.id } });
  routes.unshift({ label: "Please Select", id: 0 });

  const subRowSx = {
    backgroundColor: "#EEEEEE",
    borderBottom: "1px solid black"
  }

  const chipVariant = {  
    colour: data.row.route.inheriting ? 'success' : 'info', 
    message: data.row.route.inheriting ? 'TRUE' : 'FALSE'
  }

  const rowVariant = {  
    backgroundColor: data.row.route.inheriting ? 'rgba(221, 221, 255, 0.4)' : '', 
    borderColor: 'grey.500'
  }

  return (
    <React.Fragment>
      <TableRow sx={{...rowVariant}}>
        <TableCell>
          {foundRows.length > 0 && <IconButton
            sx={{ pb: 0, pt: 0 }}
            aria-label="expand row"
            size="small"
            onClick={() => functions.expandRowHander(data.row, data.row.route.id, data.row.innerProps.setGroupExpandRow, open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell> 
        <TableCell component="th" scope="row"><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{data.row.hubName}</Typography></TableCell>
        <TableCell component="th" scope="row"><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{data.row.routeName.replace(/\s+/g,'')}</Typography></TableCell>
        <TableCell component="th" scope="row"><Chip size="small" sx={{ width: '100%' }} label={chipVariant.message} color={chipVariant.colour} variant="outlined" /></TableCell>
        <TableCell>{functions.selectDropdown({ 
            disabled: false, 
            name: 'inheritFrom', 
            data: routes, 
            value: Number(data.row.route.parentId), 
            onChange: async (ev) => { await functions.handleChangeInherit(ev, data.row, data.row.innerProps.setGroupRender, data.row.innerProps.groupRender) }
          })
        }</TableCell>
        <TableCell><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{data.row.route.createdBy}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{formatDateTime(data.row.route.createdAt, "DD/MM/YYYY HH:mm:ss")}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{data.row.route.updatedBy}</Typography></TableCell>
        <TableCell><Typography sx={{ fontSize: 13, minWidth: '130px' }} variant="body2">{formatDateTime(data.row.route.updatedAt, "DD/MM/YYYY HH:mm:ss")}</Typography></TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={12} sx={{ p: 0, m: 0, border: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="tasks">
              <TableHead>
                <TableRow sx={{ pb: 0, pt: 0 }}>
                  {functions.addSortedHeader('Owner', "12%", 'permissionOwner', '#EEEEEE', data.row.innerProps.groupChildTableSortHandler, data.row.innerProps.setGroupChildTableSortHandler)}
                  {functions.addSortedHeader('Entity Name', "12%", 'entityName', '#EEEEEE', data.row.innerProps.groupChildTableSortHandler, data.row.innerProps.setGroupChildTableSortHandler)}
                  {functions.addSortedHeader('Entity Type', "12%", 'entityType', '#EEEEEE', data.row.innerProps.groupChildTableSortHandler, data.row.innerProps.setGroupChildTableSortHandler)}
                  {functions.addSortedHeader('Entity Enabled', "12%", 'entityEnabled', '#EEEEEE', data.row.innerProps.groupChildTableSortHandler, data.row.innerProps.setGroupChildTableSortHandler)}
                  {functions.addSortedHeader('Entity Write', "12%", 'entityWrite', '#EEEEEE', data.row.innerProps.groupChildTableSortHandler, data.row.innerProps.setGroupChildTableSortHandler)}
                  {functions.addSortedHeader('Entity Delete', "12%", 'entityDelete', '#EEEEEE', data.row.innerProps.groupChildTableSortHandler, data.row.innerProps.setGroupChildTableSortHandler)}
                  {functions.addSortedHeader('CreatedAt', "12%", 'createdAt', '#EEEEEE', data.row.innerProps.groupChildTableSortHandler, data.row.innerProps.setGroupChildTableSortHandler)}
                  {functions.addSortedHeader('UpdatedAt', "12%", 'updatedAt', '#EEEEEE', data.row.innerProps.groupChildTableSortHandler, data.row.innerProps.setGroupChildTableSortHandler)}
                  <TableCell component="th" scope="row" sx={{ backgroundColor: '#EEEEEE', width: "2%" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {foundRows.map(row => {
                  return <TableRow key={row.id} sx={subRowSx}>
                    <TableCell>{row.permissionOwner}</TableCell>
                    <TableCell>{row.entityName}</TableCell>
                    <TableCell>{row.entityType}</TableCell>
                    <TableCell>{functions.selectDropdown({ 
                        disabled: false, 
                        name: 'entityEnable', 
                        data: yesNoData, 
                        value: Number(row.entityEnable), 
                        onChange: async (ev) => { await functions.onChangeEntity(ev, row, data.row.innerProps.setGroupRender, data.row.innerProps.groupRender) }
                      })
                    }</TableCell>
                    <TableCell>{functions.selectDropdown({ 
                        disabled: row.entityName.toLowerCase().replace(/\s+/g,'') === 'generalaccess' ? true : false, 
                        name: 'entityWrite', 
                        data: yesNoData, 
                        value: Number(row.entityWrite), 
                        onChange: async (ev) => { await functions.onChangeEntity(ev, row, data.row.innerProps.setGroupRender, data.row.innerProps.groupRender) }
                      })
                    }</TableCell>
                    <TableCell>{functions.selectDropdown({ 
                        disabled: row.entityName.toLowerCase().replace(/\s+/g,'') === 'generalaccess' ? true : false, 
                        name: 'entityDelete', 
                        data: yesNoData, 
                        value: Number(row.entityDelete), 
                        onChange: async (ev) => { await functions.onChangeEntity(ev, row, data.row.innerProps.setGroupRender, data.row.innerProps.groupRender) }
                      })
                    }</TableCell>
                    <TableCell>{formatDateTime(row.createdAt, "DD/MM/YYYY HH:mm:ss")}</TableCell>
                    <TableCell>{formatDateTime(row.updatedAt, "DD/MM/YYYY HH:mm:ss")}</TableCell>
                    <TableCell>{functions.deletePermissionCell(row, data.row.innerProps, data.row.innerProps.setGroupRender, data.row.innerProps.groupRender)}</TableCell>
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default function CollapsibleTable(props) {

  const handleChangePage = (event, newPage) => {  
    const groupTableSortHandler = structuredClone(props.groupTableSortHandler);
    groupTableSortHandler.page = newPage;
    props.setGroupTableSortHandler(groupTableSortHandler);
  };

  const handleChangeRowsPerPage = (event) => {
    const groupTableSortHandler = structuredClone(props.groupTableSortHandler);
    groupTableSortHandler.rowsPerPage = parseInt(event, 10);
    groupTableSortHandler.page = 0;
    props.setGroupTableSortHandler(groupTableSortHandler);
  };

  const handleHubItemClick = (event, newValue) => {
    handleChangeRowsPerPage(12);
    props.setGroupExpandRow([]);
    props.setselectedHubIndex({ ...structuredClone(props.selectedHubIndex), hub: newValue.id });
  }

  const handleListItemClick = (event, newValue) => {
    handleChangeRowsPerPage(12);
    props.setGroupExpandRow([]);
    props.setSelectedGroupIndex({ ...structuredClone(props.selectedGroupIndex), group: newValue.id })
  }

  const handleFilterChange = (event) => {
    const groupTableSortHandler = structuredClone(props.groupTableSortHandler);
    groupTableSortHandler.filter = event.target.value;
    props.setGroupTableSortHandler(groupTableSortHandler);
  };

  const groupHubData = props.permissions.AdminHub.sort(function(a, b){
    if(a.hubName < b.hubName) { return -1; }
    if(a.hubName > b.hubName) { return 1; }
    return 0;
  }).map(hub => { return  { label: hub.hubName, id: hub.id } });
  groupHubData.unshift({ label: "All Hubs", id: 0 });

  const groupSelectData = props.permissions.AdminGroup.sort(function(a, b){
    if(a.groupName < b.groupName) { return -1; }
    if(a.groupName > b.groupName) { return 1; }
    return 0;
  }).map(group => { return  { label: group.groupName, id: group.id } });
  groupSelectData.unshift({ label: "All Groups", id: 0 });

  const foundGroupData = functions.findGroupData(props, props.selectedGroupIndex.group, props.selectedHubIndex.hub, Row);

  return (
    <div>
      <Box display="flex" justifyContent="flex-end">
        <TextField 
          size="small" 
          id="outlined-basic" 
          label="Search" 
          variant="outlined" 
          sx={{ width: 600, pr: 2 }}
          onChange={(ev) => { handleFilterChange(ev) }}
        />
        <Autocomplete
          disablePortal
          size="small"
          onChange={(event, newValue) => handleHubItemClick(event, newValue)}
          options={groupHubData}
          sx={{ width: 400, pb: 2, pr: 2 }}
          defaultValue={{ label: "All Hubs", id: 0 }}
          renderInput={(params) => <TextField {...params} label="Select Hub" />}
        />
        <Autocomplete
          disablePortal
          size="small"
          onChange={(event, newValue) => handleListItemClick(event, newValue)}
          options={groupSelectData}
          sx={{ width: 400, pb: 2, pr: 2 }}
          defaultValue={{ label: "All Groups", id: 0 }}
          renderInput={(params) => <TextField {...params} label="Select Group" />}
        />
       <Button 
        size="small"
        variant="contained"
        sx={{ mb: 1, height: '38px', backgroundColor: '#00B200', fontSize: 12, minWidth: '130px' }}
        disabled={props.selectedGroupIndex.group === 0}
        onClick={() => props.setShowPermissionsModal(true)}
       >
        Add Permission
      </Button>
        {functions.addTableRefreshButton({ ml: 2, mb: 1, height: '38px', backgroundColor: '#00B200', fontSize: 12 }, props, props.groupRender, props.setGroupRender)}
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
            <TableRow>
              <TableCell sx={{ backgroundColor: "lightgray", width: "2%" }}></TableCell>
              {functions.addSortedHeader('Hub Name', "15%", 'hubName', 'lightgray', props.groupTableSortHandler, props.setGroupTableSortHandler)}
              {functions.addSortedHeader('Route Path', "19%", 'routeName', 'lightgray', props.groupTableSortHandler, props.setGroupTableSortHandler)}
              {functions.addSortedHeader('Inheriting', "10%", 'inheriting', 'lightgray', props.groupTableSortHandler, props.setGroupTableSortHandler)}
              {functions.addSortedHeader('Inherited from', "12.5%", 'inheritFrom', 'lightgray', props.groupTableSortHandler, props.setGroupTableSortHandler)}
              {functions.addSortedHeader('Created By', "8%", 'createdBy', 'lightgray', props.groupTableSortHandler, props.setGroupTableSortHandler)}
              {functions.addSortedHeader('CreatedAt', "8%", 'createdAt', 'lightgray', props.groupTableSortHandler, props.setGroupTableSortHandler)}
              {functions.addSortedHeader('Updated By', "8%", 'updatedBy', 'lightgray', props.groupTableSortHandler, props.setGroupTableSortHandler)}
              {functions.addSortedHeader('UpdatedAt', "8%", 'updatedAt', 'lightgray', props.groupTableSortHandler, props.setGroupTableSortHandler)}
            </TableRow>
          <TableBody>
            {foundGroupData.groups}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
          size="small"
          component="div"
          showFirstButton
          showLastButton
          rowsPerPageOptions={[25, 50, 100, 200]}
          count={foundGroupData.length}
          rowsPerPage={props.groupTableSortHandler.rowsPerPage}
          page={props.groupTableSortHandler.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(ev) => handleChangeRowsPerPage(ev.target.value)}
        />
    </div>
  );
}
