import { Fragment } from 'react';
import BlockUsersTable from '../tables/blockUsersTable';


export default function Users(props) {
  return <Fragment> 
    <div className="flex-container row mar-l5 mar-b5">
      <div className="col-xs-12 col-sm-12 col-md-12" style={{ padding: 10 }}> 
        <BlockUsersTable
          userRender={props.userRender}
          setUserRender={props.setUserRender}
          users={props.users} 
          setUsers={props.setUsers}
          groups={props.groups} 
          setGroups={props.setGroups}
          userTableSortHandler={props.userTableSortHandler} 
          setUserTableSortHandler={props.setUserTableSortHandler}
          loading={props.loading}
          setLoading={props.setLoading}
          selectedUserIndex={props.selectedUserIndex}
          setSelectedUserIndex={props.setSelectedUserIndex}
          enableUser={props.enableUser} 
          setEnableUser={props.setEnableUser}
          logoutUser={props.logoutUser}
          setLogoutUser={props.setLogoutUser}
          renderLoading={props.renderLoading}
          setRenderLoading={props.setRenderLoading}
        />
      </div>
    </div>
  </Fragment> 
}
