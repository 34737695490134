import { Fragment, useEffect, useState, useRef } from 'react';
import Axios from 'classes/axios';
import UILIB from 'components';


export default function CurrentAlerts({ entityLookup }) {

  const entityData = useRef({});
  const [alertData, setAlertData] = useState([]);

  useEffect(() => {    
    const actionAsync = async () => {
      if(entityLookup.hasOwnProperty('customerId') && !entityLookup.hasOwnProperty('serialNumber')) {
        const customerId = entityLookup['customerId'];
        entityData.current.equipment = await Axios.get(`/entities/equipments/?&$filter=CustomerId eq ${customerId}&$select=CustomerId and SerialNumber`).then(res => res.data.result[0]);
        entityData.current.storeNote = await Axios.get(`/entities/storeNotes/?&$filter=ParentId eq ${customerId} and IsAlert eq 1`).then(res => res.data.result);
      } else {
        const serialNumber = entityLookup['serialNumber'];
        entityData.current.equipment = await Axios.get(`/entities/equipments/?&$filter=SerialNumber eq ${serialNumber}&$select=CustomerId and SerialNumber`).then(res => res.data.result[0]);
        entityData.current.stockNote = await Axios.get(`/entities/stockHoldingNotes/?&$filter=ParentId eq ${entityData.current.equipment.id} and IsAlert eq 1`).then(res => res.data.result);
        entityData.current.storeNote = await Axios.get(`/entities/storeNotes/?&$filter=ParentId eq ${entityData.current.equipment.CustomerId} and IsAlert eq 1`).then(res => res.data.result);
      }

      const storeNote = (entityData.current.storeNote) ? entityData.current.storeNote.map(x => { return { ...x, title: 'Customer Alert', background: 'colour background lightOrange' } }) : [];
      const stockHoldingNote = (entityData.current.stockNote) ? entityData.current.stockNote.map(x => { return { ...x, title: 'Equipment Alert', background: 'colour background lightBlue' } }) : [];
    
      setAlertData([...storeNote.map(x => x), ...stockHoldingNote.map(x => x)]);
    }
    actionAsync();
  }, [entityLookup])

  return <Fragment>
    <UILIB.Paper className='width-100'>
      <div className='flex-container row mar-b10'>
        <div className='flex-item width-100'><h3 className='mar-b15'>Alerts</h3></div>
        <div className='flex-item row width-100'>
          {!Boolean(alertData.length) && <div>No Alerts Detected</div>}
          {Boolean(alertData.length) && alertData.map(x => { return <UILIB.BannerContainer className={'mar-t2 mar-b2'} background={x.background} title={x.title} content={x.Text} /> })}
        </div>
      </div>
    </UILIB.Paper>
  </Fragment>
}