import { useState, useRef, Fragment } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';


export default function ConfirmCreateOrder({ ourDeal, isActive, onConfirm, onCancel, route, history }) {

  const baseRoute = useRef(route.split('quote/')[0]);
  const [saving, setSaving] = useState(false);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const exceptions = [400,404];

  const invalidConfig = 'The options selected are invalid, please try again or contact support';
  const notFoundError = 'The server was uable to find the requested endpoint, please reload or contact support';
  const exceptionError = 'There was an exception while saving this record, please reload or contact support';


  const actionAddOrder = async () => {
    setSaving(true);
    setBannerError(defaultError);
    try { 
      const result = await Axios.post(`/entities/workflow/deals/actionAddNewOrder/${ourDeal.dynamicsOpportunityNumber}/${ourDeal.dealID}`); 
      if(exceptions.includes(result.status)) {
        if(result.status === 400) setBannerError({ error: true, message: invalidConfig });
        if(result.status === 404) setBannerError({ error: true, message: notFoundError });
      } else {
        onConfirm();
        history.push(`${baseRoute.current}order/${result?.data?.result?.deal?.dealID}`);
        window.location.reload();
      }
    } catch (err) {
      console.log(err);
      setBannerError({ error: true, message: exceptionError });
    }
    setSaving(false);
  }

  return <Fragment>
      <h2 className="mar-b10">Create draft order from quote {ourDeal.dealID}</h2>
      <div className='text-14 font-weight-bold mar-b10'>Please note this does not submit the order to SOP</div>
      <p className="mar-b5">This action will close the current quote and move the information to a draft order.</p>
      <p className="mar-b15">You will not be able to amend this quote after this point.</p>
      <p className="mar-b5">Are you sure you want to continue?</p>
      <div className="flex-container row">
        <UILIB.Button loading={saving} disabled={!isActive || saving} value="Confirm" onClick={async () => await actionAddOrder()} />
        <UILIB.Button disabled={!isActive || saving} value="Cancel" className="grey" onClick={() => onCancel()} />
        {bannerError.error && <div className="flex-container start wrap width-100">
          <div className="errored message">{bannerError.message}</div>
        </div>}
      </div>
  </Fragment >
}