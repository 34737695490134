export function logout() {
    return function (dispatch) {
        dispatch({ type: 'LOGOUT' })
    }
}

export function setAccount(account) {
    return function (dispatch) {
        dispatch({ type: 'SET_ACCOUNT', payload: account })
    }
}

export function setRefreshToken(refreshToken) {
    return function (dispatch) {
        dispatch({ type: 'SET_REFRESH_TOKEN', payload: refreshToken })
    }
}

export function setAccessToken(accessToken) {
  return function (dispatch) {
      dispatch({ type: 'SET_ACCESS_TOKEN', payload: accessToken })
  }
}

export function setMomentSession(momentSession) {
  return function (dispatch) {
      dispatch({ type: 'SET_MOMENT_SESSION', payload: momentSession })
  }
}

export function setDrawer(drawData) {
    return function (dispatch) {
        dispatch({ type: 'SET_DRAWERCONTENT', payload: drawData })
    }
}

export function setPermissions(permissions) {
  return function (dispatch) {
      dispatch({ type: 'SET_PERMISSIONS', payload: permissions })
  }
}

export function setReleaseVersion(releaseVersion) {
  return function (dispatch) {
      dispatch({ type: 'SET_RELEASEVERSION', payload: releaseVersion })
  }
}

export function setPagePreferences(pagePreferences) {
  return function (dispatch) {
      dispatch({ type: 'SET_PAGE_PREFERENCES', payload: pagePreferences })
  }
}

export function setFleetHubReportSelected(fleetReportSelected) {
  return function (dispatch) {
      const defaultObject = { 
        stats: {
          motOverdueResults: 0, 
          motDueResults: 0, 
          serviceOverdueResults: 0, 
          serviceDueResults: 0, 
          renewalsUpcomingResults: 0,
          poolCarsAvlResults: 0,
          totalCarsAvlResults: 0
        },
        selected: null,
        loaded: false
      }
      dispatch({ type: 'SET_FLEETREPORTSELECTED', payload: fleetReportSelected ? fleetReportSelected : defaultObject })
  }
}


export function setSessionExpire(sessionExpire) {
  return function (dispatch) {
      dispatch({ type: 'SET_SESSION_EXPIRE', payload: sessionExpire })
  }
}