
import { Fragment } from 'react';
import UILIB from 'components';

export default function SelectContainer({ data, checked, setChecked, selectQuery, behaviorType = 'multi' }) 
{

  const generateInput = (object) => {
    return <UILIB.Checkbox 
      type='checkbox' 
      disabled={(object?.disabled) ? object.disabled : false}
      checked={checked[object.value]}
      onChange={() => updateCheckbox(object)} />
  }

  const updateCheckbox = (object) => {
    if(behaviorType === 'multi') {
      const selected = { ...checked, [object.value]: !checked[object.value] };
      if(selectQuery) selectQuery(selected);
      setChecked(selected);
    }
    if(behaviorType === 'single') {
      const selected = { [object.value]: !checked[object.value] };
      if(selectQuery) selectQuery(selected);
      setChecked(selected);
    }
  };

  return <Fragment>
     <div className="flex-container center row around width-100 height-100">
      <div className={`flex-item consumablesHub groupContainer-input `}>{generateInput(data)}</div>
     </div>
    </Fragment>
}

