import { useState, useEffect } from 'react';
import UILIB from 'components';
import Axios from 'classes/axios';
import { pleaseSelect, createTextInput, createSelectInput, checkAndSet, newError, createMultilineTextInput, alphaNumericSpaceSpecialPattern } from '../generic';

export default function QuarterlyCheckCRUD(props) {
    //Specific to entity
    const emptyEntity = {
        windscreenWindowsAndMirrorsClean: '',
        windscreenWindowsAndMirrorsCleanDetails: '',
        windscreenWindowsAndMirrorsChipFree: '',
        windscreenWindowsAndMirrorsChipFreeDetails: '',
        allLightsWorking: '',
        allLightsWorkingDetails: '',
        allBreaksWorking: '',
        allBreaksWorkingDetails: '',
        anyDashboardWarningLightsOn: '',
        anyDashboardWarningLightsOnDetails: '',
        brakeFluidLevelsWithinCorrectLimits: '',
        brakeFluidLevelsWithinCorrectLimitsDetails: '',
        tyresCorrectDepthAndFreeOfCutsAndDefects: '',
        tyresCorrectDepthAndFreeOfCutsAndDefectsDetails: ''
    };
    const entityUrl = `/fleet/quarterlyCheck`;
    const entityUrlWithId = `/fleet/quarterlyCheck/${props.data.entity.id}`;
    const addFromEntityWithKey = {...emptyEntity, registration: props.data.entity.registration };
    const headerText = ' Quarterly Check - ';
    const yes = 'Yes';
    const no = 'No';
    const yesNo = [pleaseSelect,'Yes','No'];
    const [registrations, setRegistrations] = useState();

    const loadVehicleRegs = async () => {
        const vehicleRegsData = await Axios.get(`/fleet/vehicleRegs`);
        const vehicleRegsDataFormatted = vehicleRegsData.data.result.map(function(item){ return item.registration });
        setRegistrations([pleaseSelect, ...vehicleRegsDataFormatted]);
    }

    const loadSpecificData = async () => {
        loadVehicleRegs();
    }

    const cleanData = () => {
        if (entity.windscreenWindowsAndMirrorsClean === yes) { entity.windscreenWindowsAndMirrorsCleanDetails = '' }
        if (entity.windscreenWindowsAndMirrorsChipFree === yes) { entity.windscreenWindowsAndMirrorsChipFreeDetails = '' }
        if (entity.allLightsWorking === yes) { entity.allLightsWorkingDetails = '' }
        if (entity.allBreaksWorking === yes) { entity.allBreaksWorkingDetails = '' }
        if (entity.anyDashboardWarningLightsOn === no) { entity.anyDashboardWarningLightsOnDetails = '' }
        if (entity.brakeFluidLevelsWithinCorrectLimits === yes) { entity.brakeFluidLevelsWithinCorrectLimitsDetails = '' }
        if (entity.tyresCorrectDepthAndFreeOfCutsAndDefects === yes) { entity.tyresCorrectDepthAndFreeOfCutsAndDefectsDetails = '' }
    }

    const evaluatedRulesPassed = (formErrorsTemp) => {
        let e = 0;
        e = e + checkAndSet(!entity.registration || entity.registration.length < 1 || entity.registration === pleaseSelect, formErrorsTemp, 'registration', newError('registration', 'Please select Registration'));

        e = e + checkAndSet(!entity.windscreenWindowsAndMirrorsClean || entity.windscreenWindowsAndMirrorsClean.length < 1 || entity.windscreenWindowsAndMirrorsClean === pleaseSelect, formErrorsTemp, 'windscreenWindowsAndMirrorsClean', newError('windscreenWindowsAndMirrorsClean', 'Please select Windscreen Windows And Mirrors Clean'));
		e = e + checkAndSet(!entity.windscreenWindowsAndMirrorsCleanDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'windscreenWindowsAndMirrorsCleanDetails', newError('windscreenWindowsAndMirrorsCleanDetails', 'Please enter Windscreen Windows And Mirrors Clean Details without special characters'));

        e = e + checkAndSet(!entity.windscreenWindowsAndMirrorsChipFree || entity.windscreenWindowsAndMirrorsChipFree.length < 1 || entity.windscreenWindowsAndMirrorsChipFree === pleaseSelect, formErrorsTemp, 'windscreenWindowsAndMirrorsChipFree', newError('windscreenWindowsAndMirrorsChipFree', 'Please select Windscreen Windows And Mirrors Chip Free'));
		e = e + checkAndSet(!entity.windscreenWindowsAndMirrorsChipFreeDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'windscreenWindowsAndMirrorsChipFreeDetails', newError('windscreenWindowsAndMirrorsChipFreeDetails', 'Please enter Windscreen Windscreen Windows And Mirrors Chip Free Details without special characters'));

        e = e + checkAndSet(!entity.allLightsWorking || entity.allLightsWorking.length < 1 || entity.allLightsWorking === pleaseSelect, formErrorsTemp, 'allLightsWorking', newError('allLightsWorking', 'Please select All Lights Working'));
		e = e + checkAndSet(!entity.allLightsWorkingDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'allLightsWorkingDetails', newError('allLightsWorkingDetails', 'Please enter All Lights Working Details without special characters'));

        e = e + checkAndSet(!entity.allBreaksWorking || entity.allBreaksWorking.length < 1 || entity.allBreaksWorking === pleaseSelect, formErrorsTemp, 'allBreaksWorking', newError('allBreaksWorking', 'Please select All Breaks Working'));
		e = e + checkAndSet(!entity.allBreaksWorkingDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'allBreaksWorkingDetails', newError('allBreaksWorkingDetails', 'Please enter All Breaks Working Details without special characters'));

        e = e + checkAndSet(!entity.anyDashboardWarningLightsOn || entity.anyDashboardWarningLightsOn.length < 1 || entity.anyDashboardWarningLightsOn === pleaseSelect, formErrorsTemp, 'anyDashboardWarningLightsOn', newError('anyDashboardWarningLightsOn', 'Please select Any Dashboard Warning Lights On'));
		e = e + checkAndSet(!entity.anyDashboardWarningLightsOnDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'anyDashboardWarningLightsOnDetails', newError('anyDashboardWarningLightsOnDetails', 'Please enter Any Dashboard Warning Lights On Details without special characters'));

        e = e + checkAndSet(!entity.brakeFluidLevelsWithinCorrectLimits || entity.brakeFluidLevelsWithinCorrectLimits.length < 1 || entity.brakeFluidLevelsWithinCorrectLimits === pleaseSelect, formErrorsTemp, 'brakeFluidLevelsWithinCorrectLimits', newError('brakeFluidLevelsWithinCorrectLimits', 'Please select Brake Fluid Levels Within Correct Limits'));
		e = e + checkAndSet(!entity.brakeFluidLevelsWithinCorrectLimitsDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'brakeFluidLevelsWithinCorrectLimitsDetails', newError('brakeFluidLevelsWithinCorrectLimitsDetails', 'Please enter Brake Fluid Levels Within Correct Limits Details without special characters'));

        e = e + checkAndSet(!entity.tyresCorrectDepthAndFreeOfCutsAndDefects || entity.tyresCorrectDepthAndFreeOfCutsAndDefects.length < 1 || entity.tyresCorrectDepthAndFreeOfCutsAndDefects === pleaseSelect, formErrorsTemp, 'tyresCorrectDepthAndFreeOfCutsAndDefects', newError('tyresCorrectDepthAndFreeOfCutsAndDefects', 'Please select Tyres Correct Depth And Free Of Cuts And Defects'));
		e = e + checkAndSet(!entity.tyresCorrectDepthAndFreeOfCutsAndDefectsDetails.match(alphaNumericSpaceSpecialPattern), formErrorsTemp, 'tyresCorrectDepthAndFreeOfCutsAndDefectsDetails', newError('tyresCorrectDepthAndFreeOfCutsAndDefectsDetails', 'Please enter Tyres Correct Depth And Free Of Cuts And Defects Details without special characters'));

        return e > 0;
	}

    const htmlButtons = (loading) => {
        if (loading) {
            return <div />
        } else {
            return <div>
                <UILIB.Button loading={saving} className='mar-t10' value='Action' onClick={submitForm} />
            </div>
        }
    }
    
    const htmlRows = (loading) => { 
        if (loading) {
            return <div />
        } else {
            return <div className='mar-t15'>
                {createTextInput('First Name', 'firstName', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Last Name', 'lastName', false, 40, entity, entityErrors, changeInput)}
                {createSelectInput('Registration', 'registration', registrations, isActiveOnEdit, entity, entityErrors, changeInput)}
                {createTextInput('Make', 'make', false, 40, entity, entityErrors, changeInput)}
                {createTextInput('Model', 'model', false, 40, entity, entityErrors, changeInput)}
                {createSelectInput('Windscreen Windows And Mirrors Clean', 'windscreenWindowsAndMirrorsClean', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.windscreenWindowsAndMirrorsClean === no &&
                    createMultilineTextInput('Windscreen Windows And Mirrors Clean Details', 'windscreenWindowsAndMirrorsCleanDetails', isActive, 999, entity, entityErrors, changeInput)}
                {createSelectInput('Windscreen Windows And Mirrors Chip Free', 'windscreenWindowsAndMirrorsChipFree', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.windscreenWindowsAndMirrorsChipFree === no &&
                    createMultilineTextInput('Windscreen Windows And Mirrors Chip Free Details', 'windscreenWindowsAndMirrorsChipFreeDetails', isActive, 999, entity, entityErrors, changeInput)}
                {createSelectInput('All Lights Working', 'allLightsWorking', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.allLightsWorking === no &&
                    createMultilineTextInput('All Lights Working Details', 'allLightsWorkingDetails', isActive, 999, entity, entityErrors, changeInput)}
                {createSelectInput('All Breaks Working', 'allBreaksWorking', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.allBreaksWorking === no &&
                    createMultilineTextInput('All Breaks Working Details', 'allBreaksWorkingDetails', isActive, 999, entity, entityErrors, changeInput)}
                {createSelectInput('Any Dashboard Warning Lights On', 'anyDashboardWarningLightsOn', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.anyDashboardWarningLightsOn === yes &&
                    createMultilineTextInput('Any Dashboard Warning Lights On Details', 'anyDashboardWarningLightsOnDetails', isActive, 999, entity, entityErrors, changeInput)}
                {createSelectInput('Brake Fluid Levels Within Correct Limits', 'brakeFluidLevelsWithinCorrectLimits', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.brakeFluidLevelsWithinCorrectLimits === no &&
                    createMultilineTextInput('Brake Fluid Levels Within Correct Limits Details', 'brakeFluidLevelsWithinCorrectLimitsDetails', isActive, 999, entity, entityErrors, changeInput)}
                {createSelectInput('Tyres Correct Depth And Free Of Cuts And Defects', 'tyresCorrectDepthAndFreeOfCutsAndDefects', yesNo, isActive, entity, entityErrors, changeInput)}
                {entity.tyresCorrectDepthAndFreeOfCutsAndDefects === no &&
                    createMultilineTextInput('Tyres Correct Depth And Free Of Cuts And Defects Details', 'mottyresCorrectDepthAndFreeOfCutsAndDefectsDetailsDate', isActive, 999, entity, entityErrors, changeInput)}
                {createTextInput('Created At', 'createdAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Created By', 'createdBy', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated At', 'updatedAt', false, 20, entity, entityErrors, changeInput)}
                {createTextInput('Updated By', 'updatedBy', false, 20, entity, entityErrors, changeInput)}
            </div>
        }
    }

    //Generic
    const addType = 'add';
    const addFromType = 'addFrom';
    const editType = 'edit';
    const deleteType = 'delete';
    const defaultPageMessage = 'Please complete the below and then click Action.';
    const deleteConfirmationMessage = 'Please confirm deletion of the below details by clicking Action.';
    const failValidationMessage = 'Please correct any errors and click Action again.';
    const rowVersionMessage = 'A newer version of this record has already been saved. Please reload the data.';
    const missingRecordMessage = 'Unable to find record in db. Please reload the data.';
    const invalidSubmitMessage = 'Invalid submit request, please advise support.';
    const saveErrorMessage = 'Unable to save, please advise support.';
    const [loadingData, setLoadingData] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [isActiveOnEdit, setIsActiveOnEdit] = useState(true);
    const [entity, setEntity] = useState(emptyEntity);
    const [entityErrors, setEntityErrors] = useState(emptyEntity);
    const [errored, setErrored] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [saving, setSaving] = useState(false);
    const [message, setMessage] = useState(defaultPageMessage);

    useEffect(() => {
        const loadEntity = async () => {
            if (props.data.type === addType) {
                setEntity(emptyEntity);
            } else if (props.data.type === addFromType) {
                const entity = addFromEntityWithKey;
                setEntity(entity);
            } else {        
                const entityData = await Axios.get(entityUrlWithId);
                setEntity(entityData.data.result);
            }

            setLoadingData(false);
        }

        if (props.data.type === deleteType) {
            setIsActive(false);
            setIsActiveOnEdit(false);
            setMessage(deleteConfirmationMessage)
        }

        if (props.data.type === editType || props.data.type === addFromType) {
            setIsActiveOnEdit(false);
        }

        loadSpecificData();
        loadEntity();
    }, [])

    const changeInput = (event) => {
        let newFormData = JSON.parse(JSON.stringify(entity));
        newFormData[event.target.name] = event.target.value;
        setEntity(newFormData);

        if (event.target.name in entityErrors) {
			let newFormErrors = { ...entityErrors };
			newFormErrors[event.target.name] = '';
			setEntityErrors(newFormErrors);
		}
    }

    const validSubmit = () => {
        cleanData();
        let formErrorsTemp = emptyEntity;        
        if (!evaluatedRulesPassed(formErrorsTemp)) {
            return true;
        }

        setEntityErrors(formErrorsTemp);
        setMessage(failValidationMessage);
        return false;
    }

    const processDelete = async () => {
        if (props.data.type === deleteType) {
            setSaving(true);
            await Axios.delete(entityUrlWithId, entity);
            setDeleted(true);
            props.sync({ deleted: true, id: props.data.entity.id });
            setSaving(false);

            return true;
        }

        return false;
    }

    const processAdd = async () => {
        if (props.data.type === addType || props.data.type === addFromType) {
            setSaving(true);
            const entityResult = await Axios.post(entityUrl, entity);
            props.data.type = editType;
            props.data.entity.id = entityResult.data.result.id;
            setEntity(entityResult.data.result);
            props.sync(entityResult.data.result);
            setIsActiveOnEdit(false);
            setSaving(false);

            return true;
        }
        
        return false;
    }

    const processEdit = async () => {
        if (props.data.type === editType) {
            setSaving(true);
            const entityResult = await Axios.put(entityUrlWithId, entity);

            if (entityResult.status === 200) {
                setEntity(entityResult.data.result);
                props.sync(entityResult.data.result);
            }

            if (entityResult.status === 409) {
                setMessage(rowVersionMessage);
                setErrored(true);
            }

            if (entityResult.status === 400) {
                setMessage(missingRecordMessage);
                setErrored(true);
            }
            
            setSaving(false);
            return true;		
        }

        return false;
    }

    const submitForm = async () => {
        try {
            if (await processDelete()) { return; }
            if (validSubmit()) {
                if (await processAdd()) { return; }
                if (await processEdit()) { return; }

                setMessage(invalidSubmitMessage);
                setErrored(true);
            }
        }
        catch (err) {
            console.log(err);
            setMessage(saveErrorMessage);
            setErrored(true);
        }
    }

    if (deleted) return <UILIB.Paper className='width-100'><p className="center">Deleted</p></UILIB.Paper>

    if (errored) return <UILIB.Paper className='width-100'><p className="center">{message}</p></UILIB.Paper>

    if (loadingData) return <UILIB.Loading type={3} />

    return (
        <div className="mar-l15 mar-r15">
            <h2 className='mar-b10'>{(props.data.type === addType || props.data.type === addFromType) ? 'Add' : 'Edit'}{headerText}{entity.id}</h2>
            <p>{message}</p>
            {htmlButtons(loadingData)}
            {htmlRows(loadingData)}
        </div> 
    )
}