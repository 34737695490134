import axios from 'axios';
import { store } from 'store/store'
import { setRefreshToken, setAccessToken, logout } from 'store/actions'
import { Buffer } from 'buffer';


async function getAccessToken(host, refreshToken, accessToken) {
  const data = await axios.post(`${host}/getAccessToken`, { accessToken: accessToken, refreshToken: refreshToken });
  return Promise.resolve(data);
}

const exceptions = [401,403,500];
const axiosInstance = axios.create();
axiosInstance.defaults.crossDomain = true;
axiosInstance.defaults.withCredentials = false;
axiosInstance.defaults.validateStatus = function (status) { if(!exceptions.includes(status)) return true; };

axiosInstance.interceptors.request.use(async config => {

    const state = await store.getState();
    const accessToken = state.accessToken;
    const account = state.account && Buffer(JSON.stringify(state.account)).toString('base64');

    if (config.method !== 'OPTIONS') {
        config.headers['Authorization'] = "Bearer " + accessToken;   
        config.headers['Account'] = account;
    }
    config.url = process.env.REACT_APP_ENV_URL + config.url;
    return config;

}, function (err) {
    return Promise.reject({ message: err });
})

axiosInstance.interceptors.response.use(response => response, async error => {

    const errorMessage = ((error.response && error.response.data) && error.response.data.message) ? error.response.data.message : null;
    const state = await store.getState();
    const refreshToken = state.refreshToken;
    const accessToken = state.accessToken;
    const account = state.account && Buffer(JSON.stringify(state.account)).toString('base64');
    const { dispatch } = store; 

    const refreshTokenData = async (host, refreshToken, accessToken) => {

      const token = await getAccessToken(host, refreshToken, accessToken);

      dispatch(setRefreshToken(token.data.refreshToken)); 
      dispatch(setAccessToken(token.data.accessToken)); 

      error.config.headers['Authorization'] = 'Bearer ' + token.data.accessToken;
      error.config.headers['Account'] = account;
    }

    if (errorMessage === "Token Expired" && accessToken) return await refreshTokenData(process.env.REACT_APP_ENV_URL, refreshToken, accessToken).then(_ => axios.request(error.config));
    if (errorMessage === "Authentication Failure" && accessToken) return Promise.reject(dispatch(logout()));
    if (error.message === 'cancelled') return Promise.reject(error.code);
    return Promise.reject(error.response)
})


export default axiosInstance;