
import axios from "classes/axios";
import UILIB from "components";
import AdminHub from "./adminHub/adminHub";
import FrontPage from "./frontpage/frontpage";
import * as Session from "classes/session";
import { ToastContainer } from 'react-toastify';
import { setAccount, setDrawer, setReleaseVersion } from "store/actions";
import { useEffect, useState, memo, Fragment } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { permissionsRouter } from 'classes/permissions';
import AccountManagerView from "./saleshub/accountmanagerview/accountmanagerview";
import AccountManagerDeals from "./saleshub/accountmanagerview/deals/deals";
import AccountManagerQuotes from "./saleshub/accountmanagerview/blockQuotesView";
import AccountManagerDrafts from "./saleshub/accountmanagerview/blockDraftsView";
import AccountManagerQuotesAdd from "./saleshub/accountmanagerview/quotes/blockQuotePage";
import AccountManagerOrdersAdd from "./saleshub/accountmanagerview/orders/blockOrderPage";
import SalesManagerView from "./saleshub/salesmanagerview/salesmanagerview";
import AccountManagerOnstop from "./saleshub/accountmanagerview/customers/onstop";
import AccountManagerServicealerts from "./saleshub/accountmanagerview/customers/servicealerts";
import PartsAdmin from './saleshub/partsManagement/partsAdmin';
import PartsManagement from './saleshub/partsManagement/partsManagement';
import PartsManagementAddProduct from './saleshub/partsManagement/addProduct/addProduct';
import PartsManagementImport from './saleshub/partsManagement/import'
import PartsManagementImportSetup from './saleshub/partsManagement/import/setup'
import PartsManagementImportProgress from './saleshub/partsManagement/import/progress'
import LogisticsHub from "./logisticsHub/logisticsHub";
import DeviceInstall from './logisticsHub/deviceInstall/deviceInstall';
import NewDeviceMovement from "./logisticsHub/deviceMovement/deviceMovement";
import CustomerDeviceMovement from "../public/deviceMovement/customerSign";
import ViewLinkError from "../../components/Error/viewLinkError";
import ConsumablesHub from "./consumablesHub/consumablesHub";
import CustomerSiteStock from "./consumablesHub/customerRequests/customerRequests";
import CustomerSiteStockRequests from "./consumablesHub/customerSiteStockRequests/customerSiteStockRequests";
import QueuedOrders from "./consumablesHub/queuedOrders/queuedOrders";
import ConsumablesLookUp from "./consumablesHub/consumablesLookUp/consumablesLookUp";
import RequestConsumables from "./requestConsumablesHub/requestConsumables";
import TeamInfo from "./consumablesHub/teamInfo/teamInfo";
import FailedBot from "./consumablesHub/failedBot/failedBot";
import SendToVantageOnline from "./consumablesHub/sendToVantageOnline/sendToVantageOnline";
import TonerLogging from "./consumablesHub/tonerLogging/tonerLogging";
import SopHub from "./sophub/sopHub";
import OrderHub from "./orderHub/orderHub";
import FleetHub from "./fleetHub/fleetHub";
import RdHub from "./rdHub/rdHub";
import ContractsHub from "./contractsHub/contractsHub";
import Header from "./includes/header/header";
import Footer from "./includes/footer/footer";
import ReleaseNotes from "./includes/footer/links/releaseNotes";
import VehicleTable from './fleetHub/entities/vehicle/tableView';
import DriverTable from './fleetHub/entities/driver/tableView';
import DriverCheckTable from './fleetHub/entities/driverCheck/tableView';
import OwnershipTable from './fleetHub/entities/Ownership/tableView';
import ClaimTable from './fleetHub/entities/claim/tableView';
import ServiceTable from './fleetHub/entities/service/tableView';
import MotTable from './fleetHub/entities/mot/tableView';
import QuarterlyCheckTable from './fleetHub/entities/quarterlyCheck/tableView';
import UploadManagement from './fleetHub/entities/uploadManagement/uploadManagement';
import FleetDocuments from './fleetHub/entities/documents/documents';
import PortalHub from "./portalHub/portalHub";
import RMBHub from "./rmbHub/rmbHub";


const MasterTemplate = memo(() => 
{

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const userPermissions = useSelector((state) => state.permissions);
  const accessToken = useSelector((state) => state.accessToken);
  const account = useSelector((state) => state.account);
  const drawer = useSelector((state) => state.drawer);
  const session = useSelector((state) => state.sessionExpire);
  const [sessionTimeout, setSessionTimeout] = useState(60);
  const generalAccess = permissionsRouter.bind(null, history, userPermissions, account, null, location.pathname, 'General Access');


  useEffect(() => {
    if(accessToken) { 
      Session.sessionAlive(20000);
      Session.sessionTimer(1000, sessionTimeout);
    }
  }, []);

  useEffect(() => {
    dispatch(setDrawer({ open: false }));
  }, [history, location]);

  useEffect(() => {
    const asyncEffect = async () => {
      if(accessToken) {

        const resultReleaseNotes = await axios.get(`/admin/releaseNotes/getLatestReleaseNumber`);
        const userResult = await axios.get(`/users/me`);
  
        dispatch(setAccount(userResult?.data));
        dispatch(setReleaseVersion(resultReleaseNotes?.data?.result?.versionNumber));

      }
    }
    asyncEffect();
  }, []);

  if (!account) return <Fragment></Fragment>;

  return (
    <Fragment>
      <Header />
      <ToastContainer
        progressClassName="toastify"
        position="bottom-right"
        autoClose={4000}
        style={{ width: 500 }}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
      <div className="content">
        <Switch>
          <Route path="/customerSign/deviceMovement/:formid?"><CustomerDeviceMovement /></Route>
          <Route path="/viewLinkError"><ViewLinkError generalAccess={() => generalAccess()} /></Route>
          <Route path="/adminHub"><AdminHub generalAccess={() => generalAccess()} /></Route>
          <Route path="/contractsHub"><ContractsHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/consumablesHub"><ConsumablesHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/requestConsumablesHub"><RequestConsumablesHub generalAccess={() => generalAccess()} /></Route>
          <Route path="/sopHub"><SopHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/logisticsHub"><LogisticsHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/salesHub"><SalesHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/orderHub"><OrderHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/fleetHub"><FleetHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/rdHub"><RdHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/releaseNotes"><ReleaseNotes generalAccess={() => generalAccess()} /></Route>
          <Route path="/portalHub"><PortalHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/rmbHub"><RMBHubTemplate generalAccess={() => generalAccess()} /></Route>
          <Route path="/"><FrontPage /></Route>
        </Switch>
        <UILIB.Drawer drawer={drawer} />
        {session && <UILIB.SessionExpireModal
          sessionTimeout={sessionTimeout}
          setSessionTimeout={setSessionTimeout}
          onLogout={() => { Session.onLogout(1) }}
          onLoggedIn={async () => { await Session.onLoggedIn(setSessionTimeout) }} />}
      </div>
      <Footer />
    </Fragment>
  );

})
export default MasterTemplate

function ContractsHubTemplate(props) {
  return (
    <Switch>
      <Route path="/contractsHub/quote/:dealID?"><AccountManagerQuotesAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/contractsHub/order/:dealID?"><AccountManagerOrdersAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/contractsHub"><ContractsHub permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  );
}

function ConsumablesHubTemplate(props) {
  return (
    <Switch>
      <Route path="/consumablesHub/consumablesLookUp/:context/:id"><ConsumablesLookUp permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub/consumablesLookUp"><ConsumablesLookUp permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub/customerSiteStock/:context/:id"><CustomerSiteStock permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub/customerSiteStock"><CustomerSiteStock permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub/customerSiteStockRequests"><CustomerSiteStockRequests permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub/queuedOrders"><QueuedOrders permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub/teamInfo"><TeamInfo permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub/failedBot"><FailedBot permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub/sendToVantageOnline"><SendToVantageOnline permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub/tonerLogging"><TonerLogging permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/consumablesHub"><ConsumablesHub permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  );
}

function RequestConsumablesHub(props) {
  return (
    <Switch>
      <Route path="/requestConsumablesHub"><RequestConsumables permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  );
}

function SopHubTemplate(props) {
  return (
    <Switch>
      <Route path="/sopHub/quote/:dealID?"><AccountManagerQuotesAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/sopHub/order/:dealID?"><AccountManagerOrdersAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/sopHub"><SopHub permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  );
}

function LogisticsHubTemplate(props) {
  return (
    <Switch>
      <Route path="/logisticsHub/deviceInstall/:formid?"><DeviceInstall /></Route>
      <Route path="/logisticsHub/quote/:dealID?" render={(props) => <AccountManagerQuotesAdd {...props} />} />
      <Route path="/logisticsHub/order/:dealID?" render={(props) => <AccountManagerOrdersAdd {...props} />} />
      <Route path="/logisticsHub/deviceMovement/:formid?"><NewDeviceMovement /></Route>
      <Route path="/logisticsHub"><LogisticsHub /></Route>
    </Switch>
  );
}

function SalesHubTemplate(props) {
  return (
    <Switch>
      <Route path="/salesHub/accountmanagerview/quoteview"><AccountManagerQuotes permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/accountmanagerview/draftview"><AccountManagerDrafts permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/accountmanagerview/quotes/add/:dealID?"><AccountManagerQuotesAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/accountmanagerview/order/:dealID?"><AccountManagerOrdersAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/accountmanagerview/quote/:dealID?"><AccountManagerQuotesAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/accountmanagerview/deals/:dealStatus"><AccountManagerDeals permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/accountmanagerview/customers/onstop"><AccountManagerOnstop permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/accountmanagerview/customers/servicealerts"><AccountManagerServicealerts permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/accountmanagerview"><AccountManagerView permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/saleshub/partsManagement/admin"><PartsAdmin permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/partsManagement/addProduct/:productId?"><PartsManagementAddProduct permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/partsManagement/import/setup/:id?"><PartsManagementImportSetup permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/partsManagement/import/:id"><PartsManagementImportProgress permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/partsManagement/import"><PartsManagementImport permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/partsManagement"><PartsManagement permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/salesHub/salesmanagerview"><SalesManagerView permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  );
}

function OrderHubTemplate(props) {
  return (
    <Switch>
      <Route path="/orderHub/quote/:dealID?"><AccountManagerQuotesAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/orderHub/order/:dealID?"><AccountManagerOrdersAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/orderHub"><OrderHub permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  );
}

function FleetHubTemplate(props) {
  return (
    <Switch>
      <Route path="/fleetHub/drivers"><DriverTable permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/driverChecks/all"><DriverCheckTable pick='/fleet/driverChecks/all' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/driverChecks/due"><DriverCheckTable pick='/fleet/driverChecks/due' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/driverChecks/overdue"><DriverCheckTable pick='/fleet/driverChecks/overdue' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/vehicles/all"><VehicleTable pick='/fleet/vehicles/all' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/vehicles/renewalUpcoming"><VehicleTable pick='/fleet/vehicles/renewalUpcoming' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/vehicles/renewalOrder"><VehicleTable pick='/fleet/vehicles/renewalOrder' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/Ownerships/all"><OwnershipTable pick='/fleet/ownerships/all' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/Ownerships/poolCars"><OwnershipTable pick='/fleet/ownerships/poolCars' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/Ownerships/totalCars"><OwnershipTable pick='/fleet/ownerships/totalCars' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/claims"><ClaimTable permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/services/all"><ServiceTable pick='/fleet/services/all' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/services/due"><ServiceTable pick='/fleet/services/due' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/services/overdue"><ServiceTable pick='/fleet/services/overdue' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/mots/all"><MotTable pick='/fleet/mots/all' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/mots/due"><MotTable pick='/fleet/mots/due' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/mots/overdue"><MotTable pick='/fleet/mots/overdue' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/quarterlyChecks/all"><QuarterlyCheckTable pick='/fleet/quarterlyChecks/all' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/quarterlyChecks/due"><QuarterlyCheckTable pick='/fleet/quarterlyChecks/due' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/quarterlyChecks/overdue"><QuarterlyCheckTable pick='/fleet/quarterlyChecks/overdue' permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/uploadManagement"><UploadManagement permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub/documents/viewDocuments"><FleetDocuments permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/fleetHub"><FleetHub permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  );
}

function RdHubTemplate(props) {
  return (
    <Switch>
      <Route path="/rdHub"><RdHub permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  )
}

function RMBHubTemplate(props) {
  return (
    <Switch>
      <Route path="/rmbHub"><RMBHub permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  )
}

function PortalHubTemplate(props) {
  return (
    <Switch>
      <Route path="/portalHub/order/:dealID?"><AccountManagerQuotesAdd permissions={{ generalAccess: props.generalAccess }} /></Route>
      <Route path="/portalHub"><PortalHub permissions={{ generalAccess: props.generalAccess }} /></Route>
    </Switch>
  )
}
