import UILIB from 'components';
import { setCacheData } from '../functions';

export default function RecordSelect({ tableData, settings, render, setRender }) 
{

  const setPageHandler = (event) => {
    const found = settings.current.paging.pages.find(x => Number(x.value) === Number(event.target.value));
    if(found) settings.current = setCacheData({ ...settings.current, paging: { ...settings.current.paging, limit: found.label, pageSelected: found.value, offset: 0, page: 0 } });
    setRender(!render);
  }

  return <div className="recordSelect">
    {settings.current.showRecords && tableData.current && <p className={"mar-r5"} style={{ marginTop: 4, display : 'inline' }}>{tableData.current.length} {settings.current.paging.totalRows === 1 ? 'Record ' : 'Records '}</p>}
      {<div className="flex-container row">
        <p className={"mar-r5"} style={{ marginTop: 4, display : 'inline' }}>{' | '} {settings.current.paging.totalRows} {' Total Records'}</p>
        <p className={"mar-r5"} style={{ marginTop: 4, display : 'inline' }}>{' | Records Per Page:'}</p>
        <UILIB.Select 
          style={{ width: 50 }}
          outerstyle={{ marginLeft: 2, marginRight: 4, width: 50 }}
          name="showRecordTotal"  
          value={settings.current.paging.pageSelected}
          onChange={event => setPageHandler(event) } 
          data={settings.current.paging.pages} 
        />
      </div>}
  </div>
}